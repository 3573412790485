import React, { Component } from 'react'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    /*start XOX */
    xox: {
        fontSize: 0,
        width: "150px",
        left: "50%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%) scale(1)",
        textAlign: "center",
        "& > div": {
            display: "inline-block",
            verticalAlign: "middle",
            width: "42px",
            height: "42px",
        },
        "& .x svg *": {
            fill: "#E2B231",
        },
        "& .x svg": {
            
            width: "50px",
            height: "5px",
            //display: props => (props.onlyO ? "none" : "block"),
            display: "block",
            transition: "transform 0.2s linear",
        },
        "& .x svg:nth-child(1)": {
            transform: "translate(-1px,20px) rotate(45deg)",
        },
        "& .x svg:nth-child(2)": {
            transform: "translate(-1px,15px) rotate(-45deg)",
        },
        "& .x:nth-child(3) svg:nth-child(1)": {
            transform: "scaleX(-1) translate(7px, 20px) rotate(45deg)",
        },
        "& .x:nth-child(3) svg:nth-child(2)": {
            transform: "scaleX(-1) translate(7px, 15px) rotate(-45deg)",
        },
        "& .o": {
            animation: "rotate 1s linear infinite",
        },
        "& .o svg": {
            width: "45px",
            height: "50px",
        },
        "& .o svg circle:nth-child(1)": {
            fill: "transparent",
            stroke: "#383b3d",
            strokeWidth: "5px",
        },
        "& .o svg circle:nth-child(2)": {
            fill: "#383b3d",
            stroke: "#fff",
            strokeWidth: "2px",

        }
    },
    onlyO: {
        top: "50%",
        "& .x": {
            display: "none",
        }
    },
    onlyOWrapper: {
        background: "#fff",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    }


/*start XOX */
});
class XOXLoader extends Component {
    render() {
        var { classes, onlyO, height, transparent, style } = this.props;
        if (!height) {
            //
            var mainContent = document.getElementById('mainContent');
            if (mainContent) {
                height = mainContent.clientHeight - 64;
            }
            else {
                height = "calc(100vh - 230px)";
            }
        }
		
		if (!style) {	
			style = {
				height
			};
		} else {
			style.height = height;
		}
		
		if (transparent) {
			style.background = "rgba(255,255,255,0.7)"
		}
		
        if (onlyO) {
            style = styles().onlyOWrapper;
        }
        return (
            <div style={style}>
                <div className={classes.xox + " " + (onlyO ? classes.onlyO : "")}>
                    <div className='x'>
                        <svg className='bar'>
                            <polygon points="0,2.5 2.5,0 47.5,0 50,2.5 47.5,5 2.5,5 0,2.5" />
                        </svg>
                        <svg className='bar'>
                            <polygon points="0,2.5 2.5,0 45,0 42.5,2.5 45,5 2.5,5 0,2.5" />
                            <circle cx="47.5" cy="2.5" r="2.5" />
                        </svg>
                    </div>
                    <div className='o'>
                        <svg>
                            <circle cx="21" cy="21" r="17.5" />
                            <circle cx="21" cy="38.75" r="4.5" />
                        </svg>
                    </div>
                    <div className='x'>
                        <svg>
                            <polygon points="0,2.5 2.5,0 47.5,0 50,2.5 47.5,5 2.5,5 0,2.5" />
                        </svg>
                        <svg>
                            <polygon points="0,2.5 2.5,0 45,0 42.5,2.5 45,5 2.5,5 0,2.5" />
                            <circle cx="47.5" cy="2.5" r="2.5" />
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}

XOXLoader.propTypes = {
    classes: PropTypes.object.isRequired,
    onlyO: PropTypes.bool,
};
XOXLoader.defaultProps = {
    onlyO: false
}

export default withStyles(styles)(XOXLoader);