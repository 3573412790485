import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Currency extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    // checkUsed = async (FIDCountry) => {
    //     return await fetch('/IR/country/IsUsed/' + FIDCountry).then(res => res.json());
        
    // }
    // deleteBrand = (FIDCountry) => {
    //     fetch('/IR/country/Delete/' + FIDCountry, { method: "POST" }).then(res => {
    //         this.Editor.current.doExit();
    //     });
    // }
    // onDeleteClick = () => {
    //     var id = this.props.match.params.id;

    //     var isUsed = this.checkUsed(id);

    //     var label = "Confermi la cancellazione della nazione?";

    //     var buttons = [
    //         {
    //             label: "SI",
    //             action: () => {
    //                 this.Editor.current.hideDialog();
    //                 this.deleteBrand(id);
    //             }
    //         },
    //         {
    //             label: "NO",
    //             action: () => {
    //                 this.Editor.current.hideDialog();
    //             }
    //         },
    //     ];
    //     if (isUsed) {
    //         label = "La nazione è utilizzata. Non è possibile procedere con la cancellazione.";
    //         buttons = [
    //             {
    //                 label: "OK",
    //                 action: () => {
    //                     this.Editor.current.hideDialog();
    //                 }
    //             },
    //         ];
    //     }


    //     this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    // }
    render() {
        var fields = [
            {
                key: 'Default',
                label: 'Predefinito',
                type: 'XSwitch',
                Required: 2
            },
            {
                key: 'Symbol',
                label: 'Simbolo',
                type: 'XField',
                Required: 2
            },
            {
                key: 'FIDSDICode',
                label: 'Codice SDI',
                type: 'XSelect',
                getUrl: '/Base/GetSDICodes?Table=ISO4217',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Description',
                abbreviationKey:'Code',
                Required: 2
            }
        ];
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/gc/currency/Save/'
            urlDelete='/gc/currency/Delete/'
            urlRedirect='/Currency/'
            urlExit='/Currencies/'
            urlNew="/Currency/new"
            urlGetData='/gc/currency/'
            urlCheckChanges='/gc/currency/HasChanges/'
            //urlClearSession='/IR/ClearSession/'
            //onDeleteClick={this.onDeleteClick}
            translate={true}
            fields={fields}
            AbbreviationKey="Abbreviation"
            DescriptionKey="Description"
            //routeTranslatableFields="/bop/brand/translatableFields/"
            //routeLanguages="/bop/brand/languages/"
            //routeDataT="/bop/brand/translations/"
            //routeSaveData="/bop/brand/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Currency);
