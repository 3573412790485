import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {withStyles, Paper, AppBar} from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

import CatalogueItem from 'components/CatalogueItem';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';

const style = theme => ({
    root: {

    }
});


class BOPStock extends Component {
    constructor(props) {
        super(props);

        this.CatalogueItem = React.createRef();

        this.state = {
            data: undefined,
            isLoading: true,
            showItem: false,
        };
    }
    static contextTypes = {
        router: PropTypes.object
    }
    
    componentDidMount() {
        var id = this.props.match.params.id;
        fetch(`/BOP/GetCatalogueData/${id}`).then(res => res.json()).then(data => {
            this.setState({ data, isLoading: false, showItem: true });
        });
        
    }
    onItemEditClick = () =>{
        var id = this.props.match.params.id;
        this.context.router.history.push(`/BOPEdit/${id}`);
    }
    handleCatalgoItemClose = () =>{
        //this.context.router.history.push('/stock/');
        this.context.router.history.goBack();
    }
    actions = (isSelected,data) => {
        return <Fragment></Fragment>;
    }
    render() {
        var { classes } = this.props;
        var { data } = this.state;
        var customActions = [];
        var label = "";

        return (
            <Paper className={classes.root}>
                <AppBar position="sticky">
                    <EnhancedTableToolbar
                        numSelected={0}
                        selectedAll={false}
                        count={0}
                        actions={customActions}
                        grid={this}
                        label={label}
                    />
                </AppBar>
                {data && (
                    <CatalogueItem 
                        ref={this.CatalogueItem} 
                        showHistory={true} 
                        actions={this.actions} 
                        isCatalog={false} 
                        onEditClick={this.onItemEditClick} 
                        data={data} 
                        open={this.state.showItem} 
                        onClose={this.handleCatalgoItemClose} 
                        //onStarItem={this.onStarItem} 
                        //onBuyItem={this.onBuyItem} 
                        //onNoticeItem={this.onNoticeItem} 
                        //onReserveItem={this.onReserveItem} 
                        //onBookItem={this.onBookItem} 
                        //onRemoveItem={this.onRemoveItem} 
                        //onNoteItem={this.onNoteItem} 
                        //onShowRemarks={this.onShowRemarks} 
                        //showRemarks={this.state.showRemarks} 
                        //onHideRemarks={this.onHideRemarks} 
                    />
                )}
            </Paper>
        );
    }
}


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(BOPStock);