import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Language extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    checkUsed = async (FIDBrand) => {
        return await fetch('/GC/LanguageIsUsed/' + FIDBrand).then(res => res.json());
        
    }
    deleteBrand = (FIDBrand) => {
        fetch('/GC/LanguageDelete/' + FIDBrand, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione del lingua?";
        if (isUsed) {
            label = "La lingua è utilizzata. Non è possibile procedere con la cancellazione.";
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteBrand(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];
        if(isUsed){
            buttons = [
                {
                    label: "OK",
                    action: () => {
                        this.Editor.current.hideDialog();
                    }
                },
            ];
        }

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {

        var fields = [
            {
                key: 'Code',
                label: 'Codice',
                type: 'XField',
            },
            {
                key: 'Main',
                label: 'Predefinito',
                type: 'XSwitch',
            },
        ];

        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/GCLanguage/SaveLanguage/'
            //urlDelete='/GC/DeleteLanguage/'
            urlRedirect='/Language/'
            urlExit='/Languages/'
            urlNew="/language/new"
            urlGetData='/GCLanguage/GetLanguage/'
            //urlCheckChanges='/GC/LanguageHasChanges/'
            //urlClearSession='/GC/ClearSession/'
            //onDeleteClick={this.onDeleteClick}
            AbbreviationKey="Abbreviation"
            DescriptionKey="Description"
            fields={fields}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Language);
