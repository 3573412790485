import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"

import "../components/XReactDataGrid.css";
import { Tooltip, IconButton } from '@material-ui/core';
import { AccountBadgeOutline, RedoVariant, ArrowLeft, Printer } from 'mdi-material-ui';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import { actionCreators } from '../store/Auth';





class DocumentExpandRow extends Component {
    constructor(props){
        super(props);
        this.state = {
            view : "processedext",
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }

    handleGridDoubleClick = (event, data) => {
        var { view } = this.state;
        this.context.router.history.push(`/doc/edit/${data.ID}?${view}=true`);
    }

    showProcessedExt = () => {
        this.setState({view: "processedext"});
    }
    render () {
        var { width,data } = this.props;
        var { view } = this.state;
        var label = undefined;
        // var actions = [];
        
        // actions.push({ name: "Indirizzi", icon: <MapMarker />, action: this.showProcessedExt, selected: view === "processedext" });
        // actions.push({ name: "Relazioni", icon: <AccountGroup />, action: this.showRelations, selected: view === "relations" });
        // var direction = "right";
        // if (["xs"].includes(width)) {
        //     direction = "down";
        // }
        // var label = <XNavigation actions={actions} direction={direction} showLabel={true} />;

        var gridName = "tblDOCProcessed";
        var dataRoute = `/DOC/GetProcessedDocsExt/${data.ID}?grid=${gridName}`;

        // if(view ==="relations") {
        //     gridName = "tblIRRelations";
        //     dataRoute = `/IR/GetIRRelations/${data.ID}?grid=${gridName}`;
        // }
        

        return <XGrid
            key={gridName}
            label={label}
            containerID={`gridExpandContainer_${data.ID}`}
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid==${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid==${gridName}`}
            onDoubleClick={this.handleGridDoubleClick}
            onEditButton={this.handleGridDoubleClick}
            showColumnsSelector={true}
            showFilterActivator={true}
        />;
    }
}
class Documents extends Component {
    constructor(props) {
        super(props);
        this.grdDocuments = React.createRef();
		this.embedPDFRef = React.createRef(); 
		this.state = {
			printDoc: undefined
		}
    }
    static contextTypes = {
        router: PropTypes.object
    }
    
    rowExpandFormat = (data) => {
        return <DocumentExpandRow data={data} />;
    }
    handleGridDoubleClick = (event, data) => {
        //var checkArray = [this.props.params.DOCCartCausalId, this.props.params.DOCPresetationCausalId];
        //checkArray = checkArray.concat(this.props.params.DOCCatalogCausals);
        //if (checkArray.includes(data.FIDCausal)) {
        if(data.HasFile && (this.props.userID != -1990 && (this.props.FIDProfile !== (this.props.params.UserTypologyId||7)))) {
            this.doShowPresentation(data);
        }
        else {
            this.context.router.history.push('/doc/edit/' + data.ID);
        }
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/documents');
    }
    handleShowPresentation = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        var data = this.grdDocuments.current.getRowData(selectedRows[0]);
        this.doShowPresentation(data);
    }
    doShowPresentation = (data) => {
        this.props.setCatalogCausal(data.FIDCausal);
        this.props.setCatalogIdentity(data.FIDIdentity);
        if (data.FIDCausal === this.props.params.DOCPresentationCausalId) {
            this.context.router.history.push('/presentation/' + data.ID);
        }
        else {
            this.context.router.history.push('/catalogue/' + data.ID);
        }
    }
    handleReturnDocuments = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        fetch('/DOC/ReturnDocumentFromDocument/', {
            method: 'POST',
            body: JSON.stringify(selectedRows)
        }).then(res => res.json()).then(data => {
            if (!data) {
                //nessun reso generato
            }
            this.grdDocuments.current.refresh();
        });
    }
	cancelPrint = () => {
		this.setState({
			printDoc: undefined
		});
	}
	printDocument = () => {
		var printDoc = this.grdDocuments.current.getSelectedData()[0];
		
		if (printDoc) {
			this.setState({
				printDoc
			});
		}
	}
    render() {
        var { FIDDocumentType, FIDDocumentSubtype } = this.props;
	    var { printDoc } = this.state;
        var selectedActions = [];

        var handleGridNew = undefined;//this.handleGridNew;
        var handleGridEdit = this.handleGridDoubleClick;
        var gridDoubleClick = this.handleGridDoubleClick;
        var deleteRoute = '/DOC/DeleteDocument/';
        if (!this.props.menus.includes("docedit")) {
            //handleGridEdit = undefined;
        }
        if (!this.props.menus.includes("docnew")) {
            handleGridNew = undefined;
        }
        if (!this.props.menus.includes("docdelete")) {
            deleteRoute = undefined;
        }

        var url = "/DOC/GetDocuments?grid=tblDocuments";
        if(FIDDocumentType){
            url = `${url}&FIDDocumentType=${FIDDocumentType}`;
        }
        if(FIDDocumentSubtype){
            url = `${url}&FIDDocumentSubtype=${FIDDocumentSubtype}`;
        }

		var customContent = undefined;
		var actions = [];
		if (printDoc) {
			customContent = <embed ref={el => (this.embedPDFRef = el)} src={`/DOC/PrintDocument/?ID=${printDoc}`} type="application/pdf" width="100%" height={document.body.offsetHeight - 151} />;
			actions = [
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelPrint
				}
			];
		}
		else {
			actions.push(
				{
					group: 'actions',
					name: 'Stampa',
					icon: <Printer />,
					action: this.printDocument,
					condition: r => r.length === 1 && this.grdDocuments.current.getRowData(r[0]).FIDStatus === 3
				}
			);
			if(this.props.FIDProfile !== this.props.params.CustomerTypologyId){
			//     actions.push(<Tooltip title="Indietro" >
			//     <IconButton aria-label="Indietro" onClick={this.handleGridNew} >
			//         <ArrowLeft />
			//     </IconButton>
			// </Tooltip>);
				actions.push({
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.handleGridNew
				});
			}
		}
        return (
            <XGrid
                showLoader
                usePrimaryColor={true}
                innerRef={this.grdDocuments}
                dataRoute={url}
                dataRouteColumns="/Base/GetColumns?grid=tblDocuments"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblDocuments"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblDocuments"
                dataRouteDelete={deleteRoute}
                onDoubleClick={gridDoubleClick}
                onEditButton={handleGridEdit}
                onNewButton={handleGridNew}
                selectedActions={selectedActions}
                rowExpandFormat={this.rowExpandFormat}
                actions={actions}
				customContent={customContent}
            />
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    )
);

export default enhance(Documents);