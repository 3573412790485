import React, {Component, Fragment} from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid";

import axios, { CancelToken } from 'axios';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { Check } from 'mdi-material-ui';
import { Tooltip, IconButton } from '@material-ui/core';
import XUpload from '../../components/XUpload';
import XProgress from '../../components/XProgress';

const style = () =>({
});
class Pdf extends Component {
    constructor(props) {
        super(props);
        this.grid = React.createRef();

        this.state = {
            data: {},
            uploadProgress: 0,
            isUploading: false,
        }
        this.cancelUpload = undefined;
    }
  
    
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        //this.context.router.history.push('/Brand/' + data.ID);
    }
    handleGridNew = (event) => {
        //this.context.router.history.push('/brand/new');
    }

    uploadPdf = (_) =>(_,file) => {
        console.log(file);
        const data = new FormData();
        data.append('file', file, file.name);

        axios.post('/CRM/UploadPdf/', data, {
            onUploadProgress: progressEvent => {
                this.setState({
                    isUploading: true,
                    uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100)
                });
            },
            cancelToken: new CancelToken(c => {
                this.cancelUpload = c;
            })
        })
            .then(res => {
                this.setState({
                    isUploading: false,
                    uploadProgress: 0
                }, () => {
                        this.grid.current.getWrappedInstance().refresh();
                });
            });
    }
    render() {
        var { data } = this.state;
        var customActions = [
			{
				group: 'actions',
				name: 'Upload',
				icon: <XUpload label="Upload" id="pdf" value={data.pdf} onChange={this.uploadPdf} color="default"/>,
				action: undefined
			}
		];

        return (
            <Fragment>
                <XGrid
                    ref={this.grid}
                    usePrimaryColor={true}
                    dataRoute="/CRM/GetPDFs?grid=tblPdfs"
                    dataRouteColumns="/Base/GetColumns?grid=tblPdfs"
                    dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblPdfs"
                    dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblPdfs"
					dataRouteDelete="/CRM/DeletePDF"
                    onDoubleClick={false}
                    onEditButton={false}
                    onNewButton={false}
                    actions={customActions}
                />
                <XProgress progress={this.state.uploadProgress} total={100} label="Caricamento pdf in corso" onCancel={() => {
                    if (this.cancelUpload) {
                        this.cancelUpload();
                    }
                }} show={this.state.isUploading} />
            </Fragment>
        );
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Pdf);
