import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"
import XSelect from 'components/XSelect';
import "../components/XReactDataGrid.css";
import XMessageBox from "components/XMessageBox";
import XNavigation from '../components/XNavigation';
import XOXLoader from 'components/XOXLoader';
import Ring from '../components/Icons/Ring';
import Grid from "@material-ui/core/Grid";
import { RemoveShoppingCart, Store } from '@material-ui/icons';
import { ArrowLeft, Check } from 'mdi-material-ui';



export class BOPS extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
        this.grdBOP = React.createRef();
        this.state = {
            view: "BOPS",
            isLoading: false,
            updateStores: false,
            storeData: {
                FIDStore: 0,
                FIDStoreArea: 0,
                Status: 0
            },
        };
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/BOP');
    }
    handleUpdateStores = () => {
        this.setState({
            updateStores: true
        })
    }
    handleUpdateStoresCancel = () => {
        this.setState({
            updateStores: false,
            storeData: {
                FIDStore: 0,
                FIDStoreArea: 0,
                Status: 0
            },
        })
    }
    handleUpdateStoresConfirm = () => {
        var { storeData } = this.state;
        if (!storeData.FIDStore || !storeData.FIDStoreArea || !storeData.Status) {
            this.MessageBox.current.showMessageOk("AGGIORNAMENTO STORE/STORE AREA", "Selezionare store, store area e stato per procedere con l'aggiornamento.");
        }
        else {
            this.MessageBox.current.showMessageYesNo("CONFERMA AGGIORNAMENTO STORE/STORE AREA", "Si desidera confermare l'aggiornamento store per le distinte selezionate?", this.doUpdateStores);
        }
    }
    onStoreSelectChange = (key) => (event, data) => {
        var { storeData } = this.state;

        if (data.length > 0) {
            storeData[key] = data[0].ID;
        }

        if (["FIDStore","FIDStoreArea"].includes(key))
            storeData = this.filterStoreAreas(data[0], storeData, key);

        this.setState({storeData});
    }
    filterStoreAreas = (data, db, key) => {
        if ("FIDStoreArea" === key && data && data.FIDStore > 0) {
            db["FIDStore"] = data.FIDStore;
        }

        if ("FIDStore" === key) {
            var storeareas = data && data.FIDStoreAreas ? data.FIDStoreAreas.split(",") : [];

            if (storeareas.length === 1) {
                db["FIDStoreArea"] = parseInt(storeareas[0]);
            } else {
                db["FIDStoreArea"] = undefined;
            }
        }
        return db;
    }
    doUpdateStores = () => {
        var gridFilters = this.grdBOP.current.state.filters;
        var { storeData } = this.state;
        var FIDStore = storeData.FIDStore;
        var FIDStoreArea = storeData.FIDStoreArea;
        var StoreStatus = storeData.Status;
        var bops = this.grdBOP.current.getSelectedData();
        this.setState({ isLoading: true }, () => {
            fetch(`/BOP/UpdateBOPStores?FIDStore=${FIDStore}&FIDStoreArea=${FIDStoreArea}&StoreStatus=${StoreStatus}`, {
                method: "POST",
                body: JSON.stringify(bops)
            }).then(res => res.json())
                .then(data => {
                    if (!data.result) {
                        this.MessageBox.current.showMessageOk("AGGIORNAMENTO STORE/STORE AREA", data.msg ? data.msg : "Aggiornamento fallito", () => { this.setState({ isLoading: false })});
                    } else {
                        this.setState({
                            isLoading: false,
                            updateStores: false,
                            storeData: {
                                FIDStore: 0,
                                FIDStoreArea: 0,
                                Status: 0
                            }
                        },() => { 
                            this.grdBOP.current.refresh(gridFilters) 
                        })
                    }
                    if (data.log) {
                        console.log(data.log);
                    }
                })
        });
    }
    handleRemoveStores = () => {
        this.MessageBox.current.showMessageYesNo("CONFERMA RIMOZIONE STORE/STORE AREA", "Si desidera rimuovere store e store area dalle distinte selezionate?", this.doRemoveStores);
    }
    doRemoveStores = () => {
        var gridFilters = this.grdBOP.current.state.filters;
        var bops = this.grdBOP.current.getSelectedData();
        this.setState({ isLoading: true }, () => {
            fetch('/BOP/DeleteBOPStores', {
                method: "POST",
                body: JSON.stringify(bops)
            }).then(res => res.json())
            .then(data => {
                if (!data.result) {
                    this.MessageBox.current.showMessageOk("RIMOZIONE STORE/STORE AREA", data.msg ? data.msg : "IMpossibile completare la rimozione degli store.", () => { this.setState({ isLoading: false })});
                } else {
                    this.setState({
                        isLoading: false,
                    },() => { 
                        this.grdBOP.current.refresh(gridFilters) 
                    })
                }
                if (data.log) {
                    console.log(data.log);
                }
            })
        })
    }
    showBOPS = () => {
        this.setState({ view: "BOPS" });
    }
    showBOPSINCOMPLETE = () => {
        this.setState({ view: "BOPSINCOMPLETE" });
    }
    render() {
        var { width } = this.props;
        var { view, updateStores, storeData, isLoading } = this.state;

        if (isLoading) {
            return (<XOXLoader />);
        }

        var key = "tblBOP";
        var gridName = "tblBOP";
        var dataRoute = "/BOP/GetData?grid=tblBOP";

        if (view === "BOPSINCOMPLETE") {
            key = "tblBOPIncomplete";
            dataRoute = "/BOP/GetDataIncomplete?grid=tblBOP";
        }
        var actions = [];
        actions.push({ name: "Distinte", icon: <Ring />, action: this.showBOPS, selected: view === "BOPS" });
        actions.push({ name: "Distinte da completare", icon: <Ring />, action: this.showBOPSINCOMPLETE, selected: view === "BOPSINCOMPLETE" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <span><XNavigation actions={actions} direction={direction} showLabel /></span>;
        
        var gridActions = [
            {
                group: 'actions',
                name: 'Rimuovi store',
                icon: <RemoveShoppingCart />,
                action: this.handleRemoveStores,
                condition: r => r.length > 0 && !updateStores && r.filter(row => this.grdBOP.current.getRowData(row).FIDStore > 0).length > 0
            },
            {
                group: 'actions',
                name: 'Aggiorna store',
                icon: <Store />,
                action: this.handleUpdateStores,
                condition: r => r.length > 0 && !updateStores
            }
        ];

        var customContent = undefined;

        if (updateStores) {
            customContent = (<Grid container spacing={2} style={{ width: "100%", padding: "20px" }}>
                    <Grid item xs={12} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            <Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} label="Store" id="FIDStore" urlData="/GC/stores"  value={storeData.FIDStore} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></ Grid>
                            <Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} label="Area" id="FIDStoreArea" urlData="/GC/storeareas" value={storeData.FIDStoreArea} filters={storeData.FIDStore ? [ { key: "FIDStore", value: storeData.FIDStore } ] : undefined} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                            <Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} label="Stato" id="Status" urlData="/enums/storepublishtype" value={storeData.Status} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>);
            
            gridActions.push({
                group: 'actions',
                name: 'Conferma',
                icon: <Check />,
                action: this.handleUpdateStoresConfirm
            });
            gridActions.push({
                group: 'actions',
                name: 'Annulla',
                icon: <ArrowLeft />,
                action: this.handleUpdateStoresCancel
            })
        }

        return (
            <Fragment>
            <XGrid
                innerRef={this.grdBOP}
                usePrimaryColor={true}
                key={key}
                dataRoute={dataRoute}
                dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                dataRouteDelete={customContent ? undefined : "/BOP/DeleteBOP/"}
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={customContent ? undefined :this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
                label={label}
                actions={gridActions}
                customContent={customContent}
                showLoader
            />
            <XMessageBox innerRef={this.MessageBox} />
            </Fragment>
        );
    }
}
