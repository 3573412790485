import React, { Component, Fragment } from 'react';
import XField from 'components/XField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import XMessageBox from 'components/XMessageBox';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import XSwitch from '../../components/XSwitch';
import XGallery from 'components/XGallery';
import XOXLoader from 'components/XOXLoader';

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: "#fff",
		fontSize: '12px',
		position: 'relative',
		width: "100%"
	},
	mainData: {
		width: "95%",
		margin: "0 auto",
		boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
		marginBottom: "10px"
	},
	componentWrap: {
		opacity: "0.6",
		width: "95%",
		margin: "0 auto",
		border: "1px solid rgba(128,128,128,0.7)",
		borderBottom: 0,
		"&:last-of-type": {
			borderBottom: "1px solid rgba(128,128,128,0.7)",
			marginBottom: "20px"
		}
	},
	componentActive: {
		opacity: 1,
		border: "1px solid gray",
	},
	activateComponent: {
		margin: "0 auto"
	},
	contentData: {
		paddingTop: "30px !important"
	}
})

class ProductionComponentsSelector extends Component {
	constructor(props) {
		super(props);
		this.MessageBox = React.createRef();
		this.state = {
			bop: undefined,
			bopPosition: undefined,
		}
	}
	componentDidMount = () => {
		var { bop, bopPosition, mode } = this.props;
		var fetchUrl = undefined;

		if (bop) {

			if (!bop.FIDBOP)
				bop.FIDBOP = bop.ID;

			bop.Qty1Edit = bop.Qty1Remaining || bop.Qty1;
			bop.Qty2Edit = bop.Qty2Remaining || bop.Qty2;

			if (!bop.Qty1Remaining)
				bop.Qty1Remaining = bop.Qty1;

			if (!bop.Qty2Remaining && bop.Qty2 != null)
				bop.Qty2Remaining = bop.Qty2;

			bop.components = [];
			this.setState({
				bop,
				//components: data
			});
			fetchUrl = '/Production/GetBOPGComponents?FIDBOP=' + bop.FIDBOP + "&rowQuantity=" + bop.Qty1;

			//if (bop.Qty2Remaining != null)
			//	fetchUrl += '&rowQuantity2=' + bop.Qty2;

			if (bop.OrderRowCode)
				fetchUrl += '&orderRowCode=' + bop.OrderRowCode;
		}
		else if (bopPosition) {
			bopPosition.Qty1Edit = mode === "activation" ? (bopPosition.Qty1Disp || bopPosition.Qty1) : 0;
			bopPosition.Qty2Edit = mode === "activation" ? (bopPosition.Qty2Disp || bopPosition.Qty2) : 0;
			bopPosition.Qty1Remaining = (bopPosition.Qty1Disp || bopPosition.Qty1);
			bopPosition.Qty2Remaining = (bopPosition.Qty2Disp || bopPosition.Qty2);
			bopPosition.TotalBopQty1 = bopPosition.MeasureUnitQty || bopPosition.BopQuantity1;
			bopPosition.TotalBopQty2 = bopPosition.SecondaryMeasureUnitQty || bopPosition.BopQuantity2;
			bopPosition.UM1 = bopPosition.MeasureUnit1 || bopPosition.UM1;
			bopPosition.UM2 = bopPosition.MeasureUnit2 || bopPosition.UM2;
			bopPosition.Image = bopPosition.FileURL && bopPosition.FileURL.split("?id=").length > 1 ? bopPosition.FileURL.split("?id=")[1] : bopPosition.Image;
			this.setState({
				bopPosition,
				//components: data
			});
			fetchUrl = '/Production/GetBOPSComponents?FIDBOPPosition=' + bopPosition.FIDBOPPosition + '&bopProductionQty=' + (mode === "activation" ? bopPosition.Qty1Edit : bopPosition.Qty1) + '&totalBopQty=' + bopPosition.TotalBopQty1 + (mode === "split" ? "&onlyActive=1" : "");
		}

		fetch(fetchUrl).then(res => res.json())
			.then(data => {
				var explodedComps = [];
				var compsExcludeWeight = 0;
				var components = data.map(item => {
					if (item.Exploded)
						explodedComps.push(item);

					item.InitialQty1 = item.Qty1;
					item.StartActive = item.Active;

					if (mode === "split")
						item.SplittedQty1 = 0;

					if (bop && !item.Exploded) {
						if (item.UM1_Weight)
							compsExcludeWeight += Math.round((item.Qty1 / parseFloat(item.UM1_Conversion)) * 100) / 100;
						else if (item.UM2_Weight)
							compsExcludeWeight += Math.round((item.Qty2 / parseFloat(item.UM2_Conversion)) * 100) / 100;
					}
					if (item.Qty2 != null) {
						item.InitialQty2 = item.Qty2;
						if (mode === "split")
							item.SplittedQty2 = 0;
					}

					return item;
				});

				if (explodedComps.length === 1) {
					explodedComps[0].Qty1 = explodedComps[0].InitialQty1 = bop && bop.Qty2Remaining ? Math.round((bop.Qty2Remaining - compsExcludeWeight) * 100) / 100 : explodedComps[0].Qty1
				}

				if (bop)
					bop.components = components;
				if (bopPosition)
					bopPosition.components = components;

				this.setState({
					bop,
					bopPosition
				})
			});
	}
	componentDidUpdate = (prevProps, prevState) => {
		var { bop, bopPosition, mode } = this.props;
		var fetchUrl = "";
		if ((bop && prevProps.bop && bop.ID != prevProps.bop.ID) || (bopPosition && prevProps.bopPosition && bopPosition.FIDBOPPosition != prevProps.bopPosition.FIDBOPPosition)) {
			if (bop) {
				if (!bop.FIDBOP)
					bop.FIDBOP = bop.ID;

				bop.Qty1Edit = bop.Qty1Remaining;
				bop.Qty2Edit = bop.Qty2Remaining;
				fetchUrl = '/Production/GetBOPGComponents?FIDBOP=' + bop.FIDBOP + "&rowQuantity=" + bop.Qty1Remaining;
				this.setState({
					bop,
					//components: data
				});
			}
			else if (bopPosition) {
				if (!bopPosition.Qty1)
					bopPosition.Qty1 = bopPosition.Qty1Disp;

				if (!bopPosition.Qty2)
					bopPosition.Qty2 = bopPosition.Qty2Disp;

				bopPosition.Qty1Edit = bopPosition.Qty1Remaining = mode === "activation" ? bopPosition.Qty1 : 0;
				bopPosition.Qty2Edit = bopPosition.Qty2Remaining = mode === "activation" ? bopPosition.Qty2 : 0;
				bopPosition.UM1 = bopPosition.MeasureUnit1 || bopPosition.UM1;
				bopPosition.UM2 = bopPosition.MeasureUnit2 || bopPosition.UM2;
				bopPosition.Image = bopPosition.FileURL && bopPosition.FileURL.split("?id=").length > 1 && bopPosition.FileURL.split("?id=")[1];
				this.setState({
					bopPosition,
					//components: data
				});
				fetchUrl = '/Production/GetBOPSComponents?FIDBOPPosition=' + bopPosition.FIDBOPPosition;
			}

			if ((bop && (!bop.components || bop.components.length === 0)) || (bopPosition && (!bopPosition.components || bopPosition.components.length === 0))) {
				fetch(fetchUrl).then(res => res.json())
					.then(data => {
						var components = data.map(item => {
							item.InitialQty1 = item.Qty1;
							if (mode === "split")
								item.SplittedQty1 = 0;

							if (item.Qty2 != null) {
								item.InitialQty2 = item.Qty2;
								if (mode === "split")
									item.SplittedQty2 = 0;
							}

							return item;
						});
						if (bop)
							bop.components = components;
						if (bopPosition)
							bopPosition.components = components;

						this.setState({
							bop,
							bopPosition
						})
					});
			}
		}
	}
	bopQtyKeyDown = (id) => (event) => {
		//if (event.keyCode === 38 || event.keyCode === 40)
		//	return;
		//
		//if (event.keyCode === 110 || event.keyCode === 190) {
		//	var bopQtyInput = document.getElementById(id);
		//
		//	bopQtyInput.value = bopQtyInput.value + ',';
		//}

		return;
	}
	newChangeBOPQuantity = (id) => (event, value) => {
		var { mode } = this.props;

		var bopKey = "bop";
		if(!this.state.bop){
			bopKey = "bopPosition";
		}
		this.setState(state => {
			state[bopKey][id] = value;


			if(id === "Qty1Edit" && mode === "split"){
				if(!value) {
					value = 0;
				}
				var intValue = parseInt(value);
				var multiplier = state[bopKey].Qty2 / state[bopKey].Qty1;
				state[bopKey].Qty1Remaining = state[bopKey].Qty1 - intValue
				state[bopKey].Qty2Edit = Math.round(multiplier * intValue * 100) / 100;
				state[bopKey].Qty2Remaining = Math.round((state[bopKey].Qty2 - state[bopKey].Qty2Edit) * 100) / 100;

				var compMultiplier1 = intValue / state[bopKey].Qty1;
				var compMultiplier2 = state[bopKey].Qty2Edit / state[bopKey].Qty2;
				for(var i in state[bopKey].components){
					var component = state[bopKey].components[i];
					component.SplittedQty1 = Math.round((component.InitialQty1 * compMultiplier1) * 1000) / 1000;

					component.Qty1 = Math.round((component.InitialQty1 - component.SplittedQty1) * 1000) / 1000;

					if (component.Qty2 != null) {
						component.SplittedQty2 = Math.round(((component.SplittedQty2 ? component.SplittedQty2 : component.InitialQty2) * compMultiplier1) * 1000) / 1000;
						component.Qty2 = Math.round((component.InitialQty2 - component.SplittedQty2) * 1000) / 1000;
					}

					state[bopKey].components[i] = component;
				}
			}
			else if(id === "Qty1Edit"){
				if(!value) {
					value = 0;
				}
				var intValue = parseInt(value);
				var multiplier = intValue / state[bopKey].Qty1Remaining;
				state[bopKey].Qty2Edit = Math.round(multiplier * state[bopKey].Qty2Remaining * 100) / 100;

				for(var i in state[bopKey].components){
					var component = state[bopKey].components[i];
					component.Qty1 = Math.round(multiplier*component.InitialQty1*1000)/1000;
					if (component.Qty2 != null){
						component.Qty2 = Math.round(multiplier * component.InitialQty2 * 1000)/1000;
					}
					state[bopKey].components[i] = component;

				}
			}
			else if(id === "Qty2Edit" && mode === "split"){
				if(!value) {
					value = 0;
				}
				var floatValue = parseFloat(value);
				
				state[bopKey].Qty2Remaining = Math.round((state[bopKey].Qty2 - floatValue) * 100) / 100;

				var compMultiplier2 = state[bopKey].Qty2Edit / state[bopKey].Qty2;
				
				for(var i in state[bopKey].components){
					var component = state[bopKey].components[i];
					if(component.UM1_Weight){
						component.SplittedQty1 = Math.round((component.InitialQty1 * compMultiplier2) * 1000) / 1000;
						component.Qty1 = Math.round((component.InitialQty1 - component.SplittedQty1) * 1000) / 1000;
					}
					if(component.UM2_Weight){
						component.SplittedQty2 = Math.round((component.InitialQty2 * compMultiplier2) * 1000) / 1000;
						component.Qty2 = Math.round((component.InitialQty2 - component.SplittedQty2) * 1000) / 1000;
					}

					state[bopKey].components[i] = component;
				}

			}
			else if(id === "Qty2Edit") {
				if(!value) {
					value = 0;
				}
				var floatValue = parseFloat(value);
				state.bopWeightEdit = true;
				if(state[bopKey].components && state[bopKey].components.length > 0){
					var explodedComponentsCount = state[bopKey].components.filter(i => i.Exploded).length;
					var compsExcludeWeight = state[bopKey].components.filter(i => !i.Exploded && i.Active).length > 0 ? 
						state[bopKey].components.filter(i => !i.Exploded && i.Active).map(currentValue => {
						if(currentValue.UM1_Weight){
							return Math.round((currentValue.Qty1 / parseFloat(currentValue.UM1_Conversion))*100)/100;
						}
						else if(currentValue.UM2_Weight){
							return Math.round((currentValue.Qty2 / parseFloat(currentValue.UM2_Conversion))*100)/100;
						}
						return 0;
					}).reduce((result, value) => result + value) : 0;
					var compQty1 = Math.round(((floatValue / explodedComponentsCount) - compsExcludeWeight) * 100) / 100;
					var validateWeight = compQty1 > 0;

					for(var i in state[bopKey].components){
						var component = state[bopKey].components[i];
						if(component.Exploded){
							component.Qty1 = compQty1;
						}
						state[bopKey].components[i] = component;
					}
				}
			}
			else if(id === "Qty1Remaining"){
				if(!value) {
					value = 0;
				}
				var intValue = parseInt(value);
				var multiplier = state[bopKey].Qty2 / state[bopKey].Qty1;
				state[bopKey].Qty1Edit = state[bopKey].Qty1 - state[bopKey].Qty1Remaining
				state[bopKey].Qty2Remaining = Math.round(multiplier * state[bopKey].Qty1Remaining * 100) / 100;
				state[bopKey].Qty2Edit = Math.round((state[bopKey].Qty2 - state[bopKey].Qty2Remaining) * 100) / 100;
				
				var compMultiplier = state[bopKey].Qty1Edit / state[bopKey].Qty1;
				for(var i in state[bopKey].components){
					var component = state[bopKey].components[i];
					component.SplittedQty1 = Math.round((component.InitialQty1 * compMultiplier) * 1000) / 1000;
					component.Qty1 = Math.round((component.InitialQty1 - component.SplittedQty1) * 1000) / 1000;
					if (component.Qty2 != null) {
						component.SplittedQty2 = Math.round(((component.SplittedQty2 ? component.SplittedQty2 : component.InitialQty2) * compMultiplier) * 1000) / 1000;
						component.Qty2 = Math.round((component.InitialQty2 - component.SplittedQty2) * 1000) / 1000;
					}

					state[bopKey].components[i] = component;
				}
			}

			return state;
		});
	}
	changeBOPQuantity = (id) => (event, value) => {
		//if (event && event.type === "keydown") {
		//	
		//}

		var { mode } = this.props;
		var { bop, bopPosition/*, components, activeComponents*/ } = this.state;

		var stock = !!bopPosition;

		bop = bop || bopPosition;

		var activeComponents = bop.components;

		if (value === "") {
			if (id === "bop_qty1_edit")
				bop.Qty1Edit = "";
			else
				bop.Qty2Edit = "";

			if (mode === "split") {
				bop.Qty1Remaining = bop.Qty1;
				bop.Qty2Remaining = bop.Qty2;
			}

			this.setState({
				bop,
				//components: components
			}, () => {
				this.props.onChange && this.props.onChange(bop);
			})
			return;
		}

		if (mode === "split") {
			if (id === "bop_qty1_edit") {
				if (value > bop.Qty1 || value < 0)
					return;

				var multiplier = bop.Qty1Edit > 0 ? parseInt(value) / bop.Qty1Edit : parseInt(value) / bop.Qty1;

				bop.Qty1Edit = parseInt(value);
				bop.Qty1Remaining = bop.Qty1 - bop.Qty1Edit
				bop.Qty2Edit = Math.round(multiplier * bop.Qty2Edit * 100) / 100;
				bop.Qty2Remaining = Math.round((bop.Qty2 - bop.Qty2Edit) * 100) / 100;

				activeComponents && activeComponents.length > 0 && activeComponents.forEach(function (component) {
					if (!component.SplittedQty1)
						component.SplittedQty1 = Math.round((component.InitialQty1 * multiplier) * 1000) / 1000;
					else
						component.SplittedQty1 = Math.round((component.SplittedQty1 * multiplier) * 1000) / 1000;

					component.Qty1 = Math.round((component.InitialQty1 - component.SplittedQty1) * 1000) / 1000;

					if (component.Qty2 != null) {
						component.SplittedQty2 = Math.round(((component.SplittedQty2 ? component.SplittedQty2 : component.InitialQty2) * multiplier) * 1000) / 1000;
						component.Qty2 = Math.round((component.InitialQty2 - component.SplittedQty2) * 1000) / 1000;
					}
				});

			} else if (id === "bop_qty1") {
				if (value > bop.Qty1 || value < 0)
					return;

				var multiplier = bop.Qty1Remaining > 0 ? parseInt(value) / bop.Qty1Remaining : parseInt(value) / bop.Qty1;

				bop.Qty1Remaining = parseInt(value);
				bop.Qty1Edit = bop.Qty1 - bop.Qty1Remaining;
				bop.Qty2Remaining = Math.round(multiplier * bop.Qty2Remaining * 100) / 100;
				bop.Qty2Edit = Math.round((bop.Qty2 - bop.Qty2Remaining) * 100) / 100;

				activeComponents && activeComponents.length > 0 && activeComponents.forEach(function (component) {
					if (!component.Qty1)
						component.Qty1 = Math.round((component.InitialQty1 * multiplier) * 1000) / 1000;
					else
						component.Qty1 = Math.round((component.Qty1 * multiplier) * 1000) / 1000;

					component.SplittedQty1 = Math.round((component.InitialQty1 - component.Qty1) * 1000) / 1000;

					if (component.Qty2 != null) {
						component.Qty2 = Math.round(((component.Qty2 ? component.Qty2 : component.InitialQty2) * multiplier) * 1000) / 1000;
						component.SplittedQty2 = Math.round((component.InitialQty2 - component.Qty2) * 1000) / 1000;
					}
				});

			} else if (id === "bop_qty2_edit") {
				if (value > bop.Qty2 || value < 0)
					return;

			} else {
				if (value > bop.Qty2 || value < 0)
					return;
			}
		}
		else if (id === "bop_qty1") {
			if (value > bop.Qty1Remaining || value < 0)
				return;
			var multiplier = parseInt(value) / bop.Qty1Remaining;

			bop.Qty1Edit = parseInt(value);
			bop.Qty2Edit = Math.round(multiplier * bop.Qty2Remaining * 100) / 100;

			activeComponents && activeComponents.length > 0 && activeComponents.forEach(function (component) {
				component.Qty1 = Math.round(multiplier*component.InitialQty1*1000)/1000;

				if (component.Qty2 != null)
					component.Qty2 = Math.round(multiplier * component.InitialQty2 * 1000)/1000;
			});
		} else {
			if (value > bop.Qty2Remaining || value < 0)
				return;
			
			//value = parseFloat(value);
			var explodedComponentsCount = 0;
			var compsExcludeWeight = 0;
			activeComponents && activeComponents.length > 0 && activeComponents.forEach(function (component) {
				if (component.Exploded)
					explodedComponentsCount++;
				else if (component.Active) {
					if (component.UM1_Weight)
						compsExcludeWeight += Math.round((component.Qty1 / parseFloat(component.UM1_Conversion))*100)/100;
					else if (component.UM2_Weight)
						compsExcludeWeight += Math.round((component.Qty2 / parseFloat(component.UM2_Conversion)) * 100) / 100;
				}
			});
			var validateWeight = true;
			activeComponents && activeComponents.length > 0 && activeComponents.forEach(function (component) {
				if (component.Exploded) {

					if (value === "")
						component.Qty1 = "";

					var compQty1 = Math.round(((parseFloat(value) / explodedComponentsCount) - compsExcludeWeight) * 100) / 100;

					if (compQty1 < 0) {
						validateWeight = false;
						return;
					}
					component.Qty1 = compQty1;
					//originalComponent.Qty1 -= parseFloat(value) / explodedComponentsCount;
				}
			}, this);

			var bopQty2 = 0;
			if (!validateWeight) {
				this.MessageBox.current.showMessageOk("VERIFICA QUANTITA'", "Il peso digitato è inferiore alla quantità minima prevista", () => {
					activeComponents.forEach((item) => {
						if (!item || !item.Active)
							return;

						if (!item.SplittedQty1 && item.SplittedQty1 != 0)
							item.SplittedQty1 = 0;

						if (item.Qty2 != null && !item.SplittedQty2 && item.SplittedQty2 != 0)
							item.SplittedQty2 = 0;

						if (item.Exploded)
							bopQty2 += mode === "split" ? item.SplittedQty1 : item.Qty1;
						else {
							if (item.UM1_Weight) {
								var compWeight = (mode === "split" ? parseFloat(item.SplittedQty1) : parseFloat(item.Qty1)) / parseFloat(item.UM1_Conversion);
								bopQty2 += compWeight ? compWeight : 0;
							}
							else if (item.UM2_Weight) {
								var compWeight = (mode === "split" ? parseFloat(item.SplittedQty2) : parseFloat(item.Qty2)) / parseFloat(item.UM2_Conversion);
								bopQty2 += compWeight ? compWeight : 0;
							}
						}
					});
					bop.Qty2Edit = Math.round(bopQty2 * 100) / 100;

					if (isNaN(bop.Qty2Edit))
						bop.Qty2Edit = 0;

					if (mode === "split") {
						bop.Qty2Remaining = Math.round((bop.Qty2 - bop.Qty2Edit) * 100) / 100;

						if (bop.Qty2Remaining < 0)
							bop.Qty2Remaining = 0;
					}
					this.MessageBox.current.hideDialog();
					this.setState({ bop });
				});
				return;
			}
		}

		bop.Qty2Edit = value;
		bop.components = activeComponents;

		if (!stock) {
			this.setState({
				bop,
				//components,
				//activeComponents
			}, () => {
				this.props.onChange && this.props.onChange(bop);
			})
		} else {
			this.setState({
				bopPosition: bop,
				//components,
				//activeComponents
			}, () => {
				this.props.onChange && this.props.onChange(bop);
			})
		}
	}
	changeComponentQty = (id) => (event, value) => {
		if (event && event.type === "keydown") {
			if (event.keyCode === 38 || event.keyCode === 40)
				return;

			if (event.keyCode === 110 || event.keyCode === 190) {
				var bopQtyInput = document.getElementById(id);
				bopQtyInput.value = bopQtyInput.value + ',';
			}
			return;
		}

		if (value < 0) {
			value = 0;
			return;
		}

		var { mode } = this.props;
		var { bop, bopPosition/*, components, activeComponents*/ } = this.state;

		var stock = !!bopPosition;
		bop = bop || bopPosition;
		var activeComponents = bop.components;

		if (mode === "split") {
			if (id.includes("qty1_edit")) {
				var compID = parseInt(id.split("comp_")[1].split("_qty1_edit")[0]);

				var activeComponent = activeComponents.find((comp) => { return comp.ID === compID; });

				if (value > activeComponent.InitialQty1)
					return;				

				activeComponent.SplittedQty1 = value;
				if (value === "" || isNaN(value)) {
					activeComponent.Qty1 = activeComponent.InitialQty1;
					activeComponent.SplittedQty1 = "";
					if (activeComponent.Qty2 !== null) {
						activeComponent.Qty2 = activeComponent.InitialQty2;
						activeComponent.SplittedQty2 = "";
					}
				} else if (typeof value !== "string" || !value.endsWith('.')) {
					if (activeComponent.UM1_Number) {
						activeComponent.Qty1 = activeComponent.InitialQty1 - parseInt(value);
						activeComponent.SplittedQty1 = parseInt(value);
					}
					else {
						activeComponent.Qty1 = Math.round((activeComponent.InitialQty1 - parseFloat(value)) * 10000) / 10000;
						activeComponent.SplittedQty1 = parseFloat(value);
					}

					if (activeComponent.SplittedQty2 != null) {
						var multiplier = activeComponent.InitialQty2 / activeComponent.InitialQty1;
						activeComponent.SplittedQty2 = Math.round((multiplier * activeComponent.SplittedQty1) * 10000) / 10000;
						activeComponent.Qty2 = Math.round((activeComponent.InitialQty2 - activeComponent.SplittedQty2) * 10000) / 	10000;
					}
				}
			} else if (id.includes("qty1")) {
				var compID = parseInt(id.split("comp_")[1].split("_qty1")[0]);

				var activeComponent = activeComponents.find((comp) => { return comp.ID === compID; });

				if (value > activeComponent.InitialQty1 && ! activeComponent.UM1_Weight)
					return;

				activeComponent.Qty1 = value;
				if (value === "" || isNaN(value)) {
					activeComponent.Qty1 = "";
					activeComponent.SplittedQty1 = activeComponent.InitialQty1;

					if (activeComponent.Qty2 !== null) {
						activeComponent.Qty2 = "";
						activeComponent.SplittedQty2 = activeComponent.InitialQty2;
					}
				}
				else if (typeof value !== "string" || !value.endsWith('.')) {
					if (activeComponent.UM1_Number) {
						activeComponent.SplittedQty1 = activeComponent.InitialQty1 - parseInt(value);
						activeComponent.Qty1 = parseInt(value);
					}
					else {
						activeComponent.SplittedQty1 = Math.round((activeComponent.InitialQty1 - parseFloat(value)) * 10000) / 	10000;
						activeComponent.Qty1 = parseFloat(value);
					}

					if (activeComponent.Qty2 != null) {
						var multiplier = activeComponent.InitialQty2 / activeComponent.InitialQty1;
						activeComponent.Qty2 = Math.round(multiplier * activeComponent.Qty1 * 10000) / 10000;
						activeComponent.SplittedQty2 = Math.round((activeComponent.InitialQty2 - activeComponent.Qty2) * 10000) / 	10000;
					}
				}
			} else if (id.includes("qty2_edit")) {
				var compID = parseInt(id.split("comp_")[1].split("_qty2_edit")[0]);

				var activeComponent = activeComponents.find((comp) => { return comp.ID === compID; });

				activeComponent.SplittedQty2 = value;
				if (value === "" || isNaN(value)) {
					activeComponent.SplittedQty2 = "";
					activeComponent.Qty2 = activeComponent.InitialQty2;
				}
				else if (typeof value !== "string" || !value.endsWith('.')) {
					activeComponent.SplittedQty2 = parseFloat(value);

					if (activeComponent.UM2_Number)
						activeComponent.SplittedQty2 = parseInt(value);

					if (activeComponent.InitialQty2 > 0){
						activeComponent.Qty2 = Math.round((activeComponent.InitialQty2 - activeComponent.SplittedQty2) * 10000) / 	10000;
						if(activeComponent.Qty2 <0){
							activeComponent.Qty2 = 0;
						}
					}
				}
			} else {
				var compID = parseInt(id.split("comp_")[1].split("_qty2")[0]);

				var activeComponent = activeComponents.find((comp) => { return comp.ID === compID; });

				if (activeComponent.InitialQty2 === 0)
					return;
				
				activeComponent.Qty2 = value;
				if (value === "" || isNaN(value)) {
					activeComponent.Qty2 = "";
					activeComponent.SplittedQty2 = activeComponent.InitialQty2;
				}
				else if (typeof value !== "string" || !value.endsWith('.')) {
					activeComponent.Qty2 = parseFloat(value);

					if (activeComponent.UM2_Number)
						activeComponent.Qty2 = parseInt(value);

					if (activeComponent.InitialQty2 > 0)
					{
						activeComponent.SplittedQty2 = Math.round((activeComponent.InitialQty2 - activeComponent.Qty2) * 10000) / 	10000;
						if(activeComponent.SplittedQty2 <0){
							activeComponent.SplittedQty2 = 0;
						}
					}
				}
			}
		}
		else if (id.includes("qty1")) {
			var compID = parseInt(id.split("comp_")[1].split("_qty1")[0]);

			var activeComponent = activeComponents.find((comp) => {	return comp.ID === compID; });

			var multiplier = parseFloat(value) / parseFloat(activeComponent.Qty1);
			var oldValue1 = activeComponent.Qty1;
			var oldValue2 = activeComponent.Qty2;
			activeComponent.Qty1 = value;

			// if (activeComponent.UM1_Number)
			// 	activeComponent.Qty1 = parseInt(value);
			// else
			// 	activeComponent.Qty1 = parseFloat(value);
			if(!value){
				value = "0";
			}
			var floatValue = parseFloat(value);

			if (activeComponent.Qty2) {
				activeComponent.Qty2 = Math.round(multiplier * activeComponent.Qty2*10000)/10000;

				if (isNaN(activeComponent.Qty2))
					activeComponent.Qty2 = "";
			}
			var delta = 0;
			if(activeComponent.UM1_Weight){
				delta = (oldValue1 - floatValue) * activeComponent.UM1_Conversion;
			}
			else if(activeComponent.UM2_Weight) {
				delta = (oldValue2 - activeComponent.Qty2) * activeComponent.UM2_Conversion;
			}
			if(delta){
				bop.Qty2Edit -= delta;
			}

			if (activeComponent.Qty2 === 0 || activeComponent.Qty2 === "") {
				if (value > 0) {
					activeComponent.Qty2 = Math.round(multiplier * activeComponent.InitialQty2*10000)/10000;
				}
			}
		}
		else {
			var compID = parseInt(id.split("comp_")[1].split("_qty2")[0]);
			var activeComponent = activeComponents.find((comp) => { return comp.ID === compID; });
			var oldValue2 = activeComponent.Qty2;
			activeComponent.Qty2 = value;

			if(!value){
				value = 0;
			}


			var stringVal = typeof value === "string" ? value.replace(/(,)+/g,'.').replace(/([^\d.-]+)+/g, '') : value;
			if(typeof value === "string" && stringVal.endsWith(".")){
				stringVal += '0';
			}
			var floatValue = parseFloat(stringVal);

			//if (activeComponent.SplittedQty2 != null) {
				//activeComponent.SplittedQty2 = Math.round((activeComponent.SplittedQty2 + (activeComponent.Qty2 - parseFloat(value))) * 10000) / 10000;
				//activeComponent.Qty2 = parseFloat(value);
			//}
			var delta = 0;
			if(activeComponent.UM2_Weight) {
				delta = (oldValue2 - floatValue) * activeComponent.UM2_Conversion;
			}
			if(delta){
				bop.Qty2Edit -= delta;
			}
		}
		bop.components = activeComponents;
		if (!stock) {
			this.setState({
				bop,
				//components,
				//activeComponents
			}, () => {
				this.props.onChange && this.props.onChange(bop);
			})
		} else {
			this.setState({
				bopPosition: bop,
				//components,
				//activeComponents
			}, () => {
				this.props.onChange && this.props.onChange(bop);
			})
		}
	}
	toggleComponent = (id) => (event, value) => {
		var { mode } = this.props;
		var { bop, bopPosition } = this.state;

		var bops = bop || bopPosition;
		//var suggQty1 = null;
		//var suggQty2 = null;
		var components = bops.components;
		var component = components.find((comp) => {
			return comp.ID === id;
		});

		//suggQty1 = component.InitialQty1;
		//if (component.Qty2 != null)
		//		suggQty2 = component.InitialQty2;
		
		var multiplier = mode === "split" ? parseInt(bops.Qty1Remaining) / bops.Qty1 : parseInt(bops.Qty1Edit) / bops.Qty1Remaining;
		//var newComp = JSON.parse(JSON.stringify(component));
		
		//newComp.Qty1 = Math.round(multiplier * suggQty1 * 1000) / 1000;
		//component.Qty1 = Math.round(multiplier * suggQty1 * 1000) / 1000;
		
		//if (mode === "split") {
		//	newComp.SplittedQty1 = suggQty1;
		//	newComp.Qty1 = 0;
		//}
		
		//if (component.Qty2 != null) 
		//	component.Qty2 = Math.round(multiplier * suggQty2 * 1000) / 1000;
		
		//	if (mode === "split") {
		//		newComp.SplittedQty2 = suggQty2;
		//		newComp.Qty2 = 0.00;
		//	}
		//}
		
		//if (value) {
		//	newComp.Active = true;
		//	activeComponents.push(newComp);
		//} else {
		//	var compIndex = activeComponents.findIndex(item => {
		//		return item.ID === component.ID;
		//	});
		//	component.Active = false;
		//	activeComponents.splice(compIndex, 1);
		//}
		component.Active = value;

		if (component.Active) {
			if (mode === "split") {
				component.SplittedQty1 = parseFloat(Math.round((multiplier * component.InitialQty1) * 10000) / 10000);

				if (component.UM1_Number)
					component.SplittedQty1 = parseInt(component.SplittedQty1);

				component.Qty1 = component.InitialQty1 - component.SplittedQty1;

				if (component.Qty2 != null) {
					component.SplittedQty2 = parseFloat(Math.round((multiplier * component.InitialQty2) * 10000) / 10000);
					component.Qty2 = component.InitialQty2 - component.SplittedQty2;
				}
			}
			else {
				component.Qty1 = parseFloat(Math.round((multiplier * component.InitialQty1) * 10000) / 10000);

				if (component.UM1_Number)
					component.Qty1 = parseInt(component.Qty1);

				if (component.Qty2 != null)
					component.Qty2 = parseFloat(Math.round((multiplier * component.InitialQty2) * 10000) / 10000);
			}
		}

		if (bop)
			bop.components = components;
		else if (bopPosition)
			bopPosition.components = components;

		this.setState({
			bop,
			bopPosition,
			//activeComponents
		}, () => {
			this.props.onChange && this.props.onChange(bops);
		});
	}


	renderMainData = () => {
		var { classes, mode } = this.props;
		var { bop, bopPosition, bopWeightEdit } = this.state;

		bop = bop || bopPosition;

		//var activeComponents = bop.components.map(item => { if (item && item.Active) return item; });
		var activeComponents = bop.components.filter(item => item && item.Active);
		var weightActive = activeComponents && activeComponents.length > 0 ? activeComponents.find((comp) => {
			return !!comp && !!comp.Exploded;
		}) : false;

		if (bop.Qty2Edit !== "" && !bopWeightEdit) {
			var bopQty2 = 0;
				activeComponents.forEach(item => {
					if (!item || !item.Active)
						return;
	
					// if (!item.SplittedQty1 && item.SplittedQty1 != 0)
					// 	item.SplittedQty1 = 0;
	
					// if (item.Qty2 != null && !item.SplittedQty2 && item.SplittedQty2 != 0)
					// 	item.SplittedQty2 = 0;
	
					if (item.Exploded)
						bopQty2 += mode === "split" ? item.SplittedQty1 : item.Qty1;
					else {
						if (item.UM1_Weight) {
							var compWeight = (mode === "split" ? parseFloat(item.SplittedQty1) : parseFloat(item.Qty1)) / parseFloat(item.UM1_Conversion);
							bopQty2 += compWeight ? compWeight : 0;
						}
						else if (item.UM2_Weight) {
							var compWeight = (mode === "split" ? parseFloat(item.SplittedQty2) : parseFloat(item.Qty2)) / parseFloat(item.UM2_Conversion);
							bopQty2 += compWeight ? compWeight : 0;
						}
					}
				});
				bop.Qty2Edit = Math.round(bopQty2 * 100) / 100;
			}
	
			if (mode === "split") {
				bop.Qty2Remaining = Math.round((bop.Qty2 - bop.Qty2Edit) * 100) / 100;
	
				if (bop.Qty2Remaining < 0)
					bop.Qty2Remaining = 0;
			}

		return (
			<Grid className={classes.mainData} container direction="row" spacing={2}>
				<Grid item xs={2}>
					{bop && bop.ImageID && <XGallery bigImage={false} imageIds={[bop.ImageID]} />}
				</Grid>
				<Grid className={classes.contentData} container direction="row" item xs={mode === "split" ? 6 : 9}>
					<Grid item direction="column" xs={12}>
						<Grid container direction="row" xs={10} spacing={2}>
							<Grid item xs={6}><Typography>Codice: {bop.Code}</Typography></Grid>
							<Grid item xs={6}><Typography>Descrizione: {bop.Description}</Typography></Grid>
						</Grid>
						<Grid container direction="row" xs={10} spacing={2}>
							<Fragment>
								<Grid item xs={3}><span>&nbsp;</span></Grid>
								<Grid item xs={3}><span>&nbsp;</span></Grid>
								<Grid item xs={2}>
									<XField 
										customFormatNumber 
										preventNegativeValue 
										decimalScale={0} 
										fontSize={12} 
										field={{ Required: 2 }} 
										label="Quantità 1" 
										type="number" 
										value={mode === "split" ? bop.Qty1Remaining : bop.Qty1Edit} 
										minValue={0} 
										maxValue={bop.Qty1} 
										id={mode === "split" ? "Qty1Remaining" : "Qty1Edit" }
										caption={bop.UM1} 
										onChange={this.newChangeBOPQuantity} 
										onKeyDown={this.bopQtyKeyDown} 
									/>
								</Grid>
								<Grid item xs={2}><span>&nbsp;</span></Grid>
							</Fragment>
							{bop.Qty2Edit != null &&
								<Fragment>
									<Grid item xs={2}>
										<XField 
											customFormatNumber 
											preventNegativeValue 
											decimalScale={3} 
											readOnly={!weightActive} 
											fontSize={12} 
											field={{ Required: 2 }} 
											label="Quantità 2" 
											type="number" 
											value={mode === "split" ? bop.Qty2Remaining : bop.Qty2Edit} 
											minValue={0} 
											//maxValue={bop.Qty2} 
											id={mode === "split" ? "Qty2Remaining" : "Qty2Edit" }
											caption={bop.UM2} 
											onChange={this.newChangeBOPQuantity} 
											onKeyDown={this.bopQtyKeyDown} 
											onBlur={(id) => (event) => {this.setState({ bopWeightEdit: false })}}
										/>
									</Grid>
								</Fragment>
							}
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={1}>
				</Grid>
				{mode === "split" && <Grid container className={classes.contentData} direction="row" item xs={3} spacing={2}>
					<Grid item xs={6} sm={3}>
						<XField 
							customFormatNumber 
							preventNegativeValue 
							decimalScale={0} 
							fontSize={12} 
							field={{ Required: 2 }} 
							label="Quantità 1" 
							type="number" 
							value={bop.Qty1Edit} 
							minValue={0} 
							maxValue={bop.Qty1} 
							caption={bop.UM1} 
							id="Qty1Edit" 
							onChange={this.newChangeBOPQuantity} 
							onKeyDown={this.bopQtyKeyDown}
						/>
					</Grid>
					<Grid item xs={6} sm={3}>
						<XField 
							customFormatNumber 
							preventNegativeValue 
							decimalScale={3} 
							readOnly={!weightActive} 
							field={{ Required: 2 }} 
							label="Quantità 2" 
							type="number" 
							value={bop.Qty2Edit} 
							minValue={0} 
							//maxValue={bop.Qty2} 
							caption={bop.UM2} 
							id="Qty2Edit" 
							onChange={this.newChangeBOPQuantity} 
							onKeyDown={this.bopQtyKeyDown} 
						/>
					</Grid>
				</Grid>}
			</Grid>
		);
	}

	render = () => {
		var { classes, mode } = this.props;
		var { bop, bopPosition } = this.state;

		bop = bop || bopPosition;

		if (!bop || !bop.components) {
			return (<XOXLoader />);
		}

		return (
			<div id="main" className={classes.root}>
				<Grid containerID="main" container direction="column" spacing={0}>
					{this.renderMainData()}
					{bop.components.map(item => {
						var componentActive = item.Active;

						var separateQtiesEditor = undefined;

						if (mode === "split") {
							separateQtiesEditor = (<Grid className={classes.contentData} container direction="row" item xs={3} spacing={2}>
								<Grid item xs={3}>
									<XField 
										customFormatNumber 
										preventNegativeValue 
										decimalScale={item.UM1_Number ? 0 : 3} 
										readOnly={!componentActive} 
										field={{ Required: 1 }} 
										label="Quantità 1" 
										type="number" 
										value={item.SplittedQty1} 
										minValue={0} 
										maxValue={item.UM1_Weight ? undefined : item.InitialQty1} 
										id={"comp_" + item.ID + "_qty1_edit"} 
										caption={item.UM1} 
										onChange={this.changeComponentQty} 
										onKeyDown={this.changeComponentQty} 
									/>
								</Grid>
								{item.Qty2 != null && <Grid item xs={3}>
									<XField 
										customFormatNumber 
										preventNegativeValue 
										decimalScale={item.UM2_Number ? 0 : 3} 
										readOnly={!componentActive} 
										field={{ Required: 1 }} 
										label="Quantità 2" 
										type="number" 
										value={item.SplittedQty2} 
										minValue={0} 
										//maxValue={undefined} 
										id={" comp_" + item.ID + "_qty2_edit"} 
										caption={item.UM2} 
										onChange={this.changeComponentQty} 
										onKeyDown={this.changeComponentQty}
									/>
								</Grid>}
							</Grid>);
						}

						var compQties = (
							<Grid container direction="row" xs={10} spacing={2}>
								<Grid item xs={6}><Typography>Quantità 1: {item.InitialQty1} {item.UM1}</Typography></Grid>
								{item.Qty2 != null && <Grid item xs={6}><Typography>Quantità 2: {item.InitialQty2} {item.UM2}</Typography></Grid>}
							</Grid>);

						if (componentActive) {
							compQties = (<Grid container direction="row" xs={10} spacing={2}>
								<Grid item xs={3}><span>&nbsp;</span></Grid>
								<Grid item xs={3}><span>&nbsp;</span></Grid>
								<Grid item xs={2}>
									<XField 
										customFormatNumber 
										preventNegativeValue 
										decimalScale={item.UM1_Number ? 0 : 3} 
										fontSize={12} 
										field={{ Required: 1 }} 
										label="Quantità 1" 
										type="number" 
										value={item.Qty1} 
										minValue={0} 
										maxValue={item.UM1_Weight ? undefined : item.InitialQty1} 
										id={"comp_" + item.ID + "_qty1"} 
										caption={item.UM1} 
										onChange={this.changeComponentQty} 
										onKeyDown={this.changeComponentQty} 
									/>
								</Grid>
								<Grid item xs={2}><span>&nbsp;</span></Grid>
								{item.Qty2 != null && <Fragment><Grid item xs={2}>
									<XField 
										customFormatNumber 
										preventNegativeValue 
										decimalScale={item.UM2_Number ? 0 : 3} 
										fontSize={12} 
										field={{ Required: 1 }} 
										label="Quantità 2" 
										type="number" 
										value={item.Qty2} 
										minValue={0} 
										//maxValue={item.InitialQty2} 
										id={" comp_" + item.ID + "_qty2"} 
										caption={item.UM2} 
										onChange={this.changeComponentQty} 
										onKeyDown={this.changeComponentQty}
									/>
								</Grid></Fragment>}</Grid>);
						}

						var component = (<Grid className={classes.componentWrap + " " + (componentActive ? classes.componentActive : "")} container direction="column"><Grid container item direction="row" spacing={2}>
							<Grid item xs={2}>
								<XGallery bigImage={false} imageIds={[0]} />
							</Grid>
							<Grid className={classes.contentData} container direction="row" item xs={mode === "split" ? 6 : 9}>
								<Grid item direction="column" xs={12}>
									<Grid container direction="row" xs={10} spacing={2}>
										<Grid item xs={6}><Typography>Codice: {item.ComponentCode}</Typography></Grid>
										<Grid item xs={6}><Typography>Descrizione: {item.ComponentDesc}</Typography></Grid>
									</Grid>
									{compQties}
								</Grid>
							</Grid>
							<Grid container item xs={1}>
								<XSwitch classes={{ label: classes.activateComponent }} label="Attivo" labelPlacement="middle" style={{ opacity: 1 }} id={item.ID} value={!!componentActive} disabled={item.StartActive} onChange={this.toggleComponent} />
							</Grid>
							{separateQtiesEditor}
						</Grid></Grid>);
						return component;
					})}
				</Grid>
				<XMessageBox ref={this.MessageBox} />
			</div>
		);
	}
}

const enhance = compose(
	connect(
		state => state.global,
		dispatch => bindActionCreators(globalCreators, dispatch)
	),
	withStyles(styles)
);

export default enhance(ProductionComponentsSelector);