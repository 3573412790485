const GET_TRANSLATIONS = "GET_TRANSLATIONS";
const TRANSLATIONS_SUCCESS = "TRANSLATIONS_SUCCESS";
const TRANSLATIONS_FAILURE = "TRANSLATIONS_FAILURE";

const initialState= {
    translationsLoading: false,
    translations:[]
};

export const getTranslation = (context, code, translations, defaultValue = null, type = 'Form') => {
    if(translations && translations.length > 0) {
        var translation = translations.filter(f => f.Context === context && f.Code === code);
        if(translation.length > 0){
            return translation[0][type];
        }
    }

    //daca defaultValue !== null call la un action prin care inserez recordul in GC_Text, GC_TextT (fetch) -> Parametri context, code, defaultvalue
    if(defaultValue !== null && code && context)
    {
        fetch(`base/InsertDefaultTranslation?itemContext=${context}&code=${code}&defaultValue=${defaultValue}`);
    }

    return defaultValue;
}

export const actionCreators = {
    getTranslations: () => async (dispatch, getState) => {
        dispatch({ type: GET_TRANSLATIONS});
        try {
            const data = await fetch('/Base/GetTranslations/').then(res => res.json());
            dispatch({type: TRANSLATIONS_SUCCESS, data});
        }
        catch {
            dispatch({type: TRANSLATIONS_FAILURE});
        }
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if(action.type === GET_TRANSLATIONS) {
        return { ...state, translations: [], translationsLoading: true };
    }
    if(action.type === TRANSLATIONS_SUCCESS) {
        return { ...state, translations: action.data, translationsLoading: false };
    }
    if(action.type === TRANSLATIONS_FAILURE) {
        return { ...state, translations: [], translationsLoading: false };
    }

    return state;
}