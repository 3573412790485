import React, { Component } from 'react';
import withStyles from 'react-jss';


const cellStyle = {
    root: {
        minWidth: props => props.width,
        maxWidth: props => props.width,
        maxHeight: props => props.rowHeight,//"42px",
        lineHeight: "21px",
        display: "inline-block",
        overflow: "hidden",
        verticalAlign: "middle",
        padding: "1px 5px 1px 20px",
        "&:last-child": {
            //minWidth: props => "calc(100% - " + props.totalWidth + "px)",
            maxWidth: props => (((props.isHeader && props.isLast) || !props.isHeader)) ? "calc(100% - " + (props.totalWidth - props.width) + "px)" : props.width,
            width: "100%",
        },
    }
};

class XGridCell extends Component {
    render() {
        var { column, classes, value, isHeader } = this.props;
        if (value && ! isHeader) {
            switch (column.type) {
                case "date":
                    value = (new Date(value)).toLocaleDateString();
                    break;
                default:
                    break;
            }
        }
        return (
            <span className={classes.root} key={column.key}>{value}</span>
        );
    }
}
export default withStyles(cellStyle)(XGridCell);