import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid";

import XNavigation from 'components/XNavigation';
import { ClipboardArrowLeftOutline, ClipboardArrowLeft, ClipboardArrowRight, ClipboardArrowRightOutline, CartArrowDown, Check } from 'mdi-material-ui';

import XStepper from 'components/XStepper';
import SupplierOrders from './SupplierOrders';

export class DOCStatus extends Component {
    constructor(props) {
        super(props);
        this.grdDOCStatus = React.createRef();
        this.SupplierOrders = React.createRef();
        this.state ={
            view: "RECORD",
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    onShowAllChange =(step) =>{
        this.setState({showAll: step}, this.grdIR.current.getWrappedInstance().refresh);
    }
    onShowActiveClick = (step) => {
        this.setState({ showActive: step}, this.grdIR.current.getWrappedInstance().refresh);
    }
    changeView = (view) => () =>{
        this.setState({view});
    }
    onSupplierSelectionChanged = (count) => {
        this.setState({supplierOrdersSelected: count});
    }
    render() {
        var { view } = this.state;
        var { width } = this.props;
        
        var key = `tblDOCStatus${view}`;
        var FIDCycle = 3;//passivo
        var FIDDocumentSubType = 3;//ordine
        switch(view){
            case "ISSORD":
                FIDCycle=2;
                FIDDocumentSubType = 3;
                break;
            case "RECINV":
                FIDCycle=3;
                FIDDocumentSubType = 4;
                break;
            case "ISSINV":
                FIDCycle=2;
                FIDDocumentSubType = 4;
                break;
        }
        var dataUrl = `/DOC/GetDOCStatus?grid=${key}&FIDCycle=${FIDCycle}&FIDDocumentSubType=${FIDDocumentSubType}`;
        var actions = [];
        actions.push({ name: "Ordini da ricevere", icon: <ClipboardArrowRightOutline />, action: this.changeView("RECORD"), selected: view === "RECORD" });
        actions.push({ name: "Ordini da consegnare", icon: <ClipboardArrowLeftOutline />, action: this.changeView("ISSORD"), selected: view === "ISSORD" });
        actions.push({ name: "Fatture da ricevere", icon: <ClipboardArrowRight />, action: this.changeView("RECINV"), selected: view === "RECINV" });
        actions.push({ name: "Fatture da emettere", icon: <ClipboardArrowLeft />, action: this.changeView("ISSINV"), selected: view === "ISSINV" });
        actions.push({ name: "Ordini a fornitori", icon: <CartArrowDown />, action: this.changeView("SUPLORD"), selected: view === "SUPLORD" });

        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <span><XNavigation actions={actions} direction={direction} showLabel /></span>;
        var customContent = undefined;
        var actions = [];
        if(view === "SUPLORD"){
            
            customContent = <SupplierOrders ref={this.SupplierOrders} onSelectionChanged={this.onSupplierSelectionChanged}/>;
            if(this.state.supplierOrdersSelected > 0){
                actions.push({
                    group: 'actions',
                    name: 'Genera ordini',
                    action: this.SupplierOrders.current && this.SupplierOrders.current.GenerateOrders,
                    icon: <Check />
                })
            }
        }

        return (
            <XGrid
                key={view}
                label={label}
                ref={this.grdDOCStatus}
                usePrimaryColor={true}
                dataRoute={dataUrl}
                dataRouteColumns={`/Base/GetColumns?grid=${key}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${key}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${key}`}
                customContent={customContent}
                actions={actions}
            />
        );
    }
}
