import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { Check, Close } from 'mdi-material-ui';


import XButton from 'components/XButton';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
    },
    stars: {
        fontSize: 40,
        cursor: "pointer",
    },
    marginTop: {
        marginTop: "10px",
    },
    buttonsWrapper: {
        width: "100%",
        "& > *": {
            display: "inline-block",
        }
    },
    catalogueButton2: {
        width: 50,
        height: 50,
        padding: 0,
        marginRight: "10px",
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
});

class AssignActions extends Component {
    state = {
        HoverStar: 0,
    };
    doSave = () => {
        var { onSave } = this.props;
        onSave && onSave();
    }
    doDelete = () => {
        var { onDelete } = this.props;
        onDelete && onDelete();
    }

    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid item container xs={12} className={classes.marginTop}>
                    <div className={classes.buttonsWrapper}>
                        {<Tooltip title={"Salva"}>
                            <XButton className={classes.catalogueButton2} color={"primary"} onClick={this.doSave}><Check /></XButton>
                        </Tooltip>}
                        {/* <Tooltip title={"Elimina"}>
                            <XButton className={classes.catalogueButton2} color={"primary"} onClick={this.doDelete}><Close /></XButton>
                        </Tooltip> */}
                    </div>
                </Grid>
            </div>
        );
    }
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(AssignActions);