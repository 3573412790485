import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Markup extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    checkDeletable = async(id) => {
        return await fetch('/gc/markup/CheckDeletable/' + id).then(res => res.json());
    }

    deleteMarkup = (id) => {
        fetch('/gc/markup/delete/?ids=' + id, { method: "POST" }).then(res => {
            if (res) {
                this.Editor.current.setState({
                    snackbar: {
                        open: true,
                        message: "Formula cancellata correttamente"
                    }
                }, () => {
                    setTimeout(() => {
                        this.Editor.current.doExit();
                    }, 1400);
                })
            } else {
                this.Editor.current.setState({
                    snackbar: {
                        open: true,
                        message: "Impossibile cancellare la formula"
                    }
                })
            }
        })
    }

    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var canDelete = this.checkDeletable(id);

        var label = "Confermi la cancellazione della formula di ricarico?";
        if (!canDelete) {
            label = "La formula è utilizzata nelle tabelle associate(distribuzioni, distinte e documenti), impossibile proseguire nella cancellazione";
            this.Editor.current.showDialog([{
                label: "OK",
                action:() => {
                    this.Editor.current.hideDialog();
                }
            }], "Cancellazione", label);
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteMarkup(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
    }
    render() {
        var fields = [
            {
                key: 'Multiplier',
                label: 'Moltiplicatore',
                type: 'XField',
                fieldType: "number",
                Required: 2
            }
        ];
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/gc/markup/Save/'
            urlRedirect='/markup/'
            urlExit='/Markups/'
            urlNew="/markup/new"
            urlGetData='/gc/markup/'
            urlCheckChanges='/gc/markup/HasChanges/'
            //urlClearSession='/IR/ClearSession/'
            onDeleteClick={this.onDeleteClick}
            translate={false}
            fields={fields}
            AbbreviationKey="Code"
            DescriptionKey="Description"
            //routeTranslatableFields="/bop/brand/translatableFields/"
            //routeLanguages="/bop/brand/languages/"
            //routeDataT="/bop/brand/translations/"
            //routeSaveData="/bop/brand/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Markup);
