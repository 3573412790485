import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Country extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    checkUsed = async (FIDCountry) => {
        return await fetch('/IR/country/IsUsed/' + FIDCountry).then(res => res.json());
        
    }
    deleteBrand = (FIDCountry) => {
        fetch('/IR/country/Delete/' + FIDCountry, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione della nazione?";

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteBrand(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];
        if (isUsed) {
            label = "La nazione è utilizzata. Non è possibile procedere con la cancellazione.";
            buttons = [
                {
                    label: "OK",
                    action: () => {
                        this.Editor.current.hideDialog();
                    }
                },
            ];
        }


        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        var fields = [
            {
                key: 'FIDSdiCode',
                label: 'Codice SDI',
                type: 'XSelect',
                getUrl: '/Base/GetSDICodes?Table=ISO3166',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Description',
                abbreviationKey:'Code',
                Required: 2
            }
        ];
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/IR/country/Save/'
            urlDelete='/IR/country/Delete/'
            urlRedirect='/Country/'
            urlExit='/Countries/'
            urlNew="/Country/new"
            urlGetData='/IR/Country/get/'
            urlCheckChanges='/IR/country/HasChanges/'
            urlClearSession='/IR/ClearSession/'
            onDeleteClick={this.onDeleteClick}
            translate={true}
            fields={fields}
            AbbreviationKey="Abbreviation"
            DescriptionKey="Country"
            //routeTranslatableFields="/bop/brand/translatableFields/"
            //routeLanguages="/bop/brand/languages/"
            //routeDataT="/bop/brand/translations/"
            //routeSaveData="/bop/brand/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Country);
