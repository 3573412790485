import React, { Component } from 'react';
import { withStyles,Tooltip,IconButton, Button,Grid } from '@material-ui/core';
import { ShieldCheck, ArrowLeft, Check, Magnify, Close } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"
import IdentitySelector from '../../components/IdentitySelector';
import { getBOPSearchContent } from '../../utils';
import CatalogueItem from '../../components/CatalogueItem';
import { compose } from 'redux';
import { withWidth } from '@material-ui/core';
import XPDFViewer from 'components/XPDFViewer';

const styles = theme => ({
    root: {
    }
});

class Warranty extends Component {
    constructor(props) {
        super(props);

        this.grdWarranty = React.createRef();
        this.SearchControl = React.createRef();

        this.state = {
            data: [],
            activation: false,
            step: false,
            FIDIdentity: undefined,
            search: false,
            searchValue: undefined,
            tempSearchValue: undefined,
            openItem: false,
            item: {},
            selectedItems: []
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    onWarrantyActivate = () => {
        var selectedRows = this.grdWarranty.current.getWrappedInstance().getSelectedData();
        this.grdWarranty.current.getWrappedInstance().clearSelection();

        this.setState({ activation: true, step: 1, data: selectedRows });

    }
    onCancelIdentitySelection = () => {
        this.setState({ FIDIdentity: undefined, step: false, activation: false });
    }
    onIdentitySelect = (FIDIdentity) => {
        this.setState({ FIDIdentity, step: 2 });
    }
    onSkipIdentity = () => {
        this.setState({ FIDIdentity: undefined, step: 2 });
        //this.doActivate();
    }
    handlePreviousStep = () => {
        this.setState(state => {
            state.step--;
            if (state.step == 0) {
                state.FIDIdentity = undefined;
                state.step = false;
                state.activation = false;
            }
            return state;
        });
    }
    onConfirm = () => {
        this.doActivate();
    }
    doActivate = () => {
        var { data, FIDIdentity } = this.state;
        var url = '/DOC/WarrantyActivation/';
        if (FIDIdentity) {
            url += FIDIdentity;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json()).then(data => {
            
            if (data) {
                var callback = this.grdWarranty.current && this.grdWarranty.current.getWrappedInstance().refresh;
                this.setState({ data: [], activation: false, step: false, FIDIdentity: undefined }, callback);
            }
        });
    }
    onSearch = () => {
        this.setState({ search: true });;
    }
    onSearchCancel = () => {
        this.setState({ search: false, tempSearchValue: "", });
    }
    onSearchConfirm = () => {
        this.doSearchConfirm();
    }
    onSearchBlur = (id) => (event) => {
        this.SearchControl.current.focus();
    }
    onSearchChange = (id) => (event, value) => {
        this.setState({ tempSearchValue: value });
    }
    onSearchKeyDown = (id) => (event, value) => {
        if (event.keyCode === 13) {
            this.doSearchConfirm();
        }
    }

    doSearchConfirm = () => {
        this.setState({ search: false, searchValue: this.state.tempSearchValue, tempSearchValue: undefined }, this.grdWarranty.current.getWrappedInstance().refresh);
    }
    onGridLoaded = (result) => {
        console.log(result);
        if (result.data.length === 1 && result.open) {
            //open
            var data = result.data[0];
            this.setState({ openItem: true, item: data });
        }
    }
    onItemClose = () => {
        this.setState({ openItem: false, item: {} }, this.doSearchConfirm);
    }
    handleGridDoubleClick = (_, data) => {
        this.setState({ openItem: true, item: data });
    }
    onItemsSelected = (items) => {
        console.log(items);
        this.setState({ selectedItems: items });
    }
    cardSelectionView = (isSelected, id) => {
        var color = isSelected ? "secondary" : "primary";
        return (
            <Grid container xs={12}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Button fullWidth variant="contained" color={color} onClick={this.onCardClick(id)}>
                        {isSelected ? "Garanzia attivata" : "Attiva garanzia"}
                    </Button>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        );
    }
    isSelected = (id) => {
        var { selectedItems } = this.state;
        return selectedItems.indexOf(id) !== -1;
    }
    onCardClick = (id) => (event) => {
        var { selectedItems } = this.state;

        const selectedIndex = selectedItems.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1),
            );
        }
        this.setState({ selectedItems: newSelected });
    }
    render() {
        var { activation, step, search, tempSearchValue, openItem, item, selectedItems } = this.state;
        var selectedActions = [];

        var searchAction = <Tooltip title="Cerca">
            <IconButton aria-label="Cerca" onClick={this.onSearch}>
                <Magnify />
            </IconButton>
        </Tooltip>;

        var actions = [searchAction];
        selectedActions.push(<Tooltip title="Attiva garanzia">
            <IconButton aria-label="Attiva garanzia" onClick={this.onWarrantyActivate}>
                <ShieldCheck />
            </IconButton>
        </Tooltip>);

        var showFilterActivator = true;
        var showColumnsSelector = true;

        var previousStep = (
            <Tooltip title="Indietro">
                <IconButton aria-label="Indietro" onClick={this.handlePreviousStep} >
                    <ArrowLeft />
                </IconButton>
            </Tooltip>
        );

        var confirm = (
            <Tooltip title="Conferma">
                <IconButton aria-label="Conferma" onClick={this.doActivate} >
                    <Check />
                </IconButton>
            </Tooltip>
        );

        var content = undefined;
        var customContent = undefined;
        var showChangeView = true;
        if (activation) {
            if (step == 1) {
                content = <IdentitySelector showRelations={true} onIdentitySelected={this.onIdentitySelect} canSkip={true} onSkip={this.onSkipIdentity} onCancel={this.onCancelIdentitySelection}/>
            }
            else {
                var { data, FIDIdentity } = this.state;
                var url = '/DOC/WarrantyActivationPdf/';
                if (FIDIdentity) {
                    url += FIDIdentity;
                }
                url += '?json=' + JSON.stringify(data);

                customContent = <XPDFViewer key="print_warranty" url={url} width="100%" height={document.body.offsetHeight - 259} />;
                actions = [previousStep, confirm];

                showFilterActivator = false;
                showColumnsSelector = false;
                showChangeView = false;
                selectedItems = [];
            }
        }
        if (search) {
            var search = getBOPSearchContent(this.SearchControl, "all", tempSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm);
            customContent = search.customContent;
            actions = search.actions;
            selectedItems = [];
        }
        if (openItem) {
            var isSelected = this.state.selectedItems.indexOf(item.ID) !== -1;
            customContent = <CatalogueItem data={item} open={openItem} actions={this.cardSelectionView} isSelected={isSelected} />
            showChangeView = false;
            showFilterActivator = false;
            showColumnsSelector = false;
            actions = [<Tooltip title="Chiudi">
                <IconButton aria-label="Chiudi" onClick={this.onItemClose}>
                    <Close />
                </IconButton>
            </Tooltip>];
            selectedItems = [];
        }

        var urlData = "/DOC/GetBopsWarranty?grid=tblDOCBops";
        if (this.state.searchValue) {
            urlData += "&search=" + this.state.searchValue;
        }
        if (!content) {
            content = <XGrid
                ref={this.grdWarranty}
                usePrimaryColor={true}
                dataRoute={urlData}
                dataRouteColumns="/Base/GetColumns?grid=tblDOCBops"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblDOCBops"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblDOCBops"
                selectedActions={selectedActions}
                actions={actions}
                customContent={customContent}
                showFilterActivator={showFilterActivator}
                showColumnsSelector={showColumnsSelector}
                cardWidth={4}
                cardHeight={5}
                showChangeView={showChangeView}
                onFinishLoad={this.onGridLoaded}
                onDoubleClick={this.handleGridDoubleClick}
                selectedRows={selectedItems}
                onRowChangeSelection={this.onItemsSelected}
                cardSelectionView={this.cardSelectionView}
                width={this.props.width}
            />;
        }
        return content;
    }
}

//export default withStyles(styles)(Warranty);

const enhance = compose(
    withStyles(styles),
    withWidth(),
);
export default enhance(Warranty);