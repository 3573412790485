import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { Prompt } from 'react-router';

import { withStyles, Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import { Timelapse, ArrowLeft, Check,Account } from 'mdi-material-ui';

import XNavigation from 'components/XNavigation';
import Ring from 'components/Icons/Ring';

import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import XGrid from "components/XGrid";
import XMessageBox from 'components/XMessageBox';
import XDialog from 'components/XDialog';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

import { PieChart, Pie, Sector, Cell, Legend, XAxis,YAxis,Label,Text,BarChart,CartesianGrid,Tooltip as ChartTooltip, Bar } from 'recharts';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { getXSelect, getXField, getXSwitch, getXDateField, handleErrors, popupBOPSearch, getBOPSearchContent, parseParam, getDiscount, format } from 'utils';

const styles = theme => ({

    card: {
        textAlign: 'center',
        borderRadius: 1,
    },
    cardCategory:{
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 10
    }
});

class EventEdit extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
        this.tabs = React.createRef();
        this.grdIdentities = React.createRef();
        this.state = {
            data:{},
            initialData: {},
            firstLoad: true,
            fields:[],
            costFields:[],
            indeitityFields:[],
            editCost: false,
            costData: {},
            totals: {
                totalSells:0.0,
                totalBuys:0.0,
                totalCosts: 0.0,
            }
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        fetch('/events/GetFields').then(res => res.json()).then(data => {
            this.setState({ fields: data});
        });
        fetch('/events/GetCostFields').then(res => res.json()).then(data => {
            this.setState({ costFields: data});
        });fetch('/events/GetIdentityFields').then(res => res.json()).then(data => {
            this.setState({ identityFields: data});
        });
        this.loadData();
    }
    loadData = () =>{
        if (this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            fetch(`/events/GetEvent/${this.props.match.params.id}`)
                .then(handleErrors)
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        state.data = data;
                        if (state.firstLoad) {
                            state.initialData = JSON.parse(JSON.stringify(state.data));
                        }
                        state.firstLoad = false;
                        return state;
                    });
                })
                .catch(error => { console.log(error) });
            fetch(`events/GetEventTotals/${this.props.match.params.id}`)
                .then(handleErrors)
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        state.totals = data;
                        return state;
                    });
                })
                .catch(error => { console.log(error) });
        }
        else {
            this.setState(state => {
                if (state.firstLoad) {
                    state.initialData = JSON.parse(JSON.stringify(state.data));
                }
                state.firstLoad = false;
                return state;
            });
        }
        
    }

    checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        fetch('/events/HasChanges/', {
            method: 'POST'
        }).then(res => res.json())
            .then(data => {
                if (data || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                    this.MessageBox.current.getWrappedInstance().showMessageYesNoCancel("Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?", callbackYes, callbackNo, callbackCancel);
                }
                else {
                    callbackNoChanges();
                }
            });
    }
    checkForChangesAsync = async (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        var data = await fetch('/events/HasChanges/', {
            method: 'POST'
        }).then(res => res.json());
        if (data || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
            this.MessageBox.current.getWrappedInstance().showMessageYesNoCancel("Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?", callbackYes, callbackNo, callbackCancel);
            return true;
        }
        else {
            callbackNoChanges();
            return false;
        }
    }
    handlePageExit = (location, action) => {
        var exitCallback = () => {
            this.justExit = true;
            this.exit = true;
            fetch('/events/ClearSession', {
                method: 'POST'
            }).then(res => {
                this.context.router.history.push(location.pathname);
            });
        }
        if(location.pathname === this.props.match.path){
            return true;;
        }
        
        if (!this.justExit) {
            var check = this.checkForChangesAsync(undefined, undefined,
                () => {
                    this.exit = true;
                    exitCallback();
                },
                () => {
                    this.exit = true;
                    this.handleSaveData(null, this, () => {
                        //this.doExit();
                        exitCallback();
                    });
                },
                () => {
                    this.exit = true;
                    exitCallback();
                },
                () => { });
            return false;
        }
        return true;
    }

    handleExit = (event) => {
        this.checkForChanges(event, this,
            //this.doExit,
            () => {

                this.exit = true;
                this.doExit();
            },
            () => {

                this.exit = true;
                this.handleSaveData(event, this, () => {
                    //this.doExit();
                    this.doExit();
                });
            },
            //this.doExit,
            () => {

                this.exit = true;
                this.doExit();
            },
            () => { }
        );
    }
    doExit = () => {
        this.justExit = true;
        this.exit = true;
        this.mounted = false;
        fetch('/events/ClearSession', {
            method: 'POST'
        }).then(res => {
            //this.context.router.history.push('/documents/');
            this.context.router.history.goBack();
        });
    }
    doSaveData = (callback) => {
        var currentTab = this.tabs.current.getCurrentTab();
        this.setState({ isLoading: true }, () => {
            var { data } = this.state;
            var FIDEvent = this.props.match.params.id;
            if (!FIDEvent) {
                FIDEvent = 0;
            }
            fetch('/events/SaveEvent/' + FIDEvent, {
                body: JSON.stringify(data),
                method: 'POST'
            }).then(handleErrors).then(res => res.json()).then((data) => {
                if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
                    if (!this.exit) {
                        this.context.router.history.replace('/Events/Edit/' + data.id);
                    }
                    if (callback) {
                        callback();
                    }
                }
                this.setState({ firstLoad: true }, () => {
                    this.loadData();
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                        if (callback) {
                            callback();
                        }
                    });
                });

            })
                .catch(error => {
                    console.log(error);
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                    });
                });
        });
    }
    handleSaveData = async (event, caller, callback) => {
        if (this.validateTabGeneral(() => {
            this.doSaveData(callback);
        })) {
            this.doSaveData(callback);
        }
        
    }
    
    validate = (tab, newTab, callback) => {
        if (tab !== 0) {
            return true;
        }
        return this.validateTabGeneral(callback);
    }
    validateTabGeneral = (callback) => {
        var { data, fields } = this.state;
        var isValid = true;
        var requiredFields = [];
        var optionalFields = [];
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            if ((field.Required === 2 || (field.Required === 1 && !field.Validated)) && (!data[field.Field] || data[field.Field].length === 0)) {
                console.log(data, data[field.Field], field);
                isValid = false;
                if (field.Required === 1) {
                    fields[i].Validated = true;
                    optionalFields.push(field.FieldLabel);
                }
                else {
                    requiredFields.push(field.FieldLabel);
                }
            }
        }
        if (!isValid) {
            this.showMessage(requiredFields,optionalFields,callback,fields);
        }
        else {
            for (var i = 0; i < fields.length; i++) {
                fields[i].Validated = false;
            }
        }
        return isValid;
    }
    showMessage = (requiredFields,optionalFields, callback,fields, extraMessages) =>{
        if(!extraMessages){
            extraMessages =[];
        }
        var msg = (
            <Fragment>
                {requiredFields.length > 0 && (<Typography variant="subtitle2">Campi obbligatori:</Typography>)}
                {requiredFields.map((item, index) => (<Typography key={index}>{item}</Typography>))}
                {optionalFields.length > 0 && (<Typography variant="subtitle2">Campi raccomandati (opzionali):</Typography>)}
                {optionalFields.map((item, index) => (<Typography key={index}>{item}</Typography>))}

                {requiredFields.length == 0 && optionalFields.length > 0 && (<Typography variant="subtitle2">Procedere senza compilare questi campi?</Typography>)}

                {requiredFields.length > 0 && (<Typography variant="subtitle2">Compilare i campi obbligatori prima di procedere.</Typography>)}

                {extraMessages.map((item,index) => <Typography key={index}>{item}</Typography>)}
            </Fragment>
        );
        if (requiredFields.length > 0 || extraMessages.length > 0) {
            this.MessageBox.current.getWrappedInstance().showMessageOk("Alcuni campi non sono stati compilati.", msg);
        }
        else {
            this.MessageBox.current.getWrappedInstance().showMessageYesNo("Alcuni campi non sono stati compilati.", msg, () => {
                callback();
                for (var i = 0; i < fields.length; i++) {
                    fields[i].Validated = false;
                }
            });
        }
    }
    
    handleFieldChange = (key) => (event, value) => {
        this.handleDataChange(event, value, key);
    }
    handleDataFieldChange = (key) => (value) => {
        this.handleDataChange(null, value, key);
    }
    handleDataChange = (event, value, key, dt, callback) => {
        this.setState(state => {
            state.data[key] = value;
            return state;
        }, callback);
    }
    
    
    handleCostFieldChange = (key) => (event, value) => {
        this.handleCostDataChange(event, value, key);
    }
    handleCostDataChange = (event, value, key, dt, callback) => {
        this.setState(state => {
            state.costData[key] = value;
            return state;
        }, callback);
    }

    renderPanelGeneral = () => {
        var { data, fields } = this.state;

        var fieldDescription = getXField(data, fields, 12, 6, "Description", "Descrizione", this.handleFieldChange);
        var fieldDate = getXDateField(data, fields, 12, 3, "DateStart", "Data inizio", this.handleDataFieldChange);
        var fieldRedemption = getXDateField(data, fields, 12, 3, "Redemption", "Fine periodo", this.handleDataFieldChange);
        
        return (
            <Grid container spacing={2}>
                {fieldDescription}
                {fieldDate}
                {fieldRedemption}
            </Grid>
        );
    }
    handleGridNewButton = () =>{
        this.setState({costData: Object.assign({},{}),editCost: true});
    }
    handleCostCancel = () => {
        this.setState({costData: Object.assign({},{}),editCost: false});
    }
    handleCostSave = () => {
        debugger;
        var { costData } = this.state;
        var FIDEvent = this.props.match.params.id;
        if (!FIDEvent) {
            FIDEvent = 0;
        }

        if (costData.Description && costData.Amount) {
            this.saveCost(FIDEvent, costData);
        }
    }
    saveCost = (FIDEvent, data) => {
        fetch(`/events/SaveCost/${FIDEvent}`, {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                this.setState({ costData: Object.assign({},{}), editCost: false });
            });
    }
    handleEditCost = (event, data) => {
        this.setState({ costData: Object.assign({}, data), editCost: true });
    }


    renderPanelCosts = () =>{
        var { data, costFields, costData, editCost } = this.state;
        var FIDEvent = this.props.match.params.id;
        if (!FIDEvent) {
            FIDEvent = 0;
        }
        var customActions = [];
        var style = { margin: '-28px', width: 'calc(100% + ' + (28 * 2) + 'px)' };
        var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
        var customContent = undefined; 
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var onNewButton = this.handleGridNewButton;
        var key = "tblEventCosts";
        var label = undefined;
        if (editCost) {
            var fieldDescription = getXField(costData, costFields, 12, 6, "Description", "Descrizione", this.handleCostFieldChange);
            var fieldAmount = getXField(costData, costFields, 12, 6, "Amount", "Importo", this.handleCostFieldChange,{ type: 'number'});
            customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
                {fieldDescription}
                {fieldAmount}
            </Grid></div>;

            key = "frmEVCosts";
            showColumnsSelector = false;
            showFilterActivator = false;
            customActions = [
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleCostCancel}>
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>,
                <Tooltip title="Salva">
                    <IconButton aria-label="Salva" onClick={this.handleCostSave}>
                        <Check />
                    </IconButton>
                </Tooltip>
            ];
            onNewButton = undefined;
            label = costData.ID > 0 ? "Modifica costo":"Nuovo costo";
        }
        var ui = <Grid item xs={12}>
            <XGrid
                containerID="EVCostsContainer"
                key={key}
                label={label}
                ref={this.grid}
                dataRoute={"/events/GetEventCosts/" + FIDEvent + "?grid=tblEventCosts"}
                dataRouteColumns="/Base/GetColumns?grid=tblEventCosts"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblEventCosts"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblEventCosts"
                dataRouteDelete={"/events/DeleteCosts/" + FIDEvent + "?grid=tblEventCosts"}
                onDoubleClick={this.handleEditCost}
                onEditButton={this.handleEditCost}
                onNewButton={onNewButton}
                customContent={customContent}
                actions={customActions}
                rowsVisible={10}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
            />
        </Grid>;

        return (
            <Grid container spacing={2} style={style}>
                {ui}  
            </Grid>
        );
    }

    renderTabGeneral = () =>{
        var panels = [];
        panels.push({
            icon: <Timelapse />,
            label: 'Dati generali',
            content: this.renderPanelGeneral()
        });
        panels.push({
            icon: <Timelapse />,
            label: 'Costi',
            id: "EVCostsContainer",
            content: this.renderPanelCosts()
        });
        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    handleIdentityAdd = () => {
        this.setState({identityInsert: true});
    }
    handleIdentityCancel = () => {
        this.setState({identityInsert: false});
    }
    handleIdentitySave = () => {
        var FIDEvent = this.props.match.params.id;
        if (!FIDEvent) {
            FIDEvent = 0;
        }
        var selected = this.grdIdentities.current.getWrappedInstance().getSelectedData();

        fetch(`/events/SaveIdentities/${FIDEvent}`, {
            body: JSON.stringify(selected),
            method: 'POST'
        }).then(res => {
            this.grdIdentities.current.getWrappedInstance().refresh();
        });
    }
    renderTabCustomers = () =>{
        var { data, identityInsert } = this.state;
        var FIDEvent = this.props.match.params.id;
        if (!FIDEvent) {
            FIDEvent = 0;
        }
        var selectedActions = [];
        var customActions = [
            <Tooltip title="Aggiungi clienti">
                <IconButton aria-label="Aggiungi clienti" onClick={this.handleIdentityAdd}>
                    <Account />
                </IconButton>
            </Tooltip>,
        ];
        
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var key = "tblEventIdentities";
        var url = `/events/GetEventIdentities/${FIDEvent}?grid=${key}`;
        var dataRouteDelete = `/events/DeleteIdentities/${FIDEvent}?grid=${key}`;
        var label = undefined;
        
        if(identityInsert){
            customActions = [
                <Tooltip title="Indietro">
                    <IconButton aria-label="Indietro" onClick={this.handleIdentityCancel}>
                        <ArrowLeft />
                    </IconButton>
                </Tooltip>,
            ];
            selectedActions = [
                <Tooltip title="Inserisci">
                    <IconButton aria-label="Inserisci" onClick={this.handleIdentitySave}>
                        <Check />
                    </IconButton>
                </Tooltip>,
            ]
            
            key = "tblEventExistingIdentities";
            url = `/events/GetExistingIdentities/${FIDEvent}?grid=${key}`;
            dataRouteDelete = undefined;
        }
        var ui = <Grid item xs={12}>
            <XGrid
                containerID="EVIdentities"
                key={key}
                label={label}
                ref={this.grdIdentities}
                dataRoute={url}
                dataRouteColumns={`/Base/GetColumns?grid=${key}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${key}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${key}`}
                dataRouteDelete={dataRouteDelete}
                actions={customActions}
                selectedActions={selectedActions}
                rowsVisible={10}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
            />
        </Grid>;

        return (
            <TabContainer id="EVIdentities">
                {ui}  
            </TabContainer>
        );
    }

    renderTabBops = () =>{
        var FIDEvent = this.props.match.params.id;
        if (!FIDEvent) {
            FIDEvent = 0;
        }
        var selectedActions = [];
        var customActions = [];
        
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var key = "tblEventBops";
        var url = `/events/GetEventBops/${FIDEvent}?grid=${key}`;
        var dataRouteDelete = undefined;
        var label = undefined;
        var ui = <Grid item xs={12}>
            <XGrid
                containerID="EVBOPS"
                key={key}
                label={label}
                ref={this.grdIdentities}
                dataRoute={url}
                dataRouteColumns={`/Base/GetColumns?grid=${key}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${key}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${key}`}
                dataRouteDelete={dataRouteDelete}
                actions={customActions}
                selectedActions={selectedActions}
                rowsVisible={10}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
            />
        </Grid>;

        return (
            <TabContainer id="EVBOPS">
                {ui}  
            </TabContainer>
        );
    }
    renderTabStatistics = () =>{
        var { classes } = this.props;
        var { data, totals } = this.state;
        // return (<TabContainer>
       

        // </TabContainer> );
        var chart = [{
            name: data.Description,
            ["Costo evento"]: totals.totalCosts,
            ["Costo merce"]: totals.totalBuys,
            ["Vendite"]: totals.totalSells
        }];
        return (
            <TabContainer id="EVBOPS">
            
                <Card className={classes.card}>
                    <CardHeader color="info" stats icon>
                        <p className={classes.cardCategory}>R.O.I</p>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                        <BarChart width={600} height={300} data={chart} style={{display: 'inline-block'}}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <ChartTooltip />
                            <Legend />
                            <Bar dataKey="Costo evento" stackId="a" fill="#8884d8" />
                            <Bar dataKey="Costo merce" stackId="a" fill="#82ca9d" />
                            <Bar dataKey="Vendite" fill="#ffc658" />
                        </BarChart>
                            <div><span>Costo evento: </span><span>{format(totals.totalCosts)}</span></div>
                            <div><span>Costo merce: </span><span>{format(totals.totalBuys)}</span></div>
                            <div><span>Costo totale: </span><span>{format(totals.totalBuys  + totals.totalCosts)}</span></div>
                            <div><span>Vendite: </span><span>{format(totals.totalSells)}</span></div>
                            <div><span>R.O.I.: </span><span>{format(totals.totalSells - (totals.totalBuys  + totals.totalCosts))}</span></div>
                    </CardBody>
                </Card>
                
            </TabContainer>
        );
    }
    renderTabInvitations = () =>{
        return <div></div>;
    }
    renderTabs = () =>{
        var { data } = this.state;
        var tabs = [];

        tabs.push({
            tabName: 'Generale',
            tabContent: this.renderTabGeneral(),
        });
        tabs.push({
            tabName: 'Clienti',
            tabContent: this.renderTabCustomers(),
        });
        tabs.push({
            tabName: 'Prodotti venduti',
            tabContent: this.renderTabBops(),
        });
        tabs.push({
            tabName: 'Statistiche',
            tabContent: this.renderTabStatistics(),
        });
        return tabs;
    }
    
   
    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <Prompt where={true} message={this.handlePageExit} />
                <Tabs
                    innerRef={this.tabs}
                    headerColor="primary"
                    plainTabs={true}
                    onExitClick={this.handleExit}
                    onSaveClick={this.handleSaveData}
                    tabs={this.renderTabs()}
                    validateTab={this.validate}
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles),
);
export default enhance(EventEdit);