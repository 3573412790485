import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import Modal from 'components/Modal';
import XField from 'components/XField';
import XButton from 'components/XButton';

import { Plus, Minus } from 'mdi-material-ui';

const styles = theme => ({
    root:{
        padding: 10,
        position: "absolute",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        background: '#fff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
		zIndex: 10000
    },
    back: {
        position: "absolute",
        top:0,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'rgba(255,255,255,0.6)',
		zIndex: 9999
    },
    header: {
        position: 'relative',
        width: 'calc(100% + 20px)',
        top: -10,
        left: -10,
        padding: 10,
        fontWeight: 'bold', 
        fontSize: 16,
        background: theme.palette.primary.main,
    },
    stockContainer: {
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
        borderTop: '1px solid',
        borderBottom: '1px solid',
    }
});


const qtySelectorStyles = theme => ({
    button: {
        width: 30,
        height: 30,
        padding: 0,
		marginTop: 16,
		"& svg": {
			marginRight: "0 !important"
		}
    }
});
class QtySelector extends Component {
    onValueChange = (id) => (event, value) => {
        var { onChange } = this.props;
        onChange && onChange(value);
    }
    onMinus = () => {
        var { onChange, value } = this.props;
        if(value <= 0){
            return;
        }
        value--;
        onChange && onChange(value);
    }
    
    onPlus = () => {
        var { onChange, value, maxLimit } = this.props;
        if(maxLimit && value >= maxLimit){
            return;
        }
        value++;
        onChange && onChange(value);
    }

    render() {
        var { classes, value } = this.props;
        return (
            <Grid container>
                <Grid item xs={3} md={2}><XButton className={classes.button} onClick={this.onMinus}><Minus /></XButton></Grid>
				<Grid item xs={0} md={1} />
                <Grid item xs={6}><XField value={value} id="value" onChange={this.onValueChange} /></Grid>
				<Grid item xs={0} md={1} />
                <Grid item xs={3} md={2}><XButton className={classes.button} onClick={this.onPlus}><Plus /></XButton></Grid>
            </Grid>
        );
    }
}
QtySelector = withStyles(qtySelectorStyles)(QtySelector);

class BOPSelection extends Component {
    constructor(props){
        super(props);

        this.state = {
            bop:{},
            stock:[],
            qty: 1,
            catalogQty: 1,
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = async () => {
        var { editData, noStock } = this.props;
        await this.loadBOP();
        if(!noStock){
            await this.loadStock();
        }

        if(editData){
            console.log(editData);
            this.setState(state => {
                state.qty = editData && editData.length > 0 ? editData.map(item => item.Qty1).reduce((a,b) => a+b) : 0;
                
                for(var i=0;i< editData.length;i++){
                    var item = editData[i];
                    if(item.ID === state.bop.ID){
                        state.catalogQty = item.Qty1;
                    }
                    else{
                        for(var j in state.stock) {
                            var stock = state.stock[j];
                            if(stock.ID === item.ID){
                                state.stock[j].DocQty1 = item.Qty1;
                            }
                        }
                    }
                }
                return state;
            });
        }
    }

    loadBOP = async () => {
        var resolveCaller = undefined;
        var promise = new Promise((resolve,reject) => {
            resolveCaller = resolve;
        })
        var { FIDBOP } = this.props;
        var bop = await fetch(`/BOP/GetBOPCatalog/${FIDBOP}`).then(res => res.json());
        this.setState({ bop },() => {
            resolveCaller(true);
        });

        return promise;
    }
    loadStock = async () => {
        var resolveCaller = undefined;
        var promise = new Promise((resolve,reject) => {
            resolveCaller = resolve;
        })
        var { FIDBOP } = this.props;
        var stock = await fetch(`/BOP/GetStockInfo/${FIDBOP}`).then(res => res.json());
        var catalogQty = 1;
        if(stock.length > 0){
            stock[0].DocQty1 = 1;
            catalogQty = 0;
        }
        this.setState({ stock,catalogQty },() => {
            resolveCaller(true);
        });
        

        return promise;
    }

    onQtyChange = value => {
        this.setState(state => {
            var delta = value - state.qty;
            state.qty = value;

            if(delta > 0){
                for(var i in state.stock){
                    if(!state.stock[i].DocQty1 || state.stock[i].DocQty1 < state.stock[i].Qty1){
                        var maxToAdd = delta;
                        if(maxToAdd > state.stock[i].Qty1){
                            maxToAdd = state.stock[i].Qty1;
                        }
                        if(!state.stock[i].DocQty1){
                            state.stock[i].DocQty1 = 0;
                        }
                        state.stock[i].DocQty1 += maxToAdd;
                        delta -= maxToAdd;
                    }
                    if(delta === 0){
                        break;
                    }
                }
                if(delta > 0){
                    state.catalogQty += delta;
                }
            }
            if(delta < 0) {
                if(state.catalogQty > 0){
                    var maxToSubstract = delta * -1;
                    if(maxToSubstract > state.catalogQty){
                        maxToSubstract = state.catalogQty;
                    }
                    state.catalogQty -= maxToSubstract;
                    delta += maxToSubstract;
                }
                if(delta < 0){
                    for(var i= state.stock.length -1;i>=0;i--) {
                        if(state.stock[i].DocQty1){
                            var maxToSubstract = delta * -1;
                            if(maxToSubstract > state.stock[i].DocQty1){
                                maxToSubstract = state.stock[i].DocQty1;
                            }
                            state.stock[i].DocQty1 -= maxToSubstract;
                            delta += maxToSubstract;
                        }
                        if(delta === 0){
                            break;
                        }
                    }
                }
            }

            return state;
        });
    }
    onCatalogChange = value => {
        this.setState(state => {

            var delta = value - state.catalogQty;
            state.catalogQty = value;
            state.qty += delta;
            return state;
        });
    }
    onStockChange = index => value => {
        this.setState(state => {
            state.stock[index].DocQty1 = value;
            var filteredStock = state.stock.map(i => i.DocQty1).filter(val => !!val);
            var totStock =  (filteredStock.length >0 && filteredStock.reduce((a,b) => a+b)) || 0;
            if(totStock + state.catalogQty > state.qty){
                if(state.catalogQty > 0){
                    state.catalogQty = state.qty - totStock;
                }
                else {
                    state.qty = totStock + state.catalogQty;
                }
            }
            else if(totStock < state.qty){
                state.catalogQty = state.qty - totStock;
            }
            return state;
        });
    }
    onCancel = () => {
        var { onCancel } = this.props;
        onCancel && onCancel();
    }
    onConfirm = () => {
        var{  catalogQty, stock, bop } = this.state;
        var { onConfirm, FIDBOP } = this.props;

        var values = [];
        if(catalogQty){
            values.push({
                ID: bop.ID,
                Qty: catalogQty
            });
        }

        var stockValues = stock.filter(item => item.DocQty1 > 0);
        if(stockValues){
            values = values.concat(stockValues.map((item, index) => ({ ID: item.ID, Qty: item.Qty1})));
        }


        onConfirm && onConfirm(values, bop.ID);
    }

    render = () => {
        var { classes, noStock } = this.props;
        var { stock, qty, catalogQty, bop } = this.state;
        var totDisp = 0;
        var um = "";
        
        if(stock.length > 0){
           totDisp = stock.map(i => i.Qty1).reduce((a,b) => a+b);
            if(totDisp > 0){
                um = stock[0].MeasureUnit1;
            }
        }

        var title = bop.Code;
        if(bop.SupplierCode){
            title += ` - ${bop.SupplierCode}`;
        }
        return (
            <Modal>
                <div className={classes.back}></div>
                <div className={classes.root}>
                    <div className={classes.header}>{title}</div>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={8}>
                                Totale pezzi
                            </Grid>
                            <Grid item xs={4}>
                                <QtySelector onChange={this.onQtyChange} value={qty}/>
                            </Grid>
                        </Grid>
                        {stock && stock.length > 0 && <Fragment>
                            {!noStock && 
                                <Grid container item xs={12} alignItems="center">
                                    <Grid item xs={6}>
                                        Disponibilità
                                    </Grid>
                                    <Grid item xs={3}>
                                        {totDisp} {um}
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>}
                                <Grid container item xs={12} className={classes.stockContainer}>
                                    {!noStock && stock.map((item,index) => {
                                        var stockValue = item.DocQty1;
                                        if(!stockValue){
                                            stockValue = 0;
                                        }
                                        return (
                                            <Grid key={index} container item xs={12} alignItems="center">
                                                <Grid item xs={3}>
                                                    {item.Position}
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {item.Barcode}{item.SupplierSN ? " - " + item.SupplierSN : ""}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {item.Qty1} {item.MeasureUnit1}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <QtySelector onChange={this.onStockChange(index)} value={stockValue} maxLimit={item.Qty1}/>
                                                </Grid>
                                            </Grid>
                                        );
                                        
                                    })}
                                </Grid>
                            </Fragment>}
                        
                        {!noStock && <Grid container item xs={12}>
                            <Grid item xs={6}>
                                Catalogo
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={4}>
                                <QtySelector onChange={this.onCatalogChange} value={catalogQty} />
                            </Grid>
                        </Grid>}
                        <Grid item xs={12}>
                            <XButton onClick={this.onCancel} color="secondary">Annulla</XButton>
                            <XButton onClick={this.onConfirm} color="primary">Conferma</XButton>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        );
    }
}

BOPSelection.propTypes = {
    //classes: PropTypes.object.isRequired,
    FIDBOP: PropTypes.number.isRequired,
};

export default withStyles(styles)(BOPSelection);