import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx"

class XSwitch extends Component {
	handleChange = (event, value) => {
		var { id, onChange } = this.props;

		if (onChange) {
			onChange(id)(event, value);
		}

		//var { data } = caller.state;

		//data[id] = value;
		//caller.setState({ data });
	}
	render() {
		var { classes, id, value, label, labelPlacement, labelFixedHeight, color, disabled } = this.props;
		if (!value) {
			value = false;
		};
		if (!color) {
			color = "primary";
		}
		if (!labelPlacement) {
			labelPlacement = 'top';
		}

		var labelClasses = {
			label: classes.label,
			//labelPlacementTop: classes.labelPlacementTop,
		};

		if (labelFixedHeight) {
			labelClasses.label += " " + classes.labelFixedHeight;
		}

		return (
			<FormControlLabel
				checked={value}
				disabled={disabled}
				onChange={this.handleChange}
				control={
					<Switch
						value={id}
						color={color}
						classes={{
							switchBase: classes.switchBase,
							checked: classes.switchChecked,
							//icon: classes.switchIcon,
							//iconChecked: classes.switchIconChecked,
							//bar: classes.switchBar
						}}
					/>
				}
				classes={labelClasses}
				labelPlacement={labelPlacement}
				label={label}
			/>
		);
	}
}

export default withStyles(styles)(XSwitch);