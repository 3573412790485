import React, { Component } from 'react';


import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { withStyles, Tooltip, IconButton, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withWidth } from '@material-ui/core';
import XExpansionPanel from '../../components/XExpansionPanel';
import XGrid from '../../components/XGrid';
import CatalogPrice from '../../components/CatalogPrice';
import XSelect from '../../components/XSelect';
import XStepper from '../../components/XStepper';
import CatalogueActions from '../../components/CatalogueActions';
import AssignActions from '../../components/AssignActions';

import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

import XMessageBox from '../../components/XMessageBox';
import { getXSelect, getXField, format } from 'utils';


const styles = (theme) => ({
    root: {
        width: '100%',
    },

});

const defaultFields= [
    {
        Field: "FIDIdentity",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Discount",
        Visibility: true,
        Required: 3,
    },
];

class AssignFromStock extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {
            },
            expansionHeight: 0,
            documents:[],
            discount: 0,
            changeAvailStep: 0,
			snackbar: {
                open: false,
                message: undefined,
            },
        };
        this.grdAssign = React.createRef();
        this.grdPresentationDocInfo = React.createRef();
        this.MessageBox = React.createRef();
    }

    onSavePresentation = id => async () => {
        var row = this.grdAssign.current.getRowData(id);
        var info = this.state.assignData[id];

        var docs = this.state.documents[id];
        if(!info || !info.FIDIdentity) {
            this.MessageBox.current.showMessageOk("Assegnazioni","Nessuna anagrafica selezionata!");
            return;
        }
        if(!docs || docs.length === 0){
            this.MessageBox.current.showMessageOk("Assegnazioni","Nessuna assegnazione effettuata!");
            return;
        }

        var hasP = await this.hasPresentation(id,info.FIDIdentity);
        if(hasP){
            //this.MessageBox.current.showMessageOk("Assegnazioni","Esiste già un'assegnazione per questa anagrafica. Selezionarla nell'elenco per modificare i dati!");
            this.MessageBox.current.showMessageOk("Assegnazioni","Esiste già un'assegnazione per questa anagrafica. Cancellare la presentazione precedente per inserire una nuova!");
            return;
        }
        docs = docs.filter(d => d !== null);
        for(var i in docs){
            var doc = docs[i];
            row.Discount = this.state.discount;
            row.TotalPrice = (row.Price * (100 - row.Discount) / 100).round(2);
            row.DueDate = doc.DueDate;
            if(doc.DeleteAssings){
                await this.deletePreviousAssigns(row,doc.DeleteOrders);
            }
            await this.saveDocument(doc.Stars || -1,row,info.FIDIdentity,doc.FIDCausal, this.state.qty);
        }
        await this.handleConfirmDocumentFinal(id);
        this.setState(state => {
            state.discount = state.data.Discount || 0;
            state.documents = [];
            state.assignData = [];
            if(state.data.FIDIdentity){
                state.assignData[row.ID] = {
                    FIDIdentity: state.data.FIDIdentity
                };
            }
            state.qty = 1;
            return state;
        }, () => {
			this.setState({
				snackbar: {
					open: true,
					message: "Assegnazione salvata correttamente"
				}
			});
            this.grdPresentationDocInfo.current && this.grdPresentationDocInfo.current.refresh();
			this.grdAssign.current.clearSelection();
            this.grdAssign.current.refresh();
        });
    }

    onDeletePresentation = id => () => {
        
    }
    
    handleConfirmDocumentFinal = async (id) => {
        var info = this.state.assignData[id];
        var url = '/IR/ConfirmDocument/' + info.FIDIdentity + '?IsCatalog=false';
        await fetch(url);
    }

    saveDocument = async (stars, item, FIDIdentity, FIDCausal, qty) => {
        var { isCatalog, CatalogFIDPricelist } = this.props
        var document = {
            IsCatalog: isCatalog,
            Stars: stars,
            FIDBOP: item.ID,
            Price: item.Price,
            Discount: item.Discount,
            TotalPrice: item.TotalPrice,
            FIDCausal: FIDCausal,
            Code: item.Code,
            BOP: item,
            VariantUnavailable: item.variantUnavailable,
            VariantExtras: [],
            InternalRemark: item.internalRemark,
            PublicRemark: item.publicRemark,
            Qty: qty,
            FIDPricelist: 0,
            DueDate: item.DueDate
        };
        if (CatalogFIDPricelist)
            document.FIDPricelist = CatalogFIDPricelist;

        var url = `/IR/SaveDocument/${FIDIdentity}?addPresentation=false`;

        await fetch(url, {
            body: JSON.stringify(document),
            method: 'POST'
        });
    }
    hasPresentation = async (FIDBOP, FIDIR) => {
        return await fetch(`/BOP/HasPresentation/${FIDBOP}?FIDIR=${FIDIR}`).then(res => res.json());
    }
    deletePreviousAssigns = async (data, deleteOrders = false) => {
        var rows = this.grdPresentationDocInfo.current.state.data;
        var rowData = rows.filter(item => item.Booked).map(item => item.ID);
        
        await fetch(`/BOP/DeleteAssignments?FIDBOP=${data.ID}&deleteOrder=${deleteOrders ? 1 : 0}`,{
            method: 'POST',
            body: JSON.stringify(rowData)
        }).then(res => res.json());
		
        this.grdPresentationDocInfo.current.refresh();
    }
    onBookItemAction = (data) => {
        var FIDCausal = 13;

        var bookAction = (deleteAssigns = false, deleteOrders = false) => {
            this.setState(state => {
                if(!state.documents[data.ID]){
                    state.documents[data.ID] = [];
                }
                var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
                if(bookDoc){
                    var index = state.documents[data.ID].indexOf(bookDoc);
                    state.documents[data.ID].splice(index,1);
                }
                state.documents[data.ID].push({
                    FIDCausal: FIDCausal,
                    DeleteAssings: deleteAssigns,
                    DeleteOrders: deleteOrders
                });
                this.onStarItemAction(data,-1, true);
                this.onRemoveItemAction(data,12);
                this.onRemoveItemAction(data,9);
                return state;
            });
        }


        if(data.Qty1Disp- data.Qty1Booked <= 0){
            var buttons = [
                {
                    label: "SI",
                    action: async () => {
                        this.MessageBox.current.hideDialog();
                        bookAction(true, true);
                    }
                },
                {
                    label: "NO",
                    action: async () => {
                        this.MessageBox.current.hideDialog();
                        bookAction(true, false);
                    }
                },
                {
                    label: "ANNULLA",
                    action: () => {
                        this.MessageBox.current.hideDialog();
                    }
                }
            ];

            this.MessageBox.current.showMessage("Assegnazioni", "Prodotto già prenotato per altre anagrafiche. Continuando le assegnazioni precedenti saranno rimosse. Cancellare anche gli ordini associati? In caso di risposta negativa, le matricole caricate in tali ordini verranno rimpiazzate con le distinte base corrispondenti.",buttons);
            return;
        }
        bookAction();
    }
    
    onNoticeItemAction = (data) => {
        var FIDCausal = this.props.params.DOCNoticeCausalId||11;
        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
            if(bookDoc){
                var index = state.documents[data.ID].indexOf(bookDoc);
                state.documents[data.ID].splice(index,1);
            }
            state.documents[data.ID].push({
                FIDCausal: FIDCausal
            });
            this.onStarItemAction(data,-1, true);
            return state;
        });
    }
	
	onQuoteItem = (data) => {
        var FIDCausal = this.props.params.DOCQuoteCausalId||3;
        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
            if(bookDoc){
                var index = state.documents[data.ID].indexOf(bookDoc);
                state.documents[data.ID].splice(index,1);
            }
            state.documents[data.ID].push({
                FIDCausal: FIDCausal,
                DueDate: data.DueDate
            });
            this.onStarItemAction(data,-1, true);
            return state;
        });
    }
	
    onNegotiateItemAction = (data) => {
        var FIDCausal = this.props.params.DOCNegotiationCausalId||39;
        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
            if(bookDoc){
                var index = state.documents[data.ID].indexOf(bookDoc);
                state.documents[data.ID].splice(index,1);
            }
            state.documents[data.ID].push({
                FIDCausal: FIDCausal,
                DueDate: data.DueDate
            });
            this.onStarItemAction(data,-1, true);
            return state;
        });
    }
    onReserveItemAction = (data) => {
        var FIDCausal = 12;
        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
            if(bookDoc){
                var index = state.documents[data.ID].indexOf(bookDoc);
                state.documents[data.ID].splice(index,1);
            }
            state.documents[data.ID].push({
                FIDCausal: FIDCausal
            });
            this.onStarItemAction(data,-1, true);
            this.onRemoveItemAction(data,13);
            this.onRemoveItemAction(data,9);
            return state;
        });
    }
    onBuyItemAction = (data) => {
        var FIDCausal = this.props.params.DOCCartCausalId||9;
        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
            if(bookDoc){
                var index = state.documents[data.ID].indexOf(bookDoc);
                state.documents[data.ID].splice(index,1);
            }
            state.documents[data.ID].push({
                FIDCausal: FIDCausal
            });
            this.onStarItemAction(data,-1, true);
            this.onRemoveItemAction(data,12);
            this.onRemoveItemAction(data,(this.props.DOCBookingCausalId||13));
            return state;
        });
    }
    onStarItemAction = (data, stars, ifNotExists = false) => {
        var FIDCausal = 10;
        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
            if(bookDoc){
                if(ifNotExists){
                    return state;
                }
                var index = state.documents[data.ID].indexOf(bookDoc);
                state.documents[data.ID].splice(index,1);
            }
            state.documents[data.ID].push({
                FIDCausal: FIDCausal,
                Stars: stars,
            });
            return state;
        });
    }
    onRemoveItemAction = (data,FIDCausal) => {

        // if(FIDCausal === 13){
        //     var cartDoc = this.state.documents[data.ID].filter(f => f.FIDCausal === 9)[0];
        //     if(cartDoc){
        //         return;
        //     }
        // }


        this.setState(state => {
            if(!state.documents[data.ID]){
                state.documents[data.ID] = [];
            }
            if(FIDCausal == 10){
                state.documents[data.ID] = [];
            }
            else {
                var bookDoc = state.documents[data.ID].filter(f => f.FIDCausal === FIDCausal)[0];
                if(bookDoc){
                    var index = state.documents[data.ID].indexOf(bookDoc);
                    state.documents[data.ID].splice(index,1);
                }
            }
            
            return state;
        });
    }
    onIdentityChange = (id) => (key) => (event, value) => {
        console.log(event, value, id);
        this.setState(state => {
            if(!state.assignData[id]) {
                state.assignData[id] = {}
            }
            if(value.length > 0){
                state.assignData[id].FIDIdentity = value[0].ID;
            }
            else {
                state.assignData[id].FIDIdentity = undefined;
            }
            
            return state;
        });
    }
	
	onAssignmentsDeleted = () => {
		this.grdPresentationDocInfo.current && this.grdPresentationDocInfo.current.refresh();
		this.grdAssign.current && this.grdAssign.current.clearSelection();
		this.grdAssign.current && this.grdAssign.current.refresh();
	}

	onStockAssignSelected = (selectedRows, id) => {
		var { data } = this.state;
		var assignData = [];
        var discount = 0;
		var rowData = this.grdAssign.current.getRowData(id);
        if(rowData && (data.FIDIdentity)){
            assignData[id] = {
                FIDIdentity: data.FIDIdentity
            }
        }
        if(data.Discount){
            discount = data.Discount;
        }
        this.setState({discount, qty: 1, assignData, documents:[], bopSelected: (id > 0)});
	}
	
    onStockAssignExpand = () => {
        var { data } = this.state;
        var id = this.grdAssign.current.getSelectedData()[0];
        var rowData = this.grdAssign.current.getRowData(id);
        var assignData = [];
        var discount = 0;
        if(rowData && (data.FIDIdentity)){
            assignData[rowData.ID] = {
                FIDIdentity: data.FIDIdentity
            }
        }
        if(data.Discount){
            discount = data.Discount;
        }
        
        this.setState({discount, qty: 1, assignData, documents:[], bopSelected: true});
    }
    renderStockAssingExpand = (data) =>{
        var gridName = 'grdPresentationDocInfo';
        var dataRoute = `/BOP/GetPresentationInfoByBOP/${data.ID}?grid=${gridName}`;
        return <XGrid
            innerRef={this.grdPresentationDocInfo}
            key={gridName}
            containerID={`gridExpandContainer_${data.ID}`}		
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid==${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid==${gridName}`}
            //onDoubleClick={this.handleGridDoubleClick}
            //onEditButton={this.handleGridDoubleClick}
            dataRouteDelete={`/BOP/DeleteAssignments`}
            deleteField="FIDBOP"
            deleteFieldValue={data.ID}
			afterDelete={this.onAssignmentsDeleted}
            handleDeleteCallback={() => {
                var buttons = [
                    {
                        label: "SI",
                        action: () => {
                            this.grdPresentationDocInfo.current.setState({ dialog: {} });
                            this.grdPresentationDocInfo.current.doDelete([{ key: "deleteOrder", value: 1 }]);
							this.setState({
								snackbar: {
									open: true,
									message: "Assegnazione rimossa correttamente"
								}
							});
                        }
                    },
                    {
                        label: "NO",
                        action: () => {
                            this.grdPresentationDocInfo.current.setState({ dialog: {} });
                            this.grdPresentationDocInfo.current.doDelete();
							this.setState({
								snackbar: {
									open: true,
									message: "Assegnazione rimossa correttamente"
								}
							});
							this.grdPresentationDocInfo.current.refresh();
							this.grdAssign.current.clearSelection();
							this.grdAssign.current.refresh();
                        }
                    },
                    {
                        label: "ANNULLA",
                        action: () => {
                            this.grdPresentationDocInfo.current.setState({ dialog: {} });
                        }
                    }
                ];

                this.grdPresentationDocInfo.current.showDialog(buttons, "Cancellazione", "Le assegnazioni selezionate saranno rimosse. Cancellare anche gli ordini associati? In caso di risposta negativa, le matricole caricate in tali ordini verranno rimpiazzate con le distinte base corrispondenti.");
            }}
            showColumnsSelector={false}
            showFilterActivator={false}
        />;
    }
    onChangeAvailClick = (changeAvailStep) => {
        this.setState({changeAvailStep});
     }
    
    canRenderActions = (data) => {
        var selection = this.grdAssign.current.getSelectedData();
        if(selection.length === 1 && selection[0] === data.ID){
            return true;
        }
        else {
            return false;
        }
    }
    onPriceChange = (value) => {
        this.setState({discount: value});
    }
    onExpansionChange = () => {
        var expPanel = document.getElementById('expansionPanel');
        if(expPanel){
            var height = expPanel.clientHeight;
            this.setState({ expansionHeight: height});
        }
    }
    renderQtySelector = (data) => {
       return (<div>
                <div style={{ width: "100%", lineHeight: 0, textAlign: "center" }}>
                    <button disabled={this.state.qty === 1} style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                        this.setState(state => {
                            if (!state.qty) state.qty = 1;
                            state.qty--;
                            return state;
                        })}}>-</button>
                    <input style={{ border: "1px solid #aaa", margin: 2, padding: 10, width: 80 }} type="number" value={this.state.qty} max={this.state.qty} onChange={(event) => {
                        var n = parseInt(event.target.value);
                        if (n < 0) {
                            n = 0;
                        }
                        this.setState({ qty: n });
                    }} />
                    <button disabled={this.state.qty === (data.Qty1Disp - data.Qty1Booked)} style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                        this.setState(state => {
                            if (!state.qty) state.qty = 0;
                            state.qty++;
                            return state;
                        })
                    }}>+</button>
                </div>
            </div>);
    }
    renderCellPrice = (data,column) =>{
        var { classes } = this.props;
        var { discount } = this.state;
        if(this.canRenderActions(data)) {
            return <div className={classes.priceAction}>
                <CatalogPrice supplierLabel={data.SupplierLabel} price={data.Price} discount={discount} currency={data.Currency} onDiscountChange={this.onPriceChange}/>
                {this.renderQtySelector(data)}
            </div>;
        }
        else {
            if(data.SupplierLabel){
                return <span>{data.SupplierLabel}</span>;
            }
            else {
            	return <span>{format(data.Price,data.Currency)}</span>;
            }
        }
    }
    renderCellIdentity = (data,column) =>{
        if(this.canRenderActions(data)) {
            return <XSelect field={{ Required: 2 }} label="" id={`FIDIdentity_${data.ID}`} urlData="/IR/GetIRSelect" value={this.state.assignData && this.state.assignData[data.ID] && this.state.assignData[data.ID].FIDIdentity} idKey="ID" valueKey="Denomination" onChange={this.onIdentityChange(data.ID)} subtitleKey="Address" />;
        }
        else {
            return <span></span>;
        }
    }
    renderCatalogActions = (data,column) =>{
        /* 
        BOOK 13
        CART 9
        PRESENTATION 10
        RESERVATION 12
        NOTICE 11
        */
        if(this.canRenderActions(data)) {
            var { documents } = this.state;
            var doc = documents[data.ID];
            if(!doc){
                doc = [];
            }
            return <CatalogueActions data={data} documents={doc} onRemoveItem={this.onRemoveItemAction} onQuoteItem={this.onQuoteItem} onBookItem={this.onBookItemAction} onNoticeItem={this.onNoticeItemAction}onReserveItem={this.onReserveItemAction} onBuyItem={this.onBuyItemAction} onStarItem={this.onStarItemAction} onNegotiateItem={this.onNegotiateItemAction} showStars={false}/>;
        }
        else {
            return <span></span>;
        }
    }
    renderCellActions = (data,column) =>{
        if(this.canRenderActions(data)) {
            return <AssignActions onSave={this.onSavePresentation(data.ID)} onDelete={this.onDeletePresentation(data.ID)} />;
        }
        else {
            return <span></span>;
        }
    }
    handleSelectChange = (id) => (event,value) => {
        this.setState(state => {
            if(value && value.length > 0){
                state.data[id] = value[0].ID;
            }
            else {
                state.data[id] = undefined;
            }
            return state;
        });
    }
    
    handleFieldChange = (id) => (event,value) => {
        this.setState(state => {
            state.data[id] = value;
            return state;
        });
    }

    renderPanelParams = () =>{
        var { data } = this.state;
		
		var identityField = getXSelect(data, defaultFields, 12, 6, "FIDIdentity", "Anagrafica", this.handleSelectChange, "/IR/GetIRSelect",undefined,{ subtitleKey:'Address'} );
		var discountField = getXField(data, defaultFields, 12, 3, "Discount", "Sconto", this.handleFieldChange, { type: "number",decimalScale: 2 });
		
		return (
			<Grid container spacing={2} direction="row">
				{identityField}
                {discountField}
			</Grid>
		);
    }
	snackbarClose = () => {
        this.setState(state => {
            state.snackbar.open = false;
            return state;
        });
    }
	renderSnackbar() {
        var { snackbar } = this.state;
        return <Snackbar
            open={snackbar.open}
            onClose={this.snackbarClose}
            TransitionComponent={props => <Slide {...props} direction="up" />}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackbar.message}</span>}
            autoHideDuration={1500}
        />;
    }
    render() {
        var { classes } = this.props;
        var { changeAvailStep,expansionHeight, bopSelected } = this.state;
        var panels =[];
        panels.push({
            label: 'Parametri',
            icon: 'code',
            content: this.renderPanelParams()
        });


        var key = "tblAssignStock";
        var gridName = key;
        var dataRoute = `/BOP/GetBopsStock/?grid=${gridName}`;
        var customCells = {
            PricelistAction: this.renderCellPrice,
            IdentityAction: this.renderCellIdentity,
            CatalogActions: this.renderCatalogActions,
            Actions: this.renderCellActions,
        };
        var viewType = "grid";
        var rowHeight = 120;
        var onlyOneSelection = true;
        var rowExpand = this.renderStockAssingExpand;
        var hideExpandButton = true;
        var gridActions=[];
        var onRowExpand = this.onStockAssignExpand;

        
        var fieldChangeAvailStatus = <XStepper label={getTranslation(4,"BOPS_AVAIL_STATUS",this.props.translations, "Disponibilità")} step={changeAvailStep} steps={[{label:getTranslation(4,"BOPS_AVAILABLE",this.props.translations, "Disponibili")},{label:getTranslation(4,"BOPS_BOOKED",this.props.translations, "Prenotate")}]} onStepClick={this.onChangeAvailClick}/>;
        if(changeAvailStep === 0){
            dataRoute += `&onlyAvailable=true`;
            key += "AVAIL";
        }
        else if(changeAvailStep === 1) {
            dataRoute += `&ShowBookedOnly=true`;
            key += "BOOK";
        }
        
        var label = <span><span style={{ display: "inline-block", position: "relative", bottom: 8}}>{fieldChangeAvailStatus}</span></span>;

        var expH = expansionHeight;
        var expPanel = document.getElementById('gridContainer');
        if(expPanel){
                expH = expPanel.getClientRects()[0].y;
        }
        var height = expH;
        var gridContainerStyle = {
            width: '100%',
            minHeight: `calc(100vh - ${height}px)`,
            dataExpHeight: expansionHeight,
        };
        console.log(gridContainerStyle);

        
        return <div className={classes.root}>
            <XExpansionPanel id="expansionPanel" panels={panels} onExpansionChange={this.onExpansionChange} allClosed={true} />
            <div style={gridContainerStyle} id="gridContainer">
                <XGrid
                    containerID="gridContainer"
                    innerRef={this.grdAssign}
                    usePrimaryColor={false}
                    key={key}
                    label={label}
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    rowExpandFormat={changeAvailStep === 0 ? undefined : rowExpand}
                    actions={gridActions}
                    customCells={customCells}
                    //viewType={viewType}
                    rowHeight={rowHeight}
                    onlyOneSelection={onlyOneSelection}
                    hideExpandButton={hideExpandButton}
                    onRowExpand={changeAvailStep === 0 ? undefined : onRowExpand}
					onRowChangeSelection={changeAvailStep === 0 ? this.onStockAssignSelected : undefined}
					//expansionWidth="70%"
					expansionMaxWidth={900}
					//expansionLeftOffset="4%"
					cardWidth={4}
					cardHeight={4.5}
					showChangeView={true}
					changeViewHideCard={true}
					showLoader
                />
            </div>
            {this.renderSnackbar()}
            <XMessageBox innerRef={this.MessageBox} />
        </div>;
    }
}




const enhance = compose(
    withStyles(styles),
    withWidth(),
    connect(
        state => state.global,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    )
);
export default enhance(AssignFromStock);