import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import XProgress from 'components/XProgress';
import Close from 'mdi-material-ui/Close';
import Check from 'mdi-material-ui/Check';
import Pencil from 'mdi-material-ui/Pencil';
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import Download from 'mdi-material-ui/Download';
import Translate from 'mdi-material-ui/Translate';
import Delete from 'mdi-material-ui/Delete';
import Ring from 'mdi-material-ui/Ring';
import LinkVariant from 'mdi-material-ui/LinkVariant';


import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XDialog from 'components/XDialog';
import XGrid from 'components/XGrid';
import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XOXLoader from 'components/XOXLoader';

import XGallery from 'components/XGallery';
import BOPHistory from 'views/BOP/BOPHistory';

import { CustomField } from 'components/CustomField';


import { getXSelect, getXField, getXSwitch, getXTag, getCookie, setCookie, getBOPSearchContent, popupBOPSearch, getDiscount, getRecharge, round } from 'utils';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import XMessageBox from '../components/XMessageBox';
import XNavigation from 'components/XNavigation';
import TranslateEditor from '../components/TranslateEditor';
import BOPComponentEditor from '../components/BOPComponentEditor';
import BOPSelector from 'components/BOPSelector';
import VariationsConfig from 'views/BOP/VariationsConfig';


//import numeral from 'numeral';
const fieldsSupplier = [{
    Field: "FIDIdentity",
    Visibility: true,
    Required: 2
},
    {
        Field: "FIDVat",
        Visibility: true,
        Required: 2
    },
    {
        Field: "FIDCurrency",
        Visibility: false,
        Required: 2
    },
    {
        Field: "CurrencyChange",
        Visibility: false,
        Required: 2
    },
    {
        Field: "CurrencyChangeOverride",
        Visibility: true,
        Required: 3
    },
    {
        Field: "PublicPrice",
        Visibility: true,
        Required: 2
    },
    {
        Field: "DiscountDesc",
        Visibility: true,
        Required: 2
    },
    {
        Field: "SupplierCost",
        Visibility: true,
        Required: 2
    },
    {
        Field: "Default",
        Visibility: true,
        Required: 2
    }, {
        Field: "Code",
        Visibility: true,
        Required: 1
    }, {
        Field: "CatalogCode",
        Visibility: true,
        Required: 1
    }, {
        Field: "Label",
        Visibility: true,
        Required: 3
    }, {
        Field: "CalculationType",
        Visibility: true,
        Required: 2
    }, {
        Field: "PublicTaxablePrice",
        Visibility: true,
        Required: 2
    },
    {
        Field: "FIDSalesPricelist",
        Visibility: true,
        Required: 2
    },
	{
		Field: "FIDMarkup",
		Visibility: true,
		Required: 2,
	}
];
const defaultFields = [
    {
        Field: "FIDGroup",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDTypology",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDCategory",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "TagIds",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Code",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "CatalogCode",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "FIDAbbreviation",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "Description",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "ExtendedDescription",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "SupplierSN",
        Visibility: false,
        Required: 1,
    },
    {
        Field: "FIDBrand",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "FIDLine",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "FIDSeries",
        Visibility: true,
        Required: 1,
    },

    {
        Field: "FIDMeasureUnit1",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "MeasureUnitQty1",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDMeasureUnit2",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "MeasureUnitQty2",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "FIDMeasureUnitCost",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "CalculationTypeQty",
        Visibility: true,
        Required: 1,
    },

    {
        Field: "CostOrigin",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "CalculationType",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDSupplier",
        Visibility: false,
        Required: 2
    },
    {
        Field: "FIDSupplierVat",
        Visibility: false,
        Required: 2
    },
    {
        Field: "FIDSupplierCurrency",
        Visibility: false,
        Required: 2
    },
	{
		Field: "FIDSupplierMarkup",
        Visibility: false,
        Required: 2
	},
    {
        Field: "SupplierCurrencyChange",
        Visibility: false,
        Required: 2
    },
    {
        Field: "SupplierCurrencyChangeOverride",
        Visibility: false,
        Required: 3
    },
    {
        Field: "SupplierPublicPrice",
        Visibility: false,
        Required: 2
    },
    {
        Field: "SupplierPublicTaxablePrice",
        Visibility: false,
        Required: 2
    },
    {
        Field: "FIDSalesPricelist",
        Visibility: false,
        Required: 2
    },
    {
        Field: "SupplierDiscountDesc",
        Visibility: false,
        Required: 2
    },
    {
        Field: "SupplierCost",
        Visibility: false,
        Required: 2
    },
    {
        Field: "SupplierCode",
        Visibility: false,
        Required: 1
    },
    {
        Field: "SupplierCatalogCode",
        Visibility: false,
        Required: 1
    },
	{
        Field: "SupplierLabel",
        Visibility: false,
        Required: 3
    },
    {
        Field: "UnitCost",
        Visibility: false, //(data.FIDMeasureUnitCost === data.FIDMeasureUnit1 ? data.MeasureUnitQty1 : data.MeasureUnitQty2) !== 1,
        Required: 1,
    },
    {
        Field: "TotalCost",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "CalculatedCost",
        Visibility: true,
        Required: 1,
    },

    {
        Field: "InternalRemark",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PublicRemark",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "IsVariation",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "DefaultVariation",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDGender",
        Visibility: true,
        Required: 3
    },
    {
        Field: "FIDRecurrency",
        Visibility: true,
        Required: 3
    },
    {
        Field: "FIDStatus",
        Visibility: true,
        Required: 3
    },
    {
        Field: "Conditions",
        Visibility: true,
        Required: 3
    }
];
const FieldLabels = {
    FIDIdentity: "Fornitore",
    FIDVat: "IVA",
    FIDCurrency: "Valuta",
    CurrencyChange: "Cambio",
    CurrencyChangeOverride: "Cambio",
    PublicPrice: "Prezzo al pubblico",
    DiscountDesc: "Sc. %",
    SupplierCost: "Costo fornitore",
    Default: "Predefinito",
    Code: "Codice",
    CatalogCode: "Codice catalogo",
    FIDGroup: "Gruppo",
    FIDTypology: "Tipologia",
    FIDCategory: "Tag principale",
    TagIds: "Tag",
    FIDAbbreviation: "Sigla",
    Description: "Descrizione",
    ExtendedDescription: "Descrizione estesa",
    SupplierSN: "Matricola fornitore",
    FIDBrand: "Marca",
    FIDLine: "Collezione",
    FIDSeries: "Serie",
    FIDMeasureUnit1: "U.M. principale",
    MeasureUnitQty1: "Q.tà U.M. principale",
    FIDMeasureUnit2: "U.M. secondaria",
    MeasureUnitQty2: "Q.tà U.M. secondaria",
    FIDMeasureUnitCost: "Opzione di costo",
    CalculationTypeQty: "Tipo calcolo q.tà",
    CostOrigin: "Origine costo",
    CalculationType: "Tipo calcolo costo",
    FIDSupplier: "Fornitore",
    FIDSupplierVat: "IVA",
    FIDSupplierCurrency: "Valuta",
	FIDSupplierMarkup: "Formula di ricarico",
    SupplierCurrencyChange: "Cambio",
    SupplierCurrencyChangeOverride: "Cambio",
    SupplierPublicPrice: "Prezzo al pubblico",
    SupplierDiscountDesc: "Sc. %",
    SupplierCode: "Codice fornitore",
    SupplierCatalogCode: "Codice catalogo fornitore",
	SupplierLabel: "Etichetta fornitore",
    UnitCost: "Costo unitario",
    TotalCost: "Costo totale",
    CalculatedCost: "Calcola costo",
    InternalRemark: "Nota interna",
    PublicRemark: "Nota pubblica",
    FIDSalesPricelist: "Listino di vendita",
    IsVariation: "Variante",
    DefaultVariation: "Variante predefinita",
    FIDGender: "Genere",
    FIDStatus: "Stato",
    FIDRecurrency: "Ricorrenza",
    Conditions: "Condizioni"
};

const uiFields = ["Generale","Fornitori", "Componenti", "Files", "Listini", "Correlati"];


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

class BOPEdit extends Component {
    constructor(props) {
        super(props);

        this.MessageBox = React.createRef();
        this.grdFiles = React.createRef();
        this.grdPricelists = React.createRef();
        this.grdStores = React.createRef();
        this.grdComponents = React.createRef();
        this.grdBOPSuppliers = React.createRef();
        this.grdBOPRelateds = React.createRef();
        this.tabs = React.createRef();
        this.cboFIDVat = React.createRef();
        this.BOPComponentEditor = React.createRef();
        this.grdBOPSComponents = React.createRef();
        //this.handleDataBlur = this.handleDataBlur.bind(this);
        this.handleSaveData = this.handleSaveData.bind(this);
        this.state = {
            value: 0,
            data: {},
            initialData: {},
            firstLoad: true,
            file: {},
            pricelist: {},
            store: {},
            bopRelateds:[],
            bopParures: [],
            showMessage: false,
            errorMessage: '',
            dialog: {},
			snackbar: {
				open: false,
				message: ""
			},
            fields: Object.assign([], defaultFields),
        };
        this.cancelUpload = undefined;
    }
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        this.clearSession();
        this.loadData();
    }
    loadData = () => {
        if (this.props.match && this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            fetch('/BOP/GetBOP/' + this.props.match.params.id)
                .then(this.handleErrors)
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        if (data) {
                            if (!data.FIDSupplierVat) {
                                data.FIDSupplierVat = this.props.params.STDFIDVat;
                            }
                            if (!data.FIDSupplierCurrency) {
                                data.FIDSupplierCurrency = this.props.params.STDFIDCurrency;
                            }
                            if (!data.SupplierCurrencyChange) {
                                data.SupplierCurrencyChange = this.props.params.STDCurrencyChange;
                            }
                        }
                        state.data = data;
                        if (state.firstLoad) {
                            if (state.data.CatalogCode)
                                state.data.IsVariation = true;
                            state.initialData = JSON.parse(JSON.stringify(state.data));
                        }
						var pageTitle = (data.Brand ? (data.Brand + " - ") : "") + (data.SupplierCode ? data.SupplierCode + " - " : "") + data.Code;
						this.props.setTitle(pageTitle);
                        state.firstLoad = false;
                        return state;
                    }, () => {
                        this.loadExtras();
                        //this.grdFiles.current.getWrappedInstance().refresh();
                        this.updateFields();
                        this.fixMeasureUnits();
                    });
                }).catch(error => { console.log(error) });
        }
        else {
			fetch("/BOP/GetNewCode").then(res => res.json()).then(
				res => {
					this.setState(state => {
						if (state.firstLoad) {
							state.data.Code = res.newCode;
							state.data.CatalogCode = res.newCode;
							if (state.data.CatalogCode)
								state.data.IsVariation = true;
							state.initialData = JSON.parse(JSON.stringify(state.data));
						}
						state.firstLoad = false;
						var pageTitle = "Nuova distinta: " + state.data.Code;
						this.props.setTitle(pageTitle);
						return state;
					},() =>{            
						this.updateFields();
					});
				})
				.catch(error => {
					console.log(error);
					this.setState(state => {
						if (state.firstLoad) {
							state.initialData = JSON.parse(JSON.stringify(state.data));
						}
					}, () =>{            
						this.updateFields();
					});
				});
        }
    }
    loadExtras = () => {
        var { data } = this.state;
        //var i = 0;
        var params = "";
        if (data.FIDGroup) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDGroup=" + data.FIDGroup;
        }
        if (data.FIDTypology) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDTypology=" + data.FIDTypology;
        }
        if (data.FIDCategory) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDCategory=" + data.FIDCategory;
        }

        fetch('/BOP/GetBOPExtraTabs' + params).then(res => res.json()).then(extratabs => {
            fetch('/BOP/GetBOPExtraGroups' + params).then(res => res.json()).then(extragroups => {
                fetch('/BOP/GetBOPExtras' + params).then(res => res.json()).then(extras => {
                    this.setState({ extratabs, extragroups, extras });
                });
            });
        });
    }
    clearSession = () => {
        fetch('/BOP/ClearSession', {
            method: 'POST'
        }).then(res => {
        });
    }

    showDialog = (buttons, title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        fetch('/BOP/HasChanges/', {
            method: 'POST'
        }).then(res => res.json())
            .then(data => {
                if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                    var buttons = [
                        {
                            label: "SI",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackYes();

                                //this.saveFile();
                            }
                        },
                        {
                            label: "NO",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackNo();
                            }
                        },
                        {
                            label: "ANNULLA",
                            action: () => {
                                this.setState({ dialog: {} });
                                callbackCancel();
                            }
                        },
                    ];

                    this.showDialog(buttons, "Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?");
                }
                else {
                    callbackNoChanges();
                }
            });
    }
    duplicate = () => {
        this.MessageBox.current.getWrappedInstance().showMessageYesNo("DUPLICAZIONE", "Confermi la duplicazione della distinta?", () =>  {
            var { data } = this.state;
			var cloneID = data.ID;
            var currentTab = this.tabs.current.getCurrentTab();
            data.ID = 0;
            data.Code = "";
			data.CatalogCode = "";
			this.setState({isLoading: true}, () => {
				fetch('/BOP/SaveBOP/0?duplicateFrom='+cloneID, {
					body: JSON.stringify(data),
					method: 'POST'
				}).then(this.handleErrors).then(res => res.json()).then((data) => {             
					this.context.router.history.replace('/BOPEdit/' + data.id);
					this.setState({ firstLoad: true }, () => {
						this.loadData();
						this.setState({ isLoading: false, snackbar: { open: true, message: "Distinta duplicata correttamente" } }, () => {
							this.tabs.current.goToTab(0);
							var pageTitle = (data.Brand ? (data.Brand + " - ") : "") + (data.SupplierCode ? data.SupplierCode + " - " : "") + data.Code;
							this.props.setTitle(pageTitle);
						});
					});
					
				})
				.catch(error => {
					console.log(error);
					this.setState({ isLoading: false }, () => {
						this.tabs.current.goToTab(currentTab);
					});
				});
			});
        },
        () =>{ })
    }
	duplicateVariant = () => {
		this.MessageBox.current.getWrappedInstance().showMessageYesNo("DUPLICAZIONE", "Confermi la duplicazione della variante?", () =>  {
            var { data } = this.state;
			var cloneID = data.ID;
            var currentTab = this.tabs.current.getCurrentTab();
            data.ID = 0;
			data.Code = "";
			this.setState({isLoading: true}, () => {
				fetch('/BOP/SaveBOP/0?duplicateFrom='+cloneID+'&variant=1', {
					body: JSON.stringify(data),
					method: 'POST'
				}).then(this.handleErrors).then(res => res.json()).then((data) => {             
					this.context.router.history.replace('/BOPEdit/' + data.id);
					this.setState({ firstLoad: true }, () => {
						this.loadData();
						this.setState({ isLoading: false, snackbar: { open: true, message: "Variante duplicata correttamente" } }, () => {
							this.tabs.current.goToTab(0);
							var pageTitle = (data.Brand ? (data.Brand + " - ") : "") + (data.SupplierCode ? data.SupplierCode + " - " : "") + data.Code;
							this.props.setTitle(pageTitle);
						});
					});
					
				})
				.catch(error => {
					console.log(error);
					this.setState({ isLoading: false }, () => {
						this.tabs.current.goToTab(currentTab);
					});
				});
			});
        },
        () =>{ })
	}

    doExit = () => {
        var { onExit } = this.props;
        var { data } = this.state;
        fetch('/BOP/ClearSession', {
            method: 'POST'
        }).then(res => {

            if (onExit) {
                onExit(data.ID);
            }
            else {
                window.history.back();
                this.props.setTitle("Distinte");
            }
            //this.context.router.history.goBack();
            //debugger;
            //this.context.router.history.push('/catalogue/');
        });
    }
    doNewData = () => {
        fetch('/BOP/ClearSession', {
            method: 'POST'
        }).then(res => {
            this.context.router.history.push('/BOPNew/');
        });
    }
    handleNewData = (event, caller) => {
        this.checkForChanges(event, caller,
            this.doNewData,
            () => {
                this.handleSaveData(event, this, () => {
                    this.doExit();
                });
            },
            this.doNewData,
            () => {
            }
        );
    }

    handleDuplication = (event, caller) => {
        this.checkForChanges(event, caller,
            this.duplicate,
            () => {
                this.handleSaveData(event, this, () => {
                    this.duplicate();
                });
            },
            this.duplicate,
            () => {}
        );
    }
	
	handleDuplicationVariant = (event, caller) => {
        this.checkForChanges(event, caller,
            this.duplicateVariant,
            () => {
                this.handleSaveData(event, this, () => {
                    this.duplicateVariant();
                });
            },
            this.duplicate,
            () => {}
        );
    }

    handleExit = (event) => {
        this.checkForChanges(event, this,
            this.doExit,
            () => {
                this.handleSaveData(event, this, () => {
                    this.doExit();
                });
            },
            this.doExit,
            () => { }
        );
    }
    handleSaveData = (event, caller, callback) => {
        if (this.validateTabGeneral(() => {
            this.doSaveData(callback);
        })) {
            this.doSaveData(callback);
        }
    }
    handleCancelData = (event, caller) => {
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined;
        if (!FIDBOP) {
            FIDBOP = 0;
        }
        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.setState({ dialog: {} });

                    this.props.setTitle(undefined);
                    fetch('/BOP/DeleteBOP', {
                        method: 'POST',
                        body: JSON.stringify([FIDBOP])
                    }).then(res => {
                        var catData = JSON.parse(getCookie('catalogue_state'));
                        if (catData) {
                            catData.showItem = false;
                            catData.reloadData = true;
                            setCookie('catalogue_state', JSON.stringify(catData));
                        }
                        this.doExit();
                    });
                }
            },
            {
                label: "NO",
                action: () => {
                    this.setState({ dialog: {} });
                }
            },
        ];

        this.showDialog(buttons, "Cancellazione", "Confermi la cancellazione della distinta?");


    }
    doSaveData = (callback) => {
        var currentTab = this.tabs.current.getCurrentTab();
        this.setState({ isLoading: true }, () => {
            var { data } = this.state;
            var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined;
            if (!FIDBOP) {
                FIDBOP = 0;
            }
            if (data.Barcode) {
                this.save(FIDBOP, data, callback, currentTab);
                return;
            }
			Promise.all([
				fetch('/BOP/CheckCodeExists?ID='+FIDBOP+'&code='+data.Code), 
				fetch('/BOP/CheckSupplierCode?ID='+FIDBOP+'&supplierCode='+data.SupplierCode+'&FIDSupplier='+data.FIDSupplier)
			]).then(([checkCode, checkSupplier]) => { 
                return Promise.all([checkCode.json(), checkSupplier.json()]) 
            }).then(([checkCode, checkSupplier]) => {
				
				if (checkCode) {
					this.setState({ isLoading: false }, () => {
						this.showDialog([
							{
								label: "OK",
								action: () => {
									this.setState({ dialog: {} });
									this.tabs.current.goToTab(currentTab);
								}
							}
						], "CODICE DUPLICATO", "Impossibile proseguire nel salvataggio, esiste già una distinta con questo codice.");
					});
				}
				else if (checkSupplier) {
					this.setState({ isLoading: false }, () => {
						this.showDialog([
							{
								label: "SI",
								action: () => {
									this.setState({ dialog: {} });
                                    this.save(FIDBOP, data, callback, currentTab);
								}
							},
							{
								label: "NO",
								action: () => {
									this.setState({ dialog: {} });
									this.tabs.current.goToTab(currentTab);
								}
							}
						],"CODICE FORNITORE DUPLICATO", "Esiste già una distinta con questo codice fornitore. Procedere lo stesso?");
					});
				} 
				else {
					if (data.DefaultVariation && data.CatalogCode) {
						fetch('/BOP/CheckDefaultVariation?ID=' + FIDBOP + '&catalogCode='+data.CatalogCode).then(res => res.json())
						.then(res => {
							if (res) {
								this.setState({ isLoading: false }, () => {
									this.showDialog([
										{
											label: "SOSTITUISCI",
											action: () => {
												this.setState({ dialog: {} });
												this.save(FIDBOP, data, callback, currentTab);
											}
										}, 
										{
											label: "MANTIENI",
											action: () => {
												this.setState({ dialog: {} });
												data.DefaultVariation = false;
												this.save(FIDBOP, data, callback, currentTab);
											}
										}, 
										{
											label: "ANNULLA",
											action: () => {
												this.setState({ dialog: {} });
											}
										}
									], "VARIANTE PREDEFINITA", "E' stata rilevata una variante predefinita per questo codice catalogo. E' possibile effettuare la sostituzione della variante predefinita con la distinta corrente, mantenere l'attuale variante predefinita o annullare il salvataggio. Come si desidera procedere?");
								});
							} else {
								this.save(FIDBOP, data, callback, currentTab);
							}
						})						
					}
					else {
						this.save(FIDBOP, data, callback, currentTab);
					}	
				}
			})
        });
    }
	
	save = (FIDBOP, data, callback, currentTab) => {
		var supplierSN = data.SupplierSN;
		this.setState({ isLoading: true }, () => {
			fetch('/BOP/SaveBOP/' + FIDBOP, {
				body: JSON.stringify(data),
				method: 'POST'
			}).then(this.handleErrors).then(res => res.json()).then((data) => {
                var bopID = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
				if (bopID === undefined || parseInt(bopID, 10) === 0) {
                    if (this.props.onExit) {
                        this.props.onExit(data.id, supplierSN);
                    }
                    else {
					this.context.router.history.replace('/BOPEdit/' + data.id);
					    if (callback) {
					    	callback();
                        }
                    }
				}
				this.setState({ firstLoad: true }, () => {
					this.loadData();
					this.setState({ isLoading: false }, () => {
						this.tabs.current.goToTab(currentTab);
						var pageTitle = (data.Brand ? (data.Brand + " - ") : "") + (data.SupplierCode ? data.SupplierCode + " - " : "") + data.Code;
						this.props.setTitle(pageTitle);
						if (callback) {
							callback();
						}
					});
				});
				
			})
			.catch(error => {
				console.log(error);
				this.setState({ isLoading: false }, () => {
					this.tabs.current.goToTab(currentTab);
				});
			});
		})
	}

    onFileSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.file[key] = data[0].ID;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }
    onFileChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.file[key] = data;
            }
            else {
                state.file[key] = undefined;
            }
            return state;
        });
    }

    onStoreSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.store[key] = data[0].ID;
            }
            else {
                state.store[key] = undefined;
            }
            return state;
        }, () => {
           
        });

    }
    onPricelistSelectChange = (key) => (event, data) => {
        this.setState(state => {
            if (data.length > 0) {
                state.pricelist[key] = data[0].ID;
            }
            else {
                state.pricelist[key] = undefined;
            }
            return state;
        }, () => {
            if (key === "FIDVat") {
                var vat = this.cboFIDVat.current.getSelectedData();
                this.setState(state => {
                    var { pricelist } = state;
                    pricelist.VatPercentage = vat.Percentage;
                    state.pricelist = this.calculateTotals(pricelist);
                    return state;
                })
            }
        });

    }
    calculateTotals = (pricelist) => {

        //price = data.PriceCurrency + " " + numeral(data.Price).format('0,0.00');
        //numeral(data.Price).format('0,0.00')
        var vatPerc = parseFloat(pricelist.VatPercentage);

        if (isNaN(vatPerc))
            vatPerc = 0;

        pricelist.TotalPrice = (pricelist.TaxablePrice * (100 + vatPerc) / 100).toFixed(2)
        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + vatPerc) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }
    calculateDiscountedTotals = (pricelist) => {
        var vatPerc = parseFloat(pricelist.VatPercentage);
        if (isNaN(vatPerc))
            vatPerc = 0;

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + vatPerc) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }
    calculateDiscountTaxable = (pricelist) => {
        var vatPerc = parseFloat(pricelist.VatPercentage);
        if (isNaN(vatPerc))
            vatPerc = 0;

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        pricelist.Discount = ((pricelist.TaxablePrice - pricelist.DiscountedTaxablePrice) * 100 / pricelist.TaxablePrice).toFixed(5);
        pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + vatPerc) / 100).toFixed(2);
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + vatPerc) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }
    calculateDiscountTotal = (pricelist) => {

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        pricelist.Discount = ((pricelist.TotalPrice - pricelist.DiscountedTotalPrice) * 100 / pricelist.TotalPrice).toFixed(5);
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
        }
        return pricelist;
    }
    calculateTaxablePrice = (pricelist) => {
        var vatPerc = parseFloat(pricelist.VatPercentage);
        if (isNaN(vatPerc))
            vatPerc = 0;

        if (!pricelist.Discount) {
            pricelist.Discount = 0;
        }
        pricelist.TaxablePrice = (pricelist.TotalPrice * 100 / (100 + vatPerc)).toFixed(2);
        if (pricelist.Discount > 0) {
            pricelist.DiscountedTaxablePrice = (pricelist.TaxablePrice * (100 - pricelist.Discount) / 100).toFixed(2);
            pricelist.DiscountedTotalPrice = (pricelist.DiscountedTaxablePrice * (100 + vatPerc) / 100).toFixed(2);
        }
        else {
            pricelist.DiscountedTaxablePrice = pricelist.TaxablePrice;
            pricelist.DiscountedTotalPrice = pricelist.TotalPrice;
        }
        return pricelist;
    }

    onPricelistChange = (key) => (event, data) => {
        this.setState(state => {
            if (data) {
                state.pricelist[key] = data;
            }
            else {
                state.pricelist[key] = undefined;
            }
            return state;
        }, () => {
                if (key === "TaxablePrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateTotals(pricelist);
                        return state;
                    });
                }
                else if (key === "Discount") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateDiscountedTotals(pricelist);
                        return state;
                    });
                }
                else if (key === "DiscountedTaxablePrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateDiscountTaxable(pricelist);
                        return state;
                    });
                }
                else if (key === "DiscountedTotalPrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateDiscountTotal(pricelist);
                        return state;
                    });
                }
                else if (key === "TotalPrice") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateTaxablePrice(pricelist);
                        return state;
                    });
                }
                else if (key === "VatPercentage") {
                    this.setState(state => {
                        var { pricelist } = state;
                        state.pricelist = this.calculateTotals(pricelist);
                        return state;
                    });
                }

        });
    }
    handleFileNew = () => {
        this.setState({ isFileEdit: true, file: {} });
    }
    handlePricelistNew = () => {
        this.setState({ isPricelistEdit: true, pricelist: {} });
    }
    handlePricelistCancel = () => {
        this.setState({ isPricelistEdit: false, pricelist: {} });
        this.grdPricelists.current.getWrappedInstance().refresh();
    }
    handleStoreNew = () => {
        this.setState({ isStoreEdit: true, store: {} });
    }
    handleStoreCancel = () => {
        this.setState({ isStoreEdit: false, store: {} });
    }
    handleCancelFile = () => {
        this.setState({ isFileEdit: false, file: {} });
    }
    handleRelatedNew = () => {
        this.setState({ isBOPRelatedEdit: true, bopRelated: {}})
    }
    handleRelatedCancel = () => {
        this.setState({ isBOPRelatedEdit: false, bopRelated: {}})
        this.grdBOPRelateds.current.getWrappedInstance().refresh();
    }
    addRelatedBOPs = (relateds) => {
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        var { bopRelatedView } = this.state;

        if (!bopRelatedView)
            bopRelatedView = 'R';

        var fetchUrl = '/BOP/SaveBOPRelateds/';

        if (bopRelatedView === "P")
            fetchUrl = '/BOP/SaveBOPParures/';

        fetch(fetchUrl + FIDBOP, {
            body: JSON.stringify(relateds),
            method: 'POST'
        }).then(res => {
            this.setState({ isBOPRelatedEdit: false });
            this.grdBOPRelateds.current.getWrappedInstance().refresh();
        })
    }
    saveFile = () => {

        var { file } = this.state;

        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        this.setState({ isUploading: true, uploadProgress: 0 });
        
        const data = new FormData();

        data.append('file', file.File, file.File.name);
        data.append('FIDFileTypology', file.FIDFileTypology);
        data.append('Default', file.Default ? true : false);
        axios
            .post('/BOP/UploadFile/' + FIDBOP, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
                },
				cancelToken: new CancelToken(c => {
					this.cancelUpload = c;
				})
            })
            .then(res => {
				this.setState({ isFileEdit: false, file: {}, isUploading: false });
                this.grdFiles.current.getWrappedInstance().refresh();
            })
			.catch(err => {
				this.setState({ isUploading: false });
			});

    }
    updateDefaultCost = (pricelist) =>{
        var { data } = this.state;
         
        if([1,3].includes(data.CostOrigin)){
            this.setState(state =>{
                state.data.SupplierPublicPrice = pricelist.TotalPrice;
                state.data.SupplierPublicTaxablePrice = pricelist.TaxablePrice;
                
                if([1,2].includes(state.data.CalculationType)){
                    state.data.SupplierCost = this.calculateSupplierCost(state);
                }
                else {
                    state.data.SupplierDiscountDesc = this.calculateSupplierDiscount(state).toString(10);
                }
                return state;
            }, this.saveDefaultSupplier);
        }
    }
    savePricelist = () => {

        var { pricelist } = this.state;

        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        fetch('/BOP/SavePricelist/' + FIDBOP, {
            body: JSON.stringify(pricelist),
            method: 'POST'
        })
            .then(res => res.json()).then(data => {
                this.setState({ isPricelistEdit: false, pricelist: {} });
                this.grdPricelists.current.getWrappedInstance().refresh();
                if(pricelist.Default){
                    this.updateDefaultCost(pricelist);
                }
            });

    }
    saveStore = () => {

        var { store } = this.state;

        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        fetch('/BOP/SaveStore/' + FIDBOP, {
            body: JSON.stringify(store),
            method: 'POST'
        })
            .then(res => res.json()).then(data => {
                this.setState({ isStoreEdit: false, store: {} });
                this.grdStores.current.getWrappedInstance().refresh();
            });

    }
    handleSaveFile = () => {
        var { file } = this.state;
        if (file.File === {}) {
            return;
        }
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined

        if (file.Default) {
            var tempFile = {
                ID: file.ID,
                FIDFileTypology: file.FIDFileTypology
            };
            if (!tempFile.ID) {
                tempFile.ID = 0;
            }
            fetch('/BOP/CheckDefaultFiles/' + FIDBOP, {
                body: JSON.stringify(tempFile),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.saveFile();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
                    }
                    else {
                        this.saveFile();
                    }
                });
        }
        else {
            this.saveFile();
        }

    }

    handlePricelistSave = () => {
        var { pricelist } = this.state;
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined

        if (pricelist.Default) {
            var tempPricelist = {
                ID: pricelist.ID,
            };
            if (!tempPricelist.ID) {
                tempPricelist.ID = 0;
            }
            fetch('/BOP/CheckDefaultPricelists/' + FIDBOP, {
                body: JSON.stringify(tempPricelist),
                method: 'POST'
            }).then(res => res.json())
                .then(result => {
                    if (result.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.savePricelist();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    //this.saveContact(FIDIdentity, isDestination, isRelation, data);
                                }
                            },
                        ];

                        this.showDialog(buttons, "Listino predefinito", "È già presente un listino predefinito. Confermi inserimento?");
                    }
                    else {
                        this.savePricelist();
                    }
                });
        }
        else {
            this.savePricelist();
        }

    }
    handleStoreSave = () => {
        this.saveStore();
    }

    handleDataChange = (event, value, key, data, callback) => {
        var oldValue = this.state.data[key];
        this.setState((state, props) => {
            state.data[key] = value;

            state.data = this.collectionsFix(data, state.data, key);
			if (["FIDSupplierMarkup","CalculationType"].includes(key)) {
				if (key === "CalculationType") {
					if (value < 5) {
						state.data.SupplierMarkupMultiplier = undefined;
						state.data.FIDSupplierMarkup = undefined;
					} else {
						state.data.SupplierDiscountDesc = undefined;
						state.data.SupplierDiscount = 0;
					}
				}
				if (key === "FIDSupplierMarkup") {
					state.data.SupplierMarkupMultiplier = data.Multiplier;
				}		
							
				if (state.data.SupplierCost > 0 && state.data.FIDSupplierMarkup) {
					if (state.data.CalculationType === 5) {
						state.data.SupplierPublicPrice = this.calculatePublicPrice(state);
						state.data.SupplierPublicTaxablePrice = Math.round(state.data.SupplierPublicPrice / 1.22 * 100) / 100;
					} else if (state.data.CalculationType === 6) {
						state.data.SupplierPublicTaxablePrice = this.calculatePublicPrice(state);
						state.data.SupplierPublicPrice = Math.round(state.data.SupplierPublicTaxablePrice * 1.22 * 100) / 100;
					}
				}                                                                        
				else {
					state.data.SupplierCost = this.calculateSupplierCost(state);
				}
			}
            //if (key === "Denomination" && !state.data["Alias"]) {
            //    state.data["Alias"] == value;
            //}
            return state;
        }, () => {
            if (["FIDGroup", "FIDTypology", "FIDCategory"].includes(key)) {
                this.loadExtras();
            }
            if(["FIDGroup","FIDBrand","FIDLine","FIDSeries"].includes(key)) {
                this.getSupplierSettings();
            }
            
            if("FIDMeasureUnit1" === key){
                if(!this.state.data.FIDMeasureUnitCost || this.state.data.FIDMeasureUnitCost === oldValue) {
                    this.setState(state =>{
                        state.data.FIDMeasureUnitCost = value;
                        return state;
                    });
                }
            }
            if (["FIDMeasureUnitCost", "FIDMeasureUnit1", "FIDMeasureUnit2", "MeasureUnitQty1", "MeasureUnitQty2"].includes(key)) {
                this.fixMeasureUnits();
                this.fixCosts();
            }
			if (["FIDSupplierMarkup","CalculationType"].includes(key)) {
				if (!this.state.data.FIDSupplierMarkup) {
					//this.setState(state => {
					//	state.data.SupplierPublicPrice = this.calculatePublicPrice(state);
					//},
					this.getDefaultPrice();
					//);
				}
			}
            callback && callback();
        });
    }
    collectionsFix = (data, db, key) => {

        if (["FIDLine", "FIDSeries"].includes(key) && data && data.ID > 0) {
            db["FIDBrand"] = data.FIDBrand;
        }
        if (["FIDSeries"].includes(key) && data && data.ID > 0) {
            db["FIDLine"] = data.FIDLine;
        }
        if (["FIDBrand", "FIDLine"].includes(key)) {
            if (db["FIDSeries"] >= 0)
                db["FIDSeries"] = undefined;
        }
        if (["FIDBrand"].includes(key)) {
            if (db["FIDLine"] >= 0)
                db["FIDLine"] = undefined;
        }
        return db;
    }

    handleChange = (key) => (event, data, callback) => {
        if (data.length > 0) {
            this.handleDataChange(event, data[0].ID, key, data[0], callback);
        }
        else {
            this.handleDataChange(event, undefined, key, undefined, callback);
        }
        //if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
        //    this.setState({ ReloadFields: true });
        //}
    }
    handleFieldChange = (key) => (event, value) => {
        if (this.handleDataChange) {
            this.handleDataChange(event, value, key);
        }
        
        //if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
        //    this.setState({ ReloadFields: true });
        //}
    }
    
    handleFieldBlur = (key) => (event) => {
        var { data } = this.state;
        if(key === "Code" && !data.CatalogCode){
            this.setState(state =>{
                state.data.CatalogCode = state.data.Code;
                return state;
            });
        }
    }
    fixCosts = () => {
        this.setState(state => {
            var MeasureUnitQty = (state.data.FIDMeasureUnitCost === state.data.FIDMeasureUnit1 ? state.data.MeasureUnitQty1 : state.data.MeasureUnitQty2);
            if(!MeasureUnitQty){
                MeasureUnitQty = 0;
            }
            MeasureUnitQty = parseFloat(MeasureUnitQty);
            if(state.data.UnitCost && MeasureUnitQty) {
                state.data.TotalCost = state.data.UnitCost * MeasureUnitQty;
            }
            return state;
        });
    }
    fixMeasureUnits = () => {
        this.setState(state => {
            var { fields, data } = state;
            var MeasureUnitQty = (data.FIDMeasureUnitCost === data.FIDMeasureUnit1 ? data.MeasureUnitQty1 : data.MeasureUnitQty2);
            if(!MeasureUnitQty){
                MeasureUnitQty = 0;
            }
            MeasureUnitQty = parseFloat(MeasureUnitQty);
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].Field === "UnitCost") {
                    fields[i].Visibility = MeasureUnitQty !== 1;
                    break;
                }
            }
            state.fields = fields;
            return state;
        });
    }

    renderPanelGeneral = () => {
        var { fields, data } = this.state;
		var { showSupplierSN } = this.props;
        var fieldGroup = getXSelect(data, fields, 12, 4, "FIDGroup", "Gruppo", this.handleChange, "/BOP/Group", "/BOP/Group");
        var fieldTypology = getXSelect(data, fields, 12, 4, "FIDTypology", "Tipologia", this.handleChange, "/BOP/Typology");
        var fieldCategory = getXSelect(data, fields, 12, 4, "FIDCategory", "Tag principale", this.handleChange, "/BOP/Category", "/BOP/Category");
        var fieldTag = getXTag(data, fields, 12, 8, "TagIds", "Tag", this.handleFieldChange, "/BOP/Tag", "/BOP/Tag");

        var fieldBrand = getXSelect(data, fields, 12, 4, "FIDBrand", "Marca", this.handleChange, "/BOP/Brand", "/BOP/Brand");
        var fieldLine = getXSelect(data, fields, 12, 4, "FIDLine", "Collezione", this.handleChange, "/BOP/Line", "/BOP/Line", { filters: [{ key: "FIDBrand", value: data.FIDBrand }] });
        var fieldSeries = getXSelect(data, fields, 12, 4, "FIDSeries", "Serie", this.handleChange, "/BOP/Series", "/BOP/Series", { filters: [{ key: "FIDBrand", value: data.FIDBrand }, { key: "FIDLine", value: data.FIDLine }] });

        var fieldCode = getXField(data, fields, 12, 4, "Code", "Codice", this.handleFieldChange, { onBlur: this.handleFieldBlur});
        var fieldCatalogCode = getXField(data, fields, 12, 4, "CatalogCode", "Codice catalogo", this.handleFieldChange);
        var fieldAbbreviation = getXSelect(data, fields, 12, 4, "FIDAbbreviation", "Sigla", this.handleChange, "/BOP/Abbreviation", "/BOP/Abbreviation");

        var fieldGender = getXSelect(data, fields, 12, 4, "FIDGender", "Genere", this.handleChange, "/BOP/Gender", "/BOP/Gender");
        var fieldRecurrency = getXSelect(data, fields, 12, 4, "FIDRecurrency", "Ricorrenza", this.handleChange, "/BOP/Recurrency", "/BOP/Recurrency");
        var fieldStatus = getXSelect(data, fields, 12, 4, "FIDStatus", "Stato", this.handleChange, "/BOP/Status", "/BOP/Status");

        var fieldConditions = getXField(data, fields, 12, 12, "Conditions", "Condizioni", this.handleFieldChange);

        var fieldDescription = getXField(data, fields, 12, 12, "Description", "Descrizione", this.handleFieldChange,{ multiline: true } );
        var fieldExtendedDescription = getXField(data, fields, 12, 12, "ExtendedDescription", "Descrizione estesa", this.handleFieldChange, { multiline: true });

        if (!data.CatalogCode)
            data.IsVariation = false;
        else
            data.IsVariation = true;

        if (data.DefaultVariation === undefined || data.DefaultVariation === null)
            data.DefaultVariation = false;

        var fieldDefaultVariation = data.IsVariation ? getXSwitch(data, fields, 12, 4, "DefaultVariation", "Variante predefinita", this.handleFieldChange) : undefined;

        var fieldSupplierSN = getXField(data, fields, 12, 3, "SupplierSN", "Matricola fornitore", this.handleFieldChange);
		
		if (showSupplierSN) {
			fieldCatalogCode = getXField(data, fields, 12, 3, "CatalogCode", "Codice catalogo", this.handleFieldChange);
			fieldDefaultVariation = data.IsVariation ? getXSwitch(data, fields, 12, 2, "DefaultVariation", "Variante predefinita", this.handleFieldChange) : undefined;
		}

        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];

        return (
            <Grid container>
                <Grid item xs={12} md={5}>
                    <XGallery imageIds={files} />
                </Grid>
                <Grid container xs={12} md={7} spacing={2} alignItems="flex-start" justify="flex-start" alignContent="flex-start">
                    {fieldTypology}
                    {fieldGroup}
                    {fieldAbbreviation}
                    {fieldCategory}
                    {fieldTag}
                    {fieldCode}
                    {fieldCatalogCode}
					{showSupplierSN && fieldSupplierSN}
                    {fieldDefaultVariation ? fieldDefaultVariation : (showSupplierSN ? <Grid item xs={12} md={2} /> : <Grid item xs={12} md={4} />)}
                    {fieldBrand}
                    {fieldLine}
                    {fieldSeries}
                </Grid>
                <Grid container xs={12} spacing={2} md={12} alignItems="flex-start" justify="flex-start" alignContent="flex-start">
					<Grid container item xs={12} md={5}>
						{fieldDescription}
						<Grid container item xs={12} spacing={2}>{fieldGender}{fieldRecurrency}{fieldStatus}</Grid>
					</Grid>
					<Grid item xs={12} md={7}>
						{fieldExtendedDescription}
						{fieldConditions}
					</Grid>
				</Grid>
            </Grid>
        );
    }
    renderPanelCollections = () => {

        var { fields, data } = this.state;

        var fieldBrand = getXSelect(data, fields, 12, 3, "FIDBrand", "Marca", this.handleChange, "/BOP/Brand", "/BOP/Brand");
        var fieldLine = getXSelect(data, fields, 12, 3, "FIDLine", "Collezione", this.handleChange, "/BOP/Line", "/BOP/Line", { filters: [{ key: "FIDBrand", value: data.FIDBrand }] });
        var fieldSeries = getXSelect(data, fields, 12, 3, "FIDSeries", "Serie", this.handleChange, "/BOP/Series", "/BOP/Series", { filters: [{ key: "FIDBrand", value: data.FIDBrand }, { key: "FIDLine", value: data.FIDLine }] });

        return (
            <Grid container spacing={2}>
                {fieldBrand}
                {fieldLine}
                {fieldSeries}
            </Grid>
        );
    }
    renderPanelMeasureUnits = () => {

        var { fields, data } = this.state;
        var fieldMeasureUnit1 = getXSelect(data, fields, 12, 2, "FIDMeasureUnit1", "U.M. principale", this.handleChange, "/Base/GetGCMeasureUnits", undefined,{ filtersExclude: [{ key: "ID", value: data.FIDMeasureUnit2 }] });
        var fieldMeasureUnitCost1 = getXField(data, fields, 12, 2, "MeasureUnitQty1", "Q.tà U.M. principale", this.handleFieldChange, { type: "number" });
        var fieldMeasureUnit2 = getXSelect(data, fields, 12, 2, "FIDMeasureUnit2", "U.M. secondaria", this.handleChange, "/Base/GetGCMeasureUnits", undefined, { filtersExclude: [{ key: "ID", value: data.FIDMeasureUnit1 }] });
        var fieldMeasureUnitCost2 = getXField(data, fields, 12, 2, "MeasureUnitQty2", "Q.tà U.M. secondaria", this.handleFieldChange, { type: "number" });
        var fieldMeasureUnitCost = getXSelect(data, fields, 12, 2, "FIDMeasureUnitCost", "Opzione di costo", this.handleChange, "/Base/GetGCMeasureUnits", undefined, { filtersOr: [{ key: "ID", value: data.FIDMeasureUnit1 }, { key: "ID", value: data.FIDMeasureUnit2 }] });
        var fieldCalculationTypeQty = getXSelect(data, fields, 12, 2, "CalculationTypeQty", "Tipo calcolo q.tà", this.handleChange, "/BOP/calculationtypeqty");


        return (
            <Grid container spacing={2}>
                {fieldMeasureUnit1}
                {fieldMeasureUnitCost1}
                {fieldMeasureUnit2}
                {fieldMeasureUnitCost2}
                {fieldMeasureUnitCost}
                {fieldCalculationTypeQty}
            </Grid>
        );
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
    }
    getDefaultPricelist = async () =>{
        var FIDDefaultPricelist = await fetch(`/BOP/GetDefaultPricelist`).then(res => res.json());
        await this.setStateAsync(state =>{
            state.data.FIDSalesPricelist = FIDDefaultPricelist;
            return state;
        });
    }

    getDefaultPrice = async () =>{
        if(!this.state.data.FIDSalesPricelist){
            await this.getDefaultPricelist();
        }

        var { data } = this.state;


		if (data.FIDSalesPricelist) {
			var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
			if (!FIDBOP) {
				FIDBOP = 0;
			}
			
			var price = await fetch(`/BOP/GetDefaultPrice/${FIDBOP}?FIDPricelist=${data.FIDSalesPricelist}`).then(res => res.json());
			
			this.setState(state => {
				state.data.SupplierPublicPrice = parseFloat(price.item2);
				state.data.SupplierPublicTaxablePrice = parseFloat(price.item1);
				state.data.SupplierCost = this.calculateSupplierCost(state);
				state.data.UnitCost = state.data.SupplierCost;
				state.data.TotalCost = this.calculateTotalCost(state);
				return state;
			});
			if([1,3].includes(data.CostOrigin)){
				
			} else if ([2,4].includes(data.CostOrigin)) {
				
			} else if (data.CostOrigin === 5) {
				
			}
		}
    }
    getSupplierSettings = async () =>{
        var { data } = this.state;
		
        if(data.FIDSupplier){
            var distr = await fetch(`/IR/GetDistributionInfo/${data.FIDSupplier}?FIDGroup=${data.FIDGroup}&FIDBrand=${data.FIDBrand}&FIDLine=${data.FIDLine}&FIDSeries=${data.FIDSeries}`).then(res => res.json());
            this.setState(state =>{
                state.data.CalculationType = distr.calculationType;
                state.data.SupplierDiscountDesc = distr.formula;
				state.data.FIDSupplierMarkup = distr.fidMarkup;
				state.data.SupplierMarkupMultiplier = distr.markupMultiplier;
                state.data.SupplierCost = this.calculateSupplierCost(state);
                state.data.UnitCost = state.data.SupplierCost;
                state.data.TotalCost = this.calculateTotalCost(state);
                return state;
            },this.updateFields);
        }
        else {
            this.updateFields();
        }
    }
    handleSupplierChange = (key) => async (event, data) => {
        var callback = undefined;
        
        if (key === "CostOrigin") {
            callback = () =>{
                this.getDefaultPrice();
                this.updateFields();
                
            }
        }
        else if(key === "FIDSupplier") {
            callback = () =>{
                this.getSupplierSettings();
            }
        }
        else if(key === "CalculationType"){		
            callback = () =>{
				this.updateFields();
				if (data && data.length > 0 && data[0].ID > 0 && data[0].ID < 5)
					this.getDefaultPrice();
            }
        }
        else if(key === "FIDSalesPricelist"){
            callback = () =>{
				this.updateFields();
                this.getDefaultPrice();
            }
        }
        this.handleChange(key)(event, data, callback);

        setTimeout(this.saveDefaultSupplier, 100);
    }
    saveDefaultSupplier = () => {
        var { data } = this.state;
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined;
        if (!FIDBOP) {
            FIDBOP = 0;
        }

        

        if (data.FIDSupplier && data.FIDSupplierVat && data.FIDSupplierCurrency && data.SupplierCost && data.SupplierPublicPrice && (data.SupplierDiscountDesc || data.FIDSupplierMarkup)) {
            var bopSupplier = {
                FIDIdentity: data.FIDSupplier,
                FIDVat: data.FIDSupplierVat,
                SupplierCost: data.SupplierCost,
                PublicPrice: data.SupplierPublicPrice,
                PublicTaxablePrice: data.SupplierPublicTaxablePrice,
                FIDSalesPricelist: data.FIDSalesPricelist,
                Code: data.SupplierCode,
                CatalogCode: data.SupplierCatalogCode,
				Label: data.SupplierLabel,
                CalculationType: data.CalculationType,             
				DiscountDescLock: data.SupplierDiscountDescLock,
				PublicPriceLock: data.SupplierPublicPriceLock,
				PublicTaxablePriceLock: data.SupplierPublicTaxablePriceLock,
				SupplierCostLock: data.SupplierCostLock,
            };
			
			if (data.FIDSupplierMarkup) {
				bopSupplier.FIDMarkup = data.FIDSupplierMarkup;
				bopSupplier.MarkupMultiplier = data.SupplierMarkupMultiplier;
				bopSupplier.DiscountDesc = "";
				bopSupplier.Discount = 0;
			} else {
				bopSupplier.DiscountDesc = data.SupplierDiscountDesc;
				bopSupplier.FIDMarkup = null;
			}
			
            fetch(`/BOP/UpdateDefaultSupplier/${FIDBOP}`, { method: 'POST', body: JSON.stringify(bopSupplier) }).then(res => res.json()).then(data => {

            });
        }
    }
    calculateSupplierCost = (state) => {
        var case1=[1,2];
        var case2=[3,4];
		var case3=[5,6];
        var taxableCase = [2,4,6];
        var price = state.data.SupplierPublicPrice;
        if(taxableCase.includes(state.data.CalculationType)){
            price = state.data.SupplierPublicTaxablePrice;
        }
        if(case1.includes(state.data.CalculationType)){
            var discount = getDiscount(state.data.SupplierDiscountDesc);
            return Math.round((parseFloat(price) * (1 - discount)) * 100) / 100;
        }
        else if(case2.includes(state.data.CalculationType)){
            var discount = getRecharge(state.data.SupplierDiscountDesc);
            return Math.round((parseFloat(price) / (1 + discount)) * 100) / 100;
        } else if (case3.includes(state.data.CalculationType)) {
			if (state.data.SupplierMarkupMultiplier) {
				return Math.round((parseFloat(price) / (state.data.SupplierMarkupMultiplier || 1)) * 100) / 100;
			}
			return parseFloat(price);
		}
        return 0;
    }
    calculatePublicPrice =(state) =>{
        var case1=[1,2];
        var case2=[3,4];
		var case3=[5,6];
		var taxableCase = [2,4,6];
		var cost = this.state.data.SupplierCost || 0;
        if(case1.includes(state.data.CalculationType)){
            var discount = getDiscount(state.data.SupplierDiscountDesc);
            //if (taxableCase.includes(state.data.CalculationType))
			//	return Math.round((parseFloat(cost) / (1 - discount) / 1.22) * 100) / 100;
			//else
				return Math.round((parseFloat(state.data.SupplierCost) / (1 - discount)) * 100) / 100;
        }
        else if(case2.includes(state.data.CalculationType)){
            var discount = getRecharge(state.data.SupplierDiscountDesc);
			//if (taxableCase.includes(state.data.CalculationType))
			//	return Math.round((parseFloat(cost) * (1 + discount) / 1.22) * 100) / 100;
			//else
				return Math.round((parseFloat(state.data.SupplierCost) * (1 + discount)) * 100) / 100;
        }
		else if (case3.includes(state.data.CalculationType)) {
			if (state.data.SupplierMarkupMultiplier) {
				//if (taxableCase.includes(state.data.CalculationType))
				//	return Math.round((parseFloat(cost) * state.data.SupplierMarkupMultiplier / 1.22) * 100) / 100;
				//else
					return Math.round((parseFloat(cost) * (state.data.SupplierMarkupMultiplier || 1)) * 100) / 100;
			}
			return parseFloat(cost);
		}
        return 0;
    }
    calculateSupplierDiscount = (state) => {
        //return round((1 - state.data.SupplierCost / state.data.SupplierPublicPrice) * 100,4);
        
        var case1=[1,2];
        var case2=[3,4];
        var taxableCase = [2,4];
        var price = state.data.SupplierPublicPrice;
        if(taxableCase.includes(state.data.CalculationType)){
            price = state.data.SupplierPublicTaxablePrice;
        }
        if(case1.includes(state.data.CalculationType)){
            return round((1 - state.data.SupplierCost / price) * 100,4);
        }
        else if(case2.includes(state.data.CalculationType)){
            return round((price / state.data.SupplierCost - 1) * 100,4);
        }
        return 0;
    }
    calculateTotalCost = (state) => {
        if(!state.data.SupplierCost && !(state.data.FIDMeasureUnitCost === state.data.FIDMeasureUnit1 ? state.data.MeasureUnitQty1 : state.data.MeasureUnitQty2)){
            return 0;
        }
        return round(state.data.SupplierCost * (state.data.FIDMeasureUnitCost === state.data.FIDMeasureUnit1 ? state.data.MeasureUnitQty1 : state.data.MeasureUnitQty2),2);
    }
    handleSupplierFieldChange = (key) => (event, value) => {
        this.handleFieldChange(key)(event, value);
        if (key === "SupplierDiscountDesc") {
            this.setState(state => {
                if([1,2].includes(state.data.CalculationType)){
                    state.data.SupplierCost = this.calculateSupplierCost(state);
                    state.data.UnitCost = state.data.SupplierCost;
                    state.data.TotalCost = this.calculateTotalCost(state);
                }
                else {
                    if([1,3,5].includes(state.data.CalculationType)) {
                        state.data.SupplierPublicPrice = this.calculatePublicPrice(state);
                        state.data.SupplierPublicTaxablePrice = Math.round(state.data.SupplierPublicPrice / 1.22 * 100) / 100;

                    }
                    else{
                        state.data.SupplierPublicTaxablePrice = this.calculatePublicPrice(state);
                        state.data.SupplierPublicPrice = Math.round(state.data.SupplierPublicTaxablePrice * 1.22 * 100) / 100;

                    }
                    
                }
                
                return state;
            });
        }
        if(key === "SupplierPublicPrice"){
            this.setState(state => {
                state.data.SupplierPublicTaxablePrice =  Math.round(state.data.SupplierPublicPrice / 1.22 * 100) / 100;
                // if([1,2].includes(state.data.CalculationType)){
                    if(state.data.SupplierCostLock){
                        state.data.SupplierCost = this.calculateSupplierCost(state);
                        state.data.UnitCost = state.data.SupplierCost;
                        state.data.TotalCost = this.calculateTotalCost(state);   
                    }
                    else {
                        state.data.SupplierDiscountDesc = this.calculateSupplierDiscount(state).toString(10); 
                    }
                // }
                // else {
                //     state.data.SupplierDiscountDesc = this.calculateSupplierDiscount(state).toString(10);
                // }
                
                return state;
            });
        }
        if(key === "SupplierPublicTaxablePrice"){
            this.setState(state => {
                state.data.SupplierPublicPrice = Math.round(state.data.SupplierPublicTaxablePrice * 1.22 * 100) / 100;

                // if([1,2].includes(state.data.CalculationType)){
                    if(state.data.SupplierCostLock){
                        state.data.SupplierCost = this.calculateSupplierCost(state);
                        state.data.UnitCost = state.data.SupplierCost;
                        state.data.TotalCost = this.calculateTotalCost(state);   
                    }
                    else {
                        state.data.SupplierDiscountDesc = this.calculateSupplierDiscount(state).toString(10); 
                    }
                // }
                // else {
                //     state.data.SupplierDiscountDesc = this.calculateSupplierDiscount(state).toString(10);
                // }
                
                return state;
            });
        }
        if(key === "SupplierCost"){
            this.setState(state => {
                if([1,2].includes(state.data.CalculationType)){
                    state.data.SupplierDiscountDesc = this.calculateSupplierDiscount(state).toString(10);
                }
                else {
                    if([1,3,5].includes(state.data.CalculationType)) {
                        state.data.SupplierPublicPrice = this.calculatePublicPrice(state);
                        state.data.SupplierPublicTaxablePrice = Math.round(state.data.SupplierPublicPrice / 1.22 * 100) / 100;

                    }
                    else{
                        state.data.SupplierPublicTaxablePrice = this.calculatePublicPrice(state);
                        state.data.SupplierPublicPrice = Math.round(state.data.SupplierPublicTaxablePrice * 1.22 * 100) / 100;

                    }
                }
                state.data.UnitCost = state.data.SupplierCost;
                state.data.TotalCost = this.calculateTotalCost(state);
                return state;
            });
        }
        //setTimeout(this.saveDefaultSupplier, 100);
    }
    handleSupplierBlur = () => () => {
        this.saveDefaultSupplier();
    }
    onLockChange1 = (id) => (isLocked) => {
        this.setState(state => {

            state.data.SupplierPublicPriceLock = true;
            state.data.SupplierPublicTaxablePriceLock = true;
            
            state.data[`${id}Lock`] = isLocked;
            return state;
        },this.saveDefaultSupplier);
    }
    onLockChange2 = (id) => (isLocked) => {
        this.setState(state => {

            state.data.SupplierDiscountDescLock = true;
            state.data.SupplierCostLock = true;
            
            state.data[`${id}Lock`] = isLocked;
            return state;
        },this.saveDefaultSupplier);
    }
    onLockChange3 =(id) => (isLocked) => {
        this.setState(state => {
			state.data.SupplierCostLock = true;
            state.data.SupplierPublicPriceLock = true;
            state.data.SupplierPublicTaxablePriceLock = true;
            state.data.SupplierDiscountDescLock = true;
            //state.data.SupplierCostLock = false;
            
            state.data[`${id}Lock`] = isLocked;
            return state;
        },this.saveDefaultSupplier);

    }
    onSupplierLockChange1 = (id) => (isLocked) => {
        this.setState(state => {

            state.supplier.PublicPriceLock = true;
            state.supplier.PublicTaxablePriceLock = true;
            
            state.supplier[`${id}Lock`] = isLocked;
            return state;
        });
    }
    onSupplierLockChange2 = (id) => (isLocked) => {
        this.setState(state => {

            state.supplier.DiscountDescLock = true;
            state.supplier.SupplierCostLock = true;
            
            state.supplier[`${id}Lock`] = isLocked;
            return state;
        });
    }
    onSupplierLockChange3 =(id) => (isLocked) => {
        this.setState(state => {

            state.supplier.PublicPriceLock = true;
            state.supplier.PublicTaxablePriceLock = true;
            state.supplier.DiscountDescLock = true;
            state.supplier.SupplierCostLock = true;
            
            state.supplier[`${id}Lock`] = isLocked;
            return state;
        });

    }
    updateFields = () => {
        this.setState(state => {
            var { fields, data } = state;
            var setVisibleFields = [];
            var setHiddenFields = [];

            if (data.CostOrigin === 1 || data.CostOrigin === 3) {
                setVisibleFields = ["FIDSupplier", "FIDSupplierVat", "FIDSupplierCurrency", "SupplierCurrencyChange", "SupplierCurrencyChangeOverride", "SupplierPublicPrice","SupplierPublicTaxablePrice", "SupplierCost","SupplierLabel", "SupplierCode", "SupplierCatalogCode","CalculationType","FIDSalesPricelist"];
					
				if (data.CalculationType >= 5) {
					setVisibleFields.push("FIDSupplierMarkup");
					setHiddenFields.push("SupplierDiscountDesc");
				} else {
					setHiddenFields.push("FIDSupplierMarkup");
					setVisibleFields.push("SupplierDiscountDesc");
				}
            }
            else {
                setHiddenFields = ["FIDSupplier", "FIDSupplierVat", "FIDSupplierCurrency", "SupplierCurrencyChange", "SupplierCurrencyChangeOverride", "SupplierPublicPrice","SupplierPublicTaxablePrice", "SupplierDiscountDesc", "SupplierCost", "SupplierLabel",
                    "SupplierCode", "SupplierCatalogCode","CalculationType","FIDSalesPricelist"];
            }
			
			if (this.props.showSupplierSN) {
				setVisibleFields.push("SupplierSN");
			}
			
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (setVisibleFields.includes(field.Field)) {
                    fields[i].Visibility = true;
                }
                else if (setHiddenFields.includes(field.Field)) {
                    fields[i].Visibility = false;
                }
            }
            state.fields = fields;
            return state;
        });
    }
    renderPanelCostOptions = () =>{
        var { fields, data } = this.state;

        if (!data.FIDSupplierVat) {
            data.FIDSupplierVat = this.props.params.STDFIDVat;
        }
        if (!data.FIDSupplierCurrency) {
            data.FIDSupplierCurrency = this.props.params.STDFIDCurrency;
        }
        if (!data.SupplierCurrencyChange) {
            data.SupplierCurrencyChange = this.props.params.STDCurrencyChange;
        }
        var case1 = [1,2];
        var case2 = [3,4];
		var case3 = [5,6];
        var labelFormula = "Sc %";
        if(case2.includes(data.CalculationType)){
            labelFormula = "% ricarico";
        }
        
        var labelPublicPrice = "Prezzo di vendita";
        var labelPublicTaxablePrice= "Prezzo di vendita imponibile";

        var fieldCostOrigin = getXSelect(data, fields, 12, 4, "CostOrigin", FieldLabels.CostOrigin, this.handleSupplierChange, "/enums/bopcostorigin");
        var fieldCostCalculationType = getXSelect(data, fields, 12, 4, "CalculationType", FieldLabels.CalculationType, this.handleSupplierChange, "/enums/boppricecalculationtype");

        var fieldSupplier = getXSelect(data, fields, 12, 8, "FIDSupplier", FieldLabels.FIDSupplier, this.handleSupplierChange, "/ir/getsuppliersselect");
        var fieldSupplierCode = getXField(data, fields, 12, 4, "SupplierCode",FieldLabels.SupplierCode, this.handleSupplierFieldChange, { onBlur: this.handleSupplierBlur });
        var fieldSupplierCatalogCode = getXField(data, fields, 12, 4, "SupplierCatalogCode", FieldLabels.SupplierCatalogCode, this.handleSupplierFieldChange, { onBlur: this.handleSupplierBlur });
		var fieldSupplierLabel = getXField(data, fields, 12, 4, "SupplierLabel", FieldLabels.SupplierLabel, this.handleSupplierFieldChange, { onBlur: this.handleSupplierBlur });

		var fieldSupplierMarkup = getXSelect(data, fields, 12, 4, "FIDSupplierMarkup", "Formula di ricarico", this.handleSupplierChange, "/gc/markup" );
        var fieldSupplierVat = getXSelect(data, fields, 12, 4, "FIDSupplierVat", FieldLabels.FIDSupplierVat, this.handleSupplierChange, "/gc/vat");
        var fieldSupplierCurrency = getXSelect(data, fields, 12, 2, "FIDSupplierCurrency", FieldLabels.FIDSupplierCurrency, this.handleSupplierChange, "/gc/currency");
        var fieldSupplierCurrencyChange = getXField(data, fields, 12, 2, "SupplierCurrencyChange", FieldLabels.SupplierCurrencyChange, this.handleSupplierFieldChange, { type: "number", onBlur: this.handleSupplierBlur });
        

        var onLockChangeGroup1 = this.onLockChange1;
        var onLockChangeGroup2 = this.onLockChange2;

        if(case1.includes(data.CalculationType)){ 
            if(!data.SupplierPublicPriceLock && !data.SupplierPublicTaxablePriceLock) {
                data.SupplierPublicPriceLock = false;
                data.SupplierPublicTaxablePriceLock = true;
            }
            if(!data.SupplierDiscountDescLock && !data.SupplierCostLock) {
                data.SupplierDiscountDescLock = false;
                data.SupplierCostLock = true;
            }
        }
        else if(case2.includes(data.CalculationType)) {
            onLockChangeGroup1 = this.onLockChange3;
            onLockChangeGroup2 = this.onLockChange3;
            data.SupplierCostLock = false;
            if(!data.SupplierPublicPriceLock && !data.SupplierPublicTaxablePriceLock && !data.SupplierDiscountDescLock) {
                data.SupplierDiscountDescLock = false;
                data.SupplierPublicPriceLock = true;
                data.SupplierPublicTaxablePriceLock = true;
            }
        } else if (case3.includes(data.CalculationType)) {
			onLockChangeGroup1 = this.onLockChange3;
			onLockChangeGroup2 = this.onLockChange3;
			if (!data.SupplierCostLock && !data.SupplierPublicPriceLock) {
				data.SupplierCostLock = false;
				data.SupplierPublicPriceLock = true;
			}
            if(!data.SupplierPublicTaxablePriceLock && !data.SupplierDiscountDescLock) {
                data.SupplierDiscountDescLock = true;
                data.SupplierPublicTaxablePriceLock = true;
            }
		}

        var fieldSupplierPublicPrice = getXField(data, fields, 12, 4, "SupplierPublicPrice", labelPublicPrice, this.handleSupplierFieldChange, { type: "number", onBlur: this.handleSupplierBlur, lockable: data.SupplierPublicPriceLock, isLocked: data.SupplierPublicPriceLock, onLockChange: onLockChangeGroup1, readOnly: data.SupplierPublicPriceLock });
        var fieldSupplierPublicTaxablePrice = getXField(data, fields, 12, 4, "SupplierPublicTaxablePrice", labelPublicTaxablePrice, this.handleSupplierFieldChange, { type: "number", onBlur: this.handleSupplierBlur, lockable: data.SupplierPublicTaxablePriceLock, isLocked: data.SupplierPublicTaxablePriceLock, onLockChange: onLockChangeGroup1, readOnly: data.SupplierPublicTaxablePriceLock });
        var fieldSupplierDiscountDesc = getXField(data, fields, 12, 4, "SupplierDiscountDesc", labelFormula, this.handleSupplierFieldChange, { onBlur: this.handleSupplierBlur, lockable: data.SupplierDiscountDescLock, isLocked: data.SupplierDiscountDescLock, onLockChange: onLockChangeGroup2, readOnly: data.SupplierDiscountDescLock });
        var fieldSupplierCost = getXField(data, fields, 12, 4, "SupplierCost", FieldLabels.SupplierCost, this.handleSupplierFieldChange, { type: "number", onBlur: this.handleSupplierBlur, lockable: data.SupplierCostLock, isLocked: data.SupplierCostLock, onLockChange: onLockChangeGroup2, readOnly: data.SupplierCostLock });
        
        var fieldSalePricelist = getXSelect(data, fields, 12, 4, "FIDSalesPricelist", FieldLabels.FIDSalesPricelist, this.handleSupplierChange, "gc/pricelist");


        var spacer1 = !fieldSupplier && <Grid item xs={12} md={10} />;
        var spacer2 = (fieldSupplierCode && fieldSupplierCatalogCode) && <Grid item xs={12} md={4} />;
        var spacer3 = fieldSalePricelist && <Grid item xs={12} md={4} />;
        var spacer4 = fieldCostCalculationType && <Grid item xs={12} md={8} />;
        return (
            <Grid container spacing={2}>
                {fieldCostOrigin}
                {spacer1}
                {fieldSupplier}
                {fieldSupplierCode}
                {fieldSupplierCatalogCode}
				{fieldSupplierLabel}
                {/*spacer2*/}
                {fieldSalePricelist}
                {fieldSupplierVat}
                {spacer3}
                {fieldCostCalculationType}
                {spacer4}
                {case1.includes(data.CalculationType) && <Fragment>
                    {fieldSupplierPublicTaxablePrice}
                    {fieldSupplierPublicPrice}
                    <Grid item xs={12} md={4} />
                    {fieldSupplierDiscountDesc}
                    {fieldSupplierCost}
                </Fragment>}
                {case2.includes(data.CalculationType) && <Fragment>
                    {fieldSupplierCost}
                    {fieldSupplierDiscountDesc}
                    <Grid item xs={12} md={4} />
                    {fieldSupplierPublicTaxablePrice}
                    {fieldSupplierPublicPrice}
                </Fragment>}
				{case3.includes(data.CalculationType) && <Fragment>
					{fieldSupplierCost}
					{fieldSupplierMarkup}
                    <Grid item xs={12} md={4} />
                    {fieldSupplierPublicTaxablePrice}
                    {fieldSupplierPublicPrice}
				</Fragment>}
            </Grid>
        );

    }
    renderPanelCosts = () => {

        var { fields, data } = this.state;

        var fieldUnitCost = getXField(data, fields, 12, 3, "UnitCost", "Costo unitario", this.handleFieldChange, { type: "number" });
        var fieldTotalCost = getXField(data, fields, 12, 3, "TotalCost", "Costo totale", this.handleFieldChange, { type: "number" });
        var fieldCalculatedCost = getXSwitch(data, fields, 12, 3, "CalculatedCost", "Calcola costo", this.handleFieldChange);


        return (
            <Grid container spacing={2}>
                {fieldUnitCost}
                {fieldTotalCost}
                {fieldCalculatedCost}
            </Grid>
        );
    }

    renderPanelManage = () => {
        var { fields, data } = this.state;

        if (data.IsVariation === undefined || data.IsVariation === null)
            data.IsVariation = false;

        if (data.DefaultVariation === undefined || data.DefaultVariation === null)
            data.DefaultVariation = false;

        var fieldIsVariation = getXSwitch(data, fields, 12, 3, "IsVariation", "Variante", this.handleFieldChange);
        var fieldDefaultVariation = getXSwitch(data, fields, 12, 3, "DefaultVariation", "Variante predefinita", this.handleFieldChange);


        return (
            <Grid container spacing={2}>
                {fieldIsVariation}
                {fieldDefaultVariation}
            </Grid>
        );
    }

    renderPanelNotes  = () => {

        var { fields, data } = this.state;

        var fieldInternalRemark = getXField(data, fields, 12, 6, "InternalRemark", "Nota interna", this.handleFieldChange, { multiline: true });
        var fieldPublicRemark = getXField(data, fields, 12, 6, "PublicRemark", "Nota pubblica", this.handleFieldChange, { multiline: true });


        return (
            <Grid container spacing={2}>
                {fieldInternalRemark}
                {fieldPublicRemark}
            </Grid>
        );
    }

    renderTabGeneral() {
        //var { data } = this.state;


        var panels = [];
        panels.push({
            icon: "code",
            label: "Dati generali",
            content: this.renderPanelGeneral(),
        });
        /*panels.push({
            icon: "code",
            label: "Brand",
            content: this.renderPanelCollections(),
        });*/
        panels.push({
            icon: "code",
            label: "Unità di misura",
            content: this.renderPanelMeasureUnits(),
        });
        panels.push({
            icon: "code",
            label: "Fornitore - costo",
            content: this.renderPanelCostOptions(),
        });
        panels.push({
            icon: "code",
            label: "Riepilogo costi",
            content: this.renderPanelCosts(),
        });
        /*panels.push({
            icon: "code",
            label: "Gestione",
            content: this.renderPanelManage(),
        })*/
        panels.push({
            icon: "code",
            label: "Note",
            content: this.renderPanelNotes(),
        });

        var ui = <XExpansionPanel panels={panels} />;

        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    
    renderEditor(addRows, handleNewRow, rowData, customContent, onSave, onCancel) {
        var { data } = this.state;
        var actions = [];
        if (addRows) {
            handleNewRow = undefined;
            customContent = <BOPComponentEditor innerRef={this.BOPComponentEditor} data={rowData} onSave={onSave} onCancel={onCancel} FIDDOCIdentity={data.FIDIdentity} FIDCycle={data.FIDCycle} FIDDocumentType={data.FIDDocumentType} FIDPricelist={data.FIDPricelist} FIDVat={data.FIDVat} DiscountDesc={data.DiscountDesc} />;
            actions = [{
                group: 'actions',
                name: 'Indietro',
                action: onCancel,
                icon: <ArrowLeft />
            },{
                group: 'actions',
                name: 'Salva',
                action: onSave,
                icon: <Check />
            }];
            if(rowData.FIDBOP && rowData.ID){
                actions.push({
                    group: 'actions',
                    name: 'Elimina',
                    action: this.onDeleteRow(rowData.ID),
                    icon: <Delete />
                });
            }
            actions.reverse();
        }
        return { handleNewRow, rowData, customContent, actions };
    }
    onDeleteRow = (id)  => () => {
        this.MessageBox.current.getWrappedInstance().showMessageYesNo("CANCELLAZIONE.", "Confermi la cancellazione del componente", () => {
            this.doDeleteSingleRow(id)
        });
    }
    doDeleteSingleRow = (id) => {
        var FIDDOC = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        fetch(`/BOP/DeleteBOPComponent/${FIDDOC}`, {
            body: JSON.stringify([id]),
            method: 'POST'
        }).then(res => {
            
            this.setState({ addRows: false, selectedData: {}, addNewComponent: false, component: {}});
            // this.refresh();
            // afterDelete && afterDelete();
        });
    }
    cancelAddNewRow = () => {
        this.setState({ addNewComponent: false, component: {} });
    }
    editRows = () => {
        var selectedRows = this.grdBOPSComponents.current.getSelectedData();
        if (selectedRows.length === 1) {
            this.grdBOPSComponents.current.clearSelection();
            var data = this.grdBOPSComponents.current.getRowData(selectedRows[0]);
            this.setState({ selectedRows, addRows: true, selectedData: data });
        }
    }
    onCancelAddRow = () => {
        this.setState({ addRows: false, selectedData: {} });
    }
    onSaveRow = async () => {
        var data = this.BOPComponentEditor.current.getData();
        await this.doSaveComponent(data);
        this.setState({ addRows: false, selectedData: {}, addNewComponent: false, component: {}});
    }
    doSaveComponent = async data => {
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }
        await fetch(`/BOP/SaveBOPComponent/${FIDBOP}`,{
            method: 'POST',
            body: JSON.stringify(data)
        });
    }
    addRows = async () => {
        var rows = this.grdBOPSComponents.current.getSelectedData();
        for(var i in rows){
            var data = await fetch(`/BOP/GetDataForComponent/${rows[i]}`).then(res => res.json());
            data.Quantity = data.MeasureUnitQty1 || 0;
            data.Quantity1 = data.MeasureUnitQty2 || 0;
            data.FIDDBComponent = data.ID;
            data.ID = undefined;
            data.TotalCost = 0;
            await this.doSaveComponent(data);
        }
        
        this.setState({ addRows: false, selectedData: {}, addNewComponent: false, component: {}});
    }
    renderPanelBops() {
        var { addRows, selectedData, data, grdDOCBopsSearch, grdDOCBopsType, grdDOCBopsSearchValue } = this.state;
        var rowData = {};
        var customContent = undefined;
        var showFilterActivator = !addRows;
        var showColumnsSelector = !addRows;
        var actions = [];
        var handleNewRow = this.handleNewRow;

        //if (selectedRows.length > 0 && this.grdDOCBops.current) {
        if (selectedData) {
            rowData = Object.assign({}, selectedData);
        }
        else if (addRows) {
            rowData.FIDVat = data.FIDVat;
            rowData.VatPerc = data.VatPerc;
            rowData.FIDCausal = data.FIDCausal;
            rowData.FIDCycle = data.FIDCycle;
            if (data.FIDCycle === 3) {//passivo
                rowData.RetailDiscount = this.props.params.RETAIL_DISCOUNT;
            }
            rowData.FIDMeasureUnit1 = this.props.params.DEF_UM;
            rowData.FIDPricelist = this.props.params.DEF_PRICELIST;
            rowData.Qty1 = 1;
        }
        ({ handleNewRow, rowData, customContent, actions } = this.renderEditor(addRows, handleNewRow, rowData, customContent, this.onSaveRow, this.onCancelAddRow));
        
        if (!addRows && !grdDOCBopsSearch) {
            actions.push({
                group: 'actions',
                name: 'Modifica',
                action: this.editRows,
                icon: <Pencil />,
                condition: r => r.length === 1,
            });
            actions.push({
                group: 'actions',
                name: 'Aggiungi',
                action: this.addRows,
                icon: <Check />,
                condition: r => r.length > 0,
            });
            actions.push({
                group: 'actions',
                name: 'Annulla',
                action: this.cancelAddNewRow,
                icon: <ArrowLeft />
            });
            var searchActions = popupBOPSearch(this.onCodeSearch, this.onBarcodeSearch, this.onSerialSearch);
            actions = actions.concat(searchActions);
        }
        if (grdDOCBopsSearch) {
            var searchContent = getBOPSearchContent(this.grdDOCBopsSearch, grdDOCBopsType, grdDOCBopsSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm)
            customContent = searchContent.customContent;
            showFilterActivator = searchContent.showFilterActivator;
            showColumnsSelector = searchContent.showColumnsSelector;
            handleNewRow = searchContent.handleNewRow;
            actions = searchContent.actions;
        }


        var key = "tblBOPComponentNew";
        var gridName = "tblBOPComponentNew";
        var url = `/BOP/GetData?grid=${gridName}`;
        var ui = (
            <XGrid
                containerID="tabNewBops"
                key={key}
                innerRef={this.grdBOPSComponents}
                dataRoute={url}
                dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                customContent={customContent}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
                actions={actions}
                onNewButton={handleNewRow}
                //rowsVisible={10}
                cardWidth={4}
                cardHeight={4.5}
                showChangeView={true}
            />
        );
        
        return (
            <TabContainer id="tabNewBops" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                {ui}
            </TabContainer>
        );
    }
    addNewRow = () => {
        this.setState({ addNewComponent: true, component: {} });
    }
    onComponentEdit = (event, data) => {
        this.setState({ addRows: true, selectedData: data, addNewComponent: true, component: {}});
    }
    
    editRowsDblClk = (event,data) => {
        var FIDDOC = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDDOC) {
            FIDDOC = 0;
        }

        this.getRowData(data.ID).then(data => {
            this.setState({ addRows: true, selectedData: data });
        });
    }
    renderTabComponents() {
        var { component, data, addNewComponent } = this.state;
        if (addNewComponent) {
            return this.renderPanelBops();
        }

        var newClick = this.addNewRow;
        var customContent = undefined;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        var doubleClick = this.onComponentEdit;

        var ui = (
            <XGrid
                containerID="tabBOPComponents"
                ref={this.grdComponents}
                dataRoute={"/BOP/GetBOPComponents/" + data.ID + "?grid=tblBOPComponents"}
                dataRouteColumns="/Base/GetColumns?grid=tblBOPComponents"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPComponents"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPComponents"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeleteBOPComponent/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer id="tabBOPComponents" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                {ui}
            </TabContainer>
        );
    }
    addNewSupplier = () => {
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }
        this.setState({ supplier: { FIDVat: this.props.params.STDFIDVat, FIDCurrency: this.props.params.STDFIDCurrency, CurrencyChange: this.props.params.STDCurrencyChange }, editSupplier: true },this.getIRDefaultPrice);
    }
    handleSupplierDataChange = (event, value, key, data, callback) => {
        this.setState(state => {
            state.supplier[key] = value;
			
			if (["FIDMarkup","CalculationType"].includes(key)) {
				if (key === "CalculationType") {
					if (value < 5) {
						state.supplier.MarkupDesc = "";
						state.supplier.MarkupMultiplier = undefined;
						state.supplier.FIDMarkup = undefined;
					} else {
						state.supplier.Discount = 0;
						state.supplier.DiscountDesc = undefined;
					}
				}
				if (key === "FIDMarkup") {
					state.supplier.MarkupMultiplier = data.Multiplier;
					state.supplier.MarkupDesc = data.Denomination;
				}
				
				if (state.supplier.SupplierCost > 0 && state.supplier.FIDMarkup) {
					if (state.supplier.CalculationType === 5) {
						state.supplier.PublicPrice = this.calculateIRPublicPrice(state);
						state.supplier.PublicTaxablePrice = Math.round(state.supplier.PublicPrice / 1.22 * 100) / 100;
					} else if (state.supplier.CalculationType === 6) {
						state.supplier.PublicTaxablePrice = this.calculateIRPublicPrice(state);
						state.supplier.PublicPrice = Math.round(state.supplier.PublicTaxablePrice * 1.22 * 100) / 100;
					}
				} else {
					state.supplier.SupplierCost = this.calculateIRSupplierCost(state);
				}						
			}
			
			if (key === "FIDVat") {
				state.supplier.VatPercentage = data.Percentage;
			}
			
            return state;
        }, callback);
    }
    getIRDefaultPricelist = async () =>{
        var FIDDefaultPricelist = await fetch(`/BOP/GetDefaultPricelist`).then(res => res.json());
        await this.setStateAsync(state =>{
            state.supplier.FIDSalesPricelist = FIDDefaultPricelist;
            return state;
        });
    }
    getIRDefaultPrice = async () =>{
        if(!this.state.supplier.FIDSalesPricelist){
            await this.getIRDefaultPricelist();
        }
        var { supplier } = this.state;
        if(supplier.FIDSalesPricelist){
            var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
            if (!FIDBOP) {
                FIDBOP = 0;
            }
            var price = await fetch(`/BOP/GetDefaultPrice/${FIDBOP}?FIDpricelist=${supplier.FIDSalesPricelist}`).then(res => res.json());
            this.setState(state => {
                state.supplier.PublicPrice = parseFloat(price.item2);
                state.supplier.PublicTaxablePrice = parseFloat(price.item1);
                if(state.supplier.SupplierCostLock){
                    state.supplier.SupplierCost = this.calculateIRSupplierCost(state);
                }
                else {
                    state.supplier.DiscountDesc = this.calculateIRSupplierDiscount(state).toString(10);
                }
                return state;
            });
        }
    }
    handleIRSupplierChange = (key) => (event, data) => {
        var callback = undefined;
        if(key === "CalculationType" && data && data.length > 0){
			if (data[0].ID < 5) {
				callback = () =>{
					this.getIRDefaultPrice();
				}
			} /*else {
				callback = () => {
					this.setState(state => {  
						if (state.supplier.SupplierCost > 0) {
							if (data[0].ID === 5) {							
								state.supplier.PublicPrice = this.calculateIRPublicPrice(state);
								state.supplier.PublicTaxablePrice = Math.round(state.supplier.PublicPrice / 1.22 * 100) / 100;
							} else {
								state.supplier.PublicTaxablePrice = this.calculateIRPublicPrice(state);
								state.supplier.PublicPrice = Math.round(state.supplier.PublicTaxablePrice * 1.22 * 100) / 100;
							}
						} else {
							state.supplier.SupplierCost = this.calculateIRSupplierCost(state);
						}
						return state;
					});
				}
			}*/
        }

        if (data.length > 0) {
            this.handleSupplierDataChange(event, data[0].ID, key, data[0], callback);
        }
        else {
            this.handleSupplierDataChange(event, undefined, key, undefined, callback);
        }
    }

    calculateIRSupplierCost = (state) => {
        var discount = getDiscount(state.supplier.DiscountDesc);
        var price = state.supplier.PublicPrice;
        var taxableCase = [2,4,6];
        if(taxableCase.includes(state.supplier.CalculationType)){
            price = state.supplier.PublicTaxablePrice;
        } else if (state.supplier.CalculationType >= 5) {
			if (!state.supplier.MarkupMultiplier) {
				return parseFloat(price);
			}
			return Math.round((parseFloat(price) / (state.supplier.MarkupMultiplier || 1)) * 100) / 100;
		}
        //return parseFloat(state.supplier.PublicPrice) * (100 - parseFloat(state.supplier.Discount)) / 100;
        
        return Math.round((parseFloat(price) * (1 - discount)) * 100) / 100;
    }
    
    calculateIRPublicPrice =(state) =>{
        var case1=[1,2];
        var case2=[3,4];
		var taxableCase = [2,4,6];
        if(case1.includes(state.supplier.CalculationType)){
            var discount = getDiscount(state.supplier.DiscountDesc);
            return Math.round((parseFloat(state.supplier.SupplierCost) / (1 - discount)) * 100) / 100;
        }
        else if(case2.includes(state.supplier.CalculationType)){
            var discount = getRecharge(state.supplier.DiscountDesc);
            return Math.round((parseFloat(state.supplier.SupplierCost) * (1 + discount)) * 100) / 100;
        } else {
			return Math.round(parseFloat(state.supplier.SupplierCost) * (state.supplier.MarkupMultiplier || 1) * 100) / 100;
		}
        return 0;
    }
    calculateIRSupplierDiscount = (state) => {
        var case1=[1,2];
        var case2=[3,4];
        
        var taxableCase = [2,4];
        var price = state.supplier.PublicPrice;
        if(taxableCase.includes(state.supplier.CalculationType)){
            price = state.supplier.PublicTaxablePrice;
        }
        if(case1.includes(state.supplier.CalculationType)){
            return round((1 - state.supplier.SupplierCost / price) * 100,4);
        }
        else if(case2.includes(state.supplier.CalculationType)){
            return round((price / state.supplier.SupplierCost - 1) * 100,4);
        }
        return 0;
    }
    handleIRSupplierFieldChange = (key) => (event, value) => {
		var taxableCase = [2,4,6];
        this.handleSupplierDataChange(event, value, key);
        //this.handleFieldChange(key)(event, value);
        if (key === "DiscountDesc") {
            
            this.setState(state  => {
                if([1,2].includes(state.supplier.CalculationType)){
                    state.supplier.SupplierCost = this.calculateIRSupplierCost(state);
                }
                else {
                    if(!taxableCase.includes(state.supplier.CalculationType)){
                        state.supplier.PublicPrice = this.calculateIRPublicPrice(state);
                        state.supplier.PublicTaxablePrice = Math.round(state.supplier.PublicPrice / 1.22 * 100) / 100;

                    }
                    else{
                        state.supplier.PublicTaxablePrice = this.calculateIRPublicPrice(state);
                        state.supplier.PublicPrice = Math.round(state.supplier.PublicTaxablePrice * 1.22 * 100) / 100;
                    }
                    
                }
                return state;
            });
        }
        else if(key === "PublicPrice") {
            this.setState(state  => {
                state.supplier.PublicTaxablePrice =  Math.round(state.supplier.PublicPrice / 1.22 * 100) / 100;
                //if([1,2].includes(state.supplier.CalculationType)){
                if(state.supplier.SupplierCostLock) {
                    state.supplier.SupplierCost = this.calculateIRSupplierCost(state);
                }
                else {
					state.supplier.DiscountDesc = this.calculateIRSupplierDiscount(state).toString(10);
                }
                //}
                //else {
                    //state.supplier.DiscountDesc = this.calculateIRSupplierDiscount(state).toString(10);
                //}
                return state;
            });
        }
        else if(key === "PublicTaxablePrice") {
            this.setState(state  => {
                state.supplier.PublicPrice =  Math.round(state.supplier.PublicTaxablePrice * 1.22 * 100) / 100;
                //if([1,2].includes(state.supplier.CalculationType)){
                if(state.supplier.SupplierCostLock) {
                    state.supplier.SupplierCost = this.calculateIRSupplierCost(state);
                }
                else if (state.supplier.CalculationType < 5) {
					// SE NON HO SELEZIONATO UNA FORMULA DI RICARICO RICALCOLO SCONTO/RICARICO
                    state.supplier.DiscountDesc = this.calculateIRSupplierDiscount(state).toString(10);    
                }
                //}
                //else {
                    //state.supplier.DiscountDesc = this.calculateIRSupplierDiscount(state).toString(10);
                //}
                return state;
            });
        }
        else if(key === "SupplierCost"){
            this.setState(state => {
                if([1,2].includes(state.supplier.CalculationType)){
                    state.supplier.DiscountDesc = this.calculateIRSupplierDiscount(state).toString(10);
                }
                else {
                    if(!taxableCase.includes(state.supplier.CalculationType)){
                        state.supplier.PublicPrice = this.calculateIRPublicPrice(state);
                        state.supplier.PublicTaxablePrice = Math.round(state.supplier.PublicPrice / 1.22 * 100) / 100;
                    }
                    else{
                        state.supplier.PublicTaxablePrice = this.calculateIRPublicPrice(state);
                        state.supplier.PublicPrice = Math.round(state.supplier.PublicTaxablePrice * 1.22 * 100) / 100;
                    }
                }
                return state;
            });
        }
    }
    doSaveSupplier = () => {
        var { supplier } = this.state;
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }
        fetch(`/BOP/UpdateSupplier/${FIDBOP}`, { method: 'POST', body: JSON.stringify(supplier) }).then(res => res.json()).then(data => {
            this.setState({ supplier: {}, editSupplier: false }, () => {
                this.grdBOPSuppliers.current.refresh();
                if (supplier.Default) {
                    this.setState(state => {
                        state.data.FIDSupplier = supplier.FIDIdentity;
                        state.data.FIDSupplierVat = supplier.FIDVat;
                        state.data.FIDSupplierCurrency = supplier.FIDCurrency;
                        state.data.SupplierCurrencyChange = supplier.CurrencyChange;
                        state.data.SupplierPublicPrice = supplier.PublicPrice;
                        state.data.SupplierPublicTaxablePrice = supplier.PublicTaxablePrice;
                        state.data.SupplierDiscountDesc = supplier.DiscountDesc;
                        state.data.SupplierCost = supplier.SupplierCost;
                        state.data.SupplierCode = supplier.Code;
						state.data.SupplierLabel = supplier.Label;
                        state.data.SupplierCatalogCode = supplier.CatalogCode;
                        state.data.CalculationType = supplier.CalculationType;
						state.data.FIDMarkup = supplier.FIDMarkup;
                        state.data.SupplierDiscountDesckLock = supplier.DiscountDesckLock;
                        state.data.SupplierPublicPriceLock = supplier.PublicPriceLock;
                        state.data.SupplierPublicTaxablePriceLock = supplier.PublicTaxablePriceLock;
                        state.data.SupplierCostLock = supplier.SupplierCostLock;
                        
                        state.data.UnitCost = state.data.SupplierCost;
                        state.data.TotalCost = this.calculateTotalCost(state);
                        return state;
                    });
                }
            });
        });
    }
    handleSaveSupplier = () => {
        var { supplier } = this.state;
        var FIDBOP = this.props.match && this.props.match.params ? this.props.match.params.id : undefined
        if (!FIDBOP) {
            FIDBOP = 0;
        }



        if (supplier.FIDIdentity && supplier.FIDVat && supplier.FIDSalesPricelist && supplier.SupplierCost && supplier.PublicPrice && (supplier.DiscountDesc || supplier.FIDMarkup)) {
            if (supplier.Default) {
                fetch(`/BOP/CheckDefaultSupplier/${FIDBOP}`, {
                    method: 'POST',
                    body: JSON.stringify(supplier)
                }).then(res => res.json()).then(data => {
                    if (data.HasDefault) {
                        var buttons = [
                            {
                                label: "SI",
                                action: () => {
                                    this.setState({ dialog: {} });
                                    this.doSaveSupplier();
                                }
                            },
                            {
                                label: "NO",
                                action: () => {
                                    this.setState({ dialog: {} });
                                }
                            },
                        ];

                        this.showDialog(buttons, "Fornitore predefinito", "È già presente un fornitore predefinito. Confermi inserimento?");
                    }
                    else {
                        this.doSaveSupplier();
                    }
                });
            }
            else {
                this.doSaveSupplier();
            }

            
        }
    }
    handleCancelSupplier = () => {
        this.setState({ supplier: {}, editSupplier: false });
    }
    editIRSupplier = (event, data) => {
        this.grdBOPSuppliers.current.clearSelection();
        this.setState({ supplier: Object.assign({},data), editSupplier: true });
    }
    renderTabSuppliers() {
        var { supplier, data, editSupplier } = this.state;
        var fields = Object.assign([], fieldsSupplier);


        var newClick = this.addNewSupplier;
        var doubleClick = this.editIRSupplier;
        var customContent = undefined;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];

        if (editSupplier) {
            newClick = undefined;
            showColumnSelector = false;
            showFilterActivator = false;

            var case1 = [1,2];
            var case2 = [3,4];
			var case3 = [5,6];
            var labelFormula = "Sc %";
            if(case2.includes(supplier.CalculationType)){
                labelFormula = "% ricarico";
            } 
            
            var labelPublicPrice = "Prezzo di vendita";
            var labelPublicTaxablePrice= "Prezzo di vendita imponibile";

            var fieldCostCalculationType = getXSelect(supplier, fields, 12, 4, "CalculationType", "Tipo calcolo costo", this.handleIRSupplierChange, "/enums/boppricecalculationtype");
            var fieldSupplier = getXSelect(supplier, fields, 12, 8, "FIDIdentity", "Fornitore", this.handleIRSupplierChange, "/ir/getsuppliersselect");
            var fieldSupplierCode = getXField(supplier, fields, 12, 4, "Code", "Codice", this.handleIRSupplierFieldChange);
            var fieldSupplierCatalogCode = getXField(supplier, fields, 12, 4, "CatalogCode", "Codice catalogo", this.handleIRSupplierFieldChange);
			var fieldSupplierLabel = getXField(supplier, fields, 12, 4, "Label", "Etichetta", this.handleIRSupplierFieldChange);
            var fieldSupplierVat = getXSelect(supplier, fields, 12, 4, "FIDVat", "IVA", this.handleIRSupplierChange, "/gc/vat");
            var fieldSupplierCurrency = getXSelect(supplier, fields, 12, 2, "FIDCurrency", "Valuta", this.handleIRSupplierChange, "/gc/currency");
            var fieldSupplierCurrencyChange = getXField(supplier, fields, 12, 2, "CurrencyChange", "Cambio", this.handleIRSupplierFieldChange, { type: "number" });
			var fieldSupplierMarkup = getXSelect(supplier, fields, 12, 3, "FIDMarkup", "Formula di ricarico", this.handleIRSupplierChange, "/gc/markup" );


            if (case1.includes(supplier.CalculationType)) {
				if(!supplier.PublicPriceLock && !supplier.PublicTaxablePriceLock) {
					supplier.PublicPriceLock = false;
					supplier.PublicTaxablePriceLock = true;
					supplier.SupplierCostLock = true;
				}
			}
			else if (case2.includes(supplier.CalculationType)) {
				if (!supplier.PublicPriceLock && !supplier.PublicTaxablePriceLock && !supplier.SupplierCostLock) {
					supplier.PublicPriceLock = true;
					supplier.PublicTaxablePriceLock = true;
				}
			}
			else if (case3.includes(supplier.CalculationType)) {
				if (!supplier.PublicPriceLock && !supplier.PublicTaxablePriceLock && !supplier.SupplierCostLock) {
					supplier.PublicPriceLock = true;
					supplier.PublicTaxablePriceLock = true;
				}
			}
            var fieldSupplierPublicPrice = getXField(supplier, fields, 12, 4, "PublicPrice", labelPublicPrice, this.handleIRSupplierFieldChange, { type: "number", lockable: supplier.PublicPriceLock, isLocked: supplier.PublicPriceLock, onLockChange: this.onSupplierLockChange1, readOnly: supplier.PublicPriceLock });
            var fieldSupplierDiscountDesc = getXField(supplier, fields, 12, 4, "DiscountDesc", "Sc %", this.handleIRSupplierFieldChange, { lockable: supplier.DiscountDescLock, isLocked: supplier.DiscountDescLock, onLockChange: this.onSupplierLockChange2, readOnly: supplier.DiscountDescLock});
            var fieldSupplierCost = getXField(supplier, fields, 12, 4, "SupplierCost", "Costo fornitore", this.handleIRSupplierFieldChange, { type: "number", lockable: supplier.SupplierCostLock, isLocked: supplier.SupplierCostLock, onLockChange: this.onSupplierLockChange2, readOnly: supplier.SupplierCostLock });
            var fieldSupplierDefault = getXSwitch(supplier, fields, 12, 2, "Default", "Predefinito", this.handleIRSupplierFieldChange);
            var fieldSupplierPublicTaxablePrice = getXField(supplier, fields, 12, 4, "PublicTaxablePrice", labelPublicTaxablePrice, this.handleIRSupplierFieldChange, { type: "number", lockable: supplier.PublicTaxablePriceLock, isLocked: supplier.PublicTaxablePriceLock, onLockChange: this.onSupplierLockChange1, readOnly: supplier.PublicTaxablePriceLock });
        
            var fieldSalePricelist = getXSelect(supplier, fields, 12, 4, "FIDSalesPricelist", FieldLabels.FIDSalesPricelist, this.handleIRSupplierChange, "gc/pricelist");

            var spacer1 = fieldSupplier && <Grid item xs={12} md={2} />;
            var spacer2 = (fieldSupplierCode && fieldSupplierCatalogCode) && <Grid item xs={12} md={4} />;
            var spacer3 = fieldSalePricelist && <Grid item xs={12} md={4} />;

            var spacer4 = fieldCostCalculationType && <Grid item xs={12} md={8} />;

            var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12} container>
                    <Grid item xs={12} container direction="row" spacing={2}>
                        {fieldSupplier}
                        {fieldSupplierDefault}
                        {spacer1}
                        {fieldSupplierCode}
                        {fieldSupplierCatalogCode}
                        {fieldSupplierLabel}
                        {fieldSalePricelist}
                        {fieldSupplierVat}
                        {spacer3}
                        {fieldCostCalculationType}
                        {spacer4}
                        {case1.includes(supplier.CalculationType) && <Fragment>
                            {fieldSupplierPublicTaxablePrice}
                            {fieldSupplierPublicPrice}
                            <Grid item xs={12} md={4} />
                            {fieldSupplierDiscountDesc}
                            {fieldSupplierCost}
                        </Fragment>}
                        {case2.includes(supplier.CalculationType) && <Fragment>
                            {fieldSupplierCost}
                            {fieldSupplierDiscountDesc}
                            <Grid item xs={12} md={4} />
                            {fieldSupplierPublicTaxablePrice}
                            {fieldSupplierPublicPrice}
                        </Fragment>}
						{
							case3.includes(supplier.CalculationType) && <Fragment>
								{fieldSupplierCost}
								{fieldSupplierMarkup}
								<Grid item xs={12} md={4} />
								{fieldSupplierPublicTaxablePrice}
								{fieldSupplierPublicPrice}
                        </Fragment>
						}
                    </Grid>
                </Grid>
            </Grid>);

            var panels = [];
            panels.push({
                icon: "attachment",
                label: "Fornitori",
                content: fileHeadFields,
            });
            customContent = (<XExpansionPanel panels={panels} />);
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleSaveSupplier} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleCancelSupplier} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var ui = (
            <XGrid
                containerID="tabBOPSuppliers"
                innerRef={this.grdBOPSuppliers}
                dataRoute={"/BOP/GetBOPSuppliers/" + data.ID + "?grid=tblBOPSuppliers"}
                dataRouteColumns="/Base/GetColumns?grid=tblBOPSuppliers"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPSuppliers"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPSuppliers"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeleteBOPSuppliers/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer id="tabBOPSuppliers" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                {ui}
            </TabContainer>
        );

    }
    
    handleDownloadFile = () => {
        var FIDFIles = this.grdFiles.current.getWrappedInstance().getSelectedData();
        for (var i = 0; i < FIDFIles.length; i++) {
            window.open("/BOP/DownloadFile/" + FIDFIles[i]);
        }
        this.grdFiles.current.getWrappedInstance().clearSelection();
    }
    renderTabFiles() {
        var { file, data, isUploading, uploadProgress  } = this.state;

        var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
                    <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Files",
            content: fileHeadFields,
        });
        var customContent = undefined;

        //var doubleClick = this.handleFileDoubleClick;
        var newClick = this.handleFileNew;
        var showColumnSelector = true;
        var showFilterActivator = true;

        var customActions = [];
        if (this.state.isFileEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            //doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                {
                    group: 'actions',
                    name: 'Salva',
                    icon: <Check />,
                    action: this.handleSaveFile
                },
                {
                    group: 'actions',
                    name: 'Annulla',
                    icon: <Close />,
                    action: this.handleCancelFile
                }
                //(
                //	<Tooltip title="Salva">
                //		<IconButton aria-label="Salva" onClick={this.handleSaveFile} >
                //			<Check />
                //		</IconButton>
                //	</Tooltip>
                //),
                //(
                //	<Tooltip title="Annulla">
                //		<IconButton aria-label="Annulla" onClick={this.handleCancelFile} >
                //			<Close />
                //		</IconButton>
                //	</Tooltip>
                //)
            ];
        } else {
            customActions = [
                {
                    group: 'actions',
                    name: 'Download',
                    icon: <Download />,
                    action: this.handleDownloadFile,
                    condition: r => r.length > 0
                }
            ]
        }
        var customCells = {
            Preview: (data, column) => {
                return <img style={{
                    height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
                }} src={"data:image/png;base64, " + data.base64file} alt="" />
            }
        };
        var ui = (
            <XGrid
                containerID="tabBOPFiles"
                ref={this.grdFiles}
                dataRoute={"/BOP/GetBOPFiles/" + data.ID + "?grid=tblFiles"}
                dataRouteColumns="/Base/GetColumns?grid=tblFiles"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
                //onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeleteFile/" + data.ID}
                onNewButton={newClick}
                //onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
                customCells={customCells}
                rowHeight={105}
            />
        )

        return (
            <TabContainer id="tabBOPFiles" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                <XProgress progress={uploadProgress} total={100} label="Caricamento file in corso..." onCancel={() => {
					if (this.cancelUpload) {
						this.cancelUpload();
					}
					this.setState({ isUploading: false });
				}} cancelLabel="Annulla" show={isUploading} />
                {ui}
            </TabContainer>
        );
    }

    handlePricelistGridDoubleClick = (event, data) => {
        this.grdPricelists.current.getWrappedInstance().clearSelection();
        this.setState({ pricelist: data, isPricelistEdit: true });
    }
    handleStoreGridDoubleClick = (event, data) => {
        this.grdStores.current.getWrappedInstance().clearSelection();
        this.setState({ store: data, isStoreEdit: true });
    }
    showRelated = () => {
        this.setState({
            bopRelatedView: 'R'
        }, () => { this.grdBOPRelateds.current.getWrappedInstance().refresh() })
    }
    showParure = () => {
        this.setState({
            bopRelatedView: 'P'
        }, () => { this.grdBOPRelateds.current.getWrappedInstance().refresh() })
    }
    renderTabRelateds = () => {
        var { data, isBOPRelatedEdit, bopRelatedView } = this.state;

        if (!bopRelatedView)
            bopRelatedView = 'R';
            
        var customActions = [];
        var customContent = undefined;
        var label = undefined;
        if (isBOPRelatedEdit) {
            customContent = <BOPSelector currBOPToRelate={data.ID} gridName="tblBOPRelateds" label={bopRelatedView === "R" ? "Selezione prodotti correlati" : "Composizione parure"} onCancel={this.handleRelatedCancel} onBOPSelectionConfirm={this.addRelatedBOPs} bopRelatedType={bopRelatedView === "R" ? "1" : "2"} />;
        }
        else {
            var direction = "right";
            var navActions = [];
            navActions.push({ name: "Correlati", icon: <LinkVariant />, action: this.showRelated, selected:     bopRelatedView === "R" });
            navActions.push({ name: "Parure", icon: <Ring />, action: this.showParure, selected: bopRelatedView === "P"     });
            label = <span><XNavigation actions={navActions} direction={direction} showLabel /></span>
        }
        var ui = (
            <XGrid
                containerID="tabContainerRelateds"
                ref={this.grdBOPRelateds}
                label={label}
                dataRoute={bopRelatedView === "R" ? "/BOP/GetBOPRelateds/" + data.ID + "?grid=tblBOPRelateds" : "/BOP/GetBOPParures/" + data.ID + "?grid=tblBOPRelateds"}
                dataRouteColumns="/Base/GetColumns?grid=tblBOPRelateds"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPRelateds"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPRelateds"
                dataRouteDelete={"/BOP/DeleteBOPRelateds/" + data.ID + '?relatedType=' + (bopRelatedView === "R" ? "1" : "2")}
                onNewButton={!customContent ? this.handleRelatedNew : false}
                customContent={customContent}
                //afterDelete={() => { this.grdBOPRelateds.current.getWrappedInstance() }}
                showColumnsSelector={true}
                showFilterActivator={true}
                actions={customActions}
                showLoader
            />
        )

        return (
            <TabContainer id="tabContainerRelateds" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                {ui}
            </TabContainer>
        ); 
    }
    onPricelistLockChange1 = (id) => (value) => {
        this.setState(state => {
            state.pricelist.TaxablePriceLock = true;
            state.pricelist.TotalPriceLock = true;
            state.pricelist[`${id}Lock`] = value;
            return state;
        });
    }
    onPricelistLockChange2 = (id) => (value) => {
        this.setState(state => {
            state.pricelist.DiscountDescLock = true;
            state.pricelist.DiscountedTaxablePriceLock = true;
            state.pricelist.DiscountedTotalPriceLock = true;
            state.pricelist[`${id}Lock`] = value;
            if(id === "Discount"){
                state.pricelist.DiscountDescLock = value;
            }
            return state;
        });
    }
    renderTabPricelists = () => {

        var { pricelist, data, isPricelistEdit } = this.state;


        if(!pricelist.TaxablePriceLock && !pricelist.TotalPriceLock){
            pricelist.TaxablePriceLock = false;
            pricelist.TotalPriceLock = true;
        }
        if(!pricelist.DiscountDescLock && !pricelist.DiscountedTaxablePriceLock && !pricelist.DiscountedTotalPriceLock) {
            pricelist.DiscountDescLock = false;
            pricelist.DiscountedTaxablePriceLock = true;
            pricelist.DiscountedTotalPriceLock = true;
        }

        var pricelistHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSelect label="Listino" id="FIDPricelist" urlData="/GC/pricelist" value={pricelist.FIDPricelist} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onPricelistSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSelect innerRef={this.cboFIDVat} label="IVA" id="FIDVat" urlData="/GC/vat" value={pricelist.FIDVat} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onPricelistSelectChange} /></Grid>
                    <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={pricelist.Default} onChange={this.onPricelistChange} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Imponibile" type="number" id="TaxablePrice" value={pricelist.TaxablePrice} onChange={this.onPricelistChange} lockable={pricelist.TaxablePriceLock} isLocked={pricelist.TaxablePriceLock} onLockChange={this.onPricelistLockChange1}/></Grid>
                    <Grid item xs={12} md={4}><XField label="IVA" type="number" id="VatPercentage" value={pricelist.VatPercentage} onChange={this.onPricelistChange} readOnly={true} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Importo totale" type="number" id="TotalPrice" value={pricelist.TotalPrice} onChange={this.onPricelistChange}  lockable={pricelist.TotalPriceLock} isLocked={pricelist.TotalPriceLock} onLockChange={this.onPricelistLockChange1} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Sconto" type="number" id="Discount" value={pricelist.Discount} onChange={this.onPricelistChange} lockable={pricelist.DiscountDescLock} isLocked={pricelist.DiscountDescLock} onLockChange={this.onPricelistLockChange2}/></Grid>
                    <Grid item xs={12} md={4}><XField label="Imponibile scontato" type="number" id="DiscountedTaxablePrice" value={pricelist.DiscountedTaxablePrice} onChange={this.onPricelistChange} lockable={pricelist.DiscountedTaxablePriceLock} isLocked={pricelist.DiscountedTaxablePriceLock} onLockChange={this.onPricelistLockChange2} /></Grid>
                    <Grid item xs={12} md={4}><XField label="Totale scontato" type="number" id="DiscountedTotalPrice" value={pricelist.DiscountedTotalPrice} onChange={this.onPricelistChange} lockable={pricelist.DiscountedTotalPriceLock} isLocked={pricelist.DiscountedTotalPriceLock} onLockChange={this.onPricelistLockChange2} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Listino",
            content: pricelistHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handlePricelistGridDoubleClick;
        var newClick = this.handlePricelistNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (isPricelistEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handlePricelistSave} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handlePricelistCancel} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var ui = (
            <XGrid
                containerID="tabContainerPricelists"
                ref={this.grdPricelists}
                dataRoute={"/BOP/GetBopPricelists/" + data.ID + "?grid=grdBOPPricelists"}
                dataRouteColumns="/Base/GetColumns?grid=grdBOPPricelists"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPPricelists"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPPricelists"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeletePricelist/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer id="tabContainerPricelists" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                {ui}
            </TabContainer>
        );
    }
    renderTabStores = () => {

        var { store, data, isStoreEdit } = this.state;

        var storeHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={4}><XSelect label="Store" id="FIDStore" urlData="/GC/stores" value={store.FIDStore} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                    <Grid item xs={12} md={4}><XSelect label="Area" id="FIDStoreArea" urlData="/GC/storeareas" value={store.FIDStoreArea} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                    <Grid item xs={12} md={4}><XSelect label="Stato" id="Status" urlData="/enums/storepublishtype" value={store.Status} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                </Grid>
            </Grid>
        </Grid>);

        var panels = [];
        panels.push({
            icon: "attachment",
            label: "Store",
            content: storeHeadFields,
        });
        var customContent = undefined;

        var doubleClick = this.handleStoreGridDoubleClick;
        var newClick = this.handleStoreNew;
        var showColumnSelector = true;
        var showFilterActivator = true;
        var customActions = [];
        if (isStoreEdit) {
            customContent = (<XExpansionPanel panels={panels} />);
            doubleClick = undefined;
            newClick = undefined;
            showFilterActivator = false;
            showColumnSelector = false;
            customActions = [
                (
                    <Tooltip title="Salva">
                        <IconButton aria-label="Salva" onClick={this.handleStoreSave} >
                            <Check />
                        </IconButton>
                    </Tooltip>
                ),
                (
                    <Tooltip title="Annulla">
                        <IconButton aria-label="Annulla" onClick={this.handleStoreCancel} >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )
            ];
        }
        var ui = (
            <XGrid
                containerID="tabBOPStores"
                ref={this.grdStores}
                dataRoute={"/BOP/GetBopStores/" + data.ID + "?grid=grdBOPStores"}
                dataRouteColumns="/Base/GetColumns?grid=grdBOPStores"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPStores"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPStores"
                onDoubleClick={doubleClick}
                dataRouteDelete={"/BOP/DeleteStores/" + data.ID}
                onNewButton={newClick}
                onEditButton={doubleClick}
                customContent={customContent}
                showColumnsSelector={showColumnSelector}
                showFilterActivator={showFilterActivator}
                actions={customActions}
            />
        )

        return (
            <TabContainer  id="tabBOPStores" padding={0} style={{ height: "calc(100vh - 145px)" }}>
                {ui}
            </TabContainer>
        );
    }
    renderTabHistory = () =>{
        var { data } = this.state;
        return (
            <TabContainer>
                <BOPHistory isStock={!!data.Barcode} data={data} />
            </TabContainer>
        );
    }

    renderTabVariationsConfig = () => {
        var { data } = this.state;
        return (
            <TabContainer>
                <VariationsConfig id={data.FIDCatalogInfo} CatalogCode={data.CatalogCode} FIDBop={data.ID} FIDTypology={data.FIDTypology} FIDGroup={data.FIDGroup} FIDCategory={data.FIDCategory} unlockedExtraID={data.VariationUnlockedExtra} onUnlockedExtraChange={this.handleFieldChange} />
            </TabContainer>
        );
    }

    handleExtraFieldChange = (FIDExtra, isData) => (id) => (event, value) => {
        this.setState((state, props) => {
            var { data, extras } = state;

            var extraField = extras.filter(f => f.ID === FIDExtra)[0];

            var extra = {
                BoolValue: null,
                ComboValue: null,
                DateTimeValue: null,
                ExtraID: FIDExtra,
                ExtraType: extraField.Type,
                GroupValue: null,
                IntValue: null,
                StringValue: null,
                TagValue: null
            };
            if (data.Extras) {
                var extraFieldVal = data.Extras.filter(f => f.ExtraID === FIDExtra);
                if (extraFieldVal.length > 0) {
                    extra = extraFieldVal[0];
                }
            }
            var index = -1;
            if (data.Extras) {
                index = data.Extras.indexOf(extra);
            }
            else {
                data.Extras = [];
            }
            switch (extra.ExtraType) {
                case 0://string
                    extra.StringValue = value;
                    break;
                case 1://integer
                    extra.IntValue = value;
                    break;
                case 2://decimal
                    extra.DecimalValue = value;
                    break;
                case 3://datetime
                    if (value) {
                        extra.DateTimeValue = value;
                    }
                    else {
                        extra.DateTimeValue = null;
                    }
                    break;
                case 4://boolean
                    extra.BoolValue = value;
                    break;
                case 5://combobox
                    if (value.length > 0) {
                        extra.ComboValue = value[0].ID;
                    }
                    else {
                        extra.ComboValue = null;
                    }
                    break;
                case 6://tagbox
                    extra.TagValue = value;
                    break;
                case 7://component
                    //extra.StringValue = value;
                    break;
                case 8://componentcustomfields
                    //extra.StringValue = value;
                    break;
                default:
                    break;
            }
            if (index >= 0) {
                state.data.Extras[index] = extra;
            }
            else {
                if (!state.data.Extras) {
                    state.data.Extras = [];
                }
                state.data.Extras = state.data.Extras.concat(extra);
            }
            return state;
        });
    }
    renderExtraField = (field, index, saveUrl, loadGridUrl, loadDataUrl, deleteDataUrl) => {
        var { data } = this.state;
        var id = this.props.match && this.props.match.params ? this.props.match.params.id : undefined;
        if (!id) {
            id = 0;
        }
        var extra = [];
        if (data.Extras) {
            extra = data.Extras.filter(f => f.ExtraID === field.ID);
        }
        return (<CustomField fieldProps={{ Required: (extra.length > 0 && extra[0].Required ? 2 : 1) }} key={index} field={field} extra={extra} saveUrl={saveUrl} mainId={id} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} handleExtraFieldChange={this.handleExtraFieldChange} />);
    }
    renderExtraGroup = (tab, group) => {
        var { extras } = this.state;
        return (
            <Grid container spacing={2} >
                {extras.filter(f => ((tab && f.FIDExtraTab === tab.ID) || (!tab && !f.FIDExtraTab)) && ((group && f.FIDExtraGroup === group.ID) || (!group && !f.FIDExtraGroup))).map((item, index) => {
                    return this.renderExtraField(item, index, '/BOP/SaveTableCF', '/BOP/GetBOPTableCFData/', '/BOP/GetBOPTableCFRecords/', '/BOP/DeleteBOPTableCFRecords/');
                })}
            </Grid>
        );
    }
    renderExtraTab = (tab) => {
        var { extragroups, extras } = this.state;
        var FIDGroups = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
        var panels = extragroups.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
            {
                icon: "people",
                label: item.Description,
                content: this.renderExtraGroup(tab, item),
            }
        ));

        var extrasNoGroup = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && !m.FIDExtraGroup);
        if (extrasNoGroup.length > 0) {
            panels = panels.concat({
                icon: "people",
                label: 'Custom',
                content: this.renderExtraGroup(tab, null),
            });
        }

        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    };
    validateTabGeneral = (callback) => {
        var { fields, data } = this.state;
        var isValid = true;
        var requiredFields = [];
        var optionalFields = [];
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];

            if (field.Visibility && (field.Required === 2 || (field.Required === 1 && !field.Validated)) && ([undefined, null, ""].includes(data[field.Field]) || data[field.Field].length === 0)) {
                isValid = false;
                if (field.Required === 1) {
                    //fields[i].Validated = true;
                    optionalFields.push(field.Field);
                }
                else {
                    requiredFields.push(field.Field);
                }
            }
        }
        if (!isValid) {

            var msg = (
                <Fragment>
                    {requiredFields.length > 0 && (<Typography variant="subtitle2">Campi obbligatori:</Typography>)}
                    {requiredFields.map((item, index) => (<Typography key={index}>{FieldLabels[item]}</Typography>))}
                    {optionalFields.length > 0 && (<Typography variant="subtitle2">Campi raccomandati (opzionali):</Typography>)}
                    {optionalFields.map((item, index) => (<Typography key={index}>{FieldLabels[item]}</Typography>))}

                    {requiredFields.length == 0 && optionalFields.length > 0 && (<Typography variant="subtitle2">Procedere senza compilare questi campi?</Typography>)}

                    {requiredFields.length > 0 && (<Typography variant="subtitle2">Compilare i campi obbligatori prima di procedere.</Typography>)}
                </Fragment>
            );
            if (requiredFields.length > 0) {
                this.MessageBox.current.getWrappedInstance().showMessageOk("Alcuni campi non sono stati compilati.", msg);
            }
            else {
                this.MessageBox.current.getWrappedInstance().showMessageYesNo("Alcuni campi non sono stati compilati.", msg, () => {
                    callback();
                    for (var i = 0; i < fields.length; i++) {
                        fields[i].Validated = false;
                    }
                });
            }
        }
        else {
            for (var i = 0; i < fields.length; i++) {
                fields[i].Validated = false;
            }
        }
        return isValid;
    }
    validate = (tab, newTab, callback) => {
        if (tab !== 0) {
            return true;
        }
        return this.validateTabGeneral(callback);
    }
    handleTranslate = () => {
        this.setState({ translate: true });
    }
    cancelTranslate = () => {
        this.setState({ translate: false });
    }
    closeTranslate = () => {
        this.setState({ translate: false },this.loadData);
    }
    
    renderTranslateEditor = () =>{
        var {data} = this.state;
        return <TranslateEditor
            routeTranslatableFields={`/BOP/GetTranslatableFields/${data.ID}`}
            routeLanguages={`/BOP/GetBopLanguages/${data.ID}`}
            routeDataT={`/BOP/GetBopT/${data.ID}`}
            routeSaveData={`/BOP/SaveBopT/${data.ID}`}
            data={data}
            // translatableFields={translatableFields}
            onCancel={this.cancelTranslate}
            onClose={this.closeTranslate}
        />;
    }

    /* Delete this comment if you find it, thanks */

    renderUITab = () => {
        var {data} = this.state;
        return <TranslateEditor    
                routeTranslatableFields={`/BOP/GetUITab/${data.ID}`}
                data={data}
                onCancel={this.cancelTranslate}
                onClose={this.closeTranslate}
                routeUiTabFields={uiFields}
                >

        </TranslateEditor>
    }

    /* Delete this comment if you find it, thanks */
    
	snackbarClose = () => {
        this.setState(state => {
            state.snackbar.open = false;
            return state;
        });
    }
	renderSnackbar() {
        var { snackbar } = this.state;
        return <Snackbar
            open={snackbar.open}
            onClose={this.snackbarClose}
            TransitionComponent={props => <Slide {...props} direction="up" />}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackbar.message}</span>}
            autoHideDuration={1500}
        />;
    }
    render() {
        if (this.state.isLoading) {
            //return (<div>Loading...</div>);
            return <XOXLoader />
        }
        var { classes } = this.props;
        var { data, extratabs, extras, translate } = this.state;
        if(translate){
            return this.renderTranslateEditor();
        }
        
        var loctabs = [
            {
                tabName: "Generale",
                tabContent: this.renderTabGeneral(),
                actions: [
                    {
                        group: 'actions',
                        name:'Traduzioni',
                        icon: <Translate />,
                        action: this.handleTranslate,
                    }
                ]
            },
            {
                tabName: "Fornitori",
                tabContent: this.renderTabSuppliers()
            },
            {
                tabName: "Componenti",
                tabContent: this.renderTabComponents()
            },
            {
                tabName: "Files",
                tabContent: this.renderTabFiles()
            },
            {
                tabName: "Listini",
                tabContent: this.renderTabPricelists(),
            },
            {
                tabName: "Correlati",
                tabContent: this.renderTabRelateds(),
            },
            {
                tabName: "Store",
                tabContent: this.renderTabStores(),
            },
            {
                tabName: "Storico",
                tabContent: this.renderTabHistory(),
            },
        ];

        if (extratabs) {
            var FIDTabs = extras.map(m => m.FIDExtraTab).filter((value, index, self) => self.indexOf(value) === index);
            loctabs = loctabs.concat(extratabs.filter(f => FIDTabs.includes(f.ID)).map((item, index) => ({
                tabName: item.Description,
                tabContent: this.renderExtraTab(item),
            })));
        }
        if (extras) {
            var extrasNoTab = extratabs ? extras.filter(m => !m.FIDExtraTab) : extras;
            if (extrasNoTab.length > 0) {
                loctabs = loctabs.concat({
                    tabName: 'CUSTOM',
                    tabContent: this.renderExtraTab(null)
                });
            }
        }

        if (data.IsVariation && data.CatalogCode) {
            loctabs.push({
                tabName: "Configurazione varianti",
                tabContent: this.renderTabVariationsConfig(),
            })
        }

        return (<div className={classes.root}>
            <Tabs
                innerRef={this.tabs}
                headerColor="primary"
                plainTabs={true}
                onExitClick={this.handleExit}
                onSaveClick={this.handleSaveData}
                onDeleteClick={this.handleCancelData}
                onCopyClick={data && data.ID > 0 ? this.handleDuplication : false}
				onCopyAltClick={data && data.IsVariation && data.ID > 0 ? this.handleDuplicationVariant : false}
                //onNewClick={this.handleNewData}
                validateTab={this.validate}
                tabs={loctabs}
            />
            <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            <XMessageBox ref={this.MessageBox} />
			{this.renderSnackbar()}
        </div>);
    }
}


BOPEdit.propTypes = {
    //classes: PropTypes.object.isRequired,
};

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(BOPEdit);