import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Phase extends Component {
	constructor(props) {
		super(props);
		//this.setState({ FIDParentColumnName: "Brand" });
		this.Editor = React.createRef();
	}
	checkUsed = async (FIDPhase) => {
		return await fetch('/Production/PhaseIsUsed/' + FIDPhase).then(res => res.json());

	}
	deleteLine = (FIDPhase) => {
		fetch('/Production/PhaseDelete/' + FIDPhase, { method: "POST" }).then(res => {
			this.Editor.current.doExit();
		});
	}
	onDeleteClick = () => {
		var id = this.props.match.params.id;

		var isUsed = this.checkUsed(id);

		var label = "Confermi la cancellazione della fase?";
		if (isUsed) {
			label = "La linea è utilizzata nelle distinte oppure ha delle Serie collegate. Continuando verranno cancellato le Serie collegate e sulle distinte veranno tolte le informazioni su Linea e Serie. " + label;
		}

		var buttons = [
			{
				label: "SI",
				action: () => {
					this.Editor.current.hideDialog();
					this.deleteLine(id);
				}
			},
			{
				label: "NO",
				action: () => {
					this.Editor.current.hideDialog();
				}
			},
		];

		this.Editor.current.showDialog(buttons, "Cancellazione", label);

	}
	render() {

		var fields = [
			{
				key: 'FIDDepartment',
				label: 'Reparto',
				type: 'XSelect',
				getUrl: '/prod/depart/GetSelect',
				saveUrl: undefined,
				idKey: 'ID',
				valueKey: 'Department',
				abbreviationKey: 'Abbreviation',
				Required: 2
			}
		];

		return <GenericEditor
			innerRef={this.Editor}
			id={this.props.match.params.id}
			//FIDParentColumnName='FIDBrand'
			//FIDParentColumnCaption='Brand'
			//urlGetParents='Base/GetBOPBrands'
			urlSave='/prod/phase/Save/'
			//urlDelete='/BOP/DeleteLine/'
			urlRedirect='/prod/phases/'
			//urlUploadFiles='/BOP/LineFileUpload/'
			//urlDeleteFiles='/BOP/DeleteLineFile/'
			//urlGetFiles='/BOP/GetLineFiles/'
			//urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
			urlExit='/prod/phases/'
			urlNew="/prod/phase/new"
			urlGetData='/prod/phase/'
			urlCheckChanges='/prod/phase/HasChanges/'
			urlClearSession='/prod/phase/ClearSession/'
			//onDeleteClick={this.onDeleteClick}
			fields={fields}
		/>;
	}
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Phase);
