import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withWidth } from '@material-ui/core';
import { actionCreators } from 'store/Auth';
import { isJObjectEmpty } from 'utils';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import XOXLoader from 'components/XOXLoader';
import XMessageBox from 'components/XMessageBox';
import XGMap from 'components/XGMap';

import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XGallery from 'components/XGallery';


import { CustomField } from 'components/CustomField';

import logoLicenseePayOff from "assets/img/logo-licensee-payoff.png";
import logo from "assets/img/logo-white.svg";
import {
  container,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    root: {
		...container
    },
	cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    justifyContentRight: {
        justifyContent: "flex-end !important",
        background: "linear-gradient(60deg, #383b3d, #383b3d)",
        padding: "0 15px",
        margin: "10px 0 0",
        boxShadow: "0 -4px 20px 0px rgba(0, 0, 0, 0.14), 0 -7px 10px -5px rgba(50, 50, 50, 0.4)",
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: "#FFFFFF"
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    body: {
        maxHeight: "74vh",
        overflowY: "auto",
    },
    logoContainer: {
        width: '100%',
        margin: 'auto',
    },
    logoContainerBig: {
        width: '33.33%',
        margin: 'auto',
    },
	brandTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    codeTitle: {
        fontSize: "16px",
        marginBottom: "30px",
        fontWeight: 'bold',
    },
    descriptionTitle: {
        fontSize: "14px",
        marginBottom: "30px",
        whiteSpace: "pre-line",
    },
    attrLabel: {
        fontWeight: 'bold',
    },
    marginTop: {
        marginTop: 20,
        marginBottom: 20,
    },
    brandLogo: {
        maxWidth: "300px",
        maxHeight: "100px",
    },
    center: {
        left: "50%",
        transform: "translateX(-50%)",
    },
    centerText: {
        textAlign: "center",
    },
});

class BOPView extends Component {
	constructor(props) {
        super(props);
		this.MessageBox = React.createRef();
		this.state = {
			bopData: {},
			extras: [],
			bopExtras: [],
			bopFiles: [],
			visibleMap: true,
			error:false
		}
	}
	
	componentWillMount = async() => {
		await fetch('/BOP/GetBOPExtras').then(res => res.json()).then(extras => {
			this.setState({ extras });
		});
	}
	
	componentDidMount = async() => {
		if (this.props.match && this.props.match.params.QRCodeGuid) {
			var QRCodeGuid = this.props.match.params.QRCodeGuid;
			
			var res = await fetch('/BOP/ViewBOPWarranty?QRCodeGuid='+QRCodeGuid).then(res => res.json());
			
			if (res.bopData) {
				var bopData = res.bopData;
				var bopExtras = res.bopExtras;
				
				var bopFiles = res.bopFiles && res.bopFiles.length > 0 ? res.bopFiles.split(",") : [];
				
				//if ((!bopData.geoLocationCoords || isJObjectEmpty(bopData.geoLocationCoords)) && (bopData.geoLocationOrigin || bopData.geoLocationAddress)) {
				//	var encodedLocation = encodeURI(bopData.geoLocationOrigin || bopData.geoLocationAddress);
				//	debugger;
				//	var mapData = await fetch("https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input="+encodedLocation+"&inputtype=textquery&fields=geometry&key=AIzaSyCIEOa1VD8JN2NhIQI6e-HC1rr9yxFkSVE", {
				//		mode: 'no-cors'
				//	}).then(res => res.json());
				//	
				//	if (mapData && !isJObjectEmpty(mapData) && mapData.status === "OK") {
				//		bopData.geoLocationCoords = mapData.candidates[0].geometry.location;
				//	} else {
				//		this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("VISUALIZZAZIONE GARANZIA", "Impossibile trovare le coordinate geografiche di provenienza della distinta, la mappa sarà nascosta", () => {
				//			this.setState({ bopData, visibleMap: false });
				//		});
				//		return;
				//	}
				//}
				
				if (!bopData.geoLocationCoords || isJObjectEmpty(bopData.geoLocationCoords)) {
					this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("VISUALIZZAZIONE GARANZIA", "Impossibile trovare le coordinate geografiche di provenienza della distinta, la mappa sar�nascosta", () => {
							this.setState({ bopData, visibleMap: false });
						});
					return;
				}
				
				this.setState({ bopData, bopExtras, bopFiles });
			} else {
				this.setState({ error: true }, () => {
					this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("VISUALIZZAZIONE GARANZIA", "Distinta non trovata", () => {
						this.context.router.history.push("/auth/login");
					});
				});
			}
		} else {
			this.setState({ error: true }, () => {
				this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("VISUALIZZAZIONE GARANZIA", "Distinta non trovata", () => {
					this.context.router.history.push("/auth/login");
				});
			});
		}
	}
	
	renderExtraField = (field, index) => {
        var { bopData, bopExtras } = this.state;

        var extra = [];
        if (bopExtras && bopExtras.length > 0) {
            var extraBop = bopExtras.filter(f => f.extraID === field.ID);
			
			if (extraBop.length > 0) {		
				extra = [{
					StringValue: extraBop[0].stringValue,
					BoolValue: extraBop[0].boolValue,
					IntValue: extraBop[0].intValue,
					ComboValue: extraBop[0].comboValue,
					DecimalValue: extraBop[0].decimalValue,
					DateTimeValue: extraBop[0].dateTimeValue,
					TagValue: extraBop[0].tagValue,
					GroupValue: extraBop[0].groupValue
				}];
				return (<CustomField fieldProps={{ Required: (extra.length > 0 && extra[0].Required ? 2 : 1) }} readOnly={true} key={index} field={field} extra={extra} mainId={bopData.id}/>);
			}
        }
    }
	
	renderPanelExtras = () => {
		var extras = this.state.extras;
		
		return (
            <Grid container spacing={2}>
			{extras.map((item, index) => {
				return this.renderExtraField(item, index);
			})}
			</Grid>
		);
	}
	
	renderPanelMap = () => {
		var { bopData } = this.state;
		var locNameOrAddress = bopData.geoLocationOrigin || bopData.geoLocationAddress;
		var gMapLat = bopData.geoLocationCoords.lat;
		var gMapLong = bopData.geoLocationCoords.lng;
		
		return (
			<Grid container spacing={2}>
				<div style={{ height: "50vh", width: "100%" }}><XGMap location={locNameOrAddress} coords={{ lat: gMapLat, lng: gMapLong }} /></div>
			</Grid>
		)
	}
	
	render () {
		var { classes } = this.props;
		var { bopData, bopFiles, visibleMap, extras } = this.state;
		
		if (!bopData || isJObjectEmpty(bopData)) {
			return <XOXLoader />
		}
		
		var md = 4;
        var sm = 6;
        var logoClass = classes.logoContainer;
		
		var extraPanels = extras && extras.length > 0 ? [
			{
				icon: "code",
				label: "Caratteristiche",
				content: this.renderPanelExtras()
			}
		] : [];
		
		var mapPanels = visibleMap ? [
			{
				icon: "code",
				label: "Provenienza",
				content: this.renderPanelMap()
			}
		] : [];
		
		return (
			<Fragment>
				<Card className={classes.root}>
					<CardHeader
						className={`${classes.cardHeader} ${classes.textCenter}`}
						color="gray">
                            <div className={logoClass}>
                                <img src={logoLicenseePayOff} alt="logo" style={{ width: "40%" }} />
                            </div>
							<div style={{ marginTop: 50 }}>
								<h3>WARRANTY CARD</h3>
							</div>
					</CardHeader>
					<CardBody>
						<Grid container xs={12}>
							<Grid item xs={12} md={6} className={classes.centerText} alignItems="flex-start" justify="center">
								<div style={{ marginTop: 30, width: "100%", paddingRight: 16}}>
									<Grid item xs={12} className={classes.brandTitle}>{bopData.brand ?
										<object className={classes.brandLogo} data={"/BOP/BrandDefaultImage/" + bopData.fidBrand} type="image/jpg">
											{bopData.brand}
										</object>
									: <div className={logoClass}>
										<img src={logoLicenseePayOff} alt="logo" style={{ width: "30%" }} />
									</div>}
									</Grid> 
									<Grid item xs={12} className={classes.descriptionTitle}>
										{bopData.description}
									</Grid>                       
								</div>
							</Grid>
							<Grid item xs={12} md={6}>
								<XGallery key={bopData.id} imageIds={bopFiles} noZoom={false} />
							</Grid>
						</Grid>
						<TabContainer><XExpansionPanel panels={extraPanels} /></TabContainer>
						{visibleMap && <TabContainer><XExpansionPanel panels={mapPanels} /></TabContainer>}
					</CardBody>
					<CardFooter color="gray" className={classes.justifyContentRight}>
						<img src={logo} alt="logo" style={{ height: 40 }} />
					</CardFooter>
				</Card>
				<XMessageBox innerRef={this.MessageBox} />
			</Fragment>
		);
	}
}

const enhance = compose(
    withStyles(styles),
    withWidth(),
	connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
);
export default enhance(BOPView);