import React, { Component } from 'react';
import { withStyles, withWidth } from '@material-ui/core';
import XGrid from 'components/XGrid';
import XNavigation from 'components/XNavigation';

import { TextBoxOutline,TextBoxMultipleOutline } from 'mdi-material-ui';

import { bindActionCreators, compose } from 'redux';

const styles = theme => ({
    root: {

    }
});


class CatalogueItemFlipContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            view: 'CHARACTERISTICS'
        }
    }
    xnavigate = view => () =>{
        this.setState({view});
    }
    render() {
        var { classes,data, flipCardHeight, width } = this.props;
        var { view } = this.state;

        var key = `tblBOPCustomFields_${data.ID}_${view}`;
        var gridName= 'tblBOPCustomFields';
        var url = `/BOP/GetBOPCustomFields/${data.ID}/?grid=${gridName}`;
        var onDoubleClickEdit = this.onDoubleClickProductInfoDoc;
        var actions=[];
        actions.push({ name: "Dettagli", icon: <TextBoxOutline />, action: this.xnavigate("CHARACTERISTICS"), selected: view === "CHARACTERISTICS" });
        actions.push({ name: "Magazzino", icon: <TextBoxMultipleOutline />, action: this.xnavigate("STOCK"), selected: view === "STOCK" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <span><XNavigation actions={actions} direction={direction} showLabel={true} /></span>;

        switch (view) {
            case "CHARACTERISTICS":
                break;
            case "STOCK":
                key= `tblBOPSTOCK_${data.ID}_${view}`;
                gridName="tblBOPStock";
                url = `/BOP/GetBopsStock/${data.ID}?grid=${gridName}&IsRepair=false`;
                break;
        }



        return <div style={{textAlign: 'initial', width: '100%', height: flipCardHeight - 20}} id="gridProductCustomFields" >
            <XGrid 
                label={label}
                key={key}
                containerID="gridProductCustomFields"
                dataRoute={url}
                dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                //rowsVisible={-1}
                //onDoubleClick={onDoubleClickEdit}
                //onEditButton={onDoubleClickEdit}
            />
        </div>;
    }
}

const enhance = compose(
    withWidth(),
    withStyles(styles)
);

export default enhance(CatalogueItemFlipContent);