import React from 'react';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	grayColor,
	primaryBoxShadow,
	primaryColor,
	dangerColor,
} from "assets/jss/material-dashboard-pro-react.jsx";
const useStyles = makeStyles(theme => ({

	root: {
			//marginLeft: theme.spacing.unit,
			//marginRight: theme.spacing.unit,
			marginTop: 8,
			width: "100%",
			"& > div": {
					"&:before": {
							borderBottomWidth: "1px !important",
							borderBottomColor: "#D2D2D2 !important"
					},
					"&:after": {
							borderBottomColor: primaryColor + ""
					}
			},
			"& input": {
				borderLeft: "0 !important",
				borderRight: "0 !important",
				borderTop: "0 !important",
				padding: "8px 0 7px !important",
				color: grayColor,
				marginTop: 0
			},
			"& label": {
				fontSize: ".55rem",
				//textTransform: "uppercase",
				color: "#3C4858 !important",
				top: -24
			},
			"& label.MuiInputLabel-shrink": {
				transform: "translate(0, 24px) scale(1) !important"
			},
			"&.Mui-focused .MuiInput-root": {
				"&:after": {
					transform: "scaleX(1)"
				}
			}
	},
	input: {
		padding: "11px 0 0",
		fontSize: ".75rem",
		color: "#3C4858",
		"& b": {
				display: "none"
		},
	},
	inputFocused: {
		opacity: 1,
		"&:after": {
			transform: "scaleX(1)"
		}
	},
	inputRoot: {
		padding: 0,

	},
	popper: {
		width: "auto !important",
	},
	popupIndicator: {
		display: "none"
	},
	clearIndicator: {
		display: "none"
	},
	listbox: {
		listStyle: 'none',
		margin: 0,
		padding: '8px 0px',
		position: 'relative',
		width: "100%",
		"& li": {
			transition: "all 150ms linear",
			backgroundColor: "#FFF !important",
			padding: "0px 20px",
			paddingRight: "30px",
			margin: "0 auto",
			borderRadius: "2px",
			display: "block",
			clear: "both",
			width: "calc(100% - 20px)",
			maxWidth: "50vw"
		},
		"& li a": {
			color: "#3C4858",
			fontSize: "13px",
			fontWeight: "400",
			lineHeight: "2",	
			width: "100%",
			display: "block",
			padding: "10px 0"
		},
		"& li:hover": {
			backgroundColor: primaryColor + " !important",
			...primaryBoxShadow
		},
		"& li a:hover": {
			color: "#FFFFFF",
		}
	},
}));

const useStylesInner = makeStyles(theme => ({
	error: {
		"&:after": {
				borderBottomColor: dangerColor + "!important"
		}
	},
	errorOptional: {
		"&:after": {
				borderBottomColor: primaryColor + "!important"
		}
	},
	marginNormal: {
			marginTop: "8px",
	},
	textFieldLabel: {
		fontSize: "10px",
		//textTransform: "uppercase",
		color: "#3C4858 !important",
		top: "-24px"
	},
	textFieldLabelTop: {
			fontSize: "10px",
			//textTransform: "uppercase",
			color: "#3C4858 !important",
			top: -32
	},
}))

function XFieldAutoComplete(props) {

	const classes = useStyles();
	const innerClasses = useStylesInner();
	var { id, value, readOnly, label, caption, multiline, type, field, style, rowsMax, rows, hasError, onBlur, onKeyDown, onChange, fontSize, labelAlignTop, suggestionURLBase, suggestionsLoad, searchField, maxLength ,disabled, decimalScale, preventNegativeNumber, minValue, maxValue, renderOption } = props;

		const [open, setOpen] = React.useState(false);
		const [options, setOptions] = React.useState([]);
		const loading = open && options.length === 0 && value && value.length > 2;
		const [val, setValue] = React.useState(value);
		const [inputValue, setInputValue] = React.useState(value);

		var loadSuggestionsTimeout = undefined;

		const handleChange = (event, value) => {
	
			var value = event.target.value;
			if(type === "number")
			{
				value = value.replace(",",".").replace("..",".");
				var checkValue = value;
				if(checkValue.endsWith('.')){
					checkValue += '0';
				}
				if((checkValue || checkValue === 0)){
					try {
						var floatNumber = parseFloat(checkValue);
						
						if(preventNegativeNumber && checkValue){
							
							if(floatNumber < 0) {
								return;
							}
						}
	
						if (minValue !== undefined && floatNumber < minValue) {
							value = minValue;
						}
	
						if (maxValue !== undefined && floatNumber > maxValue) {
							value = maxValue;
						}
					}
					catch{
						return; //invalid value
					}
				}
			}
	
			if (onChange) {
				onChange(id)(event, value);
			}
			
			var inputEl = document.getElementById(id);
			
			if (value && value.length >= 3 && inputEl && document.activeElement === inputEl) {
				loadSuggestionsTimeout = setTimeout(() => {
					setOpen(true);
				}, 1000);
			} else  {
				if (open) {
					setOpen(false);
				}
				if (options && options.length > 0)
					setOptions([]);
			}
			//var { id, caller, dataKey } = this.props;
			//var data = caller.state[dataKey];
			//data[id] = event.target.value;
			//caller.setState((state, props) => {
			//    state[dataKey] = data;
			//    return state;
			//});
			//caller.setState({ data });
		}
		const handleBlur = (event) => {
				//verifico formatazione e arrotondamenti
			if((value || value === 0) && type === "number"){
				// if(!decimalScale){
				// 	decimalScale = 0;
				// }
				//value = parseFloat(value);
				if(typeof value === "string" && value.endsWith('.')){
					value += '0';
				}
				var floatValue = parseFloat(value);
				if (decimalScale) {
				var multiplier = Math.pow(10,decimalScale);
				floatValue = Math.round(floatValue * multiplier) / multiplier;
				//visibleValue = (floatValue).toLocaleString('it-IT', {minimumFractionDigits: decimalScale})
				//event.value = floatValue;
				}
				
				if (onChange) {
					onChange(id)(event, floatValue);
				}
			}
			if (onBlur) {
				onBlur(id)(event);
			}

			document.getElementById(id).value = value;

			if (loadSuggestionsTimeout) {
				clearTimeout(loadSuggestionsTimeout);
			}
			setOpen(false);
			//window.setTimeout(() => {
			//	setOptions([]);
			//	setOpen(false);
			//}, 500);
		}

		const handleKeyDown = (event) => {
			if (onKeyDown) {
				onKeyDown(id)(event);
			}

			if (loadSuggestionsTimeout) {
				clearTimeout(loadSuggestionsTimeout);
				//setOptions([]);
			}
			if (open && event.keyCode === 9)
			{
				setTimeout(() => {
					setOpen(false);
				}, 1000);
			}
		} 

	React.useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		//if (!open)
		//	setOpen(true);
		var inputEl = document.getElementById(id);

		(async () => {
			if (active && open && inputEl && inputEl === document.activeElement) {
				const response = await fetch(suggestionsLoad + '?field=' + searchField + '&value=' + value);
				const suggestions = await response.json();
				if (suggestions.length > 0) {
					setOptions(suggestions);
				} else { 
					setOptions([]);
				}
			} else {
				setOptions([]);
				setOpen(false);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	var error = false;
	var errorClass = innerClasses.error;
	if (field) {
		if ((field.Required === 2 || (field.Required === 1)) && !value && value !== 0) {
			error = true;
			if (field.Required === 1) {
				errorClass = innerClasses.errorOptional;
			}
		}
	}
	if (hasError) {
		error = hasError;
		errorClass = innerClasses.error;
	}
	
	if(!maxLength){
		maxLength = 524288;
	}

	var endAdornment = null;

	if(caption) {
		endAdornment = <InputAdornment disableTypography={true} style={{ color: "black", fontSize: fontSize }} position="end">{caption}</	InputAdornment>;
	}
	if (!readOnly)
		readOnly = false;

	var inputProps = { maxLength };
	if (fontSize) {
		inputProps.style = {
			fontSize
		};
	}

	var InputProps = {
		readOnly: Boolean(readOnly),
		disabled: Boolean(readOnly),
		endAdornment: endAdornment
	};
	
	if (value === null)
		value = "";

	var visibleValue = value;

	if((value || value === 0) && type === "number"){
		//value = parseFloat(value);
		var floatValue = parseFloat(value);
		if(decimalScale){
		var multiplier = Math.pow(10,decimalScale);
		floatValue = Math.round(floatValue * multiplier) / multiplier;
			visibleValue = (floatValue).toLocaleString('it-IT', {minimumFractionDigits: decimalScale,maximumFractionDigits: decimalScale})
		}
		else {
			visibleValue = (floatValue).toLocaleString('it-IT', {maximumFractionDigits: 20});
		}

		if (isNaN(floatValue))
			visibleValue = "";

		type = undefined;
	}
	if(type === "number"){
		type = undefined;
	}

	if (!renderOption) {
		renderOption = (option) => (<span style={{fontSize: ".75rem", color: "#3C4858 !important"}}>{option.Description}</span>);

		if (suggestionURLBase)
			renderOption = (option) => (<Link style={{ color: "#3C4858 !important" }} to={suggestionURLBase + option.ID}><span style={{fontSize: ".75rem", color: "#3C4858 !important"}}>{option.Description}</span></Link>);
	}
	
	return (
		<Autocomplete
			id={id}
			itemRef={id}
			name={id}
			open={open}
			blurOnSelect={true}
			clearOnEscape={true}
			clearOnBlur={true}
			disableOpenOnFocus={true}
			freeSolo={true}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={(event) => {
				//setOpen(false);
				if (event && event.type && event.type === "blur")
					handleBlur(event);
			}}
			options={options}
			renderOption={renderOption}
			getOptionSelected={(option, value) => option.Description === value.Description}
			getOptionLabel={(option) => option.Description}
			loading={loading}
			InputProps={InputProps}
			classes={classes}
			style={{fontSize: ".75rem", color: "#3C4858 !important" }}
			value={visibleValue}
			onChange={(event, newValue) => {
				setValue(newValue);
			}}
			inputValue={inputValue ? inputValue : visibleValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
				if (event && event.target)
					handleChange(event, newInputValue);
			}}
			onKeyDown={handleKeyDown}
			onBlur={handleBlur}
			PopperComponent={(props) => { return (<Popper id={"popup-" + id} children={props.children} open={open} anchorEl={props.anchorEl} placement="bottom-start" />)}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					multiline={multiline}
					rowsMax={rowsMax || "6"}
					rows={rows || "6"}
					fullWidth
					error={error}
					//onBlur={handleBlur}
					//inputProps={inputProps}
					margin="normal"
					type={type}
					value={inputValue ? inputValue : visibleValue}
					className={classes.root}
					style={style}
					disabled={disabled}
					//onChange={handleChange}
					InputProps={{
						...params.InputProps,
						classes: {
							root: innerClasses.textFieldInput,
							error: errorClass
						},
						endAdornment: (
							<React.Fragment>
								{loading  ? <CircularProgress color="inherit" size={16} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
}

export default XFieldAutoComplete;