import React from 'react';
import Dashboard from "views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import CatalogueIcon from "@material-ui/icons/Style";
import { BOPS } from "layouts/BOPS.jsx";
import Identities from "layouts/Identities.jsx";
import IRUsers from "layouts/IRUsers.jsx";
import DocumentsSearch from "layouts/Documents.jsx";
import Documents from "views/DOC/Documents.jsx";

import IdentitiesEdit from "layouts/IdentitiesEdit.jsx";
import BOPEdit from "layouts/BOPEdit.jsx";

import Production from "views/Production/Production";
import Departments from "views/Production/Departments";
import Department from "views/Production/Department";
import Phases from "views/Production/Phases";
import Phase from "views/Production/Phase";

import { CustomFields } from "layouts/CustomFields.jsx";
import CustomField from "layouts/CustomField.jsx";
import { CustomTabs } from "layouts/CustomTabs.jsx";
import CustomTab from "layouts/CustomTab.jsx";
import { CustomGroups } from "layouts/CustomGroups.jsx";
import CustomGroup from "layouts/CustomGroup.jsx";
//import Camera from "components/Icons/Camera.jsx";
//import ToConfig from "layouts/ToConfig.jsx";
import Catalogue from "layouts/Catalogue.jsx";
import ImportBOP from "layouts/ImportBOP.jsx";
import ImportIR from "layouts/ImportIR.jsx";
import Stock from "layouts/Stock.jsx";
import GDPR from "views/IR/GDPR.jsx";
import DOCEdit from "views/DOC/DOCEdit.jsx";
import Agreements from "views/IR/Agreements.jsx";

import Post from "views/CRM/Post.jsx";

import Brands from "views/BOP/Brands.jsx";
import Brand from "views/BOP/Brand.jsx";
import Lines from "views/BOP/Lines.jsx";
import Line from "views/BOP/Line.jsx";
import SeriesList from "views/BOP/SeriesList.jsx";
import Series from "views/BOP/Series.jsx";
import ShopAreas from "views/BOP/Shopareas.jsx";
import ShopArea from "views/BOP/Shoparea.jsx";
import BOPTypologies from "views/BOP/Typologies.jsx";
import BOPTypology from "views/BOP/Typology.jsx";

import BOPGroups from "views/BOP/Groups.jsx";
import BOPGroup from "views/BOP/Group.jsx";

import BOPCategories from "views/BOP/Categories.jsx";
import BOPCategory from "views/BOP/Category.jsx";

import BOPTags from "views/BOP/Tags.jsx";
import BOPTag from "views/BOP/Tag.jsx";

import DistributionLists from "views/IR/DistributionLists.jsx";
import DistributionList from "views/IR/DistributionList.jsx";
import Videos from "views/CRM/Videos.jsx";
import Pdf from "views/CRM/Pdf.jsx";
import Warranty from "views/DOC/Warranty.jsx";
import SellOut from "views/DOC/SellOut.jsx";
import ReturnGoods from "views/DOC/ReturnGoods.jsx";
import PaymentMethod from "views/DOC/PaymentMethod.jsx";
import PaymentMethods from "views/DOC/PaymentMethods.jsx";
import Position from "views/MAG/Position.jsx";
import Positions from "views/MAG/Positions.jsx";
import StockNew from "views/DOC/Stock.jsx";
import BIProd from 'views/BI/BIProd';
import BIProdNew from 'views/BI/BIProdNew';
//import BusinessIntelligence from "views/BI/BusinessIntelligence";
import BOPStock from "views/BOP/BOPStock";
import Sales from "views/DOC/Sales";
import Prenotations from "views/BOP/Prenotations";

import Events from "views/Events/Events";
import EventEdit from "views/Events/EventEdit";
import EncryptConfig from "views/GC/EncryptConfig";
import Languages from "views/GC/Languages";
import Language from "views/GC/Language";
import { DOCStatus } from "views/DOC/DOCStatus";

import { Package, Newspaper, BookOpen, ShieldCheck, Receipt, RedoVariant, ChartAreaspline } from 'mdi-material-ui';
import { Camera } from 'components/Icons';
import FileTypology from '../views/GC/FileTypology';
import FileTypologies from '../views/GC/FileTypologies';
import ManualSyncro from '../views/Tools/ManualSyncro';

import Countries from "views/IR/Countries.jsx";
import Country from "views/IR/Country.jsx";
import Currencies from "views/GC/Currencies.jsx";
import Currency from "views/GC/Currency.jsx";
import Vats from "views/GC/Vats.jsx";
import Vat from "views/GC/Vat.jsx";
import Markups from "views/GC/Markups.jsx";
import Markup from "views/GC/Markup.jsx";
import Invoices from "views/DOC/ElectronicInvoice/Invoices.jsx";
import TranslateUI from "views/BOP/TranslateUI.jsx";
//import CatalogStats from "views/BI/BusinessIntelligence.jsx";


import EIConfigs from "views/DOC/ElectronicInvoice/EIConfigs.jsx";
import EIConfig from "views/DOC/ElectronicInvoice/EIConfig.jsx";
import CashRegisters from "views/DOC/Cash/CashRegisters.jsx";
import CashRegister from "views/DOC/Cash/CashRegister.jsx";


var customFields = [
    {
        path: "/CFFields",
        name: "Campi custom",
        mini: "RI",
        component: CustomFields,
        requireLogin: true,
    },
    {
        path: "/CFNew",
        name: "Nuovo campo custom",
        mini: "NU",
        component: CustomField,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFEdit/:id",
        name: "Modifica campo custom",
        mini: "MO",
        component: CustomField,
        redirect: true,
        requireLogin: true,
    },

    {
        path: "/CFTabs",
        name: "Tabs",
        mini: "RI",
        component: CustomTabs,
        requireLogin: true,
    },
    {
        path: "/CFTabNew",
        name: "Nuovo tab",
        mini: "NU",
        component: CustomTab,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFTabEdit/:id",
        name: "Modifica tab",
        mini: "MO",
        component: CustomTab,
        redirect: true,
        requireLogin: true,
    },

    {
        path: "/CFGroups",
        name: "Sezioni",
        mini: "RI",
        component: CustomGroups,
        requireLogin: true,
    },
    {
        path: "/CFGroupNew",
        name: "Nuova sezione",
        mini: "NU",
        component: CustomGroup,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFGroupEdit/:id",
        name: "Modifica sezione",
        mini: "MO",
        component: CustomGroup,
        redirect: true,
        requireLogin: true,
    }
];
var tools = [

    {
        path: '/importbop',
        name: 'Import bop',
        mini: "BOP",
        component: ImportBOP,
        requireLogin: true,
    },
    {
        path: '/importir',
        name: 'Import Anagrafiche',
        mini: "IR",
        component: ImportIR,
        requireLogin: true,
    },
    {
        id: "manualsync",
        path: '/manualsync',
        name: 'Sincronizzazione manuale',
        mini: "SM",
        component: ManualSyncro,
        requireLogin: true,
    },
	{
		id: "autotranslateui",
        path: "/TranslateUserInterface",
        name: "Traduzioni interfaccia",
        mini: "TR",
        component: TranslateUI,
        requireLogin: true,
    },
	//{
	//	id: "businessIntelligence",
    //    path: "/BusinessIntelligence",
    //    name: "Business Intelligence",
    //    mini: "BI",
    //    component: CatalogStats,
    //    requireLogin: true,
    //},
];
var tables = [
    {
        path: '/boptypologies',
        name: 'Ricerca tipologia',
        mini: "TYP",
        component: BOPTypologies,
        requireLogin: true,
    },
    {
        path: '/boptypology/:id',
        name: 'Tipologia',
        mini: "TYP",
        component: BOPTypology,
        hidden: true,
        requireLogin: true,
    },

    {
        path: '/bopgroups',
        name: 'Ricerca gruppi',
        mini: "GRP",
        component: BOPGroups,
        requireLogin: true,
    },
    {
        path: '/bopgroup/new',
        name: 'Gruppo',
        mini: "GRP",
        component: BOPGroup,
        hidden: true,
        requireLogin: true,
    },
    {
        path: '/bopgroup/:id',
        name: 'Gruppo',
        mini: "GRP",
        component: BOPGroup,
        hidden: true,
        requireLogin: true,
    },

    {
        path: '/bopcategories',
        name: 'Ricerca tag principali',
        mini: "MTG",
        component: BOPCategories,
        requireLogin: true,
    },
    {
        path: '/bopcategory/new',
        name: 'Tag principale',
        mini: "MTG",
        component: BOPCategory,
        hidden: true,
        requireLogin: true,
    },
    {
        path: '/bopcategory/:id',
        name: 'Tag principale',
        mini: "MTG",
        component: BOPCategory,
        hidden: true,
        requireLogin: true,
    },

    {
        path: '/boptags',
        name: 'Ricerca tag',
        mini: "TAG",
        component: BOPTags,
        requireLogin: true,
    },
    {
        path: '/boptag/new',
        name: 'Tag',
        mini: "TAG",
        component: BOPTag,
        hidden: true,
        requireLogin: true,
    },
    {
        path: '/boptag/:id',
        name: 'Tag',
        mini: "TAG",
        component: BOPTag,
        hidden: true,
        requireLogin: true,
    },

    {
        id: "brands",
        path: '/brands',
        name: 'Ricerca brand',
        mini: "B",
        component: Brands,
        requireLogin: true,
    },
    {
        id: "brandnew",
        path: '/brand/new',
        name: 'Brand',
        mini: "B",
        component: Brand,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "brandedit",
        path: '/brand/:id',
        name: 'Brand',
        mini: "B",
        component: Brand,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "lines",
        path: '/lines',
        name: 'Ricerca linea',
        mini: "L",
        component: Lines,
        requireLogin: true,
    },
    {
        id: "linenew",
        path: '/line/new',
        name: 'Line',
        mini: "L",
        component: Line,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "lineedit",
        path: '/line/:id',
        name: 'Line',
        mini: "L",
        component: Line,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "series",
        path: '/serieslist',
        name: 'Ricerca serie',
        mini: "S",
        component: SeriesList,
        requireLogin: true,
    },
    {
        id: "seriesnew",
        path: '/series/new',
        name: 'Serie',
        mini: "S",
        component: Series,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "seriesedit",
        path: '/series/:id',
        name: 'Serie',
        mini: "S",
        component: Series,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "shopareas",
        path: '/shopareas',
        name: 'Ricerca shop area',
        mini: "SA",
        component: ShopAreas,
        requireLogin: true,
    },
    {
        id: "shopareanew",
        path: '/shoparea/new',
        name: 'Shop area',
        mini: "SA",
        component: ShopArea,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "shopareaedit",
        path: '/shoparea/:id',
        name: 'Shop area',
        mini: "SA",
        component: ShopArea,
        hidden: true,
        requireLogin: true,
    },
    {
        id: 'paymethods',
        path: '/paymentmethods',
        name: 'Ricerca metodi di pagamento',
        mini: "PAY",
        component: PaymentMethods,
        requireLogin: true,
    },
    {
        id: 'paymethodnew',
        path: '/paymentmethod/new',
        name: 'Metodo di pagamento',
        mini: "PAY",
        component: PaymentMethod,
        requireLogin: true,
        hidden: true,
    },
    {
        id: 'paymethodedit',
        path: '/paymentmethod/:id',
        name: 'Metodo di pagamento',
        mini: "PAY",
        component: PaymentMethod,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "magpositions",
        path: '/magpositions',
        name: 'Posizioni di magazzino',
        mini: "MAG",
        component: Positions,
        requireLogin: true,
    },
    {
        id: "magpositionnew",
        path: '/magposition/new',
        name: 'Magazzino',
        mini: "MAG",
        component: Position,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "magpositionedit",
        path: '/magposition/:id',
        name: 'Magazzino',
        mini: "MAG",
        component: Position,
        requireLogin: true,
        hidden: true,
	},
  {
    id: "proddeparts",
		path: '/prod/departs',
		name: 'Reparti di produzione',
		mini: "PR",
		component: Departments,
		requireLogin: true,
	},
  {
    id: "proddepartnew",
		path: '/prod/depart/new',
		name: 'Reparti',
		mini: "PR",
		component: Department,
		requireLogin: true,
		hidden: true,
	},
  {
    id: "proddepartedit",
		path: '/prod/depart/:id',
		name: 'Reparti',
		mini: "PR",
		component: Department,
		requireLogin: true,
		hidden: true,
	},
  {
    id: "prodphases",
		path: '/prod/phases',
		name: 'Fasi di produzione',
		mini: "PF",
		component: Phases,
		requireLogin: true,
	},
  {
    id: "prodphasenew",
		path: '/prod/phase/new',
		name: 'Fasi',
		mini: "PF",
		component: Phase,
		requireLogin: true,
		hidden: true,
	},
  {
    id: "prodphaseedit",
		path: '/prod/phase/:id',
		name: 'Fasi',
		mini: "PF",
		component: Phase,
		requireLogin: true,
		hidden: true,
	},

    {
        path: '/encryptConf',
        name: 'Configurazione crittografia',
        mini: "ENC",
        component: EncryptConfig,
        requireLogin: true,
    },
    

    {
        path: '/languages',
        name: 'Lingue',
        mini: "LAN",
        component: Languages,
        requireLogin: true,
    },
    {
        path: '/language/new',
        name: 'Lingua',
        mini: "LAN",
        component: Language,
        requireLogin: true,
        hidden: true,
    },
    {
        path: '/language/:id',
        name: 'Lingua',
        mini: "LAN",
        component: Language,
        requireLogin: true,
        hidden: true,
    },

    

    {
        path: '/filetypologies',
        name: 'Tipologie file',
        mini: "FTP",
        component: FileTypologies,
        requireLogin: true,
    },
    {
        path: '/filetypology/new',
        name: 'Tipologia file',
        mini: "FTP",
        component: FileTypology,
        requireLogin: true,
        hidden: true,
    },
    {
        path: '/filetypology/:id',
        name: 'Tipologia file',
        mini: "FTP",
        component: FileTypology,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "countries",
        path: '/countries',
        name: 'Ricerca nazioni',
        mini: "N",
        component: Countries,
        requireLogin: true,
    },
    {
        id: "countriesnew",
        path: '/country/new',
        name: 'Nazione',
        mini: "N",
        component: Country,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "countriesedit",
        path: '/country/:id',
        name: 'Nazione',
        mini: "N",
        component: Country,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "currencies",
        path: '/currencies',
        name: 'Ricerca valute',
        mini: "VAL",
        component: Currencies,
        requireLogin: true,
    },
    {
        id: "currencynew",
        path: '/currency/new',
        name: 'Valuta',
        mini: "VAL",
        component: Currency,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "currencyedit",
        path: '/currency/:id',
        name: 'Valuta',
        mini: "VAL",
        component: Currency,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "vats",
        path: '/vats',
        name: 'Ricerca aliquote iva',
        mini: "IVA",
        component: Vats,
        requireLogin: true,
    },
    {
        id: "vatnew",
        path: '/vat/new',
        name: 'Aliquota iva',
        mini: "IVA",
        component: Vat,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "vatedit",
        path: '/vat/:id',
        name: 'Aliquota iva',
        mini: "IVA",
        component: Vat,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "markups",
        path: '/markups',
        name: 'Formule di ricarico',
        mini: "RIC",
        component: Markups,
        requireLogin: true,
    },
    {
        id: "markupnew",
        path: '/markup/new',
        name: 'Formula di ricarico',
        mini: "RIC",
        component: Markup,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "markupedit",
        path: '/markup/:id',
        name: 'Aliquota iva',
        name: 'Formula di ricarico',
        mini: "RIC",
        component: Markup,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "eiconfig",
        path: '/ElectronicInvoiceConfigs',
        name: 'Configurazione F.E.',
        mini: "FE",
        component: EIConfigs,
        requireLogin: true,
    },
    {
        id: "eiconfignew",
        path: '/ElectronicInvoiceConfig/new',
        name: 'Configurazione F.E.',
        mini: "FE",
        component: EIConfig,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "eiconfigedit",
        path: '/ElectronicInvoiceConfig/:id',
        name: 'Configurazione F.E.',
        mini: "FE",
        component: EIConfig,
        hidden: true,
        requireLogin: true,
    },
    
    {
        id: "cashregister",
        path: '/CashRegisters',
        name: 'Registratori di cassa',
        mini: "RC",
        component: CashRegisters,
        requireLogin: true,
    },
    {
        id: "cashregisternew",
        path: '/CashRegister/new',
        name: 'Registratore di cassa',
        mini: "RC",
        component: CashRegister,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "cashregisteredit",
        path: '/CashRegister/:id',
        name: 'Registratore di cassa',
        mini: "RC",
        component: CashRegister,
        hidden: true,
        requireLogin: true,
    },
];

function Catalog(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={false} isCatalog={true} {...props} />);
}
function Presentation(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={true} isCatalog={false} {...props} />);
}
function Profile(props) {
    return (<IdentitiesEdit userProfile={true} {...props} />);
}

function News(props) {
    return (<Post postType={1} {...props} />);
}
function Adv(props) {
    return (<Post postType={2} {...props} />);
}

function DOCReceiveDDT(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={3} FIDDocumentSubtype={4} FIDCausal={23} FIDRegister={2} {...props} />);
}
function DOCIssueDDT(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={3} FIDDocumentSubtype={4} FIDCausal={23} FIDRegister={1} {...props} />);
}

function DOCReceiveInvoice(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={3} FIDDocumentSubtype={5} FIDCausal={1} FIDRegister={2} {...props} />);
}
function DOCIssueInvoice(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={3} FIDDocumentSubtype={5} FIDCausal={1} FIDRegister={1} {...props} />);
}

function DOCReceiveQuote(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={2} FIDDocumentSubtype={2} FIDCausal={3} FIDRegister={2} {...props} />);
}
function DOCIssueQuote(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={2} FIDDocumentSubtype={2} FIDCausal={3} FIDRegister={1} {...props} />);
}

function DOCReceiveOrder(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={2} FIDDocumentSubtype={3} FIDCausal={4} FIDRegister={1} {...props} />);
}
function DOCIssueOrder(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={2} FIDDocumentSubtype={3} FIDCausal={4} FIDRegister={2} {...props} />);
}



function DOCInventory(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={6} FIDRegister={3} {...props} />);
}
function DOCMovements(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={8} FIDRegister={3} {...props} />);
}

function DOCModifications(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={14} FIDRegister={3} {...props} />);
}
function DOCAssignments(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={15} FIDRegister={3} {...props} />);
}

function DOCReturnCash(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={3} FIDDocumentSubtype={7} FIDCausal={38} FIDRegister={1} {...props} />);
}
function DOCIssueCash(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={3} FIDDocumentSubtype={7} FIDCausal={16} FIDRegister={1} {...props} />);
}

function DOCReceiveRepair(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={4} FIDDocumentSubtype={8} FIDCausal={34} FIDRegister={3} {...props} />);
}
function DOCIssueRepair(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={4} FIDDocumentSubtype={8} FIDCausal={36} FIDRegister={3} {...props} />);
}


function DOCInternalLoad(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={20} FIDRegister={3} {...props} />);
}
function DOCInternalUnload(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={21} FIDRegister={3} {...props} />);
}

function DOCSearchCommercial(props){
    return (<DocumentsSearch FIDDocumentType={2} {...props} />);
}

function DOCSearchFiscal(props){
    return (<DocumentsSearch FIDDocumentType={3} {...props} />);
}
function DOCSearchInternal(props){
    return (<DocumentsSearch FIDDocumentType={1} {...props} />);
}
function DOCSearchCash(props){
    return (<DocumentsSearch FIDDocumentType={3} FIDDocumentSubtype={7} {...props} />);
}
function DOCSearchRepair(props){
    return (<DocumentsSearch FIDDocumentType={4} {...props} />);
}





var dashRoutes = [
    {
        id: "home",
        path: "/home",
        name: "Home",
        localizationCode:"MN_HOME",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
        canSwipe: true,
    },
    //{
    //    collapse: true,
    //    path: "-ir",
    //    name: "Anagrafiche",
    //    state: "openIdentities",
    //    icon: DashboardIcon,
    //    //component: Identities
    //    views: identities,
    //    requireLogin: true,
    //},
   
    {
        id: "irnew",
        path: "/IRNew",
        name: "Nuovo inserimento",
        icon: DashboardIcon,
        component: IdentitiesEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"ir",
        path: "/IR",
        name: "Anagrafiche",
        localizationCode:"MN_IR",
        icon: DashboardIcon,
        component: Identities,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id: "usersir",
        path: "/Users",
        name: "Utenti",
		localizationCode:"MN_USERS",
        icon: DashboardIcon,
        component: IRUsers,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"iredit",
        path: "/IREdit/:id",
        name: "Modifica anagrafica",
        icon: DashboardIcon,
        component: IdentitiesEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        path: "/profile/",
        name: "Profilo",
        icon: DashboardIcon,
        component: Profile,
        requireLogin: true,
        hidden: true,
    },
    //{
    //    collapse: true,
    //    path: "-ag",
    //    name: "Contratti",
    //    state: "openAgreements",
    //    icon: DashboardIcon,
    //    //component: Identities
    //    views: agreements,
    //    requireLogin: true,
    //},

    {
        id:"agreements",
        path: "/Agreements",
        name: "Privacy",
        icon: DashboardIcon,
        component: Agreements,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"gdpr",
        path: "/Customer/GDPR",
        name: "Privacy",
        icon: DashboardIcon,
        component: GDPR,
        requireLogin: true,
        hidden: true,
    },
    //{
    //    path: "/Supplier/GDPR",
    //    name: "GDPR fornitori",
    //    icon: DashboardIcon,
    //    component: ToConfig,
    //    requireLogin: true,
    //},
    /*******************************************************************************************************************************/
    {
        id:"documents",
        path: '/documents/receive/ddt',
        name: 'Ricezione DDT',
        icon: CatalogueIcon,
        component: DOCReceiveDDT,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/receive/invoice',
        name: 'Operazioni su acquisti',
        icon: CatalogueIcon,
        component: DOCReceiveInvoice,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/issue/ddt',
        name: 'Emissione DDT',
        icon: CatalogueIcon,
        component: DOCIssueDDT,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/issue/invoice',
        name: 'Operazioni su vendite',
        icon: CatalogueIcon,
        component: DOCIssueInvoice,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/fiscal',
        name: 'Ricerca documenti fiscali',
        icon: CatalogueIcon,
        component: DOCSearchFiscal,
        requireLogin: true,
        hidden: true,
    },
    /*******************************************************************************************************************************/
    {
        id:"documents",
        path: '/documents/receive/quote',
        name: 'Emissione preventivo',
        icon: CatalogueIcon,
        component: DOCReceiveQuote,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/receive/order',
        name: 'Emissione ordine',
        icon: CatalogueIcon,
        component: DOCReceiveOrder,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/issue/quote',
        name: 'Ricezione preventivo',
        icon: CatalogueIcon,
        component: DOCIssueQuote,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/issue/order',
        name: 'Ricezione ordine',
        icon: CatalogueIcon,
        component: DOCIssueOrder,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/commercial',
        name: 'Ricerca documenti commerciali',
        icon: CatalogueIcon,
        component: DOCSearchCommercial,
        requireLogin: true,
        hidden: true,
    },
    /*******************************************************************************************************************************/
    {
        id:"documents",
        path: '/documents/inventory',
        name: 'Inventario',
        icon: CatalogueIcon,
        component: DOCInventory,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/movements',
        name: 'Spostamenti',
        icon: CatalogueIcon,
        component: DOCMovements,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/load',
        name: 'Carico',
        icon: CatalogueIcon,
        component: DOCInternalLoad,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/unload',
        name: 'Scarico',
        icon: CatalogueIcon,
        component: DOCInternalUnload,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/modifications',
        name: 'Modifiche',
        icon: CatalogueIcon,
        component: DOCModifications,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/assignments',
        name: 'Assegnazioni',
        icon: CatalogueIcon,
        component: DOCAssignments,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/internal',
        name: 'Ricerca documenti interni',
        icon: CatalogueIcon,
        component: DOCSearchInternal,
        requireLogin: true,
        hidden: true,
    },
    /*******************************************************************************************************************************/
    
    {
        id:"documents",
        path: '/documents/render/cash',
        name: 'Reso cassa',
        icon: CatalogueIcon,
        component: DOCReturnCash,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/issue/cash',
        name: 'Emissione cassa',
        icon: CatalogueIcon,
        component: DOCIssueCash,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/cash',
        name: 'Ricerca documenti cassa',
        icon: CatalogueIcon,
        component: DOCSearchCash,
        requireLogin: true,
        hidden: true,
    },
    /*******************************************************************************************************************************/
    
    {
        id:"documents",
        path: '/documents/receive/repair',
        name: 'Ricezione riparazione',
        icon: CatalogueIcon,
        component: DOCReceiveRepair,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/issue/repair',
        name: 'Consegna riparazione',
        icon: CatalogueIcon,
        component: DOCIssueRepair,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"documents",
        path: '/documents/repair',
        name: 'Ricerca documenti riparazione',
        icon: CatalogueIcon,
        component: DOCSearchRepair,
        requireLogin: true,
        hidden: true,
    },
    /*******************************************************************************************************************************/
    {
        id:"documents",
        path: '/documents',
        name: 'Documenti',
        localizationCode:"MN_DOCUMENTS",
        icon: CatalogueIcon,
        component: Documents,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"docnew",
        path: '/doc/new',
        name: 'Documenti',
        localizationCode:"MN_DOCUMENTS",
        icon: CatalogueIcon,
        component: DOCEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"docstatus",
        path: '/docstatus',
        name: 'Stato documenti',
		localizationCode:"MN_DOC_STATUS",
        icon: CatalogueIcon,
        component: DOCStatus,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"sales",
        path: '/sales',
        name: 'Vendite',
		localizationCode:"MN_SALES",
        icon: CatalogueIcon,
        component: Sales,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"assignments",
        path: '/assignments',
        name: 'Assegnazioni',
		localizationCode:"MN_ASSIGNMENTS",
        icon: CatalogueIcon,
        component: Prenotations,
        requireLogin: true,
        canSwipe: true,
    },
    //{
    //    id: "bi",
    //    path: '/BI',
    //    name: 'BI',
    //    icon: ChartAreaspline,
    //    component: BusinessIntelligence,
    //    requireLogin: true,
    //    canSwipe: true,
    //},
    /* EVENTS */
    {
        id: "events",
        path: "/Events/Edit/:id",
        name: 'Modifica evento',
        icon: Package,
        component: EventEdit,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "events",
        path: "/Events/Edit/",
        name: 'Modifica evento',
        icon: Package,
        component: EventEdit,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "events",
        path: "/Events/",
        name: 'Eventi',
		localizationCode:"MN_EVENTS",
        icon: Package,
        component: Events,
        requireLogin: true,
        canSwipe: true,
    },
    /* END EVENTS */
    {
        id: "returnbops",
        path: '/returnprod',
        name: 'Reso merci',
		localizationCode:"MN_RETURN_GOODS",
        icon: RedoVariant,
        component: ReturnGoods,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"warranty",
        path: '/warranty',
        name: 'Garanzia',
		localizationCode:"MN_WARRANTY",
        icon: ShieldCheck,
        component: Warranty,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"sellout",
        path: '/sellout',
        name: 'Sell out',
        icon: Receipt,
        component: SellOut,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"docedit",
        path: '/doc/edit/:id',
        name: 'Documenti',
		localizationCode:"MN_DOCUMENTS",
        icon: CatalogueIcon,
        component: DOCEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"electronicinvoice",
        path: '/electronicinvoice',
        name: 'Cassetto fiscale',
		localizationCode:"MN_CS_FISCAL",
        icon: CatalogueIcon,
        component: Invoices,
        requireLogin: true,
        hidden: false,
    },
    {
        id:"catalogueedit",
        path: '/catalogue/:id',
        name: 'Catalogo',
        localizationCode:"MN_CATALOGUE",
        icon: CatalogueIcon,
        component: Catalog,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "catalogueedit",
        path: '/presentation/:id',
        name: 'Presentazione',
        icon: CatalogueIcon,
        component: Presentation,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"presentation",
        path: '/presentation',
        name: 'Presentazione',
		localizationCode:"MN_PRESENTATION",
        icon: Camera,
        component: Presentation,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"catalogue",
        path: '/catalogue',
        name: 'Catalogo',
        localizationCode:"MN_CATALOGUE",
        icon: Camera,
        component: Catalog,
        requireLogin: true,
        canSwipe: true,
    },
	{
      id: "bop",
      path: "/BOPS/",
      name: 'Distinte',
	  localizationCode:"MN_BOPG",
      icon: Package,
      component: BOPS,
      requireLogin: true,
      canSwipe: true,
	},
	{
      id: "bopedit",
      path: "/BOP/",
      name: 'Modifica distinta',
      icon: Package,
      component: BOPEdit,
      hidden: true,
      requireLogin: true,
	},
	{
      id:"bopedit",
      path: "/BOPEdit/:id",
      name: 'Modifica distinta',
      icon: Package,
      component: BOPEdit,
      hidden: true,
      requireLogin: true,
	},
    {
        id:"stock",
        path: '/stock/:id',
        name: 'Magazzino',
        icon: Package ,
        component: BOPStock,
        //component: Stock,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"stock",
        path: '/stock',
        name: 'Magazzino',
        icon: Package ,
		localizationCode:"MN_STOCK",
        component: StockNew,
        //component: Stock,//per eurogems
        requireLogin: true,
        canSwipe: true,
	},
	{
		id: "production",
		path: "/production",
		name: "Produzione",
		localizationCode:"MN_PRODUCTION",
		icon: Package,
		component: Production,
		requireLogin: true,
    },
    {
        id: "BIProd",
        path: "/BIProd",
        name: "BI Produzione",
        icon: DashboardIcon,
        component: BIProd,
        requireLogin: true,
        canSwipe: true,
    },
    // {
    //     id: "BIProd",
    //     path: "/BIProd",
    //     name: "BI Produzione",
    //     icon: DashboardIcon,
    //     component: BIProdNew,
    //     requireLogin: true,
    //     canSwipe: true,
    // },
  {
      id: "news",
      path: "/news",
      name: "News",
      icon: Newspaper,
      component: News,
      requireLogin: true,
      canSwipe: true,
  },
  {
      id: "advertising",
      path: "/advertising",
      name: "Advertising",
      icon: BookOpen,
      component: Adv,
      requireLogin: true,
      canSwipe: true,
  },
  {
      id: "messages",
      path: "/messages",
      name: "Messages",
      icon: DashboardIcon,
      component: Dashboard,
      requireLogin: true,
      canSwipe: true,
  },
  {
      id: "tasks",
      path: "/Tasks",
      name: "Tasks",
      icon: DashboardIcon,
      component: Dashboard,
      requireLogin: true,
      canSwipe: true,
  },
  {
      id:"customfields",
      collapse: true,
      path: "-cf",
      name: "Custom",
      state: "openCustomFields",
      icon: DashboardIcon,
      //component: Identities
      views: customFields,
      requireLogin: true,
  },
  {
      id:"admintools",
      collapse: true,
      path: "-cf",
      name: "Tools",
      state: "openTools",
      icon: DashboardIcon,
      //component: Identities
      views: tools,
      requireLogin: true,
  },
  {
        id: "tables",
      collapse: true,
      path: "-tabs",
      name: "Tabelle",
	  localizationCode:"MN_TABLES",
      state: "openTables",
      icon: DashboardIcon,
      //component: Identities
      views: tables,
      requireLogin: true,
  },
  
  {
      id: "distributionlists",
      path: '/distributionlists',
      name: 'Liste distribuzione',
	  localizationCode:"MN_DISTR_LISTS",
      icon: CatalogueIcon,
      component: DistributionLists,
      requireLogin: true,
  },
  {
      id: "distributionlistnew",
      path: '/distributionlist/new',
      name: 'Lista distribuzione',
      icon: CatalogueIcon,
      component: DistributionList,
      hidden: true,
      requireLogin: true,
  },
  {
      id: "distributionlistedit",
      path: '/distributionlist/:id',
      name: 'Lista distribuzione',
      icon: CatalogueIcon,
      component: DistributionList,
      hidden: true,
      requireLogin: true,
  },
  {
      id: "videos",
      path: '/videos',
      name: 'Gestione video',
	  localizationCode:"MN_MANAGE_VIDEO",
      icon: CatalogueIcon,
      component: Videos,
      requireLogin: true,
  },
  {
      id: "pdf",
      path: '/pdf',
      name: 'Gestione pdf',
	  localizationCode:"MN_MANAGE_PDF",
      icon: CatalogueIcon,
      component: Pdf,
      requireLogin: true,
  },
  { redirect: true, path: "/", pathTo: "/home", name: "Home",  localizationCode:"MN_HOME", }
];
export default dashRoutes;
