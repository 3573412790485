import React from 'react';
import Dashboard from "views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import CatalogueIcon from "@material-ui/icons/Style";
import { BOPS } from "layouts/BOPS.jsx";
import Identities from "layouts/Identities.jsx";
import IRUsers from "layouts/IRUsers.jsx";
import DocumentsSearch from "layouts/Documents.jsx";
import Documents from "views/DOC/Documents.jsx";

import IdentitiesEdit from "layouts/IdentitiesEdit.jsx";
import BOPEdit from "layouts/BOPEdit.jsx";

import Production from "views/Production/Production";
import Departments from "views/Production/Departments";
import Department from "views/Production/Department";
import Phases from "views/Production/Phases";
import Phase from "views/Production/Phase";

import { CustomFields } from "layouts/CustomFields.jsx";
import CustomField from "layouts/CustomField.jsx";
import { CustomTabs } from "layouts/CustomTabs.jsx";
import CustomTab from "layouts/CustomTab.jsx";
import { CustomGroups } from "layouts/CustomGroups.jsx";
import CustomGroup from "layouts/CustomGroup.jsx";
//import Camera from "components/Icons/Camera.jsx";
//import ToConfig from "layouts/ToConfig.jsx";
import Catalogue from "layouts/Catalogue.jsx";
import ImportBOP from "layouts/ImportBOP.jsx";
import ImportIR from "layouts/ImportIR.jsx";
import Stock from "layouts/Stock.jsx";
import GDPR from "views/IR/GDPR.jsx";
import DOCEdit from "views/DOC/DOCEdit.jsx";
import Agreements from "views/IR/Agreements.jsx";

import Post from "views/CRM/Post.jsx";

import Brands from "views/BOP/Brands.jsx";
import Brand from "views/BOP/Brand.jsx";
import Lines from "views/BOP/Lines.jsx";
import Line from "views/BOP/Line.jsx";
import SeriesList from "views/BOP/SeriesList.jsx";
import Series from "views/BOP/Series.jsx";
import ShopAreas from "views/BOP/Shopareas.jsx";
import ShopArea from "views/BOP/Shoparea.jsx";
import BOPTypologies from "views/BOP/Typologies.jsx";
import BOPTypology from "views/BOP/Typology.jsx";

import BOPGroups from "views/BOP/Groups.jsx";
import BOPGroup from "views/BOP/Group.jsx";

import BOPCategories from "views/BOP/Categories.jsx";
import BOPCategory from "views/BOP/Category.jsx";

import BOPTags from "views/BOP/Tags.jsx";
import BOPTag from "views/BOP/Tag.jsx";

import DistributionLists from "views/IR/DistributionLists.jsx";
import DistributionList from "views/IR/DistributionList.jsx";
import Videos from "views/CRM/Videos.jsx";
import Pdf from "views/CRM/Pdf.jsx";
import Warranty from "views/DOC/Warranty.jsx";
import SellOut from "views/DOC/SellOut.jsx";
import ReturnGoods from "views/DOC/ReturnGoods.jsx";
import PaymentMethod from "views/DOC/PaymentMethod.jsx";
import PaymentMethods from "views/DOC/PaymentMethods.jsx";
import Position from "views/MAG/Position.jsx";
import Positions from "views/MAG/Positions.jsx";
import StockNew from "views/DOC/Stock.jsx";
import BusinessIntelligence from "views/BI/BusinessIntelligence";
import BOPStock from "views/BOP/BOPStock";
import Sales from "views/DOC/Sales";
import {Prenotations} from "views/BOP/Prenotations";

import Events from "views/Events/Events";
import EventEdit from "views/Events/EventEdit";
import EncryptConfig from "views/GC/EncryptConfig";
import Languages from "views/GC/Languages";
import Language from "views/GC/Language";
import { DOCStatus } from "views/DOC/DOCStatus";

import { Package, Newspaper, BookOpen, ShieldCheck, Receipt, RedoVariant, ChartAreaspline } from 'mdi-material-ui';
import { Camera } from 'components/Icons';
import FileTypology from '../views/GC/FileTypology';
import FileTypologies from '../views/GC/FileTypologies';

var customFields = [
    {
        path: "/CFFields",
        name: "Campi custom",
        mini: "RI",
        component: CustomFields,
        requireLogin: true,
    },
    {
        path: "/CFNew",
        name: "Nuovo campo custom",
        mini: "NU",
        component: CustomField,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFEdit/:id",
        name: "Modifica campo custom",
        mini: "MO",
        component: CustomField,
        redirect: true,
        requireLogin: true,
    },

    {
        path: "/CFTabs",
        name: "Tabs",
        mini: "RI",
        component: CustomTabs,
        requireLogin: true,
    },
    {
        path: "/CFTabNew",
        name: "Nuovo tab",
        mini: "NU",
        component: CustomTab,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFTabEdit/:id",
        name: "Modifica tab",
        mini: "MO",
        component: CustomTab,
        redirect: true,
        requireLogin: true,
    },

    {
        path: "/CFGroups",
        name: "Sezioni",
        mini: "RI",
        component: CustomGroups,
        requireLogin: true,
    },
    {
        path: "/CFGroupNew",
        name: "Nuova sezione",
        mini: "NU",
        component: CustomGroup,
        redirect: true,
        requireLogin: true,
    },
    {
        path: "/CFGroupEdit/:id",
        name: "Modifica sezione",
        mini: "MO",
        component: CustomGroup,
        redirect: true,
        requireLogin: true,
    }
];
var tools = [

    {
        path: '/importbop',
        name: 'Import bop',
        mini: "BOP",
        component: ImportBOP,
        requireLogin: true,
    },
    {
        path: '/importir',
        name: 'Import Anagrafiche',
        mini: "IR",
        component: ImportIR,
        requireLogin: true,
    },
];
var tables = [
    /*{
        path: '/boptypologies',
        name: 'Ricerca tipologia',
        mini: "TYP",
        component: BOPTypologies,
        requireLogin: true,
    },
    {
        path: '/boptypology/:id',
        name: 'Tipologia',
        mini: "TYP",
        component: BOPTypology,
        hidden: true,
        requireLogin: true,
    },

    {
        path: '/bopgroups',
        name: 'Ricerca gruppi',
        mini: "GRP",
        component: BOPGroups,
        requireLogin: true,
    },
    {
        path: '/bopgroup/new',
        name: 'Gruppo',
        mini: "GRP",
        component: BOPGroup,
        hidden: true,
        requireLogin: true,
    },
    {
        path: '/bopgroup/:id',
        name: 'Gruppo',
        mini: "GRP",
        component: BOPGroup,
        hidden: true,
        requireLogin: true,
    },

    {
        path: '/bopcategories',
        name: 'Ricerca tag principali',
        mini: "MTG",
        component: BOPCategories,
        requireLogin: true,
    },
    {
        path: '/bopcategory/new',
        name: 'Tag principale',
        mini: "MTG",
        component: BOPCategory,
        hidden: true,
        requireLogin: true,
    },
    {
        path: '/bopcategory/:id',
        name: 'Tag principale',
        mini: "MTG",
        component: BOPCategory,
        hidden: true,
        requireLogin: true,
    },

    {
        path: '/boptags',
        name: 'Ricerca tag',
        mini: "TAG",
        component: BOPTags,
        requireLogin: true,
    },
    {
        path: '/boptag/new',
        name: 'Tag',
        mini: "TAG",
        component: BOPTag,
        hidden: true,
        requireLogin: true,
    },
    {
        path: '/boptag/:id',
        name: 'Tag',
        mini: "TAG",
        component: BOPTag,
        hidden: true,
        requireLogin: true,
    },

    {
        id: "brands",
        path: '/brands',
        name: 'Ricerca brand',
        mini: "B",
        component: Brands,
        requireLogin: true,
    },
    {
        id: "brandnew",
        path: '/brand/new',
        name: 'Brand',
        mini: "B",
        component: Brand,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "brandedit",
        path: '/brand/:id',
        name: 'Brand',
        mini: "B",
        component: Brand,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "lines",
        path: '/lines',
        name: 'Ricerca linea',
        mini: "L",
        component: Lines,
        requireLogin: true,
    },
    {
        id: "linenew",
        path: '/line/new',
        name: 'Line',
        mini: "L",
        component: Line,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "lineedit",
        path: '/line/:id',
        name: 'Line',
        mini: "L",
        component: Line,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "series",
        path: '/serieslist',
        name: 'Ricerca serie',
        mini: "S",
        component: SeriesList,
        requireLogin: true,
    },
    {
        id: "seriesnew",
        path: '/series/new',
        name: 'Serie',
        mini: "S",
        component: Series,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "seriesedit",
        path: '/series/:id',
        name: 'Serie',
        mini: "S",
        component: Series,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "shopareas",
        path: '/shopareas',
        name: 'Ricerca shop area',
        mini: "SA",
        component: ShopAreas,
        requireLogin: true,
    },
    {
        id: "shopareanew",
        path: '/shoparea/new',
        name: 'Shop area',
        mini: "SA",
        component: ShopArea,
        hidden: true,
        requireLogin: true,
    },
    {
        id: "shopareaedit",
        path: '/shoparea/:id',
        name: 'Shop area',
        mini: "SA",
        component: ShopArea,
        hidden: true,
        requireLogin: true,
    },
    {
        id: 'paymethods',
        path: '/paymentmethods',
        name: 'Ricerca metodi di pagamento',
        mini: "PAY",
        component: PaymentMethods,
        requireLogin: true,
    },
    {
        id: 'paymethodnew',
        path: '/paymentmethod/new',
        name: 'Metodo di pagamento',
        mini: "PAY",
        component: PaymentMethod,
        requireLogin: true,
        hidden: true,
    },
    {
        id: 'paymethodedit',
        path: '/paymentmethod/:id',
        name: 'Metodo di pagamento',
        mini: "PAY",
        component: PaymentMethod,
        requireLogin: true,
        hidden: true,
    },*/
    {
        id: "magpositions",
        path: '/magpositions',
        name: 'Posizioni di magazzino',
        mini: "MAG",
        component: Positions,
        requireLogin: true,
    },
    {
        id: "magpositionnew",
        path: '/magposition/new',
        name: 'Magazzino',
        mini: "MAG",
        component: Position,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "magpositionedit",
        path: '/magposition/:id',
        name: 'Magazzino',
        mini: "MAG",
        component: Position,
        requireLogin: true,
        hidden: true,
	},
  {
    id: "proddeparts",
		path: '/prod/departs',
		name: 'Reparti di produzione',
		mini: "PR",
		component: Departments,
		requireLogin: true,
	},
  {
    id: "proddepartnew",
		path: '/prod/depart/new',
		name: 'Reparti',
		mini: "PR",
		component: Department,
		requireLogin: true,
		hidden: true,
	},
  {
    id: "proddepartedit",
		path: '/prod/depart/:id',
		name: 'Reparti',
		mini: "PR",
		component: Department,
		requireLogin: true,
		hidden: true,
	},
  {
    id: "prodphases",
		path: '/prod/phases',
		name: 'Fasi di produzione',
		mini: "PF",
		component: Phases,
		requireLogin: true,
	},
  {
    id: "prodphasenew",
		path: '/prod/phase/new',
		name: 'Fasi',
		mini: "PF",
		component: Phase,
		requireLogin: true,
		hidden: true,
	},
  {
    id: "prodphaseedit",
		path: '/prod/phase/:id',
		name: 'Fasi',
		mini: "PF",
		component: Phase,
		requireLogin: true,
		hidden: true,
	},

    {
        path: '/encryptConf',
        name: 'Configurazione crittografia',
        mini: "ENC",
        component: EncryptConfig,
        requireLogin: true,
    },
    

    {
        path: '/languages',
        name: 'Lingue',
        mini: "LAN",
        component: Languages,
        requireLogin: true,
    },
    {
        path: '/language/new',
        name: 'Lingua',
        mini: "LAN",
        component: Language,
        requireLogin: true,
        hidden: true,
    },
    {
        path: '/language/:id',
        name: 'Lingua',
        mini: "LAN",
        component: Language,
        requireLogin: true,
        hidden: true,
    },

    

    {
        path: '/filetypologies',
        name: 'Tipologie file',
        mini: "FTP",
        component: FileTypologies,
        requireLogin: true,
    },
    {
        path: '/filetypology/new',
        name: 'Tipologia file',
        mini: "FTP",
        component: FileTypology,
        requireLogin: true,
        hidden: true,
    },
    {
        path: '/filetypology/:id',
        name: 'Tipologia file',
        mini: "FTP",
        component: FileTypology,
        requireLogin: true,
        hidden: true,
    },
];

function Catalog(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={false} isCatalog={true} {...props} />);
}
function Presentation(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={true} isCatalog={false} {...props} />);
}
function Profile(props) {
    return (<IdentitiesEdit userProfile={true} {...props} />);
}

function News(props) {
    return (<Post postType={1} {...props} />);
}
function Adv(props) {
    return (<Post postType={2} {...props} />);
}

function DOCReceiveDDT(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={3} FIDDocumentSubtype={4} FIDCausal={23} FIDRegister={2} {...props} />);
}
function DOCIssueDDT(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={3} FIDDocumentSubtype={4} FIDCausal={23} FIDRegister={1} {...props} />);
}

function DOCReceiveInvoice(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={3} FIDDocumentSubtype={5} FIDCausal={1} FIDRegister={2} {...props} />);
}
function DOCIssueInvoice(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={3} FIDDocumentSubtype={5} FIDCausal={1} FIDRegister={1} {...props} />);
}

function DOCReceiveQuote(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={2} FIDDocumentSubtype={2} FIDCausal={3} FIDRegister={2} {...props} />);
}
function DOCIssueQuote(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={2} FIDDocumentSubtype={2} FIDCausal={3} FIDRegister={1} {...props} />);
}

function DOCReceiveOrder(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={2} FIDDocumentSubtype={3} FIDCausal={4} FIDRegister={1} {...props} />);
}
function DOCIssueOrder(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={2} FIDDocumentSubtype={3} FIDCausal={4} FIDRegister={2} {...props} />);
}



function DOCInventory(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={6} FIDRegister={3} {...props} />);
}
function DOCMovements(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={8} FIDRegister={3} {...props} />);
}

function DOCModifications(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={14} FIDRegister={3} {...props} />);
}
function DOCAssignments(props){
    return (<DOCEdit FIDCycle={1} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={15} FIDRegister={3} {...props} />);
}

function DOCReturnCash(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={3} FIDDocumentSubtype={7} FIDCausal={38} FIDRegister={1} {...props} />);
}
function DOCIssueCash(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={3} FIDDocumentSubtype={7} FIDCausal={16} FIDRegister={1} {...props} />);
}

function DOCReceiveRepair(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={4} FIDDocumentSubtype={8} FIDCausal={34} FIDRegister={3} {...props} />);
}
function DOCIssueRepair(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={4} FIDDocumentSubtype={8} FIDCausal={36} FIDRegister={3} {...props} />);
}


function DOCInternalLoad(props){
    return (<DOCEdit FIDCycle={3} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={20} FIDRegister={3} {...props} />);
}
function DOCInternalUnload(props){
    return (<DOCEdit FIDCycle={2} FIDDocumentType={1} FIDDocumentSubtype={1} FIDCausal={21} FIDRegister={3} {...props} />);
}

function DOCSearchCommercial(props){
    return (<DocumentsSearch FIDDocumentType={2} {...props} />);
}

function DOCSearchFiscal(props){
    return (<DocumentsSearch FIDDocumentType={3} {...props} />);
}
function DOCSearchInternal(props){
    return (<DocumentsSearch FIDDocumentType={1} {...props} />);
}
function DOCSearchCash(props){
    return (<DocumentsSearch FIDDocumentType={3} FIDDocumentSubtype={7} {...props} />);
}
function DOCSearchRepair(props){
    return (<DocumentsSearch FIDDocumentType={4} {...props} />);
}





var dashRoutes = [
	{
		id: "production",
		path: "/production",
		name: "Produzione",
		icon: Package,
		component: Production,
		requireLogin: true,
	},
    { redirect: true, path: "/home", pathTo: "/production", name: "Produzione", },
    { redirect: true, path: "/", pathTo: "/production", name: "Produzione", }
];
export default dashRoutes;
