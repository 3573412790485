import React, { Component } from 'react';

import PropTypes from 'prop-types';
import XNavigation from 'components/XNavigation';
import XGrid from 'components/XGrid';
import { TextBoxOutline, TextBoxMultipleOutline } from 'mdi-material-ui';
import { FileDocumentBoxMultipleVarOutline } from 'components/Icons';


class BOPHistory extends Component {
    constructor (props){
        super(props);
        this.state = {
            productInfoView: props.isStock ? 'historyid' : 'historycode',
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    showHistoryID = () => this.setState({productInfoView: 'historyid'});
    showHistoryCode = () => this.setState({productInfoView: 'historycode'});
    showHistoryCatalogCode = () => this.setState({productInfoView: 'historycatalogcode'});
    onDoubleClickProductInfoDoc = (_,data) => {
        this.context.router.history.push('/doc/edit/' + data.ID);
    }
    renderProductInfo = () =>{
        var { productInfoView } = this.state;
        var { data,width,FIDIRIdentity, isStock } = this.props;

        var actions = [];

        if(isStock) {
            actions.push({ name: "Storico su matricola", icon: <TextBoxOutline />, action: this.showHistoryID, selected: productInfoView === "historyid" });    
        }
        actions.push({ name: "Storico su codice", icon: <TextBoxMultipleOutline />, action: this.showHistoryCode, selected: productInfoView === "historycode" });
        actions.push({ name: "Storico su codice catalogo", icon: <FileDocumentBoxMultipleVarOutline />, action: this.showHistoryCatalogCode, selected: productInfoView === "historycatalogcode" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <span><XNavigation actions={actions} direction={direction} showLabel={true} /></span>;

        var key = "tblBOPHistoryID";
        var gridName= 'tblBOPHistory1';
        var url = `/DOC/GetBOPHistory/${data.ID}/?grid=${gridName}&FIDIdentity=${FIDIRIdentity}`;
        var onDoubleClickEdit = this.onDoubleClickProductInfoDoc;

        switch(productInfoView){
            case "historycode":
                key = "tblBOPHistoryCode";
                gridName= 'tblBOPHistory2';
                url = `/DOC/GetBOPHistory/${data.ID}/?grid=${gridName}&FIDIdentity=${FIDIRIdentity}&filterID=false`;
                onDoubleClickEdit = this.onDoubleClickProductInfoDoc;
                break;
            case "historycatalogcode":
                key = "tblBOPHistoryCatalogCode";
                gridName= 'tblBOPHistory3';
                url = `/DOC/GetBOPHistory/${data.ID}/?grid=${gridName}&FIDIdentity=${FIDIRIdentity}&filterID=false&filterCode=false`;
                onDoubleClickEdit = this.onDoubleClickProductInfoDoc;
                break;
        }

        return (
            <div style={{textAlign: 'initial', width: '100%', marginTop: 10}} id="gridProductInfoContainer" >
                <XGrid 
                    key={key}
                    label={label}
                    containerID="gridProductInfoContainer"
                    dataRoute={url}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    rowsVisible={-1}
                    onDoubleClick={onDoubleClickEdit}
                    onEditButton={onDoubleClickEdit}

                    //cardWidth={4}
                    //cardHeight={4.5}
                    //showChangeView={true}
                    //width={this.props.width}
                />
            </div>
        );
    }


    render() {
        return this.renderProductInfo();
    }
}


export default BOPHistory;