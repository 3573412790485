import React, { Component, Fragment } from 'react';
import { withStyles,withWidth, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"
import XDateField from "components/XDateField";
import XButton from "components/XButton";
import { bindActionCreators,compose } from 'redux';
import { Legend, XAxis,YAxis,BarChart,CartesianGrid,Tooltip, Bar, PieChart, Pie, Sector, Cell, } from 'recharts';
import XMessageBox from 'components/XMessageBox';
import Typography from '@material-ui/core/Typography';

import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

import XNavigation from '../../components/XNavigation';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { AutoSizer } from 'react-virtualized';
import XSelect from '../../components/XSelect';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';

import ReactHtmlParser from 'react-html-parser';
import XExpansionPanel from 'components/XExpansionPanel';
import { connect } from 'react-redux';
import { actionCreators as authCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';


//Icons
import { Plus, ChartPie  } from 'mdi-material-ui';
// NEW ICONS 
import InsertChartIcon from '@material-ui/icons/InsertChart';
import CakeIcon from '@material-ui/icons/Cake';
import SubjectIcon from '@material-ui/icons/Subject';
//import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';
import VisibilityIcon from '@material-ui/icons/Visibility';
import classNames from 'classnames';
import XOXLoader from 'components/XOXLoader';


var ps;
const drawerWidth = 240;
const styles = theme => ({
    root: {
        textAlign:'center',
    },
    card: {
        textAlign: 'center',
        borderRadius: 1,
    },
    card2:{
        textAlign: 'center',
        borderRadius: 0,
        padding: 20,
    },
    cardCategory:{
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 10
    },
    loadButton:{
        "& > button": {
            position: 'relative',
            left: '50%',
            transform: 'translate(-50%)'
        },  
    },

    tortaMain:{
        right: '50%',
        overflow: 'hidden',
    },

    torta:{
        position: 'relative',
        transform: 'scale(1.5)',
        left: '40%',
        bottom: '10%',
        fontSize: "x-small",
    },


    container: {
        width: '100%',
    },

    tooltipRoot: {
        backgroundColor: 'white',
        borderRadius:1,
        borderWidth: 20,
        borderColor: '#fff',
        width: 'auto',
        height: 'auto',
        border: '25px',
        textAlign: 'center',
    },

    DenominationValue: {
        fontWeight: 'bold',
        color: '#141823',
        display: 'inline-block',
    },

    ViewsValue: {
        fontWeight: 'bold',
        color: '#F4B50D',
        textAlign: 'center',
        
    }
});



const format = (number, a, b) =>{
    if(number){
        
        return `${(number).toLocaleString('it-IT', {minimumFractionDigits: 2})}`
    }

    return "";
}

// const PercentageFormat = (number) => {
//     if(number){
//         return `${(number).toLocaleString('it-IT', {minimumFractionDigits: 2})}%`
//     }
//     return "";
// }



const CustomTooltip = withStyles(styles)((props) => {
    var { active, payload, label, classes } = props;
    if (active) {
        
        var denomination = payload && payload[0].payload.Denomination;
      return (
        <div className={classes.tooltipRoot}>
          <p className={classes.DenominationValue}>{`${label} - ${denomination} `}</p>
          <p className={classes.ViewsValue}>{payload && payload[0].value} Views</p>
        </div>
      );
    }
  
    return null;
  });

class CatalogStats extends Component{
    constructor(props) {
        super(props);

        this.grdTOP10BOP = React.createRef();
        this.grdCATSTAT_CategoryP = React.createRef();
        this.grdCATSTAT_CodeV = React.createRef();
        this.grdCATSTAT_CountryP = React.createRef();
        this.grdCATSTAT_LineP = React.createRef();
        this.grdCATSTAT_SerieP = React.createRef();
        this.grdCATSTAT_RegionsP = React.createRef();
        this.grdCATSTAT_ProvincesP = React.createRef();
        this.grdCATSTAT_CitiesP = React.createRef();
        this.grdCATSTAT_Top10Catalogue = React.createRef();
        
        this.grdBINews = React.createRef();
        this.grdBIAdv = React.createRef();



        var d = new Date();
        d.setDate(d.getDate()-30);

        this.state={
            pieSector: undefined,
            width: 10,
            user: undefined,
            
            top10: undefined,
            top10Anagrafiche : undefined,
            
            Lines: undefined,
            Series: undefined,
            Categories: undefined,
            PerNazione: undefined,
            Regions: undefined,
            Province: undefined,
            Cities: undefined,
            CodiciCatalogo: undefined,

            //News e ADV
            News: undefined,
            Adv: undefined,

            //Accessi
            usrLogin: undefined,
            usrLogout: undefined,

            dateFrom: d,
            dateTo: new Date(),

            FIDLine: undefined,
            FIDSeries: undefined,
            FIDCountry: undefined,
            FIDIdentity: undefined,
            FIDCategory: undefined,
            FIDTipology: undefined,

            FIDRegion: undefined,
            FIDProvince: undefined,
            FIDCity: undefined,
            FIDDistribution: undefined,

            viewTypeTOP10: "chart",  
            viewTypeSeries: "torta",
            viewTypeNazione: "torta",
            viewTypeLines: "torta",
            viewTypeCategories: "torta",
            viewTypeAnagrafiche: "chart",
            viewTypeTOP10Catalogo: "chart",

            viewTypeRegions: "torta",
            viewTypeProvince: "torta",
            viewTypeCities: "torta",

            //News e ADV
            viewTypeNews: "chart",
            viewTypeAdv: "chart",

            //Accessi
            viewTypeLogin: "chart",
            
            actionsNav: undefined,
            direction: undefined,
        }

        this.XMessage = React.createRef();
    }

    static contextTypes = {
        router: PropTypes.object
    }

    componentDidMount = () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity } = this.state;
        if( dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE",this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE_ERROR",this.props.translations, " Range di date non valido")} </Typography>);
        } else {
            this.loadData();
            this.loadDataNewsEAdv();
            this.loadDataUserAccess();
        } 
    } 
    
    loadData = () => {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity } = this.state;

        if ( dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE",this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE_ERROR",this.props.translations, " Range di date non valido")} </Typography>);
          } 
        else {
            this.dataGetTOP10View();
            this.GetPercentageLines();
            this.GetPercentageSeries();
            this.GetPercentageCategories();
            this.GetTOP10Anagrafiche();
            this.GetPercentualeNazione();
            this.GetPercentageRegions();
            this.GetPercentageProvince();
            this.GetPercentageCities();
            this.GetTOP10ViewCodiciCatalogo();
            
            //this.grdTOP10BOP.current?.refresh(); -- sample
            this.grdTOP10BOP.current && this.grdTOP10BOP.current.refresh();
            this.grdCATSTAT_CategoryP.current && this.grdCATSTAT_CategoryP.current.refresh();
            this.grdCATSTAT_CodeV.current && this.grdCATSTAT_CodeV.current.refresh();
            this.grdCATSTAT_CountryP.current && this.grdCATSTAT_CountryP.current.refresh();
            this.grdCATSTAT_LineP.current && this.grdCATSTAT_LineP.current.refresh();
            this.grdCATSTAT_SerieP.current && this.grdCATSTAT_SerieP.current.refresh();
            this.grdCATSTAT_RegionsP.current && this.grdCATSTAT_RegionsP.current.refresh();
            this.grdCATSTAT_ProvincesP.current && this.grdCATSTAT_ProvincesP.current.refresh();
            this.grdCATSTAT_CitiesP.current && this.grdCATSTAT_CitiesP.current.refresh();
            this.grdCATSTAT_Top10Catalogue.current && this.grdCATSTAT_Top10Catalogue.current.refresh();
            
        }
    }

    // News E Adv
    loadDataNewsEAdv = () => {
        var { dateFrom, dateTo } = this.state;

        if ( dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE",this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE_ERROR",this.props.translations, " Range di date non valido")} </Typography>);
          } 
        else {


            this.GetTOP10News();
            this.GetTOP10Adv();




            this.grdBINews.current && this.grdBINews.current.refresh();
            this.grdBIAdv.current && this.grdBIAdv.current.refresh();
        }
    }

    loadDataUserAccess = () => {
        var { dateFrom, dateTo } = this.state;

        if ( dateFrom > dateTo) {
            this.XMessage.current.showMessageOk(getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE",this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5,"GC_MSGBOX_STATUSCATALOGUE_ERROR",this.props.translations, " Range di date non valido")} </Typography>);
          } 
        else {
            
            this.GetTOP10Logins();
        }
    }

    onUserDataChange = (key) => (data) => {
		this.setState(state => {
			if (data) {
				state[key] = data;
			}
			else {
				state[key] = undefined;
			}
			return state;
        });
    }

    handleSelectChange = (key) => (event, value) => {

        this.setState(state => {
            if(value && value.length > 0)
            {
                state[key] = value[0].ID;
            }
            else {
                state[key] = undefined;
            }
            
            return state;
        });
        
     }
    
    dataGetTOP10View = async () =>
    {
        
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution } = this.state;
        
        var url = `/api/BI/GetTOP10View?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url +=`&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }

        var result = await fetch(url).then(res => res.json());

        this.setState({ top10: result });
    }

    GetPercentageLines = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetPercentageLines?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        var result = await fetch(url).then(res => res.json());
        this.setState({ Lines: result });
    }

    GetPercentageSeries = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;
        
        var url = `/api/BI/GetPercentageSeries?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }

        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }

        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url +=`&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        var result = await fetch(url).then(res => res.json());
        this.setState({ Series: result });
    }

    GetPercentageCategories = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;
        
        var url = `/api/BI/GetPercentageCategories?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }

        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDTipology)
        {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        
        
        var result = await fetch(url).then(res => res.json());
        this.setState({ Categories: result });
    }

    GetTOP10Anagrafiche = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetTOP10Anagrafiche?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        
        var result = await fetch(url).then(res => res.json());
        this.setState({ top10Anagrafiche: result });
    }

    GetPercentualeNazione = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetPercentualeNazione?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
       
        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        var result = await fetch(url).then(res => res.json());
        this.setState({ PerNazione: result });
    }

    GetTOP10ViewCodiciCatalogo = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetTOP10CodiciCatalogo?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
       
        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        var result = await fetch(url).then(res => res.json());
        this.setState({ CodiciCatalogo: result });
    }
    
    getWidth = () => {
        return document.getElementById("mainContent").clientWidth * 0.9;
    }

    //GetPercentageRegions

    GetPercentageRegions = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetPercentageRegions?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
       
        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        var result = await fetch(url).then(res => res.json());
        this.setState({ Regions: result });
    }

    //GetPercentangeProvince
    GetPercentageProvince = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetPercentageProvince?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }

        if(FIDCountry){
            url += `&FIDCountry=${FIDCountry}`;
        }

        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
       
        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        var result = await fetch(url).then(res => res.json());
        this.setState({ Province: result });
    }

    // GetPercentangeCities
    GetPercentageCities = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetPercentageCities?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDLine){
            url += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            url += `&FIDSeries=${FIDSeries}`;
        }
       
        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDCategory){
            url += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            url += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        var result = await fetch(url).then(res => res.json());
        this.setState({ Cities: result });
    }
    
    getWidth = () => {
        return document.getElementById("mainContent").clientWidth * 0.9;
    }
    
    renderPanelParameters = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        //filters={[ {key: "FIDSeries", value: this.state.FIDSeries} ]
        return (
            
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} sm={6} md={3} ><XDateField label={getTranslation(7,"BI_TITLE_PARAMS_FROMDATA",this.props.translations, "Da data")} type="date" id="dateFrom" value={dateFrom} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12} sm={6} md={3}><XDateField label={getTranslation(7,"BI_TITLE_PARAMS_TODATA",this.props.translations, "A data")} type="date" id="dateTo" value={dateTo} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_COLECTION",this.props.translations, "Collezione")} id="FIDLine" urlData="/BOP/Line" value={this.state.FIDLine} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_SERIES",this.props.translations, "Serie")} id="FIDSeries" urlData="/BOP/Series" value={this.state.FIDSeries} onChange={this.handleSelectChange}  idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_COUNTRY",this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_IDENTITY",this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_CATEGORIES",this.props.translations, "Categoria")} id="FIDCategory" urlData="/BOP/Category" value={this.state.FIDCategory} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_TIPOLOGIES",this.props.translations, "Tipologia")} id="FIDTipology" urlData="/Base/GetIRTypologies" value={this.state.FIDTipology} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_REGIONS",this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMAS_PROVINCE",this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_CITIES",this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>
                <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_DISTRUBUSION",this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={6} className={classes.loadButton}><XButton onClick={this.loadData}>{getTranslation(7,"BI_BUTTON_LOADDATA",this.props.translations, "Carica Dati")} </XButton></Grid>
                <Grid item xs={12} sm={3}></Grid>
            </Grid>
        );
    }

    renderTop10 = () => {
        var { classes } = this.props;
        var { top10, viewTypeTOP10, dateFrom, dateTo,  FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution  } = this.state;
        
        const colors = scaleOrdinal(schemeCategory10).range();
        
        var width = this.getWidth();

        var content = undefined;

        if ( viewTypeTOP10 === "chart") {
            content = (   
                <Fragment>
                    <BarChart width={width} height={250} data={top10} style={{display:'inline-block'}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" />
                        <YAxis />
                        <Tooltip formatter={format} />
                        <Legend />
                        <Bar dataKey="Views" fill="#ffbf80" onClick={this.onClickTest} >
                        {
                            top10 && top10.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000}/>
                            ))
                        }
                        </Bar>
                    </BarChart>
                </Fragment>
            );
        }

        var actions = [];
        
		actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeTOP10","chart"), selected: viewTypeTOP10 === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeTOP10","grid"), selected: viewTypeTOP10 === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CodePerViews";
        var dataRoute = `/api/bi/GetTOP10ViewGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        return (
            <div id="panelTOP10View" className={classes.container}>
                <XGrid
                    innerRef={this.grdTOP10BOP}
                    containerID="panelTOP10View"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderTOP10CodiciCatalogo = () => {
        var { classes } = this.props;
        var { CodiciCatalogo,viewTypeTOP10Catalogo, dateFrom, dateTo,  FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution  } = this.state;
        
        const colors = scaleOrdinal(schemeCategory10).range();
        
        var width = this.getWidth();

        var content = undefined;

        if ( viewTypeTOP10Catalogo === "chart") {
            content = ( 
                <BarChart width={width} height={250} data={CodiciCatalogo} style={{display:'inline-block'}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="CatalogCode" />
                    <YAxis />
                    <Tooltip/>
                    <Legend />
                    <Bar dataKey="Views" fill="#D3D61E">
                        {
                            CodiciCatalogo && CodiciCatalogo.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                            ))
                        }
                        </Bar>
                </BarChart>
            );
        }

        var actions = [];
        
		actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeTOP10Catalogo","chart"), selected: viewTypeTOP10Catalogo === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeTOP10Catalogo","grid"), selected: viewTypeTOP10Catalogo === "grid" });


        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_Top10CodiciCatalogo";
        var dataRoute = `/api/bi/GetTOP10CodiciCatalogoGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        return (
            <div id="panelTOP10Codici" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_Top10Catalogue}
                    containerID="panelTOP10Codici"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    onClickUpdateTortaFIDLine = (value) => {
        var { FIDLine } =  value;
        
        this.setState(state => {
            if(state.FIDLine === FIDLine) {
                state.FIDLine = undefined;
            }
            else {
                state.FIDLine = FIDLine;
            }
            return state;
        },this.loadData);
    }

    onClickUpdateTortaFIDSeries= (value) => {
        var { FIDSeries } =  value;
        
        this.setState(state => {
            if(state.FIDSeries === FIDSeries) {
                state.FIDSeries = undefined;
            }
            else {
                state.FIDSeries = FIDSeries;
            }
            return state;
        },this.loadData);
     
    }


    onClickUpdateTortaFIDCategories = (value) => {
        var { FIDCategory } =  value;
        
        this.setState(state => {
            if(state.FIDCategory === FIDCategory) {
                state.FIDCategory = undefined;
            }
            else {
                state.FIDCategory = FIDCategory;
            }
            return state;
        },this.loadData);
    }

    onClickUpdateTortaFIDCountry = (value) => {
        var { FIDCountry } =  value;
        
        this.setState(state => {
            if(state.FIDCountry === FIDCountry) {
                state.FIDCountry = undefined;
            }
            else {
                state.FIDCountry = FIDCountry;
            }
            return state;
        },this.loadData);
     
    }


    onClickUpdateTortaFIDCountry = (value) => {
        var { FIDCountry } =  value;
        
        this.setState(state => {
            if(state.FIDCountry === FIDCountry) {
                state.FIDCountry = undefined;
            }
            else {
                state.FIDCountry = FIDCountry;
            }
            return state;
        },this.loadData);
    }

    onClickUpdateTortaFIDRegion = (value) => {
        var { FIDRegion } =  value;
        
        this.setState(state => {
            if(state.FIDRegion === FIDRegion) {
                state.FIDRegion = undefined;
            }
            else {
                state.FIDRegion = FIDRegion;
            }
            return state;
        },this.loadData);
    }

    
    onClickUpdateTortaFIDProvince= (value) => {
        var { FIDProvince } =  value;
        
        this.setState(state => {
            if(state.FIDProvince === FIDProvince) {
                state.FIDProvince = undefined;
            }
            else {
                state.FIDProvince = FIDProvince;
            }
            return state;
        },this.loadData);
    }

    onClickUpdateTortaFIDCity= (value) => {
        var { FIDCity } =  value;
        
        this.setState(state => {
            if(state.FIDCity === FIDCity) {
                state.FIDCity = undefined;
            }
            else {
                state.FIDCity = FIDCity;
            }
            return state;
        },this.loadData);
    }

    onClickUpdateChartFIDIdentity = (value) => {
        var { FIDIdentity } = this.state;

        this.setState(state => {
            if(state.FIDIdentity === value.ID) {
                state.FIDIdentity = undefined;
            }
            else 
            {
                state.FIDIdentity = value.ID;
            }
            return state;
        },this.loadData);
    }

    updateUserParameters = () => {
       
    }

    renderPercentageLines = () => {
        var { classes } = this.props;
        var { Lines, viewTypeLines, dateFrom, dateTo,  FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution } = this.state;
        var width = this.getWidth();
      
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;

        // if ( viewTypeLines === "chart") {
        //     content = ( 
        //         <BarChart width={width} height={250} data={Lines} style={{display:'inline-block'}}>
        //             <CartesianGrid strokeDasharray="3 3" />
        //             <XAxis dataKey="Line" />
        //             <YAxis />
        //             <Tooltip formatter={PercentageFormat} />
        //             <Legend />
        //             <Bar dataKey="Percentage" fill="#D3D61E">
        //                 {
        //                     Lines && Lines.map((entry, index) => (
        //                     <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        //                     ))
        //                 }
        //                 </Bar>
        //         </BarChart>
        //     );
        // }

        if( viewTypeLines == "torta" ){
            const renderActiveShape = (props) => {
            const RADIAN = Math.PI / 180;
            const {
                cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                fill, payload, percent, value, Line, Percetange
            } = props;
            
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const sx = cx + (outerRadius + 10) * cos;
            const sy = cy + (outerRadius + 10) * sin;
            const mx = cx + (outerRadius + 30) * cos;
            const my = cy + (outerRadius + 30) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;
            const textAnchor = cos >= 0 ? 'start' : 'end';
                return (
                    <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Line ||  getTranslation(7,"BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} ${value + "%"}`}</text>
                    
                    </g>
                );
                };

                const style = {
                    fontFamily: "Times New Roman",
                    fontSize: '8px',
                    display: 'inline-block',
                    left: '80%',
                };


                const renderLegend = (id, value,) => {
                    
                    return <Fragment><span>{  value.payload.Line || getTranslation(7,"BI_RESULT_NOLINE", this.props.translations, "Senza Linea")} </span> <span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage + "%"}</span>
                    
                    </Fragment>;

                }
                
                
                
                content = (
                <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                        <PieChart width={600} height={400} data={Lines}>
                        <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                            <Pie
                            activeIndex={this.state.activeIndexLines}
                            activeShape={renderActiveShape}
                            data={Lines}
                            cx={200}
                            cy={200}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="Percentage"
                            onMouseEnter={this.onPieEnter("Lines")}
                            onClick={this.onClickUpdateTortaFIDLine}
                            >
                                    {
                                      Lines && Lines.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                      
                                    }
                                </Pie>

                        </PieChart>
                        
                    </div>
                </div>
            );
        } 


        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeLines","chart"), selected: viewTypeLines === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeLines","torta"), selected: viewTypeLines === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeLines","grid"), selected: viewTypeLines === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_LinePercentage";
        var dataRoute = `/api/bi/GetPercentageLinesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        return (
            <div id="panelLines" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_LineP}
                    containerID="panelLines"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

    }

    onPieEnter =(key) => (data, index) => {
        this.setState(state => {
           state[`activeIndex${key}`] = index;
            return state;
        });
      };

    renderPercentageSeries = () => {
        var { classes } = this.props;
        var { Series, viewTypeSeries, dateFrom, dateTo,  FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution } = this.state;
        
        const COLORS = scaleOrdinal(schemeCategory10).range(); //scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        var content = undefined;
          
        
        if( viewTypeSeries == "torta" ){
                const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Series
                } = props;
                
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                
                return (
                    <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${Series || getTranslation(7,"BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} ${value + "%"}`}</text>
                    
                    </g>
                );
                };


                
                const style = {
                    fontFamily: "Times New Roman",
                    fontSize: '8px',
                    display: 'inline-block',
                    left: '80%',
                };

                const renderLegend = (id, value, c) => {

                    return <Fragment> <span>{  value.payload.Series || getTranslation(7,"BI_RESULT_NOSERIES", this.props.translations, "Senza Serie")} </span><span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage + "%"}</span></Fragment>;;
                }

                

                content = (
                    <div className={classes.tortaMain}>
                        <div className={classes.torta}>
                            
                            <PieChart width={600} height={390}>
                                <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                                <Pie
                                activeIndex={this.state.activeIndexSeries}
                                activeShape={renderActiveShape}
                                data={Series}
                                cx={200}
                                cy={200}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Series")}
                                onClick={this.onClickUpdateTortaFIDSeries}
                                >
                                    {
                                      Series && Series.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                    }
                                </Pie>
                            </PieChart>
                        </div>
                    </div>
            );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeSeries","chart"), selected: viewTypeSeries === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeSeries","torta"), selected: viewTypeSeries === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeSeries","grid"), selected: viewTypeSeries === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_SeriesPercetange";
        var dataRoute = `/api/bi/GetPercentageSeriesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

         if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
        

        return (
            <div id="panelSeries" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_SerieP}
                    containerID="panelSeries"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        )
    }

    renderPercentageCategories = () => {
        var { classes } = this.props;
        var { Categories, viewTypeCategories, dateFrom, dateTo,  FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution } = this.state;
        var width = this.getWidth();

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();

        var content = undefined;

        // if ( viewTypeCategories === "chart") {
        //     content = (
        //     <BarChart width={width} height={250} data={Categories} style={{display:'inline-block'}} onClick={this.onClickTest}>
        //         <CartesianGrid strokeDasharray="3 3" />
        //         <XAxis dataKey="Category" />
        //         <YAxis />
        //         <Tooltip formatter={PercentageFormat} />
        //         <Legend />
        //         <Bar dataKey="Percentage" fill="#33DDFF" onClick={this.onClickTest}>
        //         {
        //             Categories && Categories.map((entry, index) => (
        //             <Cell  onClick={this.onClickTest} key={`cell-${index}`} fill={colors[index % 20]} />
        //             ))
        //         }
        //         </Bar>
        //     </BarChart>
        //     );
        // }

        // Torta
        if( viewTypeCategories == "torta" ){
            const renderActiveShape = (props) => {
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, percent, value, Category
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                    
                    return (
                        <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Category || getTranslation(7,"BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} ${value + "%"}`}</text>
                       
                        </g>
                    );
                    };
    

                    const style = {
                        fontFamily: "Times New Roman",
                        fontSize: '8px',
                        display: 'inline-block',
                        left: '80%',
                    };
    
                    const renderLegend = (id, value) => {
                        
                        return <Fragment><span>{  value.payload.Category || getTranslation(7,"BI_RESULT_NOCATEGORIES", this.props.translations, "Senza Categoria")} </span> <span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage + "%"}</span></Fragment>;;
                    }

                    content = (
                        <div className={classes.tortaMain}>
                            <div className={classes.torta}>
                            
                            <PieChart width={600} height={390}>
                            <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                                <Pie
                                activeIndex={this.state.activeIndexCategories}
                                activeShape={renderActiveShape}
                                data={Categories}
                                cx={200}
                                cy={200}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="Percentage"
                                onMouseEnter={this.onPieEnter("Categories")}
                                onClick={this.onClickUpdateTortaFIDCategories}
                                >
                                  {
                                      Categories && Categories.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                  }
                                </Pie>
                            </PieChart>
                        </div>
                    </div>
                );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeCategories","chart"), selected: viewTypeCategories === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeCategories","torta"), selected: viewTypeCategories === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeCategories","grid"), selected: viewTypeCategories === "grid" });
        

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CategoryPercentage";
        var dataRoute = `/api/bi/GetPercentageCategoriesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        return (
            <div id="panelCategories" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CategoryP}
                    containerID="panelCategories"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    rendertop10Anagrafiche = () => {
        var { classes } = this.props;
        var { top10Anagrafiche, viewTypeAnagrafiche, dateFrom, dateTo,  FIDLine, FIDSeries, FIDIdentity, FIDCountry, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution } = this.state;
        var width = this.getWidth();
        const colors = scaleOrdinal(schemeCategory10).range();
        

        
        var content = undefined;

        if ( viewTypeAnagrafiche === "chart") {
            content = (
                <BarChart width={width} height={250} data={top10Anagrafiche} style={{display:'inline-block'}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Code" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey="Views" fill="#4d63b3" onClick={this.onClickUpdateChartFIDIdentity} >
                    {
                        top10Anagrafiche && top10Anagrafiche.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                        ))
                    }
                    </Bar>
                </BarChart>
            );
        }


        var actions = [];
        
		actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeAnagrafiche","chart"), selected: viewTypeAnagrafiche === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeAnagrafiche","grid"), selected: viewTypeAnagrafiche === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CoderPerViewsAnagrafiche";
        var dataRoute = `/api/bi/GetTOP10RegistriesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        return (
            <div id="panelAnagrafiche" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CodeV}
                    containerID="panelAnagrafiche"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
    }

    renderPercentageCountry = () => {
        var { classes } = this.props;
        var { PerNazione, viewTypeNazione , dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;
        var width = this.getWidth();

        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();

        var content = undefined;

        // if( viewTypeNazione === "chart"){
        //     content = (
        //         <BarChart width={width} height={250} data={PerNazione} style={{display:'inline-block'}}>
        //             <CartesianGrid strokeDasharray="3 3" />
        //             <XAxis dataKey="Country" />
        //             <YAxis />
        //             <Tooltip formatter={PercentageFormat} />
        //             <Legend />
        //             <Bar dataKey="Percentage" fill="#c74ea5" >
        //             {
        //                 PerNazione && PerNazione.map((entry, index) => (
        //                 <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        //                 ))
        //             }
        //             </Bar>
        //         </BarChart>
        //     );
        // } 

        // Torta
        if( viewTypeNazione == "torta" ){
            // return (
            //     <PieChart width={730} height={250}>
            //         <Pie data={PerNazione} dataKey="Percentage" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
            //         <Pie data={PerNazione} dataKey="Percentage" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
            //     </PieChart>
            // );

            const renderActiveShape = (props) => {
                var {FIDCountrySelector} = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, Country
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                    
                    return (
                        <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Country || getTranslation(7,"BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} ${value + "%"}`}</text>
                       
                        </g>
                    );
                    };
    
                    
                        const style = {
                            fontFamily: "Times New Roman",
                            fontSize: '8px',
                            display: 'inline-block',
                            left: '80%',
                        };

                        const renderLegend = (id, value,) => {
                            
                            return <Fragment> <span>{ value.payload.Country || getTranslation(7,"BI_RESULT_NONATION", this.props.translations, "Senza Nazione")} </span> <span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                        }
                    content = (
                        <div className={classes.tortaMain}>
                            <div className={classes.torta}>
                            
                                <PieChart width={600} height={390}>
                                <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                                    <Pie
                                    activeIndex={this.state.activeIndexPerNazione}
                                    activeShape={renderActiveShape}
                                    data={PerNazione}
                                    cx={200}
                                    cy={200}
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter={this.onPieEnter("PerNazione")}
                                    onClick={this.onClickUpdateTortaFIDCountry}
                                    fontSize="5px"
                                    >
                                    {
                                      PerNazione && PerNazione.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                    }
                                </Pie>
                                </PieChart>
                            </div>
                        </div>
                );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeNazione","torta"), selected: viewTypeNazione === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeNazione","grid"), selected: viewTypeNazione === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_PERCCOUNTRY";
        var dataRoute = `/api/bi/GetCountryPercentageGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        // Dati
        return (
            <div id="panelNations" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CountryP}
                    containerID="panelNations"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    renderPercentageRegions = () => {
        var { classes } = this.props;
        var { Regions, viewTypeRegions , dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( viewTypeRegions == "torta" ){

            const renderActiveShape = (props) => {
                var {FIDCountrySelector} = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, Region, Country
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                    
                    return (
                        <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Region || getTranslation(7,"BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} ${value + "%"}`}</text>
                       
                        </g>
                    );
                    };
                    
                    const style = {
                        fontFamily: "Times New Roman",
                        fontSize: '8px',
                        display: 'inline-block',
                        left: '80%',
                    };

                    const renderLegend = (id, value,) => {
                        
                        return <Fragment> <span>{ value.payload.Region || getTranslation(7,"BI_RESULT_NOREGION", this.props.translations, "Senza Regione")} </span> <span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                    }

                    content = (
                        <div className={classes.tortaMain}>
                            <div className={classes.torta}>
                            
                                <PieChart width={600} height={390}>
                                <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                                    <Pie
                                    activeIndex={this.state.activeIndexRegions}
                                    activeShape={renderActiveShape}
                                    data={Regions}
                                    cx={200}
                                    cy={200}
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter={this.onPieEnter("Regions")}
                                    onClick={this.onClickUpdateTortaFIDRegion}
                                    fontSize="5px"
                                    >
                                    {
                                      Regions && Regions.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                    }
                                </Pie>
                                </PieChart>
                            </div>
                        </div>
                );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeRegions","torta"), selected: viewTypeRegions === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeRegions","grid"), selected: viewTypeRegions === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_RegionsPercentange";
        var dataRoute = `/api/bi/GetCountryPercentageGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }

        // Dati
        return (
            <div id="panelRegions" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_RegionsP}
                    containerID="panelRegions"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    renderPercentageProvince = () => {
        var { classes } = this.props;
        var { Province, viewTypeProvince , dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( viewTypeProvince == "torta" ){

            const renderActiveShape = (props) => {
                var {FIDCountrySelector} = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, Province
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                    
                    return (
                        <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${Province || getTranslation(7,"BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} ${value + "%"}`}</text>
                       
                        </g>
                    );
                    };
    

                    
                        const style = {
                            fontFamily: "Times New Roman",
                            fontSize: '8px',
                            display: 'inline-block',
                            left: '80%',
                        };

                        const renderLegend = (id, value,) => {
                            
                            return <Fragment> <span>{ value.payload.Province || getTranslation(7,"BI_RESULT_NOPROVINCE", this.props.translations, "Senza Provincia")} </span> <span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                        }


                    content = (
                        <div className={classes.tortaMain}>
                            <div className={classes.torta}>
                                <PieChart width={600} height={390}>
                                <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                                    <Pie
                                    activeIndex={this.state.activeIndexProvince}
                                    activeShape={renderActiveShape}
                                    data={Province}
                                    cx={200}
                                    cy={200}
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter={this.onPieEnter("Province")}
                                    onClick={this.onClickUpdateTortaFIDProvince}
                                    fontSize="5px"
                                    >
                                    {
                                      Province && Province.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                    }
                                </Pie>
                                </PieChart>
                            </div>
                        </div>
                );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeProvince","torta"), selected: viewTypeProvince === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeProvince","grid"), selected: viewTypeProvince === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_ProvincesPercentange";
        var dataRoute = `/api/bi/GetPercentageProvinceGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }

        // Dati
        return (
            <div id="panelRegions" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_RegionsP}
                    containerID="panelRegions"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    renderPercentageCities = () => {
        var { classes } = this.props;
        var { Cities, viewTypeCities , dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( viewTypeCities == "torta" ){

            const renderActiveShape = (props) => {
                var {FIDCountrySelector} = this.state;
                const RADIAN = Math.PI / 180;
                const {
                    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
                    fill, payload, value, percent, City
                } = props;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';
                    
                    return (
                        <g>
                        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                        <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                        />
                        <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${City || getTranslation(7,"BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} ${value + "%"}`}</text>
                       
                        </g>
                    );
                    };
    
                    const style = {
                        fontFamily: "Times New Roman",
                        fontSize: '8px',
                        display: 'inline-block',
                        left: '80%',
                    };

                    const renderLegend = (id, value,) => {
                        
                        return <Fragment> <span>{ value.payload.City || getTranslation(7,"BI_RESULT_NOCITY", this.props.translations, "Senza Citta")} </span> <span style={{fontFamily: "Arial", fontSize:'10px'}}>{ value.payload.Percentage && value.payload.Percentage + "%"}</span></Fragment>;
                    }

                    content = (
                        <div className={classes.tortaMain}>
                            <div className={classes.torta}>
                            
                                <PieChart width={600} height={390}>
                                <Legend iconSize={10} iconType="diamond" width={200} height={180} layout="vertical" verticalAlign="middle" wrapperStyle={style} formatter={renderLegend}/>
                                    <Pie
                                    activeIndex={this.state.activeIndexCities}
                                    activeShape={renderActiveShape}
                                    data={Cities}
                                    cx={200}
                                    cy={200}
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="Percentage"
                                    onMouseEnter={this.onPieEnter("Cities")}
                                    onClick={this.onClickUpdateTortaFIDCity}
                                    fontSize="5px"
                                    >
                                    {
                                      Cities && Cities.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                    }
                                </Pie>
                                </PieChart>
                            </div>
                        </div>
                );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_PIE",this.props.translations, "Torta"), icon: <ChartPie />, action: this.SetView("viewTypeCities","torta"), selected: viewTypeCities === "torta" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeCities","grid"), selected: viewTypeCities === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdCATSTAT_CitiesPercentage";
        var dataRoute = `/api/bi/GetPercentageCitiesGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        if(FIDLine){
            dataRoute += `&FIDLine=${FIDLine}`;
        }
        if(FIDSeries){
            dataRoute += `&FIDSeries=${FIDSeries}`;
        }
        if(FIDCountry){
            dataRoute += `&FIDCountry=${FIDCountry}`;
        }
        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }
        
        if(FIDCategory) {
            dataRoute += `&FIDCategory=${FIDCategory}`;
        }

        if(FIDTipology) {
            dataRoute += `&FIDTipology=${FIDTipology}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }

        // Dati
        return (
            <div id="panelCities" className={classes.container}>
                <XGrid
                    innerRef={this.grdCATSTAT_CitiesP}
                    containerID="panelCities"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    SetView = (key, value) => () => {
        this.setState(state => {
            state[key] = value;
            return state;
        });
    }


    // News e ADV
    renderPanelNewseAdv = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        //filters={[ {key: "FIDSeries", value: this.state.FIDSeries} ]
        return (
            
            <Grid container xs={12} spacing={3}>
                        <Grid item xs={12} sm={12} md={6} ><XDateField label={getTranslation(7,"BI_TITLE_PARAMS_FROMDATA",this.props.translations, "Da data")} type="date" id="dateFrom" value={dateFrom} onChange={this.onUserDataChange} /></Grid>
                        <Grid item xs={12} sm={12} md={6}><XDateField label={getTranslation(7,"BI_TITLE_PARAMS_TODATA",this.props.translations, "A data")} type="date" id="dateTo" value={dateTo} onChange={this.onUserDataChange} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_COUNTRY",this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_IDENTITY",this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_REGIONS",this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMAS_PROVINCE",this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_CITIES",this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_DISTRUBUSION",this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ></Grid>
                    <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={6} className={classes.loadButton}><XButton onClick={this.loadDataNewsEAdv}>{getTranslation(7,"BI_BUTTON_LOADDATA",this.props.translations, "Carica Dati")} </XButton></Grid>
                    <Grid item xs={12} sm={3}></Grid>
            </Grid>
        );
    }

    // Accessi 
    renderPanelAccess = () => {
        var { classes } = this.props;
        var { dateFrom, dateTo } = this.state;
        //filters={[ {key: "FIDSeries", value: this.state.FIDSeries} ]
        return (
            
            <Grid container xs={12} spacing={3}>
                        <Grid item xs={12} sm={12} md={6} ><XDateField label={getTranslation(7,"BI_TITLE_PARAMS_FROMDATA",this.props.translations, "Da data")} type="date" id="dateFrom" value={dateFrom} onChange={this.onUserDataChange} /></Grid>
                        <Grid item xs={12} sm={12} md={6}><XDateField label={getTranslation(7,"BI_TITLE_PARAMS_TODATA",this.props.translations, "A data")} type="date" id="dateTo" value={dateTo} onChange={this.onUserDataChange} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_COUNTRY",this.props.translations, "Nazione")} id="FIDCountry" urlData="/Base/GetIRCountries" value={this.state.FIDCountry} onChange={this.handleSelectChange} idKey="ID" valueKey="Country" /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_IDENTITY",this.props.translations, "Anagrafiche")} id="FIDIdentity" urlData="/IR/GetIRSelect" value={this.state.FIDIdentity} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" subtitleKey="Address" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_REGIONS",this.props.translations, "Regioni")} id="FIDRegion" urlData="/Base/GetIRRegions" value={this.state.FIDRegion} onChange={this.handleSelectChange} idKey="ID" valueKey="Region" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMAS_PROVINCE",this.props.translations, "Provincia")} id="FIDProvince" urlData="/Base/GetIRProvinces" value={this.state.FIDProvince} onChange={this.handleSelectChange} idKey="ID" valueKey="Province" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_CITIES",this.props.translations, "Citta")} id="FIDCity" urlData="/Base/GetIRCities" value={this.state.FIDCity} onChange={this.handleSelectChange} idKey="ID" valueKey="City" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ><XSelect label={getTranslation(7,"BI_TITLE_PARAMS_DISTRUBUSION",this.props.translations, "Distribuzioni")} id="FIDDistribution" urlData="/IR/DistributionList" value={this.state.FIDDistribution} onChange={this.handleSelectChange} idKey="ID" valueKey="Denomination" /></Grid>
                        <Grid item xs={12} sm={6} md={3} ></Grid>
                    <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={6} className={classes.loadButton}><XButton onClick={this.loadDataUserAccess}>{getTranslation(7,"BI_BUTTON_LOADDATA",this.props.translations, "Carica Dati")} </XButton></Grid>
                    <Grid item xs={12} sm={3}></Grid>
            </Grid>
        );
    }



    renderTOP10News = () => {
        var { classes } = this.props;
        var { News, viewTypeNews , dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        console.log(News)
        // Torta
        if( viewTypeNews == "chart" ){
            content = (   
                <Fragment>
                    <BarChart width={width} height={250} data={News} style={{display:'inline-block'}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Title"/>
                        <YAxis />
                        <Tooltip formatter={format} />
                        <Legend />
                        <Bar dataKey="Views" fill="#ffbf80" >
                        {
                            News && News.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000}/>
                            ))
                        }
                        </Bar>
                    </BarChart>
                </Fragment>
            );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeNews","chart"), selected: viewTypeNews === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeNews","grid"), selected: viewTypeNews === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBINews";
        var dataRoute = `/api/bi/GetTOP10NewsGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelNews" className={classes.container}>
                <XGrid
                    innerRef={this.grdBINews}
                    containerID="panelNews"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    renderTOP10Adv = () => {
        var { classes } = this.props;
        var { Adv, viewTypeAdv , dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( viewTypeAdv == "chart" ){
            content = (   
                <Fragment>
                    <BarChart width={width} height={250} data={Adv} style={{display:'inline-block'}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Title" />
                        <YAxis />
                        <Tooltip formatter={format} />
                        <Legend />
                        <Bar dataKey="Views" fill="#ffbf80" onClick={this.onClickTest} >
                        {
                            Adv && Adv.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000}/>
                            ))
                        }
                        </Bar>
                    </BarChart>
                </Fragment>
            );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeAdv","chart"), selected: viewTypeAdv === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeAdv","grid"), selected: viewTypeAdv === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIAdv";
        var dataRoute = `/api/bi/GetTOP10AdvGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelAdv" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIAdv}
                    containerID="panelAdv"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    renderTOP10usrLogins = () => {
        var { classes } = this.props;
        var { usrLogin, viewTypeLogin , dateFrom, dateTo, FIDCountry, FIDIdentity, FIDRegion, FIDProvince, FIDDistribution, FIDCity} = this.state;
        var width = this.getWidth();
        const COLORS = scaleOrdinal(schemeCategory10).range();
        const colors = scaleOrdinal(schemeCategory10).range();
        var content = undefined;
        // Torta
        if( viewTypeLogin == "chart" ){
            content = (   
                <Fragment>
                    <BarChart width={width} height={250} data={usrLogin} style={{display:'inline-block'}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Denomination" />
                        <YAxis />
                        <Tooltip formatter={format} />
                        <Legend />
                        <Bar dataKey="Login" fill="#ffbf80" onClick={this.onClickTest} >
                        {
                            usrLogin && usrLogin.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} animationDuration={15000}/>
                            ))
                        }
                        </Bar>
                    </BarChart>
                </Fragment>
            );
        } 

        var actions = [];
        
		//actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTypeNazione","chart"), selected: viewTypeNazione === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_GRAPHIC",this.props.translations, "Grafico"), icon: <ChartPie />, action: this.SetView("viewTypeLogin","chart"), selected: viewTypeLogin === "chart" });
        actions.push({ name: getTranslation(7,"BI_BUTTON_DATAGRID",this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTypeLogin","grid"), selected: viewTypeLogin === "grid" });

        var label = <XNavigation actions={actions} />;

        var gridName = "grdBILogin";
        var dataRoute = `/api/bi/GetTop10LoginsGrid?grid=${gridName}&dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;
        
        if(FIDProvince) {
            dataRoute +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            dataRoute += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDRegion) {
            dataRoute +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            dataRoute +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        if(FIDCity) {
            dataRoute +=`&FIDCity=${FIDCity}`;
        }

        // Dati
        return (
            <div id="panelLogin" className={classes.container}>
                <XGrid
                    innerRef={this.grdBILogin}
                    containerID="panelLogin"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                    />
            </div>
        );
    }

    renderTabCatalogue(){
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { top10, Lines, Series, Categories, top10Anagrafiche, PerNazione } = this.state;
        var { classes } = this.props;
        var country = "";
        

        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_TITLE_PARAMS",this.props.translations, "Parametri"),
            content: this.renderPanelParameters()
        });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_TITLE_TOP10Catalogo",this.props.translations, "Top 10 Codici Catalogo"),
            content: this.renderTOP10CodiciCatalogo()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7,"BI_TITLE_TOP10PROD",this.props.translations, "Top 10 Prodotti"),
            content: this.renderTop10()
        });

        panels.push({
            icon: 'line_weight',
            label: getTranslation(7,"BI_TITLE_PERCENTLINE",this.props.translations, "Percentuale per Linea"),
            content: this.renderPercentageLines()
        });

        panels.push({
            icon: 'code',
            label: getTranslation(7,"BI_TITLE_PERCENTSERIES",this.props.translations, "Percentuale per Serie"),
            content: this.renderPercentageSeries()
        });

        panels.push({
            icon: 'category_icon',
            label: getTranslation(7,"BI_TITLE_PERCENTCATEGORIES",this.props.translations, "Percentuale per Categorie"),
            content: this.renderPercentageCategories()
        });

        panels.push({
            icon: 'storage_icon',
            label: getTranslation(7,"BI_TITLE_IDENTITY",this.props.translations, "TOP 10 Anagrafiche"),
            content: this.rendertop10Anagrafiche()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7,"BI_TITLE_PERCENTCOUNTRY",this.props.translations, "Percentuale per Nazione"),
            content: this.renderPercentageCountry()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7,"BI_TITLE_PERCENTAGEREGIONS",this.props.translations, "Percentuale Regioni"),
            content: this.renderPercentageRegions()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7,"BI_TITLE_PERCENTAGEPROVINCE",this.props.translations, "Percentuale Provincia"),
            content: this.renderPercentageProvince()
        });

        panels.push({
            icon: 'flag_icon',
            label: getTranslation(7,"BI_TITLE_PERCENTAGECITY",this.props.translations, "Percentuale Citta"),
            content: this.renderPercentageCities()
        });
        return <XExpansionPanel panels={panels} />;
    }


    GetTOP10News = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetTOP10News?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        
        var result = await fetch(url).then(res => res.json());
        this.setState({ News: result });
    }

    GetTOP10Adv = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetTop10Adv?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        
        var result = await fetch(url).then(res => res.json());
        this.setState({ Adv: result });
    }

    GetTOP10Logins = async () =>
    {
        var { dateFrom, dateTo, FIDLine, FIDSeries, FIDCountry, FIDIdentity, FIDCategory, FIDTipology, FIDRegion, FIDProvince, FIDCity ,FIDDistribution} = this.state;

        var url = `/api/BI/GetTop10Logins?dataFrom=${dateFrom.toISOString()}&datato=${dateTo.toISOString()}`;

        if(FIDProvince) {
            url +=`&FIDProvince=${FIDProvince}`;
        }

        if(FIDIdentity){
            url += `&FIDIdentity=${FIDIdentity}`;
        }

        if(FIDRegion) {
            url +=`&FIDRegion=${FIDRegion}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
    
        if(FIDCity) {
            url +=`&FIDCity=${FIDCity}`;
        }

        if(FIDDistribution) {
            url +=`&FIDDistribution=${FIDDistribution}`;
        }
        
        var result = await fetch(url).then(res => res.json());
        this.setState({ usrLogin: result });
    }

    renderTabCrm(){
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { News, Adv } = this.state;
        var { classes } = this.props;
        var country = "";

        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_TITLE_PARAMS",this.props.translations, "Parametri"),
            content: this.renderPanelNewseAdv()
        });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_TITLE_NEWS",this.props.translations, "Top 10 News"),
            content: this.renderTOP10News()
        });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_TITLE_ADV",this.props.translations, "Top 10 Adv"),
            content: this.renderTOP10Adv()
        });

        return <XExpansionPanel panels={panels} />;
    }
    renderTabAccess(){
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { usrLogin } = this.state;
        var { classes } = this.props;
        var country = "";

        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_TITLE_PARAMS",this.props.translations, "Parametri"),
            content: this.renderPanelAccess()
        });

        panels.push({
            icon: 'data_usage',
            label: getTranslation(7,"BI_ACCESS",this.props.translations, "Top 10 Accessi"),
            content: this.renderTOP10usrLogins()
        });
        return <XExpansionPanel panels={panels} />;
    }
    
    

    render() {

        var locTabs = [
            {
                tabName: getTranslation(7,"BI_CATALOGUE",this.props.translations, "Catalogo"),
                tabContent: this.renderTabCatalogue(),
            },
            {
                tabName: getTranslation(7,"BI_NEWS_ADV",this.props.translations, "News e Adv"),
                tabContent:  this.renderTabCrm(),
            },
            {
                tabName: getTranslation(7,"BI_ACCESS",this.props.translations, "Accessi"),
                tabContent:  this.renderTabAccess()
            },
        ];


        return <Fragment>
            <Tabs
                headerColor="primary"
                plainTabs={true}
                tabs={locTabs}
            />
            <XMessageBox innerRef={this.XMessage} />
        </Fragment>;
    }
}


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(authCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(CatalogStats);