// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = theme => ({
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    justifyContentRight: {
        justifyContent: "flex-end !important",
        background: "linear-gradient(60deg, #383b3d, #383b3d)",
        padding: "0 15px",
        margin: "10px 0 0",
        boxShadow: "0 -4px 20px 0px rgba(0, 0, 0, 0.14), 0 -7px 10px -5px rgba(50, 50, 50, 0.4)",
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: "#FFFFFF"
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    body: {
        maxHeight: "74vh",
        overflowY: "auto",
    },
    logoContainer: {
        width: '100%',
        margin: 'auto',
    },
    logoContainerBig: {
        width: '33.33%',
        margin: 'auto',
    }
});

export default loginPageStyle;
