import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import CatalogueCard from 'components/CatalogueCard';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import XNavigation from 'components/XNavigation';
//import { Tabs } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';
import TabContainer from 'components/CustomTabs/TabContainer';
import IdentitySells from 'views/IR/IdentitySells.jsx';
import CrossSell from 'views/IR/CrossSell.jsx';
import CatalogueIcon from 'components/CatalogueIcon';
import IdentityVisionAccounts from 'views/IR/IdentityVisionAccounts';
import IdentityNegotations from 'views/IR/IdentityNegotations';
import IdentityAssignments from 'views/IR/IdentityAssignments';
import XPDFViewer from 'components/XPDFViewer';
import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
        '&>header': {
            top: 64,
            zIndex: 1099,
        }
    },
    box: {
    },
    label: {
        display: "block",
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        textAlign: "center",
        padding: "1px",
        fontWeight: "bold",
    },
    yearBox: {
        display: "inline-block",
        width: "calc(25% - 6px)",
        padding: "45px 0",
        margin: "3px",
        textAlign: "center",
        backgroundColor: "#f5f5f5",
        fontWeight: "bold",
        cursor: "pointer",
    },
    yearBoxActive: {
        display: "inline-block",
        width: "calc(25% - 6px)",
        padding: "45px 0",
        margin: "3px",
        textAlign: "center",
        backgroundColor: "#353535",
        color: "#fff",
        fontWeight: "bold",
        cursor: "pointer",
    },
    sellItem: {
        display: "inline-block",
        width: "calc(33.33% - 6px)",
        margin: "3px",
        textAlign: "center",
    },
    sellLabel: {
        display: "block",
        width: "100%",
        fontSize: "10px",
    },
    sellValue: {
        display: "block",
        width: "100%",
        fontSize: "12px",
        fontWeight: "bold",
    },
    tabsPositionSticky: {
        top: 64,
        zIndex: 1099,
    }
});
const months = [
    '',
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
]
String.prototype.format = String.prototype.format ||
function () {
    "use strict";
    if(!this) {
      return undefined;
    }
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
  }
  state = {
    //year: (new Date()).getFullYear(),
    year: undefined,
    sells: [],
    values: {
      Watches: {},
      Jewels: {},
      Silvers: {}
    },
    bars: [],
    viewType: "",
    accountBalanceVisible: false,
  };
  componentDidMount() {
    this.reloadSells();
    this.checkAccountBalanceVisibilty();
  }
  manageTabChange = (value) => {
    if (value === 1) {
      this.showCrossSelling();
    } else {
      this.setState({
        viewType: ""
      })
    }
  }
  showCrossSelling = () => {
    this.setState({
      viewType: "cross_selling"
    })
  }
  showAltSelling = () => {
    this.setState({
      viewType: "alt_selling"
    })
  }
  selectYear = (year) => {
    this.setState(state => {
      if(state.year === year){
        state.year = undefined;
      }
      else {
        state.year = year;
      }
      return state;
    },this.reloadSells);
    // this.setState({ year }, () => {
    //   this.reloadSells();
    // });
  }
  checkAccountBalanceVisibilty = () => {
    var { id } = this.props;

    fetch('/IR/CheckIRCustomerAccountBalance/' + id).then(res => res.json()).then(data => this.setState(state => {
      state.accountBalanceVisible = data.res;
      return state;
    }));
  }
  reloadSells = () => {
    var { id } = this.props;
    var { year } = this.state;
    fetch('/DOC/GetHistoricalSellsTotals/' + id + '?year=' + year + '&FIDGroup=' + this.props.params.WatchGroupId).then(res => res.json()).then(data => this.setState(state => {
      state.values.Watches = data;
      return state;
    }));
    fetch('/DOC/GetHistoricalSellsTotals/' + id + '?year=' + year + '&FIDGroup=' + this.props.params.JewellryGroupId).then(res => res.json()).then(data => this.setState(state => {
      state.values.Jewels = data;
      return state;
    }));
    fetch('/DOC/GetHistoricalSellsTotals/' + id + '?year=' + year + '&FIDGroup=' + this.props.params.SilversGroupId).then(res => res.json()).then(data => this.setState(state => {
      state.values.Silvers = data;
      return state;
    }));
    fetch('/DOC/GetHistoricalSellsByMonth/' + id + '?year=' + year).then(res => res.json()).then(data => this.setState(state => {
      state.bars = data;
      return state;
    }));
    //fetch('/DOC/GetAlternativeSells/' + id).then(res => res.json()).then(data => {
    //    console.log(data);
    //});
  }
  renderTabStorico = () => {
    var { year } = this.state;
    return (
      <TabContainer>
        <IdentitySells id={this.props.id} width={this.props.width} year={year} translations={this.props.translations} />
      </TabContainer>
    );
  }
  renderTabNegotation = () => {
    var { year } = this.state;
    return (
      <TabContainer>
        <IdentityNegotations id={this.props.id} width={this.props.width} year={year} translations={this.props.translations} />
      </TabContainer>
    );
  }
  renderTabCrossSelling = () => {
    var { year } = this.state;
    var direction = "right";
    if (["xs"].includes(this.props.width)) {
      direction = "down";
    }
    var { viewType } = this.state;

    var navActions = [
      {
        name: getTranslation(4,"IR_CS_COHERENT",this.props.translations, 'Coerente'),
        icon: <CatalogueIcon tag="c" />,
        action: this.showCrossSelling,
        selected: viewType === "cross_selling"
      },
      {
        name: getTranslation(4,"IR_CS_ALTERNATIVE",this.props.translations, 'Alternativo'),
        icon: <CatalogueIcon tag="a" />,
        action: this.showAltSelling,
        selected: viewType === "alt_selling"
      }
    ];
    var label = <span><XNavigation menuLabel={getTranslation(5,"GC_TYPE",this.props.translations, "Tipo")} actions={navActions} direction={direction} showLabel /></span>;
    return (<Fragment>
      <div>{label}</div>
      <TabContainer>
        {viewType === "cross_selling" ? <CrossSell key={viewType} type='Coherent' id={this.props.id} width={this.props.width} year={year} translations={this.props.translations}/> : <CrossSell key={viewType} type='Alternative' id={this.props.id} width={this.props.width}  year={year} translations={this.props.translations} />}
      </TabContainer>
      </Fragment>)
  }
  renderTabVisite = () => {
    return <div></div>;
  }
  renderTabContoVisione = () => {
    var { year } = this.state;
    return (
      <TabContainer>
        <IdentityVisionAccounts id={this.props.id} width={this.props.width} year={year} translations={this.props.translations} />
      </TabContainer>
    );
  }
  renderTabEstrattoConto = () => {
    var { id } = this.props;
    var url = '/IR/GetIRCustomerAccountBalance/' + id;
    return (
      <TabContainer>
        <XPDFViewer key="print_balance" url={url} width="100%" height={window.innerHeight - 90 - 60} />
      </TabContainer>
    );
  }
  renderTabNotices = () => {
        var { id } = this.props;
		
        return (
            <TabContainer /*id="tabIRAssignments" padding={0} style={{ height: "calc(100vh - 124px)" }}*/>
                <IdentityAssignments id={id} year={this.state.year} assignType="NOTICE" toolbarLabel={getTranslation(4,"IR_NOTICES",this.props.translations, "Avvisi")} />
            </TabContainer>
        );
  }
  renderTabQuotes = () => {
        var { id } = this.props;
		
        return (
            <TabContainer /*id="tabIRAssignments" padding={0} style={{ height: "calc(100vh - 124px)" }}*/>
                <IdentityAssignments id={id} year={this.state.year} assignType="QUOTE" toolbarLabel={getTranslation(4,"IR_QUOTES",this.props.translations, "Preventivi")} />
            </TabContainer>
        );
  }
  renderTabBookings = () => {
        var { id } = this.props;
		
        return (
            <TabContainer /*id="tabIRAssignments" padding={0} style={{ height: "calc(100vh - 124px)" }}*/>
                <IdentityAssignments id={id} year={this.state.year} assignType="ORDER" toolbarLabel={getTranslation(4,"IR_BOOKINGS",this.props.translations, "Prenotazioni")} />
            </TabContainer>
        );
  }
  render() {
    var { classes, id } = this.props;
    var { year, values, bars, accountBalanceVisible } = this.state;
    var year3 = (new Date()).getFullYear();
    var year2 = year3 - 1;
    var year1 = year3 - 2;
    var year0 = year3 - 3;
    var tabs = [
      {
        tabName: getTranslation(4,"IR_SALESHISTORY",this.props.translations, "Storico"),
        tabContent: this.renderTabStorico()
      },
      {
        tabName: getTranslation(4,"IR_CROSSSELLING",this.props.translations, "Cross-selling"),
        tabContent: this.renderTabCrossSelling()
      },
      {
        tabName: getTranslation(4,"IR_VISACC",this.props.translations, "Conti visione"),
        tabContent: this.renderTabContoVisione()
      },
      //{
      //    tabName: "Presentazioni",
      //    tabContent: this.renderTabVisite()
      //},
      {
        tabName: getTranslation(4,"IR_NEGOTATION",this.props.translations, "Trattative"),
        tabContent: this.renderTabNegotation()
      },
	  {
        tabName: getTranslation(4,"IR_NOTICES",this.props.translations, "Avvisi"),
        tabContent: this.renderTabNotices(),
      },
	  {
		tabName: getTranslation(4,"IR_QUOTES",this.props.translations, "Preventivi"),
        tabContent: this.renderTabQuotes(), 
	  },
	  {
		tabName: getTranslation(4,"IR_BOOKINGS",this.props.translations, "Prenotazioni"),
        tabContent: this.renderTabBookings(),  
	  },
    ];

    if (accountBalanceVisible) {
      tabs.push({
        tabName: getTranslation(4,"IR_ACCBALANCE",this.props.translations, "Estratto conto"),
        tabContent: this.renderTabEstrattoConto()
      });
    }

    var margins = [(values.Watches.Margin || 0), (values.Jewels.Margin || 0), (values.Silvers.Margin || 0)].filter(a => a !== 0);
    var marginAvg = 0;
    if (margins.length > 0) {
      marginAvg = margins.reduce((a, b) => a + b) / margins.length;
    }

    var salesGroupWatches = "Vendita Orologi";
    var salesGroupJewels = "Vendita Gioielli";
    var salesGroupSilvers = "Vendita Argenti";
    var translSalesGroup = getTranslation(4,"IR_SALE$GROUP",this.props.translations);
    if(translSalesGroup){
      salesGroupWatches = translSalesGroup.format(getTranslation(-1,"T_WATCHES",this.props.translations));
      salesGroupJewels = translSalesGroup.format(getTranslation(-1,"T_JEWELS",this.props.translations));
      salesGroupSilvers = translSalesGroup.format(getTranslation(-1,"T_SILVERS",this.props.translations));
    }

    var totPezzi = 0;
    if (values.Watches.TotItems) totPezzi++;
    if (values.Jewels.TotItems) totPezzi++;
    if (values.Silvers.TotItems) totPezzi++;
    console.log(bars);

    var avgDiscountTotal = 0;
    
    if (totPezzi > 0)
      avgDiscountTotal = (((values.Watches.AvgDiscount || 0) + (values.Jewels.AvgDiscount || 0) + (values.Silvers.AvgDiscount || 0)) / totPezzi);

    return (
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} sm={3} className={classes.box}>
            <span className={classes.label}>{getTranslation(5,"GC_YEAR",this.props.translations, "Anno")}</span>
            <div onClick={() => { this.selectYear(year0); }} className={year === year0 ? classes.yearBoxActive : classes.yearBox}>{year0}</div>
            <div onClick={() => { this.selectYear(year1); }} className={year === year1 ? classes.yearBoxActive : classes.yearBox}>{year1}</div>
            <div onClick={() => { this.selectYear(year2); }} className={year === year2 ? classes.yearBoxActive : classes.yearBox}>{year2}</div>
            <div onClick={() => { this.selectYear(year3); }} className={year === year3 ? classes.yearBoxActive : classes.yearBox}>{year3}</div>
          </Grid>
          <Grid item xs={12} sm={9} className={classes.box}>
            <span className={classes.label} ref={this.panel}>{getTranslation(4,"IR_AMOUNTYEARGROUP",this.props.translations, "Importo per Mese e Gruppo")}</span>
            <BarChart
              width={900}
              height={120}
              data={bars}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name">
                {/* <Label value="name" formatter={(value) => { return getTranslation(5,`GC_MONTH_${(bars[0][value])}`, this.props.translations) || months[bars[0][value]] }} /> */}
              </XAxis>
              <YAxis />
              <Tooltip formatter={(value, name, props) => { return value.round(2) + " €"; }} labelFormatter={value => getTranslation(5,`GC_MONTH_${(value)}`, this.props.translations, months[value]) } />
              <Bar name={getTranslation(-1,"T_WATCHES",this.props.translations, "Orologi")} dataKey="Watches" fill="#8884d8" />
              <Bar name={getTranslation(-1,"T_JEWELS",this.props.translations, "Gioielli")} dataKey="Jewels" fill="#FFBB28" />
              <Bar name={getTranslation(-1,"T_SILVERS",this.props.translations, "Argenti")} dataKey="Silvers" fill="#00C49F" />
            </BarChart>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} sm={3} className={classes.box}>
            <span className={classes.label}>{getTranslation(4,"IR_TOTSALES",this.props.translations, "Totale vendite")}</span>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTSALE",this.props.translations, "Tot. vendita")}</span>
              <span className={classes.sellValue}>{`${((values.Watches.Total || 0) + (values.Jewels.Total || 0) + (values.Silvers.Total || 0)).toFixed(2)} ${values.Jewels.Currency || values.Watches.Currency || values.Silvers.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTMARGIN",this.props.translations, "Tot. margine")}</span>
              <span className={classes.sellValue}>{`${(marginAvg).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGDISC",this.props.translations, "Sconto medio")}</span>
              <span className={classes.sellValue}>{`${avgDiscountTotal.toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_NPIECES",this.props.translations, "Numero pezzi")}</span>
              <span className={classes.sellValue}>{((values.Watches.TotItems || 0) + (values.Jewels.TotItems || 0) + (values.Silvers.TotItems || 0))}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGAMOUNT",this.props.translations, "Imp. medio")}</span>
              <span className={classes.sellValue}>{`${(((values.Watches.Total || 0) + (values.Jewels.Total || 0) + (values.Silvers.Total || 0)) / (((values.Watches.TotItems || 0) + (values.Jewels.TotItems || 0) + (values.Silvers.TotItems || 0) || 1))).toFixed(2)} ${values.Jewels.Currency || values.Watches.Currency || values.Silvers.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_LASTSALE",this.props.translations, "Ultima vendita")}</span>
              <span className={classes.sellValue}>{(values.Watches.LastSell && (new Date(values.Watches.LastSell)).toLocaleDateString() || '__/__/____')}</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.box}>
            <span className={classes.label}>{salesGroupWatches}</span>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTSALE",this.props.translations, "Tot. vendita")}</span>
              <span className={classes.sellValue}>{`${(values.Watches.Total || 0).toFixed(2)} ${values.Watches.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTMARGIN",this.props.translations, "Tot. margine")}</span>
              <span className={classes.sellValue}>{`${(values.Watches.Margin || 0).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGDISC",this.props.translations, "Sconto medio")}</span>
              <span className={classes.sellValue}>{`${(values.Watches.AvgDiscount || 0).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_NPIECES",this.props.translations, "Numero pezzi")}</span>
              <span className={classes.sellValue}>{(values.Watches.TotItems || 0)}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGAMOUNT",this.props.translations, "Imp. medio")}</span>
              <span className={classes.sellValue}>{`${((values.Watches.Total || 0) / (values.Watches.TotItems || 1)).toFixed(2)} ${values.Watches.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_LASTSALE",this.props.translations, "Ultima vendita")}</span>
              <span className={classes.sellValue}>{(values.Watches.LastSell && (new Date(values.Watches.LastSell)).toLocaleDateString() || '__/__/____')}</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.box}>
            <span className={classes.label}>{salesGroupJewels}</span>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTSALE",this.props.translations, "Tot. vendita")}</span>
              <span className={classes.sellValue}>{`${(values.Jewels.Total || 0).toFixed(2)} ${values.Jewels.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTMARGIN",this.props.translations, "Tot. margine")}</span>
              <span className={classes.sellValue}>{`${(values.Jewels.Margin || 0).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGDISC",this.props.translations, "Sconto medio")}</span>
              <span className={classes.sellValue}>{`${(values.Jewels.AvgDiscount || 0).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_NPIECES",this.props.translations, "Numero pezzi")}</span>
              <span className={classes.sellValue}>{(values.Jewels.TotItems || 0)}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGAMOUNT",this.props.translations, "Imp. medio")}</span>
              <span className={classes.sellValue}>{`${((values.Jewels.Total || 0) / (values.Jewels.TotItems || 1)).toFixed(2)} ${values.Jewels.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_LASTSALE",this.props.translations, "Ultima vendita")}</span>
              <span className={classes.sellValue}>{(values.Jewels.LastSell && (new Date(values.Jewels.LastSell)).toLocaleDateString() || '__/__/____')}</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.box}>
            <span className={classes.label}>{salesGroupSilvers}</span>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTSALE",this.props.translations, "Tot. vendita")}</span>
              <span className={classes.sellValue}>{`${(values.Silvers.Total || 0).toFixed(2)} ${values.Silvers.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_TOTMARGIN",this.props.translations, "Tot. margine")}</span>
              <span className={classes.sellValue}>{`${(values.Silvers.Margin || 0).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGDISC",this.props.translations, "Sconto medio")}</span>
              <span className={classes.sellValue}>{`${(values.Silvers.AvgDiscount || 0).toFixed(2)} %`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_NPIECES",this.props.translations, "Numero pezzi")}</span>
              <span className={classes.sellValue}>{(values.Silvers.TotItems || 0)}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_AVGAMOUNT",this.props.translations, "Imp. medio")}</span>
              <span className={classes.sellValue}>{`${((values.Silvers.Total || 0) / (values.Silvers.TotItems || 1)).toFixed(2)} ${values.Silvers.Currency || '€'}`}</span>
            </div>
            <div className={classes.sellItem}>
              <span className={classes.sellLabel}>{getTranslation(4,"IR_LASTSALE",this.props.translations, "Ultima vendita")}</span>
              <span className={classes.sellValue}>{(values.Silvers.LastSell && (new Date(values.Silvers.LastSell)).toLocaleDateString() || '__/__/____')}</span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} spacing={2}>
          <div className={classes.root}>
            <Tabs
              classes={{
                root: classes.tabsPositionSticky
              }}
              headerColor="primary"
              plainTabs={true}
              tabs={tabs}
              onTabChange={this.manageTabChange}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}


Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};


const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(Profile);