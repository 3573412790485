import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid";

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () =>({
});

class Departments extends Component {
	static contextTypes = {
		router: PropTypes.object
	}
	handleGridDoubleClick = (event, data) => {
		this.context.router.history.push('/prod/depart/' + data.ID);
	}
	handleGridNew = (event) => {
		this.context.router.history.push('/prod/depart/new');
	}
	render() {
		return (
			<XGrid
				usePrimaryColor={true}
				dataRoute="/prod/departs/GetData?grid=tblPRODDepartments"
				dataRouteColumns="/Base/GetColumns?grid=tblPRODDepartments"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblPRODDepartments"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblPRODDepartments"
				onDoubleClick={this.handleGridDoubleClick}
				onEditButton={this.handleGridDoubleClick}
				onNewButton={this.handleGridNew}
			/>
		);
	}
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Departments);
