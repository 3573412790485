import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Department extends Component {
	constructor(props) {
		super(props);
		//this.setState({ FIDParentColumnName: "Brand" });
		this.Editor = React.createRef();
	}
	state = {
		depart: { FIDParent: 0 },
	};
	checkUsed = async (FIDDepartment) => {
		return await fetch('/Production/DepartIsUsed/' + FIDDepartment).then(res => res.json());

	}
	deleteLine = (FIDDepartment) => {
		fetch('/Production/DepartDelete/' + FIDDepartment, { method: "POST" }).then(res => {
			this.Editor.current.doExit();
		});
	}
	onDeleteClick = () => {
		var id = this.props.match.params.id;

		var isUsed = this.checkUsed(id);

		var label = "Confermi la cancellazione del reparto?";
		if (isUsed) {
			label = "La linea è utilizzata nelle distinte oppure ha delle Serie collegate. Continuando verranno cancellato le Serie collegate e sulle distinte veranno tolte le informazioni su Linea e Serie. " + label;
		}

		var buttons = [
			{
				label: "SI",
				action: () => {
					this.Editor.current.hideDialog();
					this.deleteLine(id);
				}
			},
			{
				label: "NO",
				action: () => {
					this.Editor.current.hideDialog();
				}
			},
		];

		this.Editor.current.showDialog(buttons, "Cancellazione", label);

	}
	render() {
		var { depart } = this.state;

		var fields = [
			{
				key: 'IsLogistics',
				label: 'Logistica',
				type: 'XSwitch',
				Required: 1,
			},
			{
				key: 'FIDPosition',
				label: 'Posizione',
				type: 'XSelect',
				getUrl: '/Production/GetProductionPositions',
				saveUrl: undefined,
				idKey: 'ID',
				valueKey: 'Position',
				abbreviationKey: 'Abbreviation',
				Required: 2
			}
		];

		return <GenericEditor
			innerRef={this.Editor}
			id={this.props.match.params.id}
			//FIDParentColumnName='FIDBrand'
			//FIDParentColumnCaption='Brand'
			//urlGetParents='Base/GetBOPBrands'
			urlSave='/prod/depart/Save/'
			//urlDelete='/BOP/DeleteLine/'
			urlRedirect='/prod/departs/'
			//urlUploadFiles='/BOP/LineFileUpload/'
			//urlDeleteFiles='/BOP/DeleteLineFile/'
			//urlGetFiles='/BOP/GetLineFiles/'
			//urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
			urlExit='/prod/departs/'
			urlNew="/prod/depart/new"
			urlGetData='/prod/depart/'
			urlCheckChanges='/prod/depart/HasChanges/'
			urlClearSession='/prod/depart/ClearSession/'
			//onDeleteClick={this.onDeleteClick}
			fields={fields}
		/>;
	}
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Department);
