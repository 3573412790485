import React, { Component } from 'react'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { Download } from 'mdi-material-ui';

import XGrid from "components/XGrid";

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const styles = () =>({
});

class Agreements extends Component {
    constructor(props) {
        super(props);
        this.grdAgreements = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/Customer/GDPR/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/Customer/GDPR');
    }

    handleDownloadAgreement = () => {
        var FIDAgreements = this.grdAgreements.current.getWrappedInstance().getSelectedData();
        for (var i = 0; i < FIDAgreements.length; i++) {
            fetch("/IR/DownloadAgreement/" + FIDAgreements[i]).then(res => res.blob()).then(blob => {
                //blob.type = "application/pdf";
                window.open(window.URL.createObjectURL(blob));
            });
            //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
        }
        this.grdAgreements.current.getWrappedInstance().clearSelection();
    }

    handledeleteAgreement = () => {
        var FIDAgreements = this.grdAgreements.current.getWrappedInstance().getSelectedData();
        var FIDIdentity = this.state.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch('/IR/DeleteAgreement/' + FIDIdentity, {
            method: 'POST',
            body: JSON.stringify(FIDAgreements)
        }).then(res => {
            //this.context.router.history.push('/IR');
            this.grdAgreements.current.getWrappedInstance().refresh();
        });
        //for (var i = 0; i < FIDAgreements.length; i++) {
        //    //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
        //}
        this.grdAgreements.current.getWrappedInstance().clearSelection();
    }
    render() {
        var actions = [
            {
                group: 'actions',
                name: 'Download',
                action : this.handleDownloadAgreement,
                icon: <Download />,
                condition: r => r.length > 0
            },
            //(
            //    <Tooltip title="Elimina">
            //        <IconButton aria-label="Elimina" onClick={this.handledeleteAgreement} >
            //            <TrashCan />
            //        </IconButton>
            //    </Tooltip>
            //),
        ];
        return (
            <XGrid
                usePrimaryColor={true}
                ref={this.grdAgreements}
                dataRoute="/IR/GetAgreements?grid=tblAgreements"
                dataRouteColumns="/Base/GetColumns?grid=tblAgreements"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblAgreements"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblAgreements"
                dataRouteDelete="/IR/DeleteAgreements/"
                //onDoubleClick={this.handleGridDoubleClick}
                //onEditButton={this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
                actions={actions}
            />
        );
    }
}


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(Agreements);