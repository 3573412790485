import React, { useRef } from 'react';
import { withTheme, makeStyles } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XMessageBox from 'components/XMessageBox';
import TabContainer from 'components/CustomTabs/TabContainer';
import WorkStatistics from './BIProd/WorkStatistics';
import ProductionStatistics from './BIProd/ProductionStatistics';
import ProductionValues from './BIProd/ProductionValues';
import ActivityList from './BIProd/ActivityList';
import StoneEmbedding from './BIProd/StoneEmbedding';
import InvoiceStatistics from './BIProd/InvoiceStatistics';

const useStyles = makeStyles(theme => ({
    root: {

    }
}));


const BIProdNew = (props) => {
    const classes = useStyles(props);
    const XMessageBox = useRef(null);


    const onTabChanged = (newTab) => {

    }
    const updateFilters = () => {

    }

    const tabActions = [
        {
            group: 'actions',
            icon: 'ChartPie',
            name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
            action: this.updateFilters
        }
    ]

    const tabs = [{
                tabName: "Statistiche Lavorazione",
                tabContent: <TabContainer><WorkStatistics /></TabContainer>,
                actions: tabActions,
            },
            {
                tabName: "Statistiche Produzione",
                tabContent:<TabContainer><ProductionStatistics /></TabContainer>,
                actions: tabActions,
            },
            {
                tabName: "Statistiche Valore",
                tabContent: <TabContainer><ProductionValues /></TabContainer>,
                actions: tabActions,
            },

            {
                tabName: "Elenco Attivita",
                tabContent: <TabContainer><ActivityList /></TabContainer>,
                actions: tabActions,
            },
            {
                tabName: "Incassatura",
                tabContent: <TabContainer><StoneEmbedding /></TabContainer>,
                actions: tabActions,
            },
            {
                tabName: "Statistiche Fatturazione",
                tabContent: <TabContainer><InvoiceStatistics /></TabContainer>,
                actions: tabActions,
            }
        ];

    return (
        <>
            <Tabs
                headerColor="primary"
                plainTabs={true}
                tabs={tabs}
                onTabChange={onTabChanged}
            />
            <XMessageBox innerRef={XMessageBox} />
        </>
    );
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withTheme
);
export default enhance(BIProdNew);