import React, {Component} from 'react';

import IREdit from 'views/IR/IREdit';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { ArrowRight, ArrowLeft } from 'mdi-material-ui';
import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as authCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const styles = theme => ({
});

class PricelistSelector extends Component {
    constructor(props) {
        super(props);

        this.grdPricelists = React.createRef();
        this.MessageBox = React.createRef();
    }
    state = {
        data: {},
    };

    pricelistSelected = () => {
        var { onPricelistSelected } = this.props;
        var { data } = this.state;
        var FIDPricelist = data.ID;
        if (FIDPricelist && onPricelistSelected) {
            onPricelistSelected(FIDPricelist);
        }
    }
    loadPricelist = () => {
        var selected = this.grdPricelists.current.getWrappedInstance().getSelectedData();
        if (selected.length > 1) {
            this.MessageBox.current.getWrappedInstance().showMessageOk("Selezione listino", <Typography>Selezionati più listini. Per continuare selezionare un solo listino.</Typography>);
            return;
        }
        else {
            var selectedData = this.grdPricelists.current.getWrappedInstance().getRowData(this.grdPricelists.current.getWrappedInstance().getSelectedData()[0]);
            this.setState({ data: selectedData }, this.pricelistSelected);
        }
    }
    selectPricelist = () => {
        this.loadPricelist();
    }
    render() {
        var { classes, onCancel, pricelists } = this.props;
        var { data } = this.state;
        var previousStep = undefined;
        if (onCancel) {
            // previousStep = <Tooltip title="Indietro">
            //     <IconButton aria-label="Indietro" onClick={onCancel} >
            //         <ArrowLeft />
            //     </IconButton>
            // </Tooltip>;
            previousStep = {
                group: 'actions',
                name: getTranslation(5,"GC_BACK",this.props.translations, 'Indietro'),
                icon: <ArrowLeft />,
                action: onCancel
            }
        } 
        // var nextStep = <Tooltip title="Avanti">
        //     <IconButton aria-label="Avanti" onClick={this.selectPricelist} >
        //         <ArrowRight />
        //     </IconButton>
        // </Tooltip>;
        var nextStep = {
            group: 'actions',
            name:getTranslation(5,"GC_NEXT",this.props.translations, 'Avanti'),
            icon: <ArrowRight />,
            action: this.selectPricelist,
            condition: rows => rows && rows.length === 1,
        };


        // var customActions = [previousStep,{
        //     component: nextStep,
        //     condition: rows => rows && rows.length === 1
        // }];
        
        var customActions = [nextStep, previousStep];
        //var selectedActions = [nextStep];
        var selectedActions = undefined;
        var dataRoute = "/gc/pricelistgrid?grid=tblGCPricelists";
		
		if (this.props.isAgent) {
			dataRoute += "&FIDIdentity="+this.props.FIDIdentity;
		}
		
        if (pricelists) {
            for (var i = 0; i < pricelists.length; i++) {
                dataRoute += "&pricelists=" + pricelists[i];
            }
        }
        var dataRouteColumns = "/Base/GetColumns?grid=tblGCPricelists";
        var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblGCPricelists";
        var dataRouteUpdateColumns = "/Base/UpdateColumns?grid=tblGCPricelists";
        var onDoubleClick = undefined;
        var customContent = undefined;
        var showFilterActivator = true;
        var showColumnsSelector = true;
        var newAction = undefined;

        var label = getTranslation(5,"GC_SELECTPRICELIST",this.props.translations, "Seleziona un listino");

        return (
            <div className={classes.root}>
                <XGrid
                    ref={this.grdPricelists}
                    label={label}
                    dataRoute={dataRoute}
                    dataRouteColumns={dataRouteColumns}
                    dataRouteAvailableColumns={dataRouteAvailableColumns}
                    dataRouteUpdateColumns={dataRouteUpdateColumns}
                    showFilterActivator={showFilterActivator}
                    showColumnsSelector={showColumnsSelector}
                    onNewButton={newAction}
                    //dataRouteDelete={""}
                    actions={customActions}
                    selectedActions={selectedActions}
                    customContent={customContent}
                    onDoubleClick={onDoubleClick}
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(authCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(PricelistSelector);