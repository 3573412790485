import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import {  KeyboardArrowLeft, KeyboardArrowRight, ExpandMore } from '@material-ui/icons';
import moment from 'moment';


import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import numeral from 'numeral';

import { Check, Close } from 'mdi-material-ui';

import XButton from 'components/XButton';
import XOXLoader from './XOXLoader';
import { Tooltip,IconButton } from '@material-ui/core';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';
import XDateField from 'components/XDateField';

import Modal from 'components/Modal';

const styles = theme => ({
    card: {
        position: "relative",
        margin: 5,
    },
    header: {
        maxHeight: 80,
        minHeight: 80,
        overflow: "hidden",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
    },
    actions: {
        display: 'flex',
    },
    //expand: {
    //    transform: 'rotate(0deg)',
    //    transition: theme.transitions.create('transform', {
    //        duration: theme.transitions.duration.shortest,
    //    }),
    //    marginLeft: 'auto',
    //    [theme.breakpoints.up('sm')]: {
    //        marginRight: -8,
    //    },
    //},
    //expandOpen: {
    //    transform: 'rotate(180deg)',
    //},
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    price: {
        fontSize: "14px",
        fontWeight: "bold",
    },
	bopsData: {
		fontSize: 14
	},
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    cardTitle: {
        fontSize: "18px",
    },
    cardContent: {
        //maxHeight: 80,
        //minHeight: 80,
        //marginTop: 136,
        //transition: "all 0.3s ease-in",
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentHover: {
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentInfo: {
        maxHeight: 42,
        minHeight: 42,
    },
    cardActions: {
        //position: "absolute",
        //transition: "all 0.3s ease-in",
        //bottom: -136,
        height: 136,
        textAlign: "center",
        "&> div:nth-child(1)": {
            marginTop: "0 !important",
        },
        bottom: 0,
    },
    cardActionsHover: {
        bottom: 0,

    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandActions: {
        padding: "8px 0",
        width: "calc(100% + 16px)",
        margin: "-8px",
    },
    docInfo: {
        marginRight: 5,
    },
    infoLabels: {
        fontSize: 12,
    },
    speedometer: {
        position: 'relative',
        width: 100,
        height: 67,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& .current-value': {
            transform: 'translateY(-60px)',
            fontSize: '12px !important',
        }
    },
    buttonsWrapper: {
        textAlign: 'center',
        width: '100%',
    },
    popup: {
        position: "absolute",
        maxWidth: 350,
        width: "100%",
        left: "50%",
        top: "50%",
        zIndex: "1299",
        background: "#fff",
        boxShadow: "0px 1px 7px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        //border: "1px solid",
        padding: "10px",
        transform: "translate(-50%,-50%)",
        
    },
    popupBack: {
        position: "fixed",
        display: "block",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#999",
        opacity: 0.8,
        zIndex: "1298",
    },
});

class AssignmentCard extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    _isMounted = false;
    state = {
        imageIndex: 0,
        isLoading: false,
        expanded: false,
    };
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }


    handleNext = () => {

        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }), () => {
            if (this.props.onExpand) {
                this.props.onExpand(this.state.expanded);
            }
        });
    }
    CancelItem = () => {
        var { data } = this.props;

        var newDate = new Date();
        newDate.setDate(newDate.getDate() - 1);
        newDate = moment(newDate);
        fetch(`/DOC/UpdateDueDate/${data.ID}?dueDate=${newDate.format("YYYY-MM-DD")}`,{
            method: 'POST'
        }).then(res => {
            this.setState({showDueDate: false, dueDate: newDate});
            this.props.onRequestReload && this.props.onRequestReload();
        });
    }
    ConfirmItem = () => {
        var { data } = this.props;
        fetch(`/DOC/ConfirmNegotation/${data.ID}`, {
            method: 'POST'
        }).then(res => {
            this.props.onRequestReload && this.props.onRequestReload();
        });
    }
    render() {
        var { imageIndex, isLoading, hover, showDueDate, dueDate } = this.state;
        var { data,classes, viewType } = this.props;
        var isLoadingPage = this.props.isLoading;
        if (!data) {
            isLoadingPage = true;
        }
        var subTitle = "";
        if (data.Brand) {
            subTitle = data.Brand;
        }
        if (data.Line) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
          subTitle += data.Line;
        }
        var currency = data.Currency;
        var price = currency + " " + numeral((data.DiscountedPrice || 0)).format("0,0.00");
        var priceList = (currency ? currency + " " : "") + numeral((data.Price || 0)).format("0,0.00");
        var discountNumber = data.Discount || 0;
        var discount = (data.Discount * 100 || 0).toFixed(2) + " %";


        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];

        if (files.length > 1) {
            files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?size=S"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        
        var dueDate = dueDate || data.DueDate;
        if(dueDate && !dueDate._isAMomentObject){
            dueDate = moment(new Date(dueDate));
        }

        var catCode = (data.CatalogCode || data.Code);
        var code = data.Code;
		
		if (catCode && catCode != code)
			code += " - " + catCode;
		
		if (data.SupplierCode)
			code += "\n" + data.SupplierCode;
		
		if (data.SupplierCatalogCode)
			code += " - " + data.SupplierCatalogCode;
		
        var content = <Fragment>
            <CardHeader
                className={classes.header}
                title={code}
                subheader={subTitle}
                classes={{
                    title: classes.cardTitle
                }}
                action={
                    <Fragment>
                    </Fragment>
                }
            />
            <div className={classes.slider}>
                <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                    {mediaFiles}
                </SwipeableViews>
                {files.length > 1 &&
                    (
                        <Fragment>
                            <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                            <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                        </Fragment>
                    )
                }
            </div>
            <CardContent className={classes.cardContent + " " + (hover ? classes.cardContentHover : "")}>
                <Grid container spacing={16}>
                    {<Grid container item xs={12}>
						{data.Barcode ?
							<Grid item xs={6} container className={classes.infoLabels} alignContent="flex-end">
								{getTranslation(1,"BOP_BARCODE",this.props.translations, "Matricola")}
							</Grid>
							
							: <Grid item xs={6} container className={classes.infoLabels}>&nbsp;</Grid>
						}
						{data.SupplierSN ?
							<Grid item xs={6} container alignContent="flex-end" justify="flex-end" className={classes.infoLabels}>
								{getTranslation(1,"BOP_SUPPLIERSN",this.props.translations, "Matricola fornitore")}
							</Grid>
							
							: <Grid item xs={6} container className={classes.infoLabels}>&nbsp;</Grid>
						}
						{data.Barcode ?
							<Grid item xs={6} container className={classes.bopsData}>
								{data.Barcode}
							</Grid>							
							: <Grid item xs={6} container>&nbsp;</Grid>							
						}
						{data.SupplierSN ?
							<Grid item xs={6} container alignContent="flex-end" justify="flex-end" className={classes.bopsData}>
								{data.SupplierSN}
							</Grid>							
							: <Grid item xs={6} container>&nbsp;</Grid>							
						}
                        <Grid item xs={4} container className={classes.infoLabels}>
                            {getTranslation(1,"CAT_PRICELIST",this.props.translations, "Prezzo di listino")}
                        </Grid>
                        <Grid item xs={4} container alignContent="center" justify="center" className={classes.infoLabels}>
                          {discountNumber > 0 ? "Sc. %" : ""}
                        </Grid>
                        <Grid item xs={4} container alignContent="flex-end" justify="flex-end" className={classes.infoLabels}>
                            {getTranslation(4,"IR_PRICE",this.props.translations, "Prezzo scontato")}
                        </Grid>
                        <Grid item xs={4} container className={classes.price}>
                            {discountNumber === 0 && data.SupplierLabel ? data.SupplierLabel : priceList}
                        </Grid>
                        {discountNumber > 0 ? <Grid item xs={4} container alignContent="center" justify="center" className={classes.price}>{discount}</Grid> :
                            <Grid item xs={4} container alignContent="center" justify="center" className={classes.price}>&nbsp;</Grid>
                        }
                        <Grid item xs={4} container alignContent="flex-end" justify="flex-end" className={classes.price}>
                            {discountNumber === 0 && data.SupplierLabel ? data.SupplierLabel:  price}
                        </Grid>
                    </Grid>}
                </Grid>
                {/*viewType !== "confirmed" && <Grid container spacing={16}>
                    <div className={classes.buttonsWrapper}>
                        {viewType === "active" && <Tooltip title={"Conferma"}>
                            <XButton className={classes.catalogueButton2} color={"primary"} onClick={this.ConfirmItem}><Check /></XButton>
                        </Tooltip>}
                        <Tooltip title={"Elimina"}>
                            <XButton className={classes.catalogueButton2} color={"primary"} onClick={this.CancelItem}><Close /></XButton>
                        </Tooltip>
                    </div>
                </Grid>*/}
            </CardContent>
            <div></div>
        </Fragment>;
        //if (this.state.isLoading) {
        //    content = <XOXLoader onlyO={true} />
        //}
        return (
            <Card style={this.props.style} className={classes.card} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                {content}
                {isLoading && <XOXLoader onlyO={true} height="100%" />}
            </Card>
        );
    }
}

export default withStyles(styles)(AssignmentCard);