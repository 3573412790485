import React, { Component } from 'react';
import{ withStyles } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { actionCreators as globalCreators } from 'store/Global';
import { connect } from 'react-redux';
import XExpansionPanel from 'components/XExpansionPanel';
import TabContainer from 'components/CustomTabs/TabContainer';
import { getXField, getXSelect, getDiscount, getRecharge, round } from '../utils';
import { CodeBrackets } from 'mdi-material-ui';
import Grid from '@material-ui/core/Grid';
import XGallery from './XGallery';

const styles = theme => ({
    root: {

    }
});


var originalFields = [
    {
        Field: "Code",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Description",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Quantity",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDMeasureUnit1",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Quantity1",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDMeasureUnit2",
        Visibility: true,
        Required: 3,
    },
];
var defaultFields = [];


class BOPComponentEditor extends Component {
    constructor(props){
        super(props);
        this.state={
            data:{}
        };
    }
    componentWillMount() {
        defaultFields =Object.assign([], originalFields);
        this.loadData();
    }
    loadData = () =>{
        var { data } = this.props;
        if(!data.FIDDBComponent) {
            fetch(`/BOP/GetDataForComponent/${data.ID}`).then(res => res.json()).then(data => {
                data.Quantity = data.MeasureUnitQty1 || 0;
                data.Quantity1 = data.MeasureUnitQty2 || 0;
                this.setState({data});
            });
        }
        else {
            data.Quantity = data.Qty1 || 0;
            data.Quantity1 = data.Qty2 || 0;
            data.MeasureUnitQty1 = data.Quantity;
            data.MeasureUnitQty2 = data.Quantity1;
            this.setState({data});
        }
    }
    onChange = (key) => (event, value) => {
        this.setState(state =>{
            state.data[key] = value;

            if(key === "Quantity"){
                if( state.data.Quantity){
                    state.data.Quantity1 = parseFloat(state.data.Quantity) * state.data.MeasureUnitQty2 / state.data.MeasureUnitQty1;
                }
                else {
                    state.data.Quantity1 = 0;
                }
            }
            // else if(key === "Quantity1"){
            //     if( state.data.Quantity){
            //         state.data.Quantity = parseFloat(state.data.Quantity1) * state.data.MeasureUnitQty1 / state.data.MeasureUnitQty2;
            //     }
            //     else {
            //         state.data.Quantity = 0;
            //     }
            // }


            return state;
        });
    }
    getData = () => {
        var propsData = this.props.data;
        var { data } = this.state;
        if(!propsData.FIDDBComponent){
            data.FIDDBComponent = data.ID;
            data.ID = undefined;
            data.TotalCost = 0;
        }
        else {

        }
        return data;
    }
    onSelectChange = (id) => (event, value) => {
        this.setState(state => {
            if (value && value.length > 0) {
                state.data[id] = value[0].ID;
            }
            else {
                state.data[id] = undefined;
            }
            return state;
        });
    }
    render() {
        var { data } = this.state;
        console.log(defaultFields);
        var qty1Props = { type: 'number' };
        var qty2Props = { type: 'number', disabled: !data.FIDMeasureUnit2 };
        

        var qty1 = data.Quantity;
        if(!qty1){
            qty1 = 0;
        }
        qty1 = parseFloat(qty1);
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];

        var fieldCode = getXField(data, defaultFields, 12, 3, "Code", this.props.labels.Code || "Codice XOX", this.onChange, { onBlur: this.onBlur, disabled: true });

        var fieldDescription = getXField(data, defaultFields, 12, 12, "Description", "Descrizione", this.onChange, { onBlur: this.onBlur  });
        
        var fieldQty1 = getXField(data, defaultFields, 12, 3, "Quantity", "Q.tà principale", this.onChange, qty1Props);
        var fieldQty2 = getXField(data, defaultFields, 12, 3, "Quantity1", "Q.tà secondaria", this.onChange, qty2Props);
        var fieldMeasureUnit1 = getXSelect(data, defaultFields, 12, 3, "FIDMeasureUnit1", "U.M. principale", this.onSelectChange, "/Base/GetGCMeasureUnits","",{disabled: true});
        var fieldMeasureUnit2 = getXSelect(data, defaultFields, 12, 3, "FIDMeasureUnit2", "U.M. secondaria", this.onSelectChange, "/Base/GetGCMeasureUnits","",{disabled: true});
        
        var panels = [];
        panels.push({
            icon: <CodeBrackets />,
            label: "Codifica",
            content: <Grid item xs={12} container direction="row" spacing={2}>
                <Grid container spacing={2} xs={12} md style={{padding:25}}>
                    {<XGallery imageIds={files} />}
                </Grid>
                <Grid container spacing={2} xs={12} md={8}>
                    {fieldCode}
                    {fieldDescription}
                    {fieldQty1}
                    {fieldMeasureUnit1}
                    {fieldQty2}
                    {fieldMeasureUnit2}
                </Grid>
            </Grid>
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer padding={0}>
                {ui}
            </TabContainer>
        );
    }
}



const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles),
);
    
export default enhance(BOPComponentEditor);