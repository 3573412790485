import React, { Component, Fragment } from 'react';
import {Checkbox, IconButton} from '@material-ui/core';
import { ChevronDown,ChevronUp } from 'mdi-material-ui';
import XGridCell from './XGridCell';
import withStyles from 'react-jss';

import color from 'color';
import classNames from 'classnames';

import { getScrollbarWidth } from 'utils';

import { grayColorLight, primaryColor } from '../assets/jss/material-dashboard-pro-react';
import { format, formatTime, formatDateTime, formatTimespan } from '../utils';


var rowStyle = {
    selected: {
        backgroundColor: color(grayColorLight).hsl().alpha(0.2).string() + " !important",
    },
    root: {
        minWidth: props => props.width,
        height: props => (props.expanded ? props.rowHeight - props.expansionSize : props.rowHeight),//"42px",
        lineHeight: props => (props.expanded ? props.rowHeight - props.expansionSize : props.rowHeight)  + "px",//"42px",
        overflowY: 'hidden',
        '&:nth-child(odd)': {
            backgroundColor: color(grayColorLight).hsl().alpha(0.05).string()
        },
        '&:after': {
            content: '" "',
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: color(grayColorLight).hsl().alpha(0.2).string(),
        },
        '&:hover': {
            backgroundColor: color(grayColorLight).hsl().alpha(0.15).string() + " !important",
        }
    }
};

class XGridRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
        };
        
    }
    expandRow = () =>{
        // this.setState(state =>{
        //     state.isExpanded = !state.isExpanded;
        //     return state;
        // }, () =>{
            
        // });
        var { onRowExpand, rowIndex } = this.props;
            onRowExpand && onRowExpand(rowIndex);
    }
    render() {
      var { data, columns, isSelected, classes, onDoubleClick, onClick, onSelectAll, width, customCells, rowHeight, style, viewType, customStyleFunction, 
            expandFormat, hideExpandButton, previousExpanded, expanded, expansionSize, rowSelectableOnlyIfExpanded, rowIndex, expansionWidth, expansionLeftOffset, expansionMaxWidth } = this.props;
        //var { isExpanded } = this.state;
        
        var rowStyle = Object.assign({}, style);
        if (customStyleFunction) {
          rowStyle = customStyleFunction(rowStyle, data);
        }
        if (!expanded) {
          rowStyle.height = rowHeight;

          //if (!previousExpanded)
          //  rowStyle.top = rowHeight * rowIndex;
        }
        if (expanded) {
          rowHeight -= expansionSize;
        }

        if (viewType === "list") {
            width += 84;
        }

        return (
            <Fragment>
                <div style={rowStyle} key={data.ID} className={classNames({ [classes.selected]: isSelected, [classes.root]: true })} width={width} onDoubleClick={onDoubleClick(data)} onClick={event => onClick(event, data.ID)}>
                    <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, verticalAlign: "middle" }}>
                      {(!rowSelectableOnlyIfExpanded || expanded) && <Checkbox id={"sel_" + data.ID} checked={isSelected} onChange={onSelectAll(data.ID)} />}
                    </span>
                    {viewType == 'list' && <span style={{ minWidth: "84px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, textAlign: "center", verticalAlign: "middle" }}>{data.FileURL && <img style={{ maxWidth: 84, maxHeight: 84 }} src={data.FileURL} />}</span>}
                    {columns.map(column => {
                        var value = data[column.key];
                        if ("boolean" === typeof value) {
                            value = value ? "SI" : "NO";
                            column.type = "managed";
                        }
                        if (customCells && customCells[column.key]) {
                            var customCell = customCells[column.key];
                            value = customCell(data, column);
                        }
                        else {
                            if (value) {
                              switch (column.type) {
                                    case "time":
                                        value = formatTime(value);
                                        break;
                                    case "time-min":
                                        value = formatTimespan(value);
                                        break;
                                    case "datetime":
                                        value = formatDateTime(new Date(value))
                                        break;
                                    case "date":
                                        value = (new Date(value)).toLocaleDateString();
                                        break;
                                    case "image":
                                        value = <img src={value} />;
                                        break;
                                    case "bool":
                                        if ("boolean" !== typeof value) {
                                            value = value === "1" ? "SI" : "NO";
                                        }
                                        else {
                                            value = value ? "SI" : "NO";
                                        }
                                        break;
                                    case "currency":
                                        value = format(value,'');
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        var caption = undefined;
                        if (column.caption && value)
                        {
                            caption = data[column.caption];
                        }

                        //return (<XGridCell value={value} column={column} width={column.width} totalWidth={width} rowHeight={rowHeight} />);
											return (<span key={column.key} style={{ width: column.width, height: rowHeight, display: 'inline-block', verticalAlign: "top", overflow: "hidden", paddingLeft: 10 }}>{value} {caption}</span>);
                    })}
                    {expandFormat && !hideExpandButton && (
                        <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, verticalAlign: "middle", position: "absolute", right: getScrollbarWidth() }}>
                            <IconButton aria-label="Espandi" onClick={this.expandRow}>
                    {expanded ? <ChevronUp id={"exp_" + data.ID} fontSize="small" /> : <ChevronDown id={"exp_" + data.ID} fontSize="small" />}
                            </IconButton>
                        </span>
                    )}
                </div>
            {expanded && expandFormat && <div id={`gridExpandContainer_${data.ID}`} style={{height:expansionSize, maxWidth: (expansionMaxWidth||"100%"), width: (expansionWidth||'100%'), top: rowStyle.top+rowHeight, position: 'relative', padding: 10, left: (expansionLeftOffset||0)}}>{expandFormat(data)}</div>}
            </Fragment>
        );
    }
}
export default withStyles(rowStyle)(XGridRow);