import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import XSelect from 'components/XSelect';
import XGrid from "components/XGrid"
import XMessageBox from "components/XMessageBox";
import XNavigation from '../components/XNavigation';
import XOXLoader from 'components/XOXLoader';
import { ClipboardArrowLeftOutline, ClipboardArrowLeft, ClipboardArrowRight, ClipboardArrowRightOutline, CartArrowDown, ArrowLeft, Check, Printer } from 'mdi-material-ui';
import Grid from "@material-ui/core/Grid";
import { RemoveShoppingCart, Store } from '@material-ui/icons';
import "components/XReactDataGrid.css";
import XStepper from 'components/XStepper';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

import { actionCreators } from '../store/Auth';

class Stock extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
        this.grdStock = React.createRef();
        this.state = {
            print: false,
            printRows: [],
            view: "STOCK",
            isLoading: false,
            updateStores: false,
            storeData: {
                FIDStore: 0,
                FIDStoreArea: 0,
                Status: 0
            },
        };
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleUpdateStores = () => {
        this.setState({
            updateStores: true
        })
    }
    handleUpdateStoresCancel = () => {
        this.setState({
            updateStores: false,
            storeData: {
                FIDStore: 0,
                FIDStoreArea: 0,
                Status: 0
            },
        })
    }
    handleUpdateStoresConfirm = () => {
        var { storeData } = this.state;
        if (!storeData.FIDStore || !storeData.FIDStoreArea || !storeData.Status) {
            this.MessageBox.current.showMessageOk("AGGIORNAMENTO STORE/STORE AREA", "Selezionare store, store area e stato per procedere con l'aggiornamento.");
        }
        else {
            this.MessageBox.current.showMessageYesNo("CONFERMA AGGIORNAMENTO STORE/STORE AREA", "Si desidera confermare l'aggiornamento store per le distinte selezionate?", this.doUpdateStores);
        }
    }
    onStoreSelectChange = (key) => (event, data) => {
        var { storeData } = this.state;

        if (data.length > 0) {
            storeData[key] = data[0].ID;
        }

        if (["FIDStore","FIDStoreArea"].includes(key))
            storeData = this.filterStoreAreas(data[0], storeData, key);

        this.setState({storeData});
    }
    filterStoreAreas = (data, db, key) => {
        if ("FIDStoreArea" === key && data && data.FIDStore > 0) {
            db["FIDStore"] = data.FIDStore;
        }

        if ("FIDStore" === key) {
            var storeareas = data && data.FIDStoreAreas ? data.FIDStoreAreas.split(",") : [];

            if (storeareas.length === 1) {
                db["FIDStoreArea"] = parseInt(storeareas[0]);
            } else {
                db["FIDStoreArea"] = undefined;
            }
        }
        return db;
    }
    doUpdateStores = () => {
        var gridFilters = this.grdStock.current.state.filters;
        var { storeData } = this.state;
        var FIDStore = storeData.FIDStore;
        var FIDStoreArea = storeData.FIDStoreArea;
        var StoreStatus = storeData.Status;
        var bops = this.grdStock.current.getSelectedData();
        this.setState({ isLoading: true }, () => {
            fetch(`/BOP/UpdateBOPStores?FIDStore=${FIDStore}&FIDStoreArea=${FIDStoreArea}&StoreStatus=${StoreStatus}`, {
                method: "POST",
                body: JSON.stringify(bops)
            }).then(res => res.json())
                .then(data => {
                    if (!data.result) {
                        this.MessageBox.current.showMessageOk("AGGIORNAMENTO STORE/STORE AREA", data.msg ? data.msg : "Aggiornamento fallito", () => { this.setState({ isLoading: false })});
                    } else {
                        this.setState({
                            isLoading: false,
                            updateStores: false,
                            storeData: {
                                FIDStore: 0,
                                FIDStoreArea: 0,
                                Status: 0
                            }
                        },() => { 
                            this.grdStock.current.refresh(gridFilters) 
                        })
                    }
                    if (data.log) {
                        console.log(data.log);
                    }
                })
        });
    }
    handleRemoveStores = () => {
        this.MessageBox.current.showMessageYesNo("CONFERMA RIMOZIONE STORE/STORE AREA", "Si desidera rimuovere store e store area dalle distinte selezionate?", this.doRemoveStores);
    }
    doRemoveStores = () => {
        var gridFilters = this.grdStock.current.state.filters;
        var bops = this.grdStock.current.getSelectedData();
        this.setState({ isLoading: true }, () => {
            fetch('/BOP/DeleteBOPStores', {
                method: "POST",
                body: JSON.stringify(bops)
            }).then(res => res.json())
            .then(data => {
                if (!data.result) {
                    this.MessageBox.current.showMessageOk("RIMOZIONE STORE/STORE AREA", data.msg ? data.msg : "IMpossibile completare la rimozione degli store.", () => { this.setState({ isLoading: false })});
                } else {
                    this.setState({
                        isLoading: false,
                    },() => { 
                        this.grdStock.current.refresh(gridFilters) 
                    })
                }
                if (data.log) {
                    console.log(data.log);
                }
            })
        })
    }
    print = () => {
        var selectedRows = this.grdStock.current.getWrappedInstance().getSelectedData();
        this.grdStock.current.getWrappedInstance().clearSelection();
        this.setState({ print: true, printRows: selectedRows });
    }
    back = () => {

        this.setState({ print: false, printRows: [] });
    }
    changeView = (view) => () =>{
        this.setState({view});
    }
    render() {
        var { width } = this.props;
        var { print, printRows, view, isLoading, updateStores, storeData, showAvail, showBooked, showInVision, showAll, showCurrDest, showAllDests, showAllLicensees } = this.state;
        var customContent = undefined;
        var showFilterActivator = !print && !updateStores;
        var showColumnsSelector = !print && !updateStores;

        if (isLoading) {
            return (<XOXLoader />);
        }

        var navactions = [];
        navactions.push({ name: "Magazzino", icon: <ClipboardArrowRightOutline />, action: this.changeView("STOCK"), selected: view === "STOCK" });
        navactions.push({ name: "Riparazioni", icon: <ClipboardArrowLeftOutline />, action: this.changeView("REPAIR"), selected: view === "REPAIR" });
        
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }

        var label = <span><XNavigation actions={navactions} direction={direction} showLabel /></span>;

        var actions = [
            {
                group: 'actions',
                name: "Stampa",
                icon: <Printer />,
                action: this.print,
                condition: r => r.length > 0
            },
            /*{
                group: 'actions',
                name: 'Rimuovi store',
                icon: <RemoveShoppingCart />,
                action: this.handleRemoveStores,
                condition: r => r.length > 0 && r.filter(row => this.grdBOP.current.getRowData(row).FIDStore > 0).length > 0
            },
            {
                group: 'actions',
                name: 'Aggiorna store',
                icon: <Store />,
                action: this.handleUpdateStores,
                condition: r => r.length > 0 && !updateStores
            }*/
        ];

        if (print) {
            var s = "";
            for (var i = 0; i < printRows.length; i++) {
                s += "&FIDBOPs=" + printRows[i];
            }
            var url = "/BOP/PrintBOPTag/?" + s;
            console.log(url);
            customContent = <embed src={url} type="application/pdf" width="100%" height={document.body.offsetHeight - 259} />;
            actions = [{
                group: 'actions',
                name: "Indietro",
                icon: <ArrowLeft />,
                action: this.back
            }];
        } else if (updateStores) {
            customContent = (<Grid container spacing={2} style={{ width: "100%", padding: "20px" }}>
                    <Grid item xs={12} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            <Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} label="Store" id="FIDStore" urlData="/GC/stores"  value={storeData.FIDStore} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></ Grid>
                            <Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} label="Area" id="FIDStoreArea" urlData="/GC/storeareas" value={storeData.FIDStoreArea} filters={storeData.FIDStore ? [ { key: "FIDStore", value: storeData.FIDStore } ] : undefined} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                            <Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} label="Stato" id="Status" urlData="/enums/storepublishtype" value={storeData.Status} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.onStoreSelectChange} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>);
            
            actions = [{
                group: 'actions',
                name: 'Conferma',
                icon: <Check />,
                action: this.handleUpdateStoresConfirm
            },
            {
                group: 'actions',
                name: 'Annulla',
                icon: <ArrowLeft />,
                action: this.handleUpdateStoresCancel
            }];
        }
        var urlData = "/BOP/GetStock?grid=tblSTOCK";
        switch(view){
            case "STOCK":
                urlData += "&IsRepair=false";
				
				var fieldChangeAvailStatus = <XStepper label={getTranslation(4,"BOPS_AVAIL_STATUS",this.props.translations) || "Disponibilità"} step={showAvail} steps={[{label:getTranslation(4,"BOPS_AVAILABLE",this.props.translations) || "Disponibili"},{label:getTranslation(4,"BOPS_BOOKED",this.props.translations) || "Prenotate"},{label:getTranslation(4,"BOPS_INVISION",this.props.translations) || "Presso terzi"},{label:getTranslation(4,"BOPS_ALL",this.props.translations) || "Tutte"}]} onStepClick={this.onShowActiveClick}/>;
				var fieldChangeIdentitiesAvail = <XStepper label={getTranslation(4,"BOPS_LICENSEES_DESTS",this.props.translations) || "Filiali / licenziatari"} step={showCurrDest} steps={[{label:getTranslation(4,"BOPS_CURRDEST",this.props.translations) || "Filiale corrente"},{label:getTranslation(4,"BOPS_ALLDESTS_CURRLIC",this.props.translations) || "Tutte le filiali"},{label:getTranslation(4,"BOPS_ALLLICENSEES",this.props.translations) || "Tutti i licenziatari"}]} onStepClick={this.onShowAllChange} />;
				
				label = <span><span style={{marginTop:8, display: "inline-block"}}><XNavigation actions={navactions} direction={direction} showLabel /></span><span style={{ display: "inline-block", position: "relative", bottom: 8}}>{fieldChangeAvailStatus} {fieldChangeIdentitiesAvail}</span></span>;
		
				if(showAvail){
					urlData += `&ShowAvailOnly=${showAvail}`
				}
				else if(showBooked) {
					urlData += `&ShowBookedOnly=${showBooked}`;
				}
				else if(showInVision) {
					urlData += `&ShowAvailToOthers=${showInVision}`;
				}
				else if (showAll) {
					urlData += `&ShowAvailAll=${showAll}`;
				}
				
				if (showCurrDest) {
					urlData += `&FIDDestination=${this.props.params.FIDLicenseeDestination}`;
				} else if (showAllDests) {
					urlData += `&FIDIDentity=${this.props.params.FIDLicensee}`;
				} /*else if (showAllLicensees) {
					urlData += `&FIDIDentity=${this.props.params.FIDLicensee}`;
				}*/
				
                break;
            case "REPAIR":
                urlData += "&IsRepair=true";
                break;
        }


        return (
            <Fragment>
                <XGrid
                    key={view}
                    usePrimaryColor={true}
                    innerRef={this.grdStock}
                    label={label}
                    dataRoute={urlData}
                    dataRouteColumns="/Base/GetColumns?grid=tblSTOCK"
                    dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblSTOCK"
                    dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblSTOCK"
                    customContent={customContent}
                    actions={actions}
                    showColumnsSelector={showColumnsSelector}
                    showFilterActivator={showFilterActivator}
                    showLoader
                />
                <XMessageBox innerRef={this.MessageBox} />
            </Fragment>
        );
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    )
);

export default enhance(Stock);