import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { CustomField } from '../components/CustomField';
import XButton from '../components/XButton';

import { Done, Close } from '@material-ui/icons';

import XGrid from "../components/XGrid"

export class TableCustomField extends Component {
    state = {
        data: [],
        fields: [],
        isEditing: false,
        currId: 0,
    };

    componentDidMount() {
        this.loadFields();
        this.loadData();
    }
    loadData = () => {
    }
    loadFields = () => {
        var { field } = this.props;
        fetch('/Base/GetTableCFFields?FIDExtra=' + field.ID)
            .then(res => res.json())
            .then(data => this.setState({ fields: data }));
    }

    handleExtraFieldChange = (FIDExtra, isData) => (id) => (event, value) => {
        this.setState((state, props) => {
            var { data, fields, currId } = state;
            var { field } = props;
            var extraField = fields.filter(f => f.ID === FIDExtra)[0];

            var extra = {
                id: 0,
                FIDParentExtra: field.ID,
                BoolValue: null,
                ComboValue: null,
                DateTimeValue: null,
                ExtraID: FIDExtra,
                ExtraType: extraField.Type,
                GroupValue: null,
                IntValue: null,
                StringValue: null,
                TagValue: null,
                FIDDBExtra: currId,
            };
            if (data) {
                var extraFieldVal = data.filter(f => f.ExtraID === FIDExtra);
                if (extraFieldVal.length > 0) {
                    extra = extraFieldVal[0];
                }
            }
            extra.FIDDBExtra = currId;
            var index = -1;
            if (data) {
                index = data.indexOf(extra);
            }
            else {
                data = [];
            }
            switch (extra.ExtraType) {
                case 0://string
                    extra.StringValue = value;
                    break;
                case 1://integer
                    extra.IntValue = value;
                    break;
                case 2://decimal
                    extra.DecimalValue = value;
                    break;
                case 3://datetime
                    extra.DateTimeValue = value;
                    break;
                case 4://boolean
                    extra.BoolValue = value;
                    break;
                case 5://combobox
                    if (value.length > 0) {
                        extra.ComboValue = value[0].ID;
                    }
                    else {
                        extra.ComboValue = null;
                    }
                    break;
                case 6://tagbox
                    extra.TagValue = value;
                    break;
                case 7://component
                    //extra.StringValue = value;
                    break;
                case 8://componentcustomfields
                    //extra.StringValue = value;
                    break;
                default:
                    break;
            }
            if (index >= 0) {
                state.data[index] = extra;
            }
            else {
                if (!state.data) {
                    state.data = [];
                }
                state.data = state.data.concat(extra);
            }
            return state;
        });
    }
    handleNewButton = () => {
        this.setState({ isEditing: true, currId:0 });
    }
    handleEditButton = (event, item) => {
        var { loadDataUrl, mainId } = this.props;
        var url = new URL(loadDataUrl, (new URL(window.location.href)).origin);
        url.searchParams.set('id', mainId);
        url.searchParams.set('FIDExtra', item.ID);
        fetch(url.href)
            .then(res => res.json())
            .then(data => {
                this.setState({ data, isEditing: true, currId: item.ID });
            });
    }
    renderExtraField = (field, index) => {
        var { data } = this.state;

        var extra = [];
        if (data) {
            extra = data.filter(f => f.ExtraID === field.ID);
        }
        return (<CustomField key={index} field={field} extra={extra} handleExtraFieldChange={this.handleExtraFieldChange} />);
    }
    handleClose = () => {
        this.setState({ data: [], isEditing: false, currId:0 });
    }
    handleSave = () => {
        var { saveUrl, field } = this.props;
        var { data } = this.state;

        var url = new URL(saveUrl, (new URL(window.location.href)).origin);
        url.searchParams.set('id', field.ID);

        fetch(url.href, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => {
            this.setState({ data: [], isEditing: false, currId:0 });
        });
    }
    render() {
        var { isEditing, fields, } = this.state;
        var { field, mainId, loadGridUrl, deleteDataUrl } = this.props;

        var loadUrl = new URL(loadGridUrl, (new URL(window.location.href)).origin);
        loadUrl.searchParams.set('id', mainId);
        loadUrl.searchParams.set('FIDExtra', field.ID);
        loadUrl.searchParams.set('grid', "tblCFTable_" + field.ID);

        var deleteUrl = new URL(deleteDataUrl, (new URL(window.location.href)).origin);
        deleteUrl.searchParams.set('id', mainId);
        deleteUrl.searchParams.set('FIDExtra', field.ID);


        var content = isEditing ? (
            <Grid item container spacing={2} direction="row" sx={12}>
                {fields.map((f, index) => this.renderExtraField(f, index))}
                <Grid item container direction="row" xs={8} sm={4} md={2} justify="flex-end" alignItems="baseline">
                    <XButton variant="contained" color="primary" justIcon round size="sm" onClick={this.handleSave}>
                        <Done />
                    </XButton>
                    <XButton variant="contained" color="primary" justIcon round size="sm" onClick={this.handleClose}>
                        <Close />
                    </XButton>
                </Grid>
            </Grid>
        ) : (
                <Grid item xs={12} id={"gridCustomContainer" + field.ID}>
                    <XGrid
                        containerID={"gridCustomContainer" + field.ID}
                        dataRoute={loadUrl.href}
                        dataRouteColumns={"/Base/GetTableCFColumns?FIDExtra=" + field.ID + "&grid=tblCFTable_" + field.ID}
                        dataRouteAvailableColumns={"/Base/GetTableCFAvailableColumns?FIDExtra=" + field.ID + "&grid=tblCFTable_" + field.ID}
                        dataRouteUpdateColumns={`/Base/UpdateColumns?grid=tblCFTable_${field.ID}`}
                        dataRouteDelete={deleteUrl.href}
                        onNewButton={this.handleNewButton}
                        onEditButton={this.handleEditButton}
                        onDoubleClick={this.handleEditButton}
                        label={field.Description}
                    />
                </Grid>
            );
        return content;
    }
}