import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import { withStyles, Grid, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Check } from 'mdi-material-ui';
import numeral from 'numeral';
import XField from 'components/XField';
import Modal from 'components/Modal';
import { format } from 'utils';

const styles = theme => ({
    centerText: {
        textAlign: 'center',
    },
    
    popup: {
        position: "absolute",
        maxWidth: 350,
        width: "100%",
        left: "50%",
        top: "50%",
        zIndex: "10000",
        background: "#fff",
        boxShadow: "0px 1px 7px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        //border: "1px solid",
        padding: "10px",
        transform: "translate(-50%,-50%)",
        
    },
    popupBack: {
        position: "fixed",
        display: "block",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#999",
        opacity: 0.8,
        zIndex: "9999",
    },
    price: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "right",
    },
    priceText: {
        fontSize: "24px",
        fontWeight: "bold",
    },
});

class CatalogPrice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPrices: false,
        }
        this.el = document.createElement('div');
        this.modalRoot = document.getElementById('mainContent');
    }
    showPrices = () => {
        this.setState({ showPrices: true });
    }
    discountChange = (id) => (event, value) => {

        this.props.onDiscountChange && this.props.onDiscountChange(value);

        // this.setState((state, props) => {

        //     state.discount = value;
        //     //state.totalPrice = (state.data.Price * (100 - state.discount) / 100).round(2);
        //     return state;
        // });
    }
    priceChange = (id) => (event, value) => {

        var discount = (100 - (value * 100 / this.props.price)).round(4);
        this.props.onDiscountChange && this.props.onDiscountChange(discount);

        // this.setState((state, props) => {
        //     //state.totalPrice = value;
        //     state.discount = (100 - (value * 100 / props.price)).round(4);
        //     return state;
        // });
    }
    
    renderEditPrice = (discount, price) => {
        var { classes } = this.props;
        var totalPrice = (price * (100 - discount) / 100).round(2);
        return (
            <Modal>
                {this.state.showPrices && <span className={classes.popupBack}></span>}
                {this.state.showPrices &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}><XField label="Sc. %" id="discount" value={discount} onChange={this.discountChange} /></Grid>
                            <Grid item xs={12}><XField label="Totale" id="totalPrice" value={totalPrice} onChange={this.priceChange} /></Grid>
                            <Fragment>
                                <IconButton onClick={() => this.setState({ showPrices: false })}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showPrices: false, discount: 0 })}>
                                    <CloseIcon />
                                </IconButton>
                            </Fragment>
                        </Grid>
                    </div>}
            </Modal>
        );
    }
    render() {
        var { classes, hidePrice, price, priceB2B, currency, discount, supplierLabel, ...more } = this.props;
        var { showPrices } = this.state;
        if(showPrices){
            return this.renderEditPrice(discount, price);
        }
        if(typeof discount === "string"){
            try{
                discount = parseFloat(discount);
            }
            catch{
                discount = 0;
            }
        }
        var visPrice = "";
        var visPriceB2B = undefined;
        var discountedPrice = "";
        if(price){
            visPrice = format(price, currency);
            discountedPrice = format((price * (100 - discount) / 100).round(2),currency);
        }
        if(priceB2B){
            visPriceB2B = format(priceB2B,currency);
        }
        if(supplierLabel && discount === 0) {
            visPrice = supplierLabel
        }
        return (<Grid container xs={12} className={classes.marginTop + " " + classes.centerText} {...more}>
        { !hidePrice ?
            <Grid item container xs={12} className={classes.marginTop}>
                <Grid item xs={12}>
                    <Typography classes={{ root: classes.priceText }} style={discount > 0 ? { textDecoration: "line-through" } : {}  } color={discount > 0 ? "gray" : "primary"} onClick={this.showPrices}>{visPrice}</Typography>
                    {1==2 && priceB2B && <Typography classes={{ root: classes.priceText }} style={discount > 0 ? { textDecoration:   "line-through" } : {}} color={discount > 0 ? "gray" : "primary"}>{visPriceB2B}</Typography>}
                </Grid>
                {/* <Grid item xs={12}>
                    {discount > 0 && <Typography classes={{ root: classes.priceText }} color="primary" onClick={this.showPrices}>    {discount} %</Typography>}
                </Grid> */}
                <Grid item xs={12}>
                    {discount > 0 && <Typography classes={{ root: classes.priceText }} color="primary" onClick={this.showPrices}>{discountedPrice}</Typography>}
                </Grid>
                {
                    //showPrices && <Fragment>
                    //<Grid container item xs={12}>
                    //    <Grid item xs={4}><Typography classes={{ root: classes.priceText }} color="primary">Sc. %</Typography></Grid>
                    //    <Grid item xs={8}><XField id="discount" value={discount} onChange={this.discountChange} /></Grid>
                    //    <Grid item xs={12}><XField id="totalPrice" value={totalPrice} onChange={this.priceChange} /></Grid>
                    //</Grid>
                    //</Fragment>
                }
            </Grid> : 
            <Grid>
                <Typography>La variante richesta non è disponibile, crea una quotazione per avere più informazioni</Typography>
            </Grid>
        }
        {this.props.children}
    </Grid>);
    }
}

export default withStyles(styles)(CatalogPrice);