import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
});

class Camera extends Component {
    render() {
        return <SvgIcon {...this.props}>
		<g transform="matrix(0.08920607,0,0,0.08920607,-4.6190901,3.0124888)">
                <path
       id="path4"
       d="m 74.2,157.6 c 25.9,0 51.8,0 77.8,0 1.2,0 2.3,0.3 3.4,0.8 10.5,5.7 21.6,8.3 33.5,7.9 10.3,-0.3 19.9,-3 28.9,-7.8 1.1,-0.6 2.3,-0.9 3.6,-0.9 25.3,0 50.7,0 76,0 0.9,0 1.1,0.3 1.1,1.1 0,7.5 0,15.1 0,22.6 0,3 -2.5,5.5 -5.5,5.5 -71.1,0 -142.1,0 -213.2,0 -2.2,0 -4.3,-1.4 -5.2,-3.5 -0.1,-0.3 -0.1,-0.7 -0.4,-0.9 0,-8.2 0,-16.5 0,-24.8 z"
	   />
    <path
       id="path6"
       d="m 293.9,14.7 c 0.9,0.4 1.8,0.8 2.5,1.4 1.3,1.2 2,2.6 2,4.4 0,7.5 0,14.9 0,22.4 0,0.8 -0.2,1 -1,1 -25,0 -49.9,0 -74.9,0 -2,0 -3.7,-0.3 -5.6,-1.3 -11,-5.8 -22.8,-8.2 -35.2,-7.3 -9,0.6 -17.4,3 -25.3,7.3 -1.8,1 -3.6,1.4 -5.6,1.4 -25.1,-0.1 -50.1,0 -75.2,0 -0.5,0 -1,0 -1.5,0 0,-8.2 0,-16.3 0,-24.5 0.7,-2.4 2.3,-4 4.7,-4.7 71.8,-0.1 143.4,-0.1 215.1,-0.1 z m -9.5,14.6 c 0.1,-3.5 -3,-6.8 -6.4,-6.9 -4.1,-0.1 -7.3,2.7 -7.4,6.7 -0.1,3.9 2.9,7 6.8,7.1 3.9,0.1 7,-3 7,-6.9 z"
	   />
    <path
       id="path8"
       d="m 74.2,58.2 c 0.2,0 0.5,0 0.7,0 20.2,0 40.5,0 60.7,0 0.3,0 0.6,0 1.2,0 -10.4,12.4 -15.8,26.5 -15.8,42.6 0,16.1 5.3,30.2 15.8,42.7 -21,0 -41.8,0 -62.6,0 0,-28.5 0,-56.9 0,-85.3 z"
	   />
    <path
       id="path10"
       d="m 236.5,143.4 c 10.2,-12.5 15.6,-26.6 15.6,-42.7 0,-16.1 -5.5,-30.1 -15.6,-42.6 0.5,0 0.8,0 1.1,0 19.9,0 39.8,0 59.7,0 0.9,0 1.1,0.2 1.1,1.1 0,27.7 0,55.4 0,83.1 0,0.9 -0.2,1.1 -1.1,1.1 -19.9,0 -39.8,0 -59.7,0 -0.2,0 -0.5,0 -1.1,0 z"
	   />
    <path
       id="path12"
       d="m 196.8,149.7 c 2,-4 1.7,-7.9 -0.9,-11.7 2.3,-0.7 4.5,-1.3 6.7,-2.2 12.5,-5.2 20.6,-14.4 23.6,-27.6 2.5,-11.1 -0.3,-21.3 -7.3,-30.2 -6.9,-8.8 -16.2,-13.7 -27.2,-15 -14,-1.6 -26,2.6 -35.7,12.9 -6.1,6.4 -9.4,14.2 -9.8,23.1 -0.5,10.4 2.9,19.4 9.9,27 5.6,6.1 12.6,10 20.7,12 -1.1,1.8 -2,3.6 -2.2,5.7 -0.2,2.1 0.3,4 1.2,6 -1.8,-0.2 -3.3,-0.6 -4.8,-1 -16.6,-5.1 -28.8,-15.4 -34.5,-31.9 -6.4,-18.5 -2.4,-35.2 10.9,-49.5 8.1,-8.7 18.4,-13.8 30.1,-15.7 16.3,-2.5 31.1,1.2 43.9,11.6 9.9,8 16,18.4 17.7,31.1 2,15.3 -2.4,28.7 -13,39.9 -7.8,8.3 -17.4,13.4 -28.6,15.6 -0.1,0 -0.3,0 -0.4,0.1 -0.1,-0.2 -0.2,-0.2 -0.3,-0.2 z"
	   />
    <path
       id="path14"
       d="m 180.2,145 c 0,-3.2 2.7,-5.7 6.1,-5.7 3.5,0 6.1,2.5 6.1,5.8 0,3.2 -2.7,5.8 -6.1,5.8 -3.5,0 -6.2,-2.6 -6.1,-5.9 z"
	   />
	   </g>
            </SvgIcon>
    }
}
export default withStyles(style)(Camera);