import React, {Component} from 'react';

import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { ArrowRight, ArrowLeft } from 'mdi-material-ui';

const styles = theme => ({
	root: {
		width: "100%",
		height: "20.5vh"
	}
});

class PositionSelector extends Component {
	constructor(props) {
		super(props);

		this.grdPositions = React.createRef();
		this.MessageBox = React.createRef();
	}
	state = {
		data: {},
	};

	positionSelected = () => {
		var { onPositionSelected } = this.props;
		var { data } = this.state;
		var FIDPosition = data.ID;
		if (FIDPosition && onPositionSelected) {
			onPositionSelected(FIDPosition);
		}
	}
	loadPosition = () => {
		var selected = this.grdPositions.current.getSelectedData();
		if (selected.length > 1) {
			this.MessageBox.current.showMessageOk("Selezione posizioni", <Typography>Selezionate più posizioni. Per continuare selezionare una sola posizione.</Typography>);
			return;
		}
		else {
			var selectedData = this.grdPositions.current.getRowData(selected[0]);
			this.setState({ data: selectedData }, this.positionSelected);
		}
	}
	selectPosition = () => {
		this.loadPosition();
	}
	render() {
		var { classes, onCancel, positions } = this.props;
		var { data } = this.state;
		var previousStep = undefined;
		if (onCancel) {
			previousStep = <Tooltip title="Indietro">
				<IconButton aria-label="Indietro" onClick={onCancel} >
					<ArrowLeft />
				</IconButton>
			</Tooltip>;
		}
		var nextStep = <Tooltip title="Avanti">
			<IconButton aria-label="Avanti" onClick={this.selectPosition} >
				<ArrowRight />
			</IconButton>
		</Tooltip>;


		var customActions = [previousStep];
		var selectedActions = [nextStep];
		var dataRoute = "/Production/GetProductionPositionsGrid?grid=grdProductionPositions";
		if (positions) {
			for (var i = 0; i < positions.length; i++) {
				dataRoute += "&positions=" + positions[i];
			}
		}
		var dataRouteColumns = "/Base/GetColumns?grid=grdProductionPositions";
		var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=grdProductionPositions";
		var dataRouteUpdateColumns = "/Base/UpdateColumns?grid=grdProductionPositions";
		var onDoubleClick = undefined;
		var customContent = undefined;
		var showFilterActivator = true;
		var showColumnsSelector = true;
		var newAction = undefined;

		var label = "Seleziona una posizione";

		return (
			<div id="gridWrap" className={classes.root}>
				<XGrid
					containerID="gridWrap"
					innerRef={this.grdPositions}
					label={label}
					dataRoute={dataRoute}
					dataRouteColumns={dataRouteColumns}
					dataRouteAvailableColumns={dataRouteAvailableColumns}
					dataRouteUpdateColumns={dataRouteUpdateColumns}
					showFilterActivator={showFilterActivator}
					showColumnsSelector={showColumnsSelector}
					onNewButton={newAction}
					//dataRouteDelete={""}
					actions={customActions}
					selectedActions={selectedActions}
					customContent={customContent}
					onDoubleClick={onDoubleClick}
					preventSelectAll={true}
				/>
				<XMessageBox ref={this.MessageBox} />
			</div>
		);
	}
}
export default withStyles(styles)(PositionSelector);