import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withWidth } from '@material-ui/core';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { primaryColor, grayColor, colorForGrayBackground, colorForPrimaryBackground } from '../assets/jss/material-dashboard-pro-react';

import { compose } from 'redux';

const styles = theme => ({
    root: {
        position: "relative",
        display: "inline-flex"
    },
    fab: {
        width: 40,
        height: 40,
        margin: "8px 8px 8px",
        zIndex: 1050,
    },
    actions: {
        padding: "0 !important",
        margin: "0 !important",
        zIndex: 1049,
    },
    action: {
        margin: '8px 8px 8px',
    },
    actionMobile: {
        margin: '5px 8px',
    },
    actionsDown: {
        padding: 0,
        position: "absolute",
        top: 48,
    },
    actionSelected: {
        margin: '8px 8px 8px',
        backgroundColor: grayColor,
        color: colorForGrayBackground,
        '&:hover': {
            backgroundColor: colorForGrayBackground,
            color: grayColor,
        }
    },
    actionSelectedMobile: {
        margin: '5px 8px',
        backgroundColor: grayColor,
        color: colorForGrayBackground,
        '&:hover': {
            backgroundColor: colorForGrayBackground,
            color: grayColor,
        }
    },
    directionLeft: {
        marginRight: "10px !important",
        paddingRight: "0 !important",
        "& button": {
            position: "relative",
            bottom: 8
        }
    },
    directionRight: {
        marginLeft: "0 !important",
        paddingLeft: "0 !important",
        "& button": {
            position: "relative",
            bottom: 8
        },
		"& div span > span": {
			marginTop: "-5px"
		}
    },
    directionDown: {
        //marginTop: "0 !important",
        //paddingLeft: "0 !important",
        '&>#-actions::before': {
            content:'""',
            display: 'block',
            position: 'absolute',
            width: '1000vw',
            height: 'calc(100% + 30px)',
            left: '-100vw',
            top: -30,
            background: primaryColor,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        '&>#-actions.MuiSpeedDial-actionsClosed::before': {
            content:'',
            display: 'none',
        },
    },
    label: {
        position: 'absolute',
        top: '50%',
        left: 95,
        transform: 'translateY(-50%)',
    },
    tooltipBottom: {
        // padding: "1px 8px",
        // margin: "3px 0",
        display: 'none',
    },
    tooltipRight: {
        color: colorForPrimaryBackground,
        backgroundColor: 'unset',
    },
    
    labels: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        fontSize: 8,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        minHeight: 19
    },
    labelsClosed: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        fontSize: 8,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        minHeight: 19
    },
    
    labelsDown: {
        position: 'absolute',
        top: 45,
        left: 0,
        fontSize: 8,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        minHeight: 19
    },
    labelsLeft: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        fontSize: 8,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        minHeight: 19,
        height: 27
    },
    labelsClosedLeft: {
        position: 'absolute',
        bottom: 8,
        right: 0,
        fontSize: 8,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        minHeight: 19
    },
    labelItem: {
        width: 56,
        textAlign: 'center',
        display: 'inline-block',
        verticalAlign: 'middle',
        '& > span': {
            display: 'block !important',
            left: '50%',
            position: 'relative',
            transform: 'translateX(-50%) translateY(15px)',
            lineHeight: '10px',
        }
    },
    labelItemDown: {
        width: 56,
        textAlign: 'center',
        display: 'inline-block',
        verticalAlign: 'middle',
        '& > span': {
            display: 'block !important',
            left: '50%',
            position: 'relative',
            transform: 'translateX(-50%)',
            lineHeight: '10px',
        }
    }
});
class XNavigation extends Component {
    state = {
        open: true,
        hidden: false,
    };
    componentDidMount() {
        var { width, startClosed, forceDirection, actions, maxItemsForce } = this.props;
        
        forceDirection = forceDirection && actions.length <= maxItemsForce;
        if((width === "xs" && !forceDirection) || startClosed){
            this.setState({open: false});
        }
    }
    componentDidUpdate(props){
        if(props.actions.length != this.props.actions.length){
            var { width, forceDirection, maxItemsForce } = this.props;
            if(width === "xs" && forceDirection) {
                if(this.props.actions.length > maxItemsForce) {
                    this.setState({open: false});
                }
                else {
                    this.setState({open: true});
                }
            }
        }
    }

    handleVisibility = () => {
        this.setState(state => ({
            open: false,
            hidden: !state.hidden,
        }));
    };

    handleClick = () => {
        if(this.state.open){
            this.handleClose();
        }
        else {
            this.handleOpen();
        }
        // this.setState(state => ({
        //     open: !state.open,
        // }));
    };
    handleActionClick = (action) => () => {
        var { width, autoClose, forceDirection, actions, maxItemsForce} = this.props;
        
        forceDirection = forceDirection && actions.length <= maxItemsForce;
        if((width === "xs" && !forceDirection)  || autoClose){
            this.handleClick();
            action.action && action.action();
        }
        else {
            action.action && action.action();
        }
        
    };

    handleOpen = (event) => {
		if (event && (event.type === 'mouseenter' || event.type === 'focus')) {
			event.preventDefault();
			return false;
		}
		
        var { onOpen } = this.props;
        if (!this.state.hidden) {
            this.setState({
                open: true,
            });
            onOpen && onOpen();
        }
    };

    handleClose = (event, type) => {
        if (type === 'blur' || type === 'mouseLeave' || (event && (event.type === 'mouseleave' || event.type === 'blur'))) {
            return;
        }
        
        var { onClose } = this.props;
        this.setState({
            open: false,
        });
        onClose && onClose();
    };
    render() {
        const { classes, showLabel, width, menuLabel } = this.props;
        var { actions,direction,forceDirection,isHidden,maxItemsForce } = this.props;
        const { open } = this.state;
        if(!actions){
            actions = [];
        }
        if(width === "xs" && forceDirection && actions.length > maxItemsForce) {
            isHidden = false;
        }
        var hidden = !actions || actions.length === 0 || isHidden;
        var isMobile = false;
        forceDirection = forceDirection && actions.length <= maxItemsForce;

        if(width === "xs" && !forceDirection){
            direction = "down";   
            isMobile = true;
        }
        if (!direction) {
            direction = "right";
        }
        var clsActions = classes.actions;
        if (direction === "down") {
            clsActions = classes.actionsDown;
        }

        var selectedAction = actions.filter(w => w.selected)[0];
        var icon = selectedAction && selectedAction.icon;
        var label = selectedAction && selectedAction.name;
        if(!icon && this.props.icon){
            icon = this.props.icon;
        }
        if(!icon || open){
            icon = <SpeedDialIcon className={classes.stdDialIcon} />;
        }

        var labelsClass = direction === "right" ? classes.labels : classes.labelsLeft;
        if(isMobile || !open){
            labelsClass = direction === "right" ? classes.labelsClosed: classes.labelsClosedLeft;
        }
        if(direction === "down" ){
            labelsClass = classes.labelsDown;
        }

        return <Fragment>
            <SpeedDial ariaLabel='' direction={direction}
                classes={{
                    root: classes.root,
                    actions: clsActions,
                    fab: classes.fab,
                    directionLeft: classes.directionLeft,
                    directionRight: classes.directionRight,
                    directionDown: classes.directionDown,
                }}
                hidden={hidden}
                icon={icon}
                //onBlur={this.handleClose}
                //onClick={this.handleClick}
				onOpen={this.handleOpen}
                onClose={this.handleClose}
                //onFocus={this.handleOpen}
                onMouseEnter={(event) => { 
                    var e = event || window.event;
                    e.preventDefault();
                    return false; 
                }}
                onMouseLeave={(event) => { 
                    var e = event || window.event;
                    e.preventDefault();
                    return false;
                }}
                open={open}
            >
                {actions.map(action => {
                    var btnCls = isMobile ? classes.actionMobile : classes.action;
                    if (action.selected) {
                        btnCls = isMobile ? classes.actionSelectedMobile : classes.actionSelected;
                    }
					
					if (action.disabled) {
						return (<span style={{ width: 40, display: "inline-flex", margin: 8 }}></span>);
					}
					
                    return (
                        <SpeedDialAction
                            classes={{
                                fab: btnCls,
                                fabClosed: classes.buttonClosed,
                            }}
                            TooltipClasses={{
                                tooltipPlacementBottom: classes.tooltipBottom,
                                tooltipPlacementRight: classes.tooltipRight
                            }}
                            FabProps={{
                                size: "small"
                            }}
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipPlacement={isMobile ? "right" : "bottom"}
                            tooltipOpen={isMobile}
                            onClick={this.handleActionClick(action)}
                        />
                    );
                })}
                
                    <div className={labelsClass}>
                        {direction === "right" && !hidden && <span className={classes.labelItem}><span>{menuLabel}</span></span>}
                        {direction === "down" && !open && <span className={classes.labelItemDown}><span>{menuLabel}</span></span>}
                        {!isMobile && open && (
                            (direction === "left" ? actions.reverse() : actions).map(action => {
								if (action.disabled) {
									return (<span className={classes.labelItem}></span>);
								}
								
                                return (
									<span key={action.name} className={classes.labelItem}><span>{action.name}</span></span>
                                );
                            })
                        )}
                        {direction === "left" && !hidden && <span className={classes.labelItem}><span>{menuLabel}</span></span>}
                    </div>
                
            </SpeedDial>
            {showLabel && !open && <span class={classes.label}>{label}</span>}
            {showLabel && direction==="down" && open && <span class={classes.label}>{menuLabel}</span>}
        </Fragment>;
    }
}


XNavigation.propTypes = {
    maxItemsForce: PropTypes.number,
};
XNavigation.defaultProps = {
    maxItemsForce: 1
}


const enhance = compose(
    withWidth(),
    withStyles(styles)
);


export default enhance(XNavigation);

//export default withStyles(styles)(XNavigation);