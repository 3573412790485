import React, {Component} from 'react';

import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { ArrowRight, ArrowLeft, Check } from 'mdi-material-ui';

const styles = theme => ({
	root: {
		width: "100%",
		height: "calc(100vh - 200px)"
	}
});

class ProductionDepartmentSelector extends Component {
	constructor(props) {
		super(props);

		this.grdDepartments = React.createRef();
		this.MessageBox = React.createRef();
	}
	state = {
		data: {},
	};

	departSelected = () => {
		var { onDepartSelected } = this.props;
		var { data } = this.state;
		var FIDDepartment = data.ID;
		if (FIDDepartment && onDepartSelected) {
			onDepartSelected(FIDDepartment);
		}
	}
	loadDepartment = () => {
		var selected = this.grdDepartments.current.getSelectedData();
		if (selected.length > 1) {
			this.MessageBox.current.getWrappedInstance().showMessageOk("Selezione reparto", <Typography>Selezionati più reparti. Per continuare selezionare una solo reparto.</Typography>);
			return;
		}
		else {
			var selectedData = this.grdDepartments.current.getRowData(selected[0]);
			this.setState({ data: selectedData }, this.departSelected);
		}
	}
	selectDeprtment = () => {
		this.loadDepartment();
	}
	render() {
		var { classes, onCancel, departments } = this.props;
		var { data } = this.state;
		var previousStep = undefined;
		if (onCancel) {
			previousStep = {
				group: 'actions',
				name: 'Indietro',
				icon: <ArrowLeft />,
				action: onCancel
			};
				//<Tooltip title = "Indietro" >
				//<IconButton aria-label="Indietro" onClick={onCancel} >
				//	<ArrowLeft />
				//</IconButton>
				//</Tooltip>;
		}
		var nextStep = {
			group: 'actions',
			name: 'Conferma',
			icon: <Check />,
			action: this.selectDeprtment,
			condition: r => r.length === 1
		};
			//<Tooltip title="Conferma riconsegna">
			//<IconButton aria-label="Conferma riconsegna" onClick={this.selectDeprtment} >
			//	<Check />
			//</IconButton>
			//</Tooltip>;

		var customActions = [nextStep,previousStep];
		//var selectedActions = [nextStep];

		var dataRoute = "/Production/GetProductionDepartmentsGrid?grid=grdProductionDepartments";
		if (departments) {
			for (var i = 0; i < departments.length; i++) {
				dataRoute += "&departs=" + departments[i];
			}
		}
		var dataRouteColumns = "/Base/GetColumns?grid=grdProductionDepartments";
		var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=grdProductionDepartments";
		var dataRouteUpdateColumns = "/Base/UpdateColumns?grid=grdProductionDepartments";
		var onDoubleClick = undefined;
		var customContent = undefined;
		var showFilterActivator = true;
		var showColumnsSelector = true;
		var newAction = undefined;

		var label = "Seleziona un reparto";

		return (
			<div id="gridWrap" className={classes.root}>
				<XGrid
					containerID="gridWrap"
					innerRef={this.grdDepartments}
					label={label}
					dataRoute={dataRoute}
					dataRouteColumns={dataRouteColumns}
					dataRouteAvailableColumns={dataRouteAvailableColumns}
					dataRouteUpdateColumns={dataRouteUpdateColumns}
					showFilterActivator={showFilterActivator}
					showColumnsSelector={showColumnsSelector}
					onNewButton={newAction}
					//dataRouteDelete={""}
					actions={customActions}
					//selectedActions={selectedActions}
					customContent={customContent}
					onDoubleClick={onDoubleClick}
					preventSelectAll={true}
					rowHeight={84}
					//actionsInverted
				/>
				<XMessageBox ref={this.MessageBox} />
			</div>
		);
	}
}
export default withStyles(styles)(ProductionDepartmentSelector);