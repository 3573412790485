import React, { Component } from 'react';
//import { withStyles } from '@material-ui/core';
import withStyles from 'react-jss';

const style = {
    root: {
        position: 'absolute',
        background: '#fff',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transform: 'translate3d(0, 0, 0px) rotateY(180deg)',
        zIndex: 10,
        padding: 5,
        overflow: 'hidden',
    }
};


class Back extends Component {
    render(){
        var { classes, id } = this.props;
        return <div className={classes.root} id={id}>
            {this.props.children}
        </div>;
    }
}


export default withStyles(style)(Back);