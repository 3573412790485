import React, { Component } from 'react';
import CustomerCatalogue from '../../layouts/CustomerCatalogue';

class CustomerShopPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var { customerid, storeid, storeareaid } = this.props.match.params;
        return <CustomerCatalogue isCatalog={true} advancedNavigation={true} customer={customerid} storeid={storeid} storeareaid={storeareaid} />;
    }
}
export default (CustomerShopPage);