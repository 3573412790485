import React, { Component } from 'react';
import { withStyles,Grid } from '@material-ui/core';


import { getXSelect } from 'utils';

const styles = theme => ({
    root: {

    }
});


class ProductionPositionSelector extends Component {
    constructor(props){
        super(props);
        this.state= {
            data:undefined,
        };
    }
    componentDidMount(){
        this.setState({
            data: this.props.data   
        });
    }
    
	handleBOPProdFieldChange = (key) => (event, data) => {
		this.setState((state, props) => {

			state.data[key] = data.length > 0 ? data[0].ID : null;

			state.data = this.filterBOPProdFields(data[0], state.data, key);
			return state;
		});
    }
    
    render() {
        var { fields } = this.props;
        var { data } = this.state;
        var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
        var operatorFilters = [];
        if (data.FIDDepartment)
            operatorFilters.push({ key: "FIDDepartments", value: data.FIDDepartment, type: "array" });
        if (data.FIDPhase)
            operatorFilters.push({ key: "FIDPhases", value: data.FIDPhase, type: "array" });
        return <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
            <Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPosition", "Posizione di produzione", this.handleBOPProdFieldChange, "/Production/GetProductionPositions", "", { readOnly: false, filters: [], sm: 6, valueKey: "Position", /*linkedFields: [ "FIDDepartment", "FIDPhase", "FIDOperator" ]*/ })}</Grid>
            <Grid item xs = { 12} sm = { 6} md = { 3} > { getXSelect(data, fields, 12, 12, "FIDDepartment", "Reparto", this.handleBOPProdFieldChange, "/Production/GetProductionDepartments", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }], sm: 6, valueKey: "Department", /*linkedFields: ["FIDPhase", "FIDOperator"]*/ })}</Grid>
            <Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPhase", "Fase di lavorazione", this.handleBOPProdFieldChange, "/Production/GetProductionPhases", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }, { key: "FIDDepartment", value: data.FIDDepartment }], sm: 6, valueKey: "Phase", /*linkedFields: [ "FIDOperator" ]*/ })}</Grid>
            <Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDOperator", "Operatore", this.handleBOPProdFieldChange, "/Production/GetEmployees", "", { readOnly: false, filters: operatorFilters, sm: 6, valueKey: "Operator" })}</Grid>
        </Grid></div>;
    }
}

export default withStyles(styles)(ProductionPositionSelector);