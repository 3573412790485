import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import XField from './XField';
import QrReader from 'react-qr-reader';


import {
	primaryColor,
	primaryColorLight,
	colorForPrimaryBackground
} from "assets/jss/material-dashboard-pro-react.jsx";

const padTheme = {
	panel: {
		backgroundColor: '#fff'
	}
}
const styles = theme => ({
	mainWrapper: {
		width: "100%",
		position: "relative",
		top: "35px",
		height: "350px",
		"& > div": {
			width: "45%",
			textAlign: "center"
		}
	},
	vertCentered: {
		"& label": {
			fontSize: "18px",
			top: "0",
			left: "15px",
			textTransform: "uppercase"
		},
		"& input": {
			fontSize: "32px",
			padding: "6px 15px 7px"
		},
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)"
	},
	numpadWrapper: {
		padding: "30px 60px",
		boxShadow: "-1px -1px 10px #aaa",
		marginTop: "2.5%",
		marginLeft: "52.5%"
	},
	numpad: {
		fontSize: "18px",
		display: "block",
		"& > span": {
			width: "33%",
			display: "inline-block",
			padding: "15px 0",
			border: "1px solid #ccc",
			cursor: "pointer",
			fontWeight: "bold",
			userSelect: "none"
		}
	},
	halfWidthBtn: {
		"&:nth-of-type(2n)": {
			borderLeft: "0 !important"
		},
		width: "calc(50% - 2px) !important",
		borderTop: "0 !important"
	},
	fullWidthBtn: {
		width: "calc(100% - 4px) !important",
		borderTop: "0 !important"
	},
	topLeftBtn: {
		borderRight: "0 !important",
	},
	topRightBtn: {
		borderLeft: "0 !important"
	},
	middleLeftBtn: {
		borderRight: "0 !important",
		borderTop: "0 !important"
	},
	middleCenterBtn: {
		borderTop: "0 !important"
	},
	middleRightBtn: {
		borderLeft: "0 !important",
		borderTop: "0 !important"
	}
})
class XNumpad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			useLegacy: false,
		};
		this.QrReader = React.createRef();
	}
	componentDidMount = () => {
		var { fieldId } = this.props;

		var id = fieldId || "XNumpadField";

		if (document.getElementById(id))
			document.getElementById(id).focus();
		navigator.getUserMedia({ video: true },() =>{ this.setState({useLegacy: true})},() =>{this.setState({useLegacy: false})});
	}
	updateLinkedField = (event) => {
		var e = event || window.event;
		var { fieldId } = this.props;
		
		var currVal = this.state.value;

		if (currVal == null)
			currVal = "";

		var newChar = e.target.textContent;

		if (isNaN(newChar)) {
			if ("CA" === newChar)
				currVal = "";
			else if ("C" === newChar)
				currVal = currVal.substring(0, currVal.length - 1);
		}
		else
			currVal += newChar;

		this.setState({ value: currVal });
	}
	onBlur = (key) => (event) => {
		document.getElementById(key).focus();
	}
	onChange = (key) => (event, value) => {
		var { onFieldChange } = this.props;
		if (onFieldChange)
			this.setState({ value }, onFieldChange);
		else
			this.setState({ value });
	}
	onKeyDown = (key) => (event) => {
		if (event.keyCode === 13) {
			this.onSubmit();
		}
	}
	onSubmit = () => {
		var { onSubmit } = this.props;
		var { value } = this.state;
		onSubmit && onSubmit(value);
	}
	handleScan = (data) => {
		if(data){
			this.setState({value: data}, this.onSubmit);
		}
	}
	handleError  = (data) => {
		console.log(data);
	}
	selectImage = () =>{
		this.QrReader.current.openImageDialog();
	}
	render() {
		var { classes, fieldLabel, fieldId } = this.props;

		var id = fieldId || "XNumpadField";

		var { value,useLegacy } = this.state;
		return (
			<div className={classes.mainWrapper}>
				<div className={classes.vertCentered}>
					<QrReader
						style={{
							width: '50%',
							margin: 'auto'
						}}
						onClick={this.selectImage}
						ref={this.QrReader}
						facingMode="user"
						legacyMode={false}
						onError={this.handleError}
						onScan={this.handleScan}
					/>
					{/* <span onClick={this.selectImage} className={classes.fullWidthBtn}>Seleziona immagine</span> */}
					<XField style={{ marginLeft: "1.5%", marginTop: "5%", marginBottom: "-5%", width: "108.5%" }} label={fieldLabel} id={id} value={value} onChange={this.onChange} onBlur={this.onBlur} onKeyDown={this.onKeyDown}/>
				</div>
				<div className={classes.numpadWrapper}>
					<div className={classes.numpad}>
						<span onClick={this.updateLinkedField} className={classes.topLeftBtn}>7</span>
						<span onClick={this.updateLinkedField}>8</span>
						<span onClick={this.updateLinkedField} className={classes.topRightBtn}>9</span>
						<span onClick={this.updateLinkedField} className={classes.middleLeftBtn}>4</span>
						<span onClick={this.updateLinkedField} className={classes.middleCenterBtn}>5</span>
						<span onClick={this.updateLinkedField} className={classes.middleRightBtn}>6</span>
						<span onClick={this.updateLinkedField} className={classes.middleLeftBtn}>1</span>
						<span onClick={this.updateLinkedField} className={classes.middleCenterBtn}>2</span>
						<span onClick={this.updateLinkedField} className={classes.middleRightBtn}>3</span>
						<span onClick={this.updateLinkedField} className={classes.middleLeftBtn}>CA</span>
						<span onClick={this.updateLinkedField} className={classes.middleCenterBtn}>0</span>
						<span onClick={this.updateLinkedField} className={classes.middleRightBtn}>C</span>
						<span onClick={this.onSubmit} className={classes.fullWidthBtn}>&#10004;</span>
					</div>
				</div>
      </div>
    );
  }    
}
export default withStyles(styles)(XNumpad);