import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles,Grid, Typography, Tooltip,IconButton } from '@material-ui/core';
import { Translate }  from 'mdi-material-ui';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XField from 'components/XField';
import XSelect from 'components/XSelect';
import XExpansionPanel from 'components/XExpansionPanel';
import XFiles from 'components/XFiles';
import XDialog from 'components/XDialog';
import XSwitch from 'components/XSwitch';
import XDateField from 'components/XDateField';
import TranslateEditor from '../components/TranslateEditor';

const style = theme => ({
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}


class GenericEditor extends Component {
	constructor(props) {
		super(props);
		this.tabs = React.createRef();

		this.state = {
			data: {},
			dialog: {},
			firstLoad: true,
			snackbar: {
				open: false,
				message: undefined,
			},
		}
	}
	static contextTypes = {
		router: PropTypes.object
	}
	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		var { id } = this.props;
		if (id && parseInt(id, 10) > 0) {
			fetch(this.props.urlGetData + id)
				.then(res => res.json())
				.then(data => {
					//this.setState({ data });
					this.setState(state => {
						state.data = data;
						if (state.firstLoad) {
							state.initialData = JSON.parse(JSON.stringify(state.data));
						}
						state.firstLoad = false;
						return state;
					});
				});
		}
		else {
			this.setState(state => {

				if (state.firstLoad) {
					state.initialData = JSON.parse(JSON.stringify(state.data));
				}
				state.firstLoad = false;
				return state;
			});
		}
	}
	handleFieldChange = (key) => (event, value) => {
		this.setState((state, props) => {
			if (value !== undefined || value !== null || !isNaN(value)) {
				state.data[key] = value;
			}
			else {
				state.data[key] = undefined;
			}
			return state;
		});
	}
	handleSelectChange = (key) => (event, data) => {
		this.setState(state => {
			if (data.length > 0) {
				state.data[key] = data[0].ID;
			}
			else {
				state.data[key] = undefined;
			}
			return state;
		});
	}
	handleDateFieldChange = (key) => (value) => {
		this.setState((state, props) => {
			if (value !== undefined || value !== null || !isNaN(value)) {
				state.data[key] = value;
			}
			else {
				state.data[key] = undefined;
			}
			return state;
		});
	}
	handleParentSelectChange = (key) => (event, data) => {
		this.setState(state => {
			if (data.length > 0) {
				state.data[key] = data[0].ID;

				if (key == "FIDParent")
					state.data[this.props.FIDParentColumnName] = data[0].ID;
			}
			else {
				state.data[key] = undefined;

				if (key == "FIDParent")
					state.data[this.props.FIDParentColumnName] = undefined;
			}
			return state;
		});
	}
	handleSaveData = (event, caller, callback) => {
		this.doSaveData(callback);
	}
	doSaveData = (callback) => {
        var { AbbreviationKey, DescriptionKey, AbbreviationRequired } = this.props;
		var currentTab = this.tabs.current.getCurrentTab();
        if (!this.state.data[DescriptionKey] && !this.props.onlyCustomFields) {
			var buttons = [
				{
					label: "OK",
					action: () => {
						this.setState({ dialog: {} });
					}
				},
			];
			this.showDialog(buttons, "Alcuni campi non sono stati compilati", "Il campo Descrizione è obbligatorio");
			return;
		}
		
		if (AbbreviationRequired && !this.state.data[AbbreviationKey]) {
			var buttons = [
				{
					label: "OK",
					action: () => {
						this.setState({ dialog: {} });
					}
				},
			];
			this.showDialog(buttons, "Alcuni campi non sono stati compilati", "Il campo Sigla è obbligatorio");
			return;
		}
		this.setState({ isLoading: true }, () => {
			var { data } = this.state;
			var { id, FIDParentColumnName, urlSave, urlRedirect } = this.props;
			if (!id) {
				id = 0;
			}
			if (FIDParentColumnName) {
				data[FIDParentColumnName] = data["FIDParent"];
			}
			fetch(urlSave + id, {
				body: JSON.stringify(data),
				method: 'POST'
			}).then(this.handleErrors).then(res => res.json()).then((data) => {
				if (id === undefined || parseInt(id, 10) === 0) {
					var newId = data.id;
					if (!newId) {
						newId = data.ID;
					}
					
					if (callback) {
						this.setState({ snackbar: { open: true, message: "Salvataggio effettuato correttamente" } }, () => { setTimeout(callback, 1500); });
					} else {
						this.setState({ snackbar: { open: true, message: "Salvataggio effettuato correttamente" } }, 
							() => { 
								setTimeout(() => {
									this.context.router.history.push(urlRedirect + newId); 
								}, 1500);
							});
					}
				}
				this.setState({ firstLoad: true }, () => {
					this.loadData();
					this.setState({ isLoading: false, snackbar: { open: true, message: "Salvataggio effettuato correttamente" } }, () => {
						this.tabs.current.goToTab(currentTab);
						if (callback) {
							callback();
						}
					});
				});

			})
				.catch(error => {
					this.setState({ isLoading: false }, () => {
						this.tabs.current.goToTab(currentTab);
					});
				});
		});
	}
	hideDialog = () => {
		this.setState({ dialog: {} });
	}
	showDialog = (buttons, title, message) => {
		var dialog = {
			message,
			title,
			buttons,
			show: true
		};
		this.setState({ dialog });
	}

	doExit = () => {
		if (!this.props.urlClearSession) {
			this.context.router.history.push(this.props.urlExit);
			return;
		}
		//fetch('/BOP/ClearSession', {
		fetch(this.props.urlClearSession, {
			method: 'POST'
		}).then(res => {
			//this.props.setTitle(undefined);
			this.context.router.history.push(this.props.urlExit);
		});
	}
	doNew = () => {
		if (!this.props.urlClearSession) {
			this.context.router.history.push(this.props.urlNew);
			return;
		}
		fetch(this.props.urlClearSession, {
			method: 'POST'
		}).then(res => {
			//this.props.setTitle(undefined);
			this.context.router.history.push(this.props.urlNew);
		});
	}
	save = (event, callback) => {
		return new Promise(resolve => {

			this.handleSaveData(event, this, (data) => {
				callback();
				resolve(data);
			});
		});
	}
	handleExit = (event) => {
		this.checkForChanges(event, this,
			this.doExit,
			() => {
				this.save(event, this.doExit);
			},
			this.doExit,
			() => { }
		);
	}
	handleNewData = (event) => {
		this.checkForChanges(event, this,
			this.doNew,
			() => {
				this.save(event, this.doNew);
			},
			this.doNew,
			() => { }
		);
	}
	checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
		if ((JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
			this.manageHasChanges(callbackYes, callbackNo, callbackCancel);
		}
		else if (this.props.urlCheckChanges) {
			fetch(this.props.urlCheckChanges, {
				method: 'POST'
			}).then(res => res.json())
				.then(data => {
					if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
						this.manageHasChanges(callbackYes, callbackNo, callbackCancel);
					}
					else {
						callbackNoChanges();
					}
				});
		}
		else {
			callbackNoChanges();
		}
	}
	manageHasChanges(callbackYes, callbackNo, callbackCancel) {
		var buttons = [
			{
				label: "SI",
				action: () => {
					this.setState({ dialog: {} });
					callbackYes();
					//this.saveFile();
				}
			},
			{
				label: "NO",
				action: () => {
					this.setState({ dialog: {} });
					callbackNo();
				}
			},
			{
				label: "ANNULLA",
				action: () => {
					this.setState({ dialog: {} });
					callbackCancel();
				}
			},
		];
		this.showDialog(buttons, "Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?");
	}

	handleDelete = () => {
		if (this.props.onDeleteClick) {
			this.props.onDeleteClick();
		}
	}

	snackbarClose = () => {
		this.setState(state => {
				state.snackbar.open = false;
				return state;
		});
	}
	renderSnackbar() {
		var { snackbar } = this.state;
		return <Snackbar
				open={snackbar.open}
				onClose={this.snackbarClose}
				TransitionComponent={props => <Slide {...props} direction="up" />}
				ContentProps={{
						'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{snackbar.message}</span>}
				autoHideDuration={1500}
		/>;
	}

	renderTabGeneral = () => {
		var { data } = this.state;
		var { fields, onlyCustomFields, AbbreviationKey, DescriptionKey, AbbreviationRequired} = this.props;
		var parentColumn = this.props.FIDParentColumnName;
		var parentFIDValue = data.FIDParent;
		var parentUrlCreate = this.props.FIDParentUrlCreate;

		var panels = [
			{
				icon: "people",
				label: "Dati generali",
				content: (<Grid container spacing={2}>
					<Grid item container xs={12} direction="row" spacing={2}>
                        {!onlyCustomFields && <Grid item xs={12} sm={6} md={3}><XField label="Sigla" field={ AbbreviationRequired ? { Required: 2 } : { Required: 1 } } id={AbbreviationKey} value={data[AbbreviationKey]} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>}
                        {!onlyCustomFields && <Grid item xs={12} sm={6} md={3}><XField label="Descrizione" id={DescriptionKey} value={data[DescriptionKey]} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>}
                        {parentColumn && <Grid item xs={12} sm={6} md={3}><XSelect label={this.props.FIDParentColumnCaption} id="FIDParent" urlData={this.props.urlGetParents} urlCreate={parentUrlCreate} value={parentFIDValue} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleParentSelectChange} /></Grid>}
						{fields && fields.map((field, index) => {
							var { Required,label,key, getUrl, filter, fieldType, ...props } = field;
							switch (field.type) {
								case "XSelect":
									return <Grid item xs={12} sm={6} md={3}><XSelect field={{ Required: Required }} label={label} id={key} urlData={getUrl} value={data[key]}  onChange={this.handleSelectChange} {...props} filters={filter && filter(data)} /></Grid>;
								case "XField":
									return <Grid item xs={12} sm={6} md={3}><XField field={{ Required: Required }} label={label} id={key} value={data[key]} type={fieldType} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} { ..._.omit(props, "type")} /></Grid>;
								case "XSwitch":
									return <Grid item xs={12} sm={6} md={3}><XSwitch label={label} id={key} value={data[key]} onChange={this.handleFieldChange} {...props} /></Grid>;
								case "XDateField":
									return <Grid item xs={12} sm={6} md={3}><XDateField  field={{ Required: Required}} label={label} id={key} value={data[key]} onChange={this.handleDateFieldChange} {...props} /></Grid>;
								default:
									return <Grid item xs={12} sm={6} md={3}></Grid>;
							}
						})}
					</Grid>
				</Grid>),
			}];

		return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
	}
	renderTabFiles = () => {
		var { urlUploadFiles, urlGetFiles, urlDeleteFiles, urlCheckDefaultFiles, id } = this.props;
		return <XFiles urlUploadFile={urlUploadFiles} urlDeleteFiles={urlDeleteFiles} urlGetFiles={urlGetFiles} urlCheckDefaultFiles={urlCheckDefaultFiles} FID={id} />;
	}

  handleTranslate = () => {
      this.setState({ translateActive: true });
  }
  cancelTranslate = () => {
      this.setState({ translateActive: false });
  }
  closeTranslate = () => {
      this.setState({ translateActive: false },this.loadData);
  }
  renderTranslateEditor = () =>{
      var {routeTranslatableFields, routeLanguages, routeDataT, routeSaveData} = this.props;
      var {data} = this.state;
      return <TranslateEditor
		  hideUITab={true}
          routeTranslatableFields={`${routeTranslatableFields}${data.ID}`}
          routeLanguages={`${routeLanguages}${data.ID}`}
          routeDataT={`${routeDataT}${data.ID}`}
          routeSaveData={`${routeSaveData}${data.ID}`}
          data={data}
          // translatableFields={translatableFields}
          onCancel={this.cancelTranslate}
          onClose={this.closeTranslate}
      />;
  }

	render() {
        var { classes, onDeleteClick, urlUploadFiles, urlGetFiles, urlDeleteFiles, customTabs, translate } = this.props;
        var { translateActive, data } = this.state;
        if(translateActive){
            return this.renderTranslateEditor();
        }
        var actions = [];
        if(translate){
            actions.push(<Tooltip title="Traduzioni">
            <IconButton aria-label="Traduzioni" onClick={this.handleTranslate} >
                <Translate />
            </IconButton>
        </Tooltip>);
        }
		var loctabs = [
			{
				tabName: "Generale",
				tabContent: this.renderTabGeneral(),
                actions
			},
		];
		if (urlGetFiles && urlUploadFiles && urlDeleteFiles) {
			loctabs.push(
				{
					tabName: "Files",
					tabContent: this.renderTabFiles(),
                    actions
				});
		}
		if (customTabs) {
			loctabs = loctabs.concat(customTabs);
		}
		return (
			<div className={classes.root}>
				<Tabs
					innerRef={this.tabs}
					headerColor="primary"
					plainTabs={true}
					onExitClick={this.handleExit}
					onSaveClick={this.handleSaveData}
					onDeleteClick={(onDeleteClick && data && data.ID > 0 ? this.handleDelete : undefined)}
					onNewClick={this.handleNewData}
					tabs={loctabs}
				/>

				<XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
				{this.renderSnackbar()}
			</div>
		);
	}
}

GenericEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func,
    urlGetData: PropTypes.string,
    urlUploadFiles: PropTypes.string,
    urlGetFiles: PropTypes.string,
    urlDeleteFiles: PropTypes.string,
    urlCheckDefaultFiles: PropTypes.string,
    urlSave: PropTypes.string,
    urlRedirect: PropTypes.string,
    urlExit: PropTypes.string,
    urlCheckChanges: PropTypes.string,
    urlClearSession: PropTypes.string,
    customTabs: PropTypes.array,
    id: PropTypes.number,
    fields: PropTypes.array,
    onlyCustomFields: PropTypes.bool,
    FIDParentColumnName: PropTypes.number,
    FIDParent: PropTypes.number,
    AbbreviationKey: PropTypes.string,
    DescriptionKey: PropTypes.string,
    FIDParentColumnCaption: PropTypes.string,
    urlGetParents: PropTypes.string,
    FIDParentColumnCaption: PropTypes.string,
    FIDParentColumnCaption: PropTypes.string,
    FIDParentColumnCaption: PropTypes.string,
};
GenericEditor.defaultProps = {
    AbbreviationKey: "AbbreviationStr",
    DescriptionKey: "DescriptionStr",
}

export default withStyles(style)(GenericEditor);