import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"

import "../components/XReactDataGrid.css";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { ArrowLeft, Printer } from 'mdi-material-ui';
import XPDFViewer from 'components/XPDFViewer';



class IRUsers extends Component {
	constructor(props) {
		super(props);
		this.grdUsers = React.createRef();
		this.state = {
			userPrintBadge: false
		}
	}
	static contextTypes = {
		router: PropTypes.object
	}
	handleGridDoubleClick = (event, data) => {
		this.context.router.history.push('/IREdit/' + data.FIDIdentity + '?Users=true');
	}
	handleGridNew = (event) => {
		this.context.router.history.push('/IRNew');
	}
	handleUserPrintBadge = () => {
		this.setState({
			userPrintBadge: true
		});
	}
	render = () => {
		var { userPrintBadge } = this.state;
		var selectedActions = [<Tooltip title="Stampa badge">
			<IconButton aria-label="Stampa" onClick={this.handleUserPrintBadge} >
				<Printer />
			</IconButton>
		</Tooltip>];
		;
		var customContent = undefined;
		var customActions = [];
		if (userPrintBadge) {
			var printRows = this.grdUsers.current.getSelectedData();
			var s = "";
			for (var i = 0; i < printRows.length; i++) {
				var rowData = this.grdUsers.current.getRowData(printRows[i]);
				s += "&operators=" + rowData.FIDUser;
			}
			var url = "/Production/PrintOperatorBadge/?" + s;
			
			this.grdUsers.current.clearSelection();
			customContent = <XPDFViewer key="print_prod_operators" url={url} width="100%" height={document.body.offsetHeight - 350} />;
			customActions = [<Tooltip title="Indietro">
				<IconButton aria-label="Indietro" onClick={() => { this.setState({ userPrintBadge: false }) }}>
					<ArrowLeft />
				</IconButton>
			</Tooltip>];
		}

		return (
			<XGrid
				innerRef={this.grdUsers}
				usePrimaryColor={true}
				dataRoute="/IR/GetIRUsersWithIR?grid=tblIRUsersIR"
				dataRouteColumns="/Base/GetColumns?grid=tblIRUsersIR"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRUsersIR"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRUsersIR"
				//dataRouteDelete={"/IR/DeleteIR/"}
				onDoubleClick={this.handleGridDoubleClick}
				onEditButton={this.handleGridDoubleClick}
				onNewButton={this.handleGridNew}
				selectedActions={selectedActions}
				customContent={customContent}
				actions={customActions}
			/>
		);
	}
}

export default IRUsers;
