import React, {Component} from 'react';

import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { Check, Close } from 'mdi-material-ui';
import { compose } from 'redux';
import { withWidth } from '@material-ui/core';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const styles = theme => ({
});

class BOPSelector extends Component {
    constructor(props) {
        super(props);
        this.grdBOP = React.createRef();
        this.MessageBox = React.createRef();
    }

    handleCancel = () => {
      var { onCancel } = this.props;
      onCancel && onCancel();
    }
    handleBOPSelection = () => {
      var { preventMultipleSelection, onBOPSelectionConfirm } = this.props; 
      var selected = this.grdBOP.current.getWrappedInstance().getSelectedData();
      if ( preventMultipleSelection && selected.length > 1) {
          this.MessageBox.current.getWrappedInstance().showMessageOk("Selezione distinte", <Typography>Selezionate più distinte. Per continuare selezionare una sola distinta.</Typography>);
          return;
      }
      else {
          var selectedData = this.grdBOP.current.getWrappedInstance().getSelectedData();
          onBOPSelectionConfirm && onBOPSelectionConfirm(selectedData);
      }
    }

    render() {
        var { classes, label, gridName, currBOPToRelate, bopRelatedType } = this.props;
        
        var customActions = [
          {
              group: 'actions',
              name:  'Conferma',
              icon: <Check />,
              action: this.handleBOPSelection,
              condition: r => r.length > 0
          },
          {
              group: 'actions',
              name: 'Annulla',
              icon: <Close />,
              action: this.handleCancel
          }
        ];

        var dataRoute = "/BOP/GetData";
        var dataRouteColumns = "/Base/GetColumns";
        var dataRouteAvailableColumns = "/Base/GetAvailableColumns";
        var dataRouteUpdateColumns = "/Base/UpdateColumns";

        if (gridName) {
            dataRoute += '?grid=' + gridName;
            dataRouteColumns += '?grid=' + gridName;
            dataRouteAvailableColumns += '?grid=' + gridName;
            dataRouteUpdateColumns += '?grid=' + gridName;
        } else
        {
            dataRoute += '?grid=tblBOP';
            dataRouteColumns += '?grid=tblBOP';
            dataRouteAvailableColumns += '?grid=tblBOP';
            dataRouteUpdateColumns += '?grid=tblBOP';
        }

        if (currBOPToRelate && bopRelatedType) {
            dataRoute += '&relateds=true&currBOPToRelate=' + currBOPToRelate + '&relatedType=' + bopRelatedType;
        }

        var onDoubleClick = undefined;
        var customContent = undefined;
        var showFilterActivator = true;
        var showColumnsSelector = true;

        if (!label) {
          label = getTranslation(5,"GC_SELECTBOP",this.props.translations,"Selezione distinte");
        }

        return (
            <div className={classes.root}>
                <XGrid
                    ref={this.grdBOP}
                    label={label}
                    dataRoute={dataRoute}
                    dataRouteColumns={dataRouteColumns}
                    dataRouteAvailableColumns={dataRouteAvailableColumns}
                    dataRouteUpdateColumns={dataRouteUpdateColumns}
                    showFilterActivator={showFilterActivator}
                    showColumnsSelector={showColumnsSelector}
                    onNewButton={false}
                    //dataRouteDelete={""}
                    actions={customActions}
                    customContent={customContent}
                    onDoubleClick={false}
                    width={this.props.width}
                    showLoader
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}

const enhance = compose(
    withStyles(styles),
    withWidth(),
);
export default enhance(BOPSelector);