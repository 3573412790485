import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class PaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.Editor = React.createRef();
    }
    checkUsed = async (FIDPaymentMethod) => {
        return await fetch('/doc/paymentmethod/IsUsed/' + FIDPaymentMethod).then(res => res.json());
        
    }
    deletePaymentMethod = (FIDPaymentMethod) => {
        fetch('/doc/paymentmethod/Delete/' + FIDPaymentMethod, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = async () => {
        var id = this.props.match.params.id;

        var isUsed = await this.checkUsed(id);
        var buttons=[];
        var label = "Confermi la cancellazione della modalità di pagamento?";
        if (isUsed) {
            label = "La modalità di pagamento è utilizzata in documenti o anagrafiche. Non è possibile continuare con la cancellazione!";
            buttons = [
                {
                    label: "OK",
                    action: () => {
                        this.Editor.current.hideDialog();
                    }
                },
            ];
        }
        else {
            buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.Editor.current.hideDialog();
                        this.deletePaymentMethod(id);
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.Editor.current.hideDialog();
                    }
                },
            ];
        }
        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        var fields = [
            {
                key: 'Code',
                label: 'Sigla',
                type: 'XField',
            },
            {
                key: 'Description',
                label: 'Descrizione',
                type: 'XField',
            },
            {
                key: 'FIDPaymentType',
                label: 'Tipo pagamento',
                type: 'XSelect',
                getUrl: '/doc/paymenttype',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Description',
                abbreviationKey: 'Abbreviation',
            },
            {
                key: 'FIDSDICode',
                label: 'Codice SDI',
                type: 'XSelect',
                getUrl: '/Base/GetSDICodes?Table=PAYTYPE',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Description',
                abbreviationKey:'Code',
                Required: 2
            }
        ];

        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/doc/paymentmethod/Save/'
            urlDelete='/doc/paymentmethod/Delete/'
            urlRedirect='/PaymentMethod/'
            //urlUploadFiles='/BOP/LineFileUpload/'
            //urlDeleteFiles='/BOP/DeleteLineFile/'
            //urlGetFiles='/BOP/GetLineFiles/'
            //urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
            urlExit='/PaymentMethods/'
            urlNew="/PaymentMethod/new"
            urlGetData='/doc/paymentmethod/'
            urlCheckChanges='/doc/paymentmethod/HasChanges/'
            urlClearSession='/doc/paymentmethod/ClearSession/'
            onDeleteClick={this.onDeleteClick}
            onlyCustomFields={true}
            fields={fields}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(PaymentMethod);
