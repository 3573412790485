import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardIcon from "components/Card/CardIcon.jsx";
import Typography from '@material-ui/core/Typography';

import Icon from "@material-ui/core/Icon";
import classNames from 'classnames';

import { primaryColor, colorForPrimaryBackground } from '../assets/jss/material-dashboard-pro-react';

var styles = theme => ({
    root: {
        width:"100%",
    },
    summaryExpanded: {
        margin: "12px 0 !important",
    },
    expansionSummary: {
        minHeight: "46px !important",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    },
    expansionSummaryFocused: {
        backgroundColor: theme.palette.primary.main,
        color: colorForPrimaryBackground,
        minHeight: "46px !important",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    },
    expansionIcon: {
        color: "#fff",
    },
    expansionIconExpanded: {
        margin: "0 !important",
    },
    hidden: {
        display: 'none'
    }
});

class XExpansionPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ExpPan0: true,
            ExpPan1: false,
            ExpPan2: false,
            ExpPan3: false,
            ExpPan4: false,
            ExpPan5: false,
            ExpPan6: false,
            ExpPan7: false,
            ExpPan8: false,
            ExpPan9: false,
            ExpPan10: false,
            ExpPan11: false,
            ExpPan12: false,
            ExpPan13: false,
            ExpPan14: false,
            ExpPan15: false,
            ExpPan16: false,
            exp1Focus: null,
        };
    }
    componentDidMount() {
        var { allClosed,onExpansionChange } = this.props;
        if(allClosed){
            this.setState({ExpPan0: false},onExpansionChange);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.panels.length !== this.props.panels.length) {
            this.setState(state =>{
                for(var i =0;i<this.props.panels.length;i++) {
                    if(state[`ExpPan${i}`] === undefined) {
                        state[`ExpPan${i}`] = true;
                    }
                }
                return state;
            })
        }
    }
    
    handleExp1Change = panel => (event, expanded) => {
        var { onExpansionChange} = this.props;
        this.setState(state =>{
            state[`ExpPan${panel}`] = expanded;
            return state;
        }, () => {
            setTimeout(onExpansionChange, 300);
        });
    }
    handleExp1Focus = panel => (event) => {
        //alert("FOCUS PANEL" + panel);
        this.setState({ exp1Focus: panel });
    }
    handleExp1Blur = panel => (event) => {
        //alert("FOCUS PANEL" + panel);
        this.setState({ exp1Focus: null });
    }
    render() {
        var { exp1Focus } = this.state;
        var { classes, panels, id, noUnmount } = this.props;
        return (
            <div className={classes.root} id={id}>
                {panels.filter(w => w.content !== undefined).map((panel, index) => {
                    var isExpanded = this.state[`ExpPan${index}`];
                    var expClasses = {
                        root: (exp1Focus === index && isExpanded ? classes.expansionSummaryFocused : classes.expansionSummary),
                        focused: classes.expansionSummaryFocused,
                        expanded: classes.summaryExpanded,
                        expandIcon: classes.expansionIconExpanded
                    };
                    return (
                        <ExpansionPanel key={index} expanded={isExpanded} onChange={this.handleExp1Change(index)} onFocus={this.handleExp1Focus(index)} onBlur={this.handleExp1Blur(index)}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon classes={{ root: classes.expansionIconExpanded }} />} classes={expClasses}>
                                <CardIcon color={(exp1Focus === index && isExpanded ? "gray" : "primary")}>
                                    <Icon classes={{ root: classes.expansionIcon }}>{panel.icon}</Icon>
                                </CardIcon>
                                <Typography>{panel.label}</Typography>
                            </ExpansionPanelSummary>
                            {(isExpanded || noUnmount) &&
                                (
                                    <ExpansionPanelDetails className={!isExpanded && classes.hidden} id={panel.id} tabIndex={index}>
                                        {panel.content}
                                    </ExpansionPanelDetails>
                                )
                            }

                        </ExpansionPanel>
                    )
                })}
            </div>
            
        )
    }
}

export default withStyles(styles)(XExpansionPanel);