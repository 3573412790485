import React, { Component, Fragment } from 'react';
import { Checkbox, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit'
import { ChevronDown, ChevronUp } from 'mdi-material-ui';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XDateTimeField from 'components/XDateTimeField';
import withStyles from 'react-jss';

import color from 'color';
import classNames from 'classnames';

import { getScrollbarWidth } from 'utils';

import { grayColorLight, primaryColor } from '../assets/jss/material-dashboard-pro-react';
import { formatTime, formatDateTime } from '../utils';


const rowStyle = {
	selected: {
		backgroundColor: color(grayColorLight).hsl().alpha(0.2).string() + " !important",
	},
	root: {
		minWidth: props => props.width,
		height: props => (props.expanded ? props.rowHeight - props.expansionSize : props.rowHeight),//"42px",
		lineHeight: props => (props.expanded ? props.rowHeight - props.expansionSize : props.rowHeight) + "px",//"42px",
		overflowY: 'hidden',
		'&:nth-child(odd)': {
			backgroundColor: color(grayColorLight).hsl().alpha(0.05).string()
		},
		'&:after': {
			content: '" "',
			display: "block",
			width: "100%",
			height: "1px",
			backgroundColor: color(grayColorLight).hsl().alpha(0.2).string(),
		},
		'&:hover': {
			backgroundColor: color(grayColorLight).hsl().alpha(0.15).string() + " !important",
		}
	},
	editedRowWrap: {
		"& > svg": {
			position: "relative",
			top: "7px",
			left: "3.5px",
			fill: color(grayColorLight).string()
		}
	}
};

class XGridEditableRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false
		};
		//this.originalData = {};
	}
	componentDidMount = () => {
		this.originalData = this.props.data;
		//this.setState({ isSelected: this.props.isSelected });
	}
	componentDidUpdate = (oldProps) => {
	}
	expandRow = () => {
		// this.setState(state =>{
		//     state.isExpanded = !state.isExpanded;
		//     return state;
		// }, () =>{

		// });
		var { onRowExpand, rowIndex } = this.props;
		onRowExpand && onRowExpand(rowIndex);
	}
	onDateTimeChange = (key) => (value) => {
		var { columns } = this.props;
		var currDateTimeColumn = columns.find((col) => { return col.key === key });
		var durationColumn = columns.find((col) => { return currDateTimeColumn.dateTimeDurationField === col.key; })
		var newDateTimeValue = value && value._isAMomentObject && value._d;
		var oldDatetimeValue = new Date(this.props.data[key]);
		this.props.onChange(key, newDateTimeValue);

		if (durationColumn) {
			var oldDuration = this.props.data[durationColumn.key];
			var newDurationOffset = (newDateTimeValue.getTime() - oldDatetimeValue.getTime()) / 1000;

			if (!oldDuration)
				return;

			if (currDateTimeColumn.dateTimeStart)
				this.props.onChange(durationColumn.key, oldDuration - newDurationOffset);
			else if (currDateTimeColumn.dateTimeStop)
				this.props.onChange(durationColumn.key, oldDuration + newDurationOffset);
		}

	/*newDateTimeValue && this.setState(state => {
			var oldDatetimeValue = new Date(state.data[key]);
			state.data[key] = newDateTimeValue;

			if (durationColumn) {
				var newDurationOffset = (newDateTimeValue.getTime() - oldDatetimeValue.getTime()) / 1000;

				if (currDateTimeColumn.dateTimeStart)
					state.data[durationColumn.key] -= newDurationOffset;
				else if (currDateTimeColumn.dateTimeStop)
					state.data[durationColumn.key] += newDurationOffset;
			}
			return state;
		});*/
	}
	onFieldChange = (key) => (_, data) => {
		//this.setState(state => {
		//	state.data[key] = data[0][key];
		//	return state;
		//})
		this.props.onChange(key, data[0][key]);
	}
	onSelectChange = (key) => (_, data) => {
		var { columns } = this.props;
		var selectFieldColumn = columns.find((col) => { return col.comboFieldID === key });
		//this.setState(state => {
		//	state.data[key] = data[0][key];
		//	return state;
		//});
		this.props.onChange(key, data[0][key]);
		this.props.onChange(selectFieldColumn.key, data[0][selectFieldColumn.key]);

		if (selectFieldColumn && selectFieldColumn.editable && selectFieldColumn.editType === "combo" && selectFieldColumn.comboLinkedFields) {
			debugger;
		}
	}
	rowCheckedChange = () => {
		var { onSelectAll, data } = this.props;
		onSelectAll && onSelectAll(data.ID);

		//this.setState({
		//	isSelected: !isSelected
		//})
	}
	render() {
		var { data, isSelected, onSelectAll, columns, classes, onDoubleClick, width, customCells, rowHeight, style, viewType, customStyleFunction, expandFormat, expanded, expansionSize, emptyColumnEdit } = this.props;
		//var { isSelected } = this.state;

		//var { isExpanded } = this.state;

		var rowStyle = Object.assign({}, style);
		if (customStyleFunction) {
			rowStyle = customStyleFunction(rowStyle, data);
		}
		if (expanded) {
			rowHeight -= expansionSize;
		}
		return (
			<Fragment>
				<div style={rowStyle} key={data.ID} className={classNames({ [classes.selected]: isSelected, [classes.root]: true })} width={width} onDoubleClick={onDoubleClick(data)}>
					<span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, verticalAlign: "middle" }}>
						<Checkbox id={"sel_" + data.ID} checked={isSelected} onChange={onSelectAll(data.ID)} />
					</span>
					{emptyColumnEdit &&
						<span className={classes.editedRowWrap} style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, verticalAlign: "middle" }}>{data.isEdited ? <EditIcon /> : ""}</span>
					}
					{viewType == 'list' && <span style={{ minWidth: "84px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, textAlign: "center", verticalAlign: "middle" }}>{data.FileURL && <img style={{ maxWidth: 84, maxHeight: 84 }} src={data.FileURL} />}</span>}
					{columns.map(column => {
						var value = data[column.key];
						if ("boolean" === typeof value) {
							value = value ? "SI" : "NO";
						}
						if (customCells && customCells[column.key]) {
							var customCell = customCells[column.key];
							value = customCell(data, column);
						}
						else {
							if (value && (!isSelected || !column.editable)) {
								switch (column.type) {
									case "time":
										value = formatTime(value);
										break;
									case "datetime":
										value = formatDateTime(new Date(value))
										break;
									case "date":
										value = (new Date(value)).toLocaleDateString();
										break;
									case "image":
										value = <img src={value} />;
										break;
									case "bool":
										if ("boolean" !== typeof value) {
											value = value === "1" ? "SI" : "NO";
										}
										break;
									default:
										break;
								}
							}
						}
						//return (<XGridCell value={value} column={column} width={column.width} totalWidth={width} rowHeight={rowHeight} />);
						if (!isSelected || column.type === "image" || !column.editable || (column.comboExcludeValues && column.comboFieldID && column.comboExcludeValues.includes(data[column.comboFieldID]) || (data.preventChangeFields && data.preventChangeFields.includes(column.key))))
							return (<span key={column.key} style={{ width: column.width, height: rowHeight, display: 'inline-block', verticalAlign: "top", overflow: "hidden", paddingLeft: 10 }}>{value}</span>);
						else if (isSelected && column.editable) {
							if (column.type === "string" && column.editType === "string")
								return (<span key={column.key} style={{ width: column.width, height: rowHeight, display: 'inline-block', verticalAlign: "top", overflow: "hidden", paddingLeft: 10 }}><XField field={{ Required: 2 }} type={column.type} value={value} id={column.key} onChange={this.onFieldChange} /></span>);
							if (column.editType === "combo")
								return (<span key={column.key} style={{ width: column.width, height: rowHeight, display: 'inline-block', verticalAlign: "top", overflow: "hidden", paddingLeft: 10 }}><XSelect field={{ Required: 2 }} id={column.comboFieldID} urlData={column.getComboDataURL} value={data[column.comboFieldID]} idKey={column.comboFieldID} valueKey={column.key} onChange={this.onSelectChange} /></span>)
							if (column.type === "datetime")
								return (<span key={column.key} style={{ width: column.width, height: rowHeight, display: 'inline-block', verticalAlign: "top", overflow: "hidden", paddingLeft: 10 }}><XDateTimeField field={{ Required: 2 }} id={column.key} value={value} onChange={this.onDateTimeChange} /></span>)
						}
					})}
					{expandFormat && (
						<span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: rowHeight, minHeight: rowHeight, verticalAlign: "middle", position: "absolute", right: getScrollbarWidth() }}>
							<IconButton aria-label="Espandi" onClick={this.expandRow}>
								{expanded ? <ChevronUp fontSize="small" /> : <ChevronDown fontSize="small" />}
							</IconButton>
						</span>
					)}
				</div>
				{expanded && <div id={`gridExpandContainer_${data.ID}`} style={{ height: expansionSize, width: '100%', top: rowStyle.top + rowHeight, position: 'relative', padding: 10 }}>{expandFormat(data)}</div>}
			</Fragment>
		);
	}
}
export default withStyles(rowStyle)(XGridEditableRow);