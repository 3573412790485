import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
});

class FileDocumentBoxMultipleVarOutline extends Component {
    render() {
        return <SvgIcon {...this.props}>
            <path d="m 16.86292,14.13708 -7.0000096,0 0,-2 7.0000096,0 0,2 m 3,-4 -10.0000096,0 0,-1.9999904 10.0000096,0 0,1.9999904 m 0,-3.9999904 -10.0000096,0 0,-2 10.0000096,0 0,2 M 4.9476562,3.0523438 l 0,15.9999902 16.0000098,0 0,2 -16.0000098,0 c -1.1045695,0 -2,-0.89543 -2,-2 l 0,-15.9999902 2,0 M 21.86292,0.13709008 c 1.104569,0 2,0.89543002 2,1.99999952 l 0,13.9999904 c 0,1.11 -0.89,2 -2,2 l -14.0000096,0 c -1.1045695,0 -2,-0.895431 -2,-2 l 0,-13.9999904 c 0,-1.11 0.89,-1.99999952 2,-1.99999952 l 14.0000096,0 M 7.8629104,2.1370896 l 0,13.9999904 14.0000096,0 0,-13.9999904 z" id="path4" />
            <path d="m 2.1849298,5.8150706 0,15.9999954 16.0000022,0 0,2 -16.0000022,0 c -1.1045701,0 -2.00000018,-0.89543 -2.00000018,-2 l 0,-15.9999954 2.00000018,0" id="path4-6" />
        </SvgIcon>
    }
}
export default withStyles(style)(FileDocumentBoxMultipleVarOutline);