import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid";

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () =>({
});

class ShopAreas extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/shoparea/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/shoparea/new');
    }
    render() {
        return (
            <XGrid
                usePrimaryColor={true}
                dataRoute="/BOP/GetShopAreas?grid=tblGCStoreAreas"
                dataRouteColumns="/Base/GetColumns?grid=tblGCStoreAreas"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblGCStoreAreas"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblGCStoreAreas"
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
            />
        );
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(ShopAreas);
