import React, { Component, Fragment } from "react";
import { getXSelect } from 'utils';
import Grid from "@material-ui/core/Grid";
import XGrid from "components/XGrid";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { ArrowLeft, ArrowRight, Check, Close } from 'mdi-material-ui';
import XMessageBox from 'components/XMessageBox';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

const styles = theme => ({
	root: {
		width: "100%"
	}
});
class OperatorDepartsPhases extends Component {
	constructor(props) {
		super(props);
		this.MessageBox = React.createRef();
		this.grdOperatorDepartsAndPhases = React.createRef();

		this.state = {
			user: this.props.user,
			operatorDepartPhase: {},
			isDepartPhaseEdit: false
		}
	}
	handleOperatorDepartPhaseNew = () => {
		this.setState({ isDepartPhaseEdit: true, operatorDepartPhase: {} });
	}
	handleOperatorDepartPhaseCancel = () => {
		this.setState({ isDepartPhaseEdit: false, operatorDepartPhase: {} });
		this.grdOperatorDepartsAndPhases.current.refresh();
	}

	handleOperatorDepartPhaseSave = () => {
		var { operatorDepartPhase } = this.state;
		var error = false;
		var msgs = [];
		if (!operatorDepartPhase.FIDDepartment) {
			error = true;
			msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere!</Typography>);
		}

		if (!error) {
			this.saveOperatorDepartPhase();
		}
		else {
			this.MessageBox.current.showMessageOk('Inserimento/Modifica reparto e fase', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
		}

	}
	onOperatorDepartPhaseChange = (key) => (event, data) => {
		this.setState(state => {
			var oldValue = state.operatorDepartPhase[key];

			if (data.length > 0) {
				if (oldValue === data[0].ID)
					return state;

				state.operatorDepartPhase[key] = data[0].ID;
				if (["FIDPhase"].includes(key) && data[0].FIDDepartment > 0) {
					state.operatorDepartPhase["FIDDepartment"] = data[0].FIDDepartment;
				}
			}
			else {
				state.operatorDepartPhase[key] = undefined;
			}
			if (["FIDDepartment"].includes(key)) {
				if (state.operatorDepartPhase["FIDPhase"] >= 0)
					state.operatorDepartPhase["FIDPhase"] = undefined;
			}
			return state;
		});

	}
	/*onOperatorDepartPhaseOtherFieldChange = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.operatorDepartPhase[key] = data;
			}
			else {
				state.operatorDepartPhase[key] = undefined;
			}
			return state;
		});
	}*/
	handleOperatorDepartPhaseGridDoubleClick = (event, data) => {
		this.grdOperatorDepartsAndPhases.current.clearSelection();
		this.setState({ operatorDepartPhase: data, isDepartPhaseEdit: true });
	}

	saveOperatorDepartPhase = () => {

		var { operatorDepartPhase, user } = this.state;

		var FIDOperator = user.FIDUser;
		if (!FIDOperator) {
			FIDOperator = 0;
		}

		fetch('/prod/operator/CheckExistingDepartPhase?FIDOperator=' + FIDOperator + '&FIDDepartment=' + operatorDepartPhase.FIDDepartment + (operatorDepartPhase.FIDPhase ? "&FIDPhase=" + operatorDepartPhase.FIDPhase : "")).then(res => res.json())
			.then(data => {
				if (data.success) {
					fetch('/prod/operator/SaveOperatorDepartPhase/' + FIDOperator, {
						body: JSON.stringify(operatorDepartPhase),
						method: 'POST'
					})
						.then(res => res.json()).then(data => {
							this.setState({ isDepartPhaseEdit: false, operatorDepartPhase: {} });
							this.grdOperatorDepartsAndPhases.current.refresh();
						});
				} else {
					this.MessageBox.current.showMessageOk('Inserimento/Modifica reparto e fase', <Typography>Configurazione duplicata</Typography>);
				}
		})
	}
	render = () => {
		var { classes } = this.props;
		var { operatorDepartPhase, user, isDepartPhaseEdit } = this.state;

		var fields = [
			{ Field: "FIDDepartment", Visibility: true, Required: 2 },
			{ Field: "FIDPhase", Visibility: true, Required: 1 },
		];

		var fieldDepartment = getXSelect(operatorDepartPhase, fields, 12, 3, "FIDDepartment", "Reparto", this.onOperatorDepartPhaseChange, "/prod/depart/GetSelect", "", { readOnly: false, filters: [], sm: 6, valueKey: "Department" });
		var fieldPhase = getXSelect(operatorDepartPhase, fields, 12, 3, "FIDPhase", "Fase", this.onOperatorDepartPhaseChange, "/prod/phase/GetSelect", "", { readOnly: false, filters: [{ key: "FIDDepartment", value: operatorDepartPhase.FIDDepartment }], sm: 6, valueKey: "Phase" });

		var operatorDepPhaseFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					{fieldDepartment}
					{fieldPhase}
				</Grid>
			</Grid>
		</Grid>);

		var customContent = undefined;

		var doubleClick = this.handleOperatorDepartPhaseGridDoubleClick;
		var newClick = this.handleOperatorDepartPhaseNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [];
		if (isDepartPhaseEdit) {
			customContent = operatorDepPhaseFields;
			doubleClick = undefined;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			customActions = [
				(
					<Tooltip title="Salva">
						<IconButton aria-label="Salva" onClick={this.handleOperatorDepartPhaseSave} >
							<Check />
						</IconButton>
					</Tooltip>
				),
				(
					<Tooltip title="Annulla">
						<IconButton aria-label="Annulla" onClick={this.handleOperatorDepartPhaseCancel} >
							<Close />
						</IconButton>
					</Tooltip>
				)
			];
		}
		var ui = (
			<XGrid
				containerID="departsPhasesGrid"
				innerRef={this.grdOperatorDepartsAndPhases}
				dataRoute={"/prod/operator/GetDepartsPhases/?operatorID=" + (user.FIDUser || 0) + "&grid=grdOperatorDepartsPhases"}
				dataRouteColumns="/Base/GetColumns?grid=grdOperatorDepartsPhases"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdOperatorDepartsPhases"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdOperatorDepartsPhases"
				onDoubleClick={doubleClick}
				dataRouteDelete={"/prod/operatordepartphase/DeleteOperatorDepartsPhases/" + user.ID}
				onNewButton={newClick}
				onEditButton={doubleClick}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
				containerNoShadow={true}
				rowsVisible={5}
			/>
		)

		return (
			<div className={classes.root}><div id="departsPhasesGrid" style={{ width: "100%" }}><Grid container spacing={2} style={{ width: "100%" }}>
				{ui}
				</Grid>
				</div>
				<XMessageBox ref={this.MessageBox} />
			</div>
		);
	}
}
const enhance = compose(
	connect(
		state => state.global,
		dispatch => bindActionCreators(globalCreators, dispatch)
	),
	withStyles(styles)
);
export default enhance(OperatorDepartsPhases);