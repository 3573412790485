import React, { Component } from 'react';
//import { withStyles } from '@material-ui/core';
import withStyles from 'react-jss';
import classNames from 'classnames';

const style = {
    root: {
        perspective: "500px",
        height: props => props.height || 200,
        background: '#ccc'
    },
    inner: {
        height: props => props.height || 200,
        position: 'relative',
        transition: 'transform .4s linear',
        transform: 'rotate3d(0, 1, 0 , 0deg)',
        transformStyle: 'preserve-3d',
    },
    rotated: {
        transform: 'rotate3d(0, 1, 0, 180deg)'
    },
};


class FlipCard extends Component {
    render(){
        var { classes, rotated } = this.props;
        return (
            <div className={classes.root}>
                <div className={classNames(classes.inner, { [classes.rotated]: !!rotated})}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


export default withStyles(style)(FlipCard);