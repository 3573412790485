import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Tabs from '../../components/CustomTabs/CustomTabs';
import TabContainer from '../../components/CustomTabs/TabContainer';
import XMessageBox from '../../components/XMessageBox';
import XProgress from '../../components/XProgress';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    }
});

class ManualSyncro extends Component{
    constructor(props){
        super(props);
        this.state = {
            progressLabel: undefined,
            progress:0,
            total:0,
            isSyncing: false,
            cancelAction: undefined,
        };

        this.MessageBox = React.createRef();
    }

    cancelSyncIR = () => {
        fetch('/api/sync/v1/irs/StopSync', { method: 'POST'});
    }
    cancelSyncOrder = () => {
        fetch('/api/sync/v1/order/StopSync', { method: 'POST'});
    }
    checkSyncIR = () => {
        fetch('/api/sync/v1/irs/GetSyncProgress').then(res => res.json()).then(data => {
            this.setState({
                progress: data.item1,
                total: data.item2,
            });
        });
    }
    checkSyncOrder = () => {
        fetch('/api/sync/v1/order/GetSyncProgress').then(res => res.json()).then(data => {
            this.setState({
                progress: data.item1,
                total: data.item2,
            });
        });
    }

    handleMatchIR=()=>{
        var actionYes = () => {
            this.setState({
                isSyncing: true,
                total: 0,
                progress: 0,
                progressLabel: "Match anagrafiche",
                cancelAction: this.cancelSyncIR,
            },() => {
                this.interval = setInterval(this.checkSyncIR, 500);
                fetch("/api/sync/v1/irs/MatchIR",{
                    method: 'POST'
                }).then(res => res.json()).then(data =>{
                    clearInterval(this.interval);
                    this.setState({
                        isSyncing: false,
                        total: 0,
                        progress: 0,
                        progressLabel: undefined,
                        cancelAction: undefined,
                    });
                }).catch(error => {
                    console.log(error);
                    clearInterval(this.interval);
                    this.setState({
                        isSyncing: false,
                        total: 0,
                        progress: 0,
                        progressLabel: undefined,
                        cancelAction: undefined,
                    });
                    this.MessageBox.current.showMessageOk("Match anagrafiche","Errore non previsto");
                });
            });
        }

        this.MessageBox.current.showMessageYesNo("Match anagrafiche","Eseguire il match delle anagrafiche?", actionYes);

        
    }
    handleSyncIR=()=>{
        var actionYes = () => {
            this.setState({
                isSyncing: true,
                total: 0,
                progress: 0,
                progressLabel: "Sincronizzazione anagrafiche",
                cancelAction: this.cancelSyncIR,
            },() => {
                this.interval = setInterval(this.checkSyncIR, 500);
                fetch("/api/sync/v1/irs/PostCustomers",{
                    method: 'POST'
                }).then(res => res.json()).then(data =>{
                    if(!data.isSuccess){
                        this.MessageBox.current.showMessageOk("Sincronizzazione anagrafiche",data.message);
                    }
                    clearInterval(this.interval);
                    this.setState({
                        isSyncing: false,
                        total: 0,
                        progress: 0,
                        progressLabel: undefined,
                        cancelAction: undefined,
                    });
                }).catch(error => {
                    console.log(error);
                    clearInterval(this.interval);
                    this.setState({
                        isSyncing: false,
                        total: 0,
                        progress: 0,
                        progressLabel: undefined,
                        cancelAction: undefined,
                    });
                    this.MessageBox.current.showMessageOk("Sincronizzazione anagrafiche","Errore non previsto");
                });
            });
        }

        this.MessageBox.current.showMessageYesNo("Sincronizzazione anagrafiche","Eseguire la sincronizzazione manuale delle anagrafiche?", actionYes);
    }
    handleSyncOrder=()=>{
        var actionYes = () => {
            this.setState({
                isSyncing: true,
                total: 0,
                progress: 0,
                progressLabel: "Sincronizzazione ordini",
                cancelAction: this.cancelSyncOrder,
            },() => {
                this.interval = setInterval(this.checkSyncOrder, 500);
                fetch("/api/sync/v1/order/PostOrders",{
                    method: 'POST'
                }).then(res => res.json()).then(data =>{
                    if(!data.isSuccess){
                        this.MessageBox.current.showMessageOk("Sincronizzazione ordini",data.message);
                    }
                    clearInterval(this.interval);
                    this.setState({
                        isSyncing: false,
                        total: 0,
                        progress: 0,
                        progressLabel: undefined,
                        cancelAction: undefined,
                    });
                }).catch(error => {
                    console.log(error);
                    clearInterval(this.interval);
                    this.setState({
                        isSyncing: false,
                        total: 0,
                        progress: 0,
                        progressLabel: undefined,
                        cancelAction: undefined,
                    });
                    this.MessageBox.current.showMessageOk("Sincronizzazione ordini","Errore non previsto");
                });
            });
        }

        this.MessageBox.current.showMessageYesNo("Sincronizzazione ordini","Eseguire la sincronizzazione manuale degli ordini?", actionYes);
    }
    render() {
        var { classes } = this.props;
        var { progress, total, progressLabel, isSyncing, cancelAction } = this.state;

        var locTabs = [{
            tabName: "Sincronizzazione manuale",
            tabContent: (
                <TabContainer padding={8}>
                    <div>
                        <button onClick={this.handleMatchIR}>MATCH ANAGRAFICHE</button>
                    </div>
                    <div>
                        <button onClick={this.handleSyncIR}>SINCRONIZZA ANAGRAFICHE</button>
                    </div>
                    <div>
                        <button onClick={this.handleSyncOrder}>SINCRONIZZA ORDINI</button>
                    </div>
                </TabContainer>
            )
        }];

        return (
            <div className={classes.root}>
                 <Tabs
                    headerColor="primary"
                    plainTabs={true}
                    tabs={locTabs}
                />
                
                <XProgress progress={progress} total={total} label={progressLabel} onCancel={() => {
                    if (cancelAction) {
                        cancelAction();
                    }
                }} cancelLabel="Interrompi" show={isSyncing} />

                <XMessageBox innerRef={this.MessageBox} />
            </div>
        );
    }
}

export default withStyles(styles)(ManualSyncro);