import React from 'react';
import Dashboard from "views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import CatalogueIcon from "@material-ui/icons/Style";
import { BOPS } from "layouts/BOPS.jsx";
import Identities from "layouts/Identities.jsx";
import { IRUsers } from "layouts/IRUsers.jsx";
import DocumentsSearch from "layouts/Documents.jsx";
import Documents from "views/DOC/Documents.jsx";

import IdentitiesEdit from "layouts/IdentitiesEdit.jsx";
import BOPEdit from "layouts/BOPEdit.jsx";

import { CustomFields } from "layouts/CustomFields.jsx";
import CustomField from "layouts/CustomField.jsx";
import { CustomTabs } from "layouts/CustomTabs.jsx";
import CustomTab from "layouts/CustomTab.jsx";
import { CustomGroups } from "layouts/CustomGroups.jsx";
import CustomGroup from "layouts/CustomGroup.jsx";
//import Camera from "components/Icons/Camera.jsx";
//import ToConfig from "layouts/ToConfig.jsx";
import Catalogue from "layouts/Catalogue.jsx";
import ImportBOP from "layouts/ImportBOP.jsx";
import ImportIR from "layouts/ImportIR.jsx";
import Stock from "layouts/Stock.jsx";
import GDPR from "views/IR/GDPR.jsx";
import DOCEdit from "views/DOC/DOCEdit.jsx";
import Agreements from "views/IR/Agreements.jsx";

import Post from "views/CRM/Post.jsx";

import Brands from "views/BOP/Brands.jsx";
import Brand from "views/BOP/Brand.jsx";
import Lines from "views/BOP/Lines.jsx";
import Line from "views/BOP/Line.jsx";
import ShopAreas from "views/BOP/Shopareas.jsx";
import ShopArea from "views/BOP/Shoparea.jsx";

import DistributionLists from "views/IR/DistributionLists.jsx";
import DistributionList from "views/IR/DistributionList.jsx";
import Videos from "views/CRM/Videos.jsx";
import Pdf from "views/CRM/Pdf.jsx";
import Warranty from "views/DOC/Warranty.jsx";
import SellOut from "views/DOC/SellOut.jsx";
import ReturnGoods from "views/DOC/ReturnGoods.jsx";
import PaymentMethod from "views/DOC/PaymentMethod.jsx";
import PaymentMethods from "views/DOC/PaymentMethods.jsx";
import Position from "views/MAG/Position.jsx";
import Positions from "views/MAG/Positions.jsx";
import StockNew from "views/DOC/Stock.jsx";
import BusinessIntelligence from "views/BI/BusinessIntelligence";
import BOPStock from "views/BOP/BOPStock";
import Sales from "views/DOC/Sales";
import {Prenotations} from "views/BOP/Prenotations";

import Events from "views/Events/Events";
import EventEdit from "views/Events/EventEdit";
import EncryptConfig from "views/GC/EncryptConfig";
import { DOCStatus } from "views/DOC/DOCStatus";

import { Package, Newspaper, BookOpen, ShieldCheck, Receipt, RedoVariant, ChartAreaspline } from 'mdi-material-ui';
import { Camera } from 'components/Icons';

function Catalog(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={false} isCatalog={true} {...props} />);
}
function Presentation(props) {
    return (<Catalogue advancedNavigation={true} showExpansionPanel={true} isCatalog={false} {...props} />);
}
function Profile(props) {
    return (<IdentitiesEdit userProfile={true} {...props} />);
}

function News(props) {
    return (<Post postType={1} {...props} />);
}
function Adv(props) {
    return (<Post postType={2} {...props} />);
}


var dashRoutes = [
    {
        path: "/home",
        name: "Home",
        localizationCode:"MN_HOME",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
        canSwipe: true,
    },
    //{
    //    collapse: true,
    //    path: "-ir",
    //    name: "Anagrafiche",
    //    state: "openIdentities",
    //    icon: DashboardIcon,
    //    //component: Identities
    //    views: identities,
    //    requireLogin: true,
    //},
    {
        id: "irnew",
        path: "/IRNew",
        name: "Nuovo inserimento",
        localizationCode:"MN_HOME",
        icon: DashboardIcon,
        component: IdentitiesEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"ir",
        path: "/IR",
        name: "Anagrafiche",
        localizationCode:"MN_IR",
        icon: DashboardIcon,
        component: Identities,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"iredit",
        path: "/IREdit/:id",
        name: "Modifica anagrafica",
        localizationCode:"MN_EDITIR",
        icon: DashboardIcon,
        component: IdentitiesEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        path: "/profile/",
        name: "Profilo",
        localizationCode:"MN_PROFILE",
        icon: DashboardIcon,
        component: Profile,
        requireLogin: true,
        hidden: true,
    },
    //{
    //    collapse: true,
    //    path: "-ag",
    //    name: "Contratti",
    //    state: "openAgreements",
    //    icon: DashboardIcon,
    //    //component: Identities
    //    views: agreements,
    //    requireLogin: true,
    //},
    {
        id:"catalogueedit",
        path: '/catalogue/:id',
        name: 'Catalogo',
        localizationCode:"MN_CATALOGUE",
        icon: CatalogueIcon,
        component: Catalog,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "catalogueedit",
        path: '/presentation/:id',
        name: 'Presentazione',
        localizationCode:"MN_PRESENTATION",
        icon: CatalogueIcon,
        component: Presentation,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"presentation",
        path: '/presentation',
        name: 'Presentazione',
        localizationCode:"MN_PRESENTATION",
        icon: Camera,
        component: Presentation,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"catalogue",
        path: '/catalogue',
        name: 'Catalogo',
        localizationCode:"MN_CATALOGUE",
        icon: Camera,
        component: Catalog,
        requireLogin: true,
        canSwipe: true,
    },    {
        id:"documents",
        path: '/documents',
        name: 'Documenti',
        localizationCode:"MN_DOCUMENTS",
        icon: CatalogueIcon,
        component: DocumentsSearch,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"docnew",
        path: '/doc/new',
        name: 'Documenti',
        localizationCode:"MN_DOCUMENTS",
        icon: CatalogueIcon,
        component: DOCEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id: "returnbops",
        path: '/returnprod',
        name: 'Reso merci',
        localizationCode:"MN_RETURNGOODS",
        icon: RedoVariant,
        component: ReturnGoods,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"warranty",
        path: '/warranty',
        name: 'Garanzia',
        localizationCode:"MN_WARRANTY",
        icon: ShieldCheck,
        component: Warranty,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"sellout",
        path: '/sellout',
        name: 'Sell out',
        localizationCode:"MN_SELLOUT",
        icon: Receipt,
        component: SellOut,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id:"docedit",
        path: '/doc/edit/:id',
        name: 'Documenti',
        localizationCode:"MN_DOCUMENTS",
        icon: CatalogueIcon,
        component: DOCEdit,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"stock",
        path: '/stock/:id',
        name: 'Magazzino',
        localizationCode:"MN_STOCK",
        icon: Package ,
        component: BOPStock,
        //component: Stock,
        requireLogin: true,
        hidden: true,
    },
    {
        id:"stock",
        path: '/stock',
        name: 'Magazzino',
        localizationCode:"MN_STOCK",
        icon: Package ,
        component: StockNew,
        //component: Stock,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id: "news",
        path: "/news",
        name: "News",
        localizationCode:"MN_NEWS",
        icon: Newspaper,
        component: News,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id: "advertising",
        path: "/advertising",
        name: "Advertising",
        localizationCode:"MN_ADV",
        icon: BookOpen,
        component: Adv,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id: "messages",
        path: "/messages",
        name: "Messages",
        localizationCode:"MN_MESSAGES",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
        canSwipe: true,
    },
    {
        id: "tasks",
        path: "/Tasks",
        name: "Tasks",
        localizationCode:"MN_TASKS",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
        canSwipe: true,
    },
    
    {
        id: "messages",
        path: "/TranslateEditor",
        name: "Messages",
        localizationCode:"MN_MESSAGES",
        icon: DashboardIcon,
        component: Dashboard,
        requireLogin: true,
        canSwipe: true,
    },

    { redirect: true, path: "/", pathTo: "/home", name: "Home", localizationCode:"MN_HOME" }
];
export default dashRoutes;
