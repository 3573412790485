import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';

import XSelect from 'components/XSelect';

const styles = theme => ({
    root:{

    }
});


class PrintRecipe extends Component{

    constructor(props){
        super(props);

        this.state = {
            FIDLicensee: undefined,
            FIDDestination: undefined,
            FIDCashRegister: undefined,
        }
    }

    componentDidMount = () => {
        var { FIDLicensee, FIDDestination } = this.props;
        fetch(`/cash/GetCashRegisters?FIDLicensee=${FIDLicensee}&FIDDestination=${FIDDestination}`).then(res => res.json()).then(data => {
            var FIDCashRegister = undefined;
            if(data.length === 1){
                FIDCashRegister = data[0];
            }

            this.setState({FIDLicensee,FIDDestination,FIDCashRegister});
        });
    }
    handleSelectChange = (id) => (event, value) => {
        this.setState(state => {
            if(value.length > 0){
                state[id] = value[0].ID;
            }
            else {
                state[id] = undefined;
            }
            return state;
        });
    }

    printRecipe = async () => {
        var { FIDDocument } = this.props;
        var { FIDCashRegister } = this.state;
        if(!FIDCashRegister){
            return false;
        }
        return await fetch(`/cash/Print/${FIDDocument}?FIDCashRegister=${FIDCashRegister}`).then(res => res.json());
    }

    render() {
        var { classes } = this.props;
        var { FIDLicensee, FIDDestination, FIDCashRegister } = this.state;


        return (
            <Grid container spacing={2}>
                <Grid item spacing={2} item xs={12} sm={6} md={3}> 
                    <XSelect field={{ Required: 2 }} label={"Licenziatario"} id={"FIDLicensee"} urlData={"/IR/GetIRLicenseesSelect"} value={FIDLicensee}  onChange={this.handleSelectChange} idKey='ID' valueKey='Denomination' abbreviationKey='Abbreviation' subtitleKey='Address' />
                </Grid>
                <Grid item spacing={2} item xs={12} sm={6} md={3}> 
                    <XSelect field={{ Required: 2 }} label={"Sede"} id={"FIDDestination"} urlData={"/IR/GetIRLicenseeDestinationsSelect"} value={FIDLicensee}  onChange={this.handleSelectChange} idKey='ID' valueKey='Denomination' abbreviationKey='Abbreviation' subtitleKey='Address' filters={[{ key: "FIDIdentity", value: FIDLicensee }]} />
                </Grid>
                <Grid item spacing={2} item xs={12} sm={6} md={3}> 
                    <XSelect field={{ Required: 2 }} label={"Registratore"} id={"FIDCashRegister"} urlData={"/Cash/GetCashRegistersSelect"} value={FIDCashRegister}  onChange={this.handleSelectChange} idKey='ID' valueKey='Denomination' abbreviationKey='Abbreviation' subtitleKey='Address' filters={[{ key: "FIDLicensee", value: FIDLicensee,"FIDLicenseeDestination": FIDDestination }]}  />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(PrintRecipe);