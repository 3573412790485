import React, { Component, Fragment } from 'react';
import { withStyles, withWidth, Grid, Button, Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"
import XDateField from "components/XDateField";
import XButton from "components/XButton";
import { bindActionCreators, compose } from 'redux';
import { Legend, XAxis, YAxis, ZAxis, BarChart, CartesianGrid, Tooltip, Bar, PieChart, Pie, Sector, Cell, } from 'recharts';
import XMessageBox from 'components/XMessageBox';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from "perfect-scrollbar";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10, interpolateRainbow } from 'd3-scale-chromatic';
import moment from 'moment';

import XNavigation from '../../components/XNavigation';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { AutoSizer } from 'react-virtualized';
import XSelect from '../../components/XSelect';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';

import ReactHtmlParser from 'react-html-parser';
import XExpansionPanel from 'components/XExpansionPanel';
import { connect } from 'react-redux';
import { actionCreators as authCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';

import XField from 'components/XField';


//Icons
import { Plus, ChartPie, DownloadNetworkOutline } from 'mdi-material-ui';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import CakeIcon from '@material-ui/icons/Cake';
import SubjectIcon from '@material-ui/icons/Subject';
import VisibilityIcon from '@material-ui/icons/Visibility';
import classNames from 'classnames';
import XOXLoader from 'components/XOXLoader';
import { formatTimespan } from 'utils';

var ps;
const drawerWidth = 240;
// This variable is used to have a list of all controllers that are currently loading data, 
// in this .jsx is used to know which controllers need to be aborted when the user changes a tab
let controllersLoading = [];


const styles = theme => ({
    root: {
        textAlign: 'center',
        overflow: 'hidden',
    },

    card: {
        textAlign: 'center',
        borderRadius: 1,
    },

    card2: {
        textAlign: 'center',
        borderRadius: 0,
        padding: 20,
    },

    cardCategory: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000',
        marginTop: 10
    },

    gridStyle: {
        margin: '0',
    },

    loadButton: {
        "& > button": {
            position: 'sticky',
            left: '50%',
            transform: 'translate(-50%)'
        },
    },

    gridLoadButton: {
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
        textAlign: 'center',
    },

    tortaMain: {
        right: '50%',
        overflow: 'hidden',
    },

    torta: {
        position: 'relative',
        // transform: 'scale(1.5)',
        // left: '40%',
        bottom: '10%',
        fontSize: "x-small",
    },


    container: {
        width: '100%',
        //overflow: 'scroll !Important'
    },

    tooltipRoot: {
        backgroundColor: 'white',
        borderRadius: 1,
        borderWidth: 20,
        borderColor: '#fff',
        width: 'auto',
        height: 'auto',
        border: '25px',
        textAlign: 'left',
    },

    DenominationValue: {
        fontWeight: 'bold',
        color: '#141823',
        display: 'inline-block',
    },

    ViewsValue: {
        fontWeight: 'bold',
        color: '#F4B50D',
        textAlign: 'center',
    },

    StatisticsCL1: {
        fontWeight: 'bold',
        color: '#ffc658',
        textAlign: 'center',
    },

    StatisticsCL2: {
        fontWeight: 'bold',
        color: '#f4980d',
        textAlign: 'center',
    },

    StatisticsCL3: {
        fontWeight: 'bold',
        color: '#8884d8',
        textAlign: 'center',
    },
    
    StatisticsCL4: {
        fontWeight: 'bold',
        color: '#45b334',
        textAlign: 'center',
    },
    
    StatisticsCL5: {
        fontWeight: 'bold',
        color: '#34b37c',
        textAlign: 'center',
    },

    // Drawer 
    tabContainer: {
        overflowY: 'hidden',
    },

    drawer: {

        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
    },

    // drawerOpen: {
    //     flexShrink: 0,
    //   },

    drawerPaper: {
        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        top: '144px',
        height: 'calc(100% - 144px)',
        padding: 0,
        right: 0,
        zIndex: 1,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    tabContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: "100%",
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    tabContentOpen: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    contentShift: {

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },

    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        // width: "260px",
        zIndex: "4",
        overflowScrolling: "touch",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        color: "inherit",
        //paddingBottom: "30px"
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: "hidden !important"
    },

    noResult: {
        textAlign: "center",
        paddingTop: '6%',
        paddingBottom: '10%',
    },

    StatisticsInvoiceToolTip: {
        backgroundColor: 'white',
        borderRadius: 1,
        borderWidth: 20,
        width: 'auto',
        height: 'auto',
        textAlign: 'center',
        border: 25,
    },

    StatisticsInvoiceToolTipTable: {
        fontFamily: 'arial, sanse-serif',
        borderCollapse: 'collapse',
        width: 'auto',
        height: 'auto',
        width: '100%',
    },

    StatisticsInvoiceToolTipTableTDTH: {
        border: '1px solid #dddddd',
        textAlign: 'center',
        padding: 2
    },
    StatisticsInvoiceToolTipTableTR: {
        "&:nth-child(even)": {
            backgroundColor: '#dddddd'
        }
    }
});


const leftGraphicsFormatter = (value) => {
    if (value) {
        return `${formatTimespan(value)}`;
    }
    else {
        return "0h 0m 0s";
    }
}

const formatSeconds = (value) => {
    if (value) {

        return `${formatTimespan(value)}`
    }
    else {
        return "0m";
    }
}


class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: false,
                suppressScrollY: false
            });

        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    render() {
        const { className, children } = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {children}
            </div>
        );
    }
}


// This component will return a custom box where the active payload will be returned, gets disposed when the click is not on the G[0] [1] etc, the payload
// can't be debbuged with debbuger; or at last that's what I know.
const CustomTooltip = withStyles(styles)(({ active, payload, label, classes, type }) => {
    switch (type) {
        case "Barcode":
            if (active) {
                var code = payload && payload[0].payload.Code;
                var catalogCode = payload && payload[0].payload.CatalogCode;
                var barcode = payload && payload[0].payload.Barcode;
                var orderRowCode = payload && payload[0].payload.OrderRowCode;
                var duration = payload && payload[0].payload.Duration;
                var qty1 = payload && payload[0].payload.Qty1;
                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}>
                            Codice:{`${code}`}<br />
                            Codice catalogo:{`${catalogCode}`}<br />
                            Barcode:{`${barcode}`}<br />
                            Commessa:{`${orderRowCode}`}<br />
                            Q.tà 1:{`${qty1}`}<br />
                        </p>
                        <p className={classes.ViewsValue}>Durata: {payload && formatTimespan(payload[0].value)}</p>
                    </div>
                );
            }
            break;
        case "Departament":
            if (active) {
                var Department = payload && payload[0].payload.Department;
                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}>Code:{`${label}`} <br /> Reparto:{`${Department}`} </p>
                        <p className={classes.ViewsValue}>Media: {payload && formatTimespan(payload[0].value)}</p>
                    </div>
                );
            }
            break;
        case "Operator":
            if (active) {

                var Department = payload && payload[0].payload.Department;
                var Operator = payload && payload[0].payload.Operator;
                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}>Code:{`${label}`} <br /> Operatore:{`${Operator}`}</p>
                        <p className={classes.ViewsValue}>Media: {payload && formatTimespan(payload[0].value)}</p>
                    </div>
                );
            }
            break;
        case "OperatorDepartament":
            if (active) {
                var Department = payload && payload[0].payload.Department;
                var Operator = payload && payload[0].payload.Operator;
                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}> Code: {`${label}`} <br /> Operatore: {`${Operator}`}  <br /> Reparto: {`${Department}`}</p>
                        <p className={classes.ViewsValue}>Media: {payload && formatTimespan(payload[0].value)}</p>
                    </div>
                );
            }
            break;
        case "ValueStatisticsCatalogCode":
            if (payload[0]) {
                if (payload[0].payload) {
                    // console.log(payload[0].payload);
                    //
                }

                var CatalogCode = payload[0] && payload[0].payload["CatalogCode"];
                var TotalCost = payload[0] && payload[0].payload["TotalCost"];
                var TotalValue = payload[0] && payload[0].payload["TotalPrice"] // Totale Valore
                var Duration = payload[0] && payload[0].payload["Duration"];
                var Qty = payload[0] && payload[0].payload["Qty1"];
                var Media = payload[0] && payload[0].payload["Media"];

                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}>{`Codice Catalogo - ${CatalogCode} `}</p>
                        <p className={classes.StatisticsCL3}>{`Costo Totale - ${TotalCost} `}</p>
                        <p className={classes.StatisticsCL2}>{`Totale Valore - ${TotalValue} `}</p>
                        <p className={classes.StatisticsCL1}>{`Durata - ${formatSeconds(Duration)} `}</p>
                        <p className={classes.StatisticsCL4}>{`Quantita - ${Qty}`}</p>
                        <p className={classes.StatisticsCL5}>{`Media - ${formatSeconds(Media)}`}</p>
                    </div>
                );
            }
            break;
        case "ValueStatisticsCode":
            if (payload[0]) {
                var Code = payload[0] && payload[0].payload["Code"];
                var TotalCost = payload[0] && payload[0].payload["TotalCost"];
                var TotalValue = payload[0] && payload[0].payload["TotalPrice"] // Totale Valore
                var Duration = payload[0] && payload[0].payload["Duration"];
                var Qty = payload[0] && payload[0].payload["Qty1"];
                var Media = payload[0] && payload[0].payload["Media"];

                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}>{`Codice - ${Code} `}</p>
                        <p className={classes.StatisticsCL3}>{`Costo Totale - ${TotalCost} `}</p>
                        <p className={classes.StatisticsCL2}>{`Totale Valore - ${TotalValue} `}</p>
                        <p className={classes.StatisticsCL1}>{`Durata - ${formatSeconds(Duration)} `}</p>
                        <p className={classes.StatisticsCL4}>{`Quantita - ${Qty}`}</p>
                        <p className={classes.StatisticsCL5}>{`Media - ${formatSeconds(Media)}`}</p>
                    </div>
                );
            }
            break;
        case "InvoiceExp1":

            if (payload[0]) {
                // console.log("Payload:",payload);
                // console.log("Payload Position 0:", payload[0]);

                var payloadItem = payload[0].payload;


                var data = Object.keys(payloadItem).map(k => ({ key: k, value: payloadItem[k] }));

                var fixedKeys = ["SellBOPID", "ProdBOPID", "OrderCode", "TotalAmount", "Denomination", "Description", "DocumentDate", "Number"];

                //console.log(data);

                var items = data.map((item, index) => {

                    if (fixedKeys.includes(item.key)) {
                        return null;
                    }
                    if (item.key.includes("Cost")) {
                        return null;
                    }
                    // console.log("Data", data);
                    // console.log("Payload Item:",item.key ," : ", item.value);
                    // console.log("Payload Cost:",data[`${item.key} Costo`]);
                    // console.log("Payload Cost:",payloadItem[`${item.key} Cost`]);
                    //return <p className={classes.StatisticsCL1} key={index}>{item.key}: {item.value} - {payloadItem[`${item.key} Cost`]} {"Costo"}</p>

                    return <tr className={classes.StatisticsInvoiceToolTipTableTR}>
                        <td className={classes.StatisticsInvoiceToolTipTableTDTH} key={index}>{item.key}</td>
                        <td className={classes.StatisticsInvoiceToolTipTableTDTH} key={index}>{formatSeconds(item.value)}</td>
                        <td className={classes.StatisticsInvoiceToolTipTableTDTH} key={index}>{payloadItem[`${item.key} Cost`]}</td>
                    </tr>
                });
                // console.log(payloadItem);
                var DocumentDate;
                if (payloadItem.DocumentDate) {
                    DocumentDate = moment(new Date(payloadItem.DocumentDate));
                }
                return (
                    <div className={classes.StatisticsInvoiceToolTip}>
                        <p>{`${payloadItem.Denomination}`}</p>
                        <p>{`${payloadItem.Description} - ${payloadItem.Number} - ${DocumentDate.format("YYYY-MM-DD")}`}</p>
                        <p>{`Commessa - ${payloadItem.OrderCode}`}</p>
                        <p>{`Vendita - ${payloadItem.TotalAmount}`}</p>
                        <table className={classes.StatisticsInvoiceToolTipTable}>
                            <tr>
                                <th>Periodo</th>
                                <th>Durata</th>
                                <th>Costo</th>
                            </tr>
                            {items}
                        </table>
                    </div>
                );
            }
            break;
        case "InvoiceExp2": {
            if (payload[0]) {
                var payloadItem = payload[0].payload;


                var data = Object.keys(payloadItem).map(k => ({ key: k, value: payloadItem[k] }));

                var fixedKeys = ["SellBOPID", "ProdBOPID", "OrderCode", "TotalAmount", "ID", "Number", "Duration"];

                //console.log(data);

                var items = data.map((item, index) => {

                    if (fixedKeys.includes(item.key)) {
                        return null;
                    }
                    if (item.key.includes("Cost")) {
                        return null;
                    }
                    // console.log("Data", data);
                    // console.log("Payload Item:",item.key ," : ", item.value);
                    // console.log("Payload Cost:",data[`${item.key} Costo`]);
                    // console.log("Payload Cost:",payloadItem[`${item.key} Cost`]);
                    //return <p className={classes.StatisticsCL1} key={index}>{item.key}: {item.value} - {payloadItem[`${item.key} Cost`]} {"Costo"}</p>

                    return <tr className={classes.StatisticsInvoiceToolTipTableTR}>
                        <td className={classes.StatisticsInvoiceToolTipTableTDTH} key={index}>{item.key}</td>
                        <td className={classes.StatisticsInvoiceToolTipTableTDTH} key={index}>{item.value}</td>
                        <td className={classes.StatisticsInvoiceToolTipTableTDTH} key={index}>{payloadItem[`${item.key} Cost`]}</td>
                    </tr>
                });
                // console.log(payloadItem);
                return (
                    <div className={classes.StatisticsInvoiceToolTip}>
                        <p>{`Commessa - ${payloadItem.OrderCode}`}</p>
                        <p>{`Durata -  ${formatTimespan(payloadItem.Duration)}`} </p>
                        <p>{`Costo - ${payloadItem.HourCost}`}</p>
                        <table className={classes.StatisticsInvoiceToolTipTable}>
                            <tr>
                                <th>Periodo</th>
                                <th>Vendita</th>
                            </tr>
                            {items}
                        </table>
                    </div>
                );
            }
        }
            break;
        case "Stones":{
            if (active) {
                var operator = payload && payload[0].payload.Operator;
                var duration = payload && payload[0].payload.Duration;
                var stones = payload && payload[0].payload.StoneQuantity;
                return (
                    <div className={classes.tooltipRoot}>
                        <p className={classes.DenominationValue}>
                            Operatore:{`${operator}`}<br />
                            Durata:{`${formatTimespan(duration)}`}<br />
                            Pietre incassate:{`${stones}`}<br />
                        </p>
                    </div>
                );
            }
        }
            break;
    }
    return null;
});

class BIProd extends Component {
    constructor(props) {
        super(props);

        this.grdBIBarcodeDurationProd = React.createRef();
        this.grdBICodeMediaProd = React.createRef();
        this.grdBICodeMediaOperatorProd = React.createRef();
        this.grdBICodeMediaOperatorDepartamentProd = React.createRef();
        this.grdBICodeMediaDepartamentProd = React.createRef();
        this.grdBIValuesCode = React.createRef();
        this.grdBIValuesCodeCatalog = React.createRef();
        this.grdBIListActivity = React.createRef();
        this.grdBIProductionTrackOperator = React.createRef();
        this.grdBIINCStone = React.createRef();
        this.grdBIInvoice = React.createRef();
        this.grdBIInvoiceMonth = React.createRef();

        // GRD INVOICE

        this.XMessageBox = React.createRef();


        var dold = new Date();
        dold.setDate(dold.getDate() - 30);
        var dnew = new Date();
        dnew.setDate(dnew.getDate());

        this.state = {
            // Data For Filters
            fromStart: dold,
            toStop: dnew,
            OperatorINC: undefined,
            ProductionNumberValue: '0.00',
            InvoiceNumberValue: '0.00',
            Operator: undefined,
            Barcode: undefined,
            ParametersOpen: false, // Parameters Statistics
            ParamtersProductionOpen: false, // Parameters Production
            ParametersProductionValuesOpen: false, // Parameters Production Values
            ParametersListActivityOpen: false, // Parameters List Activity
            ParametersINCStone: false, // Parameters Incassatura
            ParametersDataInvoice: false, // Parameters Invoice

            // Data From API - Statistics
            BarcodeDurationResult: undefined,
            CodeMediaResultResult: undefined,
            CatalogCodeMedialResult: undefined,
            CodeMediaOperatorResult: undefined,
            CodeMediaOperatorDepartamentResult: undefined,
            CodeMediaDepartamentResult: undefined,

            // Data From API - Production
            TrackOperatorResult: undefined,

            // Data from API - Production Values
            ValuesCodeTimeResult: undefined,
            ValuesCatalogCodeTimeResult: undefined,

            // Data From API - Incassature
            INCStoneResult: undefined,

            // Data From API - Invoice
            DataInvoicePeriods: undefined,
            DataInvoicePeriodsMonths: undefined,

            // Data From API For Graphics - Invoice
            DataInvoiceResultPeriods: undefined,
            DataInvoiceResultPeriodsMonths: undefined,



            // Visuals Statistics
            viewBarcodeDurationType: "chart",
            viewCodeMediaType: "chart",
            viewCatalogCodeMediaType: "chart",
            viewCodeMediaOperatorType: "chart",
            viewCodeMediaDepartamentType: "chart",
            viewCodeMediaOperatorDepartamentType: "chart",
            viewValuesCodeType: "chart",
            viewValuesCatalogCodeType: "chart",
            viewTrackOperatorType: "chart",
            viewINCStoneType: "chart",
            viewDataInvoiceType: "chart",
            viewDataInvoiceMonthType: "chart",

            // Graphics 
            existingColors: [],


            // Handle User Movements
            currentTab: 0,
            firstLoad: true,
            loadingDataControllers: [],
            allowedSignals: [],

            // Loading States

            // Tab 1
            BarcodeDurationisLoading: true,
            CodeMediaisLoading: true,
            CatalogCodeMediaisLoading: true,
            CodeMediaOperatorisLoading: true,
            CodeMediaOperatorDepartamentisLoading: true,
            CodeMediaDepartamentisLoading: true,

            // Tab 2
            TrackOperatorisLoading: true,

            // Tab 4
            ValuesCodeisLoading: true,
            ValuesCatalogCodeisLoading: true,

            // Tab 5
            INCStoneisLoading: true,

            // Tab6
            GetInvoiceGraphicsisLoading: true,
            GetInvoiceisLoading: true,
            GetInvoiceMonthGraphicsisLoading: true,
            GetInvoiceMonthisLoading: true,
        };
    }


    componentDidMount = async () => {
        this.loadData();
        this.loadDataINC();
        this.loadDataInvoice();
        this.loadDataListActivity();
        this.loadDataProductionValues();
        this.loadDataProduction();
    }

    // #region UpdateData
    updateFilters = () => {
        var { ParametersOpen } = this.state;
        this.setState({ ParametersOpen: !ParametersOpen });
    }

    updateFiltersProduction = () => {
        var { ParamtersProductionOpen } = this.state;
        this.setState({ ParamtersProductionOpen: !ParamtersProductionOpen });
    }

    updateFiltersProductionValues = () => {
        var { ParametersProductionValuesOpen } = this.state;
        this.setState({ ParametersProductionValuesOpen: !ParametersProductionValuesOpen });
    }

    updateFiltersListActivity = () => {
        var { ParametersListActivityOpen } = this.state;
        this.setState({ ParametersListActivityOpen: !ParametersListActivityOpen });
    }

    updateFiltersINC = () => {
        var { ParametersINCStone } = this.state;
        this.setState({ ParametersINCStone: !ParametersINCStone });
    }

    updateFilterDataInvoice = () => {
        var { ParametersDataInvoice } = this.state;
        this.setState({ ParametersDataInvoice: !ParametersDataInvoice });
    }

    // #endregion UpdateData

    // #region General Functions 
    getWidth = () => {
        return document.getElementById("mainContent").clientWidth * 0.9;
    }


    SetView = (key, value) => () => {
        this.setState(state => {
            state[key] = value;
            return state;
        });
    }

    // #endregion End General Functions


    // #region onChangeFunctions

    onUserDataChange = (key) => (data) => {
        this.setState(state => {
            if (data) {
                state[key] = data;
            }
            else {
                state[key] = undefined;
            }
            return state;
        });
    }

    onUserChangeXSelect = (id) => (event, value) => {
        if (id) {
            this.setState(state => {
                if (value && value.length > 0) {
                    state[id] = value[0].ID;
                }
                else {
                    state[id] = undefined;
                }
                return state;
            });
        }
    }

    // This function can be used just for XField that contains numbers and dots NOT DONE
    onUserChangeXField = (id) => (event, value) => {
        String.prototype.IsNumber = function () { return /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/.test(this); }

        if (!value) {
            this.setState(state => {
                state[id] = undefined;
                return state;
            });
        }

        if (value && value.IsNumber()) {
            this.setState(state => {
                Math.round(value);
                if (value) {
                    state[id] = value;
                }
                else {
                    state[id] = undefined;
                }
                return state;
            });
        }
    }

    // #endregion OnChangeFunctions

    // Load Data
    loadData = async () => {
        var { fromStart, toStop, Operator, Barcode, viewBarcodeDurationType } = this.state;

        if (fromStart > toStop) {
            this.XMessageBox.current.showMessageOk(getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE", this.props.translations, "Statistiche catalogo"), <Typography variant="subtitle1" gutterBottom> {getTranslation(5, "GC_MSGBOX_STATUSCATALOGUE_ERROR", this.props.translations, " Range di date non valido")} </Typography>);
        }
        else {
            this.setState({
                BarcodeDurationResult: undefined,
                CodeMediaResultResult: undefined,
                CodeMediaOperatorResult: undefined,
                CodeMediaOperatorDepartamentResult: undefined,
                CodeMediaDepartamentResult: undefined
            });
            await this.GetBIProdData("BarcodeDuration");
            await this.GetBIProdData("CodeMedia");
            await this.GetBIProdData("CatalogCodeMedia");
            await this.GetBIProdData("CodeMediaOperator");
            await this.GetBIProdData("CodeMediaOperatorDepartament");
            await this.GetBIProdData("CodeMediaDepartament");
            await this.grdBIBarcodeDurationProd.current && this.grdBIBarcodeDurationProd.current.refresh();
            await this.grdBICodeMediaProd.current && this.grdBICodeMediaProd.current.refresh();
            await this.grdBICodeMediaOperatorProd.current && this.grdBICodeMediaOperatorProd.current.refresh();
            await this.grdBICodeMediaOperatorDepartamentProd.current && this.grdBICodeMediaOperatorDepartamentProd.current.refresh();
            await this.grdBICodeMediaDepartamentProd.current && this.grdBICodeMediaDepartamentProd.current.refresh();
        }
    }

    loadDataProduction = async () => {
        this.setState({
            TrackOperatorResult: undefined
        }, async () => {
            await this.GetBIProdData("TrackOperator");
            this.grdBIProductionTrackOperator.current && this.grdBIProductionTrackOperator.current.refresh();
        });
    }

    loadDataListActivity = async () => {
        this.grdBIListActivity.current && this.grdBIListActivity.current.refresh();
    }

    loadDataProductionValues = async () => {
        this.setState({
            ValuesCodeTimeResult: undefined,
            ValuesCatalogCodeTimeResult: undefined,
        }, async () => {
            await this.GetBIProdData("ValuesCode");
            await this.GetBIProdData("ValuesCatalogCode");
            this.grdBIValuesCode.current && this.grdBIValuesCode.current.refresh();
            this.grdBIValuesCodeCatalog.current && this.grdBIValuesCodeCatalog.current.refresh();
        });
    }

    loadDataINC = async () => {
        this.setState({
            INCStoneResult: undefined
        }, async () => {
            await this.GetBIProdData("INCStone");
            this.grdBIINCStone.current && this.grdBIINCStone.current.refresh();
        });
    }

    loadDataInvoice = async () => {
        this.setState({
            DataInvoicePeriods: undefined,
            DataInvoiceResultPeriods: undefined,
        }, async () => {
            await this.GetBIProdData("GetInvoiceGraphics");
            await this.GetBIProdData("GetInvoice");
            await this.GetBIProdData("GetInvoiceMonthGraphics");
            await this.GetBIProdData("GetInvoiceMonth");
            this.grdBIInvoice.current && this.grdBIInvoice.current.refresh();
        });
    }

    // Conditional & Mapping Render
    renderBarByMap = (value, index) => {
        return <Bar dataKey={value} fill="#266DC3" />
    }


    // Get DataTab
    GetBIProdData = async (getName) => {
        var { fromStart, toStop, Operator, Barcode, ProductionNumberValue, OperatorINC, InvoiceNumberValue } = this.state;
        var dataRoute = ``;

        // #region SwitchGet
        switch (getName) {
            case "BarcodeDuration":
                dataRoute = `/api/BIProd/GetBarCodeDuration/?top10=true`;
                this.setState({ BarcodeDurationisLoading: true });
                break;
            case "CodeMedia":
                dataRoute = `/api/BIProd/GetCodeMedia/?top10=true`;
                this.setState({ CodeMediaisLoading: true });
                break;
            case "CatalogCodeMedia":
                dataRoute = `/api/BIProd/GetCodeCatalogTMedia/?top10=true`;
                this.setState({ CatalogCodeMediaisLoading: true });
                break;
            case "CodeMediaOperator":
                dataRoute = `api/BIProd/GetCodeMediaOperator/?top10=true`;
                this.setState({ CodeMediaOperatorisLoading: true });
                break;
            case "CodeMediaOperatorDepartament":
                dataRoute = `api/BIProd/GetCodeMediaOperatorDepartament/?top10=true`;
                this.setState({ CodeMediaOperatorDepartamentisLoading: true });
                break;
            case "CodeMediaDepartament":
                dataRoute = `api/BIProd/GetCodeMediaDepartament/?top10=true`;
                this.setState({ CodeMediaDepartamentisLoading: true });
                break;
            case "TrackOperator":
                dataRoute = `api/BIProd/GetTrackOperator/?top10=true`;
                this.setState({ TrackOperatorisLoading: true });
                break;
            case "ValuesCode":
                dataRoute = `api/BIProd/GetValueCode/?top10=true&hourCost=${ProductionNumberValue}`;
                this.setState({ ValuesCodeisLoading: true });
                break;
            case "ValuesCatalogCode":
                dataRoute = `api/BIProd/GetValueCodeCatalog/?top10=true&hourCost=${ProductionNumberValue}`;
                this.setState({ ValuesCatalogCodeisLoading: true });
                break;
            case "INCStone":
                dataRoute = `api/BIProd/GetStoneTime/?top10=true&Operator=${OperatorINC}`;
                this.setState({ INCStoneisLoading: true });
                break;
            case "GetInvoiceGraphics":
                dataRoute = `api/BIProd/getGraphicPeriods/?top10=true`;
                this.setState({ GetInvoiceGraphicsisLoading: true });
                break;
            case "GetInvoice":
                dataRoute = `api/BIProd/GetInvoiceData/?top10=true&hourCost=${InvoiceNumberValue}`;
                this.setState({ GetInvoiceisLoading: true });
                break;
            case "GetInvoiceMonthGraphics":
                dataRoute = `api/BIProd/getGraphicPeriodsMonth/?top10=true`;
                this.setState({ GetInvoiceMonthGraphicsisLoading: true });
                break;
            case "GetInvoiceMonth":
                dataRoute = `api/BIProd/GetInvoiceDataMonth/?top10=true&hourCost=${InvoiceNumberValue}`;
                this.setState({ GetInvoiceMonthisLoading: true });
                break;
        }
        // #endregion SwitchGet

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters


        // #region FetchDataFromAPI
        var ControllerName = getName;
        window[ControllerName] = function () {
            return new AbortController();
        }

        var signal = window[ControllerName]().signal;

        controllersLoading.push(ControllerName);
        // var result = await fetch(dataRoute).then(res => res.json());
        var result = await fetch(dataRoute, { signal }).then(res => res.json()).catch(e => {
            if (e.name === "AbortError") {

            }
        });
        // #endregion FetchDataFromAPI

        switch (getName) {
            case "BarcodeDuration":
                this.setState({ BarcodeDurationResult: result }, () => {
                    this.setState({ BarcodeDurationisLoading: false });
                });
                break;
            case "CodeMedia":
                this.setState({ CodeMediaResult: result }, () => {
                    this.setState({ CodeMediaisLoading: false });
                });
            case "CatalogCodeMedia":
                this.setState({ CatalogCodeMedialResult: result }, () => {
                    this.setState({ CatalogCodeMediaisLoading: false });
                });
                break;
            case "CodeMediaOperator":
                this.setState({ CodeMediaOperatorResult: result }, () => {
                    this.setState({ CodeMediaOperatorisLoading: false });
                });
                break;
            case "CodeMediaOperatorDepartament":
                this.setState({ CodeMediaOperatorDepartamentResult: result }, () => {
                    this.setState({ CodeMediaOperatorDepartamentisLoading: false });
                });
                break;
            case "CodeMediaDepartament":
                this.setState({ CodeMediaDepartamentResult: result }, () => {
                    this.setState({ CodeMediaDepartamentisLoading: false });
                });
                break;
            case "TrackOperator":
                this.setState({ TrackOperatorResult: result }, async () => {
                    this.setState({ TrackOperatorisLoading: false });
                    await this.grdBIProductionTrackOperator.current && this.grdBIProductionTrackOperator.current.refresh();
                });
                break;
            case "ValuesCode":
                this.setState({ ValuesCodeTimeResult: result }, async () => {
                    await this.grdBIValuesCode.current && this.grdBIValuesCode.current.refresh();
                    this.setState({ ValuesCodeisLoading: false });
                });
            case "ValuesCatalogCode":
                this.setState({ ValuesCatalogCodeTimeResult: result }, async () => {
                    await this.grdBIValuesCodeCatalog.current && this.grdBIValuesCodeCatalog.current.refresh();
                    this.setState({ ValuesCatalogCodeisLoading: false });
                });
                break;
            case "INCStone":
                this.setState({ INCStoneResult: result }, async () => {
                    await this.grdBIValuesCodeCatalog.current && this.grdBIValuesCodeCatalog.current.refresh();
                    this.setState({ INCStoneisLoading: false });
                });
                break;
            case "GetInvoiceGraphics":
                this.setState({ DataInvoicePeriods: result }, () => {
                    this.setState({ GetInvoiceGraphicsisLoading: false });
                });
                break;
            case "GetInvoice":
                this.setState({ DataInvoiceResultPeriods: result }, async () => {
                    await this.grdBIInvoice.current && this.grdBIInvoice.current.refresh();
                    this.setState({ GetInvoiceisLoading: false });
                });
                break;
            case "GetInvoiceMonthGraphics":
                this.setState({ DataInvoicePeriodsMonths: result }, async () => {
                    this.setState({ GetInvoiceMonthGraphicsisLoading: false });
                });
                break;
            case "GetInvoiceMonth":
                this.setState({ DataInvoiceResultPeriodsMonths: result }, async () => {
                    await this.grdBIInvoiceMonth.current && this.grdBIInvoiceMonth.current.refresh();
                    this.setState({ GetInvoiceMonthisLoading: false });
                });
                break;
        }

    }

    // #region RenderTabWorkST
    renderBarcodeDuration = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewBarcodeDurationType, BarcodeDurationResult, ParametersOpen } = this.state;


        const colors = scaleOrdinal(schemeCategory10).range();
        var width = this.getWidth();
        // Update Panel Width
        if (ParametersOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }
        var content = undefined;
        if (BarcodeDurationResult && BarcodeDurationResult.length > 0) {
            if (viewBarcodeDurationType === "chart") {
                if (BarcodeDurationResult.length > 10) {
                    width = width * BarcodeDurationResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={BarcodeDurationResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" width={5} />
                        <XAxis dataKey="OrderRowCode" name="Codice d'ordine" />
                        <YAxis tickFormatter={leftGraphicsFormatter} width={125} />
                        <Tooltip content={<CustomTooltip type="Barcode" />} />
                        <Legend />
                        <Bar dataKey="Duration" name="Durata" fill="#D3D61E">
                            {
                                BarcodeDurationResult && BarcodeDurationResult.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 10]} animationDuration={15000} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>
        }

        var actions = [];
        if (BarcodeDurationResult && BarcodeDurationResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewBarcodeDurationType", "chart"), selected: viewBarcodeDurationType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewBarcodeDurationType", "grid"), selected: viewBarcodeDurationType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIBarcodeDurationProd";
        var dataRoute = `/api/BIProd/GetBarCodeDurationGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIBarcodeDurationProd}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.BarcodeDurationisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    // Render
    renderCodeMedia = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewCodeMediaType, CodeMediaResult, ParametersOpen } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (CodeMediaResult && CodeMediaResult.length > 0) {
            if (viewCodeMediaType === "chart") {
                if (CodeMediaResult.length > 10) {
                    width = width * CodeMediaResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={CodeMediaResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" name="Codice" />
                        <YAxis />
                        <Tooltip formatter={formatSeconds} />
                        <Legend />
                        <Bar dataKey="Media" fill="#D3D61E">
                            {
                                CodeMediaResult && CodeMediaResult.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 10]} animationDuration={15000} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (CodeMediaResult && CodeMediaResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewCodeMediaType", "chart"), selected: viewCodeMediaType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewCodeMediaType", "grid"), selected: viewCodeMediaType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBICodeMediaProd";
        var dataRoute = `/api/BIProd/GetCodeMediaGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBICodeMediaProd}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.CodeMediaisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    // Render
    renderCodeCatalogMedia = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewCatalogCodeMediaType, CatalogCodeMedialResult, ParametersOpen } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (CatalogCodeMedialResult && CatalogCodeMedialResult.length > 0) {
            if (viewCatalogCodeMediaType === "chart") {
                if (CatalogCodeMedialResult.length > 10) {
                    width = width * CatalogCodeMedialResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={CatalogCodeMedialResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="CatalogCode" name="Codice Catalogo" />
                        <YAxis />
                        <Tooltip formatter={formatSeconds} />
                        <Legend />
                        <Bar dataKey="Media" fill="#D3D61E">
                            {
                                CatalogCodeMedialResult && CatalogCodeMedialResult.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 10]} animationDuration={15000} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        }
        else {
            if (!this.state.CatalogCodeMediaisLoading) {
                content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
            }
        }

        var actions = [];
        if (CatalogCodeMedialResult && CatalogCodeMedialResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewCatalogCodeMediaType", "chart"), selected: viewCatalogCodeMediaType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewCatalogCodeMediaType", "grid"), selected: viewCatalogCodeMediaType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBICatalogCodeMediaProd";
        var dataRoute = `/api/BIProd/GetCodeCatalogTMediaGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelCatalogCodeMedia" className={classes.container}>
                <XGrid
                    innerRef={this.grdBICodeMediaProd}
                    containerID="panelCatalogCodeMedia"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.CatalogCodeMediaisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    // Render
    renderCodeMediaOperator = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewCodeMediaOperatorType, CodeMediaOperatorResult, ParametersOpen } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (CodeMediaOperatorResult && CodeMediaOperatorResult.length > 0) {
            if (viewCodeMediaOperatorType === "chart") {
                if (CodeMediaOperatorResult.length > 10) {
                    width = width * CodeMediaOperatorResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={CodeMediaOperatorResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" name="Codice" />
                        <Tooltip content={<CustomTooltip type="Operator" />} />
                        <Legend />
                        <Bar dataKey="Media" fill="#4d63b3">
                            {
                                CodeMediaOperatorResult && CodeMediaOperatorResult.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 10]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        } else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (CodeMediaOperatorResult && CodeMediaOperatorResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewCodeMediaOperatorType", "chart"), selected: viewCodeMediaOperatorType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewCodeMediaOperatorType", "grid"), selected: viewCodeMediaOperatorType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBICodeMediaOperatorProd";
        var dataRoute = `/api/BIProd/GetCodeMediaOperatorGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBICodeMediaOperatorProd}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.CodeMediaOperatorisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }


        return (
            content
        );
    }

    // Render
    renderCodeMediaDepartament = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewCodeMediaDepartamentType, CodeMediaDepartamentResult, ParametersOpen } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;

        if (CodeMediaDepartamentResult && CodeMediaDepartamentResult.length > 0) {
            if (viewCodeMediaDepartamentType === "chart") {
                if (CodeMediaDepartamentResult.length > 10) {
                    width = width * CodeMediaDepartamentResult.length / 10;
                }
                content = (
                    //<BarChart width={width} height={250} data={CodeMediaDepartamentResult} style={{ display: 'inline-block' }}>
                    <BarChart width={width} height={250} data={CodeMediaDepartamentResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip type="Departament" />} />
                        <Legend />
                        <Bar dataKey="Media" fill="#4d63b3">
                            {
                                CodeMediaDepartamentResult && CodeMediaDepartamentResult.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 10]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (CodeMediaDepartamentResult && CodeMediaDepartamentResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewCodeMediaDepartamentType", "chart"), selected: viewCodeMediaDepartamentType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewCodeMediaDepartamentType", "grid"), selected: viewCodeMediaDepartamentType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBICodeMediaDepartamentProd";
        var dataRoute = `/api/BIProd/GetCodeMediaDepartamentGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBICodeMediaDepartamentProd}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.CodeMediaDepartamentisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    // Render
    renderCodeMediaOperatorDepartament = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewCodeMediaOperatorDepartamentType, CodeMediaOperatorDepartamentResult, ParametersOpen } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (CodeMediaOperatorDepartamentResult && CodeMediaOperatorDepartamentResult.length > 0) {
            if (viewCodeMediaOperatorDepartamentType === "chart") {
                if (CodeMediaOperatorDepartamentResult.length > 10) {
                    width = width * CodeMediaOperatorDepartamentResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={CodeMediaOperatorDepartamentResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" />
                        <Tooltip content={<CustomTooltip type="OperatorDepartament" />} />
                        <YAxis />
                        <Legend />
                        <Bar dataKey="Media" fill="#4d63b3">
                            {
                                CodeMediaOperatorDepartamentResult && CodeMediaOperatorDepartamentResult.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 10]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (CodeMediaOperatorDepartamentResult && CodeMediaOperatorDepartamentResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewCodeMediaOperatorDepartamentType", "chart"), selected: viewCodeMediaOperatorDepartamentType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewCodeMediaOperatorDepartamentType", "grid"), selected: viewCodeMediaOperatorDepartamentType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBICodeMediaOperatorDepartamentProd";
        var dataRoute = `/api/BIProd/GetCodeMediaOperatorDepartamentGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBICodeMediaDepartamentProd}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.CodeMediaOperatorDepartamentisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    // #endregion RenderTabWorkST

    // #region RenderTabProductionST

    rendertrackOperator = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewTrackOperatorType, TrackOperatorResult, ParamtersProductionOpen } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParamtersProductionOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }


        var content = undefined;
        if (TrackOperatorResult && TrackOperatorResult.length > 0) {
            if (viewTrackOperatorType === "chart") {
                if (TrackOperatorResult.length > 10) {
                    width = width * TrackOperatorResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={TrackOperatorResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Operator" name="Operatore" label={{ fontSize: 5 }} />
                        <YAxis />
                        <Tooltip formatter={formatSeconds} />
                        <Legend />
                        <Bar dataKey="Work" name="Lavoro" fill="#ffc658" />
                        <Bar dataKey="Production" name="Produzione" stackId="prod" fill="#8884d8" />
                        <Bar dataKey="Pause" name="Pausa" stackId="prod" fill="#7a5369" />
                        <Bar dataKey="Riunion" name="Riunione" stackId="prod" fill="#82ca9d" />
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (TrackOperatorResult && TrackOperatorResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewTrackOperatorType", "chart"), selected: viewTrackOperatorType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewTrackOperatorType", "grid"), selected: viewTrackOperatorType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIProductionTrackOperator";
        var dataRoute = `/api/BIProd/GetTrackOperatorGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIProductionTrackOperator}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.TrackOperatorisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    // #endregion RenderTabProductionST

    // #region RenderTabProductionValues

    renderCodeValue = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewValuesCodeType, ValuesCodeTimeResult, ParametersProductionValuesOpen, ProductionNumberValue } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersProductionValuesOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (ValuesCodeTimeResult && ValuesCodeTimeResult.length > 0) {
            if (viewValuesCodeType === "chart") {
                if (ValuesCodeTimeResult.length > 10) {
                    width = width * ValuesCodeTimeResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={ValuesCodeTimeResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Code" name="Codice" label={{ fontSize: 5 }} />
                        <YAxis yAxisId="left" orientation="left" stroke="#ffc658" />
                        <YAxis yAxisId="cost" orientation="right" stroke="#8884d8" />
                        <Tooltip content={<CustomTooltip type="ValueStatisticsCode" />} />
                        <Legend />
                        <Bar yAxisId="left" dataKey="TotalPrice" name="Valore Totale" fill="#ffc658"/>
                        <Bar yAxisId="cost" dataKey="TotalCost" name="Costo Totale" fill="#8884d8" />
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (ValuesCodeTimeResult && ValuesCodeTimeResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewValuesCodeType", "chart"), selected: viewValuesCodeType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewValuesCodeType", "grid"), selected: viewValuesCodeType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIValuesCode";
        var dataRoute = `/api/BIProd/GetValueCodeGrid?grid=${gridName}&hourCost=${ProductionNumberValue}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIValuesCode}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.ValuesCodeisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }


    renderCodeCatalogValue = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewValuesCatalogCodeType, ValuesCatalogCodeTimeResult, ParametersProductionValuesOpen, ProductionNumberValue } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersProductionValuesOpen) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (ValuesCatalogCodeTimeResult && ValuesCatalogCodeTimeResult.length > 0) {
            if (viewValuesCatalogCodeType === "chart") {
                if (ValuesCatalogCodeTimeResult.length > 10) {
                    width = width * ValuesCatalogCodeTimeResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={ValuesCatalogCodeTimeResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="CatalogCode" name="Codice Catalogo" />
                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                        <YAxis yAxisId="cost" orientation="right" stroke="#82ca9d" />
                        <Tooltip content={<CustomTooltip type="ValueStatisticsCatalogCode" />} />
                        <Legend />
                        <Bar yAxisId="left" dataKey="TotalPrice" name="Valore Totale" fill="#ffc658" />
                        <Bar yAxisId="cost" dataKey="TotalCost" name="Costo Totale" fill="#8884d8" />
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (ValuesCatalogCodeTimeResult && ValuesCatalogCodeTimeResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewValuesCatalogCodeType", "chart"), selected: viewValuesCatalogCodeType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewValuesCatalogCodeType", "grid"), selected: viewValuesCatalogCodeType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIValuesCodeCatalog";
        var dataRoute = `/api/BIProd/GetValueCodeCatalogGrid?grid=${gridName}&hourCost=${ProductionNumberValue}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIValuesCodeCatalog}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.ValuesCatalogCodeisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }


    renderListActivity = () => {
        var { classes } = this.props;
        var { fromStart, toStop, ParametersListActivityOpen } = this.state

        var gridName = "grdBIListActivity";
        var dataRoute = `/api/BIProd/GetListActivityGrid?grid=${gridName}`;
        var content = "";
        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }
        // #endregion Filters

        content = (
            <div id="panelListActivity" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIListActivity}
                    containerID="panelListActivity"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    rowsVisible={10}
                //customContent={content}
                />
            </div>
        );

        return (
            content
        );
    }

    renderINCStone = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewINCStoneType, INCStoneResult, ParametersINCStone, OperatorINC } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersINCStone) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (INCStoneResult && INCStoneResult.length > 0) {
            if (viewINCStoneType === "chart") {
                if (INCStoneResult.length > 10) {
                    width = width * INCStoneResult.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={INCStoneResult} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Operator" name="Operatore" />
                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                        {/* <Tooltip formatter={formatSeconds} /> */}
                        <Tooltip content={<CustomTooltip type="Stones" />} />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Duration" name="Durata" fill="#8884d8" />
                        <Bar yAxisId="right" dataKey="StoneQuantity" name="Pietre" stackId="prod" fill="#82ca9d" />
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (INCStoneResult && INCStoneResult.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewINCStoneType", "chart"), selected: viewINCStoneType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewINCStoneType", "grid"), selected: viewINCStoneType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIINCStone";
        var dataRoute = `/api/BIProd/GetStoneTimeGrid?grid=${gridName}&Operator=${OperatorINC}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }
        // #endregion Filters

        content = (
            <div id="panelBarcodeDuration" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIINCStone}
                    containerID="panelBarcodeDuration"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.INCStoneisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    renderInvoiceData = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewDataInvoiceType, DataInvoiceResultPeriods, DataInvoicePeriods, ParametersDataInvoice } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersDataInvoice) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (DataInvoiceResultPeriods && DataInvoiceResultPeriods.length > 0) {
            if (viewDataInvoiceType === "chart") {
                if (DataInvoiceResultPeriods.length > 10) {
                    width = width * DataInvoiceResultPeriods.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={DataInvoiceResultPeriods} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="OrderCode" />
                        <YAxis />
                        {/* <Tooltip formatter={formatSeconds} /> */}
                        <Tooltip content={<CustomTooltip type="InvoiceExp1" />} />
                        <Legend />
                        {
                            DataInvoicePeriods && DataInvoicePeriods.map((value, index) => (
                                <Bar dataKey={value} name={value} fill={colors[index % 10]} width={550} />
                            ))
                        }
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (DataInvoiceResultPeriods && DataInvoiceResultPeriods.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewDataInvoiceType", "chart"), selected: viewDataInvoiceType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewDataInvoiceType", "grid"), selected: viewDataInvoiceType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIInvoice";
        var dataRoute = `/api/BIProd/GetInvoiceDataGrid?grid=${gridName}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }

        if(this.state.InvoiceNumberValue) {
            dataRoute += `&hourCost=${this.state.InvoiceNumberValue}`;
        }
        // #endregion Filters

        content = (
            <div id="panelGRDBIInvoice" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIInvoice}
                    containerID="panelGRDBIInvoice"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );

        if (this.state.GetInvoiceisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }

    renderInvoiceDataMonth = () => {
        var { classes } = this.props;
        var { fromStart, toStop, Operator, Barcode, viewDataInvoiceMonthType, DataInvoicePeriodsMonths, DataInvoiceResultPeriodsMonths, ParametersDataInvoice } = this.state;

        const colors = scaleOrdinal(schemeCategory10).range();

        var width = this.getWidth();

        // Update Panel Width
        if (ParametersDataInvoice) {
            var tempDraw = drawerWidth;
            width -= tempDraw;
        }
        else {
            width = this.getWidth();
        }

        var content = undefined;
        if (DataInvoiceResultPeriodsMonths && DataInvoiceResultPeriodsMonths.length > 0) {
            if (viewDataInvoiceMonthType === "chart") {
                if (DataInvoiceResultPeriodsMonths.length > 10) {
                    width = width * DataInvoiceResultPeriodsMonths.length / 10;
                }
                content = (
                    <BarChart width={width} height={250} data={DataInvoiceResultPeriodsMonths} style={{ display: 'inline-block' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="OrderCode" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip type="InvoiceExp2" />}/>
                        <Legend />
                        {
                            DataInvoicePeriodsMonths && DataInvoicePeriodsMonths.map((value, index) => (
                                <Bar dataKey={value} name={value} fill={colors[index % 10]} width={550} />
                            ))
                        }
                    </BarChart>
                );
            }
        }
        else {
            content = <Typography className={classes.noResult}>Nessun Risultato Trovato</Typography>;
        }

        var actions = [];
        if (DataInvoiceResultPeriodsMonths && DataInvoiceResultPeriodsMonths.length > 0) {
            actions.push({ name: getTranslation(7, "BI_BUTTON_GRAPHIC", this.props.translations, "Grafico"), icon: <InsertChartIcon />, action: this.SetView("viewDataInvoiceMonthType", "chart"), selected: viewDataInvoiceMonthType === "chart" });
            actions.push({ name: getTranslation(7, "BI_BUTTON_DATAGRID", this.props.translations, "Dati"), icon: <SubjectIcon />, action: this.SetView("viewDataInvoiceMonthType", "grid"), selected: viewDataInvoiceMonthType === "grid" });
        }
        var label = <XNavigation actions={actions} />;

        var gridName = "grdBIInvoiceMonth";
        var dataRoute = `/api/BIProd/GetInvoiceDataMonthGrid?grid=${gridName}&hourCost=${this.state.InvoiceNumberValue}`;

        // #region Filters
        if (fromStart) {
            dataRoute += `&fromStart=${fromStart.toISOString()}`;
        }

        if (toStop) {
            dataRoute += `&toStop=${toStop.toISOString()}`;
        }

        if (Operator) {
            dataRoute += `&Operator=${Operator}`;
        }

        if (Barcode) {
            dataRoute += `&Barcode=${Barcode}`;
        }

        if(this.state.InvoiceNumber){
            dataRoute += `&hourCost=${this.state.InvoiceNumberValue}`
        }
        // #endregion Filters

        content = (
            <div id="panelGRDBIInvoice" className={classes.container}>
                <XGrid
                    innerRef={this.grdBIInvoiceMonth}
                    containerID="panelGRDBIInvoice"
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    customContent={content}
                    rowsVisible={10}
                    label={label}
                />
            </div>
        );
        if (this.state.GetInvoiceMonthisLoading) {
            content = (<XOXLoader onlyO={false} height="263px" />)
        }

        return (
            content
        );
    }



    // #endregion RenderTabProductionValues
    renderPanelParameters = () => {
        var { classes } = this.props;

        var { fromStart, toStop, Operator, Barcode, viewBarcodeDurationType } = this.state;
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={true} label={"Da data"} type="date" id="fromStart" maxDate={toStop} value={fromStart} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={true} label={"A data"} type="date" id="toStop" minDate={fromStart} value={toStop} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadData}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Render PanelParametersProduction 
    renderPanelParametersProduction = () => {
        var { classes } = this.props;

        var { fromStart, toStop, Operator, Barcode, viewBarcodeDurationType } = this.state;
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={true} label={"Data"} type="date" id="fromStart" value={fromStart} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={true} label={"A data"} type="date" id="toStop" minDate={fromStart} value={toStop} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataProduction}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Render Panel Production Values
    renderPanelParametersProductionValues = () => {
        var { classes } = this.props;

        var { fromStart, toStop, Operator, Barcode, viewBarcodeDurationType, ProductionNumberValue } = this.state;
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={true} label={"Da Data"} type="date" id="fromStart" maxDate={toStop} value={fromStart} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={true} label={"A data"} type="date" id="toStop" minDate={fromStart} value={toStop} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}>
                    <XField
                        label={"Costo Orario (#.##)"}
                        min={0.0} decimalScale={2}
                        id="ProductionNumberValue"
                        value={ProductionNumberValue}
                        customFormatNumber
                        preventNegativeValue
                        decimalScale={3}
                        field={{ Required: 2 }}
                        type="number"
                        onChange={this.onUserChangeXField}
                        type={Number}
                    /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataProductionValues}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Render Panel List Activities
    renderPanelParametersListActivity = () => {
        var { classes } = this.props;

        var { fromStart, toStop } = this.state;
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={true} label={"Da Data"} type="date" id="fromStart" maxDate={toStop} value={fromStart} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={true} label={"A Data"} type="date" id="toStop" minDate={fromStart} value={toStop} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataListActivity}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Render Panel INC
    renderPanelParametersINC = () => {
        var { classes } = this.props;

        var { fromStart, toStop, OperatorINC } = this.state;
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={true} label={"Da Data"} type="date" id="fromStart" maxDate={toStop} value={fromStart} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={true} label={"A Data"} type="date" id="toStop" minDate={fromStart} value={toStop} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XSelect clearable={true} label="Operatore" id="OperatorINC" urlData="/api/BIProd/GetOperators" value={OperatorINC} idKey="ID" valueKey="Denomination" onChange={this.onUserChangeXSelect} /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataINC}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    // Render Panel Invoice
    renderPanelParametersInvoice = () => {
        var { classes } = this.props;

        var { fromStart, toStop, InvoiceNumberValue } = this.state;
        return (
            <Grid style={{ margin: 0 }} container xs={12} spacing={3}>
                <Grid item xs={12}><XDateField clearable={true} label={"Da Data"} type="date" id="fromStart" maxDate={toStop} value={fromStart} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XDateField clearable={true} label={"A Data"} type="date" id="toStop" minDate={fromStart} value={toStop} onChange={this.onUserDataChange} /></Grid>
                <Grid item xs={12}><XField
                    label={"Costo Orario (#.##)"}
                    min={0.0} decimalScale={2}
                    id="InvoiceNumberValue"
                    value={InvoiceNumberValue}
                    customFormatNumber
                    preventNegativeValue
                    decimalScale={3}
                    field={{ Required: 2 }}
                    type="number"
                    onChange={this.onUserChangeXField}
                    type={Number}
                /></Grid>
                <Grid item xs={12} className={classes.gridLoadButton} ><XButton className={classes.loadButton} onClick={this.loadDataInvoice}>{getTranslation(7, "BI_BUTTON_LOADDATA", this.props.translations, "Carica Dati")} </XButton></Grid>
            </Grid>
        );
    }

    renderTabWorkStatistics = () => {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { classes } = this.props;
        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: "Tempo totale lavorazioni",
            content: this.renderBarcodeDuration()
        });

        panels.push({
            icon: 'data_usage',
            label: "Media per codice",
            content: this.renderCodeMedia()
        });

        panels.push({
            icon: 'data_usage',
            label: "Media per codice catalogo",
            content: this.renderCodeCatalogMedia()
        });

        panels.push({
            icon: 'data_usage',
            label: "Media per codice & operatore",
            content: this.renderCodeMediaOperator()
        });

        panels.push({
            icon: 'data_usage',
            label: "Media per codice & reparto",
            content: this.renderCodeMediaDepartament()
        });

        panels.push({
            icon: 'data_usage',
            label: "Media per codice & operatore & reparto",
            content: this.renderCodeMediaOperatorDepartament()
        });

        return (
            <>
                <TabContainer>
                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent, { [classes.tabContentOpen]: this.state.ParametersOpen })}>
                            <XExpansionPanel panels={panels} />
                        </div>
                        <Drawer
                            className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.ParametersOpen })}
                            variant="persistent"
                            anchor="right"
                            open={this.state.ParametersOpen}
                            classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper className={classes.sidebarWrapper}>
                                {this.renderPanelParameters()}
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </TabContainer>
            </>);
    }

    renderTabProductionStatistics = () => {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { classes } = this.props;
        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: 'Operatore Produzione',
            content: this.rendertrackOperator(),
        });

        return (
            <>
                <TabContainer>
                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent, { [classes.tabContentOpen]: this.state.ParamtersProductionOpen })}>
                            <XExpansionPanel panels={panels} />
                        </div>
                        <Drawer
                            className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.ParamtersProductionOpen })}
                            variant="persistent"
                            anchor="right"
                            open={this.state.ParamtersProductionOpen}
                            classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper className={classes.sidebarWrapper}>
                                {this.renderPanelParametersProduction()}
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </TabContainer>
            </>);
    }

    renderTabProductionValues = () => {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { classes } = this.props;
        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: 'Codice',
            content: this.renderCodeValue(),
        });

        panels.push({
            icon: 'data_usage',
            label: 'Codice Catalogo',
            content: this.renderCodeCatalogValue(),
        });

        return (
            <>
                <TabContainer>
                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent, { [classes.tabContentOpen]: this.state.ParametersProductionValuesOpen })}>
                            <XExpansionPanel panels={panels} />
                        </div>
                        <Drawer
                            className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.ParametersProductionValuesOpen })}
                            variant="persistent"
                            anchor="right"
                            open={this.state.ParametersProductionValuesOpen}
                            classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper className={classes.sidebarWrapper}>
                                {this.renderPanelParametersProductionValues()}
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </TabContainer>
            </>);
    }


    renderActivityList = () => {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { classes } = this.props;
        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: 'Elenco Operazioni',
            content: this.renderListActivity(),
        });

        return (
            <>
                <TabContainer>
                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent, { [classes.tabContentOpen]: this.state.ParametersListActivityOpen })}>
                            <XExpansionPanel panels={panels} />
                        </div>
                        <Drawer
                            className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.ParametersListActivityOpen })}
                            variant="persistent"
                            anchor="right"
                            open={this.state.ParametersListActivityOpen}
                            classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper className={classes.sidebarWrapper}>
                                {this.renderPanelParametersListActivity()}
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </TabContainer>
            </>);
    }

    renderINC = () => {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { classes } = this.props;
        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: 'Incassature Pietre',
            content: this.renderINCStone(),
        });

        return (
            <>
                <TabContainer>
                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent, { [classes.tabContentOpen]: this.state.ParametersINCStone })}>
                            <XExpansionPanel panels={panels} />
                        </div>
                        <Drawer
                            className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.ParametersINCStone })}
                            variant="persistent"
                            anchor="right"
                            open={this.state.ParametersINCStone}
                            classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper className={classes.sidebarWrapper}>
                                {this.renderPanelParametersINC()}
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </TabContainer>
            </>);
    }

    renderInvoice = () => {
        var width = document.getElementById("mainContent").clientWidth * 0.9;
        var { classes } = this.props;
        var panels = [];

        panels.push({
            icon: 'data_usage',
            label: 'Mese produzione di competenza',
            content: this.renderInvoiceData(),
        });

        panels.push({
            icon: 'data_usage',
            label: 'Mese fatturazione di competenza',
            content: this.renderInvoiceDataMonth(),
        });

        return (
            <>
                <TabContainer>
                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent, { [classes.tabContentOpen]: this.state.ParametersDataInvoice })}>
                            <XExpansionPanel panels={panels} />
                        </div>
                        <Drawer
                            className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.ParametersDataInvoice })}
                            variant="persistent"
                            anchor="right"
                            open={this.state.ParametersDataInvoice}
                            classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper className={classes.sidebarWrapper}>
                                {this.renderPanelParametersInvoice()}
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </TabContainer>
            </>);
    }

    onTabChanged = (index) => {
        if (index) {
            this.setState({ currentTab: index });
            this.SignalChanged(index);
            switch (index) {
                case 0:
                    // Statistiche Lavorazione 
                    this.loadData();
                    break;
                case 1:
                    // Statistiche Produzione
                    this.loadDataProduction();
                    break;
                case 2:
                    // Statistiche Valore
                    this.loadDataProductionValues();
                    break;
                case 3:
                    // Elenco Attivita
                    this.loadDataListActivity();
                    break;
                case 4:
                    // Incassatura
                    this.loadDataINC();
                    break;
                case 5:
                    // Statistiche Fatturazione
                    this.loadDataInvoice();
                    break;
            }
        }
    };

    SignalChanged = async (currentTab) => {
        var { currentTab } = this.state;
        var tab0 = ["BarcodeDuration", "CodeMedia", "CatalogCodeMedia", "CodeMediaOperator", "CodeMediaOperatorDepartament", "CodeMediaDepartament"];
        var tab1 = ["TrackOperator"];
        var tab2 = ["ValuesCode", "ValuesCatalogCode"];
        var tab3 = []; // has only a grid atm 
        var tab4 = ["INCStone"];
        var tab5 = ["GetInvoiceGraphics", "GetInvoice", "GetInvoiceMonthGraphics", "GetInvoiceMonth"];
        switch (currentTab) {
            case 0:
                tab1.some(controller => this.abortController(controller));
                tab2.some(controller => this.abortController(controller));
                tab4.some(controller => this.abortController(controller));
                tab5.some(controller => this.abortController(controller));
                break;
            case 1:
                tab0.some(controller => this.abortController(controller));
                tab2.some(controller => this.abortController(controller));
                tab4.some(controller => this.abortController(controller));
                tab5.some(controller => this.abortController(controller));
                break;
            case 3:
                tab0.some(controller => this.abortController(controller));
                tab1.some(controller => this.abortController(controller));
                tab2.some(controller => this.abortController(controller));
                tab4.some(controller => this.abortController(controller));
                tab5.some(controller => this.abortController(controller));
                break;
            case 4:
                tab0.some(controller => this.abortController(controller));
                tab1.some(controller => this.abortController(controller));
                tab2.some(controller => this.abortController(controller));
                tab5.some(controller => this.abortController(controller));
                break;
            case 5:
                tab0.some(controller => this.abortController(controller));
                tab1.some(controller => this.abortController(controller));
                tab2.some(controller => this.abortController(controller));
                tab4.some(controller => this.abortController(controller));
                break;
        }
    }

    abortController = async (ControllerName) => {
        let ac = window[ControllerName] && window[ControllerName]();
        ac && ac.abort && ac.abort();
    }

    render() {
        // Tabs 
        var locTabs = [
            {
                tabName: "Statistiche Lavorazione",
                tabContent: this.renderTabWorkStatistics(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.updateFilters
                    }
                ],
            },
            {
                tabName: "Statistiche Produzione",
                tabContent: this.renderTabProductionStatistics(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.updateFiltersProduction
                    }
                ],
            },
            {
                tabName: "Statistiche Valore",
                tabContent: this.renderTabProductionValues(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.updateFiltersProductionValues
                    }
                ],
            },

            {
                tabName: "Elenco Attivita",
                tabContent: this.renderActivityList(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.updateFiltersListActivity
                    }
                ],
            },
            {
                tabName: "Incassatura",
                tabContent: this.renderINC(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.updateFiltersINC
                    }
                ],
            },
            {
                tabName: "Statistiche Fatturazione",
                tabContent: this.renderInvoice(),
                actions: [
                    {
                        group: 'actions',
                        icon: <ChartPie />,
                        name: getTranslation(7, "BI_FILTERS", this.props.translations, "Filtri"),
                        action: this.updateFilterDataInvoice
                    }
                ],
            }
        ];

        return <Fragment>
            <Tabs
                headerColor="primary"
                plainTabs={true}
                tabs={locTabs}
                onTabChange={this.onTabChanged}
            />
            <XMessageBox innerRef={this.XMessageBox} />
        </Fragment>
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(authCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(BIProd);