import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MoreVert, KeyboardArrowLeft, KeyboardArrowRight, ExpandMore } from '@material-ui/icons';

import PropTypes from 'prop-types';

//import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import numeral from 'numeral';

import { Pencil, StarOutline, BellRingOutline, LockOpenOutline, LockOutline, CartOutline, Star,Handshake, CurrencyEur } from 'mdi-material-ui';
import { PieChart, Pie, Cell } from 'recharts';

import CatalogueActions from 'components/CatalogueActions';
import XOXLoader from './XOXLoader';
import { Collapse, CardActions } from '@material-ui/core';


import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";


const styles = theme => ({
    card: {
        position: "relative",
        margin: 5,
    },
    header: {
        maxHeight: 80,
        minHeight: 80,
        overflow: "hidden",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    actions: {
        display: 'flex',
    },
    //expand: {
    //    transform: 'rotate(0deg)',
    //    transition: theme.transitions.create('transform', {
    //        duration: theme.transitions.duration.shortest,
    //    }),
    //    marginLeft: 'auto',
    //    [theme.breakpoints.up('sm')]: {
    //        marginRight: -8,
    //    },
    //},
    //expandOpen: {
    //    transform: 'rotate(180deg)',
    //},
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    price: {
        fontSize: "16px",
        fontWeight: "bold",
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    cardTitle: {
        fontSize: "18px",
    },
    cardContent: {
        //maxHeight: 80,
        //minHeight: 80,
        //marginTop: 136,
        //transition: "all 0.3s ease-in",
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentHover: {
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentInfo: {
        maxHeight: 42,
        minHeight: 42,
    },
    cardActions: {
        //position: "absolute",
        //transition: "all 0.3s ease-in",
        //bottom: -136,
        height: 136,
        textAlign: "center",
        "&> div:nth-child(1)": {
            marginTop: "0 !important",
        },
        bottom: 0,
    },
    cardActionsHover: {
        bottom: 0,

    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandActions: {
        padding: "8px 0",
        width: "calc(100% + 16px)",
        margin: "-8px",
    },
    docInfo: {
        marginRight: 5,
    },
});

class CatalogueCard extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    _isMounted = false;
    state = {
        imageIndex: 0,
        documents: [],
        data: {},
        isLoading: true,
        expanded: false,
    };
    componentDidMount = async() => {
        this._isMounted = true;
        this.loadData(this.getItemStatus);
        //this.getItemStatus();
        this.setState({ expanded: this.props.isExpanded });
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.loadData(this.getItemStatus);
        }
        if (this.props.isExpanded !== prevProps.isExpanded) {
            this.setState({ expanded: this.props.isExpanded });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadData = async (callback) => {
        var { data, FIDCausal, CatalogFIDIdentity, showCatalog, showStock } = this.props;
        if (this._isMounted) {
			if (data.ID && !data.Code && !data.CatalogCode) {
				data.Code = await fetch('/BOP/GetBOPCode/?ID='+data.ID).then(res => res.json());
			}
			
			if (data.ID && showStock && !data.SerialNumber) {
                data.SerialNumber = await fetch(`/BOP/GetBOPSerialNumber/?ID=${data.ID}&code=${data.Code}`).then(res => res.json());
			}
            if(data.ID){
                data.SupplierLabel = await fetch(`/BOP/GetBOPSupplierLabel/?ID=${data.ID}&code=${data.Code}`).then(res => res.json());
                console.log("Loaded supplierLabel")
        }
            this.setState({ data: data, isLoading: false },() => {
                callback && callback();
                console.log("is loading false")
            } );
        }
		
        // if (data.ID > 0) {
        //     var result1 = await fetch('/BOP/GetCatalogueData/' + data.ID + '?FIDCausal=' + FIDCausal + '&FIDIdentity=' + CatalogFIDIdentity).then(res => res.json());
        //     if (this._isMounted) {
        //         this.setState({ data: result1, isLoading: false }, callback);
        //     }
        // }
        // else if (data.Code || data.Position) {
        //     var url = '/BOP/GetCatalogueData/?Code=' + data.Code;
        //     if (data.Position) {
        //         url += "&Position=" + data.Position;
        //     }
        //     if (FIDCausal) {
        //         url += '&FIDCausal=' + FIDCausal + '&FIDIdentity=' + CatalogFIDIdentity;
        //     }
        //     var result2 = await fetch(url).then(res => res.json());
        //     if (this._isMounted) {
        //         this.setState({ data: result2, isLoading: false }, callback);
        //     }
        // }
    }

    getItemStatus = async () => {
        var { CatalogFIDIdentity, FIDDocument, isCatalog, groupType } = this.props;
        var { data } = this.state;

      if (!data) return;
        if(!data.ID){
            return;
        }
        var result = await fetch(`/IR/BOPStatus/${data.ID}?FIDIdentity=${CatalogFIDIdentity}&FIDDocument=${FIDDocument}&GroupType=${groupType}`).then(res => res.json());
        if (this._isMounted) {
            this.setState(state => {
                state.IsBooked = result.IsBooked;
                state.IsReserved = result.IsReserved;
                state.IsBookedOther = result.IsBookedOther;
                state.IsReservedOther = result.IsReservedOther;
                state.documents = result.documents;
                if(isCatalog) {
                    var catalogOrder = state.documents.filter(f => f.FIDCausal === this.props.CatalogFIDCausal)[0];
                    if(catalogOrder){
                        state.qty = catalogOrder.Qty;
                    }
                }
                else {
                var cart = state.documents.filter(f => f.FIDCausal === 10)[0];
                if (cart && cart.Discount && cart.Discount > 0) {
                    state.discount = cart.Discount;
                    state.totalPrice = (data.Price * (100 - state.discount) / 100).round(2);
                    state.showPrices = true;
                        state.qty = cart.Qty;
                }
                }
                if (result.documents && result.documents.length > 0) {
                    state.internalRemark = result.documents[0].InternalRemarks;
                    state.publicRemark = result.documents[0].PublicRemarks;
                }
                return state;
            });
        }
    }
    handleNext = () => {

        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }), () => {
            if (this.props.onExpand) {
                this.props.onExpand(this.state.expanded);
            }
        });
    }
    onItemEditClick = (data) => {
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    renderDocInfos = () => {
        var { classes } = this.props;
        var { documents } = this.state;

        var isPresentation = documents.filter(i => i.FIDCausal === (this.props.params.DOCPresentationCausalId||10)).length > 0;
        var isCart = documents.filter(i => i.FIDCausal === (this.props.params.DOCCartCausalId||9)).length > 0;
        var isNotice = documents.filter(i => i.FIDCausal === (this.props.params.DOCNoticeCausalId||11)).length > 0;
        var isReservation = documents.filter(i => i.FIDCausal === this.props.params.DOCReservationCausalId).length > 0;
        var isBooking = documents.filter(i => i.FIDCausal === (this.props.params.DOCBookingCausalId||13)).length > 0;
        var isQuote = documents.filter(i => i.FIDCausal === (this.props.params.DOCQuoteCausalId||3)).length > 0;
        var isNegotation = documents.filter(i => i.FIDCausal === (this.props.params.DOCNegotiationCausalId||39)).length > 0;

        var HoverStar = 0;
        var color = grayColor;
        if (isPresentation) {
            HoverStar = documents.filter(i => i.FIDCausal === this.props.params.DOCPresentationCausalId)[0].Stars;
        }
        switch (HoverStar) {
            case 1:
                color = "#E74C3C";
                break;
            case 2:
                color = "#F39C12";
                break;
            case 3:
                color = "#F7DC6F";
                break;
            case 4:
                color = "#82E0AA";
                break;
            case 5:
                color = "#27AE60";
                break;
            default:
                break;
        }

        return <Fragment>
            {isPresentation && HoverStar > 0 && <Star className={classes.docInfo} htmlColor={color} fontSize="small" />}
            {isPresentation && HoverStar <= 0 && <StarOutline className={classes.docInfo} htmlColor={color} fontSize="small" />}
            {isNotice && <BellRingOutline className={classes.docInfo} fontSize="small"/>}
			{isQuote && <CurrencyEur className={classes.docInfo} fontSize="small"/>}
            {isNegotation && <Handshake className={classes.docInfo} fontSize="small"/>}
            {isReservation && <LockOpenOutline className={classes.docInfo} fontSize="small"/>}
            {isBooking && <LockOutline className={classes.docInfo} fontSize="small"/>}
            {isCart && <CartOutline className={classes.docInfo} fontSize="small"/>}
        </Fragment>;
    }
    
    updateDocument = () => {
        var { onBuyItem, onRemoveItem } = this.props;
        var { data, discount, totalPrice, qty } = this.state;
        if (qty === 0) {
            if (onRemoveItem) {
                onRemoveItem(data, this.props.CatalogFIDCausal);
            }
        }
        else {
            if (!discount || discount === 0) {
                discount = 0;
                totalPrice = data.Price;
            }
            data.Discount = discount;
            data.TotalPrice = totalPrice;
            if (onBuyItem) {
                onBuyItem(data, qty, true, false);
            }
        }
    }
    render() {

        var { data, imageIndex, hover, internalRemark, publicRemark, isLoading  } = this.state;
        var { classes, onItemClick, isCatalog, showCart, groupType } = this.props;
        var isLoadingPage = this.props.isLoading;
        if (isLoadingPage) {
            isLoading = isLoadingPage;
				}

      if (!data)
        return <div></div>;
        
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;

				if (data) {
						data.internalRemark = internalRemark ? internalRemark : "";
						data.publicRemark = publicRemark ? publicRemark : "";
				}

        var subTitle = "";
        if (!this.props.params.CatalogueHideBrand && data.Brand) {
            subTitle = data.Brand;
        }
        if (!this.props.params.CatalogueHideLine && data.Line) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
            subTitle += data.Line
        }
        if (!this.props.params.CatalogueHideSeries && data.Series) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
            subTitle += data.Series
        }
        var pieData2 = [];
        if (data.Value) {
            pieData2 = [{ value: 100 - (100 - data.Value + 2) }, { value: 4 }, { value: 100 - data.Value - 2 }];
        }
        var pieData = [{ name: "1", value: 33.33 }, { name: "2", value: 33.33 }, { name: "3", value: 33.33 }];
        var price = "";
        if (data.Price) {
            var locPrice = data.Price;
            price = data.PriceCurrency + " " + numeral(locPrice).format("0,0.00");
        }
        if(data.PriceB2B) {
            var locPrice = data.PriceB2B;
            price = data.CurrencyB2B + " " + numeral(locPrice).format("0,0.00");
        }
        if(data.SupplierLabel){
            price = data.SupplierLabel;
        }
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Medium"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        var catalogButtons = undefined;
        if(isCatalog && showCart){
            if(this.state.qty > 0){

                if (data.Price) {
                    var locPrice = data.Price * this.state.qty;
                    price = data.PriceCurrency + " " + numeral(locPrice).format("0,0.00");
                }

                catalogButtons = <div style={{ width: "100%" }}>
                    <button style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                        this.setState(state => {
                            if (!state.qty) state.qty = 1;
                            state.qty--;
                            return state;
                        }, this.updateDocument)}}>-</button>
                    <input style={{ border: "1px solid #aaa", margin: 2, padding: 10, width: 80 }} type="number" value={this.state.qty} onChange={(event) => {
                        var n = parseInt(event.target.value);
                        if (n < 0) {
                            n = 0;
                        }
                        this.setState({ qty: n }, this.updateDocument);
                    }} />
                    <button style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                        this.setState(state => {
                            if (!state.qty) state.qty = 0;
                            state.qty++;
                            return state;
                        }, this.updateDocument)
                    }}>+</button>
                </div>;
            }
            else {
                catalogButtons = <div style={{ width: "100%" }}>
                <button style={{ border: "1px solid #aaa", background: "#eee", margin: 2, padding: 15, lineHeight: "5px" }} onClick={() => {
                    this.setState(state => {
                        if (!state.qty) state.qty = 0;
                        state.qty++;
                        return state;
                    }, this.updateDocument)
                }}>Aggiungi alla quotazione</button>
            </div>;
            }
        }

        var SizeColorSeparator = undefined;
        if((data.Misura || data.Size) && (data.Colore || data.Colour)){
            SizeColorSeparator = " - ";
        }
		
		var title = groupType === 1 ? data.Code : (data.CatalogCode || data.Code);
		
		if (data.SupplierCatalogCode) {
			title += " - " + data.SupplierCatalogCode;
		}

        var content = <Fragment>
            <CardHeader
                className={classes.header}
                title={title}
                subheader={subTitle}
                classes={{
                    title: classes.cardTitle
                }}
                action={
                    <Fragment>
                        {catalogButtons && (<span>
                            {(data.Misura)&& (<div>
                                <span style={{padding: 5}}>Misura: {data.Misura}</span>
                            </div>)}
                            {(data.Size)&& (<div>
                                <span style={{padding: 5}}>Size: {data.Size}</span>
                            </div>)}
                            {data.Colore && (<div>
                                <span style={{padding: 5}}>Colore: {data.Colore}</span>
                            </div>)}
                            {data.Colour && (<div>
                                <span style={{padding: 5}}>Colour: {data.Colour}</span>
                            </div>)}
                        </span>)}
                        
                        {onItemClick && 1 === 2 && (<IconButton onClick={() => { onItemClick(data) }}>
                            <MoreVert />
                        </IconButton>)}
                        {1 === 2 && <IconButton onClick={() => { this.onItemEditClick(data) }}>
                            <Pencil />
                        </IconButton>}
                    </Fragment>
                }
            />
            <div className={classes.slider} onClick={() => { if(onItemClick) onItemClick(data); }}>
                <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                    {mediaFiles}
                </SwipeableViews>
                {files.length > 1 &&
                    (
                        <Fragment>
                            <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                            <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                        </Fragment>
                    )
                }
            </div>
            <CardContent className={classes.cardContent + " " + (hover ? classes.cardContentHover : "")}>
                <Grid container spacing={16}>
                    {data.Value && (<Grid container item xs={12}>
                        <Grid item xs={12} container alignContent="flex-end" justify="flex-end" className={classes.price}>
                            <PieChart width={210} height={100}>
                                <Pie data={pieData} cx={120} cy={100} startAngle={180} endAngle={0} innerRadius={60} outerRadius={80} fill="#8884d8" paddingAngle={5} dataKey="value">
                                    <Cell fill="#FF8042" />
                                    <Cell fill="#FFBB28" />
                                    <Cell fill="#00C49F" />
                                </Pie>
                                <Pie data={pieData2} cx={120} cy={100} startAngle={180} endAngle={0} innerRadius={0} outerRadius={80} fill="#fff" paddingAngle={5} dataKey="value">
                                    <Cell fill="transparent" />
                                    <Cell fill="#000" />
                                    <Cell fill="transparent" />
                                </Pie>
                            </PieChart>
                        </Grid>
                    </Grid>)}
                    <Grid container item xs={12} >
                        <Grid container item xs={6} className={classes.cardContentInfo}>
                            {false && data.DOCQty && <Grid item xs={12}>
                                {data.DOCQty + " " + data.MeasureUnit1}
                            </Grid>}
                            <Grid item xs={12}>
                                {data.SerialNumber}
                            </Grid>
                            <Grid item xs={12}>
                                {data.Position && data.Position + (data.Qty > 0 && " - " + data.Qty + " " + data.MeasureUnit1)}
                                {data.Positions && data.Qty && (data.Qty + " " + data.MeasureUnit1+" in " + data.Positions + " punti vendita")}
                            </Grid>
                            {catalogButtons && <Grid item xs={12}>
                                {catalogButtons}
                            </Grid>}
                        </Grid>
                        <Grid item xs={6} container alignContent="flex-end" justify="flex-end" className={classes.price}>
                            {price}
                        </Grid>
                    </Grid>
                    {data.SellDate && (<Grid container item xs={12}>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <Typography>Data vendita:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.SellDate.toLocaleDateString()}
                            </Grid>
                        </Grid>
                    </Grid>)}
                    {data.VisitDate && (<Grid container item xs={6}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Data visita:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.VisitDate.toLocaleDateString()}
                            </Grid>
                        </Grid>
                    </Grid>)}
                    {data.Operator && (<Grid container item xs={6}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Operatore:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.Operator}
                            </Grid>
                        </Grid>
                    </Grid>)}
                </Grid>

                {this.props.showExpansionPanel &&
                    <CardActions className={classes.expandActions}>
                        {this.renderDocInfos()}
                        <IconButton className={classnames(classes.expand, { [classes.expandOpen]: this.state.expanded })} onClick={this.handleExpandClick} aria-expanded={this.state.expanded} aria-label="Azioni">
                            <ExpandMore />
                        </IconButton>
                    </CardActions>
                }
            </CardContent>
            {this.props.showExpansionPanel &&
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    {
                        (this.props.onNoticeItem || this.props.onStarItem || this.props.onBookItem || this.props.onReserveItem || this.props.onBuyItem || this.props.onRemoveItem) &&
                        <Grid container item xs={12} className={classes.cardActions + " " + (hover ? classes.cardActionsHover : "")}>
                            <CatalogueActions data={data} documents={this.state.documents} onNoticeItem={this.props.onNoticeItem} onQuoteItem={this.props.onQuoteItem} onNegotiateItem={this.props.onNegotiateItem}  onStarItem={this.props.onStarItem} onBookItem={this.props.onBookItem} onReserveItem={this.props.onReserveItem} onBuyItem={this.props.onBuyItem} onRemoveItem={this.props.onRemoveItem} />
                        </Grid>
                    }
                </Collapse>
            }
            <div></div>
        </Fragment>;
        //if (this.state.isLoading) {
        //    content = <XOXLoader onlyO={true} />
        //}
        return (
            <Card style={this.props.style} className={classes.card} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                {content}
                {isLoading && <XOXLoader onlyO={true} height="100%" />}
            </Card>
        );
    }
}


const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(CatalogueCard);

//export default withStyles(styles)(CatalogueCard);