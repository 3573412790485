import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MoreVert, KeyboardArrowLeft, KeyboardArrowRight, ExpandMore } from '@material-ui/icons';

import PropTypes from 'prop-types';

//import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


import { withStyles } from '@material-ui/core/styles';
import numeral from 'numeral';

import { Pencil, StarOutline, BellRingOutline, LockOpenOutline, LockOutline, CartOutline, Star } from 'mdi-material-ui';
import { PieChart, Pie, Cell } from 'recharts';

import CatalogueActions from 'components/CatalogueActions';
import XOXLoader from './XOXLoader';
import { Collapse, CardActions } from '@material-ui/core';

import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";


const styles = theme => ({
    card: {
        position: "relative",
        margin: 5,
    },
    header: {
        maxHeight: 80,
        minHeight: 80,
        overflow: "hidden",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    actions: {
        display: 'flex',
    },
    //expand: {
    //    transform: 'rotate(0deg)',
    //    transition: theme.transitions.create('transform', {
    //        duration: theme.transitions.duration.shortest,
    //    }),
    //    marginLeft: 'auto',
    //    [theme.breakpoints.up('sm')]: {
    //        marginRight: -8,
    //    },
    //},
    //expandOpen: {
    //    transform: 'rotate(180deg)',
    //},
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    price: {
        fontSize: "16px",
        fontWeight: "bold",
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    cardTitle: {
        fontSize: "18px",
    },
    cardContent: {
        //maxHeight: 80,
        //minHeight: 80,
        //marginTop: 136,
        //transition: "all 0.3s ease-in",
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentHover: {
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentInfo: {
        maxHeight: 42,
        minHeight: 42,
    },
    cardActions: {
        //position: "absolute",
        //transition: "all 0.3s ease-in",
        //bottom: -136,
        height: 136,
        textAlign: "center",
        "&> div:nth-child(1)": {
            marginTop: "0 !important",
        },
        bottom: 0,
    },
    cardActionsHover: {
        bottom: 0,

    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandActions: {
        padding: "8px 0",
        width: "calc(100% + 16px)",
        margin: "-8px",
    },
    docInfo: {
        marginRight: 5,
    },
});

class CustomerCatalogueCard extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    _isMounted = false;
    state = {
        imageIndex: 0,
        documents: [],
        data: {},
        isLoading: true,
        expanded: false,
    };
    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        //this.getItemStatus();
        this.setState({ expanded: this.props.isExpanded });
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.loadData();
        }
        if (this.props.isExpanded !== prevProps.isExpanded) {
            this.setState({ expanded: this.props.isExpanded });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadData = async () => {
        var { data, customer, storeid, storeareaid } = this.props;
        if (data.ID > 0) {
            var fetch_url = '/BOP/GetCatalogueData/' + data.ID + '?customer=' + customer;

            if (storeid)
                fetch_url += '&store=' + storeid;
            if (storeareaid)
                fetch_url += '&storearea=' + storeareaid;

            var result1 = await fetch(fetch_url).then(res => res.json());
            if (this._isMounted) {
                this.setState({ data: result1, isLoading: false });
            }
        }
        else if (data.Code || data.Position) {
            var fetch_url = '/BOP/GetCatalogueData/?Code=' + data.Code;
            if (data.Position) {
                fetch_url += "&Position=" + data.Position;
            }
            if (storeid)
                fetch_url += '&store=' + storeid;
            if (storeareaid)
                fetch_url += '&storearea=' + storeareaid;

            var result2 = await fetch(fetch_url).then(res => res.json());
            if (this._isMounted) {
                this.setState({ data: result2, isLoading: false });
            }
        }
    }
    handleNext = () => {

        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }), () => {
            if (this.props.onExpand) {
                this.props.onExpand(this.state.expanded);
            }
        });
    }
    render() {

        var { data, imageIndex, hover, internalRemark, publicRemark, isLoading  } = this.state;
        var { classes, onItemClick } = this.props;
        var isLoadingPage = this.props.isLoading;
        if (isLoadingPage) {
            isLoading = isLoadingPage;
        }
        data.internalRemark = internalRemark ? internalRemark : "";
        data.publicRemark = publicRemark ? publicRemark : "";

        var subTitle = "";
        if (data.Brand) {
            subTitle = data.Brand;
        }
        if (data.Line) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
            subTitle += data.Line
        }
        var pieData2 = [];
        if (data.Value) {
            pieData2 = [{ value: 100 - (100 - data.Value + 2) }, { value: 4 }, { value: 100 - data.Value - 2 }];
        }
        var pieData = [{ name: "1", value: 33.33 }, { name: "2", value: 33.33 }, { name: "3", value: 33.33 }];
        var price = "";
        if (data.Price) {
            var locPrice = data.Price;
            //if (data.DOCQty) {
            //    locPrice = data.Price * data.DOCQty;
            //}
            //price = data.PriceCurrency + " " + data.Price.toFixed(2);
            price = data.PriceCurrency + " " + numeral(locPrice).format("0,0.00");
        }
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?size=S"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        var content = <Fragment>
            <CardHeader
                className={classes.header}
                title={data.CatalogCode || data.Code}
                subheader={subTitle}
                classes={{
                    title: classes.cardTitle
                }}
                action={
                    <Fragment>
                        {onItemClick && 1 === 2 && (<IconButton onClick={() => { onItemClick(data) }}>
                            <MoreVert />
                        </IconButton>)}
                    </Fragment>
                }
            />
            <div className={classes.slider} onClick={() => { if(onItemClick) onItemClick(data) }}>
                <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                    {mediaFiles}
                </SwipeableViews>
                {files.length > 1 &&
                    (
                        <Fragment>
                            <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                            <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                        </Fragment>
                    )
                }
            </div>
            <CardContent className={classes.cardContent + " " + (hover ? classes.cardContentHover : "")}>
                <Grid container spacing={16}>
                    {data.Value && (<Grid container item xs={12}>
                        <Grid item xs={12} container alignContent="flex-end" justify="flex-end" className={classes.price}>
                            <PieChart width={210} height={100}>
                                <Pie data={pieData} cx={120} cy={100} startAngle={180} endAngle={0} innerRadius={60} outerRadius={80} fill="#8884d8" paddingAngle={5} dataKey="value">
                                    <Cell fill="#FF8042" />
                                    <Cell fill="#FFBB28" />
                                    <Cell fill="#00C49F" />
                                </Pie>
                                <Pie data={pieData2} cx={120} cy={100} startAngle={180} endAngle={0} innerRadius={0} outerRadius={80} fill="#fff" paddingAngle={5} dataKey="value">
                                    <Cell fill="transparent" />
                                    <Cell fill="#000" />
                                    <Cell fill="transparent" />
                                </Pie>
                            </PieChart>
                        </Grid>
                    </Grid>)}
                    <Grid container item xs={12} >
                        <Grid container item xs={6} className={classes.cardContentInfo}>
                            <Grid item xs={12}>
                                {data.SerialNumber}
                            </Grid>
                            <Grid item xs={12}>
                                {data.Position && data.Position + (data.Qty > 0 && " - " + data.Qty + " " + data.MeasureUnit1)}
                                {data.Positions && data.Qty && (data.Qty + " " + data.MeasureUnit1+" in " + data.Positions + " punti vendita")}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container alignContent="flex-end" justify="flex-end" className={classes.price}>
                            {price}
                        </Grid>
                    </Grid>
                </Grid>

                {this.props.showExpansionPanel &&
                    <CardActions className={classes.expandActions}>
                        <IconButton className={classnames(classes.expand, { [classes.expandOpen]: this.state.expanded })} onClick={this.handleExpandClick} aria-expanded={this.state.expanded} aria-label="Azioni">
                            <ExpandMore />
                        </IconButton>
                    </CardActions>
                }
            </CardContent>
            {this.props.showExpansionPanel &&
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    {
                        (this.props.onNoticeItem || this.props.onStarItem || this.props.onBookItem || this.props.onReserveItem || this.props.onBuyItem || this.props.onRemoveItem) &&
                        <Grid container item xs={12} className={classes.cardActions + " " + (hover ? classes.cardActionsHover : "")}>
                            <CatalogueActions data={data} documents={this.state.documents} onNoticeItem={this.props.onNoticeItem} onStarItem={this.props.onStarItem} onBookItem={this.props.onBookItem} onReserveItem={this.props.onReserveItem} onBuyItem={this.props.onBuyItem} onRemoveItem={this.props.onRemoveItem} />
                        </Grid>
                    }
                </Collapse>
            }
            <div></div>
        </Fragment>;
        //if (this.state.isLoading) {
        //    content = <XOXLoader onlyO={true} />
        //}
        return (
            <Card style={this.props.style} className={classes.card} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                {content}
                {isLoading && <XOXLoader onlyO={true} height="100%" />}
            </Card>
        );
    }
}

export default withStyles(styles)(CustomerCatalogueCard);