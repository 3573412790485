import React from 'react';
import { withTheme, makeStyles } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators } from 'store/Localization';

const useStyles = makeStyles(theme => ({
    root: {

    }
}));


const ActivityList = (props) => {
    const classes = useStyles(props);
    return (
        <>
        </>
    );
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withTheme
);
export default enhance(ActivityList);