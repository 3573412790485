import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core'


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from '@material-ui/core/IconButton';
import { Close } from 'mdi-material-ui';

const styles = theme => ({
    root: {
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'center',
        padding: '0 5px',
        margin: '8px 0',
        width: 120,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 'bold',
    },
    actionLabel: {
        cursor: 'pointer',
        marginTop: "0 !important",
        fontSize: 10,
        textTransform: 'uppercase',
        lineHeight: '8px',
        marginBottom: 4,
        color: theme.palette.primary.main
    },
    modal: {
        position: 'absolute',
        top: 136.5,
        left: 0,
        right: 0,
        bottom: 64,
        background: 'rgba(255,255,255,0.5)',
        zIndex: 99999,
    },
    cardHeader: {
        textTransform: 'uppercase',
    },
    modalCard: {
        // background: '#fff',
        maxWidth: 300,
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    modalBody: {
        
    },
    closeButton: {
        color: '#fff',
        position: 'absolute',
        right:0,
        top:0,
    }
})

class Modal extends Component {
    constructor(props) {
        super(props);
        // Create a div that we'll render the modal into. Because each
        // Modal component has its own element, we can render multiple
        // modal components into the modal container.
        this.el = document.createElement('div');
        this.modalRoot = document.getElementById('mainContent');
    }

    componentDidMount() {
        // Append the element into the DOM on mount. We'll render
        // into the modal container element (see the HTML tab).
        this.modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        // Remove the element from the DOM when we unmount
        this.modalRoot.removeChild(this.el);
    }
    
    render() {
        // Use a portal to render the children into the element
        return ReactDOM.createPortal(
            // Any valid React child: JSX, strings, arrays, etc.
            this.props.children,
            // A DOM element
            this.el,
        );
    }
}
class XGridSelectorFilter extends Component {
    constructor(props){
        super(props);
        this.state = {
            showSelector: false,
            tempSelectedValues:[],
            options:[]
        };
    }
    componentDidMount() {
        var { options, source } = this.props;
        if(options){
            this.setState({options});
        }
        else {
            fetch(source).then(res => res.json()).then(data =>{
                this.setState({options: data});
            });
        }
    }
    openSelector = () =>{
        var { selectedValues } = this.props;
        this.setState({showSelector: true, tempSelectedValues: Object.assign([],selectedValues)});
    }
    closeSelector = () =>{
        this.setState({showSelector: false,tempSelectedValues:[]});
    }
    onOptionSelected = (id) => () =>{
        this.setState(state => {
            var indexOf = state.tempSelectedValues.indexOf(id);
            if(indexOf === -1){
                state.tempSelectedValues.push(id);
            }
            else {
                state.tempSelectedValues.splice(indexOf,1);
            }

            return state;
        });
    }
    confirm = () => {
        this.setState({showSelector: false},() => {
            var { tempSelectedValues } = this.state;
            var { onChange} = this.props;
            onChange && onChange(tempSelectedValues);
        });
    }
    
    handleFilter = (item) => {
        var result = true;
        var { filters, filtersOr, filtersExclude, idKey } = this.props;
        var hasFilters = (filters && filters.map(m => m.value).filter(f => f).length > 0) || !!filtersOr || !!filtersExclude;
        if (!hasFilters) {
            return result;
        }

        var filter = undefined;
        for (var i1 in filters) {

            filter = filters[i1];
            if (filter.value) {
                result = result && (filter.value.includes(item[filter.key]) || item[idKey] < 0);
                // if (filter.type && filter.type === "array") {
                //     var itemValue = item[filter.key];
                //     if (itemValue) {
                //         if (typeof itemValue === "string") {
                //             itemValue = itemValue.split(",").map(Number);
                //         }
                //         var hasValue = itemValue.includes(filter.value);
                //         result = result && (hasValue || item[idKey] < 0);
                //     }
                // }
                // else {
                //     result = result && (item[filter.key] === filter.value || item[idKey] < 0);
                // }
            }
        }

        filter = undefined;
        var resultOr = undefined;
        for (var i2 in filtersOr) {
            filter = filtersOr[i2];
            if (filter.value) {
                if (resultOr === undefined) {
                    resultOr = (item[filter.key] === filter.value || item[idKey] < 0);
                }
                else {
                    resultOr = resultOr || (item[filter.key] === filter.value || item[idKey] < 0);
                }
            }
        }
        filter = undefined;
        for (var i3 in filtersExclude) {
            filter = filtersExclude[i3];
            if (filter.value) {
                result = result && (item[filter.key] !== filter.value || item[idKey] < 0);
            }
        }
        if (resultOr === undefined) {
            resultOr = true;
        }
        result = result && resultOr;

        return result;
    };
    render() {
        var { showSelector,options } = this.state;
        var { classes, label, selectedValues, idKey, labelKey, filters } = this.props;
        selectedValues = selectedValues || [];
        var actionLabel = "Seleziona";
        if(filters && filters.length > 0){
            options = options.filter(this.handleFilter);
        }
        if(options && options.length > 0){
            if(selectedValues.length === 1){
                var item = options.filter(f => f[idKey] === selectedValues[0])[0];
                if(item) {
                    actionLabel = item[labelKey];
                }
            }
            else if(selectedValues.length > 1){
                actionLabel = "Selezione multipla";
            }
        }
        var modal = undefined;
        if(showSelector) {
            var { tempSelectedValues } = this.state;
            modal = <Modal>
                <div className={classes.modal}>
                        
                    <Card login className={classes.modalCard}>
                        <CardHeader className={classes.cardHeader} color="gray">
                            <span>{`SELEZIONA ${label}`}</span>
                            <IconButton className={classes.closeButton} onClick={this.closeSelector}><Close /></IconButton>
                        </CardHeader>
                        <CardBody className={classes.modalBody}>
                            <List>
                                {options.map((item, index) => (
                                    <ListItem key={index} button onClick={this.onOptionSelected(item[idKey])}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={tempSelectedValues.indexOf(item[idKey]) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': item[idKey] }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText key={item[idKey]}
                                            primary={item[labelKey]}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </CardBody>
                        
                        <CardFooter className={classes.justifyContentCenter}>
                            <Button color="primary" size="sm" block onClick={this.confirm}>Conferma</Button>
                        </CardFooter>
                    </Card>
                </div>
            </Modal>;
        }
        return (
            <div className={classes.root}>
                {label && <span className={classes.label}>{label}</span>}
                <div>
                    <span className={classes.actionLabel} onClick={this.openSelector}>{actionLabel}</span>
                </div>
                {modal}
            </div>
        );
    }
}

export default withStyles(styles)(XGridSelectorFilter);