import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
});

class Ring extends Component {
    render() {
        return <SvgIcon {...this.props}>
            <g id="g4" transform="matrix(0.05989139,0,0,0.05989139,1.9792123,1.9792422)">
                <path
                    d="m 210.495,93.183 26.748,-35.317 c 1.9,-2.509 2.113,-6.157 0.519,-8.87 L 211.444,4.191 C 209.994,1.724 206.982,0 204.12,0 h -74.959 c -2.91,0 -5.93,1.777 -7.342,4.32 L 96.767,49.414 c -1.491,2.683 -1.26,6.283 0.563,8.754 l 26.037,35.308 c -47.072,17.806 -80.635,63.339 -80.635,116.571 0,68.696 55.888,124.584 124.584,124.584 68.696,0 124.584,-55.888 124.584,-124.584 -10e-4,-53.522 -33.928,-99.266 -81.405,-116.864 z m -43.18,211.448 c -52.154,0 -94.584,-42.43 -94.584,-94.584 0,-52.153 42.43,-94.583 94.584,-94.583 52.154,0 94.584,42.43 94.584,94.583 0,52.154 -42.43,94.584 -94.584,94.584 z"
                    id="path2" />
            </g>
            <g
                id="g6"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g8"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g10"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g12"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g14"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g16"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g18"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g20"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g22"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g24"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g26"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g28"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g30"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g32"
                transform="translate(2.1060683e-5,-0.01615465)" /><g
                id="g34"
                transform="translate(2.1060683e-5,-0.01615465)" />
        </SvgIcon>
    }
}
export default withStyles(style)(Ring);