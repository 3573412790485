import {
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    grayCardHeader,
    blueCardHeader,
    redCardHeader,
    yellowCardHeader,
    violetCardHeader,
    orangeCardHeader,
} from "assets/jss/material-dashboard-pro-react.jsx";
const cardIconStyle = {
    cardIcon: {
        "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$grayCardHeader,&$blueCardHeader,&$redCardHeader,&$yellowCardHeader,&$violetCardHeader,&$orangeCardHeader": {
            borderRadius: "3px",
            backgroundColor: "#999",
            padding: "12px",
            marginTop: "-20px",
            marginRight: "15px",
            float: "left",
            lineHeight: "12px",
        }
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    grayCardHeader,
    blueCardHeader,
    redCardHeader,
    yellowCardHeader,
    violetCardHeader,
    orangeCardHeader,
};

export default cardIconStyle;
