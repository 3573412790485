import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import XExpansionPanel from '../components/XExpansionPanel';
import Typography from '@material-ui/core/Typography';


import XTag from '../components/XTag';
import XSelect from '../components/XSelect';
//import XSwitch from '../components/XSwitch';
import XField from '../components/XField';
//import XButton from '../components/XButton';
import Grid from '@material-ui/core/Grid';
import XSwitch from '../components/XSwitch';
import XGrid from '../components/XGrid';
import XButton from '../components/XButton';

import { Done, Close } from '@material-ui/icons';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

class CustomTab extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    state = {
        data: {},
        isValueEditing: false,
        currentValue: {}
    };
    componentDidMount() {
        //this.clearSession();
        this.loadData();
    }
    loadData = () => {
        if (this.props.match.params.id && parseInt(this.props.match.params.id,10) > 0) {
            fetch('/Base/GetCustomTab/' + this.props.match.params.id)
                .then(res => res.json())
                .then(data => {
                    this.setState({ data });
                });
        }
    }
    handleSelectFieldChange = (key) => (event, value) => {
        //this.props.onChange(event, value, key);
        this.setState((state, props) => {
            if (value.length > 0) {
                state.data[key] = value[0].ID;
            }
            else {
                state.data[key] = undefined;
            }
            return state;
        });
    }
    handleFieldChange = (key) => (event, value) => {
        this.setState((state, props) => {
            if (value !== undefined || value !== null || !isNaN(value)) {
                state.data[key] = value;
            }
            else {
                state.data[key] = undefined;
            }
            return state;
        });
    }
    renderTab1 = () => {
        var { data } = this.state;
        var panels = [
            {
                icon: "people",
                label: "Dati generali",
                content: (<Grid container spacing={2}>
                    <Grid item container xs={12} direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}><XField label="Sigla" id="AbbreviationStr" value={data.AbbreviationStr} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XField label="Descrizione" id="DescriptionStr" value={data.DescriptionStr} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><XField label="Ordinamento" id="Position" value={data.Position} onChange={this.handleFieldChange} onBlur={this.handleFieldBlur} /></Grid>

                    </Grid>
                </Grid>),
            }];
        return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
    }
    handleExit = (event) => {
        //fetch('/IR/ClearSession', {
        //    method: 'POST'
        //}).then(res => {
            this.context.router.history.push('/CFTabs/');
        //});
    }
    handleNewData = (event, caller) => {
        if (this.context.router.route.location.pathname === '/CFTabNew') {
            //fetch('/IR/ClearSession', {
            //    method: 'POST'
            //}).then(res => {
                this.setState({ data: {} });
            //});
        }
        else {
            //this.clearSession(() => {
                this.context.router.history.push('/CFTabNew');
            //});
        }
    }

    handleCancelData = (event, caller) => {
        var FIDCustomField = this.props.match.params.id;
        if (!FIDCustomField) {
            FIDCustomField = 0;
        }
        fetch('/Base/DeleteCustomTab', {
            method: 'POST',
            body: JSON.stringify([FIDCustomField])
        }).then(res => {
            this.context.router.history.push('/CFTabs');
        });
    }
    handleSaveData = (event, caller) => {
        this.setState({ isLoading: true }, () => {

            var { data } = this.state;
            var FIDCustomField = this.props.match.params.id;
            if (!FIDCustomField) {
                FIDCustomField = 0;
            }
            fetch('/Base/SaveCustomTab/' + FIDCustomField, {
                body: JSON.stringify(data),
                method: 'POST'
            })
                //.then(this.handleErrors)
                .then(res => res.json()).then((data) => {
                if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
                    this.context.router.history.push('/CFTabEdit/' + data.id);
                }
                this.loadData();
                this.setState({ isLoading: false });
            })
                .catch(error => {
                    this.setState({ isLoading: false });
                });
        });
    }

    render() {
        var { data } = this.state;
        var { classes } = this.props;
        var loctabs = [
            {
                tabName: "Generale",
                tabContent: this.renderTab1(),

            },
        ];
        return (
            <div className={classes.root}>
                <Tabs
                    headerColor="primary"
                    plainTabs={true}
                    onExitClick={this.handleExit}
                    onSaveClick={this.handleSaveData}
                    onDeleteClick={this.handleCancelData}
                    onNewClick={this.handleNewData}
                    tabs={loctabs}
                />
            </div>
        );
    }
}




const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);
export default enhance(CustomTab);