import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class FileTypology extends Component {
    constructor(props) {
        super(props);
        //this.setState({ FIDParentColumnName: "Brand" });
        this.Editor = React.createRef();
    }
    state = {
        line: { FIDParent: 0 },
    };
    checkUsed = async (FIDLine) => {
        return await fetch('/cg/filetypology/IsUsed' + FIDLine).then(res => res.json());
        
    }
    deleteLine = (FIDLine) => {
        fetch('/cg/filetypology/delete/' + FIDLine, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione della tipologia?";
        if (isUsed) {
            label = "La tipologia è utilizzata nelle distinte. Continuando, sulle distinte veranno tolte le informazioni sulla tipologia. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteLine(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/gc/filetypology/save/'
            //urlDelete='/BOP/LineDelete/'
            urlRedirect='/FileTypology/'
            //urlUploadFiles='/BOP/LineFileUpload/'
            //urlDeleteFiles='/BOP/DeleteLineFile/'
            //urlGetFiles='/BOP/GetLineFiles/'
            //urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
            urlExit='/FileTypologies/'
            //urlNew="/line/new"
            urlGetData='/gc/filetypology/get/'
            //urlCheckChanges='/BOP/LineHasChanges/'
            //urlClearSession='/BOP/ClearSession/'
            //onDeleteClick={this.onDeleteClick}
            //fields={fields}
            AbbreviationKey="Abbreviation"
            DescriptionKey="Description"
            translate={true}
            routeTranslatableFields="/gc/filetypology/translatableFields/"
            routeLanguages="/gc/filetypology/languages/"
            routeDataT="/gc/filetypology/translations/"
            routeSaveData="/gc/filetypology/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(FileTypology);
