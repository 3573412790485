import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import XButton from 'components/XButton';
import {
    primaryColor,
    primaryColorLight,
    colorForPrimaryBackground
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    uploadPopup: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 3000,
        background: "rgba(255,255,255,0.7)",
    },
    uploadPopupContent: {
        position: "relative",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        width: "80%",
        height: "auto",
        background: "#fff",
        padding: "10px",
        textAlign: "center",
        //border: "1 px solid #555",
        boxShadow: "0px 0px 10px #555",
        "& > button": {
            marginTop: ".5rem"
        }
    },
    uploadPopupTitle: {
        left: "-10px",
        top: "-10px",
        position: "relative",
        padding: "10px",
        width: "calc(100% + 20px)",
        background: primaryColor,
        color: colorForPrimaryBackground
    },
    uploadProgressBar: {
        position: "relative",
        width: "100%",
        height: "40px",
        background: primaryColorLight,
        "& > span": {
            display: "block",
            color: "black",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            fontSize: "16px"
        }
    },
    uploadProgress: {
        height: "100%",
        background: primaryColor,
    },
    
});

class XProgress extends Component {
    render() {
        var { classes, progress, total, label, onCancel, cancelLabel, show } = this.props;

        var uploadProgress = 0;
        if(total > 0) {
            uploadProgress = Math.round((progress / total) * 100);
        }
        //if (uploadProgress === NaN)
        if( isNaN(uploadProgress))
            uploadProgress = 0;

        if (uploadProgress > 100)
            uploadProgress = 100;

        return (show ? <div className={classes.uploadPopup}>
            <div className={classes.uploadPopupContent}>
                <div className={classes.uploadPopupTitle}><Typography>{label}</Typography></div>
                <div className={classes.uploadProgressBar}><div className={classes.uploadProgress} style={{ width: uploadProgress + "%" }}></div>
                    <span>{uploadProgress + "%"}</span>
                </div>
                {cancelLabel && <XButton variant="container" onClick={() => {
                    if (onCancel) {
                        onCancel();
                    }
                }}>{cancelLabel}</XButton>}
            </div>
        </div> : <div></div>);
    }
}

export default withStyles(styles)(XProgress);