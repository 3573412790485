import React, { Component } from 'react';
import { withStyles, Grid, Checkbox } from '@material-ui/core';
import { AutoSizer, List } from 'react-virtualized';
import { Skeleton } from '@material-ui/lab';
import { format, getXSelect } from 'utils';
import XGallery from 'components/XGallery';

const styles = theme => ({
    root: {
        overflowX: 'hidden',
        padding: 10
    },
    row: {
        borderBottom: '1px solid',
    }
});


class SupplierOrders extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows:[
                {},{},{},{},{},{},
            ],
            isLoading: true,
        }
        this.List = React.createRef();
    }
    componentDidMount(){
        this.loadData();
    }
    loadData = () =>{
        fetch(`/DOC/GetRowsForSupplyOrder`).then(res => res.json()).then(data =>{
            this.setState({
                rows: data,
                isLoading: false
            });
        })
    }
    renderRowLoading = ({index,isScrolling,key,style}) => {
        return <Grid key={key} container xs={12} className={this.props.classes.row} style={style} spacing={2}>
            <Grid item xs={12} sm={2}>
                <Skeleton variant="rect" width="100%" height="100%" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Grid>
        </Grid>;
    }
    handleSelectChange = (index) =>  (key) => (event, value) =>{
        this.setState(state => {
            if(value.length > 0){
                state.rows[index][key] = value[0].ID;
            }
            else {
                state.rows[index][key] = undefined;
            }
            if(key === "FIDSupplier"){
                if(value.length > 0){
                    state.rows[index].SupplierCost = value[0].SupplierCost;
                }
                else {
                    state.rows[index].SupplierCost = undefined;
                }    
            }
            return state;
        }, () => {
            this.List.current.forceUpdateGrid();
        });
    }
    handleChecktChange = (index) => () => {
        var { onSelectionChanged } = this.props;
        this.setState(state =>{
            state.rows[index].IsSelected = !state.rows[index].IsSelected;
            return state;
        }, () => {
            this.List.current.forceUpdateGrid();
            onSelectionChanged && onSelectionChanged(this.state.rows.filter(s => s.IsSelected).length);
        });
    }
    GenerateOrders = () =>{
        var { onSelectionChanged } = this.props;
        var { rows } = this.state;
        var selectedRows = rows.filter(s => s.IsSelected);

        fetch(`/DOC/GenerateSupplierOrders`,{
            method: 'POST',
            body: JSON.stringify(selectedRows)
        }).then(res =>{
            this.setState({
                isLoading: true,
                rows: []
            },() =>{
                onSelectionChanged && onSelectionChanged(0);
                this.loadData();
            })
        });
    }
    renderRow =({index,key,style})=>{
        var { rows } = this.state;
        var row = rows[index];
        key = JSON.stringify(row);
        if(typeof row.DocumentDate === "string") {
            row.DocumentDate = new Date(row.DocumentDate);
        }
        var fields =[
            {
                Field: "FIDSupplier",
                Visibility: true,
                Required: 2,
            },
        ];
        var fieldSupplier = getXSelect(row, fields, 12, 12, "FIDSupplier", "", this.handleSelectChange(index), `/doc/GetBOPSuppliers/${row.FIDBOP}`,"",{ subtitleKey: "Address" });
        

        var files = row.Files ? row.Files.split(',').map(m => parseInt(m, 10)) : [];

        return <Grid className={this.props.classes.row} key={key} container xs={12} style={style} spacing={2}>
            <Grid item container spacing={2} xs={12} sm={2}>
                <Grid item xs={12} sm={3}>
                    <Checkbox id={"sel_" + row.ID} checked={row.IsSelected} onChange={this.handleChecktChange(index)} />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <XGallery imageIds={files} hideThumbs={true} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <p>{row.Code}</p>
                <p>{row.Description}</p>
                <p>{row.ExtendedDescription}</p>
            </Grid>
            <Grid item xs={12} sm={2}>
                <p>{format(row.Price)}</p>
                <p>{row.Qty1} {row.UM1}</p>
                <p>{format(row.TotalAmount)}</p>
            </Grid>
            <Grid item xs={12} sm={2}>
                {fieldSupplier}
                <p>{format(row.SupplierCost * row.Qty1)}</p>
            </Grid>
            <Grid item xs={12} sm={2}>
                <p>{row.Customer}</p>
                <p>Ord n. {row.Number}</p>
                <p>{row.DocumentDate.toLocaleDateString()}</p>
            </Grid>
        </Grid>
    }
    render(){
        var { classes } = this.props;
        var { rows, isLoading } = this.state;
        //return <div className={classes.root}></div>;
        var listHeight=window.innerHeight - 85 - 60;
        var listRowHeight = 120;
        return (
            <div className={classes.root}>
                <AutoSizer disableHeight>
                    {({width}) =>(
                        <List 
                            ref={this.List}
                            className={classes.list}
                            height={listHeight}
                            overscanRowCount={5}
                            rowCount={rows.length}
                            rowHeight={listRowHeight}
                            rowRenderer={isLoading ? this.renderRowLoading : this.renderRow}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }
}

export default withStyles(styles)(SupplierOrders);