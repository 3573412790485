import React, { Component, Fragment } from 'react';
import XField from 'components/XField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import XMessageBox from 'components/XMessageBox';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import XSwitch from '../../components/XSwitch';
import XGallery from 'components/XGallery';
import XOXLoader from 'components/XOXLoader';
import XProductionButton from 'components/XProductionButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    fontSize: '12px',
    position: 'relative',
    width: "100%",
    paddingTop: "35px",
  },
  mainData: {
    cursor: "pointer",
    width: "95%",
    margin: "0 auto",
    border: "1px solid rgba(128,128,128,0.7)",
    borderBottom: 0,
    "&:last-of-type": {
      borderBottom: "1px solid rgba(128,128,128,0.7)",
      marginBottom: "20px"
    },
    "& button": {
      width: "95%",
      height: "50px",
      fontSize: "18px"
    },
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    marginBottom: "10px"
  },
})

class ProductionOperatorAssignedBOPS extends Component {
  constructor(props) {
    super(props);
    this.MessageBox = React.createRef();
    this.state = {
      assignedBOPS: [],
	  isLoading: true
    }
  }

  componentDidMount = () => {
    fetch('/Production/GetOperatorAssignedBOPS').then(res => res.json())
    .then(data => {
      this.setState({
        assignedBOPS: data,
		isLoading: false,
      });
    });
  }

  handleAssignedBOPClick = (index) => {
   var { onAssignedBOPClick } = this.props;
   var { assignedBOPS } = this.state;
    
   onAssignedBOPClick && onAssignedBOPClick(assignedBOPS[index]);
  }

  render() {
    var { classes } = this.props;
    var { assignedBOPS, isLoading } = this.state;
	
	if (isLoading) {
		return <XOXLoader />;
	}

    /*if (!assignedBOPS || assignedBOPS.length === 0) {
      return (<Typography>NESSUNA COMMESSA ASSEGNATA</Typography>);
    }*/

    return (
      <div id="main" className={classes.root}>
        <Grid containerID="main" container direction="column" spacing={0}>
          {(!assignedBOPS || assignedBOPS.length === 0) ? 
			<Typography>NESSUNA COMMESSA ASSEGNATA</Typography>
            : assignedBOPS.map((item, index) => {
              var bopData =(<Grid onClick={() => this.handleAssignedBOPClick(index)} className={classes.mainData} container direction="row" item xs={12}>
                <Grid item xs={2}>
                  {item && item.FileID && <XGallery bigImage={false} imageIds={[item.FileID]} />}
                </Grid>            
                <Grid item container direction="row" xs={10}>
                  <Grid item xs={6}><Typography>Commessa: {item.OrderRowCode} - {item.SubOrderRowCode}</Typography></Grid>
                  {item.ProductionRefOrderRow && <Grid item xs={4}><Typography>Comm. rif.: {item.ProductionRefOrderRow}</Typography></Grid>}
                  <Grid item xs={6}>{item.CatalogCode && <Typography>Cod. catalogo: {item.CatalogCode}</Typography>}</Grid>
                  <Grid item xs={6}><Typography>Codice: {item.Code}</Typography></Grid>
                  <Grid item xs={10}><Typography>Descrizione: {item.Description}</Typography></Grid>
                </Grid>
                {/* <Grid item direction="column" xs={2}>
                  <Grid item xs={12}><XProductionButton color="green" onClick={undefined}>START</XProductionButton></Grid>
                  <Grid item xs={12}><XProductionButton color="veryLightGray" onClick={undefined}>RICONSEGNA</XProductionButton></Grid>
                </Grid> */}
              </Grid>);
              return bopData;
            })
          }
        </Grid>
      </div>
    );
  }
}

const enhance = compose(
  connect(
    state => state.global,
    dispatch => bindActionCreators(globalCreators, dispatch)
  ),
  withStyles(styles)
);

export default enhance(ProductionOperatorAssignedBOPS);