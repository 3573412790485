import React, {Component} from 'react';

import IREdit from 'views/IR/IREdit';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';
import XNavigation from 'components/XNavigation';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { ArrowRight, ArrowLeft, SkipNext, AccountMultiple as People, AccountMultipleOutline as PeopleOutline } from 'mdi-material-ui';
import { compose } from 'redux';
import { withWidth } from '@material-ui/core';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const styles = theme => ({
});

class IdentitySelector extends Component {
    constructor(props) {
        super(props);

        this.IREdit = React.createRef();
        this.grdIR = React.createRef();
        this.MessageBox = React.createRef();
        this.state = {
        data: {},
        newIdentity: false,
            viewRows: "IR_COMPLETE",
    };
    }
    

    identitySelected = () => {
        var { onIdentitySelected } = this.props;
        var { data } = this.state;
        var FIDIdentity = data.ID;
        if (FIDIdentity && onIdentitySelected) {
            onIdentitySelected(FIDIdentity);
        }
    }
    saveIdentity = async () => {
        var saved = await this.IREdit.current.save(undefined, () => { });
        if (saved) {
            this.setState({ data: saved, newIdentity: false }, this.identitySelected);
        }
    }
    loadIdentity = () => {
        var selected = this.grdIR.current.getSelectedData();
        if (selected.length > 1) {
            this.MessageBox.current.showMessageOk("Selezione anagrafiche", <Typography>Selezionate più anagrafiche. Per continuare selezionare una sola anagrafica.</Typography>);
            return;
        }
        else {
            var selectedData = this.grdIR.current.getRowData(this.grdIR.current.getSelectedData()[0]);
            this.setState({ data: selectedData, newIdentity: false }, this.identitySelected);
        }
    }
    selectIdentity = () => {
        var { newIdentity } = this.state;
        if (newIdentity) {
            this.saveIdentity();
        }
        else {
            this.loadIdentity();
        }
    }
    handleNewIdentity = () => {
        this.setState({ newIdentity: true });
    }
    
    showIRComplete = () => {
        this.setState({
            viewRows: "IR_COMPLETE",
            incomplete: false
        }, this.grdIR.current.refresh);
    }
    showIRIncomplete = () => {
        this.setState({
            viewRows: "IR_INCOMPLETE",
            incomplete: true
        }, this.grdIR.current.refresh);
    }

    render() {
        var { classes, onCancel, showRelations,showDestinations,FIDIdentity, label, noNew, onSkip, showIncompleteSelector, width,containerID } = this.props;
        var { data, newIdentity,viewRows,incomplete } = this.state;
        var previousStep = undefined;
        var skipStep = undefined;
        if (onCancel) {
            // previousStep = <Tooltip title="Indietro">
            //     <IconButton aria-label="Indietro" onClick={onCancel} >
            //         <ArrowLeft />
            //     </IconButton>
            // </Tooltip>;
            previousStep = {
                group: 'actions',
                name: getTranslation(5,"GC_BACK",this.props.translations, 'Indietro'),
                icon: <ArrowLeft />,
                action: onCancel
            }
        } 
        if (onSkip) {
            // skipStep = <Tooltip title="Salta">
            //     <IconButton aria-label="Salta" onClick={onSkip} >
            //         <SkipNext />
            //     </IconButton>
            // </Tooltip>;
            skipStep = {
                group: 'actions',
                name: getTranslation(5,"GC_SKIP",this.props.translations, 'Salta'),
                icon: <SkipNext />,
                action: onSkip
            }
        }
        // var nextStep = <Tooltip title="Avanti">
        //     <IconButton aria-label="Avanti" onClick={this.selectIdentity} >
        //         <ArrowRight />
        //     </IconButton>
        // </Tooltip>;
        var nextStep = {
            group: 'actions',
            name:getTranslation(5,"GC_NEXT",this.props.translations, 'Avanti'),
            icon: <ArrowRight />,
            action: this.selectIdentity,
            condition: rows => rows && rows.length === 1,
        };


        // var customActions = [previousStep,{
        //     component: nextStep,
        //     condition: rows => rows && rows.length === 1,
        // }, skipStep];
        var customActions = [skipStep, nextStep, previousStep];
        var selectedActions = undefined; //[nextStep];
        var dataRoute = "/IR/GetData?grid=tblIR";
        var dataRouteColumns = "/Base/GetColumns?grid=tblIR";
        var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblIR";
        var dataRouteUpdateColumns = "/Base/UpdateColumns?grid=tblIR";
        var onDoubleClick = undefined;
        var customContent = undefined;
        var showFilterActivator = true;
        var showColumnsSelector = true;
        var newAction = this.handleNewIdentity;
        if (noNew) {
            newAction = false;
        }

        if (showRelations) {
            dataRoute += "&showRelations=true";
        }
        if (showDestinations) {
            dataRoute += "&showDestinations=true";
        }
        if (FIDIdentity) {
            dataRoute += "&FIDIdentity=" + FIDIdentity;
        }
        if(incomplete) {
            dataRoute += `&ShowIncomplete=${incomplete}`;
        }

        if(showIncompleteSelector){
            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }
            var navActions = [];
            navActions.push({ name: getTranslation(4,"IR_COMPLETE",this.props.translations, "COMPLETE"), icon: <People />, action: this.showIRComplete, selected: viewRows === "IR_COMPLETE" });
            navActions.push({ name: getTranslation(4,"IR_INCOMPLETE",this.props.translations, "INCOMPLETE"), icon: <PeopleOutline />, action: this.showIRIncomplete, selected: viewRows === "IR_INCOMPLETE" });
            
            label = <span><span style={{marginTop:8, display: "inline-block"}}><XNavigation actions={navActions} direction={direction} showLabel /></span></span>;
        }

        if (!label) {
            label = getTranslation(5,"GC_SELECTIR",this.props.translations, "Seleziona un'anagrafica");
        }
        if (newIdentity) {
            label = getTranslation(5,"GC_NEWIR",this.props.translations, "Inserimento nuova anagrafica");
            
			if (!previousStep) {
				previousStep = {
					group: 'actions',
					name:getTranslation(5,"GC_BACK",this.props.translations, 'Indietro'),
					icon: <ArrowLeft />
				};
			}
			
            previousStep.action = () => { this.setState({ newIdentity: false} )};
            nextStep.condition = undefined;
            customActions = [nextStep,previousStep];
            customContent = (<IREdit id={data.ID} innerRef={this.IREdit} topMargin="0px" headerColor="info" noRedirect={true} showExit={false} showSave={false} showDelete={false} showNew={false} />);
            showFilterActivator = false;
            showColumnsSelector = false;
            newAction = undefined;
        }

        return (
            <div className={classes.root}>
                <XGrid
                    innerRef={this.grdIR}
                    label={label}
                    containerID={containerID}
                    dataRoute={dataRoute}
                    dataRouteColumns={dataRouteColumns}
                    dataRouteAvailableColumns={dataRouteAvailableColumns}
                    dataRouteUpdateColumns={dataRouteUpdateColumns}
                    showFilterActivator={showFilterActivator}
                    showColumnsSelector={showColumnsSelector}
                    onNewButton={newAction}
                    //dataRouteDelete={""}
                    actions={customActions}
                    selectedActions={selectedActions}
                    customContent={customContent}
                    onDoubleClick={onDoubleClick}
                    width={this.props.width}
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}

const enhance = compose(
    withStyles(styles),
    withWidth(),
);
export default enhance(IdentitySelector);