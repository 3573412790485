import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import Tabs from 'components/CustomTabs/CustomTabs';
import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import { getXField, getXSwitch } from 'utils';

const styles = theme => ({
    root: {

    }
});


class EncryptConfig extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            initialData: {},
            firstLoad: true,
        };
    }

    componentDidMount = () => {
        fetch(`/GC/encrypt/`).then(res => res.json()).then(data => {
            this.setState(state =>{
                state.data = data;
                if(state.firstLoad){
                    state.initialData = { ...data };
                    state.firstLoad = false;
                }
                return state;
            })
        });
    }
    handleSaveData = () => {
        var { data } = this.state;

        fetch("/GC/encrypt", {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    handleFieldChange = (key) => (event, data) => {
        this.setState(state => {
            state.data[key] = data;
            return state;
        });
    }

    renderTabGeneralPanelGeneral = () =>{
        var { data } = this.state;
        var fields = [
            {
                Field: "removeDecimals",
                Visibility: true,
                Required: 2
            },
            {
                Field: "removeDecimalSeparator",
                Visibility: true,
                Required: 2
            },
            {
                Field: "removeThousandSeparator",
                Visibility: true,
                Required: 2
            },
            {
                Field: "invert",
                Visibility: true,
                Required: 2
            },
        ];
//data, fields, xs, md, id, label, handleChange, params
        var removeDecimalsField = getXSwitch(data, fields, 12, 12,"removeDecimals","Tronca decimali", this.handleFieldChange);
        var removeDecimalSeparatorField = getXSwitch(data, fields, 12, 12,"removeDecimalSeparator","Rimuovi separatore decimale", this.handleFieldChange);
        var removeThousandsSeparatorField = getXSwitch(data, fields, 12, 12,"removeThousandSeparator","Rimuovi separatore delle migliaia", this.handleFieldChange);
        var invertField = getXSwitch(data, fields, 12, 12,"invert","Inverti", this.handleFieldChange);
        return (<Grid container spacing={2}>
            {removeDecimalsField}
            {removeDecimalSeparatorField}
            {removeThousandsSeparatorField}
            {invertField}
        </Grid>);
    }
    renderTabGeneralPanelMappging = () => {
        var { data } = this.state;
        var fields = [
            {
                Field: "char0",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char1",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char2",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char3",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char4",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char5",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char6",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char7",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char8",
                Visibility: true,
                Required: 2
            },
            {
                Field: "char9",
                Visibility: true,
                Required: 2
            },
        ];
        var char0Field = getXField(data,fields,12,1,"char0","0",this.handleFieldChange, { maxLength: 1 });
        var char1Field = getXField(data,fields,12,1,"char1","1",this.handleFieldChange, { maxLength: 1 });
        var char2Field = getXField(data,fields,12,1,"char2","2",this.handleFieldChange, { maxLength: 1 });
        var char3Field = getXField(data,fields,12,1,"char3","3",this.handleFieldChange, { maxLength: 1 });
        var char4Field = getXField(data,fields,12,1,"char4","4",this.handleFieldChange, { maxLength: 1 });
        var char5Field = getXField(data,fields,12,1,"char5","5",this.handleFieldChange, { maxLength: 1 });
        var char6Field = getXField(data,fields,12,1,"char6","6",this.handleFieldChange, { maxLength: 1 });
        var char7Field = getXField(data,fields,12,1,"char7","7",this.handleFieldChange, { maxLength: 1 });
        var char8Field = getXField(data,fields,12,1,"char8","8",this.handleFieldChange, { maxLength: 1 });
        var char9Field = getXField(data,fields,12,1,"char9","9",this.handleFieldChange, { maxLength: 1 });
        return (<Grid container spacing={2}>
            {char0Field}
            {char1Field}
            {char2Field}
            {char3Field}
            {char4Field}
            {char5Field}
            {char6Field}
            {char7Field}
            {char8Field}
            {char9Field}
        </Grid>);
    }

    renterTabGeneralPanels = () =>{
        var panels = [];
        panels.push({
            icon: "people",
                label: "Dati generali",
                content: this.renderTabGeneralPanelGeneral()
        });
        
        panels.push({
            icon: "people",
                label: "Mapping cifre",
                content: this.renderTabGeneralPanelMappging()
        });

        return panels;
    }
    renderTabGeneral = () =>{
        return (
            <TabContainer>
                <XExpansionPanel panels={this.renterTabGeneralPanels()} />
            </TabContainer>
        );
    }
    renderTabs = () => {
        return [
            {
                tabName: "Generale",
                tabContent: this.renderTabGeneral()
            }
        ];
    }

    render(){
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <Tabs
                    onSaveClick={this.handleSaveData}
                    headerColor="primary"
                    plainTabs={true}
                    tabs={this.renderTabs()}
                />
            </div>
        );
    }
}

export default withStyles(styles)(EncryptConfig);