import React, { Component } from 'react';

import PropTypes from 'prop-types';

import XExpansionPanel from 'components/XExpansionPanel';

//import XGrid from '../components/XGrid';
import XTag from '../../components/XTag';
import XSelect from '../../components/XSelect';
//import XSwitch from '../components/XSwitch';
import XField from '../../components/XField';
//import XButton from '../components/XButton';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

import IRContacts from './IRContacts.jsx';
import IRIntendDeclarations from './IRIntendDeclarations.jsx';

import { getXDateField, getXSelect, getXField, getXFieldAutocomplete, getXSwitch, XSelectX } from 'utils';

import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';

var styles = (theme) => ({

});

class IRStandardFields extends Component {
    constructor(props) {
        super(props);

        this.contacts = React.createRef();
        this.
        state = {
            data: props.data ? props.data : {},
            fields: [],
            ReloadFields: true,
            dataupdate: new Date().getTime()
        };
    }
    componentDidMount() {
        this.loadFields();
    }
    componentDidUpdate (prevProps,prevState) {
        console.log(prevProps,prevState);
        if (this.state.ReloadFields) {
            this.setState({ ReloadFields: false });
            this.loadFields();
        }
    }
    getGeoDataID = (FID) => {
        if (FID === "") {
            return undefined;
        }
        return FID;
    }

    handleChange = (key) => (event, data, firstLoad = false) => {
        if (data.length > 0) {
            this.props.onChange(event, data[0].ID, key, data[0], firstLoad);
        }
        else {
            this.props.onChange(event, undefined, key, undefined, firstLoad);
        }

        if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
            this.setState({ ReloadFields: true });
        }
    }
    handleFieldChange = (key) => (event, value) => {
        if (this.props.onChange) {
            this.props.onChange(event, value, key);
        }
        if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
            this.setState({ ReloadFields: true });
        }
        this.setState({dataupdate: new Date().getTime()});
    }

    handleDataFieldChange = (key) => (value) => {
        if (this.props.onChange) {
            this.props.onChange(null, value, key);
        }
        this.setState({dataupdate: new Date().getTime()});
    }

    loadFields = () => {
        var { data } = this.props;
        var i = 0;

        var params = "";
        if (data.FIDGroup) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "FIDGroup=" + data.FIDGroup;
        }
        if (data.TypologyIds) {
            for (i = 0; i < data.TypologyIds.length; i++) {
                if (params !== "") {
                    params += "&";
                }
                else {
                    params += "?";
                }
                params += "TypologyIds[" + i + "]=" + data.TypologyIds[i];
            }
        }
        if (data.CategoryIds) {
            for (i = 0; i < data.CategoryIds.length; i++) {
                if (params !== "") {
                    params += "&";
                }
                else {
                    params += "?";
                }
                params += "CategoryIds[" + i + "]=" + data.CategoryIds[i];
            }
        }

        fetch('/IR/GetFields' + params).then(res => res.json()).then(fields => {
            this.setState({ fields });
        });
    }
    handleFieldBlur = (key) => (event) => {
        if (this.props.onBlur) {
            this.props.onBlur(event, key);
        }
    }
    refresh = () => {
        this.contacts.current.getWrappedInstance().refresh();
    }

    getField = (fields, field) => {
        var filtered = fields.filter((item) => item.Field === field);
        if (filtered.length > 0) {
            return filtered[0];
        }

        return undefined;
    }
    validate = () => {
        var { fields } = this.state;
        var { data } = this.props;
        var isValid = true;
        var requiredFields = [];
        var optionalFields = [];

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            if ((field.Required === 2 || (field.Required === 1 && !field.Validated)) && (!data[field.Field] || data[field.Field].length === 0)) {
                console.log(data, data[field.Field], field);
                isValid = false;
                if (field.Required === 1) {
                    fields[i].Validated = true;
                    //this.setState((state, props) => {
                    //    debugger;
                    //    state.fields[i].Validated = true;
                    //    return state;
                    //});
                    optionalFields.push(field.Field);
                }
                else {
                    requiredFields.push(field.Field);
                }
            }
        }
        this.setState({ fields });
        return { isValid, requiredFields, optionalFields };
    }

    render() {
        var { data, showCodes, caller, userID } = this.props;
        var { fields } = this.state;

        var params = caller && caller.props ? caller.props.params : false;

        var { PrivateGroupId, CompanyGroupId, IndividualCompanyGroupId, CustomerTypologyId, SupplierTypologyId, ContactTypologyId } = params;

        if (!PrivateGroupId)
            PrivateGroupId = 1;

        if (!CompanyGroupId)
            CompanyGroupId = 2;

        if (!IndividualCompanyGroupId)
            IndividualCompanyGroupId = 3;

        if (!ContactTypologyId)
            ContactTypologyId = 1;

        if (!CustomerTypologyId)
            CustomerTypologyId = 2;

        if (!SupplierTypologyId)
            SupplierTypologyId = 1;

        var isSupplier = false;
        var isCustomerOrContact = false;

        var isPrivate = false;
        var isCompany = false;

        if (data && data.FIDGroup) {
            if ((CompanyGroupId && data.FIDGroup === CompanyGroupId) || (IndividualCompanyGroupId && data.FIDGroup === IndividualCompanyGroupId))
                isCompany = true;
            else if (PrivateGroupId && data.FIDGroup === PrivateGroupId)
                isPrivate = true;
        }

        if (data && data.TypologyIds && data.TypologyIds.length > 0 && ((CustomerTypologyId && data.TypologyIds.includes(CustomerTypologyId)) || (ContactTypologyId && data.TypologyIds.includes(ContactTypologyId)))) {
            isCustomerOrContact = true;
        }

        if (data && data.TypologyIds && data.TypologyIds.length > 0 && ((SupplierTypologyId && data.TypologyIds.includes(SupplierTypologyId)))) {
            isSupplier = true;
        }

        if (!data.ID) {
            if (userID > 0 && !data.FIDLicenseeUserRef) {
                data.FIDLicenseeUserRef = userID;

                if (!data.FIDLicenseeDestinationRef && caller && caller.props.FIDLicenseeDestination)
                    data.FIDLicenseeDestinationRef = caller.props.FIDLicenseeDestination;
               
                var comboLicenseeUserData = [ { ID: userID } ]; 
                var comboLicenseeDestData = [ { ID: data.FIDLicenseeDestinationRef }];  
                this.handleChange("FIDLicenseeUserRef")(undefined, comboLicenseeUserData, true);
                this.handleChange("FIDLicenseeDestinationRef")(undefined, comboLicenseeDestData, true);
            }

            if (params.STDFIDPricelist) {
               if (data.FIDGroup && (isSupplier || isCustomerOrContact)) {
                   if (!data.FIDPricelistB2C)
                    data.FIDPricelistB2C = params.STDFIDPricelist;

                   if (data.PricelistSource === 1 && !data.FIDPriceList) {
                       data.FIDPriceList = params.STDFIDPricelist;
                   }
               }
            }

            if (params.STDFIDVat && !data.FIDVat) {
                if(data.FIDGroup && (isCustomerOrContact || isSupplier)) {
                    data.FIDVat = params.FIDVat;
                }
            }
        }

        var addressKey = "IR_ADDRESS";
        var defAddressLabel = "Indirizzo";
        switch(data.FIDGroup){
            case PrivateGroupId:
                addressKey = "IR_ADDRESS_PRIVATE";
                defAddressLabel = "Indirizzo di residenza";
                break;
            case CompanyGroupId: case IndividualCompanyGroupId:
                addressKey = "IR_ADDRESS_COMPANY";
                defAddressLabel = "Indirizzo sede legale";
                break;
            default:
                break;
        }

        var lblAddress = getTranslation(4,addressKey,this.props.translations) || defAddressLabel;


        //var fieldGroup = this.getField(fields, "FIDGroup") && this.getField(fields, "FIDGroup").Visibility && (<Grid item xs={12} md={6}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDGroup")} label="Gruppo" id="FIDGroup" urlData="/Base/GetIRGroups" urlCreate="/IR/CreateGroup" value={data.FIDGroup} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldGroup = getXSelect(data, fields, 12, 6, "FIDGroup", getTranslation(4,"IR_GROUP",this.props.translations, "Gruppo"), this.handleChange, "/Base/GetIRGroups", "IR/CreateGroup", { readOnly: this.props.readOnly });

        var fieldTypology = this.getField(fields, "TypologyIds") && this.getField(fields, "TypologyIds").Visibility && (<Grid item xs={12} md={6}><XTag readOnly={this.props.readOnly} field={this.getField(fields, "TypologyIds")} label={getTranslation(4,"IR_TYPOLOGY",this.props.translations, "Tipologia")} id="TypologyIds" urlData="/Base/GetIRTypologies" value={data.TypologyIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>);
        
        var fieldCategory = this.getField(fields, "CategoryIds") && this.getField(fields, "CategoryIds").Visibility && (<Grid item xs={12} md={6}><XTag readOnly={this.props.readOnly} field={this.getField(fields, "CategoryIds")} label={getTranslation(4,"IR_TAG",this.props.translations, "Tag") } id="CategoryIds" urlData="/Base/GetIRCategories" urlCreate="/IR/CreateCategory" value={data.CategoryIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>);

        var fieldCode = getXField(data, fields, 12, 6, "Code", getTranslation(4,"IR_CODE",this.props.translations, "Codice"), this.handleFieldChange, {readOnly: this.props.readOnly});

      var fieldDenomination = data && data.ID > 0 ?
        getXField(data, fields, 12, 3, "Denomination", getTranslation(4, "IR_DENOMINATION", this.props.translations, "Ragione sociale"), this.handleFieldChange, { readOnly: this.props.readOnly, onBlur: this.handleFieldBlur, sm: 6 })
        : getXFieldAutocomplete(data, fields, 12, 3, "Denomination", getTranslation(4, "IR_DENOMINATION", this.props.translations, "Ragione sociale"), this.handleFieldChange, (option) =>
          (<Link style={{ color: "#3C4858 !important" }} to={"/IREdit/" + option.ID}><span style={{ fontSize: ".75rem", color: "#3C4858 !important" }}>{option.Denomination + (option.Address ? (" - " + option.Address) : "") + (option.Zip ? (" - " + option.Zip) : "") + (option.City ? (" - " + option.City) : "") + (option.Province ? (" - " + option.Province) : "") + (option.Province ? (" - " + option.Province) : "") + (option.Region ? (" - " + option.Region) : "") + (option.Country ? (" - " + option.Country) : "")}</span></Link>), "/IR/GetSuggestions", "/IREdit/", "Denomination", { readOnly: this.props.readOnly, onBlur: this.handleFieldBlur, sm: 6 });

        var fieldAlias = getXField(data, fields, 12, 3, "Alias",getTranslation(4,"IR_ALIAS",this.props.translations, "Alias"), this.handleFieldChange, {readOnly: this.props.readOnly, sm:6 });

        //var fieldName = getXField(data, fields, 12, 2, "Name",getTranslation(4,"IR_NAME",this.props.translations) ||  "Nome", this.handleFieldChange, {readOnly: this.props.readOnly, sm:6 });
         var fieldName = data && data.ID > 0 ? getXField(data, fields, 12, 2, "Name",getTranslation(4,"IR_NAME",this.props.translations, "Nome"), this.handleFieldChange, {readOnly: this.props.readOnly, sm:6 }) 
        : getXFieldAutocomplete( data, fields, 12, 2, "Name", getTranslation(4,"IR_NAME",this.props.translations, "Nome"), this.handleFieldChange,
          (option) =>
            (<Link style={{ color: "#3C4858 !important" }} to={"/IREdit/" + option.ID}><span style={{ fontSize: ".75rem", color: "#3C4858 !important" }}>{option.Denomination + (option.Address ? (" - " + option.Address) : "") + (option.Zip ? (" - " + option.Zip) : "") + (option.City ? (" - " + option.City) : "") + (option.Province ? (" - " + option.Province) : "") + (option.Province ? (" - " + option.Province) : "") + (option.Region ? (" - " + option.Region) : "") + (option.Country ? (" - " + option.Country) : "")}</span></Link>),
        "/IR/GetSuggestions", "/IREdit/", "Name", {readOnly: this.props.readOnly, sm:6 });

        var fieldSurname = data && data.ID > 0 ? getXField(data, fields, 12, 2, "Surname",getTranslation(4,"IR_SURNAME",this.props.translations, "Cognome"), this.handleFieldChange, {readOnly: this.props.readOnly, sm:6 })      
            : getXFieldAutocomplete(data, fields, 12, 2, "Surname", getTranslation(4, "IR_SURNAME", this.props.translations, "Cognome"), this.handleFieldChange, (option) =>
            (<Link style={{ color: "#3C4858 !important" }} to={"/IREdit/" + option.ID}><span style={{ fontSize: ".75rem", color: "#3C4858 !important" }}>{option.Denomination + (option.Address ? (" - " + option.Address) : "") + (option.Zip ? (" - " + option.Zip) : "") + (option.City ? (" - " + option.City) : "") + (option.Province ? (" - " + option.Province) : "") + (option.Province ? (" - " + option.Province) : "") + (option.Region ? (" - " + option.Region) : "") + (option.Country ? (" - " + option.Country) : "")}</span></Link>),
        "/IR/GetSuggestions", "/IREdit/", "Denomination", { readOnly: this.props.readOnly, sm: 6 });


        var fieldVatnumber = getXField(data, fields, 12, 3, "Vatnumber", getTranslation(4,"IR_VATNUMBER",this.props.translations, "Partita IVA"), this.handleFieldChange, {readOnly: this.props.readOnly, onBlur: this.handleFieldBlur, sm:6 });

        var taxCodeWidthMD = isPrivate ? 4 : 3;

        var fieldTaxCode = getXField(data, fields, 12, taxCodeWidthMD, "Taxcode", getTranslation(4,"IR_TAXCODE",this.props.translations, "Codice Fiscale"), this.handleFieldChange, {readOnly: this.props.readOnly, sm:6 });
        
        var fieldAddress = getXField(data, fields, 12, 12, "Address", lblAddress, this.handleFieldChange, {readOnly: this.props.readOnly });

        //var fieldCity = this.getField(fields, "FIDCity") && this.getField(fields, "FIDCity").Visibility && (<Grid item xs={12} sm={6} md={3}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDCity")} label="Città" id="FIDCity" urlData="/Base/GetIRCities" urlCreate="/IR/CreateCity" filters={[{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }]} value={data.FIDCity} idKey="ID" valueKey="City" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        //var fieldCity = getXSelect(data, fields, 12, 3, "FIDCity", getTranslation(4,"IR_CITY",this.props.translations) || "Città", this.handleChange, "/Base/GetIRCities", "IR/CreateCity", { readOnly: this.props.readOnly, filters: [{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }], sm: 6, valueKey:"City"});
        var fieldCity = <XSelectX data={data} fields={fields}  sm={12} md={3} id="FIDCity" label={getTranslation(4,"IR_CITY",this.props.translations, "Città")} handleChange={this.handleChange} urlData="/Base/GetIRCities" urlCreate="IR/CreateCity" params={{ readOnly: this.props.readOnly, filters: [{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }], sm: 6, valueKey:"City"}} />;

        
        //var fieldZip = this.getField(fields, "FIDZip") && this.getField(fields, "FIDZip").Visibility && (<Grid item xs={4} sm={2} md={2}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDZip")} label="CAP" id="FIDZip" urlData="/Base/GetIRZips" urlCreate="/IR/CreateZip" filters={[{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }, { key: "FIDCity", value: data.FIDCity }]} value={data.FIDZip} idKey="ID" valueKey="Zip" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldZip = getXSelect(data, fields, 4, 2, "FIDZip", getTranslation(4,"IR_ZIP",this.props.translations, "CAP"), this.handleChange, "/Base/GetIRZips", "IR/CreateZip", { readOnly: this.props.readOnly, filters: [{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }, { key: "FIDProvince", value: data.FIDProvince }, { key: "FIDCity", value: data.FIDCity }], sm: 2, valueKey:"Zip"});

        //var fieldProvince = this.getField(fields, "FIDProvince") && this.getField(fields, "FIDProvince").Visibility && (<Grid item xs={8} sm={4} md={2}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDProvince")} label="Provincia" id="FIDProvince" urlData="/Base/GetIRProvinces" urlCreate="/IR/CreateProvince" filters={[{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }]} value={data.FIDProvince} idKey="ID" valueKey="Province" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldProvince = getXSelect(data, fields, 8, 2, "FIDProvince", getTranslation(4,"IR_PROVINCE",this.props.translations, "Provincia"), this.handleChange, "/Base/GetIRProvinces", "IR/CreateProvince", { readOnly: this.props.readOnly, filters: [{ key: "FIDCountry", value: data.FIDCountry }, { key: "FIDRegion", value: data.FIDRegion }], sm: 4, valueKey:"Province"});

        //var fieldRegion = this.getField(fields, "FIDRegion") && this.getField(fields, "FIDRegion").Visibility && (<Grid item xs={12} sm={6} md={2}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDRegion")} label="Regione" id="FIDRegion" urlData="/Base/GetIRRegions" urlCreate="/IR/CreateRegion" filters={[{ key: "FIDCountry", value: data.FIDCountry }]} value={data.FIDRegion} idKey="ID" valueKey="Region" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldRegion = getXSelect(data, fields, 12, 2, "FIDRegion", getTranslation(4,"IR_REGION",this.props.translations, "Regione"), this.handleChange, "/Base/GetIRRegions", "IR/CreateRegion", { readOnly: this.props.readOnly, filters: [{ key: "FIDCountry", value: data.FIDCountry }], sm: 6, valueKey:"Region"});

        //var fieldCountry = this.getField(fields, "FIDCountry") && this.getField(fields, "FIDCountry").Visibility && (<Grid item xs={12} sm={6} md={3}><XSelect readOnly={this.props.readOnly} field={this.getField(fields, "FIDCountry")} label="Nazione" id="FIDCountry" urlData="/Base/GetIRCountries" urlCreate="/IR/CreateCountry" value={data.FIDCountry} idKey="ID" valueKey="Country" abbreviationKey="Abbreviation" onChange={this.handleChange} /></Grid>);
        var fieldCountry = getXSelect(data, fields, 12, 3, "FIDCountry", getTranslation(4,"IR_COUNTRY",this.props.translations, "Nazione"), this.handleChange, "/Base/GetIRCountries", "IR/CreateCountry", { readOnly: this.props.readOnly, sm: 6, valueKey:"Country"});

        var fieldInternalRemark = getXField(data, fields, 12, 6, "Remarkinternal", getTranslation(4,"IR_REMARKINTERNAL",this.props.translations, "Nota interna"), this.handleFieldChange, {readOnly: this.props.readOnly, multiline: true });

        var fieldPublicRemark = getXField(data, fields, 12, 6, "Remarkpublic", getTranslation(4,"IR_REMARKPUBLIC",this.props.translations, "Nota pubblica"), this.handleFieldChange, {readOnly: this.props.readOnly, multiline: true });

        var fieldDistributionLists = this.getField(fields, "DistributionListIds") && this.getField(fields, "DistributionListIds").Visibility && (<Grid item xs={12} md={6}><XTag readOnly={this.props.readOnly} field={this.getField(fields, "DistributionListIds")} label={getTranslation(4,"IR_DISTLIST",this.props.translations, "Liste di distribuzione")} id="DistributionListIds" urlData="/IR/DistributionList" urlCreate="/IR/DistributionList" value={data.DistributionListIds} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={this.handleFieldChange} /></Grid>);

        var fieldDatestart = getXDateField(data, fields, 12, 3, "Datestart", getTranslation(4,"IR_DATESTART",this.props.translations, "Data inizio attività"), this.handleDataFieldChange);
        var fieldDateend = getXDateField(data, fields, 12, 3, "Dateend", getTranslation(4,"IR_DATEEND",this.props.translations, "Data fine attività"), this.handleDataFieldChange);
        var fieldDefaultSpedition = getXSwitch(data, fields, 12,3, "DefaultSpedition",getTranslation(4,"IR_DEFAULTSPEDITION",this.props.translations, "Predefinito spedizione"), this.handleFieldChange);
        var fieldDefaultInvoice = getXSwitch(data, fields, 12,3, "DefaultInvoice",getTranslation(4,"IR_DEFAULTINVOICE",this.props.translations, "Predefinito fatturazione"), this.handleFieldChange);
        var fieldIsIdentity = getXSwitch(data, fields, 12,3, "IsIdentity",getTranslation(4,"IR_ISIDENTITY",this.props.translations, "Anagrafica principale"), this.handleFieldChange);
		var fieldIsLicensee = getXSwitch(data, fields, 12,3, "IsLicensee",getTranslation(4,"IR_ISLICENSEE",this.props.translations, "Anagrafica licenziatario"), this.handleFieldChange);
        var fieldLicenseeDestinationRef = getXSelect(data, fields, 12, 3, "FIDLicenseeDestinationRef", "Negozio di riferimento", this.handleChange, "/IR/GetLicenseeDestinations");
        var fieldLicenseeUserRef = getXSelect(data, fields, 12, 3, "FIDLicenseeUserRef", "Operatore di riferimento", this.handleChange, "/IR/GetLicenseeUsers");
        
        var fieldPricelistWeb = false;//getXSelect(data, fields, 12, 3, "FIDPriceListWeb", getTranslation(4,"IR_PRICELISTWEB",this.props.translations) || "Listino Web", this.handleChange, "/GC/Pricelist");
        var fieldDiscount = getXField(data, fields, 12, 3, "DiscountDesc", getTranslation(4,"IR_DISCOUNT",this.props.translations, "Sconto %"), this.handleFieldChange);
        var fieldSDICode = getXField(data, fields, 12, 3, "SDICode", getTranslation(4,"IR_SDICODE",this.props.translations, "Codice SDI"), this.handleFieldChange);
        var fieldVAT = getXSelect(data, fields, 12, 3, "FIDVat", getTranslation(4,"IR_TAXRATE",this.props.translations, "Aliquota IVA"), this.handleChange, "/GC/VAT");
        var fieldPaymentMethod = getXSelect(data, fields, 12, 3, "FIDPayment",getTranslation(4,"IR_PAYMENT",this.props.translations,"Modalità di pagamento"), this.handleChange, "/doc/paymentmethod");

        var fieldTitle = getXSelect(data, fields, 12, 2, "FIDTitle", getTranslation(4,"IR_TITLE",this.props.translations,"Titolo"), this.handleChange, "/IR/title", "IR/title", { readOnly: this.props.readOnly, sm: 6});
        var fieldAppellation = getXSelect(data, fields, 12, 2, "FIDAppellation", getTranslation(4,"IR_APPELLATION",this.props.translations, "Appellativo"), this.handleChange, "/IR/appellation", "IR/appellation", { readOnly: this.props.readOnly, sm: 6});
        var fieldDefaultLanguage = getXSelect(data, fields, 12, 3, "FIDDefaultLanguage", getTranslation(4,"IR_DEFAULTLANGUAGE",this.props.translations, "Lingua predefinita"), this.handleChange, "/GCLanguage/Get");
        
        var fieldPricelistB2C = getXSelect(data, fields, 12, 3, "FIDPricelistB2C", getTranslation(4,"IR_PRICELISTB2C",this.props.translations, "Listino B2C"), this.handleChange, "/GC/Pricelist");
        var fieldPricelistSource = getXSelect(data, fields, 12, 3, "PricelistSource", getTranslation(4,"IR_PRICELISTSOURCE",this.props.translations, "Tipologia prezzo B2B"), this.handleChange, "/enums/irpricelistsource");
        var fieldDiscountWholesale = undefined;
        var fieldPricelist = undefined;
        if(data.PricelistSource === 0){
            fieldDiscountWholesale = getXField(data, fields, 12, 3, "DiscountWholesale", getTranslation(4,"IR_DISCOUNTWHOLESALE",this.props.translations, "Sconto rivenditore"), this.handleFieldChange, {readOnly: this.props.readOnly, multiline: false });
        }
        else {
            fieldPricelist = getXSelect(data, fields, 12, 3, "FIDPriceList", getTranslation(4,"IR_PRICELIST",this.props.translations, "Listino B2B"), this.handleChange, "/GC/Pricelist");
        }
        
        showCodes = true;
        var panels = [];
        if (showCodes) {
            panels.push({
                icon: "code",
                label: getTranslation(4,"IR_ENCODING",this.props.translations, "Codifica"),
                content: (<Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <img style={{ maxHeight: "145px", width: "auto", maxWidth: "300px", left: "50%", top: "50%", position: "relative", transform: "translate(-50%,-50%)" }} alt="" src={"/IR/DefaultImage/" + data.ID /*+ "?" + (new Date()).getTime()*/} />
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            {fieldGroup}
                            {fieldTypology}
                        </Grid>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            {fieldCode}
                        </Grid>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            {fieldCategory}
                            {fieldDistributionLists}
                        </Grid>
                    </Grid>
                </Grid>),
            });
        }

        if (fieldDenomination || fieldAlias || fieldName || fieldSurname || fieldVatnumber || fieldTaxCode || fieldAddress || fieldCity || fieldZip || fieldProvince || fieldRegion || fieldCountry) {
            panels.push({
                icon: "perm_identity",
                label: getTranslation(4,"IR_IDENTITYDATA",this.props.translations, "Dati Anagrafici"),
                content: (<Grid container spacing={2} >
                    <Grid item container xs={12} direction="row" spacing={2}>
                        {fieldAppellation}
                        {fieldTitle}
                        {fieldDenomination}
                        {fieldAlias}
                        {fieldName}
                        {fieldSurname}
                        {fieldVatnumber}
                        {fieldTaxCode}
                    </Grid>
                    {fieldAddress}
                    <Grid item container xs={12} direction="row" spacing={2}>
                        {fieldCity}
                        {fieldZip}
                        {fieldProvince}
                        {fieldRegion}
                        {fieldCountry}
                    </Grid>
                </Grid>),
            });
        }
        var identityId = this.props.id;
        if (identityId === undefined) {
            identityId = 0;
        }
        panels.push({
            icon: "contact_phone",
            label: getTranslation(4,"IR_CONTACTS",this.props.translations, "Recapiti"),
            id: "IRContactsContainer",
            content: (<IRContacts innerRef={this.contacts} FIDIdentity={identityId} isDestination={this.props.isDestination} isRelation={this.props.isRelation} readOnly={this.props.readOnly} />),
        });
        if (fieldInternalRemark || fieldPublicRemark) {
            panels.push({
                icon: "note_add",
                label:getTranslation(4,"IR_REMARKS",this.props.translations, "Note"),
                content: (<Grid container spacing={2}>
                    {fieldInternalRemark}
                    {fieldPublicRemark}
                </Grid>),
            });
        }
        if (fieldDateend || fieldDatestart || fieldDefaultInvoice || fieldDefaultSpedition || fieldIsIdentity || fieldDefaultLanguage) {
            panels.push({
                icon: "code",
                label: getTranslation(4,"IR_MANAGEMENT",this.props.translations, "Gestione"),
                content: (<Grid container spacing={2}>
                    {fieldDatestart}
                    {fieldDateend}
                    {fieldLicenseeDestinationRef}
                    {fieldLicenseeUserRef}
                    {fieldDefaultInvoice}
                    {fieldDefaultSpedition}
                    {fieldIsIdentity}
					{this.props.userID === -1990 && fieldIsLicensee}
                    {fieldDefaultLanguage}
                </Grid>),
            });
        }
        if (data.FIDGroup && (isCustomerOrContact || isSupplier)) {
            if (fieldDateend || fieldDiscount || fieldSDICode || fieldVAT || fieldPaymentMethod || fieldPricelist || fieldPricelistWeb ||   fieldPricelistB2C || fieldDiscountWholesale || fieldPricelistSource) {
                panels.push({
                    icon: "code",
                    label: getTranslation(4,"IR_COMMERCIAL",this.props.translations, "Commerciale"),
                    content: (<Grid container spacing={2}>
                        <Grid item container xs={12} direction="row" spacing={2}>
                            {fieldPricelistB2C}                       
                            {fieldPricelistSource}
                            {fieldPricelist}
                            {fieldDiscountWholesale}
                            {fieldDiscount}
                        </Grid>
                        <Grid item container xs={12} direction="row" spacing={2}>
                            {fieldPricelistWeb}
                            {fieldSDICode}
                            {fieldVAT}
                            {fieldPaymentMethod}
                        </Grid>
                    </Grid>),
                });
            }
        }

        panels.push({
            icon: "code",
            label: getTranslation(4,"IR_DECINTENT",this.props.translations, "Dichiarazione d'intento"),
            id: "IRIntentContainer",
            content: (<IRIntendDeclarations innerRef={this.contacts} FIDIdentity={identityId} isDestination={this.props.isDestination} isRelation={this.props.isRelation} readOnly={this.props.readOnly} />),
        });
        return (<XExpansionPanel
            panels={panels}
        />);
    }
}



IRStandardFields.propTypes = {
    data: PropTypes.object.isRequired,
    dataKey: PropTypes.string.isRequired,
    id: PropTypes.number,
    caller: PropTypes.object.isRequired,
    showCodes: PropTypes.bool,
    isIdentity: PropTypes.bool,
    isDestination: PropTypes.bool,
    isRelation: PropTypes.bool,
};



const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch),
        null,
        { withRef: true }
    ),
    withStyles(styles)
);

export default enhance(IRStandardFields);