import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const style = () => ({
});

class Series extends Component {
    constructor(props) {
        super(props);
        //this.setState({ FIDParentColumnName: "Brand" });
        this.Editor = React.createRef();
    }
    state = {
        series: { FIDParent: 0 },
    };
    checkUsed = async (FIDSeries) => {
        return await fetch('/BOP/SeriesIsUsed/' + FIDSeries).then(res => res.json());
        
    }
    deleteSeries = (FIDSeries) => {
        fetch('/BOP/SeriesDelete/' + FIDSeries, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione della serie?";
        if (isUsed) {
            label = "La serie è utilizzata nelle distinte. Continuando sulle distinte veranno tolte le informazioni sulla Serie. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteSeries(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        var { series } = this.state;

        var fields = this.props.userID === -1990 ? [
            {
                key: 'FIDBrand',
                label: 'Brand',
                type: 'XSelect',
                getUrl: '/Base/GetBOPBrands',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2
            },
            {
                key: 'FIDLine',
                label: 'Linea',
                type: 'XSelect',
                getUrl: '/Base/GetBOPLines',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2
            },
			{
				key: "Deactivated",
				label: getTranslation(5,"GC_DEACTIVATED", this.props.translations, "Disattivato") || "Disattivato",
				type: "XSwitch"
			},
        ] : [
			{
                key: 'FIDBrand',
                label: 'Brand',
                type: 'XSelect',
                getUrl: '/Base/GetBOPBrands',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2
            },
            {
                key: 'FIDLine',
                label: 'Linea',
                type: 'XSelect',
                getUrl: '/Base/GetBOPLines',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2
            }
		];

        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            //FIDParentColumnName='FIDBrand'
            //FIDParentColumnCaption='Brand'
            //urlGetParents='Base/GetBOPBrands'
            urlSave='/BOP/SaveSeries/'
            urlDelete='/BOP/SeriesDelete/'
            urlRedirect='/Series/'
            urlUploadFiles='/BOP/SeriesFileUpload/'
            urlDeleteFiles='/BOP/DeleteSeriesFile/'
            urlGetFiles='/BOP/GetSeriesFiles/'
            urlCheckDefaultFiles='/BOP/CheckDefaultSeriesFiles/'
            urlExit='/SeriesList/'
            urlNew="/series/new"
            urlGetData='/BOP/GetSeries/'
            urlCheckChanges='/BOP/SeriesHasChanges/'
            urlClearSession='/BOP/ClearSession/'
            onDeleteClick={this.onDeleteClick}
            fields={fields}
            translate={true}
            routeTranslatableFields="/bop/series/translatableFields/"
            routeLanguages="/bop/series/languages/"
            routeDataT="/bop/series/translations/"
            routeSaveData="/bop/series/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Series);
