import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class EIConfig extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    // checkUsed = async (FIDCountry) => {
    //     return await fetch('/IR/country/IsUsed/' + FIDCountry).then(res => res.json());
        
    // }
    // deleteBrand = (FIDCountry) => {
    //     fetch('/IR/country/Delete/' + FIDCountry, { method: "POST" }).then(res => {
    //         this.Editor.current.doExit();
    //     });
    // }
    // onDeleteClick = () => {
    //     var id = this.props.match.params.id;

    //     var isUsed = this.checkUsed(id);

    //     var label = "Confermi la cancellazione della nazione?";

    //     var buttons = [
    //         {
    //             label: "SI",
    //             action: () => {
    //                 this.Editor.current.hideDialog();
    //                 this.deleteBrand(id);
    //             }
    //         },
    //         {
    //             label: "NO",
    //             action: () => {
    //                 this.Editor.current.hideDialog();
    //             }
    //         },
    //     ];
    //     if (isUsed) {
    //         label = "La nazione è utilizzata. Non è possibile procedere con la cancellazione.";
    //         buttons = [
    //             {
    //                 label: "OK",
    //                 action: () => {
    //                     this.Editor.current.hideDialog();
    //                 }
    //             },
    //         ];
    //     }


    //     this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    // }
    render() {
        var fields = [
            {
                key: 'FIDLicensee',
                label: 'Licenziatario',
                type: 'XSelect',
                getUrl: '/IR/GetIRLicenseesSelect',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2,
                subtitleKey:'Address'
            },
            {
                key: 'FIDSDIRegimeFiscale',
                label: 'Regime fiscale',
                type: 'XSelect',
                getUrl: '/Base/GetSDICodes?Table=FISCALREGIM',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Description',
                abbreviationKey:'Code',
                Required: 2
            },
            {
                key: 'SDIProgressive',
                label: 'Progressivo',
                type: 'XField',
                Required: 1
            },
            {
                key: 'AlboProfessionale',
                label: 'Albo professionale',
                type: 'XField',
                Required: 1
            },
            {
                key: 'ProvinciaAlbo',
                label: 'Provincia albo',
                type: 'XField',
                Required: 1
            },
            {
                key: 'NumeroIscrizioneAlbo',
                label: 'Numero iscrizione albo',
                type: 'XField',
                Required: 1
            },
            {
                key: 'DataIscrizioneAlbo',
                label: 'Data iscrizione albo',
                type: 'XDateField',
                Required: 1
            },
            {
                key: 'REAUfficio',
                label: 'Ufficio',
                type: 'XField',
                Required: 1
            },
            {
                key: 'REANumero',
                label: 'Numero REA',
                type: 'XField',
                Required: 1
            },
            {
                key: 'REACapSoc',
                label: 'Capitale sociale',
                type: 'XField',
                Required: 1
            },
            
            {
                key: 'REASocioUnico',
                label: 'Socio unico',
                type: 'XSelect',
                Required: 1,
                
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                customData:[{ID: 'SU',Denomination:'SOCIO UNICO'},{ID: 'SM',Denomination:"PIU' SOCI"}]
            },
            {
                key: 'REAStatoLiquidazione',
                label: 'Stato liquidazione',
                type: 'XSelect',
                Required: 1,
                
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                customData:[{ID: 'LS',Denomination:'IN LIQUIDAZIONE'},{ID: 'LN',Denomination:"NON IN LIQUIDAZIONE"}]

            },
            {
                key: 'TISE_Manage',
                label: 'Gestisci terzo intermediario',
                type: 'XSwitch',
                Required: 1
            },
            {
                key: 'TISE_IdPaese',
                label: 'ID Paese',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_IdCodice',
                label: 'ID Codice',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_CodiceFiscale',
                label: 'CodiceFiscale',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_Denominazione',
                label: 'Denominazione',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_Nome',
                label: 'Nome',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_Cognome',
                label: 'Cognome',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_Titolo',
                label: 'Titolo',
                type: 'XField',
                Required: 1
            },
            {
                key: 'TISE_CodEORI',
                label: 'Cod. EORI',
                type: 'XField',
                Required: 1
            },
            {
                key: 'Aruba_Username',
                label: 'Utente FE premium',
                type: 'XField',
                Required: 1
            },
            {
                key: 'Aruba_Password',
                label: 'Password FE premium',
                type: 'XField',
                Required: 1
            },
            {
                key: 'IsDemo',
                label: 'Usa area demo FE premium',
                type: 'XSwitch',
                Required: 1
            },
        ];
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/api/ElectronicInvoice/Save/'
            //urlDelete='/gc/currency/Delete/'
            urlRedirect='/ElectronicInvoiceConfig/'
            urlExit='/ElectronicInvoiceConfigs/'
            urlNew="/ElectronicInvoiceConfig/new"
            urlGetData='/api/ElectronicInvoice/Get/'
            //urlCheckChanges='/gc/currency/HasChanges/'
            //urlClearSession='/IR/ClearSession/'
            //onDeleteClick={this.onDeleteClick}
            translate={true}
            fields={fields}
            AbbreviationKey="Abbreviation"
            DescriptionKey="Description"
            //routeTranslatableFields="/bop/brand/translatableFields/"
            //routeLanguages="/bop/brand/languages/"
            //routeDataT="/bop/brand/translations/"
            //routeSaveData="/bop/brand/savet/"
            onlyCustomFields={true}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(EIConfig);
