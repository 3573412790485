import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import XGrid from 'components/XGrid';
import { Tooltip, IconButton } from '@material-ui/core';
import { Check, ArrowLeft } from 'mdi-material-ui';

const style = () => ({
});

class DistributionList extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
        this.grdIR = React.createRef();
        this.grdIRDIST = React.createRef();
    }
    state = {
        insertNewIR: false
    };
    checkUsed = async (FID) => {
        return await fetch('/BOP/BransIsUsed/' + FID).then(res => res.json());
        
    }
    deleteDistributionList = (FID) => {
        fetch('/IR/DistributionListDelete/' + FID, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        //var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione della lista di distribuzione?";
        //if (isUsed) {
        //    label = "Il brand è utilizzato nelle distinte oppure ha delle Linee e Serie collegate. Continuando verranno cancellato le Linee e le Serie collegate e sulle distinte veranno tolte le informazioni su Brand,Linea e Serie. " + label;
        //}

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteDistributionList(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }

    handleGridNew = (event) => {
        //this.context.router.history.push('/distributionlist/new');
        this.setState({ insertNewIR: true });
    }
    handleBack = () => {
        this.setState({ insertNewIR: false });
    }
    handleAddIR = () => {
        var FID = this.props.match.params.id;
        if (!FID) {
            FID = 0;
        }
        var selectedRows = this.grdIRDIST.current.getWrappedInstance().getSelectedData();
        this.grdIRDIST.current.getWrappedInstance().clearSelection();
        fetch('/IR/DistributionListAddIR/' + FID, {
            method: "POST",
            body: JSON.stringify(selectedRows)
        }).then(res => {

            this.setState({ insertNewIR: false });
        });
        //this.setState({ selectedRows, addRows: true });
    }

    renderPanelIdentities = () => {
        var FID = this.props.match.params.id;
        if (!FID) {
            FID = 0;
        }
        var selectedActions;

        return <XGrid
            dataRoute={"/IR/DistributionListAvailableIR/" + FID + "?grid=tblIRDistribution"}
            dataRouteColumns="/Base/GetColumns?grid=tblIRDistribution"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRDistribution"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRDistribution"
            rowsVisible={5}
            selectedActions={selectedActions}
        />;
    }
    renderPanelListIdentities = () => {
        var { insertNewIR } = this.state;
        var FID = this.props.match.params.id;
        if (!FID) {
            FID = 0;
        }

        var dataRoute = "/IR/DistributionIR/" + FID + "?grid=tblIRIdentityDistribution";
        var dataRouteColumns = "/Base/GetColumns?grid=tblIRIdentityDistribution";
        var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblIRIdentityDistribution";
        var dataRouteDelete = "/Base/UpdateColumns?grid=tblIRIdentityDistribution";
        var onNewButton = this.handleGridNew;
        var selectedActions;
        var actions = [];

        if (insertNewIR) {
            dataRoute = "/IR/DistributionListAvailableIR/" + FID + "?grid=tblIRDistribution";
            dataRouteColumns = "/Base/GetColumns?grid=tblIRDistribution";
            dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblIRDistribution";
            dataRouteDelete = "/Base/UpdateColumns?grid=tblIRDistribution";
            onNewButton = undefined;
			actions = [
				{
					group: 'actions',
					name: 'Aggiungi',
					icon: <Check />,
					action: this.handleAddIR,
					condition: r => r.length > 0
				},
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.handleBack
				}
			];

            return <XGrid
                containerID="panelIR"
                key={1}
                ref={this.grdIRDIST}
                dataRoute={dataRoute}
                dataRouteColumns={dataRouteColumns}
                dataRouteAvailableColumns={dataRouteAvailableColumns}
                dataRouteUpdateColumns={dataRouteDelete}
                //dataRouteDelete={"/DOC/DeleteRows/" + FID}
                onNewButton={onNewButton}
                selectedActions={selectedActions}
                rowsVisible={10}
                actions={actions}
            />;
        }
        
        return <XGrid
            containerID="panelIR"
            key={2}
            ref={this.grdIR}
            dataRoute={dataRoute}
            dataRouteColumns={dataRouteColumns}
            dataRouteAvailableColumns={dataRouteAvailableColumns}
            dataRouteUpdateColumns={dataRouteDelete}
            dataRouteDelete={"/IR/DistributionListDeleteIR/" + FID}
            onNewButton={onNewButton}
            selectedActions={selectedActions}
            rowsVisible={10}
            actions={actions}
        />;
    }

    renderTabIdentities = () => {
        var panels = [];
        //panels.push({
        //    icon: "code",
        //    label: "Anagrafiche disponibili",
        //    content: this.renderPanelIdentities(),
        //});
        panels.push({
            icon: "code",
            label: "Anagrafiche abbinate",
            id:"panelIR",
            content: this.renderPanelListIdentities(),
        });
        return <TabContainer><XExpansionPanel panels={panels} /></TabContainer>;
    }
    render() {
        var tabs = [
            {
                tabName: "Anagrafiche",
                tabContent: this.renderTabIdentities(),
            }
        ];

        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/IR/DistributionListSave/'
            urlDelete='/IR/DistributionListDelete/'
            urlRedirect='/distributionlist/'
            urlNew="/distributionlist/new"
            //urlUploadFiles='/IR/DistributionListFileUpload/'
            //urlDeleteFiles='/IR/DistributionListFileDelete/'
            //urlGetFiles='/IR/DistributionListFiles/'
            urlGetData='/IR/DistributionList/'
            //urlCheckDefaultFiles='/IR/DistributionListFilesCheck/'
            urlExit='/distributionlists/'
            urlCheckChanges='/ir/DistributionListHasChanges/'
            urlClearSession='/ir/DistributionListClearSession/'
            onDeleteClick={this.onDeleteClick}
            customTabs={tabs}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(DistributionList);
