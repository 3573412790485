import React, { Component } from 'react';
import { 
    CircleOutline,
    AlphaAcircleOutline,
    AlphaBcircleOutline,
    AlphaCcircleOutline,
    AlphaDcircleOutline,
    AlphaEcircleOutline,
    AlphaFcircleOutline,
    AlphaGcircleOutline,
    AlphaHcircleOutline,
    AlphaIcircleOutline,
    AlphaJcircleOutline,
    AlphaKcircleOutline,
    AlphaLcircleOutline,
    AlphaMcircleOutline,
    AlphaNcircleOutline,
    AlphaOcircleOutline,
    AlphaPcircleOutline,
    AlphaQcircleOutline,
    AlphaRcircleOutline,
    AlphaScircleOutline,
    AlphaTcircleOutline,
    AlphaUcircleOutline,
    AlphaVcircleOutline,
    AlphaWcircleOutline,
    AlphaXcircleOutline,
    AlphaYcircleOutline,
    AlphaZcircleOutline,
} from 'mdi-material-ui';

class CatalogueIcon extends Component {
    components = {
        default: CircleOutline,
        a: AlphaAcircleOutline,
        b: AlphaBcircleOutline,
        c: AlphaCcircleOutline,
        d: AlphaDcircleOutline,
        e: AlphaEcircleOutline,
        f: AlphaFcircleOutline,
        g: AlphaGcircleOutline,
        h: AlphaHcircleOutline,
        i: AlphaIcircleOutline,
        j: AlphaJcircleOutline,
        k: AlphaKcircleOutline,
        l: AlphaLcircleOutline,
        m: AlphaMcircleOutline,
        n: AlphaNcircleOutline,
        o: AlphaOcircleOutline,
        p: AlphaPcircleOutline,
        q: AlphaQcircleOutline,
        r: AlphaRcircleOutline,
        s: AlphaScircleOutline,
        t: AlphaTcircleOutline,
        u: AlphaUcircleOutline,
        v: AlphaVcircleOutline,
        w: AlphaWcircleOutline,
        x: AlphaXcircleOutline,
        y: AlphaYcircleOutline,
        z: AlphaZcircleOutline,
    };
    render() {
       const TagName = this.components[this.props.tag || 'default'];
       return <TagName />
    }
}
export default CatalogueIcon;