import React, {Component, Fragment} from 'react';
import XExpansionPanel from 'components/XExpansionPanel';
import Grid from '@material-ui/core/Grid';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import XProgress from 'components/XProgress';

import { Check, Close, Download } from 'mdi-material-ui';
import { compose } from 'redux';
import { withWidth } from '@material-ui/core';
import XSelect from 'components/XSelect';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XOXLoader from 'components/XOXLoader';
import { CustomField } from 'components/CustomField';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { getXField } from 'utils';

const styles = theme => ({
});

class VariationsConfig extends Component {
  constructor(props) {
    super(props);

    this.grdVariationsCombinations = React.createRef();
    this.grdVariationsBOP = React.createRef();
    this.grdFiles = React.createRef();
    this.grdPassiveVariations = React.createRef();
    this.grdPassiveVarFiles = React.createRef();
    this.MessageBox = React.createRef();

    this.state = {
      unlockedExtraID: props.unlockedExtraID,
      enabledExtras: [],
      newCombination: false,
      isCombinationEdit: false,
      file: {},
      newPassiveVar: false,
      isPassiveVarEdit: false,
      passiveVarFile: {}
    }
  }

  componentDidMount = () => {
    var { FIDTypology, FIDGroup, FIDCategory } = this.props;
    var queryString = "";
    if (FIDTypology)
      queryString = "?FIDTypology="+FIDTypology;

    if (FIDGroup) {
      if (queryString.length > 0)
        queryString += "&FIDGroup="+FIDGroup;
      else
        queryString = "?FIDGroup="+FIDGroup;
    }

    if (FIDCategory) {
      if (queryString.length > 0)
        queryString += "&FIDCategory="+FIDCategory;
      else
        queryString = "?FIDCategory="+FIDCategory;
    }

    fetch('/BOP/GetVariationExtras'+queryString).then(res => res.json())
      .then(
        data => {
          this.loadEnabledExtras(data);
        }
      )
  }

  handleUnlockedExtraChanged = (id) => (event, value) => {
    var { onUnlockedExtraChange } = this.props;

    var newVal = null;
    if (value && value.length === 1) {
      newVal = value[0].ID;
    }
    onUnlockedExtraChange && onUnlockedExtraChange(id)(event, newVal);
    this.setState({
      unlockedExtraID: newVal
    });
  }

  loadEnabledExtras = (data) => {
    this.setState({
      enabledExtras: data
    });
  }

  handleNewCombination = () => {
    var { id, CatalogCode } = this.props;

    this.setState({
      newCombination: {
        FIDCatalogInfo: id,
        CatalogCode,
        CatalogVariationExtras: [],
        CatalogVariationFiles: []
      }
    })
  }

  handleEditCombination = (event, data) => {
    if (data && data.ID) {
      fetch('/BOP/GetCatalogVariation/' + data.ID)
        .then(res => res.json())
        .then(data => {
          if (data) {
            this.setState({
              newCombination: data.Combination,
              isCombinationEdit: true
            }, () => {              
              var grdCombinations = this.grdVariationsCombinations.current && this.grdVariationsCombinations.current.getWrappedInstance();
              if (grdCombinations)
                grdCombinations.clearSelection()
            });
          }
        });
    }
  }
  handleEditCombinationCancel = () => {
    this.setState({
      newCombination: false,
      isFileEdit: false,
      file: {},
      isCombinationEdit: false
    })
  }
  changeCombinationExtra = (FIDExtra, isData) => (id) => (event, value) => {
    var { enabledExtras, newCombination } = this.state;
    if (newCombination) {
      var extra = enabledExtras.find(e => e.ID == FIDExtra);
      var catVarExtra = {
        ExtraID: extra.ID,
        ExtraName: extra.Description,
        ExtraType: extra.Type,
        BoolValue: null,
        ComboValue: null,
        ComboValueString: "",
        StringValue: "",
        IntValue: null,
        DecimalValue: null,
        TagValue: null,
        DateTimeValue: null
      };

      var existing = newCombination.CatalogVariationExtras.length > 0 ? newCombination.CatalogVariationExtras.filter(e => e.ExtraID == extra.ID)[0] : false;

      switch (extra.Type) {
        case 0://string
          catVarExtra.StringValue = extra.ID === FIDExtra ? value : (existing ? existing.StringValue : "");
        break;    
        case 1://integer
          catVarExtra.IntValue = extra.ID === FIDExtra ? value : (existing ? existing.IntValue : null);
        break;
        case 2://decimal
          catVarExtra.DecimalValue = extra.ID === FIDExtra ? value : (existing ? existing.DecimalValue : null);
        break;
        case 3://datetime
          catVarExtra.DateTimeValue = extra.ID === FIDExtra ? value : (existing ? existing.DateTimeValue : null);
        break;
        case 4://boolean
          catVarExtra.BoolValue = extra.ID === FIDExtra ? value : (existing ? existing.BoolValue : null);
        break;
        case 5:
          catVarExtra.ComboValue = extra.ID === FIDExtra ? value[0].ID : (existing ? existing.ComboValue : null);
          catVarExtra.ComboValueString = extra.ID === FIDExtra ? value[0].Description : (existing ? existing.Description : null);
        break;
        case 6:
          catVarExtra.TagValue = extra.ID === FIDExtra ? value[0].ID : (existing ? existing.TagValue : null);
        break;
        default:
        break;
      }

      if (existing) {
        existing = Object.assign(existing, catVarExtra);
      } else {
        newCombination.CatalogVariationExtras.push(catVarExtra);
      }
      this.setState({
        newCombination
      })
    }
  }

  handleNewCombinationConfirm = () => {
    var { FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { newCombination, enabledExtras } = this.state;
    var error = false;
    var msgs = [];

    enabledExtras.forEach((item, index) => {
      var newVarExtra = newCombination.CatalogVariationExtras.find(e => e.ExtraID === item.ID);

      if (!newVarExtra || (!newVarExtra.StringValue && !newVarExtra.ComboValue && !newVarExtra.DateTimeValue && !newVarExtra.DecimalValue && newVarExtra.DecimalValue != 0 && !newVarExtra.IntValue && newVarExtra.IntValue != 0 && !newVarExtra.BoolValue && newVarExtra.BoolValue != false)) {
        if (!error) {
          error = true;
          msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere</Typography>);
          this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica combinazione varianti', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
          return false;
        }
      }
    });

    if (!error) {
      var queryString = "";
      if (FIDTypology)
        queryString = "?FIDTypology="+FIDTypology;

      if (FIDGroup) {
        if (queryString.length > 0)
          queryString += "&FIDGroup="+FIDGroup;
        else
          queryString = "?FIDGroup="+FIDGroup;
      }

      if (FIDCategory) {
        if (queryString.length > 0)
          queryString += "&FIDCategory="+FIDCategory;
        else
          queryString = "?FIDCategory="+FIDCategory;
      }

      fetch('/BOP/CheckCatalogVariationAdd' + queryString, {
        body: JSON.stringify(newCombination),
        method: 'POST'
      }).then(res => { console.log(res); return res.json(); }).then(
        data => {
          error = data;
          if (!error) {
            var grdFiles = this.grdFiles && this.grdFiles.current && this.grdFiles.current.getWrappedInstance();
            if (grdFiles && grdFiles.state && grdFiles.state.count === 0)
            {
              this.MessageBox.current.getWrappedInstance().showMessageYesNo("Inserimento / modifica combinazione varianti", "Nessuna immagine selezionata per la combinazione, le miniature nel catalogo non saranno visibili. Continuare ugualmente?", this.saveNewCombination);
            }
            else {
              this.saveNewCombination();
            }
          }
          else {
            msgs.push(<Typography>Combinazione duplicata.</Typography>);
            this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica combinazione varianti', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
          }
        }
      )
    }
  }
  handleNewCombinationCancel = () => {
    var id = 0;
    var { newCombination } = this.state;
    if (newCombination && newCombination.ID) {
      id = newCombination.ID;
    }
    var grdFiles = this.grdFiles.current.getWrappedInstance();
    var filesCount = grdFiles && grdFiles.state ? grdFiles.state.count : 0;

    if (filesCount)
    {
      fetch('/BOP/ClearNewCombination/'+id, {
        body: JSON.stringify(newCombination),
        method: 'POST'
      }).then(res => res.json())
        .then(data => {
          if (data) {
            this.setState({
              newCombination: false,
              isFileEdit: false,
              file: {}
            })
          }
        })
    }
    else {
      this.setState({
        newCombination: false,
        isFileEdit: false,
        file: {}
      })
    }
  }
  saveNewCombination = () => {
    var { id } = this.props;
    var { newCombination } = this.state;

    fetch('/BOP/SaveCatalogVariation', {
      body: JSON.stringify(newCombination),
      method: 'POST'
    }).then(res => res.json()).
    then(data => {
      if (data) {
        this.setState(
          {
            newCombination: false
          },() => { this.grdVariationsCombinations.current.getWrappedInstance().refresh(); }
        )
      }
    });
  }

  saveFile = () => {
    var { file, newCombination } = this.state;
    var id = 0;
    if (newCombination && newCombination.ID) {
      id = newCombination.ID;
    }

    this.setState({ isUploading: true, uploadProgress: 0 });
      
    const data = new FormData();

    data.append('file', file.File, file.File.name);
    data.append('FIDFileTypology', file.FIDFileTypology);
    data.append('Default', file.Default ? true : false);
    axios
        .post('/BOP/UploadCombinationFile/' + id, data, {
            onUploadProgress: ProgressEvent => {
                this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
            },
          cancelToken: new CancelToken(c => {
            this.cancelUpload = c;
          })
        })
        .then(res => {
          newCombination.ID = res.data.FIDCatalogVariation;
          this.setState({ newCombination, isFileEdit: false, file: {}, isUploading: false });
            this.grdFiles.current.getWrappedInstance().refresh();
          })
        .catch(err => {
          this.setState({ isUploading: false });
        });

  }

  handleSaveFile = () => {
    var { file, newCombination } = this.state;

    var id = 0;
    if (newCombination && newCombination.ID) {
      id = newCombination.ID;
    }
    
    if (file.File === {}) {
      return;
    }

    if (file.Default) {
        var tempFile = {
            ID: file.ID,
            FIDFileTypology: file.FIDFileTypology
        };
        if (!tempFile.ID) {
            tempFile.ID = 0;
        }
        fetch('/BOP/CheckDefaultCombinationFile/' + id, {
            body: JSON.stringify(tempFile),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                if (result.HasDefault) {
                  this.MessageBox.current.getWrappedInstance().showMessageYesNo('Inserimento file per combinazione varianti', "È già presente un file predefinito per questa tipologia. Continuare ugualmente?", this.saveFile);
                }
                else {
                    this.saveFile();
                }
            });
    }
    else {
        this.saveFile();
    }
  } 

  handleFileNew = () => {
    this.setState({ isFileEdit: true, file: {} });
  }
  handleCancelFile = () => {
    this.setState({ isFileEdit: false, file: {} });
  }
  handleDownloadFile = () => {
    var FIDFIles = this.grdFiles.current.getWrappedInstance().getSelectedData();
    for (var i = 0; i < FIDFIles.length; i++) {
        window.open("/BOP/DownloadCombinationFile/" + FIDFIles[i]);
    }
    this.grdFiles.current.getWrappedInstance().clearSelection();
  } 

  onFileSelectChange = (key) => (event, data) => {
    this.setState(state => {
        if (data.length > 0) {
            state.file[key] = data[0].ID;
        }
        else {
            state.file[key] = undefined;
        }
        return state;
    });
  }
  onFileChange = (key) => (event, data) => {
      this.setState(state => {
          if (data) {
              state.file[key] = data;
          }
          else {
              state.file[key] = undefined;
          }
          return state;
      });
  }

  handleNewPassiveVar = () => {
    var { id, CatalogCode } = this.props;

    var extras = [];

    this.state.enabledExtras.forEach((extra) => {
      var pvExtra = {
        BoolValue: null,
        ComboValue: null,
        ComboValueString: "",
        DateTimeValue: null,
        DecimalValue: null,
        ExtraID: extra.ID,
        ExtraName: extra.Description,
        ExtraType: extra.Type,
        Empty: true,
        IntValue: null,
        StringValue: "",
        TagValue: null
      };
      extras.push(pvExtra);
    })

    this.setState({
      newPassiveVar: {
        FIDCatalogInfo: id,
        CatalogCode,
        PassiveVariationExtras: extras,
        PassiveVariationPassiveVarFiles: []
      }
    })
  }

  handleEditPassiveVar = (event, data) => {
    if (data && data.ID) {
      fetch('/BOP/GetPassiveVariation/' + data.ID)
        .then(res => res.json())
        .then(data => {
          if (data) {
            var passiveVar = data.PassiveVar;
            this.state.enabledExtras.forEach((extra) => {
              if (!passiveVar.PassiveVariationExtras || passiveVar.PassiveVariationExtras.length === 0 || passiveVar.PassiveVariationExtras.filter(item => item.ExtraID === extra.ID).length === 0) {
                if (!passiveVar.PassiveVariationExtras) {
                  passiveVar.PassiveVariationExtras = [];
                }
                var pvExtra = {
                  BoolValue: null,
                  ComboValue: null,
                  ComboValueString: "",
                  DateTimeValue: null,
                  DecimalValue: null,
                  ExtraID: extra.ID,
                  ExtraName: extra.Description,
                  ExtraType: extra.Type,
                  Empty: true,
                  IntValue: null,
                  StringValue: "",
                  TagValue: null
                };
                passiveVar.PassiveVariationExtras.push(pvExtra);
              }
            })


            this.setState({
              newPassiveVar: passiveVar,
              isPassiveVarEdit: true
            }, () => {              
              var grdPassiveVars = this.grdPassiveVariations.current && this.grdPassiveVariations.current.getWrappedInstance();
              if (grdPassiveVars)
                grdPassiveVars.clearSelection()
            });
          }
        });
    }
  }
  handleEditPassiveVarCancel = () => {
    this.setState({
      newPassiveVar: false,
      isPassiveVarFileEdit: false,
      passiveVarFile: {},
      isPassiveVarEdit: false
    })
  }

  handlePassiveVarDescFieldChange = (key) => (event, value) => {
    var { newPassiveVar } = this.state;
    newPassiveVar[key] = value;

    this.setState({
      newPassiveVar
    })
  }

  changePassiveVarExtra = (FIDExtra, isData) => (id) => (event, value) => {
    var { enabledExtras, newPassiveVar } = this.state;
    if (newPassiveVar) {
      var extra = enabledExtras.find(e => e.ID == FIDExtra);
      var passiveVarExtra = {
        ExtraID: extra.ID,
        ExtraName: extra.Description,
        ExtraType: extra.Type,
        Empty: true,
        BoolValue: null,
        ComboValue: null,
        ComboValueString: "",
        StringValue: "",
        IntValue: null,
        DecimalValue: null,
        TagValue: null,
        DateTimeValue: null
      };

      var existing = newPassiveVar.PassiveVariationExtras.length > 0 ? newPassiveVar.PassiveVariationExtras.filter(e => e.ExtraID == extra.ID)[0] : false;

      if (extra) {
        switch (extra.Type) {
          case 0://string
            passiveVarExtra.StringValue = extra.ID === FIDExtra ? value : "";

            if (passiveVarExtra.StringValue)
              passiveVarExtra.Empty = false;
            else
              passiveVarExtra.Empty = true;

          break;    
          case 1://integer
            passiveVarExtra.IntValue = extra.ID === FIDExtra ? value : null;

            if (passiveVarExtra.IntValue || passiveVarExtra.IntValue === 0)
              passiveVarExtra.Empty = false;
            else
              passiveVarExtra.Empty = true;

          break;
          case 2://decimal
            passiveVarExtra.DecimalValue = extra.ID === FIDExtra ? value : null;

            if (passiveVarExtra.DecimalValue || passiveVarExtra.DecimalValue === 0)
              passiveVarExtra.Empty = false;
            else
              passiveVarExtra.Empty = true;

          break;
          case 3://datetime
            passiveVarExtra.DateTimeValue = extra.ID === FIDExtra ? value : null;

            if (passiveVarExtra.DateTimeValue)
              passiveVarExtra.Empty = false;
            else
              passiveVarExtra.Empty = true;

          break;
          case 4://boolean
            passiveVarExtra.BoolValue = extra.ID === FIDExtra ? value : null;

            if (passiveVarExtra.BoolValue || passiveVarExtra.BoolValue === false)
              passiveVarExtra.Empty = false;
            else
              passiveVarExtra.Empty = true;
          break;
          case 5:
            passiveVarExtra.ComboValue = value && value.length > 0 && extra.ID === FIDExtra ? value[0].ID : null;
            passiveVarExtra.ComboValueString = value && value.length > 0 && extra.ID === FIDExtra ? value[0].Description : null;

            if (passiveVarExtra.ComboValueString)
              passiveVarExtra.Empty = false;
            else
              passiveVarExtra.Empty = true;

          break;
          case 6:
            passiveVarExtra.TagValue = value && value.length > 0 && extra.ID === FIDExtra ? value[0].ID : null;
          break;
          default:
          break;
        }
      }

      if (existing) {
        existing = Object.assign(existing, passiveVarExtra);
      } else {
        newPassiveVar.PassiveVariationExtras.push(passiveVarExtra);
      }
      this.setState({
        newPassiveVar
      })
    }
  }

  handleNewPassiveVarConfirm = () => {
    var { CatalogCode, FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { newPassiveVar, enabledExtras } = this.state;
    newPassiveVar.CatalogCode = CatalogCode;
    var error = false;
    var error_desc = newPassiveVar.Description === undefined || newPassiveVar.Description === "";
    var msgs = [];
    if (error_desc) {
      error = true;
      msgs.push(<Typography>Il campo Descrizione è obbligatorio</Typography>);
    }

    var error_extra = true;

    enabledExtras.forEach((item, index) => {
      var newVarExtra = newPassiveVar.PassiveVariationExtras.find(e => e.ExtraID === item.ID);

      if (newVarExtra && !newVarExtra.Empty) {
        error_extra = false;
        return;
      }
    });

    if (error_extra) {
      error = true;
      msgs.push(<Typography>Valorizzare almeno un campo custom</Typography>);
    }

    if (!error) {
      var queryString = "";
      if (FIDTypology)
        queryString = "?FIDTypology="+FIDTypology;

      if (FIDGroup) {
        if (queryString.length > 0)
          queryString += "&FIDGroup="+FIDGroup;
        else
          queryString = "?FIDGroup="+FIDGroup;
      }

      if (FIDCategory) {
        if (queryString.length > 0)
          queryString += "&FIDCategory="+FIDCategory;
        else
          queryString = "?FIDCategory="+FIDCategory;
      }

      fetch('/BOP/CheckPassiveVariationAdd' + queryString, {
        body: JSON.stringify(newPassiveVar),
        method: 'POST'
      }).then(res => { console.log(res); return res.json(); }).then(
        data => {
          error = data;
          if (!error) {
            var grdPassiveVarFiles = this.grdPassiveVarFiles && this.grdPassiveVarFiles.current && this.grdPassiveVarFiles.current.getWrappedInstance();
            if (grdPassiveVarFiles && grdPassiveVarFiles.state && grdPassiveVarFiles.state.count === 0)
            {
              this.MessageBox.current.getWrappedInstance().showMessageYesNo("Inserimento / modifica variante passiva", "Nessuna immagine selezionata per la variante. Continuare ugualmente?", this.saveNewPassiveVar);
            }
            else {
              this.saveNewPassiveVar();
            }
          }
          else {
            msgs.push(<Typography>Variante duplicata.</Typography>);
            this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica variante passiva', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
          }
        }
      )
    } else {
      this.MessageBox.current.getWrappedInstance().showMessageOk('Inserimento / modifica variante passiva', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
      return false;
    }
  }
  handleNewPassiveVarCancel = () => {
    var id = 0;
    var { newPassiveVar } = this.state;
    if (newPassiveVar && newPassiveVar.ID) {
      id = newPassiveVar.ID;
    }
    var grdPassiveVarFiles = this.grdPassiveVarFiles.current && this.grdPassiveVarFiles.current.getWrappedInstance();
    var filesCount = grdPassiveVarFiles && grdPassiveVarFiles.state ? grdPassiveVarFiles.state.count : 0;

    if (filesCount)
    {
      fetch('/BOP/ClearNewPassiveVariation/'+id, {
        body: JSON.stringify(newPassiveVar),
        method: 'POST'
      }).then(res => res.json())
        .then(data => {
          if (data) {
            this.setState({
              newPassiveVar: false,
              isPassiveVarFileEdit: false,
              passiveVarFile: {}
            })
          }
        })
    }
    else {
      this.setState({
        newPassiveVar: false,
        isPassiveVarFileEdit: false,
        passiveVarFile: {}
      })
    }
  }
  saveNewPassiveVar = () => {
    var { id } = this.props;
    var { newPassiveVar } = this.state;

    fetch('/BOP/SavePassiveVariation', {
      body: JSON.stringify(newPassiveVar),
      method: 'POST'
    }).then(res => res.json()).
    then(data => {
      if (data) {
        this.setState(
          {
            newPassiveVar: false
          },() => { this.grdPassiveVariations.current.getWrappedInstance().refresh(); }
        )
      }
    });
  }

  SavePassiveVarFile = () => {
    var { passiveVarFile, newPassiveVar } = this.state;
    var id = 0;
    if (newPassiveVar && newPassiveVar.ID) {
      id = newPassiveVar.ID;
    }

    var file = passiveVarFile;

    this.setState({ isUploading: true, uploadProgress: 0 });
      
    const data = new FormData();

    data.append('file', file.File, file.File.name);
    data.append('FIDFileTypology', file.FIDFileTypology);
    data.append('Default', file.Default ? true : false);
    axios
        .post('/BOP/UploadPassiveVarFile/' + id, data, {
            onUploadProgress: ProgressEvent => {
                this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
            },
          cancelToken: new CancelToken(c => {
            this.cancelUpload = c;
          })
        })
        .then(res => {
          newPassiveVar.ID = res.data.FIDPassiveVariation;
          this.setState({ newPassiveVar, isPassiveVarFileEdit: false, passiveVarFile: {}, isUploading: false });
            this.grdPassiveVarFiles.current.getWrappedInstance().refresh();
          })
        .catch(err => {
          this.setState({ isUploading: false });
        });

  }

  handleSavePassiveVarFile = () => {
    var { passiveVarFile, newPassiveVar } = this.state;

    var id = 0;
    if (newPassiveVar && newPassiveVar.ID) {
      id = newPassiveVar.ID;
    }
    
  var file = passiveVarFile;
    if (file.File === {}) {
      return;
    }

    if (file.Default) {
        var tempFile = {
            ID: file.ID,
            FIDFileTypology: file.FIDFileTypology
        };
        if (!tempFile.ID) {
            tempFile.ID = 0;
        }
        fetch('/BOP/CheckDefaultPassiveVarFile/' + id, {
            body: JSON.stringify(tempFile),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
                if (result.HasDefault) {
                  this.MessageBox.current.getWrappedInstance().showMessageYesNo('Inserimento file per variante passiva', "È già presente un file predefinito per questa tipologia. Continuare ugualmente?", this.SavePassiveVarFile);
                }
                else {
                    this.SavePassiveVarFile();
                }
            });
    }
    else {
        this.SavePassiveVarFile();
    }
  } 

  handleNewPassiveVarFile = () => {
    this.setState({ isPassiveVarFileEdit: true, passiveVarFile: {} });
  }
  handleCancelPassiveVarFile = () => {
    this.setState({ isPassiveVarFileEdit: false, passiveVarFile: {} });
  }
  handleDownloadPassiveVarFile = () => {
    var FIDFIles = this.grdPassiveVarFiles.current.getWrappedInstance().getSelectedData();
    for (var i = 0; i < FIDFIles.length; i++) {
        window.open("/BOP/DownloadPassiveVarFile/" + FIDFIles[i]);
    }
    this.grdPassiveVarFiles.current.getWrappedInstance().clearSelection();
  } 

  onPassiveVarFileSelectChange = (key) => (event, data) => {
    this.setState(state => {
        if (data.length > 0) {
            state.passiveVarFile[key] = data[0].ID;
        }
        else {
            state.passiveVarFile[key] = undefined;
        }
        return state;
    });
  }
  onPassiveVarFileChange = (key) => (event, data) => {
      this.setState(state => {
          if (data) {
              state.passiveVarFile[key] = data;
          }
          else {
              state.passiveVarFile[key] = undefined;
          }
          return state;
      });
  }

  renderCombinationFiles = () => {
    var { newCombination, file, data, isUploading, uploadProgress  } = this.state;

    var id = 0;

    if (newCombination && newCombination.ID)
      id = newCombination.ID;

    var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={12} container>
            <Grid item xs={12} container direction="row" spacing={2}>
                <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
                <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
                <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
            </Grid>
        </Grid>
    </Grid>);

    var panels = [];
    panels.push({
        icon: "attachment",
        label: "Files",
        content: fileHeadFields,
    });
    var customContent = undefined;

    //var doubleClick = this.handleFileDoubleClick;
    var newClick = this.handleFileNew;
    var showColumnSelector = true;
    var showFilterActivator = true;

    var customActions = [
      {
        group: 'actions',
        name: 'Download',
        icon: <Download />,
        action: this.handleDownloadFile,
        condition: r => r.length > 0
      }
    ];
    if (this.state.isFileEdit) {
        customContent = (<XExpansionPanel panels={panels} />);
        //doubleClick = undefined;
        newClick = undefined;
        showFilterActivator = false;
        showColumnSelector = false;
        customActions = [
          {
            group: 'actions',
            name:  'Salva',
            icon: <Check />,
            action: this.handleSaveFile,
          },
          {
            group: 'actions',
            name: 'Annulla',
            icon: <Close />,
            action: this.handleCancelFile
          }
        ];
    }
    var customCells = {
        Preview: (data, column) => {
            return <img style={{
                height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
            }} src={"data:image/png;base64, " + data.base64file} alt="" />
        }
    };
    var ui = (
        <XGrid
            containerID="tabCVFiles"
            label={this.state.isFileEdit ? "Nuovo file" : "Elenco files abbinati"}
            ref={this.grdFiles}
            dataRoute={"/BOP/GetCombinationFiles/" + id + "?grid=tblFiles"}
            dataRouteColumns="/Base/GetColumns?grid=tblFiles"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
            //onDoubleClick={doubleClick}
            dataRouteDelete={"/BOP/DeleteCombinationFile/" + id}
            onNewButton={newClick}
            //onEditButton={doubleClick}
            customContent={customContent}
            showColumnsSelector={showColumnSelector}
            showFilterActivator={showFilterActivator}
            actions={customActions}
            //selectedActions={selectedActions}
            customCells={customCells}
            rowHeight={105}
        />
    )

    return (
        <div id="tabCVFiles" padding={0} style={{ height: "40vh", marginTop: 30 }}>
            <XProgress progress={uploadProgress} total={100} label="Caricamento file in corso..." onCancel={() => {
      if (this.cancelUpload) {
        this.cancelUpload();
      }
      this.setState({ isUploading: false });
    }} cancelLabel="Annulla" show={isUploading} />
            {ui}
        </div>
    );
  }

  renderPassiveVariationFiles = () => {
    var { newPassiveVar, passiveVarFile, data, isUploading, uploadProgress  } = this.state;

    var id = 0;
    var file = passiveVarFile;
    if (newPassiveVar && newPassiveVar.ID)
      id = newPassiveVar.ID;

    var fileHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={12} container>
            <Grid item xs={12} container direction="row" spacing={2}>
                <Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onPassiveVarFileSelectChange} /></Grid>
                <Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onPassiveVarFileChange} /></Grid>
                <Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onPassiveVarFileChange} /></Grid>
            </Grid>
        </Grid>
    </Grid>);

    var panels = [];
    panels.push({
        icon: "attachment",
        label: "Files",
        content: fileHeadFields,
    });
    var customContent = undefined;

    //var doubleClick = this.handleFileDoubleClick;
    var newClick = this.handleNewPassiveVarFile;
    var showColumnSelector = true;
    var showFilterActivator = true;

    var customActions = [
      {
        group: 'actions',
        name: 'Download',
        icon: <Download />,
        action: this.handleDownloadPassiveFile,
        condition: r => r.length > 0
      }
    ];
    if (this.state.isPassiveVarFileEdit) {
        customContent = (<XExpansionPanel panels={panels} />);
        //doubleClick = undefined;
        newClick = undefined;
        showFilterActivator = false;
        showColumnSelector = false;
        customActions = [
          {
            group: 'actions',
            name:  'Salva',
            icon: <Check />,
            action: this.handleSavePassiveVarFile,
          },
          {
            group: 'actions',
            name: 'Annulla',
            icon: <Close />,
            action: this.handleCancelPassiveVarFile
          }
        ];
    }
    var customCells = {
        Preview: (data, column) => {
            return <img style={{
                height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
            }} src={"data:image/png;base64, " + data.base64file} alt="" />
        }
    };
    var ui = (
        <XGrid
            containerID="tabPVFiles"
            label={this.state.isPassiveVarFileEdit ? "Nuovo file" : "Elenco files abbinati"}
            ref={this.grdPassiveVarFiles}
            dataRoute={"/BOP/GetPassiveVarFiles/" + id + "?grid=tblFiles"}
            dataRouteColumns="/Base/GetColumns?grid=tblFiles"
            dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
            dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
            //onDoubleClick={doubleClick}
            dataRouteDelete={"/BOP/DeletePassiveVarFile/" + id}
            onNewButton={newClick}
            //onEditButton={doubleClick}
            customContent={customContent}
            showColumnsSelector={showColumnSelector}
            showFilterActivator={showFilterActivator}
            actions={customActions}
            //selectedActions={selectedActions}
            customCells={customCells}
            rowHeight={105}
        />
    )

    return (
        <div id="tabPVFiles" padding={0} style={{ height: "40vh", marginTop: 30 }}>
          <XProgress progress={uploadProgress} total={100} label="Caricamento file in corso..." onCancel={() => {
            if (this.cancelUpload) {
              this.cancelUpload();
            }
            this.setState({ isUploading: false });
          }} cancelLabel="Annulla" show={isUploading} />
          {ui}
        </div>
    );
  }

  renderPanelExtraParameters = () => {
    var { id, FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { unlockedExtraID } = this.state;

    var queryString= "";

    if (FIDTypology)
      queryString = "?FIDTypology="+FIDTypology;

    if (FIDGroup) {
      if (queryString.length > 0)
        queryString += "&FIDGroup="+FIDGroup;
      else
        queryString = "?FIDGroup="+FIDGroup;
    }

    if (FIDCategory) {
      if (queryString.length > 0)
        queryString += "&FIDCategory="+FIDCategory;
      else
        queryString = "?FIDCategory="+FIDCategory;
    }

    return(
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}><XSelect label={"Raggruppamento varianti"} id={"VariationUnlockedExtra"} urlData={"/BOP/GetVariationExtrasSelect"} value={unlockedExtraID} idKey="ID" valueKey="Description"  abbreviationKey="Abbreviation" onChange={this.handleUnlockedExtraChanged} /></Grid>
    </Grid>
    );
  }

  renderPanelVariationsCombinations = () => {
    var { id, CatalogCode, FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { newCombination, enabledExtras, isCombinationEdit } = this.state;

	if (id === undefined) {
		id = 0;
	}

    var gridParams = "§[";
    if (FIDTypology)
      gridParams += FIDTypology;

    if (FIDGroup)
      gridParams += "|" + FIDGroup;

    if (FIDCategory)
      gridParams += "|" + FIDCategory;

    gridParams += "]";

    var label = "Elenco combinazioni";
    var editCallback = newCombination ? undefined : this.handleEditCombination;

    var customContent = undefined;
    var customActions = [];
    if (newCombination) {
      label = "Nuova combinazione";

      if (isCombinationEdit)
        label = "Modifica combinazione";

      customActions = [
        {
            group: 'actions',
            name:  'Conferma',
            icon: <Check />,
            action: this.handleNewCombinationConfirm,
        },
        {
            group: 'actions',
            name: 'Annulla',
            icon: <Close />,
            action: isCombinationEdit ? this.handleEditCombinationCancel : this.handleNewCombinationCancel
        }
      ];
      customContent = <div style={{ padding: "2.5%", paddingTop: 0 }}><Grid container spacing={2}>
        {enabledExtras.map((item, index) => {
          var extraValue = newCombination.CatalogVariationExtras.length > 0 ? newCombination.CatalogVariationExtras.filter(e => e.ExtraID == item.ID) : [];

          return <CustomField key={index} field={item} extra={extraValue} fieldProps={{ Required: 2 }} mainId={item.ID} handleExtraFieldChange={this.changeCombinationExtra} />
        })}
      </Grid>
        {this.renderCombinationFiles()}
      </div>;
    }

    return (
      <div id="variationsCombinationsContainer" style={{ minHeight: customContent ? "70vh" : "40vh", height: "100%", width: "100%", margin: "0 auto" }}>
        <XGrid 
          containerID="variationsCombinationsContainer"
          ref={this.grdVariationsCombinations}
          label={label}
          dataRoute={'/BOP/GetBOPVariationsCombinations/'+ id + '?catalogCode=' + CatalogCode + '&grid=grdBOPVariationsCustomFields'+gridParams}
          dataRouteColumns={"/Base/GetColumns?grid=grdBOPVariationsCustomFields"+gridParams}
          dataRouteAvailableColumns={"/Base/GetAvailableColumns?grid=grdBOPVariationsCustomFields"+gridParams}
          dataRouteUpdateColumns={"/Base/UpdateColumns?grid=grdBOPVariationsCustomFields"+gridParams}
          dataRouteDelete={"/BOP/DeleteBOPVariationsCombinations/"+ id}
          showColumnsSelector={true}
          showFilterActivator={true}
          showChangeView
          showLoader
          onNewButton={enabledExtras.length > 0 && !customContent ? this.handleNewCombination : undefined}
          onDoubleClick={editCallback}
          onEditButton={editCallback}
          customContent={customContent}
          actions={customActions}
          //onFinishLoad={this.getDBVariationCombinations}
        />
      </div>
    )
  }

  renderPanelVariationsBOP = () => {
    var { FIDBop, CatalogCode } = this.props;
    var label = "Elenco varianti associate";

    return (
    <div id="bopVariationsContainer" style={{ height: "40vh", width: "100%", margin: "0 auto" }}>
      <XGrid 
      containerID="bopVariationsContainer"
      ref={this.grdVariationsBOP}
      label={label}
      dataRoute={'/BOP/GetBOPVariations/'+ FIDBop + '?catalogCode=' + CatalogCode + '&grid=grdBOPVariations'}
      dataRouteColumns="/Base/GetColumns?grid=grdBOPVariations"
      dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdBOPVariations"
      dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdBOPVariations"
      showColumnsSelector={true}
      showFilterActivator={true}
      showLoader
      />
    </div>
    );
  }

  renderPanelPassiveVariations = () => {
    var { CatalogCode, FIDTypology, FIDGroup, FIDCategory } = this.props;
    var { newPassiveVar, enabledExtras, isPassiveVarEdit } = this.state;

    var gridParams = "§[";
    if (FIDTypology)
      gridParams += FIDTypology;

    if (FIDGroup)
      gridParams += "|" + FIDGroup;

    if (FIDCategory)
      gridParams += "|" + FIDCategory;

    gridParams += "]";

    var editCallback = newPassiveVar ? undefined : this.handleEditPassiveVar;

    var customContent = undefined;
    var customActions = [];
    if (newPassiveVar) {
      var varDescriptionsFields = [
        {
          Field: "Description",
          Visibility: true,
          Required: 2,
        },
        {
            Field: "ExtendedDescription",
            Visibility: true,
            Required: 1,
        }
      ];

      label = "Nuova variante passiva";

      if (isPassiveVarEdit)
        label = "Modifica variante passiva";

      customActions = [
        {
            group: 'actions',
            name:  'Conferma',
            icon: <Check />,
            action: this.handleNewPassiveVarConfirm,
        },
        {
            group: 'actions',
            name: 'Annulla',
            icon: <Close />,
            action: isPassiveVarEdit ? this.handleEditPassiveVarCancel : this.handleNewPassiveVarCancel
        }
      ];

      var fieldDescription = getXField(newPassiveVar, varDescriptionsFields, 12, 12, "Description", "Descrizione", this.handlePassiveVarDescFieldChange);
      var fieldExtendedDescription = getXField(newPassiveVar, varDescriptionsFields, 12, 12, "ExtendedDescription", "Descrizione estesa", this.handlePassiveVarDescFieldChange, { multiline: true });

      customContent = <div style={{ padding: "2.5%", paddingTop: 0 }}><Grid container spacing={2}>
          {enabledExtras.map((item, index) => {
            var extraValue = newPassiveVar.PassiveVariationExtras.length > 0 ? newPassiveVar.  PassiveVariationExtras.filter(e => e.ExtraID == item.ID) : [];          
            return <CustomField key={index} field={item} extra={extraValue} fieldProps={{ Required: 1 }} mainId=  {item.ID} handleExtraFieldChange={this.changePassiveVarExtra} />
          })}
        </Grid>
        <Grid container spacing={2}>
          {fieldDescription}
          {fieldExtendedDescription}
        </Grid>
        {this.renderPassiveVariationFiles()}
      </div>;
    }

    var label = "Elenco varianti passive";

    return (
      <div id="passiveVariationsContainer" style={{ height: customContent ? "100%" : "40vh", width: "100%", margin: "0 auto" }}>
        <XGrid 
        containerID="passiveVariationsContainer"
        ref={this.grdPassiveVariations}
        label={label}
        dataRoute={'/BOP/GetPassiveVariations?catalogCode=' + CatalogCode + '&grid=grdPassiveVariations'+gridParams}
        dataRouteColumns={"/Base/GetColumns?grid=grdPassiveVariations"+gridParams}
        dataRouteAvailableColumns={"/Base/GetAvailableColumns?grid=grdPassiveVariations"+gridParams}
        dataRouteUpdateColumns={"/Base/UpdateColumns?grid=grdPassiveVariations"+gridParams}
        dataRouteDelete={"/BOP/DeletePassiveVariations?catalogCode=" + CatalogCode}
        showColumnsSelector={true}
        showFilterActivator={true}
        showChangeView
        showLoader
        onNewButton={enabledExtras.length > 0 && !customContent ? this.handleNewPassiveVar : undefined}
        onDoubleClick={editCallback}
        onEditButton={editCallback}
        customContent={customContent}
        actions={customActions}
        />
      </div>
      );
  }

  render() {
    var panels = [
      {
        icon: "code",
        label: "Parametri",
        content: this.renderPanelExtraParameters()
      },
      {
        icon: "code",
        label: "Combinazioni",
        content: this.renderPanelVariationsCombinations()
      },
      {
        icon: "code",
        label: "Varianti attive",
        content: this.renderPanelVariationsBOP()
      },
      {
        icon: "code",
        label: "Varianti passive",
        content: this.renderPanelPassiveVariations()
      }
    ];

    var ui = <XExpansionPanel panels={panels} />;

    return (
      <Fragment>
        {ui}
        <XMessageBox ref={this.MessageBox} />
      </Fragment>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  withWidth(),
);
export default enhance(VariationsConfig);