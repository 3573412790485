import React, { Component } from 'react';
import { withStyles,Tooltip,IconButton } from '@material-ui/core';
import { ShieldCheck } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"
import { compose } from 'redux';
import { withWidth } from '@material-ui/core';

const styles = theme => ({
    root: {
    }
});

class SellOut extends Component {
    constructor(props) {
        super(props);

        this.grdSellout = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    onInvoiceCreate = () => {
        var selectedRows = this.grdSellout.current.getWrappedInstance().getSelectedData();
        this.grdSellout.current.getWrappedInstance().clearSelection();

        fetch('/DOC/InvoiceCreate', {
            method: 'POST',
            body: JSON.stringify(selectedRows)
        }).then(res => res.json()).then(data => {
            if (data) {

            }
            this.grdSellout.current.getWrappedInstance().refresh();
        });
    }
    render() {
        var actions = [];
        actions.push(<Tooltip title="Genera fattura">
            <IconButton aria-label="Genera fattura" onClick={this.onInvoiceCreate}>
                <ShieldCheck />
            </IconButton>
        </Tooltip>);
        

        return (
            <XGrid
                ref={this.grdSellout}
                usePrimaryColor={true}
                dataRoute="/DOC/GetBopsSellOut?grid=tblBOPSellOut"
                dataRouteColumns="/Base/GetColumns?grid=tblBOPSellOut"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPSellOut"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPSellOut"
                
                selectedActions={actions}
                cardWidth={4}
                cardHeight={4.5}
                showChangeView={true}
                width={this.props.width}
            />
        );
    }
}

const enhance = compose(
    withStyles(styles),
    withWidth(),
);
export default enhance(SellOut);