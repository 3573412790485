import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const style = () => ({
});

class Brand extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    checkUsed = async (FIDBrand) => {
        return await fetch('/BOP/BrandIsUsed/' + FIDBrand).then(res => res.json());        
    }
    deleteBrand = (FIDBrand) => {
        fetch('/BOP/BrandDelete/' + FIDBrand, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = async() => {
        var id = this.props.match.params.id;

        var isUsed = await this.checkUsed(id);

        var label = "Confermi la cancellazione del brand?";
        if (isUsed) {
            label = "Il brand è utilizzato nelle distinte oppure ha delle Linee e Serie collegate. Continuando verranno cancellato le Linee e le Serie collegate e sulle distinte veranno tolte le informazioni su Brand,Linea e Serie. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteBrand(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
		
		var fields = this.props.userID === -1990 ? [
			{
				key: "Deactivated",
				label: getTranslation(5,"GC_DEACTIVATED", this.props.translations, "Disattivato") || "Disattivato",
				type: "XSwitch"
			},
		] : [];
		
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
			fields={fields}
            urlSave='/BOP/SaveBrand/'
            urlDelete='/BOP/DeleteBrand/'
            urlRedirect='/Brand/'
            urlUploadFiles='/BOP/BrandFileUpload/'
            urlDeleteFiles='/BOP/DeleteBrandFile/'
            urlGetFiles='/BOP/GetBrandFiles/'
            urlCheckDefaultFiles='/BOP/CheckDefaultBrandFiles/'
            urlExit='/Brands/'
            urlNew="/brand/new"
            urlGetData='/BOP/GetBrand/'
            urlCheckChanges='/BOP/BrandHasChanges/'
            urlClearSession='/BOP/ClearSession/'
            onDeleteClick={this.onDeleteClick}
            translate={true}
            routeTranslatableFields="/bop/brand/translatableFields/"
            routeLanguages="/bop/brand/languages/"
            routeDataT="/bop/brand/translations/"
            routeSaveData="/bop/brand/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Brand);
