import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import PhotoCamera from '@material-ui/icons/PhotoCamera';


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
});

class XUpload extends Component {

    handleSelectedFile = event => {
        var { id, onChange } = this.props;
        if (onChange) {
            onChange(id)(event, event.target.files[0]);
        }
    }
    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    render() {
        var { classes, value, id, color } = this.props;
        if (!id) {
            id = this.uuidv4();
        }
        if (!color) {
            color = "primary";
        }
        return (
            <Fragment>
                <input accept="*" className={classes.input} id={id} type="file" onChange={this.handleSelectedFile} />
                <label htmlFor={id}>
                    <IconButton color={color} className={classes.button} component="span">
                        <PhotoCamera />
                    </IconButton>
                    {value && value.name}
                </label>
            </Fragment>
        );
    }
}

export default withStyles(styles)(XUpload);