import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Grid, Typography } from '@material-ui/core';
import XOXLoader from './XOXLoader';

const styles = theme => ({
    root: {
        width: "calc(100% - 10px)",
        position: "relative",
        margin: 5,
    },
    content: {
        position: "relative",
        width: "100%",
        //paddingTop: "calc(100% - 10px)",
        height: "100%",
        padding: 0,
    },
    bottomContent: {
        padding: "16px",
        width: "100%",
        margin: "10px 0 0 0",
    },
    alignRight: {
        textAlign: "right",
        float: "right"
    },
    logo: {
        width: "calc(100% - 0px)",
        height: "calc(100% - 0px)",
        position: "absolute",
        objectFit: "scale-down",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        cursor: "pointer",
    },
    label: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    },
    labelSpan: {
        position: "absolute",
        bottom: 0,
        left: 0,
        display: "block",
        width: "100%",
        textAlign: "center",
        padding: 10,
        background: "rgba(0,0,0,0.7)",
        color: "#fff",
        fontWeight: "bold",
    },
    itemBackground: {
        paddingTop: '56.25%',
        background: '#fff',
        border: '0px solid #bbb',
        width: 'calc(100% - 0px)',
        cursor: 'pointer',
    }
});

class XCard extends Component {
    state = {
        data: {},
        isLoading: true,
    };
    componentDidMount() {
        this.reloadData();
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.reloadData();
        }
    }
    reloadData = () => {
        var { classes, viewType, data } = this.props;
        if (!data) {
            this.setState({ isLoading: true });
            return;
        }
        var id = undefined;
        var url = "";

        this.setState({ isLoading: true }, () => {
            var { classes, viewType, data } = this.props;
            var id = 0;

            if (url !== "") {
                fetch(url).then(res => res.json()).then(data => {
                    this.setState({ data, isLoading: false });
                });
            }
        });
    }
    onClick = () => {
        var { onClick, data, viewType } = this.props;
        if (onClick && data) {
            onClick(viewType, data);
        }
    }
    render() {
        var { data, columns, classes, viewType, onClick, showLabel } = this.props;
        var { isLoading } = this.state;

        var onClick = this.onClick;
        var ui = false;
        var isLoadingCheck = false;
        //if (isLoading || !data || !this.props.data) {
        //    isLoadingCheck = true;
        //    ui = <XOXLoader onlyO={true} />;
        //    onClick = undefined;
        //}
        //else {
        var url = "";
        var label = "";
        var id = 0;
        var title = "\u00A0";
        var subtitle = "";


        if (data[columns[0].key])
            title = data[columns[0].key];

        if (data[columns[1].key])
            subtitle = data[columns[1].key]

        const contents = [];
        var maxContents = columns.length < 6 ? columns.length : 6;
        for (var i = 2; i < maxContents; i++) {
            if (columns[i] && data[columns[i].key])
                contents.push(
                    <Grid container item xs={12}>
                        <Grid item xs={12} alignContent="flex-end" justify="flex-end"><Typography component="span">{columns[i].name}</Typography><span className={classes.alignRight}>{data[columns[i].key]}</span></Grid>
                    </Grid>
                );
        }
        var style = {};
        var labelClass = "";
        var cardHead = <CardHeader
            className={classes.header}
            title={title}
            subheader={subtitle}
            classes={{
                title: classes.cardTitle
            }}
        />;

        if (data.FileURL) {
            style = {
                background: 'url(' + data.FileURL + ')',
                backgroundPosition: 'center',
                backgroundSize: "contain",
                backgroundRepeat: 'no-repeat',
            };
            labelClass = classes.label;
        }
        //ui = <object className={classes.logo} data={url} type="image/jpg">
        //        {!showLabel && <Typography className={classes.label}>{label}</Typography>}
        //    </object>;
        ui = <div className={classes.itemBackground} style={style} onClick={this.props.onCardClick} />;
        //}

        return (
            <Card style={this.props.style} className={classes.root}>
                {cardHead}
                <CardContent className={classes.content} onClick={onClick}>
                    {ui}
                    <Grid container item xs={12} class={classes.bottomContent}>
                        {contents}
                    </Grid>
                    {this.props.selectionView && this.props.selectionView(this.props.isSelected,data.ID)}
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(XCard);