import Dashboard from "layouts/Dashboard.jsx";
import Auth from 'layouts/Auth';
import CustomerShopPage from 'views/Pages/CustomerShopPage';
import Video from '../views/CRM/Video';
import BOPView from 'views/BOP/BOPView';

var indexRoutes = [
    { path: "/auth", name: "Auth", component: Auth },
    {
        path: '/cust/:customerid',
        name: 'Store',
        component: CustomerShopPage,
        requireLogin: false,
        exact: true,
    },
    {
        path: '/cust/:customerid/store/:storeid',
        name: 'Store',
        component: CustomerShopPage,
        requireLogin: false,
        exact: true,
    },
    {
        path: '/cust/:customerid/store/:storeid/:storeareaid',
        name: 'Store',
        component: CustomerShopPage,
        requireLogin: false,
        exact: true,     
    },
    {
        //id: "distributionlistedit",
        path: '/video/:id',
        name: 'Video',
        component: Video,
        hidden: true,
        requireLogin: false,
    },
	{
		path: "/warrantyQR/:QRCodeGuid",
		name: "Distinta",
		component: BOPView,
		requireLogin: false,
		exact: true
	},
    { path: "/", name: "Home", component: Dashboard, requireLogin: true },
];

export default indexRoutes;
