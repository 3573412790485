import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import { Send, Launch, Download, ArrowLeft, Printer, TextBoxPlusOutline, TextBoxMinusOutline } from 'mdi-material-ui';
import XGrid from 'components/XGrid';
import PropTypes from 'prop-types';
import XMessageBox from 'components/XMessageBox';
import XNavigation from '../../../components/XNavigation';
import ViewXml from 'views/DOC/ElectronicInvoice/ViewXml';


const styles = (theme) => ({

});


class Invoices extends Component {
	constructor(props){
		super(props);
		this.state = {
            view: "FattureAttive",
        };

		this.grdInvoices = React.createRef();
		this.XMessage = React.createRef();
	}
	static contextTypes = {
        router: PropTypes.object
	}
	/* Reason : ??
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/BOP');
	}
	*/
	
	//Functions for Fatture Attive
	sendToSDI = () => {
		var selected = this.grdInvoices.current.getSelectedData();
		

		fetch('/api/ElectronicInvoice/SendInvoices',{
			method: 'POST',
			body: JSON.stringify(selected)
		}).then(res => res.json()).then(data => {
			if(data.success){
				var message = data.result.map((item,index) => <Fragment>
				<p key={index}>{item.FIDDocument}: {item.Sent ? "SI": "NO"} - {item.ErrorCode} - {item.ErrorDescription}</p>
				</Fragment>);
				this.XMessage.current.showMessageOk('Invio fatture a SDI', message);
			}
			else {
				this.XMessage.current.showMessageOk('Invio fatture a SDI', "Fatture non inviate");
			}
		});
	}

	VerifyStatus = () => {
		var rows = this.grdInvoices.current.getSelectedData();
		var data = this.grdInvoices.current.getRowData(rows[0]);

		fetch(`/api/ElectronicInvoice/VerifyStatus/${data.ID}?FIDInvoiceStatus=${data.FIDInvoiceStatus}&FileName=${data.FileName}`).then(res =>{
			this.grdInvoices.current.refresh();
		});
	}

	//Functions for Fatture Pasive

	DownloadInvoice = async () =>
	{
		var selected = this.grdInvoices.current.getSelectedData();
		var data = this.grdInvoices.current.getRowData(selected[0]);
		
		await fetch(`/api/ElectronicInvoice/DownloadInvoice/?fileName=${data.filename}`);
	}
	ViewXml = () =>
	{
		var selected = this.grdInvoices.current.getSelectedData();
		var data = this.grdInvoices.current.getRowData(selected[0]);
			if(selected != null)
			{
				this.setState({ viewXml: true, fileName: data.filename }, () => {
					this.grdInvoices.current.clearSelection();
				});

			}
	}

	ViewXmlBack = () =>
	{

		this.setState({ viewXml: false, fileName: undefined });
	}

	print() {
        window.frames["InvoiceFromFrame"].contentWindow.print()
    }

	//Nav Bar Functions
	showFatturePassive = () => {
        this.setState({ view: "FatturePassive" });
    }
    showFattureAttive = () => {
        this.setState({ view: "FattureAttive" });
	}



	render(){
		var { width } = this.props;
		var { view } = this.state;

		var key = "";
		var gridName = "";
		var dataRoute = "";
		var actions=[];
		
        if (view === "FattureAttive") {
            key = "grdInvoices"; 
			dataRoute = `/api/ElectronicInvoice/GetInvoices?grid=${key}`;
			
			actions = [
				{
					group: 'actions',
					name: 'Invia a SDI',
					action: this.sendToSDI,
					icon: <Send />,
					condition: rows => rows.length > 0 
				},
			];
		}
		if (view === "FatturePassive") {
			key = "grdInvoicesFromAruba";
			dataRoute = `/api/ElectronicInvoice/GetReceivedInovices?grid=${key}`;
			var { viewXml, fileName } = this.state;
			//actions for FATTURE PASIVE
			actions = [
				{
					group: 'actions',
					name: 'Download Invoice',
					action: this.DownloadInvoice,
					icon: <Download />,
					condition: rows => rows.length > 0
				},
				{
					group: 'actions',
					name: 'Apri Fattura',
					action: this.ViewXml,
					icon: < Launch/>,
					condition: rows => rows.length === 1
				}
			];

			//wiewXML Logics for Fatture Passive
			var customContent = undefined;

			if(viewXml) {
				customContent = <ViewXml fileName={fileName} innerRef={this.XmlViewer} />;
				actions=[
					{
						group: 'actions',
						name: 'Indietro',
						action: this.ViewXmlBack,
						icon: < ArrowLeft/>,
					},
					{
						group: 'actions',
						name: 'Print',
						action: this.print,
						icon: < Printer/>,
					}
				];
		}

		}



		
        var actionsNav = [];
		actionsNav.push({ name: "Fatture Attive", icon: <TextBoxPlusOutline />, action: this.showFattureAttive, selected: view === "FattureAttive" });
		actionsNav.push({ name: "Fatture Passive", icon: <TextBoxMinusOutline />, action: this.showFatturePassive, selected: view === "FatturePassive" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <XNavigation actions={actionsNav} direction={direction} showLabel />;
		gridName = key;

        return (
			<Fragment>
				<XGrid
					innerRef={this.grdInvoices}
					usePrimaryColor={true}
					key={key}
					dataRoute={dataRoute}
					dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
					dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
					dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
					actions={actions}
					onDoubleClick={this.handleGridDoubleClick}
                	onEditButton={this.handleGridDoubleClick}
					onNewButton={this.handleGridNew}
					label={label}
					customContent={customContent}
					showLoader
				/>
				<XMessageBox innerRef={this.XMessage} />
			</Fragment>
        );
	}
}

export default withStyles(styles)(Invoices);