import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Footer from "components/Footer/Footer.jsx";

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Auth';
import { actionCreators as globalCreators } from '../../store/Global';

import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/img/default-avatar.png";
import XLanguageSelector from "../XLanguageSelector";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openAvatar: false,
			openComponents: this.activeRoute("/components"),
			openForms: this.activeRoute("/forms"),
			openTables: this.activeRoute("/tables"),
			openProduction: this.activeRoute("/production"),
			openMaps: this.activeRoute("/maps"),
			openPages: this.activeRoute("-page"),
            miniActive: true,
            showLanguages: false,
		};
		this.activeRoute.bind(this);
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
	}
	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}
    changeLanguage = () =>{
        this.setState({showLanguages : true});
    }
    closeLanguages = () => {
        this.setState({showLanguages : false});
    }
	render() {
		const {
			classes,
			color,
			logo,
			image,
			logoText,
			routes,
			bgColor,
		} = this.props;
        var {showLanguages} = this.state;
		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
			});
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]:
					this.props.miniActive && this.state.miniActive,
			});
		const userWrapperClass =
			classes.user +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white"
			});
		const caret = classes.caret;
		const collapseItemMini = classes.collapseItemMini;
		const photo = classes.photo;
		var user = (
			<div className={userWrapperClass}>
				<div className={photo}>
					<img src={avatar} className={classes.avatarImg} alt="..." />
					{/*<Icon>perm_identity</Icon>*/}
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						<NavLink
							to={"#"}
							className={classes.itemLink + " " + classes.userCollapseButton}
							onClick={() => this.openCollapse("openAvatar")}
						>
							<ListItemText
								primary={this.props.username}
								secondary={
									<b
										className={
											caret +
											" " +
											classes.userCaret +
											" " +
											(this.state.openAvatar ? classes.caretActive : "")
										}
									/>
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar} unmountOnExit>
							<List className={classes.list + " " + classes.collapseList}>
								{!this.props.isProductionOperator && <ListItem className={classes.collapseItem}>
									<NavLink onClick={() => {this.props.setTitle && this.props.setTitle(getTranslation(0,"MN_PROFILE",this.props.translations, "Profilo"))}}
										to="/profile/"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
									>
										<span className={collapseItemMini}>MP</span>
										<ListItemText
                                            //primary="My Profile"
                                            primary={getTranslation(0,"MN_PROFILE",this.props.translations, "Profilo")}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>}
								{
									//<ListItem className={classes.collapseItem}>
									//    <NavLink
									//        to="#"
									//        className={
									//            classes.itemLink + " " + classes.userCollapseLinks
									//        }
									//    >
									//        <span className={collapseItemMini}>EP</span>
									//        <ListItemText
									//            primary="Edit Profile"
									//            disableTypography={true}
									//            className={collapseItemText}
									//        />
									//    </NavLink>
									//</ListItem>
									//<ListItem className={classes.collapseItem}>
									//    <NavLink
									//        to="#"
									//        className={
									//            classes.itemLink + " " + classes.userCollapseLinks
									//        }
									//    >
									//        <span className={collapseItemMini}>S</span>
									//        <ListItemText
									//            primary="Settings"
									//            disableTypography={true}
									//            className={collapseItemText}
									//        />
									//    </NavLink>
									//</ListItem>
								}
                                {this.props.IsResponsible && !this.props.isProductionOperator && <ListItem className={classes.collapseItem}>
									<NavLink
										to="#"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
										onClick={this.props.changeProfile}
									>
										<span className={collapseItemMini}>CP</span>
										<ListItemText
                                            //primary="Cambia profilo"
                                            primary={getTranslation(0,"MN_CHGPROFILE",this.props.translations, "Cambia profilo")}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
                                </ListItem>}
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="#"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                        onClick={this.changeLanguage}
                                    >
                                        <span className={collapseItemMini}>LO</span>
                                        <ListItemText
                                            //primary="Log out"
                                            primary={getTranslation(0,"MN_CHGLANGUAGE",this.props.translations, "Cambia lingua")}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="#"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
										onClick={() => { 
												this.props.logout(); 
											} 
										}
									>
										<span className={collapseItemMini}>LO</span>
										<ListItemText
                                            //primary="Log out"
                                            primary={getTranslation(0,"MN_LOGOUT",this.props.translations, "Logout")}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		);
		var links = (
			<List className={classes.list}>
				{routes.map((prop, key) => {
					if (prop.id && !this.props.menus.includes(prop.id)) {
						return null;
					}
					if (prop.redirect || prop.hidden) {
						return null;
					}
					if (prop.collapse) {
						const navLinkClasses =
							classes.itemLink +
							" " +
							cx({
								[" " + classes.collapseActive]: this.activeRoute(prop.path)
							});
						const itemText =
							classes.itemText +
							" " +
							cx({
								[classes.itemTextMini]:
									this.props.miniActive && this.state.miniActive
							});
						const collapseItemText =
							classes.collapseItemText +
							" " +
							cx({
								[classes.collapseItemTextMini]:
									this.props.miniActive && this.state.miniActive
							});
						const itemIcon = classes.itemIcon;
						const caret = classes.caret;
						return (
							<ListItem key={key} className={classes.item}>
								<NavLink
									to={"#"}
									className={navLinkClasses}
									onClick={() => this.openCollapse(prop.state)}
								>
									<ListItemIcon className={itemIcon}>
										{typeof prop.icon === "string" ? (
											<Icon>{prop.icon}</Icon>
										) : (
												<prop.icon />
											)}
									</ListItemIcon>
									<ListItemText
                                        primary={getTranslation(0,prop.localizationCode,this.props.translations, prop.name)}
										secondary={
											<b
												className={
													caret +
													" " +
													(this.state[prop.state] ? classes.caretActive : "")
												}
											/>
										}
										disableTypography={true}
										className={itemText}
									/>
								</NavLink>
								<Collapse in={this.state[prop.state]} unmountOnExit>
									<List className={classes.list + " " + classes.collapseList}>
										{prop.views.map((prop, key) => {
											if (prop.id && !this.props.menus.includes(prop.id)) {
												return null;
											}
											if (prop.redirect || prop.hidden) {
												return null;
											}
											const navLinkClasses =
												classes.collapseItemLink +
												" " +
												cx({
													[" " + classes[color]]: this.activeRoute(prop.path)
												});
											const collapseItemMini = classes.collapseItemMini;
											return (
												<ListItem key={key} className={classes.collapseItem}>
													<NavLink onClick={() => { this.props.setTitle && this.props.setTitle(prop.name) }} to={prop.path} className={navLinkClasses}>
														<span className={collapseItemMini}>
															{prop.mini}
														</span>
														<ListItemText
															primary={prop.name}
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											);
										})}
									</List>
								</Collapse>
							</ListItem>
						);
					}
					const navLinkClasses =
						classes.itemLink +
						" " +
						cx({
							[" " + classes[color]]: this.activeRoute(prop.path)
						});
					const itemText =
						classes.itemText +
						" " +
						cx({
							[classes.itemTextMini]:
								this.props.miniActive && this.state.miniActive
						});
					const itemIcon = classes.itemIcon;
					return (
						<ListItem key={key} className={classes.item}>
							<NavLink onClick={() => { this.props.setTitle && this.props.setTitle(prop.name) }} to={prop.path} className={navLinkClasses}>
								<ListItemIcon className={itemIcon}>
									{typeof prop.icon === "string" ? (
										<Icon>{prop.icon}</Icon>
									) : (
											<prop.icon />
										)}
								</ListItemIcon>
								<ListItemText
                  //primary={prop.name}
                  primary={getTranslation(0,prop.localizationCode,this.props.translations, prop.name)}
									disableTypography={true}
									className={itemText}
								/>
							</NavLink>
						</ListItem>
					);
				})}
			</List>
		);

		const logoNormal =
			classes.logoNormal +
			" " +
			cx({
				[classes.logoNormalSidebarMini]:
					this.props.miniActive && this.state.miniActive
			});
		const logoMini = classes.logoMini;
		const logoClasses =
			classes.logo +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white"
			});
		var brand = (
			<div className={logoClasses}>
				<NavLink to="/" className={logoMini}>
					<img src={logo} alt="logo" className={classes.img} />
				</NavLink>
				{
					//<NavLink to="/" className={logoNormal}>
					//    {logoText}
					//</NavLink>
				}
			</div>
		);
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});
		return (
			<div ref="mainPanel">
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor="right"
						open={this.props.open}
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={<HeaderLinks rtlActive={false} />}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
                        
                        <Footer fluid />
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						onMouseOver={() => this.setState({ miniActive: false })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor="left"
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
                        
                        <Footer fluid />
					</Drawer>
				</Hidden>
                
                <XLanguageSelector showSelector={showLanguages} onClose={this.closeLanguages}/>
			</div>
		);
	}
}

Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose",
        "primary",
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object)
};

const enhance = compose(
	connect(
		state => state.auth,
		dispatch => bindActionCreators(actionCreators, dispatch)
	),
	connect(
		state => state.global,
		dispatch => bindActionCreators(globalCreators, dispatch)
	),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
	withStyles(sidebarStyle)
);

export default enhance(Sidebar);
