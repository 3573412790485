import React, { Component } from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import XGridCell from './XGridCell';
import { withStyles } from '@material-ui/core/styles';
import arrayMove from 'array-move';

import color from 'color';

import { grayColorLight } from '../assets/jss/material-dashboard-pro-react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DragVertical } from 'mdi-material-ui';


const styleSortable = theme => ({
    root: {
        position: "relative",
        opacity: "1 !important",
        visibility: "initial !important",
        verticalAlign: "top",
    },
    resize: {
        display: "block",
        position: "absolute",
        right: 1,
        top: 0,
        height: "100%",
        width: "4px",
        background: "rgba(0,0,0,0.05)",
        cursor: "col-resize",
    },
    dragHandle: {
        position: "absolute",
        top: -5,
        left: 1,
        cursor:"grab",
    },
});
const styleHandle = {
    root: {
        position: "absolute",
        top: -5,
        left: 1,
    },
    icon: {
        //position: "absolute",
        //top: "50%",
        //left: 1,
        //transform: "translateY(-50%)",
    }
};

const SortableItem = withStyles(styleSortable)(sortableElement(({ value, classes, resizeEvents, sortable, draggable, resizable }) =>
    (
        <span className={classes.root}>
						{draggable && <span className={draggable ? classes.dragHandle : undefined}><SortableHandle /></span>}
						{/* {<span className={draggable ? classes.dragHandle : undefined}><DragVertical /></span>} */}
						{value}
						{resizable && <span className={classes.resize} {...resizeEvents}></span>}
        </span>
    )
));
const SortableHandle = withStyles(styleHandle)(sortableHandle((classes) => {
    return (<span className={classes.root}><DragVertical /></span>);
}));
const SortableContainer = sortableContainer(({ children }) => {
    return <div role="row">{children}</div>;
});

const rowStyle = theme => ({
    root: {
        minWidth: props => props.width,
        height: "42px",
        lineHeight: "42px",
        background: color(theme.palette.secondary.main).hsl().alpha(0.75).string(),
        color: '#fff',
        '&:after': {
            content: '" "',
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: color(grayColorLight).hsl().alpha(0.2).string(),
        },
        '&:hover': {
            //backgroundColor: color(grayColorLight).hsl().alpha(0.1).string()
        },
        overflow: "hidden",
    },
    sortLabelRoot: {
        '&:hover': {
            color: '#fff !important',
        }
    },
    sortLabelActive: {
        color: '#fff !important',
    },
    sortLabelIcon: {
        color: '#fff !important',
    },
    checkboxRoot: {
        color: '#fff !important',
    }
});

class XGridRowHeader extends Component {
	state = {
		dragging: false,
		column: undefined,
		columnWidth: 0,
		columnIndex: 0,
		screenX: 0,
	}
	componentDidMount() {
		document.addEventListener('mousemove', this.onMouseMove);
		document.addEventListener('mouseup', this.onMouseUp);
	}
	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove);
		document.removeEventListener('mouseup', this.onMouseUp);
	}
	onMouseMove = (event) => {
        if(!this.props.reactGrid) return;
		if (this.state.dragging && this.state.column) {
			var { reactGrid } = this.props;
			var width = this.state.columnWidth + (event.screenX - this.state.screenX);

			var columns = reactGrid.state.columns;
			columns[this.state.columnIndex].width = width;

			reactGrid.setState(() => { return { columns: columns }; });
		}
	}
	onMouseDown = (column, index) => event => {
        if(!this.props.reactGrid) return;
		this.setState({ dragging: true, column, columnWidth: column.width, columnIndex: index, screenX: event.screenX });
	}
	onMouseUp = (event) => {
        if(!this.props.reactGrid) return;
		if (this.state.dragging && this.state.column) {
			var { reactGrid } = this.props;

			var width = this.state.columnWidth + (event.screenX - this.state.screenX);

			var columns = reactGrid.state.columns;
			columns[this.state.columnIndex].width = width;

			reactGrid.setState(() => { return { columns: columns }; }, reactGrid.saveColumsConfiguration);



			this.setState({ dragging: false, column: undefined, columnIndex: 0, screenX: 0 });
		}
	}
	onSortEnd = (reactGrid) => ({ oldIndex, newIndex }) => {
        if(!reactGrid) return;
		var columns = reactGrid.state.columns;
		columns = arrayMove(columns, oldIndex, newIndex);

		reactGrid.setState(() => { return { columns: columns }; }, reactGrid.saveColumsConfiguration);
	};

	onSortOver = (reactGrid) => ({ oldIndex, newIndex }) => {
        if(!reactGrid) return;
		var columns = reactGrid.state.columns;
		//columns = arrayMove(columns, oldIndex, newIndex);

		reactGrid.setState(() => { return { columns: columns }; });
	}


	render() {
		var { columns, classes, onSelectAll, isSelected, isIndeterminate, width, orderBy, order, onSort, reactGrid, viewType, emptyColumnEdit } = this.props;



		return (
			<div className={classes.root} width={width} >
				<SortableContainer onSortEnd={this.onSortEnd(reactGrid)} onSortOver={this.onSortOver(reactGrid)} axis="x" lockAxis="x" lockToContainerEdges={true} useDragHandle={true}>
					{onSelectAll ? <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: "42px", minHeight: "42px", verticalAlign: "middle" }}>
						<Checkbox classes={{ root: classes.checkboxRoot }} indeterminate={isIndeterminate} checked={isSelected} onChange={onSelectAll} />
					</span> : <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: "42px", minHeight: "42px", verticalAlign: "middle" }}>&nbsp;</span>}
					{emptyColumnEdit && <span style={{ minWidth: "42px", maxWidth: "42px", display: "inline-block", overflow: "hidden", maxHeight: "42px", minHeight: "42px", verticalAlign: "middle" }}>&nbsp;</span>}
					{viewType == 'list' && <SortableItem
						key={-2}
						index={-1}
						disabled={true}
						value={
							<span style={{ minWidth: "84px", display: "inline-block", overflow: "hidden", maxHeight: "42px", minHeight: "42px", verticalAlign: "middle" }}>
								Immagine
                            </span>
						}
					/>}
					{columns.map((column, index) => {
						var value = (<Tooltip
							title="Sort"
							placement={'top-end'}
							enterDelay={300}
                            disableFocusListener={!column.sortable}
                            disableHoverListener={!column.sortable}
                            disableTouchListener={!column.sortable}
						>
							<TableSortLabel
								active={orderBy === column.key}
								direction={order}
                                hideSortIcon={!column.sortable}
                                onClick={onSort && onSort(column.key)}
								classes={{
									root: classes.sortLabelRoot,
									active: classes.sortLabelActive,
									icon: classes.sortLabelIcon,
								}}
							>
								{column.name}
							</TableSortLabel>
						</Tooltip>);

						if (!column.sortable)
							value = column.name;

						var resizeEvents = {
							onMouseDown: this.onMouseDown(column, index),
						};
						return (<SortableItem sortable={column.sortable} draggable={column.draggable} resizable={column.resizable} key={column.key} index={index} resizeEvents={resizeEvents} value={<XGridCell value={value} column={column} width={column.width} totalWidth={width} isHeader={true} isLast={index === columns.length - 1} />} />);

					})}
				</SortableContainer>
			</div>
		);
	}
}
export default withStyles(rowStyle)(XGridRowHeader);