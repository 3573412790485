import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class CashRegister extends Component {
    constructor(props) {
        super(props);

        this.Editor = React.createRef();
    }
    // checkUsed = async (FIDCountry) => {
    //     return await fetch('/IR/country/IsUsed/' + FIDCountry).then(res => res.json());
        
    // }
    // deleteBrand = (FIDCountry) => {
    //     fetch('/IR/country/Delete/' + FIDCountry, { method: "POST" }).then(res => {
    //         this.Editor.current.doExit();
    //     });
    // }
    // onDeleteClick = () => {
    //     var id = this.props.match.params.id;

    //     var isUsed = this.checkUsed(id);

    //     var label = "Confermi la cancellazione della nazione?";

    //     var buttons = [
    //         {
    //             label: "SI",
    //             action: () => {
    //                 this.Editor.current.hideDialog();
    //                 this.deleteBrand(id);
    //             }
    //         },
    //         {
    //             label: "NO",
    //             action: () => {
    //                 this.Editor.current.hideDialog();
    //             }
    //         },
    //     ];
    //     if (isUsed) {
    //         label = "La nazione è utilizzata. Non è possibile procedere con la cancellazione.";
    //         buttons = [
    //             {
    //                 label: "OK",
    //                 action: () => {
    //                     this.Editor.current.hideDialog();
    //                 }
    //             },
    //         ];
    //     }


    //     this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    // }
    render() {
        var fields = [
            {
                key: 'Code',
                label: 'Codice',
                type: 'XField',
                Required: 2
            },
            {
                key: 'FIDLicensee',
                label: 'Licenziatario',
                type: 'XSelect',
                getUrl: '/IR/GetIRLicenseesSelect',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2,
                subtitleKey:'Address'
            },
            {
                key: 'FIDLicenseeDestination',
                label: 'Sede',
                type: 'XSelect',
                getUrl: '/IR/GetIRLicenseeDestinationsSelect',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2,
                subtitleKey:'Address',
                filter: data => [{ key: "FIDIdentity", value: data.FIDLicensee }]
            },
            {
                key: 'FTPUrl',
                label: 'Url FTP',
                type: 'XField',
                Required: 2
            },
            {
                key: 'FTPFolder',
                label: 'Cartella FTP',
                type: 'XField',
                Required: 2
            },
            {
                key: 'FTPUser',
                label: 'Utente FTP',
                type: 'XField',
                Required: 2
            },
            {
                key: 'FTPPassword',
                label: 'Password FTP',
                type: 'XField',
                Required: 2
            },
            
        ];
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/Cash/Save/'
            //urlDelete='/gc/currency/Delete/'
            urlRedirect='/CashRegister/'
            urlExit='/CashRegisters/'
            urlNew="/CashRegister/new"
            urlGetData='/Cash/Get/'
            translate={true}
            fields={fields}
            onlyCustomFields={true}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(CashRegister);
