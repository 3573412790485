import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"

import "components/XReactDataGrid.css";
import XNavigation from 'components/XNavigation';
import Ring from 'components/Icons/Ring';



class Events extends Component {
    constructor(props) {
        super(props);
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/Events/Edit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/Events/Edit');
    }
   
    render() {
        var { width } = this.props;
        var key = "tblEvents";
        var gridName = "tblEvents";
        var dataRoute = "/events/GetEvents?grid=tblEvents";



        return (
            <XGrid
                usePrimaryColor={true}
                key={key}
                dataRoute={dataRoute}
                dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                dataRouteDelete={"/events/DeleteEvents/"}
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={this.handleGridDoubleClick}
                onNewButton={this.handleGridNew}
            />
        );
    }
}

export default Events;