import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';


import Button from "components/CustomButtons/Button.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";



class XProductionButton extends Component {
    render() {
        var { children, ...all } = this.props;
        return (<Button {...all}>{children}</Button>)
    }
}

export default withStyles(buttonStyle)(XProductionButton);