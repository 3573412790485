import React, { Component } from 'react';
import { withStyles,Grid } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import XGrid from "components/XGrid"
import { getXDateField, getXSelect, getXField, getXSwitch, format } from 'utils';

const style = theme => ({
    root: {

    },
    payments: {
        width: '100%',
    },
    paymentsHeader: {
        fontSize: 0,
        fontWeight: 'bold',
        '&>span':{
            verticalAlign:'top',
            fontSize: 'initial',
            display: 'inline-block',
            width: "25%",
            padding: 5,
            '&:not(:last-child)': {
                borderRight: '1px solid black',
            },
            '&:not(:first-child)': {
                textAlign: 'right',
            },
        },
        '&:not(:last-child)': {
            borderBottom: '1px solid black',
        }
    },
    paymentsRow: {
        fontSize: 0,
        '&>span':{
            verticalAlign:'top',
            fontSize: 'initial',
            display: 'inline-block',
            width: "25%",
            padding: 5,
            '&:not(:last-child)': {
                borderRight: '1px solid black',
            },
            '&:not(:first-child)': {
                textAlign: 'right',
            },
        },
        // '&:not(:last-child)': {
        //     borderBottom: '1px solid black',
        // }
    },
    paymentsTotals: {
        fontSize: 0,
        fontWeight: 'bold',
        '&>span':{
            verticalAlign:'top',
            fontSize: 'initial',
            display: 'inline-block',
            width: "25%",
            padding: 5,
            '&:not(:last-child)': {
                borderRight: '1px solid black',
            },
            '&:not(:first-child)': {
                textAlign: 'right',
            },
        },
        // '&:not(:last-child)': {
        //     borderBottom: '1px solid black',
        // }
    }
});


class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters:{
                FIDLicensee: undefined,
                FIDDestination: undefined,
                FIDVendor: undefined,
                DateTo: new Date(),
                DateFrom: new Date(),
            },
            payments:[]
        }
        this.grdSells = React.createRef();
    }
    componentDidMount() {
        this.reloadPaymentTotals();
    }
    reloadPaymentTotals = () => {
        var { filters } = this.state;

        var url ='/DOC/GetSellHistoryByPayment?grid=tblSellHistory';
        if(filters.FIDLicensee){
            url += `&FIDLicensee=${filters.FIDLicensee}`;
        }
        if(filters.FIDDestination){
            url += `&FIDDestination=${filters.FIDDestination}`;
        }
        if(filters.FIDVendor){
            url += `&FIDVendor=${filters.FIDVendor}`;
        }
        if(filters.DateFrom){
            url += `&from=${this.getDateString(filters.DateFrom)}`;
        }
        if(filters.DateTo){
            url += `&to=${this.getDateString(filters.DateTo)}`;
        }
        fetch(url).then(res => res.json()).then(data => {
            this.setState({payments: data.data});
        });
    }
    handleSelectChange = (key) => (_,value) =>{
        this.setState(state =>{
            if(value.length == 0){
                state.filters[key] = undefined;
            }    
            else {
                state.filters[key] = value[0].ID;
            }
            return state;
        },() => {
            this.grdSells.current.getWrappedInstance().refresh();
            this.reloadPaymentTotals();
        })
    }
    handleFieldChange = (key) => (value) =>{
        this.setState(state =>{
            state.filters[key] = value;
            return state;
        },() => {
            this.grdSells.current.getWrappedInstance().refresh();
            this.reloadPaymentTotals();
        });
    }
    renderFilters = () => {
        var { classes } = this.props;
        var { filters, payments } = this.state;
        var fields = [
            {
                Field: 'FIDLicensee',
                Visibility: true,
                Required: 0,
            },
            {
                Field: 'FIDDestination',
                Visibility: true,
                Required: 0,
            },
            {
                Field: 'FIDPosition',
                Visibility: true,
                Required: 0,
            },
            {
                Field: 'FIDVendor',
                Visibility: true,
                Required: 0,
            },
            {
                Field: 'DateFrom',
                Visibility: true,
                Required: 0,
            },
            {
                Field: 'DateTo',
                Visibility: true,
                Required: 0,
            }
        ];

        var licenseeDestinationFilter = [{ key: "FIDIdentity", value: filters.FIDIdentity }];
        
        var vendorFilters = [{ key: "FIDIdentity", value: filters.FIDIdentity }];
        var fieldLicensee = getXSelect(filters, fields, 12, 4, "FIDLicensee", "Licenziatario", this.handleSelectChange, "/IR/GetIRLicenseesSelect","", { sm: 4, subtitleKey: "Address"});
        var fieldDestination = getXSelect(filters, fields, 12, 4, "FIDDestination", "Filiale", this.handleSelectChange, "/IR/GetIRLicenseeDestinationsSelect", "", {sm: 4, subtitleKey: "Address", filters: licenseeDestinationFilter});
        var fieldPosition = getXSelect(filters, fields, 12, 4, "FIDPosition", "Filiale", this.handleSelectChange, "/mag/position/GetSelect", "", { sm: 4});
        var fieldVendor = getXSelect(filters, fields, 12, 4, "FIDVendor", "Venditore", this.handleSelectChange, "/IR/GetIRUserSelect","", { sm: 4, filters: vendorFilters});

        var fieldDatefrom = getXDateField(filters, fields, 12, 4, "DateFrom", "Da data", this.handleFieldChange, { sm: 4,});
        var fieldDateto = getXDateField(filters, fields, 12, 4, "DateTo", "A data", this.handleFieldChange, { sm: 4});
        
        return <div id="filtersContainer" style={{width: '100%'}}>
            <Grid container spacing={2} style={{margin: 0}}>
                <Grid item container spacing={2} xs={12} sm={8}>
                    {fieldLicensee}
                    {fieldDestination}
                    {fieldVendor}
                    {fieldDatefrom}
                    {fieldDateto}
                </Grid>
                <Grid item container xs={12} sm={4}>
                    <div className={classes.payments}>
                        <div className={classes.paymentsHeader}>
                            <span>Pagamento</span>
                            <span>Totale imponibile</span>
                            <span>Totale imposte</span>
                            <span>Totale</span>
                        </div>
                        <div className={classes.paymentsTotals}>
                            <span>Tutte</span>
                            <span>{format(payments.length > 0 ? payments.map(f => f.TaxableAmount).reduce((a,b) => a+b) : 0)}</span>
                            <span>{format(payments.length > 0 ? payments.map(f => f.Taxes).reduce((a,b) => a+b) : 0)}</span>
                            <span>{format(payments.length > 0 ? payments.map(f => f.TotalAmount).reduce((a,b) => a+b) : 0)}</span>
                        </div>
                        {payments.map((f,i) => {
                            return <div className={classes.paymentsRow}><span>{f["Payment"]}</span><span>{format(f["TaxableAmount"])}</span><span>{format(f["Taxes"])}</span><span>{format(f["TotalAmount"])}</span></div>;
                        })}
                    </div>
                </Grid>
            </Grid>
        </div>;
    }
    getDateString = (date) => {
        console.log(date);
        if(date._isAMomentObject){
            return `${date.year()}-${date.month()+1}-${date.date()}`;
        }
        else {
            return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        }
    }
    render(){
        var { classes } = this.props;
        var { filters, payments } = this.state;

        var url ='/DOC/GetSellHistory?grid=tblSellHistory';
        if(filters.FIDLicensee){
            url += `&FIDLicensee=${filters.FIDLicensee}`;
        }
        if(filters.FIDDestination){
            url += `&FIDDestination=${filters.FIDDestination}`;
        }
        if(filters.FIDVendor){
            url += `&FIDVendor=${filters.FIDVendor}`;
        }
        if(filters.DateFrom){
            url += `&from=${this.getDateString(filters.DateFrom)}`;
        }
        if(filters.DateTo){
            url += `&to=${this.getDateString(filters.DateTo)}`;
        }
        return (
            <XGrid
                ref={this.grdSells}
                usePrimaryColor={true}
                dataRoute={url}
                dataRouteColumns="/Base/GetColumns?grid=tblSellHistory"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblSellHistory"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblSellHistory"
                preGridContent={this.renderFilters}
                preGridContentID="filtersContainer"
                onDoubleClick={this.handleGridDoubleClick}
                onEditButton={this.handleGridDoubleClick}

                cardWidth={4}
                cardHeight={4.5}
                //showChangeView={true}
                width={this.props.width}
            />
        );
    }
}


const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Sales);