import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { grayColorVeryLight, grayColor } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
	dataWrapper: {
		width: "100%",
		margin: "auto",
		marginBottom: "10px",
		padding: "0 15px"
	},
	displayInline: {
		float: "left",
		width: "auto"
	},
	lineAfter: {
		position: "relative",
		marginBottom: "30px",
		"&::after": {
			content: "''",
			position: "absolute",
			bottom: "-10px",
			height: "0.05rem",
			width: "calc(100% + 15px)",
			left: "-7.5px",
			backgroundColor: grayColor
		}
	},
	dataLabel: {
		color: grayColorVeryLight,
		padding: "5px 0px",
		fontSize: "12px",
		"& > p": {
			fontSize: "0.8rem"
		}
	},
	dataValue: {
		color: grayColor,
	},
	bigFont: {
		"& > p": {
			fontSize: "2rem",
			fontWeight: "bold"
		}
	}
})

class XProductionBOPDataLabel extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		var { classes, label, value, lineAfter, bigFont, inline } = this.props;

		var wrapperClass = classes.dataWrapper;

		if (lineAfter)
			wrapperClass += " " + classes.lineAfter;

		if (inline)
			wrapperClass += " " + classes.displayInline

		var valueClass = classes.dataValue;

		if (bigFont)
			valueClass += " " + classes.bigFont;

		return (
			<div className={wrapperClass}>
				<div className={classes.dataLabel}><Typography>{label}</Typography></div>
				<div className={valueClass}><Typography>{value}</Typography></div>
			</div>
		);
	}
}
export default withStyles(styles)(XProductionBOPDataLabel);