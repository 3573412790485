import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import XMessageBox from 'components/XMessageBox';
import XNumpad from 'components/XNumpad';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import PositionSelector from 'components/PositionSelector';
import ProductionDepartmentSelector from 'components/ProductionDepartmentSelector';
import XPDFViewer from 'components/XPDFViewer';
import XGallery from 'components/XGallery';
import XProductionButton from 'components/XProductionButton';
import XProductionBOPDataLabel from 'components/XProductionBOPDataLabel';
import XProductionOrderRowLabel from 'components/XProductionOrderRowLabel';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import AppBar from '@material-ui/core/AppBar';
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import { formatDate } from 'utils';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const vertCentered = {
	position: "absolute",
	top: "50%",
	transform: "translateY(-50%)",
}
const halfSpaceH = {
	width: "49%",
	display: "inline-block",
	margin: "0 0.5%",
	marginBottom: "15px",
};
const styles = theme => ({
	qrContainer: {
		position: "relative",
		width: "80%",
		margin: "auto",
		marginTop: "80px",
		textAlign: "center",
		height: "300px",
	},
	qrBlock: {
		"& p": {
			fontSize: "38px"
		},
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translateX(-50%) translateY(-37.5%)",
		boxShadow: "-1px -1px 10px #aaa",
		padding: "60px 45px"
	},
	actionButtons: {
		"& > button": {
			padding: "30px",
			fontSize: "36px"
		},
		width: "100%",
		height: "35%",
		marginTop: "10%"
	},
	btnConfirmSearch: halfSpaceH,
	btnHome: halfSpaceH,
	btnExit: {
		width: "99%",
		margin: "0 0.5%"
	},
	bopContainer: {
		"& > div": {
			display: "inline-block",
			fontSize: "0",
			margin: "0"
		}
	},
	vertCentered: vertCentered,
	centerWrapper: {
		width: "75%"
	},
	rightWrapper: {
		right: "2.5%",
		width: "20%"
	},
	topLabelsWrapper: {
		marginTop: 40,
		marginBottom: "15px",
		width: "100%"
	},
	toggleProductionButtons: {
		marginTop: "20px",
		"& button": {
			width: "calc(100% - 2px)",
			display: "inline-block",
			fontSize: "3vh",
			padding: "5vh 0"
		},
		"& button:nth-of-type(3)": {
			width: "calc(100% - 2px)"
		}
	}
})

class ProductionBOP extends Component {
	constructor(props) {
		super(props);
		this.MessageBox = React.createRef();
		this.state = {
			bopProduction: props.bopProduction,
			operatorId: props.operatorId,
			numpadHidden: false,
			orderRowCodeSearch: false,
			orderRowCode: props.bopProduction ? props.bopProduction.SubOrderRowCode : props.orderRowCode,
			newBop: true,
			defaultReturnDepartment: null,
			productionReturnDepartments: [],
			customContent: null
		};
	}
	componentDidMount = () => {
		var { orderRowCode, bopProduction } = this.state;
		var productionDepartments = [];

		if (orderRowCode && !bopProduction) {
			var { onBopScanning, onBopScanned, onBopScannedFail } = this.props;
			onBopScanning && onBopScanning();
			Promise.all([
				fetch('Production/GetProductionDepartments?returnBOP=true'),
				fetch('Production/GetBOPProduction/?subOrderRowCode=' + orderRowCode)
			]).then(([depsData, bopData]) => { 
				return Promise.all([depsData.json(), bopData.json()]) 
			}).then(([depsData, bopData]) => {
				if (bopData.success) {
					productionDepartments = depsData;

					var newState = {
						bopProduction: bopData.bopProduction,
						numpadHidden: true,
					};

					if (productionDepartments.length > 1)
						newState.productionReturnDepartments = productionDepartments;
					else
						newState.defaultReturnDepartment = productionDepartments[0].ID;
					this.setState(newState);
					onBopScanned && onBopScanned(bopData.bopProduction, bopData.operatorTimes)
				} else if (bopData.msg && onBopScannedFail) {
					// NON DOVREBBE SUCCEDERE IN QUESTO CASO
					onBopScannedFail(bopData.msg, true);
				}
			})
		}
		else {
			fetch('Production/GetProductionDepartments?returnBOP=true').then(res => res.json())
				.then(data => {
					productionDepartments = data;

					if (productionDepartments.length > 1) {
						this.setState({
							productionReturnDepartments: productionDepartments
						})
					} else {
						this.setState({
							defaultReturnDepartment: productionDepartments && productionDepartments.length === 1 ? 	productionDepartments[0].ID : null
						})
					}
				})
		}
	}
	onReturnDepartmentConfirm = (FIDDepartment) => {
		this.endProductionBOP(FIDDepartment);
	}
	returnProductionBOP = () => {
		var { defaultReturnDepartment } = this.state;

		if (defaultReturnDepartment) {
			this.endProductionBOP(defaultReturnDepartment);
		}
		else {
			var departSelect = this.getDepartmentSelectorContent();
			this.setState({
				customContent: departSelect
			});
		}
	}
	getDepartmentSelectorContent = () => {
		var { classes } = this.props;
		var { productionReturnDepartments } = this.state;
		var content = (
			<div className={classes.vertCentered} style={{ width: "90%" }}><Paper>
				<ProductionDepartmentSelector departments={productionReturnDepartments} onDepartSelected={this.onReturnDepartmentConfirm} onCancel={() => { this.setState({ customContent: null }) }} />
			</Paper></div>
		);
		return content;
	}
	toggleNumpad = () => {
		var { numpadHidden } = this.state;

		this.setState({
			numpadHidden: !numpadHidden,
		});
	}

	showSearchFormBOP = () => {
		var { onNewBopClick } = this.props;
		this.setState({
			bopProduction: null,
			orderRowCode: null
		});
		onNewBopClick && onNewBopClick();
	}

	searchCodeChange = () => {
		var searchOrderRowCodeField = document.getElementById("barcodeField");
		var orderRowCodeSearch = false;
		if (searchOrderRowCodeField && searchOrderRowCodeField.value)
			orderRowCodeSearch = searchOrderRowCodeField.value;

		if (orderRowCodeSearch && orderRowCodeSearch.length > 0)
			this.setState({ orderRowCodeSearch });
		else {
			this.setState({ orderRowCodeSearch: false });
		}
	}

	searchOrderRowCode = (orderRowCode) => {
		//var orderRowCode = document.getElementById("barcodeField").value;
		var { onBopScanning, onBopScanned, onBopScannedFail } = this.props;
		if (!orderRowCode || orderRowCode === "")
			return;
		onBopScanning && onBopScanning();

		fetch('Production/GetBOPProduction/?subOrderRowCode=' + orderRowCode).then(res => res.json())
			.then(data => {
				if (data.success) {
					this.setState({
						bopProduction: data.bopProduction,
						numpadHidden: true,
						orderRowCode: orderRowCode
					});
					onBopScanned && onBopScanned(data.bopProduction, data.operatorTimes);
				}
				else if (data.msg && onBopScannedFail) {
					onBopScannedFail(data.msg);
				}
			});
	}

	startProductionBOP = (FIDDepartment, FIDPhase) => {
		var { onProductionStart } = this.props;
		var { bopProduction } = this.state;
		
		var bopData = {
			FIDBOP: bopProduction.ID,
			FIDBOPPosition: bopProduction.FIDBOPPosition,
			FIDOrderRow: bopProduction.FIDOrderRow,
			Description: bopProduction.Description,
			FIDDepartment: FIDDepartment && FIDDepartment > 0 ? FIDDepartment : null,
			FIDPhase: FIDPhase && FIDPhase > 0 ? FIDPhase: null
		};

		fetch('Production/StartProductionActivity', {
			method: "POST",
			body: JSON.stringify(bopData)
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					bopProduction.Running = true;
					this.setState({
						bopProduction: bopProduction
					});
					onProductionStart && onProductionStart();
				}
				else if (data.msg) {
					var buttons = [
						{
							label: "OK",
							action: () => {
								this.MessageBox.current.getWrappedInstance().hideDialog();
							}
						}
					];
					this.MessageBox.current.getWrappedInstance().showDialog(buttons, "", data.msg);
				}
			})
	}

	endProductionBOP = (FIDDepartment) => {
		var { onProductionEnd } = this.props;
		var { bopProduction, orderRowCode } = this.state;

		var bopData = {
			FIDBOP: bopProduction.ID,
			FIDBOPPosition: bopProduction.FIDBOPPosition,
			FIDDepartment: FIDDepartment
		}

		fetch('Production/FinishProductionActivity', {
			method: "POST",
			body: JSON.stringify(bopData)
		}).then(res => res.json())
			.then(data => {
				if (data.success) {
					fetch('Production/GetBOPProduction/?subOrderRowCode=' + orderRowCode).then(res => res.json())
						.then(data => {
							if (data.success) {
								if (FIDDepartment) {
									this.setState({
										customContent: null,
										bopProduction: null,
										orderRowCode: null,
									});
									onProductionEnd && onProductionEnd(0, data.operatorTimes);
								} else {
									this.setState({
										bopProduction: data.bopProduction,
									});
									onProductionEnd && onProductionEnd(data.bopProduction.ProdTime, data.operatorTimes);
								}
							}
						});
				}
			})
	}
	
	showTechnicalSheet = (event) => {
		this.setState({showTechicalSheet:true});
	}

	render() {
		var { classes, onExit, bopsAssigned, onShowAssignedBOPS } = this.props;
		var { customContent, bopProduction, numpadHidden, orderRowCode, orderRowCodeSearch, showTechicalSheet } = this.state;

		if (bopProduction && bopProduction.PdfID && showTechicalSheet) {
			let customActions = [];
			customActions.push({
				group: 'actions',
				name: getTranslation(5,'GC_BACK',this.props.translations, 'Indietro'),
				icon: <ArrowLeft />,
				action: () => { this.setState({showTechicalSheet:false}); }
			});
			
			let url = `/BOP/GetBOPTechnicalSheet?FIDFile=${bopProduction.PdfID}`;
			// SHOW PDF VIEWER
			customContent = (
				<AppBar position="sticky">
                <EnhancedTableToolbar
                    onFilterButton={false}
                    onAdvancedFilterButton={false}
                    onClearFilterButton={false}
                    showColumnsSelector={false}
                    numSelected={0}
                    selectedAll={false}
                    count={0}
                    actions={customActions}
                    grid={this}
					label={"SCHEDA TECNICA - " + bopProduction.Code + (bopProduction.CatalogCode && bopProduction.CatalogCode != bopProduction.Code ? " - " + bopProduction.CatalogCode : "")}
                />
				<XPDFViewer key="print_TS" url={url} width="100%" height={window.innerHeight - 90 - 60 - 6} />
				</AppBar>
			)
		}

		if (customContent) {
			return (<Fragment>{customContent}</Fragment>);
		}

		var files = [];

		if (bopProduction && bopProduction.Files)
			files = bopProduction.Files.split(',').map(m => parseInt(m, 10));

		return (
			!bopProduction && !orderRowCode ?
				<Fragment>
					<div style={{marginTop: "7.5%"}}>
						{numpadHidden ? <div className={classes.qrContainer}>
							<div className={classes.qrBlock}><div><Typography>QR CODE<br />SCAN</Typography></div></div>
						</div> :
							<Grid><XNumpad fieldLabel="Codice commessa" fieldId="barcodeField" padTheme="material" padDisplay="inline" onFieldChange={this.searchCodeChange} onSubmit={this.searchOrderRowCode} /></Grid>}
					</div>
					<div>
						<div className={classes.actionButtons}>
							<XProductionButton className={classes.btnConfirmSearch} disabled={!orderRowCodeSearch} color="green" onClick={(orderRowCodeSearch ? () => { this.searchOrderRowCode(orderRowCodeSearch); } : undefined)}>CONFERMA</XProductionButton>
							<XProductionButton className={classes.btnHome} color="gray" onClick={onExit}>PAGINA INIZIALE</XProductionButton>
							{bopsAssigned && <XProductionButton className={classes.btnExit} color="veryLightGray" onClick={onShowAssignedBOPS}>COMMESSE ASSEGNATE</XProductionButton>}
						</div>
					</div>
					<XMessageBox ref={this.MessageBox} />
				</Fragment>
				:
				<Fragment><div className={classes.bopContainer}>
					<div className={classes.centerWrapper + " " + classes.vertCentered}>
						<div className={classes.topLabelsWrapper}>
							{bopProduction.OrderRowCode && <XProductionOrderRowLabel label="COMMESSA" value={bopProduction.OrderRowCode} />}
							<XProductionOrderRowLabel label="SOTTOCOMMESSA" value={bopProduction.SubOrderRowCode} />
							{bopProduction.OrderRowRefDate && <XProductionOrderRowLabel label="DATA ORDINE" value={formatDate(bopProduction.OrderRowRefDate)} />}
							{bopProduction.OrderRowDeliveryDate && <XProductionOrderRowLabel label="CONSEGNA" value={formatDate(bopProduction.OrderRowDeliveryDate)} />}
							{bopProduction.OrderRowRefNumber && <XProductionOrderRowLabel label="ORDINE" value={bopProduction.OrderRowRefNumber} />}
							{bopProduction.ProductionRefOrderRow && <XProductionOrderRowLabel label="COMMESSA RIF." value={bopProduction.ProductionRefOrderRow} />}
							{bopProduction.Customer && <XProductionOrderRowLabel label="CLIENTE" value={bopProduction.Customer} />}
						</div>
						{bopProduction.Code && <XProductionBOPDataLabel label="ARTICOLO" value={bopProduction.Code + (bopProduction.CatalogCode && bopProduction.CatalogCode != bopProduction.Code ? " - " + bopProduction.CatalogCode : "")} lineAfter={true} bigFont={true} />}
						{bopProduction.Brand && <XProductionBOPDataLabel label="MARCA" value={bopProduction.Brand} lineAfter={!bopProduction.Series && !bopProduction.Line ? true : false} />}
						{bopProduction.Line && <XProductionBOPDataLabel label="LINEA" value={bopProduction.Line} lineAfter={!bopProduction.Series ? true : false} />}
						{bopProduction.Series && <XProductionBOPDataLabel label="SERIE" value={bopProduction.Series} lineAfter={true} />}
						{bopProduction.MeasureUnit1 && bopProduction.Qty1 && <XProductionBOPDataLabel label="ARTICOLI" value={bopProduction.Qty1 + " " + bopProduction.MeasureUnit1} inline={true} />}
						{bopProduction.MeasureUnit2 && bopProduction.Qty2 && <XProductionBOPDataLabel label="PESO" value={bopProduction.Qty2 + " " + bopProduction.MeasureUnit2} inline={true} />}
					</div>
					<div className={classes.rightWrapper + " " + classes.vertCentered}>
						<XGallery bigImage={false} imageIds={files} />
						<div className={classes.toggleProductionButtons}>
							<XProductionButton color="gray" onClick={this.showSearchFormBOP}>NUOVO</XProductionButton>
							{!bopProduction.Running ? (<Fragment><XProductionButton color="green" onClick={(event) => { this.startProductionBOP() }}>START</XProductionButton><XProductionButton style={{ display: "none" }} color="veryLightGray" onClick={this.changeProductionPhaseBOP}>CAMBIA LAVORAZIONE</XProductionButton></Fragment>)
								: (<Fragment><XProductionButton color="red" onClick={(event) => { this.endProductionBOP() }}>STOP</XProductionButton><XProductionButton color="veryLightGray" onClick={this.returnProductionBOP}>RICONSEGNA</XProductionButton></Fragment>)}
							{bopProduction && bopProduction.PdfID && <XProductionButton color={bopProduction.Running? "red" : "veryLightGray"} onClick={(event) => { this.showTechnicalSheet() }} >SCHEDA TECNICA</XProductionButton>}
						</div>
					</div></div>
					<XMessageBox ref={this.MessageBox} />
				</Fragment>
		);
	}
}
export default withStyles(styles)(ProductionBOP);