import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {

	var { padding, id, style } = props;

	style = style || {};

    if (!padding && padding !== 0) {
        style.padding = 8 * 3;
		}
    var tabStyle = Object.assign({}, style);
    if(!tabStyle){
        tabStyle = {};
    }
    tabStyle.padding = padding;
    return (
        <Typography id={id} component="div" style={tabStyle}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

export default TabContainer;
