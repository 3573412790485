import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
//import { KeyboardDatePicker } from '@material-ui/pickers';
//import KeyboardDatePicker from '@material-ui/pickers/KeyboardDatePicker';

import { DatePicker } from "material-ui-pickers";

import XSelect from '../components/XSelect';
//import XSwitch from '../components/XSwitch';
import XField from '../components/XField';
import XDateField from '../components/XDateField';
import XSwitch from '../components/XSwitch';
import XTag from '../components/XTag';
import { TableCustomField } from '../components/TableCustomField';

export class CustomField extends Component {
    state = {
        isEditing: false,
    };

    componentDidMount() {
        this.loadFields();
    }
    loadFields = () => {
        //var { field } = this.props;
    }

    handleNewButton = () => {
        this.setState({ isEditing: true });
    }
    handleExtraFieldChange = (FIDExtra, isData) => (id) => (event, value) => {
        if (this.props.handleExtraFieldChange) {
            this.props.handleExtraFieldChange(FIDExtra, isData)(id)(event, value);
        }
    }
    handleDateChange = (FIDExtra, isData) =>  (id) => (value) => {
        if (this.props.handleExtraFieldChange) {
            this.props.handleExtraFieldChange(FIDExtra, isData)(id)(null, value);
        }
    }
    render() {
        var { field, extra, saveUrl, mainId, loadGridUrl, loadDataUrl, deleteDataUrl, fieldProps, readOnly } = this.props;
        var urlCreate = "";
        switch (field.Type) {
            case 0://string
                var str = "";
                if (extra && extra.length > 0) {
                    str = extra[0].StringValue;
                }
                return (<Grid item xs={12} sm={6} md={3}><XField readOnly={readOnly} label={field.Description} id={"EXT_" + field.ID} value={str} onChange={this.handleExtraFieldChange(field.ID, false)} field={fieldProps} /></Grid>);
            case 1://integer
                var int = "";
                if (extra && extra.length > 0) {
                    int = extra[0].IntValue;
                }
                return (<Grid item xs={12} sm={6} md={3}><XField readOnly={readOnly} label={field.Description} id={"EXT_" + field.ID} value={int} onChange={this.handleExtraFieldChange(field.ID, false)} type="number" field={fieldProps} /></Grid>);
            case 2://decimal
                var dec = "";
                if (extra && extra.length > 0) {
                    dec = extra[0].DecimalValue;
                }
                return (<Grid item xs={12} sm={6} md={3}><XField readOnly={readOnly} label={field.Description} id={"EXT_" + field.ID} value={dec} onChange={this.handleExtraFieldChange(field.ID, false)} type="number" field={fieldProps} /></Grid>);
            case 3://datetime
                var dt = null;
                if (extra && extra.length > 0) {
                    //var date = (new Date(extra[0].DateTimeValue));
                    //dt = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                    dt = extra[0].DateTimeValue;
                }
                
                //return (<Grid item xs={12} sm={6} md={3}><XField label={field.Description} id={"EXT_" + field.ID} value={dt} onChange={this.handleExtraFieldChange(field.ID, false)} type="date" /></Grid>);
                return (
                    <Grid item xs={12} sm={6} md={3}>
                        <XDateField
							readOnly={readOnly}
                            //keyboard
                            label={field.Description}
                            id={"EXT_" + field.ID}
                            value={dt}
                            onChange={this.handleDateChange(field.ID, false)}
                            field={fieldProps}
                        />
                    </Grid>
                );
            case 4://boolean
                var bool = false;
                if (extra && extra.length > 0) {
                    bool = extra[0].BoolValue;
                }
                return (<Grid item xs={12} sm={6} md={3}><XSwitch label={field.Description} id={"EXT_" + field.ID} value={bool} onChange={this.handleExtraFieldChange(field.ID, false)} /></Grid>);
            case 5://combobox
                var cb = undefined;
                if (extra && extra.length > 0) {
                    cb = extra[0].ComboValue;
                }
                if (field.AllowNewValue) {
                    urlCreate = "/Base/CreateCustomFieldValue/" + field.ID;
                }
                return (<Grid item xs={12} sm={6} md={3}><XSelect disabled={readOnly} label={field.Description} id={"EXT_" + field.ID} value={cb} urlData={"/Base/GetExtraValues/" + field.ID} urlCreate={urlCreate} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleExtraFieldChange(field.ID, true)} field={fieldProps} /></Grid>);
            case 6://tagbox
                var tag = [];
                if (extra && extra.length > 0) {
                    tag = extra[0].TagValue;
                    if (typeof tag === "string") {
                        tag = tag.split(',').map((item) => parseInt(item, 10));
                    }
                }
                if (field.AllowNewValue) {
                    urlCreate = "/Base/CreateCustomFieldValue/" + field.ID;
                }
                return (<Grid item xs={12} sm={6} md={3}><XTag label={field.Description} id={"EXT_" + field.ID} value={tag} urlData={"/Base/GetExtraValues/" + field.ID} urlCreate={urlCreate} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.handleExtraFieldChange(field.ID, true)} field={fieldProps} /></Grid>);
            case 7://component
                break;
            case 8://componentcustomfields
                break;
            case 9://tabella
                if (saveUrl && loadGridUrl && loadDataUrl && deleteDataUrl)
                    return (<TableCustomField field={field} saveUrl={saveUrl} mainId={mainId} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} />);
                else 
                    break;
            //return (<Grid item xs={12} sm={12} md={12}>{this.renderExtraTable(field, index)}</Grid>);
            default:
                break;
        }

        return (<Grid item xs={12} sm={6} md={3}><XField label={field.Description} id={"EXT_" + field.ID} value="" /></Grid>);
    }
}