import React, { Component, Fragment } from 'react';

import { withStyles,IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { Check, Star, StarOutline, LockOpenOutline, LockOutline, CartOutline, BellRingOutline, BellOffOutline, StarOff, Handshake, HandRight, Close, CurrencyEur,CurrencyEurOff } from 'mdi-material-ui';
import { LockOffOutline, CartOffOutline } from 'components/Icons';


import XButton from 'components/XButton';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import XDateField from 'components/XDateField';

import Modal from 'components/Modal';

import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
    },
    stars: {
        fontSize: 40,
        cursor: "pointer",
    },
    marginTop: {
        marginTop: "10px",
    },
    buttonsWrapper: {
        width: "100%",
        "& > *": {
            display: "inline-block",
        }
    },
    catalogueButton2: {
        width: 50,
        height: 50,
        padding: 0,
        marginRight: "10px",
		"& svg": {
			marginRight: "0 !important",
		},
        "& > * ": {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
    popup: {
        position: "absolute",
        maxWidth: 350,
        width: "100%",
        left: "50%",
        top: "50%",
        zIndex: "1299",
        background: "#fff",
        boxShadow: "0px 1px 7px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        //border: "1px solid",
        padding: "10px",
        transform: "translate(-50%,-50%)",
        
    },
    popupBack: {
        position: "fixed",
        display: "block",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#999",
        opacity: 0.8,
        zIndex: "1298",
    },
});

class CatalogueActions extends Component {
    state = {
        HoverStar: 0,
        DueDate: undefined,
        showDueDate: false,
    };

    StarEnter = (star) => {
        this.setState({ HoverStar: star });
    }
    StarExit = (star) => {
        setTimeout(() => {
            this.setState(state => {
                if (state.HoverStar === star) {
                    state.HoverStar = undefined;
                }
                return state;
            });
        }, 100);
    }
    StarItem = (star) => {
        var { data, onStarItem } = this.props;
        if (onStarItem) {
            onStarItem(data, star);
        }
    }
    doBuy = () => {
        var { data, onBuyItem } = this.props;
        if (onBuyItem) {
            onBuyItem(data);
        }

    }
    BuyItem = () => {
        var { IsReservedOther, IsBookedOther } = this.state;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        this.doBuy();
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {
            this.doBuy();
        }
    }
    NoticeItem = () => {
        var { data, onNoticeItem } = this.props;
        if (onNoticeItem) {
            onNoticeItem(data);
        }
    }
    NegotiateItem = () => {
        var dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + 7);
        this.setState({ showDueDate: true, dueDate });
    }
    QuoteItem = () => {
        var { data, onQuoteItem } = this.props;
        if (onQuoteItem) {
            onQuoteItem(data);
        }
    }
    onDueDateSelect = () => {
        var { data, onNegotiateItem } = this.props;
        var { dueDate } = this.state;
        data.DueDate = dueDate;
        this.setState({ showDueDate: false, dueDate: undefined });
        if (onNegotiateItem) {
            onNegotiateItem(data);
        } 
    }
    ReserveItem = () => {
        var { data, onReserveItem } = this.props;
        var { IsReservedOther, IsBookedOther } = this.state;

        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onReserveItem) {
                            onReserveItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onReserveItem) {
                onReserveItem(data);
            }
        }

    }
    BookItem = () => {
        var { data, onBookItem } = this.props;
        var { IsReservedOther, IsBookedOther } = this.state;


        if (IsReservedOther || IsBookedOther) {

            var buttons = [
                {
                    label: "SI",
                    action: () => {
                        this.setState({ dialog: {} });

                        if (onBookItem) {
                            onBookItem(data);
                        }
                    }
                },
                {
                    label: "NO",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                },
            ];

            this.showDialog(buttons, "Confermi operazione", "Il prodotto è stato " + (IsReservedOther ? "riservato" : "prenotato") + " da un'altro cliente. Procedere con l'operazione?");
        }
        else {

            if (onBookItem) {
                onBookItem(data);
            }
        }
    }
    RemoveItem = (FIDCausal) => () => {
        var { data, onRemoveItem } = this.props;
        if (onRemoveItem) {
            onRemoveItem(data, FIDCausal);
        }
    }
    dueDateChange = (key) => (value) => {
        this.setState({dueDate: value});
    }
    onDueDateCancel = () => {
        this.setState({ showDueDate: false, dueDate: undefined });
    }
    renderEditDueDate = () => {
        var { classes } = this.props;
        var { dueDate, showDueDate } = this.state;
        return (
            <Modal>
                {showDueDate && <span className={classes.popupBack}></span>}
                {showDueDate &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}><XDateField label="Scadenza" id="DueDate" value={dueDate} onChange={this.dueDateChange} /></Grid>
                            <Fragment>
                                <IconButton onClick={this.onDueDateSelect}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={this.onDueDateCancel}>
                                    <Close />
                                </IconButton>
                            </Fragment>
                        </Grid>
                    </div>}
            </Modal>
        );
    }
    render() {
        var { HoverStar, showDueDate } = this.state;
        var { documents, classes, showStars, onQuoteItem, onNoticeItem } = this.props;
        var isPresentation = documents.filter(i => i.FIDCausal === (this.props.params.DOCPresentationCausalId||10)).length > 0;
        var isCart = documents.filter(i => i.FIDCausal === (this.props.params.DOCCartCausalId||9)).length > 0;
        var isNotice = documents.filter(i => i.FIDCausal === (this.props.params.DOCNoticeCausalId||11)).length > 0;
        var isReservation = documents.filter(i => i.FIDCausal === this.props.params.DOCReservationCausalId).length > 0;
        var isBooking = documents.filter(i => i.FIDCausal === (this.props.params.DOCBookingCausalId||13)).length > 0;
        var isQuote = documents.filter(i => i.FIDCausal === (this.props.params.DOCQuoteCausalId||3)).length > 0;
        var isNegotation = documents.filter(i => i.FIDCausal === (this.props.params.DOCNegotiationCausalId||39)).length > 0;
        var color = grayColor;
        
        if (showStars === undefined)
            showStars = true;

        if (isPresentation && showStars === true) {
            if (!HoverStar) {
                HoverStar = documents.filter(i => i.FIDCausal === (this.props.params.DOCPresentationCausalId||10))[0].Stars;
            }
            showStars = true;
        }
        switch (HoverStar) {
            case 1:
                color = "#E74C3C";
                break;
            case 2:
                color = "#F39C12";
                break;
            case 3:
                color = "#F7DC6F";
                break;
            case 4:
                color = "#82E0AA";
                break;
            case 5:
                color = "#27AE60";
                break;
            default:
                break;
        }

        return (
            <div className={classes.root}>
                <Grid item container xs={12} className={classes.marginTop}>
                    <div className={classes.buttonsWrapper}>
                        {<Tooltip title={!isPresentation ? "Aggiungi a presentazione" : "Rimuovi da presentazione"}>
                            <XButton className={classes.catalogueButton2} color={!isPresentation ? "primary" : "gray"} onClick={!isPresentation ? () => this.StarItem(-1) : this.RemoveItem(this.props.params.DOCPresentationCausalId)}>{!isPresentation ? <Star /> : <StarOff />}</XButton>
                        </Tooltip>}
                        {onNoticeItem && <Tooltip title={!isNotice ? "Avviso" : "Rimuovi avviso"}>
                            <XButton className={classes.catalogueButton2} color={!isNotice ? "primary" : "gray"} onClick={!isNotice ? this.NoticeItem : this.RemoveItem(this.props.params.DOCNoticeCausalId)}>{!isNotice ? <BellRingOutline /> : <BellOffOutline />}</XButton>
                        </Tooltip>}
                        {onQuoteItem && <Tooltip title={!isQuote ? "Preventivo" : "Preventivo"}>
                            <XButton className={classes.catalogueButton2} color={!isQuote ? "primary" : "gray"} onClick={!isQuote ? this.QuoteItem : this.RemoveItem(this.props.params.DOCQuoteCausalId)}>{!isQuote ? <CurrencyEur /> : <CurrencyEurOff />}</XButton>
                        </Tooltip>}
                        <Tooltip title={!isNegotation ? "Trattativa" : "Trattativa"}>
                            <XButton className={classes.catalogueButton2} color={!isNegotation ? "primary" : "gray"} onClick={!isNegotation ? this.NegotiateItem : this.RemoveItem(39)}>{!isNegotation ? <Handshake /> : <HandRight />}</XButton>
                        </Tooltip>
                        <Tooltip title={!isBooking ? "Prenota" : "Prenota"}>
                            <XButton className={classes.catalogueButton2} color={!isBooking ? "primary" : "gray"} onClick={!isBooking ? this.BookItem : this.RemoveItem(this.props.params.DOCBookingCausalId)}>{!isBooking ? <LockOutline /> : <LockOffOutline />}</XButton>
                        </Tooltip>
                        <Tooltip title={!isCart ? "Carrello" : "Carrello"}>
                            <XButton className={classes.catalogueButton2} color={!isCart ? "primary" : "gray"} onClick={!isCart ? this.BuyItem : this.RemoveItem(this.props.params.DOCCartCausalId)}>{!isCart ? <CartOutline /> : <CartOffOutline />}</XButton>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item container xs={12} className={classes.marginTop}>
                    {showStars && <Grid item xs={12} className={classes.stars}>
                        {HoverStar >= 1 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(1)} onMouseLeave={() => this.StarExit(1)} htmlColor={color} onClick={() => this.StarItem(1)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(1)} onMouseLeave={() => this.StarExit(1)} htmlColor={color} />}
                        {HoverStar >= 2 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(2)} onMouseLeave={() => this.StarExit(2)} htmlColor={color} onClick={() => this.StarItem(2)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(2)} onMouseLeave={() => this.StarExit(2)} htmlColor={color} />}
                        {HoverStar >= 3 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(3)} onMouseLeave={() => this.StarExit(3)} htmlColor={color} onClick={() => this.StarItem(3)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(3)} onMouseLeave={() => this.StarExit(3)} htmlColor={color} />}
                        {HoverStar >= 4 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(4)} onMouseLeave={() => this.StarExit(4)} htmlColor={color} onClick={() => this.StarItem(4)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(4)} onMouseLeave={() => this.StarExit(4)} htmlColor={color} />}
                        {HoverStar >= 5 ? <Star className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(5)} onMouseLeave={() => this.StarExit(5)} htmlColor={color} onClick={() => this.StarItem(5)} /> : <StarOutline className={classes.stars} style={{ cursor: 'pointer', fontSize: 40 }} onMouseEnter={() => this.StarEnter(5)} onMouseLeave={() => this.StarExit(5)} htmlColor={color} />}
                    </Grid>}
                </Grid>
                {showDueDate && this.renderEditDueDate()}
            </div>
        );
    }
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(CatalogueActions);