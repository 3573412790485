import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Link } from 'react-router-dom';

import { PaletteSwatch, CashMultiple } from 'mdi-material-ui';
import { Tools } from 'components/Icons';
import classNames from 'classnames';

import {
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";


import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';


const fullHeight = (window.innerHeight - 64);
const halfHeight = fullHeight / 2 - 8;
const styles = theme => ({
    root: {
        padding: 5,
    },
    fullHeight: {
        maxHeight: 'calc(100vh - 64px)',
        minHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        padding: '0 5px',
    },
    halfHeight: {
        maxHeight: 'calc(calc(100vh - 64px) / 2)',
        minHeight: 'calc(calc(100vh - 64px) / 2)',
        overflow: 'hidden',
    },
    card: {
        maxHeight: 'calc(calc(calc(100vh - 64px) / 2) - 35px)',//halfHeight - 35,
        minHeight: 'calc(calc(calc(100vh - 64px) / 2) - 35px)',//halfHeight - 35,
        //overflow: 'hidden',
        borderRadius: 1,
        marginBottom: 0,
    },
    cardBody: {
        maxHeight: 'calc(100% - 56px)',
        overflowY: 'auto',
        padding: 10,
        fontSize: 0,
        '&> *':{
            fontSize: 'initial',
        }
    },
    cardCategory: {
        color: "#999999",
        fontSize: 20,
        fontWeight: 'bold',
        paddingTop: 17,
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    button:{
        display: 'inline-block',
        verticalAlign: 'top',
        width: 'calc(50% - 10px)',
        height: 'calc(calc(calc(calc(calc(100vh - 64px) / 2) - 35px - 56px) / 3) - 20px)',//((halfHeight - 35 - 56) / 3) - 20,
        background: 'red',
        margin: 5,
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease',
        position:'relative',
        '& > span': {
            //lineHeight: `${((halfHeight - 35 - 56) / 3) - 20}px`,
            color: '#fff',
            display:'inline-block',
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)'
        },
        '&:hover': {
            boxShadow: '0 0 10px rgba(0,0,0,0.4)',
        }
    },
    buttonFull:{
        width: 'calc(100% - 10px)',
    },
    buttonRed: {
        background: 'rgb(197,82,77)',
    },
    buttonBlue: {
        background: 'rgb(113,182,177)',
    },
    buttonGray: {
        background: 'rgb(163,163,163)',
    },
    buttonGold: {
        background: 'rgb(226,179,49)',
    }
});

class Documents extends Component {
    render() {
        var { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} className={classes.halfHeight}>
                        <Card className={classes.card}>
                            <CardHeader color="blue" stats icon>
                                <CardIcon color="blue">
                                    <PaletteSwatch />
                                </CardIcon>
                                <p className={classes.cardCategory}>{getTranslation(2,"DOC_COMMERCIALS",this.props.translations, "Documenti commerciali")}</p>
                            </CardHeader>
                            <CardBody className={classes.cardBody}>
                                <Link to="/documents/receive/quote" className={classNames(classes.button, classes.buttonRed)}><span>{getTranslation(2,"DOC_RECQUOTE",this.props.translations, "Ricezione preventivo")}</span></Link>
                                <Link to="/documents/issue/order" className={classNames(classes.button, classes.buttonRed)}><span>{getTranslation(2,"DOC_ISSORDER",this.props.translations, "Emissione ordine")}</span></Link>
                                <Link to="/documents/issue/quote" className={classNames(classes.button, classes.buttonBlue)}><span>{getTranslation(2,"DOC_ISSQUOTE",this.props.translations, "Emissione preventivo")}</span></Link>
                                <Link to="/documents/receive/order" className={classNames(classes.button, classes.buttonBlue)}><span>{getTranslation(2,"DOC_RECORDER",this.props.translations, "Ricezione ordine")}</span></Link>
                                <Link to="/documents/commercial" className={classNames(classes.button, classes.buttonFull, classes.buttonGray)}><span>{getTranslation(2,"DOC_SEARCH",this.props.translations, "Ricerca")}</span></Link>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.halfHeight}>
                        <Card className={classes.card}>
                            <CardHeader color="red" stats icon>
                                <CardIcon color="red">
                                    <PaletteSwatch />
                                </CardIcon>
                                <p className={classes.cardCategory}>{getTranslation(2,"DOC_FISCALS",this.props.translations) || "Documenti fiscali"}</p>
                            </CardHeader>
                            <CardBody className={classes.cardBody}>
                                <Link to="/documents/receive/ddt" className={classNames(classes.button, classes.buttonRed)}><span>{getTranslation(2,"DOC_RECDDT",this.props.translations, "Ricezione DDT")}</span></Link>
                                <Link to="/documents/receive/invoice" className={classNames(classes.button, classes.buttonRed)}><span>{getTranslation(2,"DOC_RECINV",this.props.translations, "Operazioni su acquisti")}</span></Link>
                                <Link to="/documents/issue/ddt" className={classNames(classes.button, classes.buttonBlue)}><span>{getTranslation(2,"DOC_ISSDDT",this.props.translations, "Emissione DDT")}</span></Link>
                                <Link to="/documents/issue/invoice" className={classNames(classes.button, classes.buttonBlue)}><span>{getTranslation(2,"DOC_ISSINV",this.props.translations, "Operazioni su vendite")}</span></Link>
                                <Link to="/documents/fiscal" className={classNames(classes.button, classes.buttonFull, classes.buttonGray)}><span>{getTranslation(2,"DOC_SEARCH",this.props.translations, "Ricerca")}</span></Link>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.halfHeight}>
                        <Card className={classes.card}>
                            <CardHeader color="yellow" stats icon>
                                <CardIcon color="yellow">
                                    <PaletteSwatch />
                                </CardIcon>
                                <p className={classes.cardCategory}>{getTranslation(2,"DOC_INTERNAL",this.props.translations, "Documenti interni")}</p>
                            </CardHeader>
                            <CardBody className={classes.cardBody}>
                                <Link to="/documents/inventory" className={classNames(classes.button, classes.buttonGold)}><span>{getTranslation(2,"DOC_INVENTORY",this.props.translations, "Inventario")}</span></Link>
                                <Link to="/documents/load" className={classNames(classes.button, classes.buttonGold)}><span>{getTranslation(2,"DOC_LOAD",this.props.translations,"Carico")}</span></Link>

                                <Link to="/documents/movements" className={classNames(classes.button, classes.buttonGold)}><span>{getTranslation(2,"DOC_MOVEMENTS",this.props.translations, "Spostamenti")}</span></Link>
                                <Link to="/documents/unload" className={classNames(classes.button, classes.buttonGold)}><span>{getTranslation(2,"DOC_UNLOAD",this.props.translations,"Scarico")}</span></Link>
                                {/* <Link to="/documents/modifications" className={classNames(classes.button, classes.buttonGold)}><span>Modifiche</span></Link> */}
                                {/* <Link to="/documents/assignments" className={classNames(classes.button, classes.buttonGold)}><span>Assegnazioni</span></Link> */}
                                <Link to="/documents/internal" className={classNames(classes.button, classes.buttonFull, classes.buttonGray)}><span>{getTranslation(2,"DOC_SEARCH",this.props.translations, "Ricerca")}</span></Link>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.halfHeight}>
                        <Card className={classes.card}>
                            <CardHeader color="violet" stats icon>
                                <CardIcon color="violet">
                                    <CashMultiple />
                                </CardIcon>
                                <p className={classes.cardCategory}>{getTranslation(2,"DOC_CASH",this.props.translations,"Cassa")}</p>
                            </CardHeader>
                            <CardBody className={classes.cardBody}>
                                <Link to="/documents/render/cash" className={classNames(classes.button, classes.buttonFull, classes.buttonRed)}><span>{getTranslation(2,"DOC_RECCASH",this.props.translations, "Reso cassa")}</span></Link>
                                <Link to="/documents/issue/cash" className={classNames(classes.button, classes.buttonFull, classes.buttonBlue)}><span>{getTranslation(2,"DOC_ISSCASH",this.props.translations,"Emissione cassa")}</span></Link>
                                <Link to="/documents/cash" className={classNames(classes.button, classes.buttonFull, classes.buttonGray)}><span>{getTranslation(2,"DOC_SEARCH",this.props.translations,"Ricerca")}</span></Link>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.halfHeight}>
                        <Card className={classes.card}>
                            <CardHeader color="orange" stats icon>
                                <CardIcon color="orange">
                                    <Tools />
                                </CardIcon>
                                <p className={classes.cardCategory}>{getTranslation(2,"DOC_REPAIRS",this.props.translations, "Riparazioni")}</p>
                            </CardHeader>
                            <CardBody className={classes.cardBody}>
                                <Link to="/documents/receive/repair" className={classNames(classes.button, classes.buttonFull, classes.buttonRed)}><span>{getTranslation(2,"DOC_RECREPAIR",this.props.translations,"Ricevi riparazioni")}</span></Link>
                                <Link to="/documents/issue/repair" className={classNames(classes.button, classes.buttonFull, classes.buttonBlue)}><span>{getTranslation(2,"DOC_ISSREPAIR",this.props.translations, "Consegna riparazioni")}</span></Link>
                                <Link to="/documents/repair" className={classNames(classes.button, classes.buttonFull, classes.buttonGray)}><span>{getTranslation(2,"DOC_SEARCH",this.props.translations, "Ricerca")}</span></Link>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(Documents);